import { memo, useEffect, useState, type ReactNode } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Badge, { type BadgeProps } from '@mui/material/Badge';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';

type Props = BoxProps & {
    readonly isError?: boolean;
    readonly message?: ReactNode;
    readonly TooltipProps?: TooltipProps & {
        readonly closeTimeoutSec?: number;
    };
    readonly BadgeProps?: BadgeProps;
};

const AdvancedTabLabel = ({
    children,
    isError,
    message,
    TooltipProps,
    BadgeProps,
    ...props
}: Props) => {
    const [isOpen, setOpen] = useState(isError);

    useEffect(() => setOpen(isError), [isError]);

    useEffect(() => {
        const closeTimeoutSec = TooltipProps?.closeTimeoutSec ?? 5;
        let timer: ReturnType<typeof setTimeout>;

        if (isOpen) {
            timer = setTimeout(() => setOpen(false), closeTimeoutSec * 1000);
        }

        return () => clearTimeout(timer);
    }, [isOpen, TooltipProps?.closeTimeoutSec]);

    return (
        <Tooltip
            placement='top'
            arrow
            title={message}
            {...TooltipProps}
            open={isOpen}
        >
            <Box
                sx={{
                    p: 1
                }}
            >
                <Badge
                    color={isError
                        ? 'error'
                        : 'primary'}
                    variant='dot'
                    invisible={!isError}
                    {...BadgeProps}
                >
                    <Box
                        {...props}
                        onMouseEnter={() => isError && setOpen(true)}
                    >
                        {children}
                    </Box>
                </Badge>
            </Box>
        </Tooltip>
    );
};

export default memo(AdvancedTabLabel);
