import { Filters as GeneralFilters, FilterValueModifier, ParentVisibility } from "consts/filters";
import { Filters } from "consts/merchantPayouts";
import { RequestQueryMapperStrategy } from "consts/request-query";
import { RequestQueryMapper } from "util/request-query-mapper";

export default function useRequestQueryOverrideDecorator(
    requestQueryAggregator: (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void
) {
    const getParentVisibility = (fontendQueryParams: URLSearchParams) => {
        if (fontendQueryParams.has(GeneralFilters.parentVisibility)) {
            return Number(fontendQueryParams.get(GeneralFilters.parentVisibility));
        }
    };

    const getParentId = (fontendQueryParams: URLSearchParams) => {
        if (fontendQueryParams.has(Filters.parentId)) {
            return fontendQueryParams.get(Filters.parentId);
        }
    };

    const isChildrenVisibilitySelected = (fontendQueryParams: URLSearchParams) =>
        getParentVisibility(fontendQueryParams) === ParentVisibility.Children;

    const isParentsVisibilitySelected = (fontendQueryParams: URLSearchParams) =>
        getParentVisibility(fontendQueryParams) === ParentVisibility.Parents;

    return (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        const requestQueryMapper = RequestQueryMapper.from(
            backendQueryParams,
            { setStrategy: RequestQueryMapperStrategy.Set }
        );
        const parentId = getParentId(fontendQueryParams);

        if (isChildrenVisibilitySelected(fontendQueryParams)) {
            if (parentId) {
                return requestQueryMapper.containsIn(
                    Filters.parentId,
                    parentId
                );
            }

            return requestQueryMapper.inclusiveRange(
                Filters.parentId,
                '1'
            );
        }

        if (isParentsVisibilitySelected(fontendQueryParams)) {
            return requestQueryMapper.checkWithModifier(
                Filters.parentId,
                FilterValueModifier.IsNull
            );
        }

        return requestQueryAggregator(
            fontendQueryParams,
            backendQueryParams
        );
    };
};
