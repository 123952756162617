import moment from "moment";

export class DateTimeFormatRepository {
    static Default = 'YYYY-MM-DD';

    static Time = 'HH:mm:ss';

    static Week = 'ww';

    static Hour = 'H';

    static get MonthYear() {
        return ['MMM', this.Year].join(' ');
    }

    static Year = 'YYYY';

    static get Read() {
        return [this.Default, this.Time].join('T');
    }

    static get Write() {
        return [this.Default, this.Time].join(' ');
    }
}

export const AUTH_DATA_KEY = 'authData';

export const enum Locale {
    En = 'en'
};

export const FLOAT_NUMBER_PRECISION = 2;

export const MIN_ALLOWED_AGE = 18;

export const DEFAULT_MAX_DATE = moment()
    .subtract(MIN_ALLOWED_AGE, 'y')
    .toDate();

export const enum BooleanSwitchValue {
    Off = 0,
    On
};

export const enum Setup {
    Domains = 'APIINFO_DOMAINS',
    KYC = 'KYC_ON_OFF_SWITCH',
    TicketingSystem = 'TICKETING_SYSTEM_ON_OFF_SWITCH',
    ZendeskTicketingSystem = 'ZENDESK_TICKETING_ON_OFF_SWITCH',
};

export const enum MOR {
    Default = 'default'
};

export const enum CurrencySymbol {
    EUR = '€',
    USD = '$',
    GBP = '£'
};
