import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRouteTypes } from "consts/enpoints/api";
import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { PaginateResourceResponse, ThunkReturnType } from "types";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { getMerchantsMethodsPivot } from "./api";
import { sliceToken } from "./state";
import type { MerchantsMethodsPivot } from "./types";

export const getMerchantsMethodsPivotThunk: AsyncThunk<
    ThunkReturnType<PaginateResourceResponse<MerchantsMethodsPivot>>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantsMethodsPivot]),
    payloadCreator(getMerchantsMethodsPivot),
    {
        condition: (_, { getState }) => {
            const { pivots } = getState() as RootState;

            return ![ApiState.Pending].includes(pivots.merchantsMethods.loadingState);
        }
    }
);