import { memo } from "react";
import { selectIsMerchantsModelLoading, selectInactiveMerchants } from "features/merchants/selectors";
import { useTypedSelector } from "hooks";
import Cassette from "../Cassette";

const Inactive = () => {
    const inactiveMerchants = useTypedSelector(selectInactiveMerchants);

    return (
        <Cassette
            isLoading={useTypedSelector(selectIsMerchantsModelLoading)}
            labelSlot='Inactive Merchants'
            amountSlot={inactiveMerchants.length}
        />
    );
};

export default memo(Inactive);
