import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.ApiId]: string()
                .required()
                .label('Api Id'),
            [FormField.ApiGuid]: string()
                .required()
                .label('Api Guid'),
            [FormField.CpKey]: string()
                .required()
                .label('Cp Key'),
            [FormField.CpSecret]: string()
                .required()
                .label('Cp Secret'),
            [FormField.SharedKey]: string()
                .required()
                .label('Shared Key')
        })
        , []);
};
