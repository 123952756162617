import { useMemo } from "react";
import { object, string } from "yup";

export const formFields = {
  email: "email",
  password: "password",
};

const useValidationSchema = () =>
  useMemo(
    () =>
      object().shape({
        [formFields.email]: string()
          .email("Invalid email")
          .required("Email required"),
        [formFields.password]: string()
          .min(6, "Password length not enough")
          .max(50, "You are crazy?")
          .required("Password required"),
      }),
    []
  );

export default useValidationSchema;
