import { useRequest } from "hooks";
import {
    getMerchants as getMerchantsRequest,
    getMerchantGroups as getMerchantGroupsRequest
} from './api';
import type {
    Merchant,
    MerchantGroupsResponse,
    MerchantResponse,
    MerchantsResponse
} from "./types";
import {
    getMerchantGroupsThunk,
    getMerchantsThunk,
    postMerchantThunk,
    putMerchantThunk
} from "./thunks";
import type { ThunkReturnType } from "types";
import useExportTable from "hooks/useExportTable";
import {
    changeExportLoading,
    changeMerchantGroupsLoadingState,
    changeMerchantGroupsSlice,
    changeMerchantsLoadingState,
    changeMerchantsSlice,
    resetState
} from "./slice";
import useResetState from "hooks/useResetState";
import { ApiRouteTypes } from "consts/enpoints/api";
import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { PER_PAGE_SIZE } from "consts/transactions";
import { ApiState } from "infrastructure/api";
import { selectIsNotMor } from "features/auth/selectors";
import { Filters, MerchantTabs } from "consts/merchants";
import { RequestQueryMapper } from "util/request-query-mapper";
import { SortOrder } from "consts/request-query";

const useMerchantActions = () => {
    const getMerchants = useRequest<ThunkReturnType<MerchantsResponse>, string | undefined>({
        thunk: getMerchantsThunk
    });

    const postMerchant = useRequest<any, Partial<Merchant>>({
        thunk: postMerchantThunk
    });

    const putMerchant = useRequest<ThunkReturnType<MerchantResponse>, Partial<Merchant>>({
        thunk: putMerchantThunk
    });

    const getMerchantGroups = useRequest<ThunkReturnType<MerchantGroupsResponse>, string | undefined>({
        thunk: getMerchantGroupsThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetMerchants
    });

    const resetMerchants = useResetState(resetState);

    return {
        getMerchants,
        putMerchant,
        postMerchant,
        downloadCsv,
        resetMerchants,
        getMerchantGroups,
        getAllMerchantGroups: useFetchFeatureResource({
            resourceRequest: getMerchantGroupsRequest,
            changeLoadingState: changeMerchantGroupsLoadingState,
            changeSlice: changeMerchantGroupsSlice,
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE,
            condition: getState => {
                const { merchantGroups } = getState().merchants[MerchantTabs.Merchants].bootstrapData;

                return ![
                    ApiState.Pending,
                    ApiState.Succeeded
                ].includes(merchantGroups.loadingState);
            }
        }),
        getAllMerchants: useFetchFeatureResource({
            resourceRequest: (queryString?: string) => getMerchantsRequest(
                RequestQueryMapper.from(queryString)
                    .sortBy(Filters.fullName)
                    .sortOrder(SortOrder.Asc)
                    .toString()
            ),
            changeLoadingState: changeMerchantsLoadingState,
            changeSlice: changeMerchantsSlice,
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE,
            condition: getState => selectIsNotMor(getState()) && ![
                ApiState.Succeeded,
                ApiState.Pending
            ].includes(getState().merchants.merchants.tableLoadingState)
        })
    };
};

export default useMerchantActions;
