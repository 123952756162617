import useAuth from "features/auth/useAuth";
import navConfig from "./config";
import { UserRolesNames } from "consts/auth";

export default function useNavConfig() {
    const { user } = useAuth();

    const getConfig = () => {
        const hasRole = (roles: Array<UserRolesNames>) =>
            roles.includes(user?.roleName);

        return navConfig.reduce((config, navConfigItem) => {
            return [
                ...config,
                {
                    ...navConfigItem,
                    items: navConfigItem.items
                        .reduce((items, item) => {
                            if (!hasRole(item.roles)) {
                                return items;
                            }

                            return [
                                ...items,
                                {
                                    ...item,
                                    children: item.children?.filter(({ roles }) => hasRole(roles))
                                }
                            ];
                        }, [] as typeof navConfigItem.items)
                }
            ];
        }, [] as typeof navConfig);
    };

    return {
        getConfig
    };
};
