import { memo, type PropsWithChildren } from 'react';
import type { BoxProps } from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import type { UseDownloadable } from './useDownloadable';
import useDownloadable from './useDownloadable';

type Props = UseDownloadable & {
    readonly file: string | File;
    readonly sx?: BoxProps['sx'];
};

const Downloadable = ({ file, downloadFile, sx, children }: PropsWithChildren<Props>) => {
    const {
        isDownloading,
        onDownload
    } = useDownloadable({ downloadFile });

    const handleDownload = () => onDownload(file);

    return (
        <LoadingButton
            loading={isDownloading}
            onClick={handleDownload}
            sx={{
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
                minWidth: 'unset',
                ...sx
            }}
        >
            {!isDownloading && children}
        </LoadingButton>
    );
};

export default memo(Downloadable);
