import { memo, type PropsWithChildren } from "react";
import {
    FormFields,
    type FormValues,
    type QueryBuilderForm,
    type QueryBuilderProps
} from "ui/forms/QueryBuilder";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { RequestQueryMapper } from "util/request-query-mapper";
import type { ElasticSearchResourceResponse } from "types";

type Props =
    & QueryBuilderProps
    & QueryBuilderForm
    & {
        readonly onSubmit: (formValues: FormValues) => Promise<void>;
        readonly queryParam?: string;
        readonly data?: ElasticSearchResourceResponse<string>['data'];
    };

const QueryBuilderPaginator = ({
    isLoading,
    isSubmitting,
    children,
    values,
    data,
    onSubmit,
    queryParam
}: PropsWithChildren<Props>) => {
    const onClick = () =>
        onSubmit({
            [FormFields.SearchQuery]: RequestQueryMapper.from(values[FormFields.SearchQuery])
                .contains(`${queryParam}`, data!.cursor)
                .searchQueryParams
                .toString()
        });

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}
        >
            {children}
            <LoadingButton
                variant='outlined'
                loading={isLoading || isSubmitting}
                disabled={!data?.cursor}
                onClick={onClick}
                sx={{
                    mt: 2
                }}
            >
                Next Page
            </LoadingButton>
        </Box>
    );
};

QueryBuilderPaginator.defaultProps = {
    queryParam: 'cursor'
};

export default memo(QueryBuilderPaginator);
