import { memo } from "react";
import { BasicDialog, type ConfirmatableDialogProps } from "ui/molecules/Dialog";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Confirmatable = ({
    content,
    checkPredicate,
    onConfirm,
    onCancel,
    cancelActionContentSlot,
    onClose = () => {},
    open = false,
    isProcessing = false,
}: ConfirmatableDialogProps) => {
    const renderContent = () => {
        const commonActionProps: LoadingButtonProps = {
            variant: 'contained',
            loading: isProcessing,
            onClick: onConfirm
        };

        if (checkPredicate()) {
            return {
                dialogContent: content[1].dialogContent,
                action: (
                    <LoadingButton
                        {...commonActionProps}
                        color="error"
                    >
                        {content[1].actionContent}
                    </LoadingButton>
                )
            };
        }

        return {
            dialogContent: content[0].dialogContent,
            action: (
                <LoadingButton
                    {...commonActionProps}
                >
                    {content[0].actionContent}
                </LoadingButton>
            )
        };
    };

    const {
        dialogContent,
        action: ActionComponent
    } = renderContent();

    return (
        <BasicDialog
            isOpen={open}
            onClose={onClose}
            actionsSlot={(
                <Stack
                    direction='row'
                    spacing={2}
                >
                    <Button
                        variant='outlined'
                        onClick={onCancel}
                        disabled={isProcessing}
                    >
                        {cancelActionContentSlot || 'Close'}
                    </Button>
                    {ActionComponent}
                </Stack>
            )}
        >
            <Box>
                <Box
                    sx={{
                        fontWeight: 700,
                        textAlign: 'center'
                    }}
                >
                    {dialogContent[0]}
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        textAlign: 'center'
                    }}
                >
                    {dialogContent[1]}
                </Box>
            </Box>
        </BasicDialog>
    );
};

export default memo(Confirmatable);
