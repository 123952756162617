import { memo, useState } from "react";
import { GridActionsCellItem, type GridRowParams } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import Icon from "ui/atoms/Icon";
import Edit from "./Edit";
import Delete, { DeleteDialog } from "./Delete";

const TableActions = (gridRowParams: GridRowParams) => {
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const onMouseDown = () => setDeleteDialogOpen(true);

    const onClose = () => setDeleteDialogOpen(false);

    return (
        <>
            <Tooltip
                arrow
                placement="left"
                title={(
                    <List dense disablePadding>
                        <Edit {...gridRowParams} />
                        <Delete
                            {...gridRowParams}
                            onMouseDown={onMouseDown}
                        />
                    </List>
                )}
            >
                <GridActionsCellItem
                    label="More"
                    icon={(
                        <Icon name="MoreVert" />
                    )}
                />
            </Tooltip>
            <DeleteDialog
                {...gridRowParams}
                open={isDeleteDialogOpen}
                onClose={onClose}
            />
        </>
    );
};

export default memo(TableActions);
