import { memo } from 'react';
import Box from '@mui/material/Box';
import StatisticDashboardProvider from './StatisticDashboardProvider';
import StatisticDashboardFilters from './StatisticDashboardFilters';
import StatisticDashboardData from './StatisticDashboardData';
import StatisticDashboardCassettes from './StatisticDashboardCassettes';
import { FilterVariant, date } from 'consts/filters';
import { multi, text } from 'consts/dashboard';
import Resource from 'ui/layouts/Resource';

const StatisticDashboard = () => (
    <Resource
        title=' Dashboard'
        breadcrumbsProps={{
            heading: 'Dashboard',
            links: [{ name: '' }],
            sx: {
                px: {
                    xs: 2,
                    lg: 0
                }
            }
        }}
    >

    <StatisticDashboardProvider
        filterTypes={new Map<FilterVariant, string[]>([
            [FilterVariant.MultiOption, multi],
            [FilterVariant.DateRange, date],
            [FilterVariant.Text, text]
        ])}
    >
        <Box
            sx={{
                px: {
                    xs: 2,
                    lg: 0
                }
            }}
        >
            <StatisticDashboardFilters />
            <StatisticDashboardCassettes />
            <StatisticDashboardData />
        </Box>
    </StatisticDashboardProvider>
    </Resource>
);

export default memo(StatisticDashboard);
