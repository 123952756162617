import { downloadKYCFile } from "features/kyc/api";
import withGridCellParams from "../withGridCellParams";
import { useCellContext } from "ui/widgets/Table/contexts/CellProvider";
import type { FileCellProps } from "./types";
import Single from "./Single";
import Multiple from "./Multiple";


const File = withGridCellParams<string | File | Array<string | null>>(props => {
    const { value = null, field } = props;

    const cellContext = useCellContext();

    const {
        title,
        downloadFile = downloadKYCFile
    } = cellContext[field]?.getCellProps?.<FileCellProps>(props) ?? {};

    const fileProps = {
        title,
        downloadFile: (fileKey: string) => downloadFile(fileKey, fileKey)
    };

    if (Array.isArray(value)) {
        return value.length > 1
            ? (
                <Multiple
                    {...fileProps}
                    value={value}
                />
            )
            : (
                <Single
                    {...fileProps}
                    value={value[0]}
                />
            );
    }

    return (
        <Single
            {...fileProps}
            value={value}
        />
    );
});

export default File;
