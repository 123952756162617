import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import type { ThunkReturnType } from "types";
import type { PaymentMethodsResponse, PaymentMethodsState } from "./types";

export const paymentMethodsRequestLoading = (state: WritableDraft<PaymentMethodsState>) => {
    state.paymentMethodsLoadingState = ApiState.Pending;
};

export const paymentMethodsRequestFulfilled = (
    state: WritableDraft<PaymentMethodsState>,
    { payload }: PayloadAction<ThunkReturnType<PaymentMethodsResponse>>) => {
    state.paymentMethodsLoadingState = ApiState.Succeeded;
    state.paymentMethodsSlice = payload!.data;
};

export const paymentMethodsRequestRejected = (state: WritableDraft<PaymentMethodsState>) => {
    state.paymentMethodsLoadingState = ApiState.Failed;
    state.paymentMethodsSlice = null;
};
