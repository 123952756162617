import { memo } from "react";
import AccountApiSettings from "./AccountApiSettings";
import AccountAdvancedSettings from "./AccountAdvancedSettings";
import type { MerchantDetailProps } from "../../types";
import { useMerchantAccountSettingsContext } from "../MerchantAccountSettingsProvider";
import MerchantAccountSettingsTab from "../MerchantAccountSettingsTab";

const ApiSettings = (props: MerchantDetailProps) => {
    const { selectAccountApiSettings } = useMerchantAccountSettingsContext();

    return (
        <MerchantAccountSettingsTab
            {...props}
            {...selectAccountApiSettings()}
        >
             <AccountApiSettings />
             <AccountAdvancedSettings />
        </MerchantAccountSettingsTab>
    );
};

export default memo(ApiSettings);
