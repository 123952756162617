import { useRef } from "react";
import { Consumer } from "features/consumers/types";
import { TabIndex, TabProps } from "../types";
import useFilableKYCServices from "./useFilableKYCServices";
import { KYCServiceType, KYCStatus } from "consts/kyc";
import type { FilableKYCServiceState, KYCEntity } from "features/kyc/types";
import { useSimpleRequest } from "hooks/useRequest";
import { deleteFile, postFile } from "features/general/api";
import { createKYCEntity, deleteKYCEntity } from "features/kyc/api";
import { NotificationSeverity, type FileEntity } from "features/general/types";
import { convertArrayToString, convertToFormData } from "util/transformers";
import useConsumerActions from "features/consumers/useActions";
import { useModal } from "ui/atoms/Modal";
import { getConsumerByEmailOrId } from "features/consumers/api";
import type { ResourceResponse } from "types";
import useGeneralActions from "features/general/useActions";
import { FORM_DATA_FILE_KEY } from "consts/forms";

type UseMarkAsKYCArg =
    & Pick<
        ReturnType<typeof useFilableKYCServices>,
        | 'servicesState'
        | 'onKYCServiceChange'
    >
    & TabProps<TabIndex.MarkAsKYCed>;

export default function useMarkAsKYC({
    servicesState,
    onKYCServiceChange,
    tabRegistry: [dialogDataRegistry, updateDialogDataRegistry],
    getTabPayload,
    dialogRequestProcessing: [, setIsRequestProcessing]
}: UseMarkAsKYCArg) {
    const dialogDataRegistryRef = useRef(dialogDataRegistry);

    const { open, onOpen, onClose } = useModal();

    const { updateConsumerById } = useConsumerActions();

    const { showNotication } = useGeneralActions();

    const request = useSimpleRequest();

    // Predicates
    const shouldDeleteKYCEntity = ([
        filableKycServiceState,
        kycEntity
    ]: [FilableKYCServiceState, KYCEntity]) => {
        const files = filableKycServiceState
            .files
            .filter(file => typeof file === 'string');

        if (kycEntity.fileKey && (files.length > 0)) {
            return !files.some(file => file === kycEntity.fileKey);
        }

        return Boolean(kycEntity.fileKey);
    };

    const shouldCreateKYCEntity = ({ checked }: FilableKYCServiceState) => Boolean(checked);

    const isFileAvailable = (filableKYCServiceState: FilableKYCServiceState) =>
        filableKYCServiceState.files.some(file => file instanceof File);

    const shouldCreateFilableKYCEntity = (filableKYCServiceState: FilableKYCServiceState) =>
        shouldCreateKYCEntity(filableKYCServiceState) &&
        isFileAvailable(filableKYCServiceState);

    const validateKYCService = (filableKYCServiceState: FilableKYCServiceState) => {
        if (
            filableKYCServiceState.checked &&
            (!filableKYCServiceState.files.length ||
                !filableKYCServiceState.country)
        ) {
            return false;
        }

        return true;
    };

    const withKYCServicesValidation = (fn: () => boolean) => {
        for (const [, filableKycServiceState] of Object.entries(servicesState)) {
            if (!validateKYCService(filableKycServiceState)) {
                return false;
            }
        }

        return fn();
    };

    const shouldMarkAsKYCed = () =>
        dialogDataRegistry
            .some(kycEntity =>
                shouldDeleteKYCEntity([servicesState[kycEntity.kycTypeId], kycEntity])
            ) ||
        Object.entries(servicesState)
            .some(([kycType, filableKycServiceState]) => {
                const kycTypeId = Number(kycType) as KYCServiceType;

                if (shouldCreateFilableKYCEntity(filableKycServiceState)) {
                    return true;
                }

                if (
                    !findByKYCTypeId(kycTypeId) &&
                    shouldCreateKYCEntity(filableKycServiceState)
                ) {
                    return true;
                }

                return false;
            });

    // Helpers
    const findByKYCTypeId = (kycTypeId: KYCServiceType) =>
        dialogDataRegistry.some(kycEntity => kycEntity.kycTypeId === kycTypeId);

    // Request handlers
    const createKYCEntityBaseRequest = async ([
        kycTypeId,
        filableKycServiceState,
        fileKey
    ]: [KYCServiceType, FilableKYCServiceState, string?],
        { onSuccess, onError }: Readonly<{
            onSuccess?: (response: ResourceResponse<KYCEntity>) => void;
            onError?: (response: ResourceResponse<KYCEntity>) => void;
        }> = {}) => {
        const { coreId } = getTabPayload<Consumer>();
        const { id, name } = filableKycServiceState.merchant ?? {};
        const { iso2 } = filableKycServiceState.country ?? {};
        const kycEntity = await request<KYCEntity>(() => createKYCEntity({
            consumerId: coreId,
            kycTypeId,
            fileKey: fileKey || null,
            reason: convertArrayToString([id, name]),
            countryIso2: iso2
        }), {
            onSuccess: resourceResponse => {
                const { data } = resourceResponse;

                let isKycEntityUpdated = false;
                dialogDataRegistryRef.current = dialogDataRegistryRef.current.map(kyc => {
                    if ((kyc.kycTypeId === kycTypeId) && kyc.fileKey) {
                        isKycEntityUpdated = true;
                        return {
                            ...kyc,
                            ...data
                        };
                    }

                    return kyc;
                });

                dialogDataRegistryRef.current = isKycEntityUpdated
                    ? dialogDataRegistryRef.current
                    : [...dialogDataRegistryRef.current, data];

                updateDialogDataRegistry([
                    ...dialogDataRegistryRef.current
                ]);

                updateConsumerById({
                    ...getTabPayload<Consumer>(),
                    kycStatus: KYCStatus.ManuallyPending
                });

                onSuccess?.(resourceResponse);
            },
            onError
        });

        return kycEntity;
    };

    const fetchConsumerEntity = () => request<Consumer>(() =>
        getConsumerByEmailOrId(getTabPayload<Consumer>().coreId), {
        onSuccess: ({ data }) => updateConsumerById(data)
    });

    const handleRemoveKYCEntities = () =>
        Promise.allSettled(
            dialogDataRegistryRef.current
                .filter(kycEntity =>
                    shouldDeleteKYCEntity([servicesState[kycEntity.kycTypeId], kycEntity])
                )
                .map(kycEntity => request(async () => {
                    if (kycEntity.fileKey) {
                        await deleteFile(kycEntity.fileKey);
                    }

                    await request(() => deleteKYCEntity(kycEntity.pki), {
                        onSuccess: () => {
                            dialogDataRegistryRef.current = dialogDataRegistryRef.current
                                .filter(registryKYCEntity => registryKYCEntity.pki !== kycEntity.pki);

                            updateDialogDataRegistry([
                                ...dialogDataRegistryRef.current
                            ]);
                        }
                    });

                    return new Response();
                }))
        );

    const handleCreateKYCEntities = () =>
        Promise.all(
            Object.entries(servicesState)
                .reduce((requests: Promise<Response>[], [kycType, filableKycServiceState]) => {
                    const kycTypeId = Number(kycType) as KYCServiceType;

                    if (shouldCreateFilableKYCEntity(filableKycServiceState)) {
                        const fileRequests = filableKycServiceState.files.reduce((requests: Promise<Response>[], uploadableFile) => {
                            if (uploadableFile instanceof File) {
                                return [
                                    ...requests,
                                    Promise.resolve().then(async () => {
                                        const fileResponse = await request<FileEntity>(() => postFile(
                                            convertToFormData<File>({
                                                [FORM_DATA_FILE_KEY]: uploadableFile
                                            })
                                        ));

                                        if (fileResponse.success) {
                                            await createKYCEntityBaseRequest([
                                                kycTypeId,
                                                filableKycServiceState,
                                                fileResponse.data.fileKey
                                            ], {
                                                onSuccess: () => {
                                                    onKYCServiceChange([
                                                        kycTypeId,
                                                        {
                                                            files: servicesState[kycTypeId]
                                                                .files
                                                                .map(file => {
                                                                    if ((file instanceof File) &&
                                                                        (file.name === uploadableFile.name)) {

                                                                        return fileResponse.data.fileKey;
                                                                    }

                                                                    return file;
                                                                })
                                                        }
                                                    ]);
                                                }
                                            });
                                        }

                                        return new Response();
                                    })
                                ] as Promise<Response>[];
                            }

                            return requests;
                        }, []);

                        return [
                            ...requests,
                            ...fileRequests
                        ];
                    }

                    if (
                        !findByKYCTypeId(kycTypeId) &&
                        shouldCreateKYCEntity(filableKycServiceState)
                    ) {
                        return [
                            ...requests,
                            createKYCEntityBaseRequest([
                                kycTypeId,
                                filableKycServiceState
                            ])
                                .then(() => new Response())
                        ];
                    }

                    return requests;
                }, [])
        );

    const onMarkAsKYCed = async () => {
        dialogDataRegistryRef.current = [
            ...dialogDataRegistry
        ];

        setIsRequestProcessing(true);

        let notification = {
            severity: NotificationSeverity.Error,
            message: 'Something went wrong. Please try again later'
        };

        try {
            await handleRemoveKYCEntities();

            notification = await handleCreateKYCEntities()
                .then(() => ({
                    severity: NotificationSeverity.Success,
                    message: 'All the documents were successfully uploaded'
                }));

            await fetchConsumerEntity();
        } finally {
            setIsRequestProcessing(false);
            showNotication(notification);
        }
    };

    const handleMarkAsKYCed = () => {
        if (isFileAvailable(servicesState[KYCServiceType.IDVerification])) {
            return onOpen();
        }

        return onMarkAsKYCed();
    };

    return {
        open,
        onOpen,
        onClose,
        onMarkAsKYCed,
        shouldMarkAsKYCed,
        handleMarkAsKYCed,
        withKYCServicesValidation
    };
};
