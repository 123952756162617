import { Filters } from "consts/transactions";
import useAuth from "features/auth/useAuth";
import { selectDomains } from "features/general/selectors";
import type { Setup } from "features/general/types";
import { useTypedSelector } from "hooks";
import { useSimpleRequest } from "hooks/useRequest";
import { useEffect, useRef, useState } from "react";
import type { BooleanSwitch, ResourceResponse } from "types";
import type { UseLabeledSwitchArg } from "ui/atoms/LabeledSwitch";
import { useUndoableDialog } from "ui/molecules/Dialog";
import { RequestQueryMapper } from "util/request-query-mapper";

type PickedSetup<
    TIdentifier extends string,
    TSetupValue = BooleanSwitch
> = Pick<
    Setup<
        TIdentifier,
        TSetupValue
    >,
    | 'MOR'
    | 'setupValue'
>;

type SetupMasterConnectionSwitch<
    TIdentifier extends string,
    TSetupValue = BooleanSwitch
> =
    | PickedSetup<TIdentifier, TSetupValue>
    | null
    | undefined;

export type UseMasterConnectionArg<
    TIdentifier extends string,
    TSetupValue = BooleanSwitch
> = {
    readonly getSetupsMasterConnectionSwitch: (queryString: string) => Promise<Response>;
    readonly updateSetupsMasterConnectionSwitch: (body: Pick<
        Setup<TIdentifier, TSetupValue>,
        | "MOR"
        | "setupValue"
        | "userId"
    >) => Promise<Response>;
    readonly processInitialDataResponse?: (setupPayload: ResourceResponse<
        Exclude<
            SetupMasterConnectionSwitch<
                TIdentifier,
                TSetupValue
            >,
            undefined
        >
    >) => (typeof setupPayload)['data'];
    readonly renderLabel?: (
        setupMasterConnectionSwitch?: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => UseLabeledSwitchArg['i18n'];
    readonly getSetupValue?: (
        setupMasterConnectionSwitch: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => TSetupValue;
    readonly getDefaultEnabled?: (
        setupMasterConnectionSwitch: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => boolean;
};

export default function useMasterConnection<
    TIdentifier extends string,
    TSetupValue = BooleanSwitch
>({
    getSetupsMasterConnectionSwitch,
    updateSetupsMasterConnectionSwitch,
    getSetupValue,
    getDefaultEnabled,
    processInitialDataResponse = ({ data }: ResourceResponse<
        Exclude<
            SetupMasterConnectionSwitch<
                TIdentifier,
                TSetupValue
            >,
            undefined
        >
    >) => data,
    renderLabel = () => ['Master Connection'],
}: UseMasterConnectionArg<TIdentifier, TSetupValue>) {
    const domains = useTypedSelector(selectDomains);
    const { MOR, identifier } = domains ?? {};

    const { user } = useAuth();

    const request = useSimpleRequest();

    const [
        isUpdateMasterConnectionRequestLoading,
        setUpdateMasterConnectionRequestLoading
    ] = useState<boolean>(false);
    const isRequestProcessingRef = useRef(false);
    const isComponentMountedRef = useRef(false);
    const [
        setupMasterConnectionSwitch,
        setSetupMasterConnectionSwitch
    ] = useState<SetupMasterConnectionSwitch<TIdentifier, TSetupValue>>();

    const getDefaultEnabledFallbackFn = (
        setupMasterConnectionSwitch: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => Boolean(Number(setupMasterConnectionSwitch?.setupValue));


    const getSetupValueFallbackFn = (
        setupMasterConnectionSwitch: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => Number(!getDefaultEnabledFallbackFn(setupMasterConnectionSwitch)) as TSetupValue;

    const isMasterConnectionEnabled = (
        setupMasterConnectionSwitch: SetupMasterConnectionSwitch<
            TIdentifier,
            TSetupValue
        >
    ) => (getDefaultEnabled ?? getDefaultEnabledFallbackFn)(setupMasterConnectionSwitch);

    const {
        open,
        onProceed,
        onCancel,
        getChecked,
        getLabel,
        onChange,
        setCheckedState
    } = useUndoableDialog({
        i18n: [renderLabel()],
        handleProcess: () => {
            if (!setupMasterConnectionSwitch) {
                return Promise.reject('setupMasterConnectionSwitch object is not defined');
            }

            const { MOR } = setupMasterConnectionSwitch;

            setUpdateMasterConnectionRequestLoading(true);

            return request(() => updateSetupsMasterConnectionSwitch({
                MOR,
                setupValue: (getSetupValue ?? getSetupValueFallbackFn)(setupMasterConnectionSwitch),
                userId: user.coreId
            }))
                .then(response => {
                    handleResponse(response);

                    return response;
                })
                .finally(() => {
                    setUpdateMasterConnectionRequestLoading(false);
                });
        },
        getDefaultChecked: () => isMasterConnectionEnabled(setupMasterConnectionSwitch)
    });

    const handleResponse = (response: ResourceResponse<
        Exclude<
            SetupMasterConnectionSwitch<
                TIdentifier,
                TSetupValue
            >,
            undefined
        >
    >) => {
        if (!isComponentMountedRef.current) {
            return;
        }

        const setup = processInitialDataResponse(response);

        setSetupMasterConnectionSwitch(setup);
        setCheckedState(
            isMasterConnectionEnabled(setup)
        );
    };

    const handlersRef = useRef({
        request,
        setCheckedState,
        handleResponse,
        getSetupsMasterConnectionSwitch
    });

    handlersRef.current = {
        request,
        setCheckedState,
        handleResponse,
        getSetupsMasterConnectionSwitch
    };

    useEffect(() => {
        isComponentMountedRef.current = true;

        if (!isRequestProcessingRef.current && [MOR, identifier].every(Boolean)) {
            isRequestProcessingRef.current = true;

            const { handleResponse, getSetupsMasterConnectionSwitch } = handlersRef.current;

            getSetupsMasterConnectionSwitch(
                RequestQueryMapper.from()
                    .containsIn(Filters.MOR, MOR)
                    .containsIn(Filters.identifier, identifier)
                    .toString()
            )
                .then(response => response.json())
                .then(handleResponse);
        }

        return () => {
            isComponentMountedRef.current = false;
        };
    }, [MOR, identifier]);

    return {
        isSwitchDisabled: typeof setupMasterConnectionSwitch === 'undefined',
        isUpdateMasterConnectionRequestLoading,
        setupMasterConnectionSwitch,
        open,
        onProceed,
        onCancel,
        getChecked,
        getLabel,
        onChange,
        isMasterConnectionEnabled
    };
};
