import { memo } from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { BasicDialog } from "ui/molecules/Dialog";
import useMultiTabDialog from "./useMultiTabDialog";
import Tabs from "ui/atoms/Tabs";
import MoreDetails from "./MoreDetails";
import ManageDuplicates from "./ManageDuplicates";
import MarkAsKYC from "./MarkAsKYC";
import useDialogDataRegistry from "./useDialogDataRegistry";
import { TabIndex } from "./types";

const MultiTabDialog = () => {
    const [
        dialogDataRegistry,
        updateDialogDataRegistry
    ] = useDialogDataRegistry();

    const {
        dialogRequestProcessing,
        isDialogOpen,
        getTabValue,
        getTabPayload,
        onTabChange,
        onCloseDialog
    } = useMultiTabDialog();

    const tabProps = {
        getTabPayload,
        dialogRequestProcessing
    };

    return (
        <BasicDialog
            isOpen={isDialogOpen}
            onClose={onCloseDialog}
            DialogProps={{
                sx: {
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 1200
                    }
                }
            }}
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    zIndex: 1
                }}
                onClick={onCloseDialog}
            >
                <CloseIcon />
            </IconButton>
            <Tabs
                TabProps={{
                    disabled: dialogRequestProcessing[0]
                }}
                model={[
                    {
                        label: 'User',
                        component: (
                            <MoreDetails
                                {...tabProps}
                                tabRegistry={[
                                    dialogDataRegistry[TabIndex.MoreDetails],
                                    updateDialogDataRegistry(TabIndex.MoreDetails)
                                ]}
                            />
                        )
                    },
                    {
                        label: 'KYC',
                        component: (
                            <MarkAsKYC
                                {...tabProps}
                                tabRegistry={[
                                    dialogDataRegistry[TabIndex.MarkAsKYCed],
                                    updateDialogDataRegistry(TabIndex.MarkAsKYCed)
                                ]}
                            />
                        )
                    },
                    {
                        label: 'Duplicates',
                        component: (
                            <ManageDuplicates
                                {...tabProps}
                                tabRegistry={[
                                    dialogDataRegistry[TabIndex.ManageDuplicates],
                                    updateDialogDataRegistry(TabIndex.ManageDuplicates)
                                ]}
                            />
                        )
                    }
                ]}
                value={getTabValue()}
                onChange={onTabChange}
            />
        </BasicDialog>
    );
};

export default memo(MultiTabDialog);
