import { Banking, Filters } from 'consts/transactions';
import type { Option } from "types";
import type {
    AutocompleteProps,
    AbstractFilterProps,
    Filter
} from 'ui/widgets/Filters';

export default function useFiltersVisibilityRules(filters: Filter<AbstractFilterProps>[]) {

    return ({ props }: Filter<AbstractFilterProps>) => {
        if (Banking.filters.includes(props.name)) {
            return bankingVisibilityPredicate(filters);
        }

        return true;
    };
};

function bankingVisibilityPredicate(filters: Filter<AbstractFilterProps>[]) {
    const selectedFilterValues = filtersSelector([Filters.PSP, Filters.methodId], filters)
        .reduce((valuesMap: Map<string, Array<Option>>, { props }) => {
            valuesMap.set(props.name, (props as AutocompleteProps).value);

            return valuesMap;
        }, new Map<string, Array<Option>>());

    for (const values of Array.from(selectedFilterValues.values())) {
        if (values.some(({ id }) => Banking.visibilityCriterias.includes(id))) {
            return true;
        }
    }

    return false;
}

function filtersSelector<T extends AbstractFilterProps>(selectFilters: Array<string>, filters: Filter<T>[]) {
    return filters.filter(({ props }) => selectFilters.includes(props.name));
}
