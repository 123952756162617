import { KYCServiceType } from "consts/kyc";
import { memo } from "react";
import type { SyntheticEvent } from "react";
import Box from '@mui/material/Box';
import { FilableKYCService, type FilableKYCServiceProps } from "ui/molecules/KYCService";
import useFilableKYCServices from "./useFilableKYCServices";
import { Consumer } from "features/consumers/types";
import { TabIndex, TabProps } from "../types";
import ActionPanel from "ui/organizms/Consumers/ConsumersDialogs/DialogActionPanel";
import useMarkAsKYC from "./useMarkAsKYC";
import MerchantSelector from "ui/widgets/MerchantSelector";
import type { Option } from "types";
import type { FileAreaProps } from "ui/molecules/KYCService/FilableKYCService/FileArea";
import IDVerificationConsumerDetails from "./IDVerificationConsumerDetails/IDVerificationConsumerDetails";
import { downloadKYCFile } from "features/kyc/api";
import type { Country } from "features/general/types";
import ConsumerCountrySelector from "./ConsumerCountrySelector";

const FilableKYCServices = ({ getTabPayload, ...restProps }: TabProps<TabIndex.MarkAsKYCed>) => {
    const {
        isServiceLoading,
        servicesState,
        onKYCServiceChange,
        getKYCServicesCollection
    } = useFilableKYCServices({
        ...restProps,
        getTabPayload
    });

    const {
        handleMarkAsKYCed,
        onMarkAsKYCed,
        shouldMarkAsKYCed,
        withKYCServicesValidation,
        ...consumerDetailsModalProps
    } = useMarkAsKYC({
        ...restProps,
        getTabPayload,
        servicesState,
        onKYCServiceChange
    });

    const { dialogRequestProcessing: [isMarkAsKYCedRequestProcessing] } = restProps;

    const getFileAreaProps = (kycServiceType: KYCServiceType, fileAreaPropsOverride?: FileAreaProps): FilableKYCServiceProps['FileAreaProps'] => ({
        files: servicesState[kycServiceType].files,
        accept: {
            'application/vnd.rar': ['.rar'],
            'application/zip': ['.zip']
        },
        multiple: false,
        renderDeleteFileAction: file =>
            file instanceof File
                ? undefined
                : true,
        downloadFile: fileKey => downloadKYCFile(fileKey, fileKey),
        onRemoveFile: (removeFile: string | File) => {
            onKYCServiceChange([
                kycServiceType,
                {
                    files: servicesState[kycServiceType]
                        .files
                        .filter(file => {
                            if (removeFile instanceof File) {
                                return removeFile.name !== (file as File).name;
                            }

                            return removeFile !== file;
                        })
                }
            ]);

            if (kycServiceType === KYCServiceType.IDVerification) {
                Object.keys(servicesState)
                    .forEach(key => {
                        const kycServiceTypeKey = Number(key) as KYCServiceType;

                        onKYCServiceChange([
                            kycServiceTypeKey,
                            {
                                files: servicesState[kycServiceTypeKey]
                                    .files
                                    .filter(file => !(file instanceof File))
                            }
                        ]);
                    });
            }
        },
        onDrop: acceptedFiles =>
            onKYCServiceChange([
                kycServiceType,
                {
                    files: [
                        ...acceptedFiles,
                        ...servicesState[kycServiceType].files
                            .filter(file => !(file instanceof File))
                    ].slice(0, 1)
                }
            ]),
        ...fileAreaPropsOverride
    });

    const withValidation = (kycServiceType: KYCServiceType): FilableKYCServiceProps['FileAreaProps'] => {
        const isFileAreaDisabled = (servicesState[KYCServiceType.IDVerification].files.length === 0) &&
            (kycServiceType !== KYCServiceType.IDVerification);

        const getTooltipMessage = () => {
            if (isFileAreaDisabled) {
                return 'Please upload the ID file first in order to enable this option';
            }

            if (servicesState[kycServiceType].files.some(file => typeof file === 'string')) {
                return 'Replacing the current file will lead to the permanent deletion of it';
            }

            return null;
        };


        return getFileAreaProps(kycServiceType, {
            disabled: isFileAreaDisabled,
            TooltipProps: {
                title: getTooltipMessage(),
                placement: 'top'
            }
        });
    };

    const getFilableKYCServices = () =>
        getKYCServicesCollection({
            getStatus: type => {
                const {
                    kycStatusFace,
                    kycStatusId,
                    kycStatusAml,
                    kycStatusAddress,
                    kycStatusPhone,
                    kycStatusEidv
                } = getTabPayload<Consumer>();

                return {
                    [KYCServiceType.FaceVerification]: kycStatusFace,
                    [KYCServiceType.IDVerification]: kycStatusId,
                    [KYCServiceType.AMLVerification]: kycStatusAml,
                    [KYCServiceType.AddressVerification]: kycStatusAddress,
                    [KYCServiceType.PhoneVerification]: kycStatusPhone,
                    [KYCServiceType.EIDVerification]: kycStatusEidv,
                }[type];
            },
            getProvider: type => {
                const {
                    kycStatusIdFrom,
                    kycStatusFaceFrom,
                    kycStatusAddressFrom,
                    kycStatusAmlFrom,
                    kycStatusPhoneFrom,
                    kycStatusEidvFrom
                } = getTabPayload<Consumer>();

                return {
                    [KYCServiceType.FaceVerification]: kycStatusFaceFrom,
                    [KYCServiceType.IDVerification]: kycStatusIdFrom,
                    [KYCServiceType.AMLVerification]: kycStatusAmlFrom,
                    [KYCServiceType.AddressVerification]: kycStatusAddressFrom,
                    [KYCServiceType.PhoneVerification]: kycStatusPhoneFrom,
                    [KYCServiceType.EIDVerification]: kycStatusEidvFrom
                }[type];
            }
        })
            .filter(({ type }) => ![
                KYCServiceType.AMLVerification,
                KYCServiceType.PhoneVerification
            ].includes(type));

    const handleSelectMerchant = (kycTypeId: KYCServiceType) =>
        (_: SyntheticEvent, merchant: Option) =>
            onKYCServiceChange([
                kycTypeId,
                { merchant }
            ]);

    const handleSelectCountry = (kycTypeId: KYCServiceType) =>
        (_: SyntheticEvent, country: Country | Country[]) =>
            onKYCServiceChange([
                kycTypeId,
                { country: country as Country }
            ]);

    const [
        faceKYCService,
        idKYCService,
        addressKYCService
    ] = getFilableKYCServices();

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: 1,
                    alignItems: 'flex-start'
                }}
            >
                <FilableKYCService
                    {...idKYCService}
                    value={servicesState[KYCServiceType.IDVerification]}
                    onChange={onKYCServiceChange}
                    FileAreaProps={withValidation(KYCServiceType.IDVerification)}
                    isServiceLoading={isServiceLoading}
                    detailsSlot={(
                        <>
                            <ConsumerCountrySelector
                                label="ID Verification"
                                value={servicesState[KYCServiceType.IDVerification].country}
                                onChange={handleSelectCountry(KYCServiceType.IDVerification)}
                            />
                            <MerchantSelector
                                value={servicesState[KYCServiceType.IDVerification].merchant}
                                onChange={handleSelectMerchant(KYCServiceType.IDVerification)}
                            />
                        </>
                    )}
                >
                    {idKYCService.name}
                </FilableKYCService>
                <FilableKYCService
                    {...faceKYCService}
                    value={servicesState[KYCServiceType.FaceVerification]}
                    onChange={onKYCServiceChange}
                    FileAreaProps={withValidation(KYCServiceType.FaceVerification)}
                    isServiceLoading={isServiceLoading}
                    detailsSlot={(
                        <>
                            <ConsumerCountrySelector
                                label="Face Verification"
                                value={servicesState[KYCServiceType.FaceVerification].country}
                                onChange={handleSelectCountry(KYCServiceType.FaceVerification)}
                            />
                            <MerchantSelector
                                value={servicesState[KYCServiceType.FaceVerification].merchant}
                                onChange={handleSelectMerchant(KYCServiceType.FaceVerification)}
                            />
                        </>
                    )}
                >
                    {faceKYCService.name}
                </FilableKYCService>
                <FilableKYCService
                    {...addressKYCService}
                    value={servicesState[KYCServiceType.AddressVerification]}
                    onChange={onKYCServiceChange}
                    FileAreaProps={withValidation(KYCServiceType.AddressVerification)}
                    isServiceLoading={isServiceLoading}
                    detailsSlot={(
                        <>
                            <ConsumerCountrySelector
                                label="Address Verification"
                                value={servicesState[KYCServiceType.AddressVerification].country}
                                onChange={handleSelectCountry(KYCServiceType.AddressVerification)}
                            />
                            <MerchantSelector
                                value={servicesState[KYCServiceType.AddressVerification].merchant}
                                onChange={handleSelectMerchant(KYCServiceType.AddressVerification)}
                            />
                        </>
                    )}
                >
                    {addressKYCService.name}
                </FilableKYCService>
            </Box>
            <ActionPanel
                loading={isMarkAsKYCedRequestProcessing}
                disabled={!withKYCServicesValidation(shouldMarkAsKYCed)}
                onClick={handleMarkAsKYCed}
            >
                Mark as KYC
            </ActionPanel>
            <IDVerificationConsumerDetails
                {...consumerDetailsModalProps}
                {...restProps}
                getTabPayload={getTabPayload}
                onMarkAsKYCed={onMarkAsKYCed}
            />
        </>
    );
};

export default memo(FilableKYCServices);
