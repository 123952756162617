import { FilterVariant } from "consts/filters";
import InvalidFilterTypeException from "../exceptions/InvalidFilterTypeException";
import InvalidFilterVariantException from "../exceptions/InvalidFilterVariantException";

export default function useFiltersVariants<TFilterType extends string>(
    filterTypes: Map<FilterVariant, TFilterType[]>
) {
    const getSpecificFiltersByFilterVariant = (filterVariant: FilterVariant) => {
        if (!filterTypes.has(filterVariant)) {
            throw new InvalidFilterVariantException(
                `Unknown filter variant ${filterVariant}`,
                {}
            );
        }

        return filterTypes.get(filterVariant)!;
    };

    const isFilterSpecificVariantInCollection = (
        filterName: TFilterType,
        specificFilterCollection: Array<TFilterType>
    ) => specificFilterCollection.includes(filterName);

    const getFilterVariantByFilterName = (filterName: TFilterType) => {
        for (const [filterVariant, filterTypeCollection] of Array.from(filterTypes)) {
            if (isFilterSpecificVariantInCollection(
                filterName,
                filterTypeCollection
            )) {
                return filterVariant;
            }
        }

        throw new InvalidFilterTypeException(
            `Unknown filter type ${filterName}`,
            {}
        );
    };

    const getAllFilterNames = () =>
        Array.from(filterTypes)
            .reduce((accFilterType: TFilterType[], [, filterTypeCollection]) =>
                [
                    ...accFilterType,
                    ...filterTypeCollection
                ], []);

    return {
        getSpecificFiltersByFilterVariant,
        isFilterSpecificVariantInCollection,
        getFilterVariantByFilterName,
        getAllFilterNames
    };
}
