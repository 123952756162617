import { memo } from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Image from 'lib/image';
import { Link } from 'react-router-dom';
import { WebRoutes } from "consts/enpoints/web";


const NotAuthorized = () => {


    return (
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
            <Typography
                variant="h3"
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                Sorry, you are not permitted to access this page
            </Typography>

            <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={'/assets/illustrations/illustration_empty_content.svg'}
                sx={{
                    maxWidth: 480,
                    alignSelf: 'center',
                    marginBottom: '60px'
                }}
            />
            <Button
                variant='contained'
                color="secondary"
                component={Link}
                to={WebRoutes.Root}
                sx={{
                    marginTop: '70px !important;',
                    py: 1.6,
                    width: '199px',
                    fontSize: '19px',
                    alignSelf: 'center'
                }}
            >
                Go Home
            </Button>
        </Stack>
    );
};

export default memo(NotAuthorized);