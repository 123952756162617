import { memo } from "react";
import {
    type GridRowParams,
    type GridRenderCellParams,
    GRID_ACTIONS_COLUMN_TYPE
} from "@mui/x-data-grid-premium";
import useMerchants from "./useMerchants";
import useMerchantUsers from "./MerchantUsers/useMerchantUsers";
import {
    selectIsMerchantsLoading,
    selectMerchantsSlice
} from "features/merchants/selectors";
import ResourceLayout from "ui/layouts/Resource";
import { ResourceProvider } from "ui/widgets/Resource";
import AddEntity from "./shared/AddEntity";
import StatefulTabs from "ui/widgets/StatefulTabs";
import Merchants from "./Merchants/Merchants";
import MerchantUsers, {
    MerchantUsersTableActions,
    MerchantUserStatus,
    MerchantUserToolbarExtended
} from "./MerchantUsers";
import StatisticCassettes from "./StatisticCassettes";
import { FilterVariant } from "consts/filters";
import { MerchantModeLive, MerchantStatus } from "./Merchants/RenderTableCells";
import * as users from "consts/users";
import * as merchants from "consts/merchants";

const MerchantsComponent = () => {
    const {
        disabled,
        getTabValue,
        onChangeTab
    } = useMerchants();

    const FiltersProps = {
        sx: {
            display: 'none'
        }
    };

    return (
        <ResourceLayout
            breadcrumbsProps={{
                heading: 'Merchants',
                links: [{ name: '' }],
                action: (
                    <AddEntity
                        variant='contained'
                        color='secondary'
                    />
                )
            }}
        >
            <StatisticCassettes />
            <StatefulTabs
                TabProps={{
                    disabled
                }}
                model={[
                    {
                        label: 'Merchants',
                        component: (
                            <Merchants
                                LayoutProps={{
                                    sx: {
                                        m: '24px 0px'
                                    }
                                }}
                                filterTypes={new Map<FilterVariant, string[]>([
                                    [FilterVariant.SingleOption, merchants.single],
                                ])}
                                FiltersProps={FiltersProps}
                                columns={[
                                    {
                                        field: merchants.Filters.isActive,
                                        headerName: 'Active',
                                        headerAlign: 'center',
                                        align: 'center',
                                        minWidth: 160,
                                        renderCell: (params: GridRenderCellParams) => (
                                            <MerchantStatus
                                                {...params}
                                            />
                                        )
                                    },
                                    {
                                        field: merchants.Filters.isLiveMode,
                                        headerName: 'Mode Live',
                                        headerAlign: 'center',
                                        align: 'center',
                                        minWidth: 160,
                                        renderCell: (params: GridRenderCellParams) => (
                                            <MerchantModeLive
                                                {...params}
                                            />
                                        )
                                    }
                                ]}
                            />
                        )
                    },
                    {
                        label: 'Merchant Users',
                        component: (
                            <MerchantUsers
                                useResource={useMerchantUsers}
                                selectIsTableUninitialized={() => true}
                                selectIsTableLoading={selectIsMerchantsLoading()}
                                selectTableDataSlice={selectMerchantsSlice()}
                                Toolbar={MerchantUserToolbarExtended}
                                LayoutProps={{
                                    sx: {
                                        m: '24px 0px'
                                    }
                                }}
                                pinnedColumns={{
                                    right: [GRID_ACTIONS_COLUMN_TYPE]
                                }}
                                columns={[
                                    {
                                        field: GRID_ACTIONS_COLUMN_TYPE,
                                        type: GRID_ACTIONS_COLUMN_TYPE,
                                        width: 50,
                                        maxWidth: 50,
                                        minWidth: 50,
                                        getActions: (params: GridRowParams) => [
                                            <MerchantUsersTableActions
                                                {...params}
                                            />
                                        ]
                                    },
                                    {
                                        field: users.Filters.status,
                                        headerName: 'Status',
                                        headerAlign: 'center',
                                        align: 'center',
                                        minWidth: 160,
                                        renderCell: (params: GridRenderCellParams) => (
                                            <MerchantUserStatus
                                                {...params}
                                            />
                                        )
                                    }
                                ]}
                            />
                        )
                    },
                ]}
                value={getTabValue()}
                onChange={onChangeTab}
                TabListContainerProps={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
                TabListProps={{
                    'aria-label': 'merchants',
                    variant: "scrollable",
                    scrollButtons: "auto"
                }}
                TabPanelProps={{
                    sx: {
                        padding: 0
                    }
                }}
                ContainerProps={{
                    pl: 0,
                    sx: {
                        width: '100%'
                    }
                }}
            />
        </ResourceLayout>
    );
};

export default memo(() => (
    <ResourceProvider>
        <MerchantsComponent />
    </ResourceProvider>
));
