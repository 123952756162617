import { memo } from 'react';
import Button from "ui/atoms/Button";
import useCreateTaxableEntityContext from './useCreateTaxableEntityContext';

const CreateTaxableEntityAction = () => {
    const { createTaxableActionLabel, onOpen, open } = useCreateTaxableEntityContext();

    return (
        <Button
            variant="contained"
            onClick={onOpen}
            disabled={open}
            sx={{ color: "white !important" }}
        >
            {createTaxableActionLabel}
        </Button>
    );
};

export default memo(CreateTaxableEntityAction);
