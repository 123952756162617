import type { MerchantsMethodsPivot } from "features/pivots/types";
import type { useForm } from "hooks";
import type { BootstrapableForm } from "types";

export const enum FormField {
    MinimumTicketSize = 'minTicketSize',
    MaximumTicketSize = 'maxTicketSize',
    MaxTransactionNumberWithin24HoursPerMerchant = 'maxNumberOfTransaction24',
    MaxTransactionNumberWithinWeekPerMerchant = 'maxNumberOfTransactionWeek',
    MaxTransactionNumberWithinMounthPerMerchant = 'maxNumberOfTransactionMonth',
    MaxTotalAmountWithinWeekPerMerchant = 'maxTotalAmountWeek',
    MaxTransactionNumberIn24HoursForSamePersonTag = 'maxNumberOfTransactionProfile24',
    AdjustMaxNumberOfTransactionProfile24ByConsumerRisk = 'adjustMaxNumberOfTransactionProfile24ByConsumerRisk',
    MaxTotalAmountIn24HoursForSamePersonTag = 'maxTotalAmountProfile24',
    AdjustMaxTotalAmountProfile24ByConsumerRisk = 'adjustMaxTotalAmountProfile24ByConsumerRisk',
    MaxTotalAmountForSameProfileTag = 'maxTotalAmountMonth',
    AdjustMaxTotalAmountMonthByConsumerRisk = 'adjustMaxTotalAmountMonthByConsumerRisk',
    VelocitySec = 'velocity',
    AdjustVelocityByConsumerRisk = 'adjustVelocityByConsumerRisk',
    EmailRiskLevel = 'maxRiskLevel',
    EnableUserVerificationPage = 'requireConsumerData',
    EnableMethodLimits = 'limitsEnabled'
};

export type PaymentMethodLimits = Partial<
    Pick<
        MerchantsMethodsPivot,
        | 'minTicketSize'
        | 'maxTicketSize'
        | 'maxNumberOfTransaction24'
        | 'maxNumberOfTransactionWeek'
        | 'maxNumberOfTransactionMonth'
        | 'maxTotalAmountWeek'
        | 'maxNumberOfTransactionProfile24'
        | 'adjustMaxNumberOfTransactionProfile24ByConsumerRisk'
        | 'maxTotalAmountProfile24'
        | 'adjustMaxTotalAmountProfile24ByConsumerRisk'
        | 'maxTotalAmountMonth'
        | 'adjustMaxTotalAmountMonthByConsumerRisk'
        | 'velocity'
        | 'adjustVelocityByConsumerRisk'
        | 'maxRiskLevel'
        | 'requireConsumerData'
        | 'limitsEnabled'
    >
>;

export type PaymentMethodLimitsForm = Pick<
    BootstrapableForm<PaymentMethodLimits, {}>,
    | 'initialValues'
    | 'onSaveOrCreate'
>;

export type PaymentMethodLimitsFormProps =
    & ReturnType<typeof useForm<PaymentMethodLimits>>
    & Omit<PaymentMethodLimitsForm, 'onSaveOrCreate'>;
