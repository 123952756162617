/*interface AbstractResponsePayload {
  readonly error?: string;
}

export const payloadCreator = <P, T, R extends AbstractResponsePayload>(
  apiService: (requestPayload: T) => Promise<Response>,
  applyTransformers?: (requestPayload: P) => T
) =>
  async (
    requestPayload: P,
    { rejectWithValue, fulfillWithValue }: {
      fulfillWithValue: (resolveWith: R) => void
      rejectWithValue: (rejectWith: Response) => void
    }) => {
    let transformedRequestPayload!: T;
    if (typeof applyTransformers === 'function') {
      transformedRequestPayload = applyTransformers(requestPayload);
    }

    const response = await apiService(
      (transformedRequestPayload ?? requestPayload) as T
    );

    if (response.ok) {
      const payload = await response.json();

      if (!payload.success) {
        return rejectWithValue(payload);
      }

      fulfillWithValue(payload);
      return payload;
    }

    return rejectWithValue(response.clone());
  };
*/

import { SortOrder } from "consts/request-query";
import type { ID } from "types";

export function concat<T>(elements: Array<T>, { token = '.' } = {}) {
  return elements.join(token);
}

export const stringifyUrlQueryParams = (urlQueryParams: URLSearchParams) => {
  const queryParams = urlQueryParams.toString();

  return queryParams ? `?${queryParams}` : '';
};

export function move<T>(
  array: Array<T>,
  from: number,
  to: number
) {
  return array.splice(to, 0, array.splice(from, 1)[0]);
}

export function swap<T>(array: Array<T>, from: number, to: number) {
  [array[from], array[to]] = [array[to], array[from]];
}

export const isEqual = (comparableA: unknown, comparableB: unknown) =>
  (JSON.stringify(comparableA) === JSON.stringify(comparableB));

export const route = (segments: Array<number | string | undefined>, joinToken = '/') => {
  const url = segments.filter(segment => typeof segment !== 'undefined')
    .join(joinToken);

  return url.startsWith(joinToken)
    ? url
    : `${joinToken}${url}`;
};

export const getSearchParams = (url = window.location.href) => new URL(url).searchParams;

export const getSearchParameterFromUrl = ({
  name = '',
  fallbackValue = '',
  url = window.location.href
}) => getSearchParams(url)
  .get(name) ?? fallbackValue;

export const stripExtension = (fileName: string) => fileName.split('.').slice(0, -1).join('.');

export const getFileExtension = (fileKey: string) => fileKey.split('.').pop();

export const generateFileName = ({
  prefix = '',
  fileKey = null,
  extension
}: Readonly<{
  prefix?: string,
  fileKey?: string | null,
  extension?: string
}>) => {
  const fileName = [prefix.replaceAll(' ', '_'), fileKey]
    .filter(Boolean)
    .join('_');

  return [extension ? stripExtension(fileName) : fileName, extension]
    .filter(Boolean)
    .join('.');
};

export const compareIds = (idA: ID, idB: ID) => String(idA) === String(idB);

export const randomColor = (seed = 16777215) => Math.floor(Math.random() * seed).toString(16);

export const range = (start: number, end: number, step: number) =>
  Array.from({
    length: (end - start) / step + 1
  }, (_, i) => i * step + start);

// TODO: Renalda please refactor this function
export const getEnvironment = () => {
  const host = window.location.hostname.includes("localhost")
    ? new URL(`${process.env.REACT_APP_BACKEND_ORIGIN_URL}`)?.hostname
    : window.location.hostname;

  let environment = "";
  if (host.includes("sandbox")) {
    environment = "Sandbox";
  } else if (host.includes("stg") || host.includes("stage")) {
    environment = "Stage";
  }

  return environment;
};

export const compare = (valueA: number | string, valueB: number | string, sortOrder: SortOrder) => {
  if (Object.is(valueA, valueB)) {
    return 0;
  }

  switch (true) {
    case typeof valueA === 'string': {
      return Object.is(sortOrder, SortOrder.Asc)
        ? String(valueA).localeCompare(String(valueB))
        : String(valueB).localeCompare(String(valueA));
    }

    case typeof valueA === 'number': {
      return Object.is(sortOrder, SortOrder.Desc)
        ? Number(valueB) - Number(valueA)
        : Number(valueA) - Number(valueB);
    }

    default: {
      return 0;
    }
  }
};
