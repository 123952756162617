import { selectMerchantOptions } from "features/merchants/selectors";
import { selectPaymentMethodsOptions } from "features/paymentMethods/selectors";
import { useTypedSelector } from "hooks";
import { selectMerchantsMethodsPivotSlice } from 'features/pivots/selectors';
import { Filters } from 'consts/merchantPayouts';
import usePivot from 'hooks/usePivot';

export default function useFilterModelOverrides() {
    const {
        [Filters.merchantId]: merchantId,
        [Filters.methodId]: methodId,
        onFilterChange,
        onFiltersReset
    } = usePivot({
        pivotSlice: useTypedSelector(selectMerchantsMethodsPivotSlice),
        relations: {
            [Filters.merchantId]: useTypedSelector(selectMerchantOptions),
            [Filters.methodId]: useTypedSelector(selectPaymentMethodsOptions)
        }
    });

    return {
        Merchants: merchantId,
        PaymentMethods: methodId,
        onFilterChange,
        onFiltersReset
    };
};
