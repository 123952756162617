import { memo } from 'react';
import { GridRowParams } from '@mui/x-data-grid-premium';
import useDeleteUser from './useDeleteUser';
import Typography from '@mui/material/Typography';

const DialogContent = (props: GridRowParams) => {
    const { renderLabel } = useDeleteUser(props);

    return (
        <>
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    fontSize: '24px'
                }}
            >
                Are you sure you want to delete user:
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontSize: '20px'
                }}
            >
                {renderLabel({})}
            </Typography>

        </>
    );
};

export default memo(DialogContent);
