import { Button } from "@mui/material";
import { memo } from "react";
import useGenerateFileContext from "./useGenerateFileContext";

const GenerateFileAction = () => {
    const { onOpen, open } = useGenerateFileContext();

    return (
        <Button
            variant="contained"
            onClick={onOpen}
            disabled={open}
            sx={{ color: 'white !important' }}
        >
            Generate
        </Button>
    );
};

export default memo(GenerateFileAction);
