import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
// components
import { useSettingsContext } from 'lib/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import useDashboardBootstrap from 'hooks/useDashboardBootstrap';
import DashboardRedirect from './DashboardRedirect';
import { useAppStore } from 'hooks';
import { selectIsNotMor } from 'features/auth/selectors';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const store = useAppStore();

  const { themeLayout } = useSettingsContext();

  useDashboardBootstrap();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      shouldRenderDocumentation={selectIsNotMor(store.getState())}
    />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
          <DashboardRedirect />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
            <DashboardRedirect />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
          <DashboardRedirect />
        </Main>
      </Box>
    </>
  );
}
