import {
    FormField as MerchantFormField,
    type Form
} from "../Merchant";
import type { Countries } from "features/general/types";
import type { Merchant } from "features/merchants/types";
import type { useForm } from "./useForm";
import type { Option } from "types";

export const FormField = {
    ...MerchantFormField,
    Currency: 'payoutCurrency',
    Groups: 'groups',
    Website: 'websiteUrl'
} as const;

export type MerchantCreate = Partial<
    Pick<
        Merchant,
        | 'fullAddress'
        | 'fullName'
        | 'cityAddress'
        | 'vatAddress'
        | 'stateAddress'
        | 'phone'
        | 'zipAddress'
        | 'defaultLanguage'
        | 'countryAddress'
        | 'groups'
        | 'websiteUrl'
        | 'payoutCurrency'
    >
>;

export type MerchantCreateForm<TForm = ReturnType<typeof useForm>> = Form<
    MerchantCreate,
    {
        readonly countries: Countries;
        readonly merchantGroupsOptions?: Option[];
    },
    TForm
>;
