import { memo, useEffect, useRef } from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import type { SelectProps } from "@mui/material/Select";
import { useTypedSelector } from 'hooks';
import { selectConstants } from 'features/general/selectors';
import { KYCProviders } from 'consts/kyc';
import {
    useKYCSettingsActionsContext,
    useKYCSettingsStateSelectorsContext
} from 'ui/organizms/Merchants/KYC/KYCSettingsProvider';

const KYCProvidersSelector = () => {
    const constants = useTypedSelector(selectConstants);

    const { updateKYCProvider } = useKYCSettingsActionsContext();
    const { selectKYCProvider } = useKYCSettingsStateSelectorsContext();

    const getKYCProviders = () => {
        if (constants.KycProviders?.length > 0) {
            return constants.KycProviders.filter((provider: string) =>
                provider !== KYCProviders.Manual);
        }

        return [];
    };

    const handlersRef = useRef({
        updateKYCProvider,
        getKYCProviders
    });

    handlersRef.current = {
        updateKYCProvider,
        getKYCProviders
    };

    useEffect(() => {
        const { updateKYCProvider, getKYCProviders } = handlersRef.current;

        updateKYCProvider(getKYCProviders()[0]);
    }, []);

    const onKYCProviderChange: SelectProps<string>['onChange'] = ({ target }) => {
        const value = target.value;

        updateKYCProvider(value);
    };

    if (getKYCProviders().length <= 1) {
        return null;
    }

    return (
        <Select
            value={selectKYCProvider()}
            onChange={onKYCProviderChange}
            size='small'
        >
            {getKYCProviders().map((provider: string) => (
                <MenuItem
                    key={provider}
                    value={provider}
                >
                    {provider}
                </MenuItem>
            ))}
        </Select>
    );
};

export default memo(KYCProvidersSelector);
