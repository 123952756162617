import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { ConfirmableAction } from "ui/molecules/Action";
import type { ConfirmatableDialogProps } from "ui/molecules/Dialog";

type Props =
    & LoadingButtonProps
    & Pick<ConfirmatableDialogProps, 'onConfirm'>
    & {
        readonly dialogContent: ConfirmatableDialogProps['content']['0']['dialogContent'];
    };

const OptionAction = ({ onConfirm, dialogContent, ...restButtonProps }: Props) => {
    const content = {
        dialogContent,
        actionContent: 'Yes'
    };

    return (
        <ConfirmableAction
            ActionProps={restButtonProps}
            DialogProps={{
                content: [
                    content,
                    content
                ],
                onConfirm,
                checkPredicate: () => false,
                onCancel: () => { },
                cancelActionContentSlot: 'No',
            }}
        />
    );
};

export default OptionAction;
