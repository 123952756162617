import { memo } from "react";
import FiltersActionable from "ui/layouts/FiltersActionable";
import { MergeFilesAction } from "./MergeFiles";
import { GenerateFileAction } from "./GenerateFile";

const FrontPanelWidget = () => (
    <FiltersActionable>
        <MergeFilesAction />
        <GenerateFileAction />
    </FiltersActionable>
);

export default memo(FrontPanelWidget);
