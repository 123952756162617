import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from 'lib/custom-avatar';
import { WebRoutes } from 'consts/enpoints/web';
import useAuth from 'features/auth/useAuth';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user, getUserName } = useAuth();

  return (
    <Link to={WebRoutes.Root} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar alt={getUserName()} name={getUserName()} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {getUserName()}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user.roleName}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
