import { AUTH_DATA_KEY } from "consts/general";
import { AuthData } from "features/auth/types";
import Storage from "util/storage";
import { ID, ResourceResponse } from "types";
import decodeToken from "util/jwt";
import type { JWTPayload } from "util/jwt";
import { ResponseCode } from "consts/api";
import { UserRolesNames } from "consts/auth";

interface UserPayload extends JWTPayload {
    readonly merchantIds: Array<ID>;
    readonly mors: Array<unknown>;
    readonly permissions: Array<unknown>;
    readonly userId: ID;
    readonly roleId: number;
}

export const getAuthData = () =>
    Storage.getItem<AuthData>(AUTH_DATA_KEY);

export const getAuthToken = () =>
    getAuthData()?.access_token;

export const getUserId = () => {
    const token = getAuthToken();

    if (!token) {
        return;
    }

    const payload = decodeToken(token) as UserPayload;

    return payload.userId;
};

export const setAuthData = (authData: AuthData) => {
    Storage.setItem(AUTH_DATA_KEY, authData);
};

export const revokeAuthData = () => {
    Storage.removeItem(AUTH_DATA_KEY);
};

export const isCodeIncludes = (code: number) =>
    [ResponseCode.Unathenticated].includes(code);

export const isResponseValid = (response: ResourceResponse<unknown>) =>
    !isCodeIncludes(response?.code);

export const isEmployee = (roleName: string) =>
    Object.is(roleName, UserRolesNames.Employee);

export const isMors = (roleName: string) =>
Object.is(roleName, UserRolesNames.Mors);

export const isMerchant = (roleName: string) => Boolean(
    roleName.includes(UserRolesNames.Merchant)
);
export const isMorsAgent = (roleName: string) => Boolean(
    roleName.includes(UserRolesNames.MorsAgent)
);
export const isMerchantsAgent = (roleName: string) => Boolean(
    roleName.includes(UserRolesNames.MerchantsAgent)
);

export const isAdmin = (roleName: string) => [
    UserRolesNames.RootAdmin,
    UserRolesNames.Admin
].includes(roleName as UserRolesNames);
