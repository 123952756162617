import { memo } from "react";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { StatusResourceTableCell } from "ui/organizms/Merchants/Merchants/shared/TableCells";
import { Status } from "consts/users";
import useUserProfile from "features/users/useUserProfile";
import useMerchantUserActions from "features/merchants/useMerchantUserActions";
import type { User } from "features/users/types";
import type { CoreBankingEntity } from "types";

const MerchantUserStatus = (props: GridRenderCellParams) => {
    const { updateOrAddMerchantUserById } = useMerchantUserActions();

    const {
        isLoading,
        createOrUpdateUser
    } = useUserProfile(props.row);

    const handleProcess = async (isChecked: boolean, { row }: GridRenderCellParams) =>
        createOrUpdateUser({
            coreId: row.coreId,
            status: isChecked
                ? Status.Active
                : Status.Inactive
        });

    const update = (user: CoreBankingEntity) => {
        updateOrAddMerchantUserById(user as User);
    };

    const getChecked = ({ value }: GridRenderCellParams) => Object.is(value, Status.Active);

    return (
        <StatusResourceTableCell
            {...props}
            isProcessing={isLoading}
            getChecked={getChecked}
            handleProcess={handleProcess}
            update={update}
        />
    );
};

export default memo(MerchantUserStatus);
