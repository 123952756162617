import { memo } from "react"
import type { WidgetProps } from "../types";

const Widgetable = ({ Component, ...restComponentProps }: WidgetProps) =>
    Component
        ? (
            <Component
                {...restComponentProps}
            />
        )
        : null

export default memo(Widgetable);
