import { useEffect } from "react";
import { fetchApi } from 'infrastructure/api';

const useProfilerTool = ({
    id = 'sfwdtd47bd0d',
    url = '/_wdt/47bd0d',
    filePath = '/profiler-tool.js'
} = {}) => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            return;
        }

        fetchApi(url)
            .then(async markupResponse => {
                const profiler = document.getElementById(id);
                if (profiler && markupResponse.ok) {
                    profiler.innerHTML = `${await markupResponse.text()}`;

                    const profilerScriptId = `${id}_script`;
                    if (document.getElementById(profilerScriptId)) {
                        return;
                    }

                    const profilerScript = document.createElement('script');
                    profilerScript.id = profilerScriptId;
                    profilerScript.src = `${process.env.PUBLIC_URL}${filePath}`;
                    profilerScript.onload = () => {
                        (window as any).Sfjs.loadToolbar('e5a1d8');
                    };

                    document.head.append(profilerScript);
                }
            });
    }, [filePath, id, url]);
};

export default useProfilerTool;
