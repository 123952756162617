export const enum Period {
    Hour = 'Hour',
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
};

export const groupMaxThresholdDiffMap = new Map<Period, number>([
    [Period.Hour, 24],
    [Period.Day, 62],
    [Period.Week, 13],
    [Period.Month, 24],
    [Period.Year, Number.POSITIVE_INFINITY]
]);
