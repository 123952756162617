import { FilterVariant, MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { paginate, sort, summsQueryField, visible } from "consts/table";
import { splitWithModifier } from "util/filters";
import type { ColumnVisibilityFilterType, PaginatableFilterType, SortableFilterType } from "./types";

export function isColumnVisibility(columnVisibilityQueryName: ColumnVisibilityFilterType) {
    return visible.includes(columnVisibilityQueryName);
}

export function isSortable(sortQueryName: SortableFilterType) {
    return sort.includes(sortQueryName);
}

export function isPaginatable(paginatableQueryName: PaginatableFilterType) {
    return paginate.includes(paginatableQueryName);
}

export function isExclusiveFilterVariant(filterVariant: FilterVariant) {
    return [
        FilterVariant.DateRange
    ].includes(filterVariant);
}

export function isEqualFilterVariant(filterVariant: FilterVariant) {
    return [
        FilterVariant.MultiOption,
        FilterVariant.SingleOption,
        FilterVariant.Text
    ].includes(filterVariant);
}

export function isExclusiveMulticolumn(filterName: string) {
    return splitWithModifier(filterName, MULTI_COLUMN_SEPARATOR).length > 1;
}

export function isSummableFilterVariant(filterName: string) {
    return Object.is(filterName, summsQueryField);
}
