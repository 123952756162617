import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { getMerchantsMethodsPivot } from "./api";
import {
    changeMerchantsMethodsPivotLoadingState,
    changeMerchantsMethodsPivotSlice
} from "./slice";
import { PER_PAGE_SIZE } from "consts/transactions";

const useActions = () => ({
    getAllMerchantsMethods: useFetchFeatureResource({
        resourceRequest: getMerchantsMethodsPivot,
        changeLoadingState: changeMerchantsMethodsPivotLoadingState,
        changeSlice: changeMerchantsMethodsPivotSlice,
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE
    })
});

export default useActions;
