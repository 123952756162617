import Box from '@mui/material/Box';
import {
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { FunctionComponent, useCallback } from "react";
import GridToolbarColumnsButton from "./GridToolbarColumnsButton";

export type ToolbarElements = 'columns' | 'density' | 'export' | 'add' | 'search';

type GetToolbarElementsArgs = {
    readonly omit: Array<ToolbarElements>;
};

const toolbarComponentsRepository = new Map<ToolbarElements, FunctionComponent>()
    .set('columns', GridToolbarColumnsButton)
    .set('density', GridToolbarDensitySelector)
    .set('export', GridToolbarExport)
    .set('add', Box)
    .set('search', GridToolbarQuickFilter);

export default function useToolbar() {
    const getToolbarElements = useCallback(({ omit }: GetToolbarElementsArgs) =>
        Array.from(toolbarComponentsRepository.keys())
            .filter(toolbarElement => !omit.includes(toolbarElement))
        , []);

    return {
        getToolbarElements,
        toolbarComponentsRepository
    };
};
