import transactionTable from 'config/transactionTable.json';

import { TransactionMode } from 'features/transactions/types';
import { generalTableParser } from 'util/parsers';

const { columns: trnasactionTableColumns, meta } = transactionTable;

export default function transactionTableParser(mode: TransactionMode) {
    const columns = (trnasactionTableColumns as any)[mode];

    return generalTableParser(columns);
};

export const metaParser = (fieldName: string, value: string, criteria: 'renderer') => {
    const field = (meta as any)[fieldName];
    const fieldCriteria = field[criteria];

    return new Map()
        .set('renderer', renderCriteria)
        .get(criteria)?.(fieldName)?.(value, fieldCriteria);
};

function renderCriteria(fieldName: string) {
    return new Map()
        .set('countryIso2', (value: string, { img }: CountryIso2Renderer) => {
            const { resource, ext, srcSet, src, ...props } = img;
            const getResourceUrl = (src: string, appendWith = '') =>
                `${resource}/${src}/${value.toLocaleLowerCase()}.${ext} ${appendWith}`.trim();

            return {
                ...props,
                src: getResourceUrl(src),
                srcSet: srcSet.map(({ src, d }) => getResourceUrl(src, d))
            };
        })
        .get(fieldName);
}

type CountryIso2Renderer = {
    readonly img: {
        readonly resource: string;
        readonly src: string;
        readonly ext: string;
        readonly srcSet: Array<{
            readonly d: string;
            readonly src: string;
        }>
    }
};
