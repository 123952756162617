import type { GridCellParams } from '@mui/x-data-grid-premium';
import type { SummaryFiles } from 'features/merchantPayouts/types';
import { memo } from 'react';
import ActionMenu from 'ui/organizms/MerchantPayouts/ActionMenu';
import useSummaryFilesActions from './useSummaryFilesActions';

const Actions = ({ row }: GridCellParams<SummaryFiles>) => {
    const { getMenuItems, getRequestMappings } = useSummaryFilesActions();

    return (
        <ActionMenu
            row={row}
            getMenuItems={getMenuItems}
            requestMappings={getRequestMappings()}
        />
    );
};

export default memo(Actions);