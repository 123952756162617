import type { Files } from "features/merchantPayouts/types";
import Box from "@mui/material/Box";
import { memo } from "react";
import useManageFileTaxes from "./useManageFileTaxes";

type Props = {
  readonly file: Files;
};

const FileSummary = ({ file }: Props) => {
  const { getViaStatus } = useManageFileTaxes();

  return (
    <Box>
      You are viewing the taxes applied for {file.merchantName} via {file.MOR} - {getViaStatus(file.via)} on {file.currency} currency between {file.dateFrom} and {file.dateTo}.
    </Box>
  );
};

export default memo(FileSummary);
