import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { NotificationSeverity } from 'features/general/types';
import { memo, forwardRef, FC, PropsWithChildren } from "react";

const Alert = forwardRef<HTMLDivElement, AlertProps>((
    props,
    ref,
) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
    readonly snackbarProps: SnackbarProps;
    readonly alertProps: AlertProps;
};

const snackbarPropsDefaultMixin = {
    autoHideDuration: 6000
};

const alertPropsDefaultMixin = {
    severity: NotificationSeverity.Info
};

const Notificatior: FC<PropsWithChildren<Props>> = ({ children, snackbarProps, alertProps }) => (
    <Snackbar {...snackbarPropsDefaultMixin} {...snackbarProps}>
        <Alert {...alertPropsDefaultMixin} {...alertProps}>
            {children}
        </Alert>
    </Snackbar>
);

Notificatior.defaultProps = {
    snackbarProps: {},
    alertProps: {}
};

export default memo(Notificatior);
