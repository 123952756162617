import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import type { Transaction } from "features/transactions/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { memo } from 'react';
import useFakeRedirect from "./useFakeRedirect";

type Props = LoadingButtonProps & Pick<Transaction, 'createdAt' | 'via'>;

const FakeRedirectAction = ({ createdAt, via, ...restProps }: Props) => {
    const { checkIfFakeRedirect } = useFakeRedirect({ createdAt, via });

    return (
        <>
            {checkIfFakeRedirect() && (
                <LoadingButton
                    {...restProps}
                >
                    PoD
                </LoadingButton>
            )}
        </>
    );
};

export default memo(FakeRedirectAction);
