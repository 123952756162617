import { memo } from "react";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { FormableTodo, type FormableTodoProps } from "ui/widgets/TodoWidget";

const AddEmail = (props: FormableTodoProps) => (
    <FormableTodo
        {...props}
        TextFieldProps={{
            label: 'Add Email address',
            fullWidth: true
        }}
        ActionProps={{
            variant: 'contained',
            startIcon: (
                <AttachEmailIcon />
            )
        }}
    />
);

export default memo(AddEmail);
