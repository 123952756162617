import { memo } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import type { SingleFileProps } from "./types";
import Action from "./Action";

const Single = ({ title, value, downloadFile }: SingleFileProps) => (
    <Tooltip
        title={title}
        placement='top'
        arrow
    >
        <Box
            sx={{
                display: 'grid',
                placeContent: 'center',
                cursor: title && value
                    ? 'pointer'
                    : 'default'
            }}
            component='span'
        >
            {value
                ? (
                   <Action
                        value={value}
                        downloadFile={downloadFile}
                   />
                )
                : '-'}
        </Box>
    </Tooltip>
);

export default memo(Single);
