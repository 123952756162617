import type { MerchantSettings } from "features/merchants/types";
import { stateFactory } from "./state";
import {
    MerchantDetailActionType,
    type MerchantDetailRepositoryAction,
    type MerchantDetailRepositoryState,
} from "./types";

export function reducer(state: MerchantDetailRepositoryState, action: MerchantDetailRepositoryAction): MerchantDetailRepositoryState {
    switch (action.type) {
        case MerchantDetailActionType.FetchMerchantSettings: {
            return {
                ...state,
                merchantSettings: {
                    ...state.merchantSettings,
                    ...action.payload
                }
            };
        }

        case MerchantDetailActionType.UpdateMerchantSettings: {
            return {
                ...state,
                merchantSettings: {
                    ...state.merchantSettings,
                    data: {
                        ...state.merchantSettings.data,
                        ...action.payload
                    } as MerchantSettings
                }
            };
        }

        case MerchantDetailActionType.FetchMerchantUsers: {
            return {
                ...state,
                merchantUsers: {
                    ...state.merchantUsers,
                    ...action.payload
                }
            };
        }

        case MerchantDetailActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
}

export default reducer;
