import type { Transaction } from "features/transactions/types";


// import { isBefore } from "date-fns";

export default function useRefundActions({status, fundStatus, paymentMethod }: Pick<Transaction, "status" | "fundStatus" | "paymentMethod">) {
  const checkIfRefundAvailable = () => {
    // status should be SUCCEEDED and fundStatus RECEIVED - this should not be available for OPEN BANKING transactions
return (status === 'SUCCEEDED' && fundStatus === "RECEIVED" && paymentMethod !== "Open Banking")
   
  };

  return {
    checkIfRefundAvailable,
  };
}