import { memo, useEffect, useRef, useState, type ChangeEvent } from "react";
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextFieldLoadable from "./TextFieldLoadable";
import type { TextFieldLoadableProps } from "./types";
import type { Option } from "types";
import useTextField from "./useTextField";

type Props = TextFieldLoadableProps & {
    readonly separator?: string;
    readonly fallbackProtocol?: string;
    readonly protocols?: Option[];
};

const UrlTextField = ({
    value,
    separator = '://',
    fallbackProtocol = 'https',
    protocols = [{
        id: 'http',
        name: 'http'
    },
    {
        id: 'https',
        name: 'https'
    }],
    ...props
}: Props) => {
    const textFieldHandlers = useTextField(props);

    const [protocol, ...rest] = String(value).split(separator);
    const domain = rest.at(-1) || '';

    const getNormalizeDomain = (domain: string) => (
        domain.trim()
    );
    const getProtocol = (proto: string) => proto || fallbackProtocol;
    const joinProtocol = (proto: string, domain: string) => (
        getNormalizeDomain(domain).length
            ? [proto, domain].join(separator)
            : domain
    );
    const helpers = {
        getProtocol
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    const [selectedProtocol, selectProtocol] = useState<string>(() => getProtocol(protocol));

    const handleTextChange: TextFieldLoadableProps['onChange'] = event => {
        const newValue = event.target.value;

        event.target.value = joinProtocol(getProtocol(selectedProtocol), newValue);

        textFieldHandlers.onChange(event);
    };

    const handleProtocolChange = (event: SelectChangeEvent) => {
        selectProtocol(event.target.value);

        const castEvent = event as ChangeEvent<HTMLInputElement>;

        castEvent.target.value = joinProtocol(event.target.value, getNormalizeDomain(domain));
        castEvent.target.name = props.name || '';
        castEvent.target.id = props.id || '';

        props.onChange?.(castEvent);
    };

    useEffect(() => {
        const { getProtocol } = helpersRef.current;

        selectProtocol(
            getProtocol(protocol)
        );
    }, [protocol]);

    return (
        <TextFieldLoadable
            {...props}
            value={domain}
            onChange={handleTextChange}
            InputProps={{
                startAdornment: (
                    <Select
                        value={selectedProtocol}
                        onChange={handleProtocolChange}
                        IconComponent={() => null}
                        sx={{
                            '*': {
                                border: 'none'
                            },
                            '& .MuiSelect-select': {
                                px: '5px !important'
                            }
                        }}
                    >
                        {protocols.map(({ id, name }) => (
                            <MenuItem
                                key={id}
                                value={id}
                            >
                                {name}{separator}
                            </MenuItem>
                        ))}
                    </Select>
                ),
                ...props.InputProps,
            }}
        />
    );
};

export default memo(UrlTextField);
