import { memo, FC } from "react";
import Box from '@mui/material/Box';
import Button from 'ui/atoms/Button';
import Modal from "ui/atoms/Modal";
import ImportResult from "./ImportResult";
import type { Settings } from "./Props";
import Legend from "./Legend";
import useImportCSV from "./useImportCSV";

type Props = Omit<ReturnType<typeof useImportCSV>, 'onImportCSV'> & {
    readonly settings?: Settings;
};

const ImportCSV: FC<Props> = ({ open, result, onClose, settings }) => (
    <Modal
        isOpen={open}
        onClose={onClose}
        DialogProps={{
            sx: {
                '& .MuiDialog-container .MuiPaper-root': {
                    maxWidth: '1287px'
                }
            }
        }}
    >
        <Box
            sx={{
                py: 2,
                px: 4
            }}
        >
            <Box
                sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '32px',
                    marginBottom: '1rem'
                }}
            >
                CSV uploaded succesfully
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '1rem'
                }}
            >
                <Box>Preview your import before closing</Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, auto)',
                        gap: '16px',
                    }}
                >
                    <Legend
                        label='Suceeded'
                        bgColor={theme => theme.palette.success.main}
                    />
                    <Legend
                        label='Failed'
                        bgColor={theme => theme.palette.error.main}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    height: 'calc(100vh - 300px)',
                    '& .grid-import-bulk--error': {
                        backgroundColor: theme => theme.palette.error.main
                    },
                    '& .grid-import-bulk--success': {
                        backgroundColor: theme => theme.palette.success.main
                    }
                }}
            >
                <ImportResult
                    {...settings!}
                    data={result}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '1rem'
                }}
            >
                <Button disabled>
                    Failed imports report
                </Button>
                <Button
                    onClick={onClose}
                >
                    Close
                </Button>
            </Box>
        </Box>
    </Modal>
);

ImportCSV.defaultProps = {
    settings: {
        failedClassName: 'grid-import-bulk--error',
        successClassName: 'grid-import-bulk--success',
        errorColumnField: 'id'
    }
};

export default memo(ImportCSV);
