import { memo } from "react";
import Skeleton from '@mui/material/Skeleton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useIncludeMethodsContext from "./useIncludeMethodsContext";

const PaymentMethods = () => {
    const {
        isLoading,
        isMethodsAvailable,
        merchantMethodsPivot,
        handleChange,
        handleProcess,
        getActiveMerchantPaymentMethodIds,
        getIsMethodProcessing
    } = useIncludeMethodsContext();

    if (!isLoading && !isMethodsAvailable) {
        return (
            <Box
                sx={{
                    display: 'grid',
                    placeContent: 'center',
                    height: '100px'
                }}
            >
                No methods available
            </Box>
        );
    }


    return (
        <ToggleButtonGroup
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                p: '5px'
            }}
            value={getActiveMerchantPaymentMethodIds()}
            onChange={handleChange}
            disabled={isLoading}
            aria-label="payment methods"
        >
            {isLoading && (
                Array.from({ length: 20 }).map((_, index) => (
                    <ToggleButton
                        key={index}
                        value={index}
                        sx={{
                            height: 30,
                            px: 0
                        }}
                    >
                        <Skeleton
                            variant="rounded"
                            width={100}
                            height={30}
                        />
                    </ToggleButton>
                ))
            )}
            {merchantMethodsPivot.map(({ coreId, paymentMethodName, shouldKyc }) => (
                <ToggleButton
                    key={coreId}
                    sx={{
                        height: 30,
                        bgcolor: 'divider',
                        '&:first-of-type': {
                            ml: 0
                        },
                        '&.Mui-selected': {
                            bgcolor: 'primary.main',
                            color: 'white',
                            ':hover': {
                                bgcolor: 'primary.main'
                            }
                        }
                    }}
                    value={coreId}
                    aria-label={paymentMethodName}
                    disabled={getIsMethodProcessing(coreId)}
                    onClick={() => handleProcess({
                        coreId,
                        shouldKyc
                    })}
                >
                    <Box
                        sx={{
                            position: 'relative'
                        }}
                    >
                        {getIsMethodProcessing(coreId) && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    inset: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CircularProgress
                                    color='inherit'
                                    size={20}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                visibility: getIsMethodProcessing(coreId)
                                    ? 'hidden'
                                    : 'visible'
                            }}
                        >
                            {paymentMethodName}
                        </Box>
                    </Box>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default memo(PaymentMethods);
