import { memo } from 'react';
import Box from '@mui/material/Box';
import OptionAction from './OptionAction';
import type { OptionActionProps } from './types';

type Props = OptionActionProps<'removeDuplicate'>;

const RemoveDuplicateAction = ({
    duplicatedEmailRegistryEntry,
    removeDuplicate,
    ...restButtonProps
}: Props) => (
    <OptionAction
        {...restButtonProps}
        dialogContent={[
            <Box>
                Are you sure you want to <strong>remove duplicate</strong>
                &nbsp;
                <Box
                    component='span'
                    sx={{
                        fontWeight: 'bold',
                        color: 'primary.main'
                    }}
                >
                    {duplicatedEmailRegistryEntry.email}
                </Box>?
            </Box>
        ]}
        onConfirm={removeDuplicate}
    >
        Remove Duplicate
    </OptionAction>
);

export default memo(RemoveDuplicateAction);
