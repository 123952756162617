// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from 'lib/logo';
import Image from 'lib/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

type Props = {
    title?: string;
    illustration?: string;
    children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
    return (
        <>
            <StyledRoot>
                <Logo
                    brandingImage
                    sx={{
                        zIndex: 9,
                        position: 'absolute',
                        mt: { xs: 1.5, md: 5 },
                        ml: { xs: 2, md: 5 },
                    }}
                />

                <StyledSection>
                    <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
                        {title || 'Hi, Welcome back'}
                    </Typography>

                    <Image
                        disabledEffect
                        visibleByDefault
                        alt="auth"
                        src={illustration || '/assets/illustrations/illustration_dashboard.png'}
                        sx={{ maxWidth: 720 }}
                    />

                    <StyledSectionBg />
                </StyledSection>

                <StyledContent>
                    <Stack sx={{ width: 1 }}>
                        {children}
                    </Stack>
                </StyledContent>
            </StyledRoot>
        </>
    );
};
