// !!!!!!!!!!!!!! TODO: this file deprecated, please use renderers from src\ui\widgets\Table\renderers
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import Icon from "ui/atoms/Icon";
import { FunctionComponent, useState } from "react";
import { metaParser } from "./transactionTableConfigTransformer";
import type { GridRowModel, GridValueFormatterParams } from "@mui/x-data-grid";
import type { FundStatus, SettlementStatus, Status } from "types";
import { selectMerchantPayoutIncrementsVia } from "features/general/selectors";
import { useTypedSelector } from "hooks";
import {  getNullTextMapper } from "./formatters";

const withRendererFactory = <T extends BaseStatusProps>(Component: FunctionComponent<any>) =>
    (props: T) =>
    (
        <Component
            {...props}
        />
    );

export type BaseStatusProps = {
    readonly value: string;
};

interface CountryISOProps extends BaseStatusProps {
}

export const countryISO = withRendererFactory(({ value }: CountryISOProps) => {
    if (value) {
        return (
            <Box display='flex' columnGap={2}>
                <img
                    {...metaParser('countryIso2', value, 'renderer')}
                    loading="lazy"
                    width="20"
                    alt={value}
                />
                <span>{value}</span>
            </Box>
        );
    }
    return null;
});

interface TransactionFundStatusProps extends BaseStatusProps {
    readonly value: FundStatus;
}

export const transactionFundStatus = withRendererFactory(({ value }: TransactionFundStatusProps) => {
    const statuses = {
        'NOT_EXPECTED': {
            icon: <Icon name="NotInterested" />,
            color: 'primary'
        },
        'WAITING': {
            icon: <Icon name="HourglassEmpty" />,
            color: 'warning'
        },
        'RECEIVED': {
            icon: <Icon name="KeyboardDoubleArrowDown" />,
            color: 'success'
        },
        'SENT': {
            icon: <Icon name="Outbox" />,
            color: 'info'
        },
        'MISSING': {
            icon: <Icon name="CallMissed" />,
            color: 'error'
        },
        'REFUNDED': {
            icon: <Icon name="LocalAtm" />,
            color: 'secondary'
        }
    };

    if (!isStatusValid(statuses, value)) {
        return null;
    }

    const { icon, color } = statuses[value];

    return (
        <Chip
            size='small'
            icon={icon}
            label={value}
            color={color as any}
        />
    );
});

interface TransactionStatusProps extends BaseStatusProps {
    readonly value: Status;
}

export const transactionStatus = withRendererFactory(({ value }: TransactionStatusProps) => {
    const statuses = {
        'PENDING': {
            icon: <Icon name="HourglassEmpty" />,
            color: 'warning'
        },
        'FAILED': {
            icon: <Icon name="SmsFailed" />,
            color: 'error'
        },
        'REJECT': {
            icon: <Icon name="ThumbDownOffAlt" />,
            color: 'secondary'
        },
        'SUCCEEDED': {
            icon: <Icon name="CheckCircleOutline" />,
            color: 'success'
        },
        'REFUNDED': {
            icon: <Icon name="LocalAtm" />,
            color: 'primary'
        },
        'CHARGE_BACK': {
            icon: <Icon name="CurrencyExchange" />,
            color: 'info'
        }
    };

    if (!isStatusValid(statuses, value)) {
        return null;
    }

    const { icon, color } = statuses[value];

    return (
        <Chip
            size='small'
            icon={icon}
            label={value}
            color={color as any}
        />
    );
});

interface SettlementStatusProps extends BaseStatusProps {
    readonly value: SettlementStatus;
}

export const settlementStatus = withRendererFactory(({ value }: SettlementStatusProps) => {
    const statuses = {
        'SUCCEEDED': {
            icon: <Icon name="CheckCircleOutline" />,
            color: 'success'
        },
        'FUNDSMISSING': {
            icon: <Icon name="SmsFailed" />,
            color: 'error'
        },
        'FUNDSRECEIVED': {
            icon: <Icon name="ThumbUp" />,
            color: 'secondary'
        },
        'FUNDSREFUNDED': {
            icon: <Icon name="LocalAtm" />,
            color: 'warning'
        },
        'FUNDSSENT': {
            icon: <Icon name="Outbox" />,
            color: 'info'
        }
    };

    if (!isStatusValid(statuses, value)) {
        return null;
    }

    const { icon, color } = statuses[value];

    return (
        <Chip
            size='small'
            icon={icon}
            label={value}
            color={color as any}
        />
    );
});

interface BooleanStatusProps {
    readonly value: boolean;
}
export const booleanStatus = withRendererFactory(({ value }: BooleanStatusProps) => {
    if (value) {
        return (
            <Icon
                name="Check"
                color='success'
            />
        );
    }

    return (
        <Icon
            name='Close'
            color='error'
        />
    );
});

export const merchantsActiveSwitch = withRendererFactory(({ value }: GridRowModel) => {
    const [isActive, setActiveState] = useState(Boolean(value));

    const onChange = () => {
        setActiveState(state => !state);
    };

    return (
        <Switch
            checked={isActive}
            onChange={onChange}
        />
    );
});

export const genericStatus = withRendererFactory(({ value }) => (
    <Chip
        variant='outlined'
        label={value}
        color='success'
    />
));

export const viaStatus = withRendererFactory(({ value }) => {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);

    return (
        <>
            {merchantPayoutIncrementsVia.find(({ id }) => Object.is(id, value))?.name ?? '-'}
        </>
    );
});

export const nullValue = withRendererFactory((params: GridValueFormatterParams<string>) => {
    const { field, value } = params;

    return value === null ? (
      <Box
        sx={{
          color: "grey.500",
        }}
      >
        {getNullTextMapper(field)}
      </Box>
    ) : (
      <Box>{value}</Box>
    );
})

// export const transactionErrorMessage = withRendererFactory(({ value }: any) => {
//     console.log('value => ', value);
//     if (value?.ResultCdcData?.ERRMSG) {
//         return (
//             <div>
//                 {value.ResultCdcData.ERRMSG}
//             </div>
//         );
//     }

//     return null;
// });

function isStatusValid<T extends object>(statuses: T, value: BaseStatusProps['value']) {
    return Object.keys(statuses).includes(value);
}
