import { FilterVariant } from "consts/filters";
import dateRangeFilterValueStrategy from "./dateRange";
import multiAutocompleteFilterValueStrategy from "./multiAutocomplete";
import singleAutocompleteFilterValueStrategy from "./singleAutocomplete";
import textFilterValueStrategy from "./textField";
import numericRangeFilterValueStrategy from "./numericRange";

const filterValueStrategy = {
    [FilterVariant.DateRange]: dateRangeFilterValueStrategy,
    [FilterVariant.Text]: textFilterValueStrategy,
    [FilterVariant.MultiOption]: multiAutocompleteFilterValueStrategy,
    [FilterVariant.SingleOption]: singleAutocompleteFilterValueStrategy,
    [FilterVariant.NumericRange]: numericRangeFilterValueStrategy,
};

export default filterValueStrategy;
