import { type PropsWithChildren, memo } from "react";
import clsx from "clsx";
import Box, { type BoxProps } from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import { useForm } from "./useForm";
import {
    FormField,
    type UserFormSlots,
    type UserFormType
} from "./types";

export type UserFormProps =
    & BoxProps
    & ReturnType<typeof useForm>
    & UserFormType
    & UserFormSlots;

const UserForm = ({
    values,
    initialValues,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleChange,
    handleBlur,
    TextFieldProps,
    rolesSlot,
    merchantsSlot,
    domainsSlot,
    actionSlot,
    children,
    ...restFormik
}: PropsWithChildren<UserFormProps>) => {
    const { coreId } = initialValues;
    const isNew = typeof coreId === 'undefined';

    const sx = {
        mt: 2,
        ...TextFieldProps?.sx
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={String(coreId)}
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'grid'
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                }}
            >
                <Box>
                    <TextField
                        fullWidth
                        variant='outlined'
                        {...TextFieldProps}
                        {...getFormInputProps(FormField.FirstName, restFormik)}
                        label='First Name*'
                        name={FormField.FirstName}
                        id={FormField.FirstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FormField.FirstName]}
                        sx={sx}
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        {...TextFieldProps}
                        {...getFormInputProps(FormField.LastName, restFormik)}
                        label='Last Name*'
                        name={FormField.LastName}
                        id={FormField.LastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FormField.LastName]}
                        sx={sx}
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        {...TextFieldProps}
                        {...getFormInputProps(FormField.Email, restFormik)}
                        label='Email*'
                        type='email'
                        autoComplete='false'
                        name={FormField.Email}
                        id={FormField.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FormField.Email]}
                        sx={sx}
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        {...TextFieldProps}
                        {...getFormInputProps(FormField.Password, restFormik)}
                        label={`Password${clsx(isNew && '*')}`}
                        type='password'
                        autoComplete="false"
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off'
                            }
                        }}
                        name={FormField.Password}
                        id={FormField.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FormField.Password] ?? ''}
                        sx={sx}
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        {...TextFieldProps}
                        {...getFormInputProps(FormField.PasswordConfirmation, restFormik)}
                        label={`Confirm Password${clsx(isNew && '*')}`}
                        type='password'
                        autoComplete="false"
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off'
                            }
                        }}
                        name={FormField.PasswordConfirmation}
                        id={FormField.PasswordConfirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FormField.PasswordConfirmation]}
                        sx={sx}
                    />
                </Box>
                <Box>
                    {children ?? (
                        <>
                            {rolesSlot}
                            {merchantsSlot}
                            {domainsSlot}
                        </>
                    )}
                </Box>
            </Box>
            {actionSlot}
        </Box>
    );
};

export default memo(UserForm);
