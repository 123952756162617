import { memo } from "react";
import { TicketingSystemService } from "../TicketingSystemProvider/types";
import { Service, useServiceEnable } from "./Service";

const SupportPageOnInit = () => (
    <Service
        {...useServiceEnable(TicketingSystemService.SupportPageOnInit)}
        label='Support page on init'
    />
);

export default memo(SupportPageOnInit);
