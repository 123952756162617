import { useRef, useEffect, Dispatch, SetStateAction } from "react";
import { TabIndex, type TabProps } from "ui/organizms/Consumers/ConsumersDialogs/MultiTabDialog/types";
import type { ID, PaginateResourceResponse, Paginator } from "types";
import type { Consumer, BlacklistedEmailEntity } from "features/consumers/types";
import useFetchResource from "hooks/useFetchResource";
import { compareIds } from "util/support";
import { getConsumers, getConsumersBlacklist } from "features/consumers/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/consumers";
import { getPromiseSettledResourceResult } from "util/resource";
import DuplicatedEmailRegistryEntry from "./DuplicatedEmailRegistryEntry";
import { PER_PAGE_SIZE } from "consts/transactions";

type ConsumerEmails<T> = Record<
    keyof Pick<
        Consumer,
        | 'email'
        | 'hashedEmail'
    >,
    T
>;

const prevConsumerCoreIdSymbol = Symbol('prevConsumerCoreId');

interface UseDuplicateEmailsList {
    (props:
        & TabProps<TabIndex.ManageDuplicates>
        & {
            readonly requestLoading: [boolean, Dispatch<SetStateAction<boolean>>]
        }): void;
    [prevConsumerCoreIdSymbol]?: ID;
}

const useDuplicateEmailsList: UseDuplicateEmailsList = props => {
    const {
        requestLoading: [isLoading],
        getTabPayload,
    } = props;

    const { coreId } = getTabPayload<Consumer>();

    const request = useFetchResource({
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE
    });

    const getSamePersonConsumerEmails = async () => {
        const { samePersonIdentifier } = getTabPayload<Consumer>();
        if (!samePersonIdentifier) {
            throw new Error('Consumer does not have samePersonIdentifier');
        }

        return request((queryParams = '') => getConsumers(
            RequestQueryMapper.from(queryParams)
                .containsIn(
                    Filters.samePersonIdentifier,
                    samePersonIdentifier
                )
                .toString()
        ));
    };

    const getConsumersBlacklistedEmailEntities = async (params: ConsumerEmails<string[]>) => {
        const { email, hashedEmail } = Object.entries(params)
            .reduce((acc, [key, values]) => {
                const requestQueryMapper = RequestQueryMapper.from();

                for (const emailOrHashedEmail of values) {
                    requestQueryMapper.containsIn(key, emailOrHashedEmail);
                }

                return {
                    ...acc,
                    [key]: requestQueryMapper
                };
            }, {} as ConsumerEmails<RequestQueryMapper>);

        const settledResults = await Promise.allSettled([
            request((queryParams = '') => getConsumersBlacklist(
                email
                    .concat(queryParams)
                    .toString()
            )),
            request((queryParams = '') => getConsumersBlacklist(
                hashedEmail
                    .concat(queryParams)
                    .toString()
            ))
        ]);

        return settledResults.map(promiseSettledResult =>
            getPromiseSettledResourceResult<
                Paginator<BlacklistedEmailEntity>
            >(promiseSettledResult)
                ?.data
                .data ?? []
        ) as Array<BlacklistedEmailEntity[]>;
    };

    const helpers = {
        ...props,
        getSamePersonConsumerEmails,
        getConsumersBlacklistedEmailEntities,
    };

    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const {
            tabRegistry: [, updateDialogDataRegistry],
            requestLoading: [, setLoading],
            getSamePersonConsumerEmails,
            getConsumersBlacklistedEmailEntities
        } = helpersRef.current;

        if (!compareIds(coreId, useDuplicateEmailsList[prevConsumerCoreIdSymbol]!)) {
            setLoading(true);

            let sameConsumerEmails = Array.of<Consumer>();

            getSamePersonConsumerEmails()
                .then(({ data: samePersonConsumers }: PaginateResourceResponse<Consumer>) => {
                    sameConsumerEmails = samePersonConsumers.data ?? [];

                    if (sameConsumerEmails.length <= 0) {
                        return Promise.resolve([
                            [], []
                        ]);
                    }

                    const consumerEmails = sameConsumerEmails
                        .reduce((consumerEmails, { email, hashedEmail }) => ({
                            ...consumerEmails,
                            email: [...consumerEmails.email, email],
                            hashedEmail: [...consumerEmails.hashedEmail, hashedEmail]
                        }), {
                            email: Array.of<string>(),
                            hashedEmail: Array.of<string>()
                        } as ConsumerEmails<string[]>);

                    return getConsumersBlacklistedEmailEntities(consumerEmails);
                })
                .then((blacklistedEmailEntities: Array<BlacklistedEmailEntity[]>) => {
                    const manageDuplicatesDataRegistry = sameConsumerEmails
                        .reduce((acc, consumer) => {
                            for (const blacklistedEmails of blacklistedEmailEntities) {
                                const blacklistedEmail = blacklistedEmails.find(({ email, hashedEmail }) =>
                                    (consumer.email === email) || (consumer.hashedEmail === hashedEmail));

                                if (blacklistedEmail) {
                                    return [
                                        ...acc,
                                        new DuplicatedEmailRegistryEntry(
                                            consumer,
                                            blacklistedEmail
                                        )
                                    ];
                                }
                            }

                            return [
                                ...acc,
                                new DuplicatedEmailRegistryEntry(
                                    consumer
                                )
                            ];
                        }, Array.of<DuplicatedEmailRegistryEntry>());

                    updateDialogDataRegistry(manageDuplicatesDataRegistry);
                })
                .catch(() => updateDialogDataRegistry([]))
                .finally(() => setLoading(false));
        }

        useDuplicateEmailsList[prevConsumerCoreIdSymbol] = coreId;
    }, [
        coreId,
        isLoading
    ]);
};

useDuplicateEmailsList[prevConsumerCoreIdSymbol] = '';

export default useDuplicateEmailsList;
