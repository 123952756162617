import { useForm as useFormHook } from "hooks";
import type { PaymentMethodLimits, PaymentMethodLimitsForm } from "./types";

export const getInitialValues = (initialValues?: Partial<PaymentMethodLimits>) => ({
    ...initialValues,
});

export const useForm = (paymentMethodLimitsProps: PaymentMethodLimitsForm) =>
    useFormHook<PaymentMethodLimits>({
        enableReinitialize: true,
        ...paymentMethodLimitsProps,
        initialValues: getInitialValues(paymentMethodLimitsProps.initialValues)
    });
