import type { ChangeEvent } from "react";
import type { Option } from "types";
import { compareIds } from "util/support";

export type UseOptionControllerArg = {
    readonly value: Array<Option>;
    readonly onChange: (value: Array<Option>) => void;
};

export default function useOptionController({ onChange, value }: UseOptionControllerArg) {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(value.map(({ id, name }) => {
            if (name === event.target.name) {
                return {
                    id: Number(event.target.checked),
                    name
                };
            }

            return {
                id,
                name
            };
        }));
    };

    const isSelected = (option: Option) =>
        Boolean(value.find(({ name }) => compareIds(name, option.id))?.id);

    return {
        handleChange,
        isSelected
    };
};
