import { useState } from "react";
import { useContextPublisherContext } from "providers/ContextPublisher";
import HasUnsavedItemException from "./HasUnsavedItemException";
import type { UnsavedChangesSubscriber, UnsavedChangesSubscriberKey } from "./types";

export default function useUnsavedChanges() {
    const [resolvers, setResolvers] = useState<any>(null);

    const publisher = useContextPublisherContext();

    const addUnsavedChangesSubscriber = ([
        unsavedChangesSubscriberKey,
        unsavedChangesSubscriber
    ]: [UnsavedChangesSubscriberKey, UnsavedChangesSubscriber]) =>
        publisher.addSubscriber([
            unsavedChangesSubscriberKey,
            () => {
                if (unsavedChangesSubscriber()) {
                    throw new HasUnsavedItemException('There are unsaved changes', {});
                }
            }]);

    const removeUnsavedChangesSubscriber = (unsavedChangesSubscriberKey: UnsavedChangesSubscriberKey) =>
        publisher.removeSubscriber(unsavedChangesSubscriberKey);

    const resetResolvers = () => setResolvers(null);

    const checkUnsavedChanges = (handlers: {
        readonly shouldResetResolvers?: boolean;
        readonly onConfirm?: () => void;
        readonly onCancel?: () => void;
    } = {}) => {
        try {
            publisher.notify();
        } catch (exception) {
            if (exception instanceof HasUnsavedItemException) {
                const hasHandlers = Boolean(Object.entries(handlers).length);
                // TODO: remove any assertion in TypeScript 5 +
                const resolvers = (Promise as any).withResolvers();

                setResolvers(resolvers);

                if (hasHandlers) {
                    return resolvers.promise
                        .then(handlers.onConfirm)
                        .catch(handlers.onCancel)
                        .finally(resetResolvers);
                }
            }else {
                throw exception;
            }
        }

        return Promise.resolve();
    };

    const hasUnsavedChanges = () => Boolean(resolvers);

    const confirmExit = () => resolvers.resolve();

    const cancelExit = () => resolvers.reject();

    return {
        unsavedChangesPromise: resolvers?.promise,
        resetResolvers,
        addUnsavedChangesSubscriber,
        removeUnsavedChangesSubscriber,
        checkUnsavedChanges,
        hasUnsavedChanges,
        confirmExit,
        cancelExit
    };
};
