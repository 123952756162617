import type { ComponentType } from "react";
import type { AbstractFilterProps } from "ui/widgets/Filters";

const withProps = <T extends AbstractFilterProps>(Component: ComponentType<T>) =>
    (props: Partial<T>) => {
        if (!Object.entries(props).length) {
            return null;
        }
        
        return (
            <Component
                {...props as T}
            />
        );
    };
    
export default withProps;
