import { createContext, memo, PropsWithChildren } from "react";
import { useModal } from "ui/atoms/Modal"

type DownloadCSVTemplateContextType = Pick<ReturnType<typeof useModal>, 'open' | 'onOpen'>;

export const DownloadCSVTemplateContext = createContext<
    DownloadCSVTemplateContextType
>({} as DownloadCSVTemplateContextType);

const DownloadCSVTemplateProvider = ({ children, ...restProps }: PropsWithChildren<DownloadCSVTemplateContextType>) => (
    <DownloadCSVTemplateContext.Provider
        value={restProps}
    >
        {children}
    </DownloadCSVTemplateContext.Provider>
);

export default memo(DownloadCSVTemplateProvider);
