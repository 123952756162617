import { memo, PropsWithChildren } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';

const FiltersActionable = (props: PropsWithChildren<StackProps>) => (
    <Stack
        spacing={2}
        direction='row'
        {...props}
    />
);

export default memo(FiltersActionable);
