import type { NumericRangeFilterProps } from "../../FilterVariants/NumericRangeFilter";
import type { SelectedFilterContentProps } from "../types";
import { withSelectedFilterContent } from "./SelectedFilterContent";

const NumericRangeFilterContent = ({ filter: { props }, onChange }: SelectedFilterContentProps<NumericRangeFilterProps>) => {
    const { label, value, id } = props;

    return withSelectedFilterContent({
        onDelete: () => () => onChange({
            type: id,
            payload: []
        }),
        label,
        values: [value]
            .flat()
            .filter(Boolean),
        renderValues: values => values.join(' - '),
    });
};

export default NumericRangeFilterContent;
