import { memo } from "react";
import { Details, DetailContentProps } from "ui/molecules/TableGrids";
import CodeSnippet from "ui/molecules/CodeSnippet";
import DetailsGeneralInfo from "ui/molecules/TableGrids/Details/DetailsGeneralInfo";
import DetailTabs from "ui/molecules/TableGrids/Details/DetailTabs";
import useNotifications from "./useNotifications";
import { NotificationType } from "consts/notifications";
import useActions from "features/transactions/useActions";
import type { InNotification, OutNotification } from "features/transactions/types";
import useTransactionDetails from "./useTransactionDetails";
import TransactionActions from "./TransactionActions";
import TransactionHistory from "./TransactionHistory";
import { useTypedSelector } from "hooks";
import { selectIsTransactionDetailsLoading } from "features/transactions/selectors";
import useTransactionHistory from "./useTransactionHistory";
import useStatusesHistory from "./useStatusesHistory";

const TransactionDetails = ({ row: transaction }: DetailContentProps) => {
    const {
        transactionRequest,
        transactionResponse,
        transactionDecision
    } = transaction;

    const {
        getInNotifications,
        getOutNotifications
    } = useActions();

    const transactionDetails = useTransactionDetails(transaction);

    const transactionHistoryCollection = useTransactionHistory(transaction);

    const statusesHistory = useStatusesHistory({
        transactionHistoryCollection,
        transaction
    });

    const [inNotifications, fetchInNotifications] = useNotifications<InNotification>(
        getInNotifications,
        transaction
    );
    const [outNotifications, fetchOutNotifications] = useNotifications<OutNotification>(
        getOutNotifications,
        transaction
    );

    const isTransactionDetailsLoading = useTypedSelector(selectIsTransactionDetailsLoading);

    const onTabChange = (activeTab: string) => {
        const tabHandlers = new Map<string, any>([
            ['4', () => null],
            ['5', fetchInNotifications],
            ['6', fetchOutNotifications]
        ]);

        tabHandlers.get(activeTab)?.();
    };

    return (
        <Details
            row={transaction}
            transactionActionsSlot={
                <TransactionActions
                    {...transactionDetails}
                />
            }
            generalInfoSlot={
                <DetailsGeneralInfo
                    isLoading={isTransactionDetailsLoading}
                    {...({
                        ...transactionDetails,
                        statusesHistory
                    })}
                />
            }
            tabsSlot={
                <DetailTabs
                    onTabChange={onTabChange}
                    model={[
                        {
                            label: 'Request',
                            component: (
                                <CodeSnippet
                                    data={transactionRequest}
                                />
                            )
                        },
                        {
                            label: 'Response',
                            component: (
                                <CodeSnippet
                                    data={transactionResponse}
                                />
                            )
                        },
                        {
                            label: 'System Decision',
                            component: (
                                <CodeSnippet
                                    data={transactionDecision}
                                />
                            )
                        },
                        {
                            label: 'Transaction History',
                            component: (
                                <TransactionHistory
                                    transaction={transaction}
                                    transactionHistoryCollection={transactionHistoryCollection}
                                />
                            )
                        },
                        {
                            label: `${NotificationType.In} Notifications`,
                            component: (
                                <CodeSnippet
                                    data={inNotifications}
                                />
                            )
                        },
                        {
                            label: `${NotificationType.Out} Notifications`,
                            component: (
                                <CodeSnippet
                                    data={outNotifications}
                                />
                            )
                        }
                    ]}
                />
            }
        />
    );
};

export default memo(TransactionDetails);
