import { selectMerchantRoleOptions } from "features/users/selectors";
import { useTypedSelector } from "hooks";
import { type SyntheticEvent, memo } from "react";
import type { Option } from "types";
import Autocomplete from "ui/atoms/Autocomplete";
import { FormField, MerchantValidator, UserForm, type UserFormComponentProps } from "ui/forms/User";
import { isMerchantMultiple } from "ui/forms/User";
import { getOptionName, isOptionEqualToValue } from "util/option";

const MerchantUser = (props: UserFormComponentProps) => {
    const merchantRoleOptions = useTypedSelector(selectMerchantRoleOptions);

    const getValue = (formikValue?: Option[], isMultiple?: boolean) =>
        isMultiple
            ? [formikValue]
                .flat()
                .filter(Boolean)
            : (Array.isArray(formikValue) ? formikValue[0] : formikValue) ?? null;

    const onOptionChange = <T extends unknown>(
        fieldName: string,
        valueGetter = (newValue: any) => newValue
    ) => (_: SyntheticEvent | undefined, newValue: T) => {
        props.setFieldValue(
            fieldName,
            Array.isArray(newValue)
                ? newValue
                : valueGetter(newValue),
            true
        );
        props.setFieldTouched(fieldName, true, true);
    };

    const sx = {
        mt: 2
    };

    return (
        <UserForm
            {...props}
            TextFieldProps={{
                size: 'small'
            }}
        >
            <Autocomplete
                fullWidth
                disablePortal
                size='small'
                layout={null}
                loading={props.isBootstrapDataLoading}
                options={merchantRoleOptions}
                getOptionLabel={getOptionName}
                isOptionEqualToValue={isOptionEqualToValue}
                value={props.values[FormField.Role]}
                onChange={(_, roleOption: Option) => {
                    props.setFieldValue(FormField.Role, roleOption);
                    props.setFieldValue(FormField.RoleId, roleOption?.id, true);
                }}
                TextFieldProps={{
                    label: 'Role',
                    required: true
                }}
                sx={sx}
            />
            <Autocomplete
                fullWidth
                disablePortal
                size='small'
                layout={null}
                multiple={isMerchantMultiple(props.bootstrapData.roles, props.values)}
                options={props.bootstrapData.merchants}
                getOptionLabel={getOptionName}
                value={getValue(
                    props.values[FormField.Merchants],
                    isMerchantMultiple(props.bootstrapData.roles, props.values)
                )}
                onChange={onOptionChange<
                    | Option
                    | Array<Option | undefined>
                    | undefined
                    | null
                >(FormField.Merchants)}
                loading={props.isBootstrapDataLoading}
                TextFieldProps={{
                    label: 'Merchants',
                    error: MerchantValidator.from(props).isInvalid,
                    helperText: MerchantValidator.from(props).message,
                    required: true
                }}
                sx={sx}
            />
        </UserForm>
    );
};

export default memo(MerchantUser);
