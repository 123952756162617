import { DomainAction } from "types";
import type { MerchantUsersRepository as IMerchantUsersRepository } from "../../repository";
import type { IDomainRepository } from "hooks";

export const enum MerchantUsersActionType {
    FetchMerchantUsers = 'MerchantUsersActionType:FetchMerchantUsers',
    Reset = 'MerchantUsersActionType:Reset'
};

export type MerchantUsersRepositoryState = {};

export type MerchantUsersRepositoryAction =
    | DomainAction<
        MerchantUsersActionType.FetchMerchantUsers,
        undefined
    >
    | DomainAction<
        MerchantUsersActionType.Reset,
        undefined
    >;

export interface MerchantUsersRepository extends IMerchantUsersRepository, IDomainRepository {
    readonly state: MerchantUsersRepositoryState;

    fetchMerchantUsers(): Promise<void>;
}
