import {
    generateMerchantPayoutFilesSettlements,
    getMerchantPayoutFilesSettlementXLS,
    getMerchantPayoutFilesStillPendingSettlementCSV,
    getMerchantPayoutFilesTransactionCSV,
    previewMerchantPayoutFilesSettlementXLS
} from "features/merchantPayouts/api";
import type { AbstractFileEntity } from "features/merchantPayouts/types";
import useActions from "features/merchantPayouts/useActions";
import { ActionRequestHandlers, MenuActions } from "ui/organizms/MerchantPayouts/ActionMenu";

export default function useFilesActions(row: AbstractFileEntity) {
    const { deleteMerchantPayoutFiles } = useActions();
    const getIsPaid = () => Boolean(Number(row.isPaid));

    const getIsGenerated = () => Boolean(row.fileKey?.length);

    const getMenuItems = () => {
        let actionMenu: Array<MenuActions>;

        switch (true) {
            case getIsPaid(): {
                actionMenu = [
                    MenuActions.SettlementXLS,
                    MenuActions.TransactionsCSV,
                    MenuActions.StillPendingSettlementCSV
                ];
                break;
            }
            case !getIsPaid() && getIsGenerated(): {
                actionMenu = [
                    MenuActions.ManageFileTaxes,
                    MenuActions.SettlementXLS,
                    MenuActions.TransactionsCSV,
                    MenuActions.StillPendingSettlementCSV,
                    MenuActions.Delete
                ];
                break;
            }
            case !getIsPaid() && !getIsGenerated(): {
                actionMenu = [
                    MenuActions.ManageFileTaxes,
                    MenuActions.TransactionsCSV,
                    MenuActions.StillPendingSettlementCSV,
                    MenuActions.PreviewSettlementXLS,
                    MenuActions.GenerateSettlementXLS,
                    MenuActions.Delete
                ];
                break;
            }
            default: {
                actionMenu = [];
            }
        }

        return actionMenu;
    };

    const getRequestMappings = () =>
        new Map<MenuActions, ActionRequestHandlers>([
            [MenuActions.Delete, { dialog: deleteMerchantPayoutFiles }],
            [MenuActions.GenerateSettlementXLS, { menu: generateMerchantPayoutFilesSettlements }],
            [MenuActions.SettlementXLS, { menu: getMerchantPayoutFilesSettlementXLS }],
            [MenuActions.TransactionsCSV, { menu: getMerchantPayoutFilesTransactionCSV }],
            [MenuActions.StillPendingSettlementCSV, { menu: getMerchantPayoutFilesStillPendingSettlementCSV }],
            [MenuActions.PreviewSettlementXLS, { menu: previewMerchantPayoutFilesSettlementXLS }]
        ]);

    return {
        getMenuItems,
        getRequestMappings
    };
};
