import { ApiState } from "infrastructure/api";
import { RootState } from "infrastructure/store";
import { PaymentMethod } from "./types";


export const selectIsPaymentMethodsLoading = (state: RootState) =>
  state.paymentMethods.paymentMethodsLoadingState === ApiState.Pending;

export const selectPaymentMethods = (state: RootState) =>
  state.paymentMethods.paymentMethodsSlice;

export const selectPaymentMethodsOptions = (state: RootState) =>
  state.paymentMethods
    .paymentMethodsSlice
    ?.data
    .map(({ coreId, name }: PaymentMethod) => ({
      id: coreId,
      name: `${name} (${coreId})`
    })) ?? [];

export const selectPaymentMethodsTags = (state: RootState) => {
  const uniqueTags = new Set<string>(
    state.paymentMethods
      .paymentMethodsSlice
      ?.data
      .map(({ tag }: PaymentMethod) => tag) ?? []
  );

  return Array.from(uniqueTags)
    .map(name => ({
      id: name,
      name
    }));
};
