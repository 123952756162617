import {
    selectIsMerchantPayoutEntityLoading,
    selectIsMultiFileMergeEnabled,
    selectSelectionModel
} from "features/merchantPayouts/selectors";
import useActions from "features/merchantPayouts/useActions";
import { useTypedSelector } from "hooks";
import useMergeFilesContext from "./useMergeFilesContext";

export default function useMergeFilesAction() {
    const selectionModel = useTypedSelector(selectSelectionModel);
    const isMultiFileMergeEnabled = useTypedSelector(selectIsMultiFileMergeEnabled);
    const isMerchantPayoutEntityLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);

    const { onChangeMultiFileMerge } = useActions();

    const { open, onOpen } = useMergeFilesContext();

    const toggleEnableMultiFileMerge = () => {
        onChangeMultiFileMerge(!isMultiFileMergeEnabled);
    };

    const isDisabled = () =>
        !isMultiFileMergeEnabled ||
        open ||
        isMerchantPayoutEntityLoading ||
        (selectionModel.length < 2);

    return {
        isMerchantPayoutEntityLoading,
        isMultiFileMergeEnabled,
        isDisabled,
        onOpen,
        toggleEnableMultiFileMerge
    };
};
