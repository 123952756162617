import { ApiState } from "infrastructure/api";
import type { EnabledMerchantsProps } from "./types";
import { useState, useEffect, useRef } from "react";
import type { ID } from "types";
import { compareIds } from "util/support";
import { useParams } from "react-router-dom";
import { Filters } from "consts/transactions";

export default function useEnabledMerchants({
    selectApiState
}: EnabledMerchantsProps) {
    const previousMerchantCoreIdRef = useRef<ID | undefined>(
        useParams()[Filters.merchantId]
    );
    const [isMerchantProcessing, setMerchantProcessing] = useState(false);

    const isLoading = selectApiState({
        apiState: ApiState.Pending
    });

    const isSettled = selectApiState({
        apiState: [ApiState.Succeeded, ApiState.Failed]
    });

    const isIdle = selectApiState({
        apiState: ApiState.Idle
    });

    const onMerchantSelect = (coreId: ID) => {
        if (
            previousMerchantCoreIdRef.current &&
            compareIds(previousMerchantCoreIdRef.current, coreId)
        ) {
            return;
        }

        setMerchantProcessing(true);
        previousMerchantCoreIdRef.current = coreId;
    };

    useEffect(() => {
        if (isIdle) {
            return;
        }

        setMerchantProcessing(!isSettled);
    }, [
        isSettled,
        isIdle
    ]);

    return {
        isProcessing: isLoading || isMerchantProcessing,
        onMerchantSelect
    };
};
