import LoadingButton from '@mui/lab/LoadingButton';
import FilesForm, {
    useFilesForm,
    FilesBootstrapData,
    getInitialValues as getFilesFormInitialValues
} from "ui/forms/Files";
import { Filters } from "consts/merchantPayouts";
import { selectViaAutoSuggestModel } from 'features/general/selectors';
import { useTypedSelector } from 'hooks';
import { memo, PropsWithChildren, FC } from 'react';
import { useModal } from 'ui/atoms/Modal';
import useFiltersModel from 'ui/widgets/Filters/hooks/useFiltersModel';
import GenerateFileProvider from './GenerateFileProvider';
import { UiDateTimeFormatter, dateRangeFormatter } from 'util/formaters';
import { convertToFormData } from 'util/transformers';
import type { GenerateFileActionProps } from './Props';
import type { Moment } from 'moment';
import { BasicDialog } from 'ui/molecules/Dialog';

const GenerateFileDialog: FC<PropsWithChildren<GenerateFileActionProps>> = ({
    children,
    dialogTitleSlot,
    formId,
    createNewFileRequestHandler,
    getInitialValues,
    getBootstrapData: bootstrapDataOptionalGetter,
}) => {
    const { open, onClose, onOpen } = useModal();

    const viaAutosuggestModel = useTypedSelector(selectViaAutoSuggestModel);

    const {
        getFilterOptions,
        isLoading: isBootstrapDataLoading
    } = useFiltersModel();

    const filesForm = useFilesForm({
        initialValues: getInitialValues!(),
        isInitialValid: false,
        onResolve: () => {
            filesForm.resetForm()
           onClose()
        },
        onSaveOrCreate: values => {
            const [dateFrom, dateTo] = dateRangeFormatter<string>(
                values["dateFrom|dateTo"] as [Moment, Moment], {
                pattern: UiDateTimeFormatter.Write
            });

            return createNewFileRequestHandler(convertToFormData<string>({
                MOR: values.MOR!.name,
                via: values.via?.id as string,
                merchantId: values.merchantId?.id as string,
                dateFrom,
                dateTo
            }));
        }
    });

    const getBootstrapData = () => {
        if (typeof bootstrapDataOptionalGetter !== 'function') {
            return [
                Filters.via,
                Filters.merchantId,
                Filters.MOR
            ].reduce((bootstrapData, bootstrapDataToken) => ({
                ...bootstrapData,
                [bootstrapDataToken]: getFilterOptions(bootstrapDataToken).options ?? [],
                [Filters.via]: viaAutosuggestModel
            }), {} as FilesBootstrapData);
        }

        return bootstrapDataOptionalGetter();
    };

    const handleClose = () => {
        filesForm.resetForm();
        onClose();
    };

    return (
        <GenerateFileProvider
            open={open}
            onOpen={onOpen}
        >
            <BasicDialog
                isOpen={open}
                onClose={handleClose}
                isCanClose={!filesForm.isSubmitting}
                titleSlot={dialogTitleSlot}
                actionsSlot={(
                    <LoadingButton
                        type="submit"
                        variant="outlined"
                        form={formId}
                        loading={filesForm.isSubmitting}
                        disabled={!filesForm.isValid}
                    >
                        Create
                    </LoadingButton>
                )}
            >
                <FilesForm
                    {...filesForm}
                    id={formId}
                    bootstrapData={getBootstrapData()}
                    isBootstrapDataLoading={isBootstrapDataLoading}
                />
            </BasicDialog>
            {children}
        </GenerateFileProvider>
    );
};

GenerateFileDialog.defaultProps = {
    getInitialValues: getFilesFormInitialValues as GenerateFileActionProps['getInitialValues']
};

export default memo(GenerateFileDialog);
