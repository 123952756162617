import { Filters, ParentVisibility } from "consts/filters";
import { Filters as MerchantPayoutsFilters } from "consts/merchantPayouts";
import type {
    AbstractFilterProps,
    Filter,
    AutocompleteProps
} from "ui/widgets/Filters";
import { compareIds } from "util/support";

export default function useFilterPropsOverride(filters: Filter<AbstractFilterProps>[]) {
    const isParentVisibilityFilterSelected = () => {
        const parentVisibilityFilter = filters.find(filter => Object.is(filter.props.id, Filters.parentVisibility));
        if (parentVisibilityFilter) {
            const { props } = parentVisibilityFilter as Filter<AutocompleteProps>;
            return compareIds(ParentVisibility.Parents, props.value?.id);
        }

        return false;
    };

    const isParentIdFilter = (filter: Filter<AbstractFilterProps>) =>
        Object.is(filter.props.id, MerchantPayoutsFilters.parentId);

    return (filter: Filter<AbstractFilterProps>) => {
        let propsOverrides = {};

        if (isParentIdFilter(filter) && isParentVisibilityFilterSelected()) {
            propsOverrides = {
                disabled: true
            };
        }

        return propsOverrides as Filter<AbstractFilterProps>;
    };
};
