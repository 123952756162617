import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type {
    LedgersResponse,
    Merchant,
    MerchantGroupsResponse,
    MerchantResponse,
    MerchantsResponse
} from "./types";
import {
    getMerchants,
    createOrUpdateMerchant,
    getMerchantGroups,
    createOrUpdateMerchantSettings,
    getMerchantUsers,
    getLedgers
} from "features/merchants/api";
import type { ThunkReturnType } from "types";
import { selectIsNotMor } from "features/auth/selectors";
import { MerchantTabs } from "consts/merchants";
import { createMerchantsGroupsPivot } from "features/pivots/api";
import { toIds } from "util/mappers";
import type { UsersResponse } from "features/users/types";

export const getMerchantsThunk: AsyncThunk<
    ThunkReturnType<MerchantsResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchants]),
    payloadCreator(getMerchants),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return (
                selectIsNotMor(getState()) &&
                ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].tableLoadingState)
            );
        }
    }
);

export const getMerchantUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantUsers]),
    payloadCreator(getMerchantUsers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.MerchantUsers].tableLoadingState);
        }
    }
);

export const getMerchantByIdUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantByIdUsers]),
    payloadCreator(getMerchantUsers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants.merchantUsers.tableLoadingState);
        }
    }
);

export const getMerchantLedgersThunk: AsyncThunk<
    ThunkReturnType<LedgersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetLedgers]),
    payloadCreator(getLedgers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants.ledgers.tableLoadingState);
        }
    }
);

export const postMerchantThunk: AsyncThunk<
    ThunkReturnType<MerchantResponse>,
    Partial<Merchant>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostMerchant]),
    async (merchant: Partial<Merchant>, { rejectWithValue, fulfillWithValue }) => {
        const merchantResponse = await createOrUpdateMerchant(merchant);
        if (merchantResponse.ok) {
            const merchantPayload: MerchantResponse = await merchantResponse.json();

            if (merchantPayload.success) {
                const merchantId = merchantPayload.data.coreId;

                await Promise.allSettled([
                    ...toIds(merchant.groups).map(groupId =>
                        createMerchantsGroupsPivot({
                            merchantId,
                            merchantGroupId: groupId
                        })
                    ),
                    createOrUpdateMerchantSettings({
                        merchantId,
                        apiId: merchantId,
                        apiGuid: String(merchantId),
                        cpKey: String(merchantId),
                        cpSecret: String(merchantId),
                        sharedKey: String(merchantId),
                    })
                ]);

                const fulfillValue = merchantPayload as ThunkReturnType<MerchantResponse>;
                fulfillWithValue(fulfillValue);

                return fulfillValue;
            }

            return rejectWithValue(merchantPayload);
        }

        rejectWithValue(merchantResponse.clone());
    },
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].entityLoadingState);
        }
    }
);

export const putMerchantThunk: AsyncThunk<
    ThunkReturnType<MerchantResponse>,
    Partial<Merchant>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutMerchant]),
    payloadCreator(createOrUpdateMerchant),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].entityLoadingState);
        }
    }
);

export const getMerchantGroupsThunk: AsyncThunk<
    ThunkReturnType<MerchantGroupsResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantGroups]),
    payloadCreator(getMerchantGroups),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;
            const { merchantGroups } = merchants[MerchantTabs.Merchants].bootstrapData;

            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(merchantGroups.loadingState);
        }
    }
);
