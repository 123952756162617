import type { FundStatus as FundStatusType } from "types";
import Icon from "ui/atoms/Icon";
import withStatus from "./withStatus";

const FundStatus = withStatus<FundStatusType>({
    NOT_EXPECTED: {
        icon: <Icon name="NotInterested" />,
        color: 'primary'
    },
    WAITING: {
        icon: <Icon name="HourglassEmpty" />,
        color: 'warning'
    },
    RECEIVED: {
        icon: <Icon name="KeyboardDoubleArrowDown" />,
        color: 'success'
    },
    SENT: {
        icon: <Icon name="Outbox" />,
        color: 'info'
    },
    MISSING: {
        icon: <Icon name="CallMissed" />,
        color: 'error'
    },
    REFUNDED: {
        icon: <Icon name="LocalAtm" />,
        color: 'secondary'
    }
});

export default FundStatus;
