import { memo, type PropsWithChildren } from "react";
import useResourceContext from "./useResourceContext";
import type { ResourceDetailProps } from "./types";
import Modal from "ui/atoms/Modal";

const ResourceDetail = ({
    resourceName,
    children,
    component: DetailDialog = Modal,
    ...restProps
}: PropsWithChildren<ResourceDetailProps>) => {
    const {
        open,
        getIsNew,
        onClose
    } = useResourceContext();

    const renderTitle = () => [
        getIsNew()
            ? 'Create new'
            : 'Update',
        resourceName
    ].join(' ');

    return (
        <DetailDialog
            isOpen={open}
            titleSlot={renderTitle()}
            onClose={onClose}
            {...restProps}
        >
            {children}
        </DetailDialog>
    );
};

export default memo(ResourceDetail);
