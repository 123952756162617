import { memo, type FC } from "react";
import Box from "@mui/material/Box";
import {
    useStatisticDashboardContext,
    esTransactionsGroupByStatusQueryToken
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import type { LinearChartProps, LinearDiagramProps } from "../../types";
import type { ElasticSearchResourceResponse } from "types";
import TitleNumber from "../shared/TitleNumber";
import LinearDiagram from "../shared/LinearDiagram";
import SliceTooltip from "../shared/SliceTooltip";
import AxisBottomTick from "../shared/AxisBottomTick";
import {
    diagramPropsPredicate,
    formatXDateAxis,
    getAverage,
    getChartData,
    integerFormat
} from "../../support/helpers";
import useLinearChartColors from "../../hooks/useLinearChartColors";
import useLinearDiagram from "../../hooks/useLinearDiagram";
import { statusColorSchemeMap } from "consts/transactions";

type TransactionsVolumeByStatusDiagramProps =
    & Pick<LinearDiagramProps, 'className' | 'isLoading'>
    & {
        readonly payload: ElasticSearchResourceResponse<string>['data'];
    };

const TransactionsVolumeByStatusDiagram = memo(({
    className,
    isLoading,
    payload
}: TransactionsVolumeByStatusDiagramProps) => {
    const {
        total: totalGroupCount,
        data,
        columns
    } = useLinearDiagram({
        isLoading,
        onLoad: () => {
            let totalGroupCount = 0;

            const data = getChartData(payload, {
                getDatum: row => {
                    const groupCount = Number(row.at(-3));

                    totalGroupCount += groupCount;

                    return {
                        pivot: String(row.at(-1)),
                        x: row.at(-2),
                        y: groupCount
                    };
                }
            });
            return {
                total: totalGroupCount,
                data,
                columns: payload.columns
            };
        }
    });

    return (
        <LinearDiagram
            {...useLinearChartColors(statusColorSchemeMap)}
            className={className}
            sx={{
                zIndex: 1
            }}
            title='Transaction Volume'
            subtitle={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 27
                    }}
                >
                    <TitleNumber
                        isLoading={isLoading}
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {totalGroupCount}
                    </TitleNumber>
                </Box>
            )}
            subheader={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 14
                    }}
                >
                    Average per interval unit:
                    <TitleNumber
                        isLoading={isLoading}
                    >
                        {getAverage(
                            totalGroupCount,
                            columns
                        )}
                    </TitleNumber>
                </Box>
            )}
            axisLeft={{
                legend: 'Group Count',
                format: integerFormat
            }}
            yFormat=' >-,'
            data={data}
            columns={columns}
            sliceTooltip={props => (
                <SliceTooltip
                    {...props}
                    columns={columns}
                />
            )}
            renderAxisBottomTick={props => (
                <AxisBottomTick
                    {...props}
                    isLoading={isLoading}
                    data={data}
                    formatValue={formatXDateAxis(columns)}
                />
            )}
            isLoading={isLoading}
            chartMargin={{
                right: 130
            }}
        />
    );
}, diagramPropsPredicate);

const TransactionsVolumeByStatus: FC<LinearChartProps> = ({ className }) => {
    const statisticDashboard = useStatisticDashboardContext();
    const { payload, isLoading } = statisticDashboard[esTransactionsGroupByStatusQueryToken];

    return (
        <TransactionsVolumeByStatusDiagram
            className={className!}
            isLoading={isLoading}
            payload={payload!}
        />
    );
};

TransactionsVolumeByStatus.defaultProps = {
    className: 'js-transactions-volume-by-status'
};

export default memo(TransactionsVolumeByStatus);
