import type { InputBaseComponentProps } from "@mui/material/InputBase";
import { ValueInterceptorModifier, type ValueModifierRegistry } from "./types";

export const textFieldValueModifierRegistry: ValueModifierRegistry<string> = new Map([
    ['maxLength', ({ value, rule }) => String(value).slice(0, Number(rule))]
]);

export class TextFieldValueInterceptorModifier<T extends string> extends ValueInterceptorModifier<T> {
    public static fromInputProps(inputProps?: InputBaseComponentProps) {
        return new this(inputProps, textFieldValueModifierRegistry);
    }
};
