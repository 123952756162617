import { createContext, memo, ReactNode, PropsWithChildren } from 'react';
import { useModal } from 'ui/atoms/Modal';

export type CreateTaxableEntityContextType = ReturnType<typeof useModal> & {
    readonly createTaxableActionLabel: ReactNode;
};

export const CreateTaxableEntityContext = createContext<
    CreateTaxableEntityContextType
>({} as CreateTaxableEntityContextType);

const CreateTaxableEntityProvider = ({
    children,
    ...restProps
}: PropsWithChildren<Pick<CreateTaxableEntityContextType, 'createTaxableActionLabel'>>) => {
    const modalProps = useModal();

    return (
        <CreateTaxableEntityContext.Provider
            value={{
                ...restProps,
                ...modalProps,
            }}
        >
            {children}
        </CreateTaxableEntityContext.Provider>
    );
};

export default memo(CreateTaxableEntityProvider);
