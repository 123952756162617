import { DataGridPremium, GridColumns } from '@mui/x-data-grid-premium';
import Box from "@mui/material/Box";
import { memo } from 'react';
import useHistory, { TransactionHistoryCollection, TransactionHistoryRecord } from './useHistory';
import type { Transaction } from 'features/transactions/types';

type Props = {
    readonly transactionHistoryCollection: Array<Transaction>;
    readonly transaction: TransactionHistoryRecord;
};

const TransactionHistory = ({ transaction, transactionHistoryCollection }: Props) => {
    const columns: GridColumns = [
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 200,
            hide: true
        },
        {
            field: 'historyCreatedAt',
            headerName: 'History Creation Date',
            minWidth: 200
        },
        {
            field: 'field',
            headerName: 'Field',
            minWidth: 200
        },
        {
            field: 'previous',
            headerName: 'Old Value',
            minWidth: 200
        },
        {
            field: 'next',
            headerName: 'New Value',
            minWidth: 200
        }
    ];

    const {
        getRows,
        getRowGroupingModel,
        isTransactionDetailsLoading
    } = useHistory();

    return (
        <Box
            sx={{
                height: 'calc(100vh - 300px)',
                width: '100%'
            }}
        >
            <DataGridPremium
                loading={isTransactionDetailsLoading}
                initialState={{
                    rowGrouping: {
                        model: getRowGroupingModel(),
                    }
                }}
                disableSelectionOnClick
                columns={columns}
                rows={getRows(
                    transactionHistoryCollection as unknown as TransactionHistoryCollection,
                    transaction
                )}
                groupingColDef={{
                    minWidth: 250
                }}
            />
        </Box>
    );
};

export default memo(TransactionHistory);
