import { useRef } from "react";
import type { ResourceResponse } from "types";
import { useLabeledSwitch, type UseLabeledSwitchArg } from "ui/atoms/LabeledSwitch";
import { useModal } from "ui/atoms/Modal";

type UseUndoableDialogArg<TResponse extends Pick<ResourceResponse<unknown>, 'success'>> = UseLabeledSwitchArg & {
    readonly handleProcess: (isChecked: boolean) => Promise<TResponse>;
};

export default function useUndoableDialog<TResponse extends Pick<ResourceResponse<unknown>, 'success'>>({
    handleProcess,
    ...labelledSwitchProps
}: UseUndoableDialogArg<TResponse>) {
    const { open, onOpen, onClose } = useModal();

    const stubHandler = (_: boolean) => { };

    const actionControlsRef = useRef({
        resolve: stubHandler,
        reject: stubHandler
    });

    const confirmationHandler = () => {
        onOpen();

        return new Promise<boolean>((resolve, reject) => {
            actionControlsRef.current = {
                resolve,
                reject
            };
        });
    };

    const labeledSwitchProps = useLabeledSwitch({
        ...labelledSwitchProps,
        confirmationHandler
    });

    const onProceed = async () => {
        const { success } = await handleProcess(labeledSwitchProps.getChecked());

        actionControlsRef.current.resolve(success);

        onClose();
    };

    const onCancel = () => {
        actionControlsRef.current.reject(false);
        onClose();
    };

    return {
        ...labeledSwitchProps,
        open,
        onProceed,
        onCancel
    };
};
