import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRoutes, ApiRouteTypes } from "consts/enpoints/api";
import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { ID, ResourceResponse, ThunkReturnType } from "types";
import type { UseImportResultArg } from "ui/organizms/MerchantPayouts/Taxable/FrontPanelWidget/ImportCSV";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import {
    getMerchantPayoutCalculations,
    getMerchantPayoutFiles,
    getMerchantPayoutFileTaxCalculations,
    getMerchantPayoutIncrements,
    getMerchantPayoutIncrementTaxes,
    getMerchantPayoutSummaryFiles,
    getTransactionsMerchantPayoutIncrementsPivot,
    getMerchantPayoutIncrementExchangeRates,
    postMerchantPayoutIncrementExchangeRatesBulk,
    postMerchantPayoutIncrementTaxesBulk,
    postMerchantPayoutIncrementExchangeRates,
    deleteMerchantPayoutIncrementExchangeRates,
    postMerchantPayoutFiles,
    mergeMerchantPayoutFiles,
    postMerchantPayoutSummaryFiles,
    putMerchantPayoutIncrements,
    postMerchantPayoutIncrementTaxes,
    deleteMerchantPayoutIncrementTaxes,
    paidMerchantPayoutFiles,
    deleteMerchantPayoutFiles,
    deleteMerchantPayoutSummaryFiles,
    getMerchantPayoutFilesById,
    putMerchantPayoutFiles
} from "./api";
import { sliceToken } from "./state";
import type {
    CalculationsResponse,
    FilesResponse,
    FileTaxCalculationsResponse,
    IncrementExchangeRatesResponse,
    IncrementsPivotResponse,
    IncrementsResponse,
    IncrementTaxesResponse,
    SummaryFilesResponse,
    IncrementExchangeRates,
    Files,
    SummaryFiles,
    Increments,
    AbstractFileEntity,
    IncrementTaxes,
    TogglePayMerchantPayoutFilesRequest,
    FileResponse
} from "./types";

type MerchantPayoutThunkFactoryArgs<TBody> = {
    readonly apiRouteType: ApiRouteTypes;
    readonly merchantPayoutApiService: (body: TBody) => Promise<Response>;
};

export const getMerchantPayoutSummaryFilesThunk: AsyncThunk<
    ThunkReturnType<SummaryFilesResponse>,
    string,
    {}
> = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutSummaryFiles,
    merchantPayoutApiService: getMerchantPayoutSummaryFiles
}) as AsyncThunk<
    ThunkReturnType<SummaryFilesResponse>,
    string,
    {}
>;

export const postMerchantPayoutSummaryFilesThunk = merchantPayoutThunkFactory<FormData>({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutSummaryFiles,
    merchantPayoutApiService: postMerchantPayoutSummaryFiles
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<SummaryFiles>>,
    FormData,
    {}
>;

export const mergeMerchantPayoutSummaryFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutSummaryFiles,
    merchantPayoutApiService: (body: FormData) => mergeMerchantPayoutFiles(
        body,
        ApiRoutes.PostMerchantPayoutSummaryFiles
    )
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
    FormData,
    {}
>;

export const getMerchantPayoutFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutFiles,
    merchantPayoutApiService: getMerchantPayoutFiles
}) as AsyncThunk<
    ThunkReturnType<FilesResponse>,
    string,
    {}
>;

export const getMerchantPayoutFilesByIdThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutFilesById,
    merchantPayoutApiService: getMerchantPayoutFilesById
}) as AsyncThunk<
    ThunkReturnType<FileResponse>,
    ID,
    {}
>;

export const postMerchantPayoutFilesThunk = merchantPayoutThunkFactory<FormData>({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutFiles,
    merchantPayoutApiService: postMerchantPayoutFiles
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<Files>>,
    FormData,
    {}
>;

export const putMerchantPayoutFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PutMerchantPayoutFiles,
    merchantPayoutApiService: putMerchantPayoutFiles
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<Files>>,
    Partial<Files>,
    {}
>;

export const mergeMerchantPayoutFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutFiles,
    merchantPayoutApiService: (body: FormData) => mergeMerchantPayoutFiles(
        body,
        ApiRoutes.PostMerchantPayoutFiles
    )
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
    FormData,
    {}
>;

export const togglePayMerchantPayoutFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PaidMerchantPayoutFiles,
    merchantPayoutApiService: paidMerchantPayoutFiles
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
    TogglePayMerchantPayoutFilesRequest,
    {}
>;

export const deleteMerchantPayoutFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.DeleteMerchantPayoutFiles,
    merchantPayoutApiService: deleteMerchantPayoutFiles
}) as AsyncThunk<
    ThunkReturnType<null>,
    ID,
    {}
>;

export const deleteMerchantPayoutSummaryFilesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.DeleteMerchantPayoutSummaryFiles,
    merchantPayoutApiService: deleteMerchantPayoutSummaryFiles
}) as AsyncThunk<
    ThunkReturnType<null>,
    ID,
    {}
>;

export const getMerchantPayoutCalculationsThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutCalculations,
    merchantPayoutApiService: getMerchantPayoutCalculations
}) as AsyncThunk<
    ThunkReturnType<CalculationsResponse>,
    string,
    {}
>;

export const getMerchantPayoutFileTaxCalculationsThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutFileTaxCalculations,
    merchantPayoutApiService: getMerchantPayoutFileTaxCalculations
}) as AsyncThunk<
    ThunkReturnType<FileTaxCalculationsResponse>,
    string,
    {}
>;

export const getTransactionsMerchantPayoutIncrementsPivotThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetTransactionsMerchantPayoutIncrementsPivot,
    merchantPayoutApiService: getTransactionsMerchantPayoutIncrementsPivot
}) as AsyncThunk<
    ThunkReturnType<IncrementsPivotResponse>,
    string,
    {}
>;

export const getMerchantPayoutIncrementTaxesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutIncrementTaxes,
    merchantPayoutApiService: getMerchantPayoutIncrementTaxes
}) as AsyncThunk<
    ThunkReturnType<IncrementTaxesResponse>,
    string,
    {}
>;
export const postMerchantPayoutIncrementTaxesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutIncrementTaxes,
    merchantPayoutApiService: postMerchantPayoutIncrementTaxes
}) as AsyncThunk<
    ThunkReturnType<IncrementTaxes>,
    Partial<IncrementTaxes>,
    {}
>;
export const postMerchantPayoutIncrementTaxesBulkThunk = merchantPayoutThunkFactory<FormData>({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutIncrementTaxesBulk,
    merchantPayoutApiService: postMerchantPayoutIncrementTaxesBulk
}) as AsyncThunk<
    ThunkReturnType<UseImportResultArg>,
    FormData,
    {}
>;
export const getMerchantPayoutIncrementsThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutIncrements,
    merchantPayoutApiService: getMerchantPayoutIncrements
}) as AsyncThunk<
    ThunkReturnType<IncrementsResponse>,
    string,
    {}
>;

export const putMerchantPayoutIncrementsThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PutMerchantPayoutIncrements,
    merchantPayoutApiService: putMerchantPayoutIncrements
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<Increments>>,
    Partial<Increments>,
    {}
>;

export const getMerchantPayoutIncrementExchangeRatesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.GetMerchantPayoutIncrementExchangeRates,
    merchantPayoutApiService: getMerchantPayoutIncrementExchangeRates
}) as AsyncThunk<
    ThunkReturnType<IncrementExchangeRatesResponse>,
    string,
    {}
>;

export const postMerchantPayoutIncrementExchangeRatesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutIncrementExchangeRates,
    merchantPayoutApiService: postMerchantPayoutIncrementExchangeRates
}) as AsyncThunk<
    ThunkReturnType<ResourceResponse<IncrementExchangeRates>>,
    Partial<IncrementExchangeRates>,
    {}
>;

export const postMerchantPayoutIncrementExchangeRatesBulkThunk = merchantPayoutThunkFactory<FormData>({
    apiRouteType: ApiRouteTypes.PostMerchantPayoutIncrementExchangeRatesBulk,
    merchantPayoutApiService: postMerchantPayoutIncrementExchangeRatesBulk
}) as AsyncThunk<
    ThunkReturnType<UseImportResultArg>,
    FormData,
    {}
>;

export const deleteMerchantPayoutIncrementExchangeRatesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.DeleteMerchantPayoutIncrementExchangeRates,
    merchantPayoutApiService: deleteMerchantPayoutIncrementExchangeRates
}) as AsyncThunk<
    ThunkReturnType<null>,
    ID,
    {}
>;
export const deleteMerchantPayoutIncrementTaxesThunk = merchantPayoutThunkFactory({
    apiRouteType: ApiRouteTypes.DeleteMerchantPayoutIncrementTaxes,
    merchantPayoutApiService: deleteMerchantPayoutIncrementTaxes
}) as AsyncThunk<
    ThunkReturnType<null>,
    ID,
    {}
>;
function merchantPayoutThunkFactory<TBody>({
    apiRouteType,
    merchantPayoutApiService
}: MerchantPayoutThunkFactoryArgs<TBody>) {
    return createAsyncThunk(
        concat([sliceToken, apiRouteType]),
        payloadCreator(merchantPayoutApiService),
        {
            condition: (_, { getState }) => {
                const { merchantPayouts } = getState() as RootState;
                const activeTab = merchantPayouts.activeTab;
                return ![ApiState.Pending].includes(merchantPayouts[activeTab].tableLoadingState);
            }
        }
    );
}
