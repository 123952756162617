import type { Transaction } from "features/transactions/types";
import moment from "moment";

// import { isBefore } from "date-fns";

export default function useFakeRedirect({ createdAt, via }: Pick<Transaction, "createdAt" | "via">) {
  const checkIfFakeRedirect = () => {
    //1. now < createdAt + 30 sec
    //2. hide after createdAt + 30sec - now)

    return Boolean(createdAt) && moment.utc().utcOffset(0, true).format() < moment(createdAt).add(30, "seconds").utcOffset(0, true).format() && via === "";
  };

  return {
    checkIfFakeRedirect,
  };
}
