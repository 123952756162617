import type { Mapped } from "types";

const fallbacks = ['fallback', 'default'];

export function isStatusValid<
    TMapping extends object,
    TValue extends string
>(statuses: TMapping, value: TValue) {
    return Object.keys(statuses).includes(value);
};

export function hasFallback<TMapping extends object>(statuses: TMapping) {
    return Object.keys(statuses).some(status =>
        fallbacks
            .includes(status.trim().toLocaleLowerCase())
    );
};

export function getFallbackValue<TValue extends object>(statuses: Mapped<TValue>): TValue | null {
    for (const fallback of [...fallbacks, 'Fallback', 'Default', 'FALLBACK', 'DEFAULT']) {
        if (fallback in statuses) {
            return statuses[fallback];
        }
    }

    return null;
}
