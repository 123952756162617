import { memo } from "react";
import type { MerchantBusiness } from "features/merchants/types";
import EditBusinessEntity from "../../shared/EditBusinessEntity";
import useMerchantDomains from "../../../useMerchantDomains";
import { EditMerchantBusiness as EditMerchantBusinessForm, useEditMerchantBusinessForm } from "ui/forms/Merchant/Domains";

const EditMerchantBusiness = (merchantBusiness: MerchantBusiness) => {
    const { businessName } = merchantBusiness;
    const { updateMerchantBusiness } = useMerchantDomains();
    const editMerchantBusinessForm = useEditMerchantBusinessForm({
        initialValues: merchantBusiness,
        onSaveOrCreate: merchantBusinessFormValues => updateMerchantBusiness({
            ...merchantBusiness,
            ...merchantBusinessFormValues
        })
    });

    const renderDialogTitle = () => (
        `Edit business ${businessName}`
    );

    return (
        <EditBusinessEntity
            form={editMerchantBusinessForm}
            renderDialogTitle={renderDialogTitle}
        >
            <EditMerchantBusinessForm
                {...editMerchantBusinessForm}
            />
        </EditBusinessEntity>
    );
};

export default memo(EditMerchantBusiness);
