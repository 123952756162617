import { useEffect, useRef, useState } from "react";
import { useModal } from "ui/atoms/Modal";
import { ConsumerActionType, useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";
import { TabIndex } from "./types";

export default function useMultiTabDialog() {
    const { open, onOpen, onClose } = useModal();

    const [isRequestProcessing, setRequestProcessing] = useState(false);

    const dialogClosingTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const { consumersState, resetConsumersState } = useConsumerContext();

    const consumersStateType = consumersState?.type;

    const canDialogOpen = () => Boolean(consumersState) && [
        ConsumerActionType.MoreDetails,
        ConsumerActionType.MarkAsKYCed,
        ConsumerActionType.ManageDuplicates
    ].includes(consumersStateType!);

    const modalControlsRef = useRef({
        onOpen,
        canDialogOpen
    });

    modalControlsRef.current = {
        onOpen,
        canDialogOpen
    };

    const getTabValue = () =>
        new Map<
            ConsumerActionType,
            TabIndex
        >([
            [ConsumerActionType.MoreDetails, TabIndex.MoreDetails],
            [ConsumerActionType.MarkAsKYCed, TabIndex.MarkAsKYCed],
            [ConsumerActionType.ManageDuplicates, TabIndex.ManageDuplicates]
        ])
            .get(consumersStateType ?? ConsumerActionType.MoreDetails);

    const getTabPayload = <TConsumerStatePayload>() => consumersState?.payload as TConsumerStatePayload;

    const onTabChange = () => { };

    const onCloseDialog = () => {
        if (isRequestProcessing) {
            return;
        }

        onClose();

        dialogClosingTimeoutRef.current = setTimeout(resetConsumersState, 500);
    };

    useEffect(() => {
        const { canDialogOpen, onOpen } = modalControlsRef.current;

        if (canDialogOpen()) {
            clearTimeout(dialogClosingTimeoutRef.current);

            onOpen()
        }
    }, [consumersStateType]);

    return {
        dialogRequestProcessing: [isRequestProcessing, setRequestProcessing] as const,
        isDialogOpen: open,
        getTabValue,
        getTabPayload,
        onTabChange,
        onCloseDialog,
        setRequestProcessing
    };
};
