import { capitalize } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import type { Option } from "types";
import { BooleanSwitchValue } from "consts/general";
import type { PaymentMethodSwitchComponentProps } from "./types";
import { useTypedSelector } from "hooks";
import { selectMerchantMethodPivotActiveDomain } from "features/general/selectors";

const PaymentMethodActivationSelector = ({
    paymentMethodSwitch
}: PaymentMethodSwitchComponentProps) => {
    const merchantMethodPivotActiveDomainOptions = useTypedSelector(selectMerchantMethodPivotActiveDomain);

    const {
        paymentMethodState,
        onChange,
        onClick
    } = paymentMethodSwitch;

    const renderMenu = () => [
        {
            value: `${BooleanSwitchValue.Off}`,
            children: paymentMethodState.enabled
                ? 'Disable'
                : 'Disabled'
        },
        ...merchantMethodPivotActiveDomainOptions.map(({ id, name }: Option) => ({
            value: `${id}`,
            children: capitalize(name)
        }))
    ];

    const getValue = () => `${paymentMethodState.MOR}` as number | "" | undefined;

    const getStyles = () => {
        if (!Object.is(getValue(), `${BooleanSwitchValue.Off}`)) {
            return {
                bgcolor: 'primary.main',
                color: '#fff',
                '& .MuiSvgIcon-root': {
                    color: '#fff'
                }
            };
        }
    };

    return (
        <Select
            value={getValue()}
            onChange={onChange}
            onClick={onClick}
            size='small'
            sx={{
                ...getStyles(),
                maxWidth: 200,
                width: '100%',
                justifySelf: 'flex-end',
                mr: 2
            }}
        >
            {renderMenu()
                .map(({ value, ...props }) => (
                    <MenuItem
                        {...props}
                        key={value}
                        value={value}
                    />
                ))}
        </Select>
    );
};

export default PaymentMethodActivationSelector;
