import { memo } from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { selectIsExportMerchantsLoading } from "features/merchants/selectors";
import { TableToolbar } from "ui/widgets/Table";
import AddEntity from "../shared/AddEntity";
import { ActiveInactiveSelector } from "../shared/ToolbarFilters";

type Props = {
    readonly onExport: () => void;
};

const Add = () => (
    <AddEntity
        size='small'
        startIcon={<AccountBoxIcon />}
    >
        Add Merchant
    </AddEntity>
);

const MerchantToolbar = ({ onExport }: Props) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportMerchantsLoading}
        add={Add}
        search={ActiveInactiveSelector}
    />
);

export default memo(MerchantToolbar);
