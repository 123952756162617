import React, {memo, useState} from "react";
import QueryBuilder from "ui/organizms/QueryBuilder";
import Conditions from "./Conditions";
import Aggregations from "./Aggregations";
import Transactions from "../../organizms/QueryBuilder/Transactions/QueryBuilderTransactions";

const QueryBuilderScreen= () => {
    const [updatedConditions, setUpdatedConditions] = useState<{
        dropdown1: string;
        dropdown2: string;
        inputValue: string;
        condition: string;
    }[]>([]);

    const [updatedAggregations, setUpdatedAggregations] = useState<{
        dropdown1: string;
        dropdown2: string;
        aggregation: string;
    }[]>([]);

    const handleUpdateConditions = (conditions: any[]) => {
        setUpdatedConditions(conditions);
    };

    const handleUpdateAggregations = (aggregations: any[]) => {
        setUpdatedAggregations(aggregations);
    };

    const handleConditionTextAreaChange = (value: string) => {
    };

    return (
        <div>
            <Conditions onUpdateRows={handleUpdateConditions}/>
            <Aggregations onUpdateAggregations={handleUpdateAggregations}/>
            <QueryBuilder updatedConditions={updatedConditions}
                          updatedAggregations={updatedAggregations}
                          onConditionTextAreaChange={handleConditionTextAreaChange}/>
            <Transactions/>

        </div>
    );
};

export default memo(QueryBuilderScreen);
