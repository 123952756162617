import type { DomainAction, PaginateResourceResponse, ResourceResponse } from "types";
import type { MerchantsMethodsPivot, MerchantsMethodsPivotHistory } from "features/pivots/types";
import { ApiState } from "infrastructure/api";
import { IDomainRepository } from "hooks";

export const enum MerchantPaymentMethodsActionType {
    FetchMerchantsMethods = 'MerchantPaymentMethodsActionType:FetchMerchantsMethods',
    UpdateMerchantsMethod = 'MerchantPaymentMethodsActionType:UpdateMerchantsMethod',
    FetchMerchantsMethodsHistory = 'MerchantPaymentMethodsActionType:FetchMerchantsMethodsHistory',
    Reset = 'MerchantPaymentMethodsActionType:Reset'
};

export type MerchantPaymentMethodsPivot = {
    readonly apiState: ApiState;
    data: PaginateResourceResponse<MerchantsMethodsPivot>['data'] | null;
};

export type MerchantPaymentMethodsPivotHistory = {
    readonly apiState: ApiState;
    readonly data: PaginateResourceResponse<MerchantsMethodsPivotHistory>['data'] | null;
};

export type MerchantPaymentMethodsRepositoryState = {
    readonly merchantsMethods: MerchantPaymentMethodsPivot;
    readonly merchantsMethodsHistory: MerchantPaymentMethodsPivotHistory;
};

export type MerchantPaymentMethodsRepositoryAction =
    | DomainAction<
        MerchantPaymentMethodsActionType.FetchMerchantsMethods,
        MerchantPaymentMethodsPivot
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.UpdateMerchantsMethod,
        MerchantPaymentMethodsPivot
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
        MerchantPaymentMethodsPivotHistory
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.Reset,
        undefined
    >;

export interface MerchantPaymentMethodsRepository extends IDomainRepository {
    readonly state: MerchantPaymentMethodsRepositoryState;

    readonly isMerchantMethodsLoading: boolean;

    readonly isMerchantMethodsHistoryLoading: boolean;

    readonly isMerchantMethodsError: boolean;

    readonly isMerchantMethodsHistoryError: boolean;

    readonly isMerchantMethodsEmpty: boolean;

    readonly isMerchantMethodsHistoryEmpty: boolean;

    readonly merchantsMethods: Array<MerchantsMethodsPivot>;

    readonly merchantsMethodsHistory: Array<MerchantsMethodsPivotHistory>;

    fetchMerchantsMethods(): Promise<void>;

    toggleEnableMerchantsMethodsPivot(merchantMethod: Pick<
        MerchantsMethodsPivot,
        | 'coreId'
        | 'enabled'
        | 'MOR'
    >): Promise<ResourceResponse<MerchantsMethodsPivot>>;

    updateMethodLimits(merchantMethod: Partial<
        Pick<
            MerchantsMethodsPivot,
            | 'coreId'
            | 'minTicketSize'
            | 'maxTicketSize'
            | 'maxNumberOfTransaction24'
            | 'maxNumberOfTransactionWeek'
            | 'maxNumberOfTransactionMonth'
            | 'maxTotalAmountWeek'
            | 'maxNumberOfTransactionProfile24'
            | 'adjustMaxNumberOfTransactionProfile24ByConsumerRisk'
            | 'maxTotalAmountProfile24'
            | 'adjustMaxTotalAmountProfile24ByConsumerRisk'
            | 'maxTotalAmountMonth'
            | 'adjustMaxTotalAmountMonthByConsumerRisk'
            | 'velocity'
            | 'adjustVelocityByConsumerRisk'
            | 'maxRiskLevel'
            | 'requireConsumerData'
            | 'limitsEnabled'
        >
    >): Promise<ResourceResponse<MerchantsMethodsPivot>>;

    fetchMerchantsMethodsHistory({ methodId }: Pick<MerchantsMethodsPivot, 'methodId'>): Promise<void>;
};
