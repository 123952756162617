import { RefObject } from "react";
import type { AbstractFileEntity } from "features/merchantPayouts/types";
import type { MenuImperativeProps } from "ui/atoms/Menu";
import ActionItem from "./ActionItem";
import { ActionMenuArg, MenuActions } from "./Props";
import useActionMenuItemRepository from "./useActionMenuItemRepository";

type UseActionMenuArg<T> = ActionMenuArg<T> & {
    readonly menuRef: RefObject<MenuImperativeProps | undefined>;
};

export default function useActionMenu<T extends AbstractFileEntity>({
    menuRef,
    row,
    requestMappings,
    getMenuItems
}: UseActionMenuArg<T>) {
    const { getActionMenuItemEntity } = useActionMenuItemRepository({
        requestMappings,
        row
    });

    return () =>
        getMenuItems(row).map((menuAction: MenuActions) => {
            const menuItemProps = getActionMenuItemEntity(menuAction)();

            return (
                <ActionItem
                    key={menuAction}
                    {...menuItemProps}
                    menuRef={menuRef}
                >
                    {menuItemProps.dialogSlot}
                </ActionItem>
            );
        });
};
