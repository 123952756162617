import { memo, ReactNode } from "react";
import Stack from '@mui/material/Stack';
import type { DetailsOverlayProps } from "./DetailOverlay";
import type { DetailContentProps } from "../Props";
import Legend from "./Legend";
import DetailOverlay from "./DetailOverlay";

type Props = DetailsOverlayProps
    & DetailContentProps
    & {
        readonly transactionActionsSlot: ReactNode;
    };


const DetailPanelContent = ({
    transactionActionsSlot,
    row,
    ...props
}: Props) => (
    <Stack
        sx={{ p: 2, height: 1, boxSizing: 'border-box' }}
        direction="column"
    >
        <Legend
            row={row}
            transactionActionsSlot={transactionActionsSlot}
        >
            <DetailOverlay
                {...props}
            />
        </Legend>
    </Stack>
);

export default memo(DetailPanelContent);
