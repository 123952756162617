import { memo } from "react";
import AccountApiSettingsForm, { type MerchantAccountApiSettingsProps } from "ui/forms/Merchant/AccountApiSettings";
import useMerchantDetail from "../../useMerchantDetail";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantAccountSettingsRepository } from "../repository";

const AccountApiSettings = () => {
    const { merchant } = useMerchantDetail();

    const repository = useMerchantDetailsTabContext<MerchantAccountSettingsRepository>();

    const generateApiKeys: MerchantAccountApiSettingsProps['onSaveOrCreate'] = (merchantAccountApiSettings, formikHelpers) =>
        repository.updateMerchantSettings({
            ...merchantAccountApiSettings,
            merchantId: merchant.coreId,
            coreId: repository.merchantSettings?.coreId
        })
            .then(({ data: values }) => values && formikHelpers!.resetForm({
                values
            }));

    return (
        <AccountApiSettingsForm
            initialValues={repository.accountApiSettings}
            onSaveOrCreate={generateApiKeys}
            fullName={merchant.fullName}
        />
    );
};

export default memo(AccountApiSettings);
