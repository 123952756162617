import { ApiState } from "infrastructure/api";
import { batch } from "react-redux";
import { useAppDispatch, useAppStore } from "./store";
import useFetchResource, { type FetchResourceSettings } from "./useFetchResource";
import type { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import type { RootState } from "infrastructure/store";

type ActionReturnType<TPayload> = Readonly<{
    payload: TPayload;
    type: string;
}>;

type UseFetchFeatureResourceArg<TResponse = Response> = FetchResourceSettings & {
    readonly resourceRequest: (queryString?: string) => Promise<TResponse>;
    readonly changeLoadingState: (loadingState: ApiState) => ActionReturnType<ApiState>;
    readonly changeSlice: <TResource extends unknown>(slice: TResource) => ActionReturnType<TResource>;
    readonly condition?: (getState: BaseThunkAPI<RootState, {}>['getState']) => boolean | Promise<boolean>;
};

export default function useFetchFeatureResource<
    TResponse = Response
>({
    resourceRequest,
    changeLoadingState,
    changeSlice,
    shouldFetchAll,
    perPage,
    condition
}: UseFetchFeatureResourceArg<TResponse>) {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const fetchResource = useFetchResource({
        shouldFetchAll,
        perPage
    });

    return async () => {
        const canFetch = condition
            ? await condition(store.getState)
            : true;

        if (!canFetch) {
            return;
        }

        dispatch(changeLoadingState(ApiState.Pending));

        try {
            const response = await fetchResource(resourceRequest)

            batch(() => {
                dispatch(changeSlice(response));
                dispatch(changeLoadingState(ApiState.Succeeded));
            });
        } catch (error) {
            dispatch(changeLoadingState(ApiState.Failed));
        }
    };
};
