import { compareIds } from "util/support";
import { stateFactory } from "./state";
import {
    MerchantDomainsActionType,
    type MerchantDomainsRepositoryState,
    type MerchantDomainsRepositoryAction,
    type MerchantDomainsTuple
} from "./types";

export function reducer(state: MerchantDomainsRepositoryState, action: MerchantDomainsRepositoryAction) {
    switch (action.type) {
        case MerchantDomainsActionType.FetchMerchantDomains: {
            return {
                ...state,
                ...action.payload
            };
        }

        case MerchantDomainsActionType.UpdateMerchantBusiness: {
            return {
                ...state,
                businesses: state.businesses.map(merchantBusiness => {
                    if (compareIds(merchantBusiness.coreId, Number(action.payload.coreId))) {
                        return {
                            ...merchantBusiness,
                            ...action.payload
                        };
                    }

                    return merchantBusiness;
                })
            };
        }

        case MerchantDomainsActionType.UpdateMerchantDomain: {
            const currentMerchantId = state.businesses.find(merchantBusiness =>
                Boolean(merchantBusiness.merchantId)
            )?.merchantId;

            const merchantId = action.payload.businessMerchantId;
            const businessId = action.payload.merchantBusinessId;
            const domainId = action.payload.coreId;

            if (!currentMerchantId || !businessId || !merchantId || !domainId) {
                return state;
            }

            // Outside of the current merchant or merchant businesses
            if (
                !state.domains.has(businessId) ||
                !compareIds(currentMerchantId, merchantId)
            ) {
                return {
                    ...state,
                    domains: new Map([
                        ...Array.from(state.domains)
                            .map(([merchantBusinessIdKey, domains]) => [
                                merchantBusinessIdKey,
                                domains.filter(merchantDomain => !compareIds(merchantDomain.coreId, domainId))
                            ] as MerchantDomainsTuple),
                    ])
                };
            }

            // Moved to another merchant business within the same merchant
            const businessDomains = state.domains.get(businessId)!;
            if (!businessDomains.find(merchantDomain => compareIds(merchantDomain.coreId, domainId))) {
                return {
                    ...state,
                    domains: new Map([
                        ...Array.from(state.domains)
                            .map(([merchantBusinessIdKey, domains]) => [
                                merchantBusinessIdKey,
                                domains.filter(merchantDomain => !compareIds(merchantDomain.coreId, domainId))
                            ] as MerchantDomainsTuple),
                        [businessId, [...businessDomains, action.payload]]
                    ])
                };
            }

            return {
                ...state,
                domains: new Map([
                    ...Array.from(state.domains)
                        .map(([merchantBusinessIdKey, domains]) => [
                            merchantBusinessIdKey,
                            domains.map(merchantDomain => {
                                if (compareIds(merchantDomain.coreId, domainId)) {
                                    return {
                                        ...merchantDomain,
                                        ...action.payload
                                    };
                                }

                                return merchantDomain;
                            })
                        ] as MerchantDomainsTuple),
                ])
            };
        }

        case MerchantDomainsActionType.CreateMerchantBusiness: {
            return {
                ...state,
                businesses: [...state.businesses, action.payload],
                domains: new Map([
                    ...Array.from(state.domains),
                    [action.payload.coreId, []]
                ])
            };
        }

        case MerchantDomainsActionType.CreateMerchantDomain: {
            return {
                ...state,
                domains: new Map([
                    ...Array.from(state.domains),
                    [action.payload.merchantBusinessId, [
                        ...state.domains.get(action.payload.merchantBusinessId) || [],
                        action.payload
                    ]]
                ])
            };
        }

        case MerchantDomainsActionType.DeleteMerchantBusiness: {
            return {
                ...state,
                businesses: state.businesses
                    .filter(merchantBusiness =>
                        !compareIds(merchantBusiness.coreId, action.payload.coreId)
                    ),
                domains: new Map(
                    Array.from(state.domains)
                        .filter(([merchantBusinessId]) =>
                            !compareIds(merchantBusinessId, action.payload.coreId)
                        )
                )
            };
        }

        case MerchantDomainsActionType.DeleteMerchantDomain: {
            return {
                ...state,
                domains: new Map([
                    ...Array.from(state.domains),
                    [action.payload.merchantBusinessId, [
                        ...(state.domains.get(action.payload.merchantBusinessId) || [])
                            .filter(merchantDomain =>
                                !compareIds(merchantDomain.coreId, action.payload.coreId)
                            )
                    ]]
                ])
            };
        }

        case MerchantDomainsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
