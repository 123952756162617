import { createContext, memo, useEffect, useRef } from "react";
import { useTypedSelector } from "hooks";
import { selectProfileLoadingState } from "features/users/selectors";
import CassetteLayout from "ui/layouts/Cassette";
import {
    Active as ActiveMerchants,
    Inactive as InactiveMerchants,
    Total
} from "./Merchants";
import {
    Active as ActiveUsers,
    Inactive as InactiveUsers,
    LoggedToday
} from "./Users";
import useStatisticCassettes from "./useStatisticCassettes";
import { ApiState } from "infrastructure/api";

type StatisticCassettesContextType = Omit<ReturnType<typeof useStatisticCassettes>, 'fetch'>;

export const StatisticCassettesContext = createContext({} as StatisticCassettesContextType);

const StatisticCassettes = () => {
    const profileLoadingState = useTypedSelector(selectProfileLoadingState);

    const { fetch, ...rest } = useStatisticCassettes();

    const fetchRef = useRef(fetch);
    fetchRef.current = fetch;

    useEffect(() => {
        fetchRef.current('');
    }, []);

    useEffect(() => {
        [ApiState.Succeeded].includes(profileLoadingState) && fetchRef.current('');
    }, [profileLoadingState]);

    return (
        <StatisticCassettesContext.Provider
            value={rest}
        >
            <CassetteLayout
                sx={{
                    gridTemplateColumns: 'repeat(6, minmax(100px, 1fr))',
                }}
            >
                <Total />
                <ActiveMerchants />
                <InactiveMerchants />
                <ActiveUsers />
                <InactiveUsers />
                <LoggedToday />
            </CassetteLayout>
        </StatisticCassettesContext.Provider>
    );
};

export default memo(StatisticCassettes);
