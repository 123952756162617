import { useAppDispatch, useRequest } from "hooks";
import { changeExportLoading, updateEntityById } from "./slice";
import type { Consumer, ConsumersResponse } from "./types";
import { getConsumersThunk } from "./thunks";
import type { ThunkReturnType } from "types";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";

const useActions = () => {
    const dispatch = useAppDispatch();
    const getConsumers = useRequest<ThunkReturnType<ConsumersResponse>, string>({
        thunk: getConsumersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumers
    });

    const updateConsumerById = (consumer: Partial<Consumer>) =>
        dispatch(updateEntityById(consumer));

    return {
        getConsumers,
        downloadCsv,
        updateConsumerById
    };
};

export default useActions;
