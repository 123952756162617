import { useEffect, useRef } from "react";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { useFeatureFiltersContext } from "ui/widgets/Filters";
import useMerchantDetailsTabContext from "../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "./repository";

type MiddlewarePlugins = {
    readonly useTags?: Array<
        (merchantPaymentMethods: Array<[string, MerchantsMethodsPivot[]]>) => Array<[string, MerchantsMethodsPivot[]]>
    >;
    readonly useMethods?: Array<
        (merchantPaymentMethods: Array<MerchantsMethodsPivot>) => Array<MerchantsMethodsPivot>
    >;
};

export default function useMerchantPaymentMethods() {
    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const { withFilters } = useFeatureFiltersContext();

    useEffect(() => {
        repositoryRef.current
            .fetchMerchantsMethods();
    }, []);

    const getFilteredMerchantPaymentMethodsEntries = () => Object.entries<Array<MerchantsMethodsPivot>>(
        (Object as any).groupBy(
            withFilters<MerchantsMethodsPivot>(repository.merchantsMethods),
            ({ paymentMethodName, paymentMethodTag }: MerchantsMethodsPivot) => paymentMethodTag || paymentMethodName
        )
    );

    const getMerchantPaymentMethods = ({
        useTags = [],
        useMethods = []
    }: MiddlewarePlugins = {}) => useTags.reduce(
        (merchantPaymentMethodsEntries, middleware) =>
            middleware(merchantPaymentMethodsEntries)
                .map(([key, methods]) => [
                    key,
                    useMethods.reduce((methods, middleware) => middleware(methods), methods)
                ] as [string, Array<MerchantsMethodsPivot>]),
        getFilteredMerchantPaymentMethodsEntries()
    );

    return {
        repository,
        getMerchantPaymentMethods,
        getFilteredMerchantPaymentMethodsEntries
    };
};
