import { convertArrayToString } from "util/transformers";
import type { Role, Roles, Users } from "./types";
import { Status } from "consts/users";

export const decorateRoleOptions = (roles: Roles = []) =>
    roles.map(({ coreId, roleName, roleDescription }: Role) => ({
        id: coreId,
        name: convertArrayToString([roleName, roleDescription], ' - ')
    })) ?? [];

export const selectActiveUsers = (users: Users) => users.filter(({ status }) =>
    [Status.Active].includes(status as Status)
);

export const selectInactiveUsers = (users: Users) => users.filter(({ status }) =>
    ![Status.Active].includes(status as Status)
);
