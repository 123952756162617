import { useEffect, useRef } from 'react';
import useMerchantActions from "features/merchants/useMerchantActions";
import usePivotActions from "features/pivots/useActions";
import useMerchantPayoutActions from "features/merchantPayouts/useActions";
import { date, FilterVariant } from 'consts/filters';
import {
    multi,
    single,
    text,
    date as merchantPayoutsDate,
    merchantPayoutTabValueMapping
} from 'consts/merchantPayouts';
import { useTypedSelector } from 'hooks';
import { selectIsMerchantPayoutLoading, selectMerchantPayoutsActiveTab } from 'features/merchantPayouts/selectors';
import { selectMerchantPayoutIncrementsVia } from 'features/general/selectors';
import { TabManager } from 'util/tab';

export default function useMerchantPayouts() {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);

    const activeTab = useTypedSelector(selectMerchantPayoutsActiveTab);
    const isMerchantPayoutsLoading = useTypedSelector(selectIsMerchantPayoutLoading);

    const { getAllMerchants } = useMerchantActions();
    const { getAllMerchantsMethods } = usePivotActions();

    const {
        onChangeTab,
        getMerchantPayoutSummaryFiles,
        getMerchantPayoutFiles,
        getMerchantPayoutCalculations,
        getMerchantPayoutIncrements,
        getTransactionsMerchantPayoutIncrementsPivot,
        getMerchantPayoutFileTaxCalculations,
        getMerchantPayoutIncrementTaxes,
        getMerchantPayoutIncrementExchangeRates
    } = useMerchantPayoutActions();

    const initRef = useRef({
        getAllMerchants,
        getAllMerchantsMethods
    });

    useEffect(() => {
        const {
            getAllMerchants,
            getAllMerchantsMethods
        } = initRef.current;

        getAllMerchants();
        getAllMerchantsMethods();
    }, []);

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.Text, text],
        [FilterVariant.DateRange, [...date, ...merchantPayoutsDate]],
        [FilterVariant.MultiOption, multi],
        [FilterVariant.SingleOption, single]
    ]);

    const getTabValue = () =>
        TabManager.getTabValue(merchantPayoutTabValueMapping, activeTab);

    const getFiltersModelOverrides = () => ({
        Via: merchantPayoutIncrementsVia
    });

    return {
        isMerchantPayoutsLoading,
        filterTypes,
        onChangeTab,
        getMerchantPayoutSummaryFiles,
        getMerchantPayoutFiles,
        getMerchantPayoutCalculations,
        getMerchantPayoutIncrements,
        getTransactionsMerchantPayoutIncrementsPivot,
        getMerchantPayoutFileTaxCalculations,
        getMerchantPayoutIncrementTaxes,
        getMerchantPayoutIncrementExchangeRates,
        getTabValue,
        getFiltersModelOverrides
    };
};
