import { memo, ComponentType } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ServicesControlPanel from "./ServicesControlPanel";
import FaceVerification from "./FaceVerification";
import IDVerification from "./IDVerification";
import AMLScreening from "./AMLScreening/AMLScreening";
import AddressVerification from "./AddressVerification/AddressVerification";
import PhoneVerification from "./PhoneVerification/PhoneVerification";
import { KYCServiceType } from "consts/kyc";
import type { ServiceProps } from "./Service";
import useFetchServices from "./useFetchServices";
import { useParams } from "react-router-dom";
import { Filters } from "consts/transactions";

const kycServicesComponents = new Map<KYCServiceType, ComponentType<Omit<ServiceProps, 'serviceLabel'>>>()
    .set(KYCServiceType.FaceVerification, FaceVerification)
    .set(KYCServiceType.IDVerification, IDVerification)
    .set(KYCServiceType.AMLVerification, AMLScreening)
    .set(KYCServiceType.AddressVerification, AddressVerification)
    .set(KYCServiceType.PhoneVerification, PhoneVerification);

const Services = () => {
    const params = useParams();

    const servicesLayoutSx = {
        display: 'grid',
        gridTemplateColumns: '165px repeat(3, 1fr) 180px',
        alignItems: 'center',
        columnGap: '35px',
        px: '20px'
    };

    const getkycServiceComponents = () => Array.from(kycServicesComponents.entries());

    useFetchServices({
        merchantId: params[Filters.merchantId]
    });

    return (
        <Card
            sx={{
                py: '27px',
                px: 0,
                boxShadow: theme => theme.shadows[5]
            }}
        >
            <Box
                sx={{
                    px: '17px',
                }}
            >
                <Box
                    sx={servicesLayoutSx}
                >
                    {['Type', 'Options', 'Trx. limit', 'Amount Limit', 'Condition']
                        .map(heading => (
                            <Box
                                key={heading}
                                sx={{
                                    color: 'text.disabled',
                                    pb: '10px',
                                }}
                            >
                                {heading}
                            </Box>
                        ))}
                </Box>
                {getkycServiceComponents()
                    .map(([kycServiceType, ServiceComponent]) => (
                        <ServiceComponent
                            key={kycServiceType}
                            sx={servicesLayoutSx}
                            kycTypeId={kycServiceType}
                        />
                    ))}
            </Box>
            <Divider />
            <ServicesControlPanel />
        </Card>
    );
};

export default memo(Services);
