export const Filters = {
    kycFileKey: 'kycFileKey',
    fileKey: 'fileKey',
    merchantId: 'merchantId',
    totalProcessedRows: 'totalProcessedRows',
    totalIgnoredRows: 'totalIgnoredRows',
    totalRows: 'totalRows',
    isQueued: 'isQueued'
};

export const enum KYCStatus {
    Pending = 'PENDING',
    Success = 'COMPLETED',
    Failed = 'FAILED',
    NA = 'N/A',
    ManuallyCompleted = 'MANUALLY COMPLETED',
    ManuallyPending = 'MANUALLY PENDING',
    Triggered = 'TRIGGERED'
};

export const enum ManualBulkKycImportStatus {
    Ignored = 'IGNORED',
    Created = 'CREATED',
    NotFound = 'NOT_FOUND',
    //refactor asap Renalda
    NotCreated = 'NOT_CREATED'
};

export const enum KYCProcessingStatus {
    NA = -1,
    Pending = 0,
    Success,
    Failed
}

export const enum KYCServiceType {
    FaceVerification = 0,
    IDVerification,
    AddressVerification,
    PhoneVerification,
    AMLVerification,
    EIDVerification
}

export const enum KYCProviders {
    Manual = 'manual',
    ShuftiPro = 'shuftiPro'
}

export const enum KYCServiceState {
    Off,
    Strict,
    Soft
}

export const kycServiceTransactionLimit = new Map<KYCServiceState, null | number>([
    [KYCServiceState.Off, null],
    [KYCServiceState.Strict, 0],
    [KYCServiceState.Soft, 1]
]);

export const enum KYCSettingsServicePropertyName {
    IncludeIdVerifiedConsumers = 'includeIdVerifiedConsumers',
    DocumentBacksideProofRequired = 'documentBacksideProofRequired',
    DocumentShowOcrForm = 'documentShowOcrForm',
    AddressShowOcrForm = 'addressShowOcrForm'
};

export const KYCSettingsDefaults = {
    [KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers]: 0,
    [KYCSettingsServicePropertyName.DocumentBacksideProofRequired]: 0,
    [KYCSettingsServicePropertyName.DocumentShowOcrForm]: 1,
    [KYCSettingsServicePropertyName.AddressShowOcrForm]: 1
} as const;


export const text = [
    Filters.kycFileKey,
    Filters.fileKey,
];

export const multi = [
    Filters.merchantId,
];
