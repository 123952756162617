import type { Merchant } from "features/merchants/types";
import { PropsWithChildren, createContext, memo } from "react";
import useMerchantDetail from "../useMerchantDetail";

export const MerchantContext = createContext({} as Merchant);

const MerchantProvider = ({ children }: PropsWithChildren) => {
    const { merchant } = useMerchantDetail();

    return (
        <MerchantContext.Provider
            value={merchant}
        >
            {children}
        </MerchantContext.Provider>
    )
};

export default memo(MerchantProvider);
