import { memo } from "react";
import usePaymentMethodSwitch from "./usePaymentMethodSwitch";
import PaymentMethodActivationSelector from "./PaymentMethodActivationSelector";
import PaymentMethodHistoryConfirmationDialog from "./PaymentMethodHistoryConfirmationDialog";
import type { PaymentMethodSwitchComponentProps } from "./types";
import useMerchantDetailsTabContext from "../../../../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "../../../repository";

const PaymentMethodSwitch = (props: PaymentMethodSwitchComponentProps['merchantMethod']) => {
    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();

    const paymentMethodSwitchComponentProps = {
        paymentMethodSwitch: usePaymentMethodSwitch({
            ...props,
            change: state => repository.toggleEnableMerchantsMethodsPivot({
                ...props,
                ...state
            })
        }),
        merchantMethod: props
    };

    return (
        <>
            <PaymentMethodActivationSelector
                {...paymentMethodSwitchComponentProps}
            />
            <PaymentMethodHistoryConfirmationDialog
                {...paymentMethodSwitchComponentProps}
            />
        </>
    );
};

export default memo(PaymentMethodSwitch);
