import { memo, type PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import Box, { type BoxProps } from "@mui/material/Box";

const AddPayoutAccountForm = ({ title, children, ...props }: PropsWithChildren<BoxProps>) => (
    <Box
        {...props}
    >
        <Typography
            variant='h6'
            color='textPrimary'
            gutterBottom
        >
            {title}
        </Typography>
        {children}
    </Box>
);

export default memo(AddPayoutAccountForm);
