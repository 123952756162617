import { KYCServiceType } from "consts/kyc";
import type { KYCSettings } from "features/kyc/types";
import type { MerchantKYCService } from "features/merchants/types";
import { ApiState } from "infrastructure/api";
import type { DomainAction, ID, Option } from "types";

type KYCTypeId =
    | KYCServiceType.FaceVerification
    | KYCServiceType.IDVerification
    | KYCServiceType.AddressVerification
    | KYCServiceType.PhoneVerification
    | KYCServiceType.AMLVerification
    | KYCServiceType.EIDVerification;

export type KYCSettingsState = {
    kycSettingsLoadingState: ApiState;
    isKycSettingsChanged: boolean;
    initialKYCServicesSnapshot: KYCSettingsState['kycServices'];
    kycProvider: string;
    readonly kycServices: {
        readonly [kycTypeId in KYCTypeId]: Pick<
            MerchantKYCService,
            | 'kycLimit'
            | 'summedAmountEurLimit'
        > & {
            readonly kycSettings: Array<Option>;
            readonly isActive?: boolean;
        };
    };
};

export const enum KYCSettingsActionTypes {
    KYCSettingsInit = '[INIT KYC SETTINGS]',
    KYCSettingsApiState = '[KYC API STATE]',
    KYCSettingsTurnOffAllServices = '[KYC TURN OFF ALL SERVICES]',
    UpdateTrxLimit = '[CHANGE KYC TRX LIMIT]',
    UpdateSummedAmountEurLimit = '[UPDATE SUMMED AMOUNT EUR LIMIT]',
    UpdateAdditionalOptions = '[UPDATE KYC ADDITIONAL OPTIONS]',
    UpdateKYCProvider = '[UPDATE KYC PROVIDER]',
    SyncInitialKYCServicesSnapshot = '[UPDATE INITIAL KYC SERVICES SNAPSHOT]'
};

export type KYCSettingsInitActionPayload = {
    kysServices: Array<MerchantKYCService>;
    kycSettings: KYCSettings;
};

export type KYCSettingsUpdateTrxLimitActionPayload = Pick<
    MerchantKYCService,
    | 'kycTypeId'
    | 'kycLimit'
>;

export type KYCSettingsUpdateSummedAmountEurLimitActionPayload = Pick<
    MerchantKYCService,
    | 'kycTypeId'
    | 'summedAmountEurLimit'
>;

export type KYCSettingsUpdateAdditionalOptionsActionPayload = Pick<
    MerchantKYCService,
    | 'kycTypeId'
> & {
    readonly kycSettings: KYCSettingsState['kycServices'][KYCServiceType]['kycSettings'];
};

export type UpdateInitialKYCServicesSnapshot = Array<ID>;

export type KYCSettingsAction =
    | DomainAction<
        KYCSettingsActionTypes.KYCSettingsInit,
        KYCSettingsInitActionPayload
    >
    | DomainAction<
        KYCSettingsActionTypes.KYCSettingsApiState,
        ApiState
    >
    | DomainAction<
        KYCSettingsActionTypes.UpdateTrxLimit,
        KYCSettingsUpdateTrxLimitActionPayload
    >
    | DomainAction<
        KYCSettingsActionTypes.UpdateSummedAmountEurLimit,
        KYCSettingsUpdateSummedAmountEurLimitActionPayload
    >
    | DomainAction<
        KYCSettingsActionTypes.UpdateAdditionalOptions,
        KYCSettingsUpdateAdditionalOptionsActionPayload
    >
    | DomainAction<
        KYCSettingsActionTypes.UpdateKYCProvider,
        string
    >
    | DomainAction<
        KYCSettingsActionTypes.SyncInitialKYCServicesSnapshot,
        undefined
    >;
