import type { Serie } from "@nivo/line";
import type { ElasticSearchResourceResponse } from "types";
import type { LinearDiagramProps } from "../types";
import { useEffect, useRef, useState } from "react";

type UseLinearDiagramState = {
    total: number;
    data: Serie[];
    columns: ElasticSearchResourceResponse<string>['data']['columns'];
};

type UseLinearDiagramArg = Pick<LinearDiagramProps, 'isLoading'> & {
    readonly onLoad: () => UseLinearDiagramState;
};

export default function useLinearDiagram({ isLoading, onLoad }: UseLinearDiagramArg) {
    const onLoadRef = useRef(onLoad);
    onLoadRef.current = onLoad;

    const [{ total, data, columns }, setState] = useState<UseLinearDiagramState>({
        total: 0,
        data: Array.from<Serie>([]),
        columns: []
    });

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const {
            total,
            data,
            columns = []
        } = onLoadRef.current();

        setState({
            total,
            data,
            columns
        });
    }, [isLoading]);

    return {
        total,
        data,
        columns
    };
};
