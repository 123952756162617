import { memo } from 'react';
import numeral from 'numeral';
import Box from '@mui/material/Box';
import { CurrencySymbol } from 'consts/general';
import type { TopMerchantSucceededTransactionsByUniqueEmailRecord } from './types';
import useTopMerchants from './useTopMerchants';

type Props = Pick<TopMerchantSucceededTransactionsByUniqueEmailRecord, 'amount'>;

const Amount = ({ amount }: Props) => {
    const { getPercentageFromTotalAmount } = useTopMerchants();

    return (
        <Box
            sx={{
                fontWeight: 600,
                whiteSpace: 'nowrap',
                textAlign: 'right'
            }}
        >
            {CurrencySymbol.EUR} {numeral(amount)
                .format(`0,0.00`)}
            <Box
                sx={{
                    color: 'text.secondary',
                    mt: .5
                }}
            >
                {numeral(getPercentageFromTotalAmount(amount))
                    .format('%0.00')}
            </Box>
        </Box>
    );
};

export default memo(Amount);
