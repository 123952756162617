import { useEffect, useRef } from "react";
import useMerchantDetailsTabContext from "../../../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "../../repository";
import type { MerchantsMethodsPivot, MerchantsMethodsPivotHistory } from "features/pivots/types";
import type { GridColDef, GridRowIdGetter, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { capitalize } from "@mui/material";
import { DEFAULT_PAGE_SIZE } from "consts/table";
import { UiDateTimeFormatter, formatDate } from "util/formaters";

export default function useMerchantPaymentMethodHistory({ methodId }: MerchantsMethodsPivot) {
    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    useEffect(() => {
        if (!methodId) {
            return;
        }

        repositoryRef.current
            .fetchMerchantsMethodsHistory({ methodId });
    }, [methodId]);

    const dateTimeValueFormatter = ({ value }: GridValueFormatterParams<string>) =>
        formatDate(value, { pattern: UiDateTimeFormatter.Write });

    const columns: GridColDef[] = [
        {
            field: 'isEnabled',
            headerName: 'State',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: ({ value }) => value ? 'Enabled' : 'Disabled',
            sortable: true
        },
        {
            field: 'MOR',
            headerName: 'Domain',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: ({ value }) => capitalize(value || 'Default'),
            sortable: true
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: dateTimeValueFormatter,
            sortable: true
        },
        {
            field: 'updatedAt',
            headerName: 'Date updated',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: dateTimeValueFormatter,
            sortable: true
        }
    ];

    const getRowId: GridRowIdGetter<MerchantsMethodsPivotHistory> = ({ historyCreatedAt }) => historyCreatedAt;

    return {
        columns,
        rows: repository.merchantsMethodsHistory,
        loading: repository.isMerchantMethodsHistoryLoading,
        getRowId,
        pagination: true,
        initialState: {
            pagination: {
                pageSize: DEFAULT_PAGE_SIZE
            }
        },
        rowsPerPageOptions: [
            DEFAULT_PAGE_SIZE,
            DEFAULT_PAGE_SIZE * 5,
            DEFAULT_PAGE_SIZE * 10
        ]
    };
};
