import { memo } from "react";
import Button from "@mui/material/Button";
import GetAppIcon from '@mui/icons-material/GetApp';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "ui/molecules/TableGrids/Table/Toolbar";
import { useTypedSelector } from "hooks";
import type { TableToolbarProps } from "../types";

const TableToolbar = ({
    onExport,
    selectIsExportLoading,
    ...restComponents
}: TableToolbarProps) => {
    const isExportLoading = useTypedSelector(selectIsExportLoading);

    return (
        <Box>
            {isExportLoading && <LinearProgress />}
            <Toolbar
                export={() => (
                    <Button
                        size='small'
                        startIcon={<GetAppIcon />}
                        onClick={onExport}
                        disabled={isExportLoading}
                    >
                        Export
                    </Button>
                )}
                {...restComponents}
            />
        </Box>
    );
};

export default memo(TableToolbar);
