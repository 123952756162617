import { useTypedSelector } from "hooks";
import { memo, MouseEvent } from "react";
import Button from 'ui/atoms/Button';
import { selectIsMerchantPayoutEntityLoading } from 'features/merchantPayouts/selectors';
import useImportCSVContext from "./useImportCSVContext";

const ImportCSVAction = () => {
    const onImportCSV = useImportCSVContext();
    const isLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);

    const onResetValue = ({ currentTarget }: MouseEvent<HTMLInputElement>) => {
        currentTarget.value = '';
    };

    return (
        <Button
            variant="outlined"
            component='label'
            disabled={isLoading}
        >
            <input
                onClick={onResetValue}
                onChange={onImportCSV}
                hidden
                accept=".csv"
                multiple
                type="file"
            />
            Import .CSV
        </Button>
    );
};

export default memo(ImportCSVAction);
