import type { ComponentType, ReactNode } from "react";
import type { FormikValues } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import type useForm from "hooks/useForm";
import { getFormInputProps, isFormChanged } from "util/forms";
import { TriggerableDialog } from "ui/molecules/Dialog";

type Props<T extends FormikValues, TTextFieldComponentProps = TextFieldProps> = {
    readonly form: ReturnType<typeof useForm<T>>;
    readonly TextFieldProps: TextFieldProps;
    readonly ButtonProps: LoadingButtonProps;
    readonly DialogActionProps: LoadingButtonProps;
    readonly renderDialogTitle: (form: ReturnType<typeof useForm<T>>) => ReactNode;
    readonly TextFieldComponent?: ComponentType<TTextFieldComponentProps>;
};

const AddBusinessUnit = <T extends FormikValues>({
    form,
    TextFieldProps,
    ButtonProps,
    DialogActionProps,
    renderDialogTitle,
    TextFieldComponent = TextField
}: Props<T>) => (
    <Box
        component='form'
        autoComplete='off'
        onSubmit={form.handleSubmit}
        sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1
        }}
    >
        <TextFieldComponent
            required
            fullWidth
            size='small'
            variant='outlined'
            {...TextFieldProps}
            {...getFormInputProps(TextFieldProps.name, form)}
            value={form.values[String(TextFieldProps.name)]}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
        />
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    {...ButtonProps}
                    disabled={!form.isValid || !isFormChanged(form)}
                    loading={form.isSubmitting}
                    onClick={onClick}
                />
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        onClick={() => (
                            form.submitForm()
                                .then(() => form.resetForm())
                                .finally(onClose)
                        )}
                        loading={form.isSubmitting}
                        {...DialogActionProps}
                    />
                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                p: 0,
                                border: 'none'
                            }
                        }
                    }
                },
                titleSlot: renderDialogTitle(form)
            }}
        />
    </Box>
);

export default AddBusinessUnit;
