import { PayloadAction } from "@reduxjs/toolkit";
import { Draft, WritableDraft } from "immer/dist/internal";
import { ApiState } from "infrastructure/api";
import { exportableStateGuard, sliceStateGuard, tabbableStateGuard } from "./guards";
import type { CoreBankingEntity } from "types";
import { compareIds } from "util/support";

export default function sliceMixin<T extends object, TTabs = undefined>(initialState: T) {
    return {
        saveTabSearchParams: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<string>>) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].searchParams = payload;
            }
        },
        changeTab: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<TTabs>>) => {
            if (tabbableStateGuard(state)) {
                state.activeTab = String(payload);
            }
        },
        changeExportLoading: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<ApiState>>) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].exportLoadingState = payload;
            }

            if (exportableStateGuard(state)) {
                state.exportLoadingState = payload;
            }
        },
        resetState: (state: WritableDraft<T>) => {
            Object.assign(state, initialState);
        },
        updateOrAddEntityById: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<CoreBankingEntity>>) => {
            let isUpdated = false;

            const updateById = (resourceEntity: CoreBankingEntity) => {
                if (compareIds(resourceEntity.coreId, payload.coreId)) {
                    isUpdated = true;

                    return ({
                        ...resourceEntity,
                        ...payload
                    });
                }

                return resourceEntity;
            };

            if (tabbableStateGuard(state)) {
                const { tableSlice } = state[state.activeTab];
                if (tableSlice) {
                    state[state.activeTab].tableSlice!.data = tableSlice.data.map(updateById);

                    if (!isUpdated) {
                        state[state.activeTab].tableSlice!.data.unshift(payload);
                        state[state.activeTab].tableSlice!.to += 1;
                        state[state.activeTab].tableSlice!.total += 1;
                    }
                }
            }

            if (sliceStateGuard(state)) {
                const tableSlice = state.tableSlice;

                if (tableSlice) {
                    state.tableSlice!.data = tableSlice.data.map(updateById);

                    if (!isUpdated) {
                        state.tableSlice!.data.unshift(payload);
                        state.tableSlice!.to += 1;
                        state.tableSlice!.total += 1;
                    }
                }
            }
        }
    };
};
