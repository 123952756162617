import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import {
    createOrUpdateUserPreferences,
    getBitsoBanks,
    getConstants,
    getCountries,
    getDomains,
    getUserPreferences
} from "features/general/api";
import type { RootState } from "infrastructure/store";
import { ApiState } from "infrastructure/api";
import type {
    BitsoBanksResponse,
    ConstantsResponse,
    CountriesPaginatedResponse,
    DomainsResourceResponse,
    UserPreferencesRequest,
    UserPreferencesResponse
} from "./types";
import type { ID, ThunkReturnType } from "types";
import { selectIsNotMor } from "features/auth/selectors";

export const getConstantsThunk: AsyncThunk<
    ThunkReturnType<ConstantsResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConstants]),
    payloadCreator(getConstants),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(general.constantsLoadingState);
        }
    }
);

export const getBitsoBanksThunk: AsyncThunk<
    ThunkReturnType<BitsoBanksResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetBitsoBanks]),
    payloadCreator(getBitsoBanks),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;

            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(general.bitsoBanksLoadingState);
        }
    }
);

export const getDomainsThunk: AsyncThunk<
    ThunkReturnType<DomainsResourceResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetApiDomains]),
    payloadCreator(getDomains),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return (
                selectIsNotMor(getState()) &&
                !general.domains &&
                ![ApiState.Pending].includes(general.domainsLoadingState)
            );
        }
    }
);

export const getCountriesThunk: AsyncThunk<
    ThunkReturnType<CountriesPaginatedResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetCountries]),
    payloadCreator(getCountries),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return (
                selectIsNotMor(getState()) &&
                ![ApiState.Pending].includes(general.countriesLoadingState) &&
                (general.countries.length === 0)
            );
        }
    }
);

export const getUserPreferencesThunk: AsyncThunk<
    ThunkReturnType<UserPreferencesResponse>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersPreferences]),
    payloadCreator(getUserPreferences),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![ApiState.Pending].includes(general.userPreferencesLoadingState) &&
                (Object.entries(general.userPreferences.preferences).length === 0);
        }
    }
);

export const createOrUpdateUserPreferencesThunk: AsyncThunk<
    ThunkReturnType<UserPreferencesResponse>,
    UserPreferencesRequest,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutUsersPreferences]),
    payloadCreator(createOrUpdateUserPreferences),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![ApiState.Pending].includes(general.userPreferencesLoadingState);
        }
    }
);
