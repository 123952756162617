import {
    createConsumersBlacklist,
    deleteConsumersBlacklist,
    updateConsumer,
    updateOrCreateConsumerEmails
} from "features/consumers/api";
import type { OptionsProps } from "../types";
import { useSimpleRequest } from "hooks/useRequest";
import type { BlacklistedEmailEntity, ConsumerEmailEntity } from "features/consumers/types";
import type { ResourceResponse } from "types";
import { BLACKLIST_CONSUMER_REASON } from "consts/consumers";
import { compareIds } from "util/support";
import { useAppStore } from "hooks";
import { BooleanSwitchValue } from "consts/general";

export default function useOptionActions({
    duplicatedEmailRegistryEntry,
    tabRegistry: [
        manageDuplicatesRegistry,
        updateManageDuplicatesRegistry
    ]
}: OptionsProps) {
    const store = useAppStore();
    const request = useSimpleRequest();

    const handleRemoveDuplicatedEmail = ({ data }: ResourceResponse<ConsumerEmailEntity>) =>
        updateManageDuplicatesRegistry(
            manageDuplicatesRegistry.filter(manageDuplicatesRegistryEntity =>
                manageDuplicatesRegistryEntity.email !== data.email
            )
        );

    const handleBlacklistConsumer = () =>
        updateManageDuplicatesRegistry(
            manageDuplicatesRegistry.map(manageDuplicatesRegistryEntity =>
                compareIds(duplicatedEmailRegistryEntry.id, manageDuplicatesRegistryEntity.id)
                    ? manageDuplicatesRegistryEntity.blacklist()
                    : manageDuplicatesRegistryEntity
            )
        );

    const handleWhitelistConsumer = () =>
        updateManageDuplicatesRegistry(
            manageDuplicatesRegistry.map(manageDuplicatesRegistryEntity =>
                compareIds(duplicatedEmailRegistryEntry.id, manageDuplicatesRegistryEntity.id)
                    ? manageDuplicatesRegistryEntity.whitelist()
                    : manageDuplicatesRegistryEntity
            )
        );


    const blacklistConsumer = () => {
        const computedEmail = (duplicatedEmailRegistryEntry.email
            ? {
                email: duplicatedEmailRegistryEntry.email
            }
            : {
                hashedEmail: duplicatedEmailRegistryEntry.hashedEmail
            }) as Partial<BlacklistedEmailEntity>;

        return request(() => createConsumersBlacklist({
            ...computedEmail,
            importedFrom: BLACKLIST_CONSUMER_REASON
        }), {
            onSuccess: handleBlacklistConsumer,
            notifyOnSuccess: true
        });
    };

    const whitelistConsumer = () => new Promise<void>((resolve, reject) =>
        request(() =>
            deleteConsumersBlacklist(
                duplicatedEmailRegistryEntry.computedEmail
            ), {
            onSuccess: () => request(() => updateConsumer({
                coreId: duplicatedEmailRegistryEntry.computedEmail,
                isRehabilitated: true,
                active: BooleanSwitchValue.On,
                email: duplicatedEmailRegistryEntry.computedEmail,
                MOR: store.getState().general.domains.MOR
            }), {
                onSuccess: () => {
                    handleWhitelistConsumer();
                    resolve();
                },
                onError: reject,
                notifyOnSuccess: true
            }),
            onError: reject
        }));

    const toggleBlacklist = () =>
        duplicatedEmailRegistryEntry.isBlacklisted
            ? whitelistConsumer()
            : blacklistConsumer();

    const removeDuplicate = () => request<ConsumerEmailEntity>(
        () => updateOrCreateConsumerEmails({
            coreId: duplicatedEmailRegistryEntry.id,
            samePersonIdentifier: ''
        }), {
        onSuccess: handleRemoveDuplicatedEmail,
        notifyOnSuccess: true
    });

    return {
        toggleBlacklist,
        removeDuplicate
    };
};
