import type { ResourceProps } from "ui/widgets/Resource";
import {
    selectIsMerchantUsersLoading,
    selectIsMerchantUsersUninitialized,
    selectMerchantUsersSlice
} from "features/merchants/selectors";

export default function useMerchantUsers(): Pick<
    ResourceProps,
    | 'selectIsTableLoading'
    | 'selectIsTableUninitialized'
    | 'selectTableDataSlice'
> {
    return {
        selectIsTableLoading: selectIsMerchantUsersLoading,
        selectIsTableUninitialized: selectIsMerchantUsersUninitialized,
        selectTableDataSlice: selectMerchantUsersSlice
    };
};
