import useForm, { type UseFormArg } from "hooks/useForm";
import { getInitialValues, useValidationSchema } from "./validators";
import type { Consumer } from "features/consumers/types";

export type FormValues = Pick<
    Consumer,
    | 'email'
    | 'samePersonIdentifierSource'
>;

type UseFilesFormArgs =
    & UseFormArg<Partial<FormValues>>;

export default function useAddDuplicateEmailForm({
    ...restFormArgs
}: UseFilesFormArgs) {
    const validationSchema = useValidationSchema();

    return useForm({
        ...restFormArgs,
        validationSchema,
        isInitialValid: false,
        validateOnMount: true,
        initialValues: {
            ...getInitialValues(),
            ...restFormArgs.initialValues
        }
    });
};
