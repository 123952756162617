import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { EsMerchantsPayoutFiles } from "ui/organizms/QueryBuilder";

const EsMerchantPayoutFilesScreen = () => (
    <Screen
        title='Query Builder | Es-Merchant Payout Files'
    >
        <EsMerchantsPayoutFiles />
    </Screen>
);

export default memo(EsMerchantPayoutFilesScreen);
