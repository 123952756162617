import { memo } from "react";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import type { TextFieldProps } from "@mui/material/TextField";
import type { MerchantDomain } from "features/merchants/types";
import { FormField, type CreateMerchantDomainForm } from "./types";
import AddBusinessUnit from "./AddBusinessUnit";
import { useCreateMerchantDomainForm } from "./useForm";
import { UrlTextField } from "ui/atoms/TextField";
import { ClipboardTextField } from "ui/molecules/Input";

type MerchantDomainType = Pick<MerchantDomain, 'sourceUrl'>;

const TextFieldComponent = (props: TextFieldProps) => (
    <ClipboardTextField
        {...props}
        TextFieldComponent={UrlTextField}
    />
);

const AddMerchantDomain = (merchantDomainForm: CreateMerchantDomainForm) => {
    const form = useCreateMerchantDomainForm(merchantDomainForm);

    return (
        <AddBusinessUnit
            <MerchantDomainType>
            form={form}
            TextFieldComponent={TextFieldComponent}
            TextFieldProps={{
                name: FormField.SourceUrl,
                label: 'Domain Name',
                placeholder: 'Enter domain name'
            }}
            ButtonProps={{
                loading: form.isSubmitting,
                disabled: !form.isValid,
                variant: 'contained',
                children: 'Add Domain',
                startIcon: (
                    <DomainAddIcon />
                ),
                sx: {
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content'
                }
            }}
            DialogActionProps={{
                children: 'Add Domain'
            }}
            renderDialogTitle={({ values }) => (
                `Are you sure you want to add domain “${values[FormField.SourceUrl]}” to “${merchantDomainForm.businessName}?`
            )}
        />
    );
};

export default memo(AddMerchantDomain);
