import { memo } from "react";
import { Profile } from "ui/organizms/Users";
import useMerchantUser from "./useMerchantUser";
import type { UserFormProps } from "ui/forms/User";

const MerchantUser = (props: UserFormProps) => (
    <Profile
        {...useMerchantUser(props)}
    />
);

export default memo(MerchantUser);
