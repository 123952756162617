import { memo } from 'react';
import { BasicDialog } from 'ui/molecules/Dialog';
import TriggerKYC from './TriggerKYC';
import useTriggerKYCDialog from './useTriggerKYCDialog';

const TriggerKYCDialog = () => {
    const {
        isTriggerKYCServicesLoading,
        isProcessing,
        open,
        servicesState,
        handleDialogClose,
        handleProcessKYCService,
        onKYCServiceChange,
        getKYCServicesCollection
    } = useTriggerKYCDialog();

    return (
        <BasicDialog
            isOpen={open}
            onClose={handleDialogClose}
            DialogProps={{
                sx: {
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 370
                    }
                }
            }}
        >
            <TriggerKYC
                isProcessing={isProcessing}
                isTriggerKYCServicesLoading={isTriggerKYCServicesLoading}
                servicesState={servicesState}
                handleProcessKYCService={handleProcessKYCService}
                onKYCServiceChange={onKYCServiceChange}
                getKYCServicesCollection={getKYCServicesCollection}
            />
        </BasicDialog>
    );
};

export default memo(TriggerKYCDialog);