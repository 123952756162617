import type { ConstantsResponse, DomainsResourceResponse } from "features/general/types";
import type { ThunkReturnType } from "types";

import { getConstantsThunk, getDomainsThunk } from "features/general/thunks";
import useRequest from "./useRequest";
import useCountries from "features/general/useCountries";
import useUserPreferences from "features/general/useUserPreferences";
import useMerchantActions from "features/merchants/useMerchantActions";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import useSessionEffect from "./useSessionEffect";
import useBitsoBanks from "features/general/useBitsoBanks";

const dashboardSessionEffectSymbol = Symbol('dashboardSessionEffectSymbol');

export default function useDashboardBootstrap() {
    const getConstants = useRequest<ThunkReturnType<ConstantsResponse>, void>({
        thunk: getConstantsThunk
    });

    useUserPreferences({
        shouldFetchUserPreferences: true
    });

    const { getAllPaymentMethods } = usePaymentMethodActions();

    const { getAllMerchants } = useMerchantActions();
    /*useRequest<ThunkReturnType<PaymentMethodsResponse>, void>({
        thunk: getPaymentMethodsThunk
    });*/

    const getDomains = useRequest<ThunkReturnType<DomainsResourceResponse>, void>({
        thunk: getDomainsThunk
    });

    useCountries();

    useBitsoBanks();

    useSessionEffect(() => {
        getConstants();
        getAllPaymentMethods();
        getDomains();
        getAllMerchants();
    }, dashboardSessionEffectSymbol);
};
