import { createContext, PropsWithChildren } from "react";
import useKYCSettings from "./useKYCSettings";
import useKYCSettingsStateSelectors from "./useKYCSettingsStateSelectors";

export const KYCSettingsStateSelectorsContext = createContext({} as ReturnType<
    typeof useKYCSettingsStateSelectors
>);
export const KYCSettingsActionsContext = createContext({} as Omit<
    ReturnType<typeof useKYCSettings>,
    | 'kycSettingsState'
>);

const KYCSettingsProvider = ({ children }: PropsWithChildren) => {
    const {
        kycSettingsState,
        ...kycSettingsActions
    } = useKYCSettings();

    return (
        <KYCSettingsActionsContext.Provider
            value={kycSettingsActions}
        >
            <KYCSettingsStateSelectorsContext.Provider
                value={useKYCSettingsStateSelectors(kycSettingsState)}
            >
                {children}
            </KYCSettingsStateSelectorsContext.Provider>
        </KYCSettingsActionsContext.Provider>
    );
};

export default KYCSettingsProvider;
