import { memo } from 'react';
import { selectIsExportManualBulkKYCsImportHistoryLoading } from 'features/kyc/selectors';
import { TableToolbar, type TableToolbarProps } from 'ui/widgets/Table';

const ImportHistoryTableToolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportManualBulkKYCsImportHistoryLoading}
    />
);

export default memo(ImportHistoryTableToolbar);
