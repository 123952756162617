import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";

export const selectIsConsumersUninitialized = (state: RootState) =>
    state.consumers.tableLoadingState === ApiState.Idle;

export const selectIsConsumersLoading = (state: RootState) =>
    state.consumers.tableLoadingState === ApiState.Pending;

export const selectConsumersSlice = (state: RootState) =>
    state.consumers.tableSlice;

export const selectIsExportConsumersLoading = (state: RootState) =>
    state.consumers.exportLoadingState === ApiState.Pending;
