import { useMemo } from 'react';
import { Filters } from "consts/merchantPayouts";
import { array, number, object, string } from 'yup';
import { multiColumnFilterFactory } from 'util/filters';
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { Moment } from 'moment';
import type { IncrementExchangeRateFormValues } from './types';

export const getInitialValues = (initialValues?: IncrementExchangeRateFormValues) => ({
    [Filters.MOR]: undefined,
    [Filters.PSP]: undefined,
    [multiColumnFilterFactory([
        Filters.dateFrom,
        Filters.dateTo
    ]) as 'dateFrom|dateTo']: [null, null] as DateRange<Moment>,
    [Filters.transactionCurrency]: undefined,
    [Filters.currencyExchangeRate]: '',
    [Filters.payoutCurrency]: undefined,
    [Filters.merchantId]: null,
    [Filters.tag]: null,
    ...initialValues
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [Filters.MOR]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required("This is a required field!"),
            [Filters.PSP]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required("This is a required field!"),
            [multiColumnFilterFactory([
                Filters.dateFrom,
                Filters.dateTo
            ])]: array().required("This is a required field!"),
            [Filters.transactionCurrency]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required("This is a required field!"),
            [Filters.currencyExchangeRate]: number()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .required("This is a required field!"),
            [Filters.payoutCurrency]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required("This is a required field!"),
            [Filters.merchantId]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required()
                .label('Merchant'),
            [Filters.tag]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required()
                .label('Tag')
        })
        , []);
