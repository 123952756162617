import { memo } from "react";
import { getEsTransactions } from "features/transactions/api";
import QueryBuilderEntity from "./QueryBuilderEntity";

const EsTransactions = () => (
    <QueryBuilderEntity
        label='ES-TRANSACTIONS'
        query={getEsTransactions}
    />
);

export default memo(EsTransactions);
