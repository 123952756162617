import { useState } from "react";
import { BulkKYCStepperStep, type BulkKYCStep } from "./types";
import CSVFileUploader from "./CSVFileUploader";
import ArchiveFileUploader from "./ArchiveFileUploader";
import KYCServices from "./KYCServices";

export default function useBulkKYCStepper() {
    const [activeStep, setActiveStep] = useState<BulkKYCStepperStep>(BulkKYCStepperStep.UploadCSVFile);

    const [steps, updateSteps] = useState(() =>
        new Map<BulkKYCStepperStep, BulkKYCStep>([
            [BulkKYCStepperStep.UploadCSVFile, {
                id: BulkKYCStepperStep.UploadCSVFile,
                label: 'Upload .CSV File',
                componentSlots: {
                    mainArea: CSVFileUploader
                }
            } as BulkKYCStep<BulkKYCStepperStep.UploadCSVFile>],
            [BulkKYCStepperStep.UploadVerificationArchive, {
                id: BulkKYCStepperStep.UploadVerificationArchive,
                label: 'Upload File',
                isOptional: true,
                componentSlots: {
                    mainArea: ArchiveFileUploader
                }
            } as BulkKYCStep<BulkKYCStepperStep.UploadVerificationArchive>],
            [BulkKYCStepperStep.SelectKYCTypesAndSubmit, {
                id: BulkKYCStepperStep.SelectKYCTypesAndSubmit,
                label: 'Select KYC Types',
                componentSlots: {
                    mainArea: KYCServices
                }
            } as BulkKYCStep<BulkKYCStepperStep.SelectKYCTypesAndSubmit>]
        ]));

    const getActiveStep = () => steps.get(activeStep)!;

    const handleBack = () =>
        setActiveStep(prevActiveStep => prevActiveStep - 1);

    const handleNext = (isCompleted = false) => {
        updateSteps(steps =>
            new Map(steps)
                .set(activeStep, {
                    ...getActiveStep(),
                    isCompleted
                })
        );

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const getComponentSlots = () =>
        getActiveStep().componentSlots;

    const getIsOptional = () =>
        getActiveStep().isOptional;

    const fromSteps = () => Array.from(steps);

    const isLastStep = () => activeStep === steps.size - 1;

    return {
        activeStep,
        fromSteps,
        handleBack,
        handleNext,
        getComponentSlots,
        getIsOptional,
        isLastStep
    };
};
