import useAuth from "features/auth/useAuth";
import type { User } from "features/users/types";
import { useEffect, useRef, type EffectCallback } from "react";
import type { ID, Scalar } from "types";
import { useAppStore } from "./store";

type UseSessionEffectArg = (user: User | null) => ReturnType<EffectCallback>;

type UseSessionEffect = {
    (param: UseSessionEffectArg, identifier: Scalar): void;
    [key: Scalar]: {
        userId?: ID | null;
    };
};

const useSessionEffect = ((effect: UseSessionEffectArg, identifier: Scalar) => {
    const store = useAppStore();
    const { user } = useAuth();

    const locals = {
        user,
        effect
    };

    const localsRef = useRef(locals);
    localsRef.current = locals;

    const userId = user?.coreId;

    useEffect(() => {
        if (!(identifier in useSessionEffect)) {
            useSessionEffect[identifier] = {};
        }

        let unsubscribe: ReturnType<EffectCallback> = undefined;

        if (!Object.is(useSessionEffect[identifier].userId, userId)) {
            const { effect, user } = localsRef.current;

            unsubscribe = effect(user);
        }

        useSessionEffect[identifier].userId = userId;

        return () => {
            if (!store.getState().auth.user?.coreId) {
                delete useSessionEffect[identifier];
            }

            unsubscribe?.();
        };
    }, [userId, identifier, store]);
}) as UseSessionEffect;

export default useSessionEffect;
