import {
    useState,
    memo,
    useEffect,
    useRef,
    type PropsWithChildren,
    type ReactNode
} from 'react';
import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';
import Popover, { type PopoverProps } from '@mui/material/Popover';
import BaseException from 'exceptions/BaseException';
import Icon from 'ui/atoms/Icon';

type Props =
    & LoadingButtonProps
    & {
        readonly label: ReactNode;
        readonly PopoverProps?: PopoverProps;
    }
    & Required<PropsWithChildren>;

const Popoverable = ({
    children,
    id,
    label,
    onClick,
    PopoverProps,
    startIcon,
    endIcon,
    variant = 'outlined',
    size = 'small',
    ...buttonProps
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick: LoadingButtonProps['onClick'] = event => {
        try {
            onClick?.(event);
            setAnchorEl(event.currentTarget);
        } catch (e) {
            if (!(e instanceof BaseException)) {
                throw e;
            }
        }
    };

    const handleClose: PopoverProps['onClose'] = (...args) => {
        setAnchorEl(null);
        PopoverProps?.onClose?.(...args);
    };

    const popoverPropsRef = useRef(PopoverProps);
    popoverPropsRef.current = PopoverProps;

    useEffect(() => {
        if (
            (typeof popoverPropsRef.current?.open === 'boolean') &&
            !popoverPropsRef.current.open
        ) {
            setAnchorEl(null);
            popoverPropsRef.current.onClose?.({}, 'backdropClick');
        }
    }, [PopoverProps?.open]);

    const renderAdornmentIcon = (icon: ReactNode) => (
        icon &&
            <Icon name={String(icon)} />
    );

    return (
        <>
            <LoadingButton
                {...buttonProps}
                variant={variant}
                size={size}
                aria-describedby={id}
                onClick={handleClick}
                startIcon={renderAdornmentIcon(startIcon)}
                endIcon={renderAdornmentIcon(endIcon)}
            >
                {label}
            </LoadingButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                {...PopoverProps}
                id={id}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {children}
            </Popover>
        </>
    );
};

export default memo(Popoverable);
