import { useCallback } from 'react';
import { type GridRowParams, GRID_ACTIONS_COLUMN_TYPE } from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import type { GridRenderCellParams, GridEnrichedColDef } from "@mui/x-data-grid-premium";
import type { ManualBulkKYCsImportHistory } from "features/kyc/types";
import type { Mapped } from "types";
import { FilterVariant, date } from "consts/filters";
import { multi, text } from "consts/kyc";
import useActions from "features/kyc/useActions";
import Status from "./ColumnOverrides/Status";
import TableActions from './TableActions';

export default function useImportHistoryTable() {
    const {
        getManualBulkKYCsImportHistoryRecords,
        downloadCsv
    } = useActions();

    const getInitialState: () => GridInitialStatePremium = useCallback(() => ({
        pinnedColumns: {
            right: [GRID_ACTIONS_COLUMN_TYPE]
        }
    }), []);

    const columns: Array<GridEnrichedColDef> = [
        {
            field: GRID_ACTIONS_COLUMN_TYPE,
            type: GRID_ACTIONS_COLUMN_TYPE,
            width: 50,
            maxWidth: 50,
            getActions: (rowParams: GridRowParams) => [
                <TableActions {...rowParams} />
            ]
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            renderCell: (params: GridRenderCellParams<unknown, Mapped<ManualBulkKYCsImportHistory>>) => (
                <Status {...params} />
            )
        }
    ];

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.DateRange, date],
        [FilterVariant.Text, text],
        [FilterVariant.MultiOption, multi],
    ]);

    return {
        filterTypes,
        columns,
        getInitialState,
        getManualBulkKYCsImportHistoryRecords,
        downloadCsv
    };
};
