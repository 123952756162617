import { Accept } from "react-dropzone";

export class FileManager {
    public static fromMimeTypes(mimeTypes: string[]) {
        return new this(mimeTypes);
    }

    private constructor(
        private readonly acceptedMimeTypes: string[],
    ) { }

    public get accept(): Accept {
        return this.acceptedMimeTypes.reduce((acc, format) => ({
            ...acc,
            [format]: this.getExtensionFromMimeType(format, '.')
        }), {});
    }

    public getExtensions(prefix = '') {
        return this.acceptedMimeTypes.map(mimeType =>
            this.getExtensionFromMimeType(mimeType, prefix)
        ).flat();
    }

    public getExtensionFromMimeType(mimeType: string, prefix = ''): Array<string> {
        return mimeType
            .split('/')
            .slice(-1)
            .map(ext => `${prefix}${ext}`);
    }
}
