import { memo } from "react";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { convertToBooleanSwitch } from "util/transformers";
import { useTypedSelector } from "hooks";
import { selectIsMerchantLoading } from "features/merchants/selectors";
import { createOrUpdateMerchantSettings } from "features/merchants/api";
import type { MerchantResponse } from "features/merchants/types";
import useMerchantActions from "features/merchants/useMerchantActions";
import { StatusResourceTableCell } from "../../../shared/TableCells";

const MerchantModeLive = (props: GridRenderCellParams) => {
    const { putMerchant } = useMerchantActions();

    const isProcessing = useTypedSelector(selectIsMerchantLoading);

    const handleProcess = async (isChecked: boolean, { row }: GridRenderCellParams) => {
        const isLiveMode = convertToBooleanSwitch(isChecked);

        const [response] = putMerchant({
            coreId: row.coreId,
            isLiveMode
        });

        Promise.allSettled([
            createOrUpdateMerchantSettings({
                coreId: row.merchantSettingCoreId,
                merchantId: row.coreId,
                isLiveMode
            }),
            response
        ]);

        return response as Promise<MerchantResponse>;
    };

    const getChecked = ({ value }: GridRenderCellParams) => !!Number(value);

    return (
        <StatusResourceTableCell
            {...props}
            isProcessing={isProcessing}
            getChecked={getChecked}
            handleProcess={handleProcess}
        />
    );
};

export default memo(MerchantModeLive);
