import { useState } from "react";
import { useFormik } from "formik";
import { useFormableTab } from "ui/widgets/StatefulTabs";
import { MerchantPaymentMethodPSPDetailTab } from "./types";
import useMerchantDetailsTabContext from "../../../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "../../repository";
import { PaymentMethodLimits } from "ui/forms/Merchant";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { FormAdapter } from "hooks/useForm";
import { withSubscription } from "providers/ContextPublisher";

export default function useMerchantPaymentMethodPSPDetail(merchantMethod: MerchantsMethodsPivot) {
    const [isSaving, setSavingState] = useState(false);

    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();

    const state = {
        [MerchantPaymentMethodPSPDetailTab.MethodLimits]: withSubscription(
            PaymentMethodLimits.useForm({
                initialValues: PaymentMethodLimits.getInitialValues(merchantMethod),
                onSaveOrCreate: async values =>
                    FormAdapter.fromResponse(
                        await repository.updateMethodLimits({
                            ...values,
                            coreId: merchantMethod.coreId
                        })
                    )
            }),
            'MerchantPaymentMethodPSPDetailTab.MethodLimits'
        ),
        [MerchantPaymentMethodPSPDetailTab.History]: withSubscription(
            useFormik({
                initialValues: {},
                onSubmit: async () => {}
            }),
            'MerchantPaymentMethodPSPDetailTab.History'
        ),
        [MerchantPaymentMethodPSPDetailTab.Info]: withSubscription(
            useFormik({
                initialValues: {},
                onSubmit: async () => {}
            }),
            'MerchantPaymentMethodPSPDetailTab.Info'
        )
    };

    const formableTab = useFormableTab<MerchantPaymentMethodPSPDetailTab>({
        state,
        entries: [
            ['1', MerchantPaymentMethodPSPDetailTab.MethodLimits],
            ['2', MerchantPaymentMethodPSPDetailTab.History],
            ['3', MerchantPaymentMethodPSPDetailTab.Info]
        ]
    });

    const selectMethodLimits = () =>
        state[MerchantPaymentMethodPSPDetailTab.MethodLimits];

    return {
        ...formableTab,
        isSaving,
        setSavingState,
        selectMethodLimits
    };
};
