import { PropsWithChildren, createContext } from "react";
import type { StatisticDashboardContext, StatisticDashboardProps } from "./types";
import useStatisticDashboard from "./useStatisticDashboard";

export const StatisticDashboard = createContext({} as StatisticDashboardContext);

const StatisticDashboardProvider = ({ children, filterTypes }: PropsWithChildren<StatisticDashboardProps>) => (
    <StatisticDashboard.Provider
        value={useStatisticDashboard({
            filterTypes
        })}
    >
        {children}
    </StatisticDashboard.Provider>
);

export default StatisticDashboardProvider;
