import { ApiState } from "infrastructure/api";
import { RootState } from "infrastructure/store";

export const selectIsTransactionsUninitialized = (state: RootState) =>
  state.transactions.transactionsLoadingState === ApiState.Idle;

export const selectIsTransactionsLoading = (state: RootState) =>
  state.transactions.transactionsLoadingState === ApiState.Pending;

export const selectIsTransactionDetailsLoading = (state: RootState) =>
  state.transactions.transactionDetailsLoadingState === ApiState.Pending;

export const selectIsTransactionHistoryLoading = (state: RootState) =>
  state.transactions.transactionHistoryLoadingState === ApiState.Pending;

export const selectIsExportTransactionsLoading = (state: RootState) =>
  state.transactions.exportLoadingState === ApiState.Pending;

export const selectTransactionSlice = (state: RootState) =>
  state.transactions.transactionSlice
