import { memo } from "react";
import Box from "@mui/material/Box";
import { BasicDialog } from 'ui/molecules/Dialog';
import BulkKYCHistoryResults from "./BulkKYCHistoryResults";
import useBulkKYCHistoryResultsDialog from "./useBulkKYCHistoryResultsDialog";
import DialogActionPanel from "../DialogActionPanel";
import BulkKYCHistoryResultsStats from "./BulkKYCHistoryResults/BulkKYCHistoryResultsStats";

const BulkKYCHistoryResultsDialog = () => {
    const {
        open,
        handleDialogClose,
        isDownladResultsLoading,
        downloadResults,
        ...bulkKYCHistoryResultsProps
    } = useBulkKYCHistoryResultsDialog();

    return (
        <BasicDialog
            isOpen={open}
            onClose={handleDialogClose}
            DialogProps={{
                sx: {
                    "& .MuiDialogContent-root"  : {
                        overflow: 'hidden'
                    } ,
                    
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 800,
                        overflow: 'auto',
                        height: 825
                    }
                }
            }}
            DialogActionsProps={
                bulkKYCHistoryResultsProps.isDataLoading
                    ? {}
                    : {
                        sx: {
                            justifyContent: 'space-between',
                            width: '100%',
                            '& > div': {
                                width: 'inherit'
                            }
                        }
                    }}
            titleSlot='Results'
            actionsSlot={(
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        gap: 2,
                        width: 'inherit'
                    }}
                >
                    <BulkKYCHistoryResultsStats
                        consumersManualBulkKYCsImportHistoryPivotAggregatable={(
                            bulkKYCHistoryResultsProps.consumersManualBulkKYCsImportHistoryPivotAggregatable
                        )}
                    />
                    <DialogActionPanel
                        sx={{
                            mt: 'auto'
                        }}
                        loading={isDownladResultsLoading}
                        disabled={
                            bulkKYCHistoryResultsProps.isDataLoading ||
                            !bulkKYCHistoryResultsProps.consumersManualBulkKYCsImportHistoryPivotGroup.length
                        }
                        onClick={downloadResults}
                    >
                        Download results
                    </DialogActionPanel>
                </Box>
            )}
        >
            <BulkKYCHistoryResults
                {...bulkKYCHistoryResultsProps}
            />
        </BasicDialog>
    );
};

export default memo(BulkKYCHistoryResultsDialog);
