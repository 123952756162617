import { allSummableColumnsAlias, summsQueryField } from "consts/table";
import { useTypedSelector } from "hooks";
import type { RootState } from "infrastructure/store";
import type { CoreBankingEntity, PaginateResourceResponse } from "types";

export type UseSumsArgs<T = CoreBankingEntity> = {
    readonly selectTableDataSlice: (state: RootState) => PaginateResourceResponse<T>['data'];
    readonly getSummableColumns?: () => Array<string> | string;
};

export default function useSums<T>({
    selectTableDataSlice,
    getSummableColumns
}: UseSumsArgs<T>) {
    const tableDataSlice = useTypedSelector(selectTableDataSlice);

    const isGetAllSummableColumns = () =>
        getSummableColumns?.() === allSummableColumnsAlias;

    const getSummableColumnsFromTableDataSlice = () => {
        const {
            sums
        } = tableDataSlice ?? {};

        return sums ?? {};
    };

    const isColumnInSummableColumns = (column: string) => {
        const summableColumns = getSummableColumns?.();

        return Array.isArray(summableColumns) &&
            summableColumns.includes(column);
    };

    return (urlSearchParams: URLSearchParams) => {
        if (isGetAllSummableColumns()) {
            urlSearchParams.append(summsQueryField, allSummableColumnsAlias);
            return;
        }

        for (const [column] of Object.entries(getSummableColumnsFromTableDataSlice())) {
            if (isColumnInSummableColumns(column)) {
                urlSearchParams.append(summsQueryField, column);
            }
        }
    };
};
