import {
    GridColumns,
    GridPinnedColumns,
    GridRowParams,
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_COL_DEF
} from "@mui/x-data-grid-premium";
import { Via } from "consts/filters";
import { selectIsMultiFileMergeEnabled, selectSelectionModel } from "features/merchantPayouts/selectors";
import { Files } from "features/merchantPayouts/types";
import useActions from "features/merchantPayouts/useActions";
import { useTypedSelector } from "hooks";
import { useState, useEffect, useRef } from "react";
import type { FiltersPublicApi } from "ui/widgets/Table";
import type { MutableRefObject } from 'react';

export default function useFilable() {
    const filtersRef: MutableRefObject<FiltersPublicApi<string> | null> = useRef<FiltersPublicApi<string>>(null);

    const { onSelectionModelChange } = useActions();

    const selectionModel = useTypedSelector(selectSelectionModel);

    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>(() => ({
        left: [],
        right: ['actions']
    }));

    const isMultiFileMergeEnabled = useTypedSelector(selectIsMultiFileMergeEnabled);

    // TODO: rectify business logic
    const isRowSelectable = ({ row }: GridRowParams<Files>) => {
        const { MOR, via, isPaid, parentId } = row;

        return (
            Boolean(MOR) &&
            Boolean(Number(isPaid)) &&
            (via === Via.Gateway) &&
            (parentId === null)
        );
    };

    useEffect(() => {
        setPinnedColumns(state => ({
            ...state,
            left: isMultiFileMergeEnabled
                ? [GRID_CHECKBOX_SELECTION_COL_DEF.field]
                : []
        }));
    }, [isMultiFileMergeEnabled]);

    const enhanceColumns = (columns?: GridColumns) =>
        columns?.map(column =>
            (column.field === GRID_ACTIONS_COLUMN_TYPE)
                ? ({
                    resizable: false,
                    disableColumnMenu: true,
                    headerName: 'Options',
                    ...column
                })
                : column);

    return {
        checkboxSelection: isMultiFileMergeEnabled,
        pinnedColumns,
        isRowSelectable,
        selectionModel,
        onSelectionModelChange,
        enhanceColumns,
        filtersRef
    };
};
