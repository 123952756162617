import { useCallback, useRef } from 'react';
import type { KYCSettingsState } from './types';
import { KYCServiceType } from 'consts/kyc';
import type { KYCSettings } from 'features/kyc/types';
import { useApiState } from 'hooks';

export default function useKYCSettingsStateSelectors(kycSettingsState: KYCSettingsState) {
    const kycSettingsStateRef = useRef<KYCSettingsState>(kycSettingsState);
    kycSettingsStateRef.current = kycSettingsState;

    const selectKYCSettingsLoadingState = useApiState(
        kycSettingsStateRef.current.kycSettingsLoadingState
    );

    const selectKYCServices = useCallback(() =>
        kycSettingsStateRef.current.kycServices
        , []);

    const selectAdditionalSettingsByTypeId = useCallback((kycTypeId: KYCServiceType) =>
        kycSettingsStateRef.current.kycServices[kycTypeId].kycSettings
        , []);

    const selectKYCLimitByTypeId = useCallback((kycTypeId: KYCServiceType) =>
        kycSettingsStateRef.current.kycServices[kycTypeId].kycLimit
        , []);

    const selectKYCSummedAmountEurLimitByTypeId = useCallback((kycTypeId: KYCServiceType) =>
        kycSettingsStateRef.current.kycServices[kycTypeId].summedAmountEurLimit
        , []);

    const selectKYCSettings = useCallback(() =>
        Object.values(selectKYCServices()).reduce((acc, { kycSettings }) => ({
            ...acc,
            ...kycSettings.reduce((acc, { id, name }) => ({
                ...acc,
                [name]: id
            }), {})
        }), {} as Partial<KYCSettings>)
        , [selectKYCServices]);

    const selectIsKYCSettingsChanged = useCallback(() =>
        kycSettingsStateRef.current.isKycSettingsChanged
        , []);

    const selectKYCProvider = useCallback(() =>
        kycSettingsStateRef.current.kycProvider
        , []);

    return {
        selectKYCServices,
        selectAdditionalSettingsByTypeId,
        selectKYCLimitByTypeId,
        selectKYCSummedAmountEurLimitByTypeId,
        selectKYCSettingsLoadingState,
        selectKYCSettings,
        selectIsKYCSettingsChanged,
        selectKYCProvider
    };
};
