import { KYCStatus as KYCStatusEnum } from "consts/kyc";
import withStatus from './withStatus';
import { removeUnderscoreFormatter } from "util/formaters";

const defaultProps = {
    renderLabel: (value = '-') => removeUnderscoreFormatter(value)
};

const KYCStatus = withStatus({
    [KYCStatusEnum.NA]: {
        color: 'default',
        variant: 'soft'
    },
    [KYCStatusEnum.Pending]: {
        color: 'info',
        variant: 'filled'
    },
    [KYCStatusEnum.Failed]: {
        color: 'error',
        variant: 'filled'
    },
    [KYCStatusEnum.Success]: {
        color: 'success',
        variant: 'filled'
    },
    DEFAULT: {
        ...defaultProps,
        color: 'default',
        variant: 'soft'
    }
});

export default KYCStatus;
