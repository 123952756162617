import { useState } from "react";
import { useFormik } from "formik";
import { useFormableTab } from "ui/widgets/StatefulTabs";
import { MerchantAccountSettingsTab } from "./types";
import useMerchantAccountAdvancedSettings from "./useMerchantAccountAdvancedSettings";
import useMerchantAccountMerchantSetup from "./useMerchantAccountMerchantSetup";
import { withSubscription } from "providers/ContextPublisher";

export default function useMerchantAccountSettings() {
    const [isSaving, setSavingState] = useState(false);

    const state = {
        [MerchantAccountSettingsTab.Ledger]: withSubscription(
            useFormik({
                initialValues: {},
                onSubmit: async () => { }
            }),
            'MerchantAccountSettingsTab.Ledger'
        ),
        [MerchantAccountSettingsTab.ApiSettings]: withSubscription(
            useMerchantAccountAdvancedSettings(setSavingState),
            'MerchantAccountSettingsTab.ApiSettings'
        ),
        [MerchantAccountSettingsTab.MerchantSetup]: withSubscription(
            useMerchantAccountMerchantSetup(setSavingState),
            'MerchantAccountSettingsTab.MerchantSetup'
        )
    };

    const formableTab = useFormableTab<MerchantAccountSettingsTab>({
        state,
        entries: [
            ['1', MerchantAccountSettingsTab.Ledger],
            ['2', MerchantAccountSettingsTab.ApiSettings],
            ['3', MerchantAccountSettingsTab.MerchantSetup]
        ]
    });

    const selectAccountApiSettings = () =>
        state[MerchantAccountSettingsTab.ApiSettings];

    const selectAccountMerchantSetup = () =>
        state[MerchantAccountSettingsTab.MerchantSetup];

    return {
        ...formableTab,
        isSaving,
        setSavingState,
        selectAccountApiSettings,
        selectAccountMerchantSetup
    };
};
