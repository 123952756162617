import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { ConsumerActionType, useConsumerContext } from "../../ConsumerProvider";
import type {
    ConsumersManualBulkKYCsImportHistoryPivot,
    ConsumersManualBulkKYCsImportHistoryPivotGroup
} from "features/consumers/types";
import type { ManualBulkKYCsImportHistory } from "features/kyc/types";
import type {
    Aggregatable,
    ID,
    ResourceResponse,
    PaginateResourceResponse
} from "types";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/consumers";
import { changeExportLoading } from "features/kyc/slice";
import { selectIsExportManualBulkKYCsImportHistoryLoading } from "features/kyc/selectors";
import { getConsumersManualBulkKYCsImportHistoryPivot } from "features/consumers/api";
import { getPromiseSettledResourceResult } from "util/resource";
import { Aggregates } from "consts/request-query";
import useFetchResource from "hooks/useFetchResource";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";
import { useTypedSelector } from "hooks";
import { PER_PAGE_SIZE } from "consts/transactions";
import { fromPaginateResourceResponse } from "util/api";

export default function useBulkKYCHistoryResultsDialog() {
    const [isDataLoading, setLoading] = useState<boolean>(false);
    const isDownladResultsLoading = useTypedSelector(selectIsExportManualBulkKYCsImportHistoryLoading)

    const [
        consumersManualBulkKYCsImportHistoryPivotGroup,
        setConsumersManualBulkKYCsImportHistoryPivotGroup
    ] = useState(
        Array.of<ConsumersManualBulkKYCsImportHistoryPivotGroup>()
    );

    const [
        consumersManualBulkKYCsImportHistoryPivotAggregatable,
        setConsumersManualBulkKYCsImportHistoryPivotAggregatable
    ] = useState(
        Array.of<ConsumersManualBulkKYCsImportHistoryPivot & Aggregatable>()
    );

    const fetchResource = useFetchResource({
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE
    });
    const requestHandlerRef = useRef(fetchResource);
    requestHandlerRef.current = fetchResource;

    const prevCoreId = useRef<ID>();
    const isDialogOpenRef = useRef<boolean>(false);

    const {
        consumersState,
        getConsumersStatePayloadByType,
        resetConsumersState
    } = useConsumerContext();

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumersManualBulkKYCsImportHistoryPivot
    });

    const handleDialogClose = () => {
        resetConsumersState();
        setLoading(false);
    };

    const setDataStateRef = useRef(<T extends unknown, D = T>(
        setState: Dispatch<SetStateAction<T[]>>,
        promiseSettledResult: PromiseSettledResult<PaginateResourceResponse<D>>,
        transformer: (dataSlice: D[]) => T[] = _ => (_ as unknown as T[])
    ) => {
        setState(
            transformer(
                fromPaginateResourceResponse(
                    getPromiseSettledResourceResult(
                        promiseSettledResult
                    )
                )
            )
        );
    });

    const { coreId } = getConsumersStatePayloadByType<ManualBulkKYCsImportHistory, {}>(
        ConsumerActionType.BulkKYCHistoryResults,
        {}
    );

    const downloadResults = () =>
        downloadCsv(RequestQueryMapper.from()
            .containsIn(Filters.manualBulkKycImportHistoryId, `${coreId}`)
            .exportVisibility([
                Filters.coreId,
                Filters.MOR,
                Filters.emailOrHash,
                Filters.manualBulkKycImportHistoryId,
                Filters.importStatus,
                Filters.consumerId,
                Filters.createdAt,
                Filters.merchantName,
                Filters.kycTypeName,
                Filters.pki
            ])
            .toString()
        );

    useEffect(() => {
        isDialogOpenRef.current = Boolean(coreId);

        if (!coreId || Object.is(prevCoreId.current, coreId)) {
            return;
        }

        const baseRequestQuery = RequestQueryMapper.from()
            .containsIn(Filters.manualBulkKycImportHistoryId, `${coreId}`);

        const aggregatedRequestQuery = baseRequestQuery
            .clone()
            .aggregates(Aggregates.GroupBys, Filters.kycTypeId)
            .aggregates(Aggregates.GroupBys, Filters.importStatus);

        setLoading(true);
        setConsumersManualBulkKYCsImportHistoryPivotGroup(
            Array.of<ConsumersManualBulkKYCsImportHistoryPivotGroup>()
        );
        setConsumersManualBulkKYCsImportHistoryPivotAggregatable(
            Array.of<ConsumersManualBulkKYCsImportHistoryPivot & Aggregatable>()
        );

        const { current: request } = requestHandlerRef;

        Promise.allSettled([
            request<ResourceResponse<ConsumersManualBulkKYCsImportHistoryPivot[]>>(() =>
                getConsumersManualBulkKYCsImportHistoryPivot(
                    baseRequestQuery
                        .toString()
                )
            ),
            request<ResourceResponse<Array<ConsumersManualBulkKYCsImportHistoryPivot & Aggregatable>>>(() =>
                getConsumersManualBulkKYCsImportHistoryPivot(
                    aggregatedRequestQuery
                        .toString()
                )
            )
        ])
            .then(([
                consumersManualBulkKYCsImportHistoryPivotSettledResult,
                consumersManualBulkKYCsImportHistoryPivotAggregatableSettledResult
            ]) => {
                if (!isDialogOpenRef.current) {
                    return;
                }

                setDataStateRef.current<
                    ConsumersManualBulkKYCsImportHistoryPivotGroup,
                    ConsumersManualBulkKYCsImportHistoryPivot
                >(
                    setConsumersManualBulkKYCsImportHistoryPivotGroup,
                    consumersManualBulkKYCsImportHistoryPivotSettledResult,
                    dataSlice => {
                        const groupType = new Map<
                            ConsumersManualBulkKYCsImportHistoryPivotGroup['emailOrHash'],
                            ConsumersManualBulkKYCsImportHistoryPivotGroup
                        >();

                        for (const { emailOrHash, kycTypeId, importStatus } of dataSlice) {
                            if (groupType.has(emailOrHash)) {
                                const group = groupType.get(emailOrHash)!;

                                const kycTypeIds = [
                                    ...group.kycTypeIds,
                                    kycTypeId
                                ].sort();

                                const importStatuses = [
                                    ...group.importStatuses,
                                    importStatus
                                ];

                                groupType.set(emailOrHash, {
                                    ...group,
                                    kycTypeIds,
                                    importStatuses
                                });
                                continue;
                            }

                            groupType.set(emailOrHash, {
                                emailOrHash,
                                kycTypeIds: Array.of(kycTypeId),
                                importStatuses: Array.of(importStatus)
                            });
                        }

                        return Array.from(groupType.values());
                    }
                );

                setDataStateRef.current(
                    setConsumersManualBulkKYCsImportHistoryPivotAggregatable,
                    consumersManualBulkKYCsImportHistoryPivotAggregatableSettledResult
                );
            })
            .finally(() =>
                isDialogOpenRef.current &&
                setLoading(false)
            );

        prevCoreId.current = coreId ?? prevCoreId.current;
    }, [coreId]);

    return {
        open: consumersState?.type === ConsumerActionType.BulkKYCHistoryResults,
        isDataLoading,
        consumersManualBulkKYCsImportHistoryPivotGroup,
        consumersManualBulkKYCsImportHistoryPivotAggregatable,
        handleDialogClose,
        downloadResults,
        isDownladResultsLoading
    };
};
