import { memo } from "react";
import Box from "@mui/material/Box";
import { SortOrder } from "consts/request-query";
import { compare } from "util/support";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { useAccordion } from "ui/atoms/Accordion";
import MerchantPaymentMethodsFiltersPanel from "./MerchantPaymentMethodsFiltersPanel";
import MerchantPaymentMethod from "./MerchantPaymentMethod";
import useMerchantPaymentMethods from "./useMerchantPaymentMethods";
import { Collection } from "ui/molecules/Resource";
import { NoDataOverlay } from "ui/molecules/TableGrids";

const MerchantPaymentMethodsWithFilters = () => {
    const { getMerchantPaymentMethods } = useMerchantPaymentMethods();

    const accordion = useAccordion();

    const sortTags = (entries: Array<[string, MerchantsMethodsPivot[]]>) =>
        entries.sort(([a], [b]) => compare(a, b, SortOrder.Asc));

    const sortMethods = (methods: Array<MerchantsMethodsPivot>) =>
        methods.sort((methodA, methodB) => {
            const compareByEnabled = compare(methodA.enabled, methodB.enabled, SortOrder.Desc);
            const compareByRiskLevel = compare(methodA.maxRiskLevel, methodB.maxRiskLevel, SortOrder.Desc);

            if (compareByEnabled) {
                return compareByEnabled;
            }

            if (compareByRiskLevel) {
                return compareByRiskLevel;
            }

            return compare(methodA.createdAt, methodB.createdAt, SortOrder.Asc);
        });

    const merchantPaymentMethods = getMerchantPaymentMethods({
        useTags: [sortTags],
        useMethods: [sortMethods]
    });

    return (
        <Box
            sx={{
                display: 'grid',
                gap: 2
            }}
        >
            <MerchantPaymentMethodsFiltersPanel />
            <Collection
                isEmpty={!merchantPaymentMethods.length}
                emptySlot={<NoDataOverlay />}
                collection={merchantPaymentMethods}
                renderResource={([key, merchantsMethods]) => (
                    <MerchantPaymentMethod
                        {...accordion}
                        key={key}
                        name={key}
                        merchantsMethods={merchantsMethods}
                    />
                )}
            />
        </Box>
    );
};

export default memo(MerchantPaymentMethodsWithFilters);
