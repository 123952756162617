import type { ChangeEvent, ReactNode } from 'react';
import { useState } from 'react';

export type UseLabeledSwitchArg = {
    readonly i18n: [ReactNode, ReactNode?];
    readonly getDefaultChecked: () => boolean;
    readonly confirmationHandler?: () => Promise<boolean>;
    readonly getDisabled?: () => boolean;
};

export default function useLabeledSwitch({
    i18n,
    getDefaultChecked,
    getDisabled,
    confirmationHandler = () => Promise.resolve(true)
}: UseLabeledSwitchArg) {
    const [isChecked, setCheckedState] = useState(getDefaultChecked);

    const getLabel = () => i18n[+isChecked] ?? i18n[0];

    const getChecked = () => isChecked;

    const onChange = async (_: ChangeEvent, checkedState: boolean) => {
        setCheckedState(checkedState);
        let confirmationResult = false;
        try {
            confirmationResult = await confirmationHandler();
        } catch {
            confirmationResult = false;
        }finally {
            if (!confirmationResult) {
                setCheckedState(!checkedState);
            }
        }
    };

    return {
        setCheckedState,
        getLabel,
        getDisabled,
        getChecked,
        onChange
    };
};
