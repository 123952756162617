import { AuthState, AuthStatus } from "./types";
import { getAuthToken } from "./helpers";

const isTokenAvailable = Boolean(getAuthToken());

const state: AuthState = {
  status: isTokenAvailable
    ? AuthStatus.Uninitialized
    : AuthStatus.Guest,
  user: null
};

export const sliceToken = 'auth';

export default state;
