import { useState } from "react";
import { TabIndex, DialogDataRegistry, ManageDuplicatesDataRegistry } from "./types";
import type { KYCEntity } from "features/kyc/types";

export default function useDialogDataRegistry(): [
    DialogDataRegistry,
    (tabIndex: TabIndex) =>
        (registryData: DialogDataRegistry[typeof tabIndex]) => void
] {
    const [dialogDataRegistry, setDialogDataRegistry] = useState<DialogDataRegistry>({
        [TabIndex.MoreDetails]: {
            activityDetails: {
                createdAt: '',
                blacklistedAt: '',
                whitelistedAt: '',
                lastActiveAt: '',
                importedFrom: ''
            },
            transactionOrigins: [],
            transactionStats: null
        },
        [TabIndex.MarkAsKYCed]: Array.of<KYCEntity>(),
        [TabIndex.ManageDuplicates]: Array.of<ManageDuplicatesDataRegistry>()
    });

    const updateDialogDataRegistry = (tabIndex: TabIndex) =>
        (registryData: DialogDataRegistry[typeof tabIndex]) =>
            setDialogDataRegistry(state => ({
                ...state,
                [tabIndex]: registryData
            }));

    return [dialogDataRegistry, updateDialogDataRegistry];
};
