import { memo } from "react";
import { WebRoutes } from "consts/enpoints/web";
import { getSetupsKYCSwitch, updateSetupsKYCSwitch } from "features/kyc/api";
import { EnabledMerchants } from "ui/organizms/Merchants";
import MasterConnection from "ui/organizms/Merchants/MasterConnection";
import { useKYCSettingsStateSelectorsContext } from "../KYCSettingsProvider";

const KYCMerchantList = () => {
    const { selectKYCSettingsLoadingState } = useKYCSettingsStateSelectorsContext();

    return (
        <EnabledMerchants
            title="KYC Settings"
            heading="KYC Settings"
            links={[{ name: 'KYC Settings' }]}
            Route={WebRoutes.Merchants.KYCSettings}
            selectApiState={selectKYCSettingsLoadingState}
            action={(
                <MasterConnection
                    getSetupsMasterConnectionSwitch={getSetupsKYCSwitch}
                    updateSetupsMasterConnectionSwitch={updateSetupsKYCSwitch}
                    content={[
                        {
                            dialogContent: [
                                'Are you sure you want to enable the KYC connection for all merchants?',
                                'This action will activate Shufti Pro’s services for the merchants with enabled KYC settings.'
                            ],
                            actionContent: 'Enable KYC'
                        },
                        {
                            dialogContent: [
                                'Are you sure you want to disable the KYC connection for all merchants?',
                                'This action will shut down Shufti Pro’s services.'
                            ],
                            actionContent: 'Disable KYC'
                        }
                    ]}
                />
            )}
        />
    );
};

export default memo(KYCMerchantList);
