import { memo } from "react";
import Screen from "ui/layouts/Screen";
import StatisticDashboard from "ui/organizms/StatisticDashboard";

const StatisticDashboardScreen = () => (
    <Screen
        title='Dashboard'
    >
        <StatisticDashboard />
    </Screen>
);

export default memo(StatisticDashboardScreen);
