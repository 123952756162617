import type { Status } from "types";
import useStatisticDashboardContext from "./useStatisticDashboardContext";
import { esTransactionsGroupByStatusQueryToken } from "./useStatisticDashboardQuery";

type RowParser<T = {
    readonly status: string;
    readonly groupCount: number;
}> = (row: (string | number)[]) => T;

export default function useStatisticDashboardUtils() {
    const statisticDashboard = useStatisticDashboardContext();

    const { payload } = statisticDashboard[esTransactionsGroupByStatusQueryToken];

    const getTransactionAmountByStatus = (
        status: Status,
        rowParser: RowParser = row => ({
            status: String(row.at(-1)),
            groupCount: Number(row.at(-3) ?? 0)
        })
    ): [number, number] => {
        let totalCount = 0;

        if (!payload) {
            return [0, totalCount];
        }

        const countByStatus = payload.rows.reduce((sum, row) => {
            const {
                status: transactionStatus,
                groupCount
            } = rowParser(row);

            totalCount += groupCount;

            if (Object.is(
                status,
                transactionStatus
            )) {
                return sum + groupCount;
            }

            return sum;
        }, 0) ?? 0;

        return [countByStatus, totalCount];
    };

    const getTransactionAmountPercentageByStatus = ({
        status,
        rowParser,
        amount
    }: {
        readonly status: Status;
        readonly rowParser?: RowParser;
        readonly amount?: number;
    }) => {
        const [countByStatus, totalCount] = getTransactionAmountByStatus(status, rowParser);

        if (amount) {
            return countByStatus && (amount / countByStatus);
        }

        return totalCount && (countByStatus / totalCount);
    };

    return {
        getTransactionAmountByStatus,
        getTransactionAmountPercentageByStatus
    };
};
