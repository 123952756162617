import { memo, useState, ReactNode } from "react";
import Icon from "ui/atoms/Icon";
import Modal from "ui/atoms/Modal";
import Stack from "@mui/material/Stack";
import Button from "ui/atoms/Button";
import Box from "@mui/material/Box";

export type DetailsOverlayProps = {
    readonly generalInfoSlot: ReactNode;
    readonly tabsSlot: ReactNode;
};

const DetailOverlay = ({
    generalInfoSlot,
    tabsSlot
}: DetailsOverlayProps) => {
    const [isOpen, setOpenState] = useState(false);

    const onOpen = () => {
        setOpenState(true);
    };

    const onClose = () => {
        setOpenState(false);
    };

    return (
        <Stack
            direction='row'
            justifyContent='center'
            m={2}
        >
            <Button
                onClick={onOpen}
                endIcon={<Icon name='Visibility' />}
            >
                View Transaction Details
            </Button>
            <Modal
                isOpen={isOpen}
                titleSlot='Transaction Detail'
                onClose={onClose}
                actionsSlot={
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                    >
                        <Button onClick={onClose}>
                            Close
                        </Button>
                    </Stack>
                }
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            '@media (min-width: 900px)': {
                                gridTemplateColumns: '45% calc(55% - 40px)',
                            }
                        },
                        gap: 5
                    }}
                >
                    {generalInfoSlot}
                    {tabsSlot}
                </Box>
            </Modal>
        </Stack>
    );
};

export default memo(DetailOverlay);
