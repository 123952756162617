import { memo } from "react";
import type { MerchantDomain } from "features/merchants/types";
import { DeleteBusinessEntity } from "../../shared/DeleteBusinessEntity";
import useMerchantDomains from "../../../useMerchantDomains";

const DeleteMerchantDomain = (merchantDomain: MerchantDomain) => {
    const { sourceUrl, businessName, isDeletable } = merchantDomain;
    const { deleteMerchantDomain } = useMerchantDomains();

    const onDelete = () => deleteMerchantDomain(merchantDomain);

    const renderDialogTitle = () => (
        `Are you sure you want to delete ${sourceUrl} of ${businessName}`
    );

    const renderHint = () => (
        'Have linked transactions'
    );

    return (
        <DeleteBusinessEntity
            isDeletable={isDeletable}
            onDelete={onDelete}
            renderDialogTitle={renderDialogTitle}
            renderHint={renderHint}
        />
    );
};

export default memo(DeleteMerchantDomain);
