import { memo, ReactNode } from "react";
import Tabs from "ui/atoms/Tabs";

type Props = {
    readonly model: Array<{
        readonly label: ReactNode;
        readonly component: ReactNode;
    }>;
    readonly onTabChange: (activeTab: string) => void;
};

const DetailTabs = ({
    model,
    onTabChange
}: Props) => (
    <Tabs
        model={model}
        onChange={onTabChange}
        TabListContainerProps={{
            borderBottom: 1,
            borderColor: 'divider'
        }}
        TabListProps={{
            'aria-label': "transaction details",
            variant: "scrollable",
            scrollButtons: "auto"
        }}
        TabPanelProps={{
            sx: {
                padding: 1
            }
        }}
        ContainerProps={{
            pl: 1,
            sx: {
                width: '100%',
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderColor: '#ccc'
            }
        }}
    />
);

export default memo(DetailTabs);
