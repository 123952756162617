import { memo } from "react";
import useBulkKYCHistoryResultsDialog from "../useBulkKYCHistoryResultsDialog";
import BulkKYCHistoryResultsTable from "./BulkKYCHistoryResultsTable";

type Props = Pick<
    ReturnType<typeof useBulkKYCHistoryResultsDialog>,
    | 'isDataLoading'
    | 'consumersManualBulkKYCsImportHistoryPivotGroup'
    | 'consumersManualBulkKYCsImportHistoryPivotAggregatable'
>;

const BulkKYCHistoryResultsDialog = ({
    isDataLoading,
    consumersManualBulkKYCsImportHistoryPivotGroup = []
}: Props) => (
    <>
        <BulkKYCHistoryResultsTable
            isDataLoading={isDataLoading}
            consumersManualBulkKYCsImportHistoryPivotGroup={consumersManualBulkKYCsImportHistoryPivotGroup}
        />
    </>
);

export default memo(BulkKYCHistoryResultsDialog);
