import type { GridCellParams, GridColumns, GridRowIdGetter, GridRowsProp } from "@mui/x-data-grid";
import { ResponseCode } from "consts/api";
import { CoreBankingEntity } from "types";
import { capitalizeFirstLetterFormatter, splitCamelCaseFormatter } from "util/formaters";
import { transformErrorResponseIntoMessage } from 'util/transformers';
import FailedColumnCell from "./FailedColumnCell";
import type { Settings, UseImportResultArg } from "./Props";


export default function useImportResult({
    data,
    failedClassName,
    successClassName,
    errorColumnField
}: UseImportResultArg & Settings) {
    const commonColumnProps = {
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: ({ row }: GridCellParams) => {
            return row.hasError
                ? failedClassName
                : successClassName;
        }
    };

    const getHeaderName = (currentColumnName: string) =>
        splitCamelCaseFormatter(currentColumnName)
            .split(' ')
            .map(capitalizeFirstLetterFormatter)
            .join(' ');

    const generateColumns = (data: Record<string, string> = {}) =>
        Object.keys(data)
            .reduce((acc: GridColumns, currentColumnName) => [
                ...acc,
                {
                    ...commonColumnProps,
                    field: currentColumnName,
                    headerName: getHeaderName(currentColumnName)
                }
            ], []);

    const isFailedAvailable = () => data.some(({ statusCode }) => [
        ResponseCode.BadRequest,
        ResponseCode.UnprocessibleEntity
    ].includes(statusCode));

    const getFailedReasonInfo = () => {
        if (!isFailedAvailable()) {
            return [];
        }

        return [
            {
                ...commonColumnProps,
                field: errorColumnField,
                resizable: false,
                width: 0,
                renderHeader: () => null,
                renderCell: ({ value, row }) => Boolean(row.hasError) && (
                    <FailedColumnCell>
                        {value}
                    </FailedColumnCell>
                )
            }
        ] as GridColumns;
    };

    const getRows = () => data.reduce((rows: GridRowsProp, current, index) => {
        const rowStrategies = new Map<ResponseCode, () => GridRowsProp>()
            .set(ResponseCode.Created, () => [
                {
                    ...current.content,
                    index,
                    [errorColumnField]: (current.content as CoreBankingEntity).coreId
                }
            ])
            .set(ResponseCode.BadRequest, () => [
                {
                    ...current.data,
                    hasError: true,
                    index,
                    [errorColumnField]: current.content.message
                }
            ])
            .set(ResponseCode.UnprocessibleEntity, () => [
                {
                    ...current.data,
                    hasError: true,
                    index,
                    [errorColumnField]: (
                        <>
                            {current.content.message}
                            <br />
                            {transformErrorResponseIntoMessage(current.content.errors as Record<string, string[]>)}
                        </>
                    )
                }
            ]);

        return [
            ...rows,
            ...(rowStrategies.get(current.statusCode)?.() ?? [])
        ];
    }, []);

    const getColumns = () => {
        const [head] = data;

        if (typeof head === 'undefined') {
            return [];
        }

        return [
            ...getFailedReasonInfo(),
            ...generateColumns((head.data ?? head.content) as Record<string, string>)
        ]
    };

    const getRowId: GridRowIdGetter = ({ index }) => index;

    return {
        generateColumns,
        getRows,
        getColumns,
        getRowId
    };
};
