import { useMemo } from 'react';
import { object, string } from 'yup';

export const enum FormFields {
    Email = 'email',
    SamePersonIdentifierSource = 'samePersonIdentifierSource'
};

export const getInitialValues = () => ({
    [FormFields.Email]: '',
    [FormFields.SamePersonIdentifierSource]: ''
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormFields.Email]: string()
                .email()
                .required('Email is required'),
            [FormFields.SamePersonIdentifierSource]: string()
                .required('Please specify a reason for adding this email')
        })
        , []);
