import { defaultValues as defaultTableValues } from "consts/table";
import { useCallback } from "react";

export type UseDefaultValues = {
    readonly defaultValues?: Array<[string, any]>;
};

export default function useDefaultValues({ defaultValues = [] }: UseDefaultValues) {
    return useCallback((urlSearchParams: URLSearchParams) => {
        for (const [filterName, defaultValue] of [
            ...defaultValues,
            ...defaultTableValues
        ]) {
            if (urlSearchParams.has(filterName)) {
                continue;
            }

            urlSearchParams.set(filterName, defaultValue);
        }

        return urlSearchParams;
    }, [defaultValues]);
};
