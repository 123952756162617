import { PropsWithChildren, MouseEvent, useState } from 'react';
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import { ConfirmatableDialog, type ConfirmatableDialogProps } from '../Dialog';
import { useModal } from 'ui/atoms/Modal';

type Props = {
    readonly ActionProps: LoadingButtonProps;
    readonly DialogProps: ConfirmatableDialogProps;
};

const Confirmable = ({
    ActionProps,
    DialogProps
}: PropsWithChildren<Props>) => {
    const { open, onOpen, onClose } = useModal();

    const [isLoading, setLoading] = useState(false);

    const handleActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        onOpen();
        ActionProps.onClick?.(event);
    };

    const handleonActionConfirm = () => {
        setLoading(true);

        return DialogProps.onConfirm()
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    const handleActionCancel = () => {
        onClose();
        DialogProps.onCancel?.();
    };

    return (
        <>
            <LoadingButton
                {...ActionProps}
                loading={isLoading}
                onClick={handleActionClick}
            >
                {ActionProps.children}
            </LoadingButton>
            <ConfirmatableDialog
                {...DialogProps}
                isProcessing={isLoading}
                open={open}
                onConfirm={handleonActionConfirm}
                onCancel={handleActionCancel}
            />
        </>
    );
};

export default Confirmable;
