import { useState } from 'react';
import type { ManageFileTaxes } from 'features/merchantPayouts/types';
import type { ID, ResourceResponse } from 'types';
import {
    createMerchantPayoutFileTaxCalculations,
    deleteMerchantPayoutFileTaxCalculations,
    getMerchantPayoutFileTaxCalculations,
    updateMerchantPayoutFileTaxCalculations
} from 'features/merchantPayouts/api';
import useMountedState from 'hooks/useMountedState';
import { compareIds } from 'util/support';
import { Filters } from 'consts/merchantPayouts';
import { useSimpleRequest } from 'hooks/useRequest';
import { RequestQueryMapper } from 'util/request-query-mapper';
import useActions from 'features/merchantPayouts/useActions';
import { useFileContext } from './FileProvider';
import { selectMerchantPayoutIncrementsVia } from 'features/general/selectors';
import { useTypedSelector } from 'hooks';


export default function useManageFileTaxes() {
    const [isLoading, setLoading] = useState(false);
    const [fileTaxes, setFileTaxes] = useState<Array<ManageFileTaxes>>([]);

    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);

    const file = useFileContext();

    const { getMerchantPayoutFilesById } = useActions();

    const setLoadingState = useMountedState(setLoading);
    const setFileTaxesState = useMountedState(setFileTaxes);

    const request = useSimpleRequest({ setLoading: setLoadingState });

    const addMany = (data: Array<ManageFileTaxes>) => setFileTaxesState(data);

    const addOne = (data: ManageFileTaxes) => {
        setFileTaxesState(state => [...state, data]);
    };

    const editById = (id: ID, data: ManageFileTaxes) =>
        setFileTaxesState(state => state.map(fileTax =>
            compareIds(fileTax.coreId, id)
                ? data
                : fileTax));

    const deleteById = (id: ID) =>
        setFileTaxesState(state => state.filter(fileTax => !compareIds(fileTax.coreId, id)));

    const getFileTaxes = async (id: ID) => {
        if (fileTaxes.length > 0) {
            return;
        }

        const { data: payload } = await request(() => getMerchantPayoutFileTaxCalculations(
            RequestQueryMapper.from()
                .containsIn(Filters.merchantPayoutFileId, `${id}`)
                .toString()
        )
        );

        addMany(payload.data);
    };

    const withFileRefetch = <T extends unknown>(handler: (arg: T) => ReturnType<typeof request<ManageFileTaxes>>) =>
        async (arg: T) => {
            const response = await handler(arg);

            getMerchantPayoutFilesById(file!.coreId);

            return response;
        };

    const deleteFileTaxes = withFileRefetch<ID>(id =>
        request(() => deleteMerchantPayoutFileTaxCalculations(`${id}`), {
            onSuccess: () => deleteById(id),
            notifyOnSuccess: true
        })
    );

    const updateFileTaxes = (fileTaxes: Partial<ManageFileTaxes>) =>
        request(() => updateMerchantPayoutFileTaxCalculations(fileTaxes), {
            notifyOnSuccess: true,
            onSuccess: ({ data }) => editById(fileTaxes.merchantPayoutFileId!, data)
        });

    const createFileTaxes = withFileRefetch<ManageFileTaxes>(newFileTaxes =>
        request(() => createMerchantPayoutFileTaxCalculations(newFileTaxes), {
            notifyOnSuccess: true,
            onSuccess: ({ data }: ResourceResponse<ManageFileTaxes>) => editById(newFileTaxes.merchantPayoutFileId, data)
        })
    );

    const getViaStatus = (value: string) => {
        return merchantPayoutIncrementsVia.find(({ id }) => Object.is(id, value))?.name ?? '-'
    }

    return {
        isLoading,
        data: fileTaxes,
        addOne,
        editById,
        deleteById,
        getFileTaxes,
        deleteFileTaxes,
        updateFileTaxes,
        createFileTaxes,
        getViaStatus
    };
};
