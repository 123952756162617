import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { ID } from "types";
import type { Role, UsersMerchantsPivot, UsersMorsPivot } from "./types";
import { decorateRoleOptions } from "./helpers";

export const selectIsUsersUninitialized = (state: RootState) =>
  state.users.usersLoadingState === ApiState.Idle;

export const selectIsUsersLoading = (state: RootState) =>
  state.users.usersLoadingState === ApiState.Pending;

export const selectIsExportUsersLoading = (state: RootState) =>
  state.users.exportLoadingState === ApiState.Pending;

export const selectUserSlice = (state: RootState) =>
  state.users.usersSlice;

export const selectProfileLoadingState = (state: RootState) =>
  state.users.userProfileLoadingState;

export const selectIsProfileLoading = (state: RootState) =>
  state.users.userProfileLoadingState === ApiState.Pending;

export const selectIsRolesLoading = (state: RootState) =>
  state.users.rolesLoadingState === ApiState.Pending;

export const selectRoleOptions = (state: RootState) =>
  decorateRoleOptions(
    state.users
      .rolesSlice
      ?.data
  );

export const selectMerchantRoleOptions = (state: RootState) =>
  decorateRoleOptions(
    state.users
      .rolesSlice
      ?.data
      .filter(({ roleName }: Role) => roleName.startsWith('Merchant'))
  );

export const selectIsUsersMerchantsLoading = (state: RootState) =>
  state.users.usersMerchantsLoadingState === ApiState.Pending;

export const selectMerchantsUserBelongs = (userId?: ID) => (state: RootState) => {
  const slice = state.users.usersMerchantsSlice;
  if (!userId || !slice) {
    return [];
  }

  return slice.data
    .filter((pivot: UsersMerchantsPivot) => Object.is(pivot.userId, userId))
    .map((pivot: UsersMerchantsPivot) => pivot.merchantId);
};

export const selectIsUsersMorsLoading = (state: RootState) =>
  state.users.usersMorsLoadingState === ApiState.Pending;

export const selectMorsUserBelongs = (userId?: ID) => (state: RootState) => {
  const slice = state.users.usersMorsSlice;
  if (!userId || !slice) {
    return [];
  }

  return slice.data
    .filter((pivot: UsersMorsPivot) => Object.is(pivot.userId, userId))
    .map((pivot: UsersMorsPivot) => pivot.MOR);
};
