import BusinessLogicException from "exceptions/BusinessLogicException";
import { ApiState } from "infrastructure/api";
import { type Reducer, useReducer, useState, Dispatch } from "react";
import type { DomainAction, ResourceResponse } from "types";

type UseDomainState<TState, TActionTypeEnum = unknown> = {
    readonly initialState: TState | null;
    readonly initialProcessingActionType?: TActionTypeEnum;
};

export function useDomainState<TDomainState, TActionTypeEnum = unknown>({
    initialProcessingActionType,
    initialState = null
} = {} as UseDomainState<TDomainState, TActionTypeEnum>) {
    const [
        processingActionTypeSet,
        setProcessingActionTypeSet
    ] = useState<Set<TActionTypeEnum>>(() => {
        const processingActionTypeSet = new Set<TActionTypeEnum>();

        if (typeof initialProcessingActionType !== 'undefined') {
            processingActionTypeSet.add(initialProcessingActionType);
        }

        return processingActionTypeSet;
    });

    const [
        domainState,
        setDomainState
    ] = useState<UseDomainState<TDomainState>['initialState']>(initialState);

    return {
        processingActionTypeSet,
        domainState,
        setDomainState,
        setProcessingActionTypeSet
    };
};

type ActionPayload = any;

export type Dispatcher<
    TActionType,
    TActionPayload = ActionPayload
> = Dispatch<DomainAction<TActionType, TActionPayload>>;

export interface IDomainRepository {
    reset(): void;

    shouldSendRequest(apiState: ApiState): boolean;

    throwOnFailedResponse<T extends unknown>(response: ResourceResponse<T>): ResourceResponse<T>;
}
export abstract class DomainRepository<TState, TActionType> implements IDomainRepository {
    public constructor(
        public readonly state: TState,
        protected readonly dispatch: Dispatcher<TActionType>
    ) {}

    public abstract reset(): void;

    public shouldSendRequest(apiState: ApiState): boolean {
        return [
            ApiState.Pending,
            ApiState.Succeeded
        ].includes(apiState);
    }

    public throwOnFailedResponse<T extends unknown>(response: ResourceResponse<T>): ResourceResponse<T> {
        if (!response.success) {
            throw new BusinessLogicException(response.message, response);
        }

        return response;
    }
}

type UseDomainRepositoryArg<
    TState,
    TActionType
> = {
    readonly reducer: Reducer<TState, DomainAction<TActionType, ActionPayload>>;
    readonly initializerArg: TState;
    readonly initializer?: (initializerArg: TState) => TState;
    readonly factory: (state: TState, dispatch: Dispatcher<TActionType>) =>
        DomainRepository<TState, TActionType>;
};

export function useDomainRepository<TState, TActionType>({
    reducer,
    initializerArg,
    factory,
    initializer = (arg: TState) => arg
}: UseDomainRepositoryArg<TState, TActionType>) {
    const [state, dispatch] = useReducer(reducer, initializerArg, initializer);

    return factory(state, dispatch);
};
