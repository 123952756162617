import { memo } from "react";
import { useToolbarFiltersContext, withProps } from "../ToolbarFiltersProvider";
import  { Autocomplete, type AutocompleteProps } from "ui/widgets/Filters";
import { useTypedSelector } from "hooks";
import { selectIsMerchantsLoading } from "features/merchants/selectors";

const AutocompleteFilter = withProps<AutocompleteProps>(Autocomplete);

export const componentKey = 'ActiveInactiveSelector';

const ActiveInactiveAutocomplete = () => {
    const loading = useTypedSelector(selectIsMerchantsLoading());
    const { getSelectorByComponentId, filtersRef } = useToolbarFiltersContext();
    
    return (
        <AutocompleteFilter
            {...getSelectorByComponentId(componentKey)?.(filtersRef.current)}
            loading={loading}
            disabled={loading}
        />
    );
};

export default memo(ActiveInactiveAutocomplete);
