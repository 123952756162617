import { memo } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import Box from '@mui/material/Box';
import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DownloadableAction } from "ui/molecules/Action";
import type { UseDownloadable } from "ui/molecules/Action/Downloadable";

export type DownloadableFileProps = Partial<UseDownloadable> & {
    readonly file: string | File;
    readonly onRemoveFile?: IconButtonProps['onClick'];
    readonly renderDeleteFileAction?: (file: string | File) => ReactNode;
};

const DownloadableFile = ({
    file,
    onRemoveFile,
    renderDeleteFileAction,
    downloadFile = async (_: string) => new Response(),
    children
}: PropsWithChildren<DownloadableFileProps>) => {
    const renderFileName = () =>
        children || (
            typeof file === 'string'
                ? file
                : file?.name
        );

    const handleRenderDeleteFileAction = () => {
        const Component = (
            <IconButton
                onClick={onRemoveFile}
            >
                <DeleteForeverIcon
                    color='disabled'
                />
            </IconButton>
        );

        if (renderDeleteFileAction) {
            const Node = renderDeleteFileAction(file);

            return typeof Node === 'undefined'
                ? Component
                : Node;
        }

        return Component;
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr auto',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: 1,
                border: 'dashed 1px rgba(145, 158, 171, 0.24)',
                borderRadius: '10px',
                py: '5px',
                width: '100%',
                mb: '10px'
            }}
        >
            <DownloadableAction
                file={file}
                downloadFile={downloadFile}
            >
                <SaveAltIcon
                    color='disabled'
                />
            </DownloadableAction>
            <Box
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}
            >
                {renderFileName()}
            </Box>
            {handleRenderDeleteFileAction()}
        </Box>
    );
};

export default memo(DownloadableFile);
