import { memo } from "react";
import Screen from "ui/layouts/Screen";
import Login from "ui/organizms/Login";

const LoginScreen = () => (
    <Screen
        title='Sign in'
    >
        <Login />
    </Screen>
);

export default memo(LoginScreen);
