import { useMemo } from "react";
import { array, number, object, string } from "yup";
import { FormField } from "./types";
import { useValidationSchema as useMerchantValidationSchema } from "../Merchant";

const useValidationSchema = () => {
    const merchantValidationSchema = useMerchantValidationSchema();

    return useMemo(() => merchantValidationSchema.concat(
        object().shape({
            [FormField.Groups]: array()
                .of(number())
                .required()
                .label('Groups'),
            [FormField.Website]: string()
            .matches(/^(https?:\/\/|www\.)\S+\.\S+$/, 'Invalid URL')
            .optional()
                .label('Website'),
            [FormField.Currency]: string()
                .optional()
                .label('Currency')
        })
    ), [merchantValidationSchema]);
};

export default useValidationSchema;
