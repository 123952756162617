import { KYCServiceState, kycServiceTransactionLimit } from "consts/kyc";
import type { MerchantKYCService } from "features/merchants/types";

export const isServiceOff = ({ kycLimit, summedAmountEurLimit }: Pick<
    MerchantKYCService,
    | 'kycLimit'
    | 'summedAmountEurLimit'
>) => (
    (kycLimit === kycServiceTransactionLimit.get(KYCServiceState.Off)) &&
    [kycServiceTransactionLimit.get(KYCServiceState.Strict)].includes(Number(summedAmountEurLimit))
);

export const isServiceStrict = ({ kycLimit }: Pick<
    MerchantKYCService,
    | 'kycLimit'
    | 'summedAmountEurLimit'
>) => (
    kycLimit === kycServiceTransactionLimit.get(KYCServiceState.Strict)
);

export const isServiceSoft = ({ kycLimit, summedAmountEurLimit }: Pick<
    MerchantKYCService,
    | 'kycLimit'
    | 'summedAmountEurLimit'
>) => (
    (Number(kycLimit) > kycServiceTransactionLimit.get(KYCServiceState.Strict)!) ||
    (
        (kycLimit === kycServiceTransactionLimit.get(KYCServiceState.Off)) &&
        (Number(summedAmountEurLimit) > kycServiceTransactionLimit.get(KYCServiceState.Strict)!)
    )
);

export const transactionLimitConstraintValidator = new Map<
    KYCServiceState,
    ((transactionLimit: string) => number | null)
>([
    [KYCServiceState.Off, (_: string) =>
        kycServiceTransactionLimit.get(KYCServiceState.Off)!],

    [KYCServiceState.Strict, (_: string) =>
        kycServiceTransactionLimit.get(KYCServiceState.Strict)!],

    [KYCServiceState.Soft, (transactionLimit: string) => {
        const transactionLimitNumber = Number(transactionLimit);
        const minTrxLimit = kycServiceTransactionLimit.get(KYCServiceState.Soft)!;

        if (transactionLimitNumber >= minTrxLimit) {
            return transactionLimitNumber;
        }

        return kycServiceTransactionLimit.get(KYCServiceState.Off)!;
    }]
]);

export const summedAmountEurLimitConstraintValidator = new Map<
    KYCServiceState,
    ((transactionLimit: number | null, summedAmountEurLimit: string) => number)
>([
    [KYCServiceState.Off, (_: number | null, __: string) =>
        kycServiceTransactionLimit.get(KYCServiceState.Strict)!],

    [KYCServiceState.Strict, (_: number | null, __: string) =>
        kycServiceTransactionLimit.get(KYCServiceState.Strict)!],

    [KYCServiceState.Soft, (
        transactionLimit: number | null,
        summedAmountEurLimit: string
    ) => {
        const transactionLimitNumber = Number(transactionLimit);
        const summedAmountEurLimitNumber = Number(summedAmountEurLimit);

        const minStrictAmountLimit = kycServiceTransactionLimit.get(KYCServiceState.Strict)!;
        const minSoftAmountLimit = kycServiceTransactionLimit.get(KYCServiceState.Soft)!;

        // if transaction limit is null
        if (!transactionLimitNumber) {
            return summedAmountEurLimitNumber >= minSoftAmountLimit
                ? summedAmountEurLimitNumber
                : minSoftAmountLimit;
        }

        // if transaction limit is not null
        if (transactionLimitNumber >= minStrictAmountLimit) {
            return summedAmountEurLimitNumber >= minStrictAmountLimit
                ? summedAmountEurLimitNumber
                : minStrictAmountLimit;
        }

        return minStrictAmountLimit;
    }]
]);
