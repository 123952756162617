import { useRef, useCallback } from 'react';
import { TicketingSystemService, TicketingSystemState } from "./types";
import { useApiState } from 'hooks';

export default function useTicketingSystemStateSelectors(ticketingSystemState: TicketingSystemState) {
    const ticketingSystemStateRef = useRef<TicketingSystemState>(ticketingSystemState);
    ticketingSystemStateRef.current = ticketingSystemState;

    const selectTicketingSystemServicesState = useCallback(() =>
        ticketingSystemStateRef.current.ticketingSystemSettings
        , []);

    const selectTicketingSystemResources = useCallback(() =>
        ticketingSystemStateRef.current.resources
        , []);

    const selectTicketingSystemApiState = useApiState(
        ticketingSystemStateRef
            .current
            .ticketingSystemSettingsApiState
    );

    const selectTicketingSystemEnabledState = useCallback((service: TicketingSystemService) =>
        ticketingSystemStateRef
            .current
            .ticketingSystemSettings
            [service]
            .enabled
        , []);

    const selectTicketingSystemAdditionalOption = useCallback(<TAdditionalOption>(service: TicketingSystemService) =>
        ticketingSystemStateRef
            .current
            .ticketingSystemSettings
            [service]
            .additionalOptions as TAdditionalOption
        , []);

    const selectIsTicketingSystemSettingsChanged = useCallback(() =>
        ticketingSystemStateRef
            .current
            .isTicketingSystemSettingsChanged
        , []);

    return {
        selectTicketingSystemResources,
        selectTicketingSystemServicesState,
        selectTicketingSystemApiState,
        selectTicketingSystemEnabledState,
        selectTicketingSystemAdditionalOption,
        selectIsTicketingSystemSettingsChanged
    };
};
