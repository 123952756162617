import useForm, { UseFormArg } from "hooks/useForm";
import type { Form } from "types";
import type { FormValues } from "./IncrementTaxes"
import { getInitialValues, useValidationSchema } from "./validators";

type UseIncrementTaxesFormArgs =
    & UseFormArg<FormValues>
    & {
        readonly initialValues: Form<FormValues>['initialValues'] 
    };

export default function useIncrementTaxesForm({
    initialValues,
    ...restFormArgs
}: UseIncrementTaxesFormArgs) {
    const validationSchema = useValidationSchema();

    return useForm({
        ...restFormArgs,
        initialValues: {
            ...getInitialValues(),
            ...(initialValues ?? {})
        },
        validationSchema
    });
};
