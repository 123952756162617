import { memo, useMemo } from "react";
import { CurrencySymbol } from "consts/general";
import { fNumber } from "util/formaters";
import Cassette from "./Cassette";
import {
    consumerEmailsRequestQueryParamsFactory,
    esTransactionsGroupByCurrencyQueryToken,
    totalCountRequestQueryParamsFactory,
    useStatisticDashboardContext
} from "../StatisticDashboardProvider";
import { useQuery } from "hooks/useQuery";
import type { PaginateResourceResponse, ResourceResponse } from "types";
import type { TransactionsResponse } from "features/transactions/types";
import type { EsTransactionsResponse } from "../StatisticDashboardProvider";
import { getEsTransactions } from "features/transactions/api";
import { getConsumerEmails } from "features/consumers/api";
import { getPromiseSettledResourceResult } from "util/resource";
import { Filters as FiltersTransactions } from "consts/transactions";
import { Filters } from "consts/filters";
import { Period } from "consts/period";
import { getDiff } from "../StatisticDashboardData";
import { PeriodAdapter } from "util/period-adapter";

const AvgTransactionSize = () => {
    const statisticDashboard = useStatisticDashboardContext();

    const { payload, isLoading } = statisticDashboard[esTransactionsGroupByCurrencyQueryToken];

    const [totalAmountEur, totalGroupCount] = useMemo(() => {
        const initialValues = Array.of(0, 0);

        return payload?.rows.reduce(([totalAmountEur, totalGroupCount], row) => [
            Number(totalAmountEur) + Number(row.at(1)),
            Number(totalGroupCount) + Number(row.at(-3))
        ], initialValues) ?? initialValues
    }, [payload]);

    const initialState = {
        isLoading: false
    };

    const consumerEmailsQuery = useQuery<PaginateResourceResponse<unknown>>({
        getRequestQueryParams: consumerEmailsRequestQueryParamsFactory('SUCCEEDED'),
        fetchQuery: getConsumerEmails,
        initialState
    });

    const totalCountTransactionsPerDistictEmailsQuery = useQuery<EsTransactionsResponse>({
        getRequestQueryParams: requestQueryMapper =>
            totalCountRequestQueryParamsFactory()(
                requestQueryMapper
                    .containsIn(FiltersTransactions.status, 'SUCCEEDED')
            ),
        fetchQuery: getEsTransactions,
        initialState
    });

    const query = async (): Promise<ResourceResponse<{
        readonly consumerEmails: PaginateResourceResponse<unknown>['data'],
        readonly totalCountTransactionsPerDistictEmails: EsTransactionsResponse['data']
    }>> => {
        const requestSearchParams = statisticDashboard.requestSearchParamsRef.current;

        const [
            consumerEmailsSettledResponse,
            totalCountTransactionsPerDistictEmailsSettledResponse
        ] = await Promise.allSettled([
            consumerEmailsQuery.query(requestSearchParams),
            totalCountTransactionsPerDistictEmailsQuery.query(requestSearchParams),
        ]);

        const [
            consumerEmailsResponse,
            totalCountTransactionsPerDistictEmailsResponse
        ] = [
                getPromiseSettledResourceResult(consumerEmailsSettledResponse),
                getPromiseSettledResourceResult(totalCountTransactionsPerDistictEmailsSettledResponse)
            ];

        return {
            data: {
                consumerEmails: (
                    consumerEmailsResponse as TransactionsResponse
                ).data,
                totalCountTransactionsPerDistictEmails: (
                    totalCountTransactionsPerDistictEmailsResponse as EsTransactionsResponse
                ).data
            },
            message: '',
            code: 200,
            success: true,
            locale: ''
        };
    };

    const getAvgUniqueEmailsPerTransaction = ({
        data: {
            consumerEmails,
            totalCountTransactionsPerDistictEmails
        }
    }: Awaited<ReturnType<typeof query>>) => {
        const daysDiff = getDiff({
            precise: true,
            period: PeriodAdapter.getPeriodType({
                period: Period.Day,
                field: Filters.createdAt
            })
        });

        return (totalCountTransactionsPerDistictEmails.total_rows || 0) /
            ((consumerEmails.total || 1) * daysDiff);
    };

    return (
        <Cassette
            isLoading={isLoading}
            labelSlot='Average transaction size'
            amountSlot={fNumber(Number(totalAmountEur) / Number(totalGroupCount), '0,0.00')}
            currencySlot={CurrencySymbol.EUR}
            footerSlot='Trx. number / person / day'
            tooltipProps={{
                title: 'Calculate the trx. number / person / day'
            }}
            lazyQuery={{
                payload: null,
                isLoading: (
                    totalCountTransactionsPerDistictEmailsQuery.isLoading ||
                    consumerEmailsQuery.isLoading
                ),
                query
            }}
            countGetter={getAvgUniqueEmailsPerTransaction}
            countFormat='0,0.00'
        />
    );
};

export default memo(AvgTransactionSize);
