import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import type { Transaction } from "features/transactions/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { memo} from "react";
import usePingOrderActions from "./usePingOrderActions";



type Props = LoadingButtonProps & Pick<Transaction, 'pingTransactionCoreId' >;

const ClosePingOrderAction = ({ pingTransactionCoreId, ...restProps }: Props) => {
  const {checkIfClosePingOrderAvailable} = usePingOrderActions({pingTransactionCoreId})
  return (
    <>
     { checkIfClosePingOrderAvailable() && ( 
        <LoadingButton
                {...restProps}
        >
           Close PING order
        </LoadingButton>

     )}
    </>
  );
};

export default memo(ClosePingOrderAction);