
import { KYCProcessingStatus, KYCServiceType } from "consts/kyc";
import { useState, useRef } from "react";
import type { BaseKYCServiceState, KYCServicesRecord } from "./types";

type UseKYCServicesArg<TServiceState extends BaseKYCServiceState> = {
    readonly getKYCServiceState?: () => TServiceState;
};

export default function useKYCServices<
    TServiceState extends BaseKYCServiceState>({
        getKYCServiceState = <
            TServiceState extends BaseKYCServiceState
        >() => ({ disabled: false, checked: false }) as TServiceState
    }: UseKYCServicesArg<TServiceState> = {}) {

    const getDefaultServicesState = () => [
        KYCServiceType.FaceVerification,
        KYCServiceType.IDVerification,
        KYCServiceType.AMLVerification,
        KYCServiceType.AddressVerification,
        KYCServiceType.PhoneVerification,
        KYCServiceType.EIDVerification
    ].reduce((acc, kycTypeId) => ({
        ...acc,
        [kycTypeId]: getKYCServiceState()
    }), {} as KYCServicesRecord<TServiceState>);

    const [servicesState, setServicesState] = useState<
        ReturnType<typeof getDefaultServicesState>
    >(getDefaultServicesState);

    const serviceStateRef = useRef<
        ReturnType<typeof getDefaultServicesState>
    >(servicesState);

    serviceStateRef.current = servicesState;

    const onKYCServiceChange = ([
        kycServiceType,
        kycServiceState
    ]: [KYCServiceType, Partial<TServiceState>]) =>
        setServicesState(state => ({
            ...state,
            [kycServiceType]: {
                ...state[kycServiceType],
                ...kycServiceState
            }
        }));

    const resetKYCServicesState = () =>
        setServicesState(getDefaultServicesState());

    const getKYCServicesCollection = ({ getStatus, getProvider }: {
        getStatus: (type: KYCServiceType) => KYCProcessingStatus | null | undefined,
        getProvider?: (type: KYCServiceType) => string | null
    }) => [
        {
            type: KYCServiceType.FaceVerification,
            name: 'Face Verification',
        },
        {
            type: KYCServiceType.IDVerification,
            name: 'ID verification'
        },
        {
            type: KYCServiceType.AMLVerification,
            name: 'AML Screening'
        },
        {
            type: KYCServiceType.AddressVerification,
            name: 'Address Verification'
        },
        {
            type: KYCServiceType.PhoneVerification,
            name: 'Phone Verification'
        },
        {
            type: KYCServiceType.EIDVerification,
            name: 'EID Verification'
        }
    ].map(kycService => ({
        ...kycService,
        status: getStatus(kycService.type),
        provider: getProvider?.(kycService.type)
    }));

    return {
        servicesState,
        setServicesState,
        serviceStateRef,
        onKYCServiceChange,
        resetKYCServicesState,
        getKYCServicesCollection
    };
};
