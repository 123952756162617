import { useState, type SyntheticEvent } from "react";
import type { Scalar } from "types";

export default function useAccordion() {
    const [expanded, setExpanded] = useState<Scalar<boolean>>(false);

    const handleChange = (panel: Scalar<string>) => (_: SyntheticEvent, isExpanded: boolean) =>
        setExpanded(isExpanded ? panel : false);

    return {
        expanded,
        handleChange
    };
};
