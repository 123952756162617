import { useEffect, useRef } from "react";
import type { Merchant } from "features/merchants/types";
import useMerchantUserActions from "features/merchants/useMerchantUserActions";
import useMerchantLedgerActions from "features/merchants/useMerchantLedgerActions";
import usePersistMerchant from "../usePersistMerchant";
import { useResourceContext } from "ui/widgets/Resource";

export default function useResetMerchantDetail() {
    const resourceContext = useResourceContext<Merchant>();
    const merchantUserActions = useMerchantUserActions();
    const merchantLedgerActions = useMerchantLedgerActions();
    const persistMerchant = usePersistMerchant();

    const helpers = {
        ...resourceContext,
        ...merchantUserActions,
        ...merchantLedgerActions,
        ...persistMerchant
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        const {
            resource,
            reset,
            resetMerchantUsers,
            resetMerchantLedgers,
            persistMerchant
        } = helpersRef.current;

        const resetState = () => {
            reset();
            resetMerchantUsers();
            resetMerchantLedgers();
        };

        resetState();
        persistMerchant(resource);

        return () => {
            resetState();
        };
    }, []);
};
