import { useModal } from "ui/atoms/Modal";
import { generateFileName } from "util/support";
import { DownloadCSVTemplateDialogProps } from "./DownloadCSVTemplateDialog";

type UseDownloadCSVTemplateArg = {
    readonly fileName?: string;
    readonly fileExtension?: string;
} & Pick<DownloadCSVTemplateDialogProps, 'downloadTemplateHandler'>


export default function useDownloadCSVTemplate({
    fileName = 'csvTemplateSample',
    fileExtension = 'csv',
    downloadTemplateHandler
}: UseDownloadCSVTemplateArg)
 {
    const { onClose, open, onOpen } = useModal();

    const handleOpen = () => {
        onOpen();

        downloadTemplateHandler(
            generateFileName({
                prefix: fileName,
                extension: fileExtension
            })
        )
        .finally(onClose)
    };

    return {
        onOpen: handleOpen,
        onClose,
        open
    };
};
