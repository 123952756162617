import type { KYCEntity } from "features/kyc/types";
import useMultiTabDialog from "./useMultiTabDialog";
import { Dispatch, SetStateAction } from "react";
import type { Consumer, BlacklistedEmailEntity } from "features/consumers/types";
import type { Aggregatable, ID, Paginator } from "types";
import type { Transaction, TransactionOrigin } from "features/transactions/types";

export const enum TabIndex {
    MoreDetails = '1',
    MarkAsKYCed = '2',
    ManageDuplicates = '3'
};

export type TabProps<TTabIndex extends TabIndex> = {
    readonly getTabPayload: ReturnType<typeof useMultiTabDialog>['getTabPayload'];
    readonly tabRegistry: [
        DialogDataRegistry[TTabIndex],
        (registryData: DialogDataRegistry[TTabIndex]) => void
    ];
    readonly dialogRequestProcessing: readonly [boolean, Dispatch<SetStateAction<boolean>>]
};

export interface ManageDuplicatesDataRegistry extends ManageDuplicatesActions {
    readonly consumer: Consumer;
    readonly blacklistedEmailEntity: BlacklistedEmailEntity | null;

    readonly id: ID;
    readonly isBlacklisted: boolean;
    readonly email: string | null;
    readonly hashedEmail: string | null;
    readonly computedEmail: string;
};

export interface ManageDuplicatesActions {
    whitelist(): ManageDuplicatesDataRegistry;
    blacklist(): ManageDuplicatesDataRegistry;
};

export type ConsumerActivityDetails = {
    readonly createdAt: string;
    readonly blacklistedAt: string | null;
    readonly whitelistedAt: string | null;
    readonly lastActiveAt: string | null;
    readonly importedFrom: string | null;
};

export type DialogDataRegistry = {
    readonly [TabIndex.MoreDetails]: {
        readonly activityDetails: ConsumerActivityDetails;
        readonly transactionOrigins: Array<TransactionOrigin>;
        readonly transactionStats: Paginator<Transaction & Aggregatable> | null;
    };
    readonly [TabIndex.MarkAsKYCed]: Array<KYCEntity>;
    readonly [TabIndex.ManageDuplicates]: Array<ManageDuplicatesDataRegistry>;
};
