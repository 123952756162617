import { memo, Dispatch, SetStateAction } from 'react';
import Table from 'ui/molecules/Table';
import { TabIndex, TabProps } from 'ui/organizms/Consumers/ConsumersDialogs/MultiTabDialog/types';
import { DuplicateEmailsTableBody, DuplicateEmailsTableHead } from './DuplicateEmailsTable';
import useDuplicateEmailsList from './useDuplicateEmailsList';

type Props = TabProps<TabIndex.ManageDuplicates> & {
    readonly requestLoading: [boolean, Dispatch<SetStateAction<boolean>>]
};

const DuplicateEmails = (props: Props) => {
    useDuplicateEmailsList(props);

    const {
        tabRegistry: [manageDuplicatesDataRegistry],
        requestLoading: [isLoading]
    } = props;

    return (
        <Table
            isLoading={isLoading}
            isEmpty={manageDuplicatesDataRegistry.length === 0}
            emptyMessageSlot="No duplicates found"
        >
            <DuplicateEmailsTableHead />
            <DuplicateEmailsTableBody
                {...props}
            />
        </Table>
    );
};

export default memo(DuplicateEmails);
