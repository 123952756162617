import { memo, useState } from "react";
import Box from "@mui/material/Box";
import { FormField, type MerchantAccountApiSettingsProps } from "./types";
import { useForm } from "./useForm";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { ClipboardTextField } from "ui/molecules/Input";
import { getFormInputProps } from "util/forms";
import LoadingButton from "@mui/lab/LoadingButton";
import { ConfirmatableDialog } from "ui/molecules/Dialog";

const disabledInputStyles = {
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'black',
    },
};
const AccountApiSettings = ({ initialValues, onSaveOrCreate, fullName }: MerchantAccountApiSettingsProps) => {
    const form = useForm({
        initialValues,
        onSaveOrCreate
    });

    const handleConfirm = async () => {
        await handleSubmit();
        setOpen(false);
    };
    const {
        values,
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur
    } = form;
    const [open, setOpen] = useState<boolean>(false)



    return (
        <TabLayout
            title='Account API Settings'
        >
            <Box
                component='form'
                sx={{
                    pt: 2,
                    display: 'grid',
                    gap: 2
                }}

            >
                <ClipboardTextField
                    {...getFormInputProps(FormField.ApiId, form)}
                    sx={disabledInputStyles}
                    disabled
                    required
                    fullWidth
                    size='small'
                    name={FormField.ApiId}
                    value={values[FormField.ApiId]}
                    label='API-ID'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ClipboardTextField
                    {...getFormInputProps(FormField.ApiGuid, form)}
                    sx={disabledInputStyles}
                    disabled
                    required
                    fullWidth
                    size='small'
                    name={FormField.ApiGuid}
                    value={values[FormField.ApiGuid]}
                    label='API-GUID'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ClipboardTextField
                    {...getFormInputProps(FormField.CpKey, form)}
                    sx={disabledInputStyles}
                    disabled
                    required
                    fullWidth
                    size='small'
                    name={FormField.CpKey}
                    value={values[FormField.CpKey]}
                    label='CPKey'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ClipboardTextField
                    {...getFormInputProps(FormField.CpSecret, form)}
                    sx={disabledInputStyles}
                    disabled
                    required
                    fullWidth
                    size='small'
                    name={FormField.CpSecret}
                    value={values[FormField.CpSecret]}
                    label='CPSecret'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ClipboardTextField
                    {...getFormInputProps(FormField.SharedKey, form)}
                    sx={disabledInputStyles}
                    disabled
                    required
                    fullWidth
                    size='small'
                    name={FormField.SharedKey}
                    value={values[FormField.SharedKey]}
                    label='Shared Key'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <LoadingButton
                    onClick={() => setOpen(true)}
                    variant='outlined'
                    color='primary'
                    size='large'
                    loading={isSubmitting}
                    sx={{
                        mt: 2,
                        justifySelf: 'flex-start'
                    }}
                >
                    Generate API Keys
                </LoadingButton>
                <ConfirmatableDialog
                    isProcessing={isSubmitting}
                    open={open}
                    checkPredicate={() => false}
                    content={[
                        {
                            dialogContent: [
                                `Are you sure that you would like to regenerate API Keys for merchant ${fullName}`
                            ],
                            actionContent: 'Yes'
                        },
                        {
                            dialogContent: [
                                `Are you sure that you would like to regenerate API Keys for merchant ${fullName}`
                            ],
                            actionContent: 'Yes'
                        }
                    ]}
                    onConfirm={() => handleConfirm()}
                    onCancel={() => setOpen(false)}
                    cancelActionContentSlot='No'

                />
            </Box>
        </TabLayout>
    );
};

export default memo(AccountApiSettings);
