import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { MerchantBusiness } from "features/merchants/types";

const MerchantBusinessName = ({ businessName }: MerchantBusiness) => (
    <Typography
        sx={{
            fontWeight: 600,
            textTransform: 'capitalize'
        }}
    >
        {businessName}
    </Typography>
);

export default memo(MerchantBusinessName);
