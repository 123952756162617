import type { FC } from "react";
import { AbstractActionableFilterProps, FilterRenderTypes, type Filter as FilterProps } from "../types";
import {
    Autocomplete as AutocompleteFilter,
    PopoverSelect as PopoverSelectFilter,
    type AutocompleteProps as AutocompleteFilterProps,
    type PopoverSelectProps as PopoverSelectFilterProps
} from "./FilterVariants/SelectFilter";
import {
    DateRange as DateRangeFilter,
    DatePicker as DatePickerFilter,
    PopoverPeriod as PopoverPeriodFilter,
    type DateRangeProps as DateRangeFilterProps,
    type DatePickerProps as DatePickerFilterProps,
    type PopoverPeriodProps as PopoverPeriodFilterProps
} from "./FilterVariants/DateFilter";
import {
    TextField as TextFieldFilter,
    PopoverTextField as PopoverTextFieldFilter,
    type TextFieldProps as TextFieldFilterProps,
    type PopoverTextFieldProps as PopoverTextFieldFilterProps
} from "./FilterVariants/TextFieldFilter";
import NumericRangeFilter, { type NumericRangeFilterProps } from "./FilterVariants/NumericRangeFilter";

const Filters: FC<FilterProps & Pick<AbstractActionableFilterProps, 'onChange'>> = ({ renderAs, props, onChange }) => ({
    [FilterRenderTypes.Dropdown]: (
        <AutocompleteFilter
            {...props as AutocompleteFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.PopoverDropdown]: (
        <PopoverSelectFilter
            {...props as PopoverSelectFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.DateRange]: (
        <DateRangeFilter
            {...props as DateRangeFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.PopoverPeriodDropdown]: (
        <PopoverPeriodFilter
            {...props as PopoverPeriodFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.TextField]: (
        <TextFieldFilter
            {...props as TextFieldFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.PopoverTextField]: (
        <PopoverTextFieldFilter
            {...props as PopoverTextFieldFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.DatePicker]: (
        <DatePickerFilter
            {...props as DatePickerFilterProps}
            onChange={onChange}
        />
    ),
    [FilterRenderTypes.NumericRange]: (
        <NumericRangeFilter
            {...props as NumericRangeFilterProps}
            onChange={onChange}
        />
    )
}[renderAs]);

export default Filters;
