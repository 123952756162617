import { memo } from "react";
import Cassette from "../Cassette";
import useStatisticCassettesContext from "../useStatisticCassettesContext";
import { loggedTodayMerchantUsersAggregateQueryToken } from "../useStatisticCassettes";

const LoggedToday = () => {
    const { [loggedTodayMerchantUsersAggregateQueryToken]: query } = useStatisticCassettesContext();

    return (
        <Cassette
            isLoading={query.isLoading}
            labelSlot='Logged Today'
            amountSlot={query.payload?.total || 0}
        />
    );
};

export default memo(LoggedToday);
