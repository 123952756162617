import Chip, { type ChipProps } from "@mui/material/Chip";
import { getFallbackValue, hasFallback, isStatusValid } from "./helpers";
import withGridCellParams from "./withGridCellParams";
import type { ReactNode } from "react";

type EnhancedChipProps<TStatusType extends string> = ChipProps & {
    readonly renderLabel?: (value?: TStatusType) => ReactNode;
};

const withStatus = <TStatusType extends string>(statusIconMapping: Record<
    TStatusType,
    EnhancedChipProps<TStatusType>
>) =>
    withGridCellParams<TStatusType>(({ value }) => {
        let isValid = true;
        let statusValue: null | EnhancedChipProps<TStatusType> = null;

        if (!isStatusValid(statusIconMapping, value!)) {
            isValid = false;
            statusValue = hasFallback(statusIconMapping)
                ? getFallbackValue(statusIconMapping)
                : null;
        }

        const props = isValid
            ? statusIconMapping[value!]
            : statusValue;

        if (props) {
            const { renderLabel = v => v, ...chipProps } = props;

            return (
                <Chip
                    size='small'
                    {...chipProps}
                    label={renderLabel(value)}
                />
            );
        }

        return null;
    });

export default withStatus;
