import { memo } from "react";
import type { GridColumns } from "@mui/x-data-grid-premium";
import FrontPanelWidget, {
    FrontPanelWidgetProvider,
    FrontPanelWidgetProviderProps
} from "./FrontPanelWidget";
import useFilable from "./useFilable";
import FiltersPublicApiProvider from "ui/organizms/MerchantPayouts/FiltersPublicApiProvider";
import useFilterPropsOverride from "./useFilterPropsOverride";
import useRequestQueryOverrideDecorator from "./useRequestQueryOverrideDecorator";
import MerchantPayoutsTable, { type MerchantPayoutsTableProps } from "../MerchantPayoutsTable";

type Props = MerchantPayoutsTableProps
    & FrontPanelWidgetProviderProps
    & {
        readonly columns?: GridColumns;
    };

const Filable = ({
    formId,
    createNewFileRequestHandler,
    mergeFilesRequestHandler,
    getInitialValues,
    getBootstrapData,
    dialogTitleSlot,
    columns,
    FiltersProps,
    ...props
}: Props) => {
    const {
        checkboxSelection,
        pinnedColumns,
        isRowSelectable,
        selectionModel,
        onSelectionModelChange,
        enhanceColumns,
        filtersRef
    } = useFilable();

    return (
        <FiltersPublicApiProvider
            filtersRef={filtersRef}
        >
            <FrontPanelWidgetProvider
                formId={formId}
                getInitialValues={getInitialValues}
                getBootstrapData={getBootstrapData}
                createNewFileRequestHandler={createNewFileRequestHandler}
                mergeFilesRequestHandler={mergeFilesRequestHandler}
                dialogTitleSlot={dialogTitleSlot}
            >
                <MerchantPayoutsTable
                    {...props}
                    filtersRef={filtersRef}
                    checkboxSelection={checkboxSelection}
                    pinnedColumns={pinnedColumns}
                    isRowSelectable={isRowSelectable}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    columns={enhanceColumns(columns)}
                    requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
                    // columns={[
                    //     {
                    //         resizable: false,
                    //         disableColumnMenu: true,
                    //         field: 'actions',
                    //         headerName: 'Options',
                    //         minWidth: 170,
                    //         renderCell: (params: GridCellParams) => (
                    //             <Options {...params} />
                    //         )
                    //     }
                    // ]}
                    FiltersProps={{
                        ...FiltersProps,
                        FrontPanelWidget,
                        useFilterPropsOverride
                    }}
                />
            </FrontPanelWidgetProvider>
        </FiltersPublicApiProvider>
    );
};

export default memo(Filable);
