import {
    memo,
    type PropsWithChildren,
    type ReactNode
} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Resource, type ResourceProps } from "ui/molecules/Resource";

type Props = ResourceProps & {
    readonly title?: ReactNode;

    readonly auxSlot?: ReactNode;
};

const TabLayout = ({
    title,
    auxSlot,
    isLoading,
    isEmpty,
    emptySlot,
    isError,
    errorSlot,
    children
}: PropsWithChildren<Props>) => (
    <Box>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: title ? 2 : 0,
                gap: 3
            }}
        >
            {title && (
                <Typography
                    variant='h6'
                >
                    {title}
                </Typography>
            )}
            {auxSlot}
        </Box>
        <Resource
            isError={isError}
            errorSlot={errorSlot}
            isEmpty={isEmpty}
            emptySlot={emptySlot}
            isLoading={isLoading}
        >
            {children}
        </Resource>
    </Box>
);

export default memo(TabLayout);
