import { memo } from "react";
import Screen from "ui/layouts/Screen";
import {
    TicketingSystemMerchantList,
    TicketingSystemProvider
} from "ui/organizms/Merchants/TicketingSystem";

const TicketingSystemMerchantListScreen = () => (
    <Screen
        title='Merchants | Ticketing System'
    >
        <TicketingSystemProvider>
            <TicketingSystemMerchantList />
        </TicketingSystemProvider>
    </Screen>
);

export default memo(TicketingSystemMerchantListScreen);
