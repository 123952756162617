import { useEffect, useRef } from "react";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantAccountSettingsRepository } from "../repository";
import { useMerchantAccountSettingsContext } from "../MerchantAccountSettingsProvider";

export default function useMerchantAccountSettingsTab() {
    const repository = useMerchantDetailsTabContext<MerchantAccountSettingsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const { isSaving } = useMerchantAccountSettingsContext();

    useEffect(() => {
        repositoryRef.current
            .fetchMerchantSettings();
    }, []);

    return {
        isSaving,
        repository
    };
};
