import { memo, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LabeledSwitch from 'ui/atoms/LabeledSwitch';
import { BasicDialog, useUndoableDialog } from 'ui/molecules/Dialog';
import { useKYCSettingsActionsContext, useKYCSettingsStateSelectorsContext } from 'ui/organizms/Merchants/KYC/KYCSettingsProvider';
import { ApiState } from 'infrastructure/api';
import { isServiceOff } from '../helpers';
import { useKYCActionPanel } from 'ui/organizms/Merchants/KYC/KYCActionPanel';
import { useTypedSelector } from 'hooks';
import { useParams } from 'react-router-dom';
import { Filters } from 'consts/transactions';
import { selectMerchantById } from 'features/merchants/selectors';
import { kycServiceTransactionLimit } from 'consts/kyc';
import { KYCServiceState } from 'consts/kyc';
import { publish } from 'util/custom-event';

export const MERCHANT_KYC_QUICK_SWITCH_CHANGE_EVENT = 'merchant-kyc-quick-switch:change';

const QuickSwitch = () => {
    const params = useParams();
    const merchantId = params[Filters.merchantId];

    const merchant = useTypedSelector(selectMerchantById(String(merchantId)));

    const {
        selectKYCSettingsLoadingState,
        selectKYCServices
    } = useKYCSettingsStateSelectorsContext();

    const {
        updateTrxLimit,
        updateSummedAmountEurLimit
    } = useKYCSettingsActionsContext();

    const { isLoading, onSave } = useKYCActionPanel();

    const isServicesOff = () =>
        Object.values(selectKYCServices())
            .every(isServiceOff);

    const isAllServicesDisabled = isServicesOff();

    const {
        open,
        onProceed,
        onCancel,
        getChecked,
        getLabel,
        onChange,
        getDisabled,
        setCheckedState
    } = useUndoableDialog({
        i18n: ['Turn on all options', 'Turn off all options'],
        handleProcess: () => {
            Object.keys(selectKYCServices()).forEach(kycTypeId => {
                updateTrxLimit({
                    kycTypeId: Number(kycTypeId),
                    kycLimit: kycServiceTransactionLimit.get(KYCServiceState.Off)!
                });

                updateSummedAmountEurLimit({
                    kycTypeId: Number(kycTypeId),
                    summedAmountEurLimit: kycServiceTransactionLimit.get(KYCServiceState.Strict)!
                })
            });

            return new Promise(resolve =>
                setTimeout(resolve, 100)
            )
                .then(onSave)
                .then(() => ({ success: true }));
        },
        getDefaultChecked: () => !isServicesOff(),
        getDisabled: () =>
            isAllServicesDisabled ||
            selectKYCSettingsLoadingState({
                apiState: ApiState.Succeeded,
                notIn: true
            })
    });

    const handleProceed = () => {
        publish(MERCHANT_KYC_QUICK_SWITCH_CHANGE_EVENT, getChecked());

        return onProceed();
    };

    const isSwitchDisabled = getDisabled!();

    useEffect(() => {
        setCheckedState(!isAllServicesDisabled);
    }, [
        isAllServicesDisabled,
        isSwitchDisabled,
        setCheckedState
    ]);

    return (
        <>
            <LabeledSwitch
                checked={getChecked()}
                label={getLabel()}
                disabled={isSwitchDisabled}
                onChange={onChange}
            />
            <BasicDialog
                isOpen={!getChecked() && open}
                onClose={() => { }}
                actionsSlot={(
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <Button
                            variant='outlined'
                            onClick={onCancel}
                            disabled={isLoading}
                        >
                            Close
                        </Button>
                        <LoadingButton
                            color='error'
                            variant='contained'
                            loading={isLoading}
                            onClick={handleProceed}
                        >
                            Disable KYC
                        </LoadingButton>
                    </Stack>
                )}
            >
                <Box>
                    <Box
                        sx={{
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                    >
                        Are you sure you want to disable the KYC connection for {merchant?.fullName}?
                    </Box>
                </Box>
            </BasicDialog>
        </>
    );
};

export default memo(QuickSwitch);
