import { createContext, type PropsWithChildren } from "react";
import type {
    ToolbarFiltersProviderProps,
    ToolbarFiltersContext as ToolbarFiltersContextType
} from "./types";
import useToolbarFilters from "./useToolbarFilters";

export const ToolbarFiltersContext = createContext<ToolbarFiltersContextType>({} as ToolbarFiltersContextType);

const ToolbarFiltersProvider = ({ children, ...props }: PropsWithChildren<ToolbarFiltersProviderProps>) => (
    <ToolbarFiltersContext.Provider
        value={useToolbarFilters(props)}
    >
        {children}
    </ToolbarFiltersContext.Provider>
)

export default ToolbarFiltersProvider;