import { useState } from "react";
import type { SwitchProps } from "@mui/material/Switch";
import { useTypedSelector } from "hooks";
import { selectTransactionCurrencies } from "features/general/selectors";
import { selectMerchantLedgerCurrencies } from "features/merchants/selectors";
import useLedgersActions from "../useLedgerActions";
import { useSimpleRequest } from "hooks/useRequest";
import { createOrUpdateLedger } from "features/merchants/api";
import { useMerchantContext } from "../../../../MerchantProvider";
import { useResourceContext } from "ui/widgets/Resource";

export default function useLedger() {
    const [isLoading, setLoading] = useState(false);

    const { getResource } = useLedgersActions();

    const merchant = useMerchantContext();

    const { onClose } = useResourceContext();

    const request = useSimpleRequest();

    const merchantLedgerCurrencies = useTypedSelector(selectMerchantLedgerCurrencies);
    const transactionCurrencies = useTypedSelector(selectTransactionCurrencies);

    const [currencies, setCurrencies] = useState<Array<string>>([]);

    const getAvailableLedgerCurencies = () => [
        // TODO: remove any after TypeScript upgrade
        ...(new Set(transactionCurrencies) as any)
            .difference(new Set(merchantLedgerCurrencies))
    ];

    const selectLedgerCurrency: SwitchProps['onChange'] = ({ target }, checked) => setCurrencies(state => (
        checked
            ? [...state, target.value]
            : state.filter(item => !Object.is(item, target.value))
    ));

    const createLedgers = async () => {
        setLoading(true);

        await Promise.allSettled(
            currencies.map(currency => request(() => createOrUpdateLedger({
                merchantId: merchant.coreId,
                currency
            })))
        );

        setCurrencies([]);
        setLoading(false);
        onClose();

        getResource();
    };

    return {
        isLoading,
        getIsChecked: (currency: string) => currencies.includes(currency),
        getAvailableLedgerCurencies,
        selectLedgerCurrency,
        createLedgers,
        closeDialog: onClose
    };
};
