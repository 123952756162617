import thunkReducerFactory from "infrastructure/store/reducers/thunkReducerFactory";
import type { WritableDraft } from "immer/dist/internal";
import type { Consumer, ConsumersState } from "./types";
import { compareIds } from "util/support";

export const updateConsumerEntityById = (
    state: WritableDraft<ConsumersState>,
    payload: Partial<Consumer>
) => {

    state.tableSlice!.data = state.tableSlice!.data.map(resourceEntity => {
        if (compareIds(resourceEntity.coreId, payload.coreId!)) {
            return {
                ...resourceEntity,
                ...payload
            };
        }

        return resourceEntity;
    });
};

export const {
    requestLoading: consumersRequestLoading,
    requestFulfilled: consumersRequestFulfilled,
    requestRejected: consumersRequestRejected
} = thunkReducerFactory();
