import { useRef, useEffect, useMemo } from "react";
import { useTicketingSystemActionsContext } from "../TicketingSystemProvider";
import { ApiState } from "infrastructure/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import type { ID, PaginateResourceResponse } from "types";
import { Filters } from "consts/transactions";
import { Filters as NotificationFilters } from "consts/notifications";
import { getMerchantSettings } from "features/merchants/api";
import { getMerchantsNotificationChannelsPivot } from "features/pivots/api";
import { useSimpleRequest } from "hooks/useRequest";
import type { MerchantSettings } from "features/merchants/types";
import type { MerchantsNotificationChannelsPivot } from "features/pivots/types";
import { getPromiseSettledResourceResult } from "util/resource";
import { getInitActionPayload } from "../TicketingSystemProvider/getters/payload";
import { NotificationChannel } from "features/notificationChannel/types";
import { getNotificationChannels } from "features/notificationChannel/api";
import { useTypedSelector } from "hooks";
import { selectConstants } from "features/general/selectors";

export type UseFetchServicesArg = {
    readonly merchantId?: ID;
};

export default function useFetchServices({ merchantId }: UseFetchServicesArg) {
    const constants = useTypedSelector(selectConstants);
    const ticketingSystemActions = useTicketingSystemActionsContext();
    const ticketingSystemActionsRef = useRef(ticketingSystemActions);
    ticketingSystemActionsRef.current = ticketingSystemActions;

    const resourceRequestRef = useRef(useSimpleRequest());

    const isRequestsProcessingRef = useRef(false);

    const notificationChannel = useMemo(() => {
        const { NotificationChannels = [] } = constants;

        if (NotificationChannels.length > 0) {
            return NotificationChannels[0];
        }

        return null;
    }, [constants]);

    useEffect(() => {
        if (isRequestsProcessingRef.current || !merchantId || !notificationChannel) {
            return;
        }

        isRequestsProcessingRef.current = true;

        const {
            initTicketingSystemSettings,
            updateTicketingSystemSettingsRequestState
        } = ticketingSystemActionsRef.current;
        const { current: request } = resourceRequestRef;

        updateTicketingSystemSettingsRequestState(ApiState.Pending);

        const requestSearchQueryParams = RequestQueryMapper.from()
            .containsIn(Filters.merchantId, String(merchantId))
            .toString();

        Promise.allSettled([
            request<PaginateResourceResponse<MerchantSettings>['data']>(() =>
                getMerchantSettings(requestSearchQueryParams)),
            request<PaginateResourceResponse<MerchantsNotificationChannelsPivot>['data']>(() =>
                getMerchantsNotificationChannelsPivot(requestSearchQueryParams)),
            request<PaginateResourceResponse<NotificationChannel>['data']>(() =>
                getNotificationChannels(
                    RequestQueryMapper.from()
                        .containsIn(NotificationFilters.channel, notificationChannel)
                        .toString()
                ))
        ])
            .then(([
                merchantSettingsSettledResult,
                merchantsNotificationChannelsPivotSettledResult,
                notificationChannelsSettledResult
            ]) => {
                const ticketingSystemInitActionPayload = getInitActionPayload();

                const merchantSettings = getPromiseSettledResourceResult(merchantSettingsSettledResult)?.data?.data[0];
                if (merchantSettings) {
                    ticketingSystemInitActionPayload.merchantSettings = merchantSettings;
                }

                const merchantsNotificationChannelsPivot = getPromiseSettledResourceResult(merchantsNotificationChannelsPivotSettledResult)?.data?.data[0];
                if (merchantsNotificationChannelsPivot) {
                    ticketingSystemInitActionPayload.merchantsNotificationChannelsPivot = merchantsNotificationChannelsPivot;
                }

                updateTicketingSystemSettingsRequestState(ApiState.Succeeded);
                initTicketingSystemSettings({
                    ...ticketingSystemInitActionPayload,
                    resources: {
                        merchantSettings,
                        merchantsNotificationChannelsPivot,
                        notificationChannels: getPromiseSettledResourceResult(notificationChannelsSettledResult)?.data?.data[0]
                    }
                });
            })
            .finally(() => {
                isRequestsProcessingRef.current = false;
            });
    }, [merchantId, notificationChannel]);
};
