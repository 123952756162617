import type { Moment } from "moment";
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import type { Option } from "types";
import { FilterVariant } from "consts/filters";
import filterValueStrategy, { ValueStrategy } from "./meta";
import {
    SelectValue,
    RangeValue,
    TextFieldValue,
    DateRangeValue
} from "./adapters";

const FiltersRepository = {
    [FilterVariant.Text]: new ValueStrategy<string>(
        filterValueStrategy[FilterVariant.Text],
        TextFieldValue
    ),
    [FilterVariant.DateRange]: new ValueStrategy<DateRange<Moment>>(
        filterValueStrategy[FilterVariant.DateRange],
        DateRangeValue
    ),
    [FilterVariant.MultiOption]: new ValueStrategy<Array<Option>>(
        filterValueStrategy[FilterVariant.MultiOption],
        SelectValue
    ),
    [FilterVariant.SingleOption]: new ValueStrategy<Option | null>(
        filterValueStrategy[FilterVariant.SingleOption],
        SelectValue
    ),
    [FilterVariant.NumericRange]: new ValueStrategy<Array<string | number>>(
        filterValueStrategy[FilterVariant.NumericRange],
        RangeValue
    ),

    getStrategyByFilterVariant(filterVariant: FilterVariant) {
        return this[filterVariant];
    },

    getDefaultValue(filterVariant: FilterVariant, meta?: DOMStringMap) {
        return this.getStrategyByFilterVariant(filterVariant).getDefaultValue(meta);
    }
};

export default FiltersRepository;
