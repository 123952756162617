import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FC, memo, ReactNode } from 'react';

const CustomizedDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '100%',
        maxWidth: 1440
    },

    // [theme.breakpoints.up('sm')]: {

    // }
}));

export type DialogCloseHandler = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: DialogCloseHandler;
}

const CustomizedDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export type CustomDialogProps = Omit<DialogProps, 'open'>;

export type ModalProps = {
    readonly onClose: DialogCloseHandler;
    readonly isOpen: boolean;
    readonly id?: string;
    readonly titleSlot?: ReactNode;
    readonly actionsSlot?: ReactNode;
    readonly DialogProps?: CustomDialogProps;
    readonly DialogContentProps?: DialogContentProps;
    readonly DialogActionsProps?: DialogActionsProps;
    readonly DialogTitleProps?: DialogTitleProps;
    readonly children?: ReactNode;
};

const Modal: FC<ModalProps> = ({
    titleSlot,
    children,
    actionsSlot,
    onClose,
    isOpen,
    DialogContentProps,
    id = '',
    DialogProps = {},
    DialogTitleProps = {},
    DialogActionsProps = {}
}) => (
    <CustomizedDialog
        {...DialogProps}
        onClose={onClose}
        aria-labelledby={id}
        open={isOpen}
    >
        {Boolean(titleSlot) && (
            <CustomizedDialogTitle
                {...DialogTitleProps}
                id={id}
                onClose={onClose}
            >
                {titleSlot}
            </CustomizedDialogTitle>
        )}
        <DialogContent {...DialogContentProps}>
            {children}
        </DialogContent>
        {Boolean(actionsSlot) && (
            <DialogActions {...DialogActionsProps}>
                {actionsSlot}
            </DialogActions>
        )}
    </CustomizedDialog>
);

Modal.defaultProps = {
    DialogContentProps: {
        dividers: true
    }
};

export default memo(Modal);
