import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { MerchantDomain as MerchantDomainType } from "features/merchants/types";

const MerchantDomain = ({ sourceUrl }: MerchantDomainType) => (
    <Typography
        sx={{
            color: 'primary.main',
            fontWeight: 500,
            textDecoration: 'none',
            width: 'fit-content'
        }}
        component='a'
        href={sourceUrl}
    >
        {sourceUrl}
    </Typography>
);

export default memo(MerchantDomain);
