import { useFormik } from "formik";
import { getInitialValues, useValidationSchema } from "./validators";
import type { FormValues, QueryBuilderFormProps } from "./types";

export default function useQueryBuilderForm(props: QueryBuilderFormProps) {
    const validationSchema = useValidationSchema();

    return useFormik<FormValues>({
        ...props,
        validationSchema,
        isInitialValid: false,
        validateOnMount: true,
        initialValues: getInitialValues()
    });
};
