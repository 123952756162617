import { memo } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import PaymentMethods from "./PaymentMethods";
import useIncludeMethodsContext from "./useIncludeMethodsContext";

const IncludeMethods = () => {
    const { isMethodsAvailable } = useIncludeMethodsContext();

    return (
        <Card
            sx={{
                mt: '24px',
                py: '27px',
                px: '17px',
                boxShadow: theme => theme.shadows[5]
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content auto',
                    columnGap: '24px',
                    py: '10px',
                    px: '17px',
                    mb: '10px',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Box>Include Methods</Box>
                {isMethodsAvailable && (
                    <Box>
                        <Tooltip
                            placement='right'
                            title="Select the method to disable it"
                            arrow
                        >
                            <InfoIcon />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    px: '17px'
                }}
            >
                <PaymentMethods />
            </Box>
        </Card>
    );
};

export default memo(IncludeMethods);