import type { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { useCallback } from 'react';

export default function useTaxable() {
    const getInitialState: () => GridInitialStatePremium = useCallback(() => ({
        pinnedColumns: { right: ['actions'] }
    }), []);

    return {
        getInitialState
    };
};
