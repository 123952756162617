import { useDomainRepository } from "hooks/useDomainState";
import { createOrUpdateMerchantSettings, getMerchantSettings, getMerchantUsers } from "features/merchants/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/merchants";
import reducer from "./reducer";
import stateFactory from "./state";
import Repository from "./repository";
import {
    MerchantDetailActionType,
    type MerchantSettingsActionPayload,
    type MerchantDetailRepositoryState
} from "./types";
import { useSimpleRequest, type UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import type { Merchant } from "features/merchants/types";
import BusinessLogicException from "exceptions/BusinessLogicException";
import useMerchantActions from "features/merchants/useMerchantActions";

export default function useRepository(merchant: Merchant) {
    const request = useSimpleRequest();

    const { putMerchant } = useMerchantActions();

    const repository = useDomainRepository<
        MerchantDetailRepositoryState,
        MerchantDetailActionType
    >({
        reducer,
        initializerArg: stateFactory(),
        factory: (state, dispatch) => new Repository(
            state,
            dispatch,
            () => request(() => getMerchantSettings(
                RequestQueryMapper.from()
                    .contains(Filters.merchantId, String(merchant.coreId))
                    .toString()
            )),
            () => request(() => getMerchantUsers(
                RequestQueryMapper.from()
                    .contains(Filters.merchantId, String(merchant.coreId))
                    .toString()
            )),
            async (merchant: Partial<Merchant>) => {
                const [request] = putMerchant(merchant);
                await request;
            },
            (
                merchantSettings: MerchantSettingsActionPayload,
                requestSettings?: UseSimpleRequestReturnCallbackArg
            ) => request(() => createOrUpdateMerchantSettings(merchantSettings), requestSettings)
        )
    });

    const inject = () => {
        if (repository instanceof Repository) {
            return repository;
        }

        throw new BusinessLogicException('My life completely sucks', {});
    };

    return {
        repository,
        inject
    };
};
