import { Filters } from "consts/filters";
import BusinessLogicException from "exceptions/BusinessLogicException";
import moment, { type Moment } from "moment";
import type { ElasticSearchResourceResponse } from "types";
import { browserUrlSearchParamsAdapterFactory, PeriodAdapter, type PeriodType } from "util/period-adapter";
import type { Serie } from "@nivo/line";
import { Period } from "consts/period";
import type { LinearChartDatum, LinearChartGetters, LinearDiagramProps } from "../types";
import { ChartRepository, Datum } from "./ChartRepository";
import { getSearchParams } from "util/support";
import { UiDateTimeFormatter, numberFormatter } from "util/formaters";

export const getPeriod = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(columns: TPayload['columns'] = []) =>
    columns.find(({ name }) => name.startsWith(Filters.createdAt))?.name as PeriodType | undefined;

export const getFormattedTickPivot = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    date: Moment,
    columns: TPayload['columns'] = []
) => {
    const period = getPeriod(columns);

    const tickPivotFormattingStrategyRepository = new Map<PeriodType, () => string>()
        .set(`${Filters.createdAt}${Period.Year}`, () => moment(date).format(UiDateTimeFormatter.Year))
        .set(`${Filters.createdAt}${Period.Month}`, () => UiDateTimeFormatter.withISO8601(
            moment(date)
                .startOf('month')
                .format(UiDateTimeFormatter.Default)
        ))
        .set(`${Filters.createdAt}${Period.Week}`, () => {
            const momentDate = moment(date);
            const year = momentDate.format(UiDateTimeFormatter.Year);
            const week = momentDate.format(UiDateTimeFormatter.Week);

            return `${year}${week}`;
        })
        .set(`${Filters.createdAt}${Period.Day}`, () => UiDateTimeFormatter.withISO8601(
            moment(date)
                .format(UiDateTimeFormatter.Default)
        ))
        .set(`${Filters.createdAt}${Period.Hour}`, () => moment(date).format(UiDateTimeFormatter.Hour));

    return getValidatedFormattingValue(
        tickPivotFormattingStrategyRepository,
        period
    );
};

export const formatXDateAxis = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(columns: TPayload['columns'] = []) =>
    (value: string | number) => {
        const period = getPeriod(columns);

        if (!period) {
            return value;
        }

        const xDateAxisFormattingStrategyRepository = new Map<PeriodType, () => string>()
            .set(`${Filters.createdAt}${Period.Year}`, () => String(value))
            .set(`${Filters.createdAt}${Period.Month}`, () => moment(value).format(UiDateTimeFormatter.MonthYear))
            .set(`${Filters.createdAt}${Period.Week}`, () => {
                const year = String(value).slice(0, 4);
                const week = String(value).slice(4, 6);

                return UiDateTimeFormatter.withYearWeekUi(year, week);
            })
            .set(`${Filters.createdAt}${Period.Day}`, () => moment(value).format(UiDateTimeFormatter.Ui))
            .set(`${Filters.createdAt}${Period.Hour}`, () => UiDateTimeFormatter.withHour(value));

        return getValidatedFormattingValue(
            xDateAxisFormattingStrategyRepository,
            period
        );
    };

export const getTickCount = (data: Serie[]) => data.reduce((max, { data }) => Math.max(max, data.length), 0);

export const getAxisBottomTickRotation = (serie: Serie[]) => {
    const periodLength = getTickCount(serie);

    if (periodLength > 6) {
        return 30;
    }

    return 0;
};

export const applyTotals = (series: Serie[], pivot = 'Total') => {
    const totals = Array.from<Omit<LinearChartDatum, 'pivot'>>([]);
    const tickMaxCount = getTickCount(series);

    for (let tickIndex = 0; tickIndex < tickMaxCount; tickIndex += 1) {
        let tick, total = 0;

        for (const { data } of series) {
            const { x, y = 0 } = data[tickIndex] ?? {};
            tick = x;
            total += Number(y);
        }

        if (tick) {
            totals.push(new Datum(String(tick), total));
        }
    }

    return series.concat({
        id: pivot,
        data: totals
    });
};

export const getAverage = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    total: number,
    columns?: TPayload['columns']
) => {
    const diff = getDiff({ columns, precise: true });

    if (diff <= 0) {
        return total;
    }

    return total / diff;
};

export const getChartData = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    payload: TPayload | undefined,
    {
        getDatum,
        getPivot = row => String(row.at(-1))
    }: LinearChartGetters<TPayload>
) => {
    if (!payload) {
        return [];
    }

    const chartRepository = ChartRepository.make();

    // Preset chart repository with ticks
    for (const x of Array.from(getTicks(payload.columns))) {
        for (const row of payload.rows) {
            const pivot = getPivot(row);

            chartRepository.update({
                pivot,
                x
            });
        }
    }

    // Populate chart repository with data
    for (const row of payload.rows) {
        chartRepository.update(getDatum(row));
    }

    // Compensation for missing ticks
    for (const x of chartRepository.ticks) {
        for (const [pivot] of chartRepository.entries) {
            chartRepository.getSerieTicks(pivot)
                .forEach(() => {
                    const semiDatum = {
                        pivot,
                        x
                    };

                    if (!chartRepository.has(semiDatum)) {
                        chartRepository.update(semiDatum);
                    }
                });
        }
    }

    let sortSerie: undefined | ((a: string, b: string) => number) = undefined;

    // This is fucking workaround for 53rd week returned
    if ([
        Period.Week,
        Period.Year
    ].some(period => getPeriod(payload.columns)?.endsWith(period))) {
        sortSerie = (ax, bx) => ax.localeCompare(bx);
    }
    // return applyTotals(
    //     chartRepository.getSerie(),
    //     'Total'
    // );
    return chartRepository.getSerie(sortSerie);
};

export const diagramPropsPredicate = <TProps extends Pick<LinearDiagramProps, 'isLoading'>>(
    prevProps: TProps,
    nextProps: TProps
) => Object.is(prevProps.isLoading, nextProps.isLoading);

export const integerFormat = (value: number): string => {
    if (!value) {
        return `${value}`;
    }

    const number = Object.is(Math.floor(value), value) && value;

    if (!number) {
        return '';
    }

    return numberFormatter(number, {
        notation: 'compact'
    });
};

type GetDiffArg<TPayload extends ElasticSearchResourceResponse<string>['data']> = {
    readonly columns?: TPayload['columns'];
    readonly precise?: boolean;
    readonly period?: PeriodType;
};

export function getDiff<TPayload extends ElasticSearchResourceResponse<string>['data']>({
    columns,
    precise,
    period
}: GetDiffArg<TPayload>) {
    const periodFactor = period ?? getPeriod(columns);

    if (!periodFactor) {
        return 0;
    }

    return PeriodAdapter
        .make(browserUrlSearchParamsAdapterFactory(undefined, [
            moment.invalid(),
            moment()
                .endOf('day')
        ]))
        .getDifferenceFromPeriod(
            getSearchParams(),
            periodFactor,
            precise
        );
};

function getTicks<TPayload extends ElasticSearchResourceResponse<string>['data']>(
    columns?: TPayload['columns']
) {
    const ticks = new Set<string>();
    const periodFactor = getPeriod(columns);

    if (!periodFactor) {
        return ticks;
    }

    const [dateFrom] = browserUrlSearchParamsAdapterFactory()(getSearchParams());

    let momentDate = moment(dateFrom);

    ticks.add(getFormattedTickPivot(momentDate, columns));

    for (let count = 0; count < Math.floor(getDiff({ columns, precise: true })); count += 1) {
        momentDate = PeriodAdapter.incrementPeriod(
            momentDate,
            periodFactor
        );

        ticks.add(getFormattedTickPivot(momentDate, columns));
    }

    return ticks;
};

function getValidatedFormattingValue(
    formattingStrategyRepository: Map<PeriodType, () => string>,
    period?: PeriodType
): string {
    if (!period) {
        throw new BusinessLogicException('Period factor is not defined', {});
    }

    if (!formattingStrategyRepository.has(period)) {
        throw new BusinessLogicException(`Unknown period of type ${period}`, { period });
    }

    return formattingStrategyRepository.get(period)!();
}
