import { SecondsInterval } from "util/interval";

export const getStatuses = () => [
    { id: 'SUCCEEDED', name: 'SUCCEEDED' },
    { id: 'FAILED', name: "FAILED" },
    { id: 'PENDING', name: 'PENDING' }
];

export const getIntervals = () => [
    { id: 0, name: 'Every Transaction' },
    { id: SecondsInterval.daily(), name: 'Daily' },
    { id: SecondsInterval.weekly(), name: 'Weekly' },
    { id: SecondsInterval.monthly(), name: "Monthly" }
];

export const getIntervalsByValue = (id: number | null) =>
    getIntervals()
        .find(interval => interval.id === id);
