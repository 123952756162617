import { useTheme } from "@mui/material/styles";
import { ThemePaletteMode } from "consts/ui";

export const NavSectionSettings = {
    ListItem: {
        color: '#CEDAE5'
    }
};

type UseNavSectionArg = {
    readonly active?: boolean;
};

export default function useNavSection({ active = false }: UseNavSectionArg) {
    const theme = useTheme();

    const getListItemTextColor = () => {
        if (active) {
            return {};
        }

        switch (theme.palette.mode) {
            case ThemePaletteMode.Dark:
                return NavSectionSettings.ListItem;
            default:
                return {};
        }
    };

    return {
        getListItemTextColor
    };
};
