import { memo } from "react";
import { MultiOption, useAdditionalOptions } from "../AdditionalOptions";
import { KYCServiceType, KYCSettingsServicePropertyName } from "consts/kyc";

const AdditionalOptions = () => {
    const { onChange, value } = useAdditionalOptions({
        kycTypeId: KYCServiceType.IDVerification
    });

    return (
        <MultiOption
            id='IdVerification-additional-options'
            options={[
                {
                    id: KYCSettingsServicePropertyName.DocumentBacksideProofRequired,
                    name: 'Back side ID'
                },
                { 
                    id: KYCSettingsServicePropertyName.DocumentShowOcrForm,
                    name: 'Show OCR form'
                }
            ]}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(AdditionalOptions);
