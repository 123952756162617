import type { MerchantSettings } from "features/merchants/types";
import {
    FormField,
    type MerchantAccountAdvancedSettings,
    type MerchantAccountAdvancedSettingsForm
} from "./types";
import useValidationSchema from "./useValidationSchema";
import { useForm as useFormHook } from "hooks";
import { ActiveStatus, KycType } from "consts/merchants";
import { BooleanSwitchValue } from "consts/general";

export const getInitialValues = (initialValues?: Partial<MerchantSettings>) => ({
    [FormField.Descriptor]: '',
    [FormField.AccountStatus]: ActiveStatus.Active,
    [FormField.WhitelistCheck]: BooleanSwitchValue.Off,
    [FormField.ClientCallbackUrl]: '',
    [FormField.ClientNotificationUrl]: '',
    [FormField.AccountKYCOption]: KycType.None,
    [FormField.DomainCheck]: BooleanSwitchValue.Off,
    [FormField.DailyAmountLimit]: 0,
    ...initialValues,
});

export const useForm = (accountAdvancedSettingsProps: MerchantAccountAdvancedSettingsForm) =>
    useFormHook<MerchantAccountAdvancedSettings>({
        enableReinitialize: true,
        ...accountAdvancedSettingsProps,
        validateOnMount: true,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(accountAdvancedSettingsProps.initialValues)
    });
