import Typography from '@mui/material/Typography';
import type { CustomFile } from "lib/upload";
import { BulkKYCStepperStep, type BulkKYCStepperState } from './types';

type FilableValue =
    | BulkKYCStepperState[BulkKYCStepperStep.UploadCSVFile]
    | BulkKYCStepperState[BulkKYCStepperStep.UploadVerificationArchive];

type UseFileUploaderArg =
    & Pick<CustomFile, 'preview'>
    & {
        readonly value: FilableValue;
        readonly onChange: (file: FilableValue) => void;
    };

export default function useFileUploader({
    value,
    onChange,
    preview
}: UseFileUploaderArg) {
    const handleDrop = (files: CustomFile[]) => {
        const file = files.at(0);
        if (file) {
            file.preview = preview;
            onChange(file);
        }
    };

    const renderHelperText = () => {
        if (value instanceof File) {
            return (
                <Typography
                    gutterBottom
                    variant='body2'
                    textAlign='center'
                >
                    {value.name}
                </Typography>
            );
        }
    };

    return {
        handleDrop,
        renderHelperText
    };
};
