import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTypedSelector } from "hooks";
import IncrementExchangeRate, {
    type IncrementExchangeRateFormBootstrapData,
    useIncrementExchangeRateForm
} from "ui/forms/IncrementExchangeRate";
import useFiltersModel from "ui/widgets/Filters/hooks/useFiltersModel";
import { Filters } from "consts/merchantPayouts";
import { getInitialValues } from "ui/forms/IncrementExchangeRate/validators";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import { selectPaymentMethodsTags } from "features/paymentMethods/selectors";
import { useCreateTaxableEntityContext } from "../Taxable";
import { BasicDialog } from "ui/molecules/Dialog";
import { UiDateTimeFormatter, dateRangeFormatter } from "util/formaters";
import type { Moment } from "moment";

const AddIncrementExchangeRateAction = () => {
    const { open, onClose } = useCreateTaxableEntityContext();

    const formId = "incrementExchangeRateForm";

    const paymentMethodsTags = useTypedSelector(selectPaymentMethodsTags);

    const { postMerchantPayoutIncrementExchangeRates } = useMerchantPayoutsActions();

    const { getFilterOptions, isLoading: isBootstrapDataLoading } = useFiltersModel();

    const incrementExchangeRateForm = useIncrementExchangeRateForm({
        initialValues: getInitialValues(),
        isInitialValid: false,
        onResolve: () => {
            incrementExchangeRateForm.resetForm();
            onClose();
        },
        onSaveOrCreate: values => {
            const [dateFrom, dateTo] = dateRangeFormatter(
                values["dateFrom|dateTo"] as [Moment, Moment], {
                pattern: UiDateTimeFormatter.Write
            });

            return postMerchantPayoutIncrementExchangeRates({
                MOR: String(values.MOR?.id),
                PSP: String(values.PSP?.id),
                payoutCurrency: String(values.payoutCurrency?.id),
                transactionCurrency: String(values.transactionCurrency?.id),
                tag: String(values.tag?.id),
                merchantId: values.merchantId?.id,
                currencyExchangeRate: values.currencyExchangeRate,
                dateFrom,
                dateTo,
            });
        },
    });

    const getBootstrapData = () =>
        [
            Filters.payoutCurrency,
            Filters.transactionCurrency,
            Filters.PSP,
            Filters.MOR
        ].reduce(
            (bootstrapData, bootstrapDataToken) => ({
                ...bootstrapData,
                [bootstrapDataToken]: getFilterOptions(bootstrapDataToken).options ?? [],
            }),
            {
                tag: paymentMethodsTags,
            } as IncrementExchangeRateFormBootstrapData
        );

    const handleClose = () => {
        onClose();
        incrementExchangeRateForm.resetForm();
    };

    return (
        <BasicDialog
            isOpen={open}
            isCanClose={!incrementExchangeRateForm.isSubmitting}
            onClose={handleClose}
            titleSlot='Create new exchange rate'
            actionsSlot={(
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    form={formId}
                    loading={incrementExchangeRateForm.isSubmitting}
                    disabled={!incrementExchangeRateForm.isValid}
                >
                    Create
                </LoadingButton>
            )}
        >
            <IncrementExchangeRate
                {...incrementExchangeRateForm}
                id={formId} bootstrapData={getBootstrapData()}
                isBootstrapDataLoading={isBootstrapDataLoading}
            />
        </BasicDialog>
    );
};

export default memo(AddIncrementExchangeRateAction);
