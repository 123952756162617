import { memo } from "react";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import type { TriggerableDialogProps } from "ui/molecules/Dialog";

const AddPayoutAccountTrigger: TriggerableDialogProps['TriggerComponent'] = ({ isOpen, onClick, children }) => (
    <Button
        onClick={onClick}
        disabled={isOpen}
        fullWidth
        startIcon={(
            <AddCircleOutlineIcon />
        )}
        sx={{
            borderRadius: 0,
            justifyContent: 'flex-start',
            pl: 4
        }}
    >
        {children}
    </Button>
);

export default memo(AddPayoutAccountTrigger);
