import React from 'react';

interface ConditionTextAreaProps {
    updatedConditions: {
        dropdown1: string;
        dropdown2: string;
        inputValue: string;
        condition: string;
    }[];
    updatedAggregations: {
        dropdown1: string;
        dropdown2: string;
        aggregation: string;
    }[];
    onChange: (value: string) => void;
}

const ConditionTextArea: React.FC<ConditionTextAreaProps> = ({ updatedConditions, updatedAggregations, onChange }) => {
    const concatenatedConditions = updatedConditions.map(row => row.condition).join('&');
    const concatenatedAggregations = updatedAggregations.map(row => row.aggregation).join('&');

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    const concatenatedData = `${concatenatedConditions}&${concatenatedAggregations}`;

    return (
        <textarea
            value={concatenatedData}
            onChange={handleChange}
            rows={5}
            cols={50}
            style={{ marginTop: '10px' }}
        />
    );
};

export default ConditionTextArea;
