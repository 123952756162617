import type { GridEventListener } from "@mui/x-data-grid";
import useMerchantUserActions from "features/merchants/useMerchantUserActions";
import { useResourceContext } from "ui/widgets/Resource";

export default function useMerchantUsers() {
    const {
        getMerchantUsers: getResource,
        downloadCsv
    } = useMerchantUserActions();

    const { onOpen } = useResourceContext();

    const onRowClick: GridEventListener<'rowClick'> = ({ row }) => onOpen(row);

    return {
        downloadCsv,
        getResource,
        onRowClick
    };
};
