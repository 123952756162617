import { useState } from 'react';
import type { ID, PaginateResourceResponse, Scalar, Option } from "types";
import type { FiltersChangeHandler } from "ui/widgets/Filters";
import { fromPaginateResourceResponse } from 'util/api';
import { compareIds } from 'util/support';

type UsePivotArg<TPivotResource extends Record<TRelatedFilters, Scalar<string>>, TRelatedFilters extends string> = {
    readonly pivotSlice: PaginateResourceResponse<TPivotResource> | null;
    readonly relations: Record<TRelatedFilters, Array<Option>>
};

export default function usePivot<
    TPivotResource extends Record<TRelatedFilters, Scalar<string>>,
    TRelatedFilters extends string,
>({ pivotSlice, relations }: UsePivotArg<TPivotResource, TRelatedFilters>) {
    const getRelationNames = () => Object.keys(relations) as Array<TRelatedFilters>;

    const getInitialState = () =>
        getRelationNames().reduce((acc, relationalFilterName: TRelatedFilters) => ({
            ...acc,
            [relationalFilterName]: []
        }), {} as Record<TRelatedFilters, Array<ID>>);

    const [filters, setFilters] = useState<Record<TRelatedFilters, Array<ID>>>(getInitialState);

    const onFilterChange: FiltersChangeHandler = ({ type, payload }) => {
        if (getRelationNames().includes(type as TRelatedFilters)) {
            setFilters(state => ({
                ...state,
                [type]: (payload as Array<Option>).map(({ id }) => id)
            }));
        }
    };

    const onFiltersReset = () => {
        setFilters(getInitialState());
    };

    const selectFilterValue = (
        filterName: TRelatedFilters,
        relationalFilterName: TRelatedFilters,
        collection: Array<Option>
    ) => {
        if ((filters[relationalFilterName].length <= 0) || !pivotSlice) {
            return collection;
        }

        const value = filters[relationalFilterName];

        const pivotValues: Array<TPivotResource> = fromPaginateResourceResponse(pivotSlice)
            .filter((pivotEntity: TPivotResource) => {
                for (const id of value) {
                    if (compareIds(id, pivotEntity[relationalFilterName])) {
                        return true;
                    }
                }

                return false;
            });

        return collection.filter(({ id }) => {
            for (const pivotValue of pivotValues) {
                if (compareIds(id, pivotValue[filterName])) {
                    return true;
                }
            }

            return false;
        });
    };

    return {
        ...getRelationNames()
            .reduce((acc, relationalFilterName: TRelatedFilters) => ({
                ...acc,
                [relationalFilterName]: () => selectFilterValue(
                    relationalFilterName,
                    getRelationNames()
                        .find(name => !compareIds(name, relationalFilterName)) as TRelatedFilters,
                    relations[relationalFilterName]
                )
            }), {} as Record<TRelatedFilters, () => Array<Option>>),
        onFilterChange,
        onFiltersReset
    };
};
