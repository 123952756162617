import { useRef, useEffect } from 'react';
import { selectIsMerchantPayoutEntityLoading, selectRowsById, selectSelectionModel } from "features/merchantPayouts/selectors";
import { useTypedSelector } from "hooks";
import { convertToFormData } from "util/transformers";
import { MergeFilesActionProps } from "./Props";
import useActions from 'features/merchantPayouts/useActions';
import { useModal } from 'ui/atoms/Modal';
import _ from 'lodash'

export default function useMergeFiles({ mergeFilesRequestHandler }: MergeFilesActionProps) {
    const isLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);
    const selectionModel = useTypedSelector(selectSelectionModel);
    const selectedRows = useTypedSelector(selectRowsById(selectionModel));

    const { open, onOpen, onClose } = useModal();
    const { onChangeMultiFileMerge, onSelectionModelChange } = useActions();

    const handleCleanupOnComponentUnmountRef = useRef(() => {
        onSelectionModelChange([]);
        onChangeMultiFileMerge(false);
    });

    useEffect(() => handleCleanupOnComponentUnmountRef.current, []);

    const onMerge = () => {
        const [request] = mergeFilesRequestHandler(
            convertToFormData({
                childrenIds: selectionModel.toString(),
            })
        );

        return request
            .then(handleCleanupOnComponentUnmountRef.current)
            .finally(onClose);
    };
    /**
     * Authored by @renaldaZhuka
     * TODO: refactor ASAP
     */
    const isRowsMergable = () => {

        const groupedMerchantName = _.groupBy(selectedRows, "merchantName");
        const groupedMerchantCurrency = _.groupBy(selectedRows, "currency");
        const groupedMerchantDomain = _.groupBy(selectedRows,'MOR');
        return Boolean(
            Object.keys(groupedMerchantName).length < 2 &&
            Object.keys(groupedMerchantCurrency).length < 2 &&
            Object.keys(groupedMerchantDomain).length > 1)
    };

    return {
        isLoading,
        open,
        onOpen,
        onClose,
        isRowsMergable,
        onMerge
    };
};
