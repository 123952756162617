import type { ChangeEvent } from "react";
import type { TextFieldProps } from "./types";
import { TextFieldValueInterceptorModifier } from "./helpers";

export default function useTextField({ inputProps, onChange }: TextFieldProps) {
    return {
        onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            event.target.value = TextFieldValueInterceptorModifier
                .fromInputProps(inputProps)
                .modifyValue(event.target.value);

            onChange?.(event);
        }
    };
};
