import type { ReactNode } from "react";
import type { DeleteUserProps } from "./types";
import useAuth from "features/auth/useAuth";
import { compareIds } from "util/support";
import useActions from "features/users/useActions";
import type { ID } from "types";

export default function useDeleteUser({ row }: DeleteUserProps) {
    const { deleteUser } = useActions();

    const { user } = useAuth();

    const renderLabel = ({ prefix }: Readonly<{
        prefix?: ReactNode;
    }>) => `${prefix ? prefix : ''} ${[
        row.firstName,
        row.email
    ].join('-')}`;

    const getIsRowOwner = () => compareIds(row.coreId, user.coreId);

    const handleDeleteUser = (coreId: ID) => {
        const [request] = deleteUser(coreId);

        return request;
    };

    return {
        renderLabel,
        getIsRowOwner,
        deleteUser: handleDeleteUser
    };
};
