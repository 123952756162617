import { memo } from "react";
import type { MerchantDomain } from "features/merchants/types";
import EditMerchantDomain from "./EditMerchantDomain";
import DeleteMerchantDomain from "./DeleteMerchantDomain";
import BusinessEntityToolbar from "../../shared/BusinessEntityToolbar";
import TransactionExistanceIndicator from "./TransactionExistanceIndicator";

const MerchantDomainToolbar = (merchantDomain: MerchantDomain) => (
    <BusinessEntityToolbar>
        <TransactionExistanceIndicator
            {...merchantDomain}
        />
        <EditMerchantDomain
            {...merchantDomain}
        />
        <DeleteMerchantDomain
            {...merchantDomain}
        />
    </BusinessEntityToolbar>
);

export default memo(MerchantDomainToolbar);
