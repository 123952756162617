import type { MerchantSettings } from "features/merchants/types";
import type { NotificationChannel } from "features/notificationChannel/types";
import type { MerchantsNotificationChannelsPivot } from "features/pivots/types";
import type { DomainAction, Option } from "types";
import { ApiState } from "infrastructure/api";

export const enum TicketingSystemService {
    SupportPageOnInit = 'SupportPageOnInit',
    SupportPageOnRedirect = 'SupportPageOnRedirect',
    EmailConfirmation = 'EmailConfirmation'
};

export const enum TicketingSystemServicesAdditionalOptions {
    Statuses = 'statuses',
    Interval = 'interval'
};

export type TicketingSystemServiceState<TAdditionalOptions = null> = {
    readonly enabled: boolean;
    readonly additionalOptions: TAdditionalOptions;
}

export type TicketingSystemServicesState = {
    readonly [TicketingSystemService.SupportPageOnInit]: TicketingSystemServiceState;
    readonly [TicketingSystemService.SupportPageOnRedirect]: TicketingSystemServiceState<{
        readonly id: MerchantSettings['showTicketingSystemOnRedirectOnlyOnStatuses'];
        readonly name: string;
    }>;
    readonly [TicketingSystemService.EmailConfirmation]: TicketingSystemServiceState<Option>;
};

export type TicketingSystemResources = {
    merchantSettings: Pick<
        MerchantSettings,
        | 'showTicketingSystemOnInit'
        | 'showTicketingSystemOnRedirect'
        | 'showTicketingSystemOnRedirectOnlyOnStatuses'
    >;
    merchantsNotificationChannelsPivot: Pick<
        MerchantsNotificationChannelsPivot,
        | 'isEnabled'
        | 'secondsInterval'
    >;
};

export type TicketingSystemState = {
    ticketingSystemSettingsApiState: ApiState;
    isTicketingSystemSettingsChanged: boolean;
    initialTicketingSystemSettingsSnapshot: TicketingSystemServicesState;
    ticketingSystemSettings: TicketingSystemServicesState;
    resources?: Partial<{
        readonly merchantSettings: MerchantSettings;
        readonly merchantsNotificationChannelsPivot: MerchantsNotificationChannelsPivot;
        readonly notificationChannels: NotificationChannel;
    }>;
};

export const enum TicketingSystemActionTypes {
    TicketingSystemSettingsInit = '[INIT TICKETING SYSTEM SETTINGS]',
    TicketingSystemSettingsApiState = '[TICKETING SYSTEM API STATE]',
    UpdateEnabledState = '[CHANGE ENABLED STATE]',
    UpdateAdditionalOptions = '[UPDATE ADDITIONAL OPTIONS]',
    SyncTicketingSystemSettingsOnSave = '[SYNC TICKETING SYSTEM SETTINGS ON SAVE]',
};

export type TicketingSystemInitActionPayload =
    & TicketingSystemResources
    & Pick<TicketingSystemState, 'resources'>;

export type TicketingSystemUpdateEnabledStateActionPayload = {
    readonly service: TicketingSystemService;
    readonly isEnabled: boolean;
};

export type TicketingSystemUpdateAdditionalOptionsActionPayload = {
    readonly service: TicketingSystemService;
    readonly option: Option;
};

export type TicketingSystemSyncTicketingSystemSettingsOnSavePayload = TicketingSystemState['resources'];

export type TicketingSystemAction =
    | DomainAction<
        TicketingSystemActionTypes.TicketingSystemSettingsInit,
        TicketingSystemInitActionPayload
    >
    | DomainAction<
        TicketingSystemActionTypes.TicketingSystemSettingsApiState,
        ApiState
    >
    | DomainAction<
        TicketingSystemActionTypes.UpdateEnabledState,
        TicketingSystemUpdateEnabledStateActionPayload
    >
    | DomainAction<
        TicketingSystemActionTypes.UpdateAdditionalOptions,
        TicketingSystemUpdateAdditionalOptionsActionPayload
    >
    | DomainAction<
        TicketingSystemActionTypes.SyncTicketingSystemSettingsOnSave,
        TicketingSystemSyncTicketingSystemSettingsOnSavePayload
    >;
