import type { GridValueGetterParams } from "@mui/x-data-grid";

type FieldNumericPrecisionArg = {
    readonly precision: number;
};

const fieldNumericPrecision = ({ precision }: FieldNumericPrecisionArg) =>
    ({ value }: GridValueGetterParams) => (
        Number(value).toFixed(precision)
    );

export default fieldNumericPrecision;
