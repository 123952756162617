import type { TabProps } from "ui/atoms/Tabs";

export type StatefulTabsProps = TabProps & {
    readonly searchParamName?: string;
};

export const enum StatefulTabs {
    InitialValue = '1',
    SearchParamName = 'activeTab'
};
