import { memo, type SyntheticEvent } from 'react';
import Badge from '@mui/material/Badge';
import type { PopoverSelectFilterProps } from './types';
import type { Option } from 'types';
import useSelectFilter, { type SelectValue } from './useSelectFilter';
import { compareIds } from 'util/support';
import { PopoverableAction } from 'ui/molecules/Action';
import SelectMenuItem from './shared/SelectMenuItem';
import SearchableOptions from './shared/SearchableOptions';
import ToggleSelectAll from './shared/ToggleSelectAll';

const PopoverSelectFilter = (props: PopoverSelectFilterProps) => {
    const { label, id, options, multiple, PopoverTriggerProps } = props;

    const { getValue, handleChange } = useSelectFilter(props);

    const selectValue = [getValue()]
        .flat()
        .filter(Boolean);

    const onChange = (option: Option) =>
        (event: SyntheticEvent) => {
            const checked = selectValue.find((value: Option) => compareIds(value.id, option.id));
            let payload: SelectValue;

            if (multiple) {
                payload = checked
                    ? selectValue.filter(value => !compareIds(value.id, option.id))
                    : [...selectValue, option];
            } else {
                payload = checked
                    ? null
                    : option;
            }

            handleChange(
                event,
                payload,
                checked
                    ? 'selectOption'
                    : 'removeOption'
            );
        };

    return (
        <Badge
            color='secondary'
            variant='dot'
            invisible={!selectValue.length}
        >
            <PopoverableAction
                {...PopoverTriggerProps}
                id={id}
                label={label}
                disabled={!options.length}
            >
                <SearchableOptions
                    options={options}
                >
                    {options => (
                        <>
                            <ToggleSelectAll
                                onChange={props.onChange}
                                id={id}
                                options={options}
                            />
                            {options.map(option => (
                                <SelectMenuItem
                                    key={option.id}
                                    checked={selectValue
                                        .some((value: Option) => compareIds(option.id, value.id))}
                                    value={option}
                                    onClick={onChange(option)}
                                />
                            ))}
                        </>
                    )}
                </SearchableOptions>
            </PopoverableAction>
        </Badge>
        /*<>
            <Button
                variant='outlined'
                size='small'
                {...ButtonProps}
                aria-describedby={id}
                onClick={handleClick}
            >
                {label}
            </Button>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.id}
                        value={option.name}
                        onClick={onChange(option)}
                    >
                        <Checkbox
                            size='small'
                            checked={selectValue
                                .some((value: Option) => compareIds(option.id, value.id))}
                        />
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'subtitle2'
                            }}
                            primary={option.name}
                        />
                    </MenuItem>
                ))}
            </Popover>
        </>*/
    );
};

export default memo(PopoverSelectFilter);
