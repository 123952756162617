import { styled } from '@mui/material/styles';
import MuiInputLabel, { inputLabelClasses } from '@mui/material/InputLabel';

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
    [`&.${inputLabelClasses.root}`]: {
        color: theme.palette.text.primary,
        transform: 'none',
        position: 'static',
        fontSize: 13,
        lineHeight: '24px',
        fontWeight: 500,
        width: 'min-content'
    }
}));

export default InputLabel;
