import { AbstractFileEntity } from 'features/merchantPayouts/types';
import { createContext, memo, useContext, ProviderProps } from 'react';

type FileContextType = AbstractFileEntity | undefined;

export const FileContext = createContext<FileContextType>(undefined);

const FileProvider = (props: ProviderProps<FileContextType>) => (
    <FileContext.Provider {...props} />
);

export default memo(FileProvider);

export const useFileContext = () => useContext(FileContext);
