import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { CustomAvatar } from "lib/custom-avatar";

const PaymentMethodName = ({
    paymentMethodPSP
}: MerchantsMethodsPivot) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, min-content)',
            alignItems: 'center',
            gap: 2
        }}
    >
        <CustomAvatar
            color='default'
            name={paymentMethodPSP}
            alt={paymentMethodPSP}
        />
        <Typography
            variant='button'
        >
            {paymentMethodPSP}
        </Typography>
    </Box>
);

export default memo(PaymentMethodName);
