import { createSlice } from "@reduxjs/toolkit";
import initialState, { sliceToken } from "./state";
import { getManualBulkKYCsImportHistoryThunk } from "./thunks";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import type { KYCState } from "./types";
import {
    kycRequestLoading,
    kycRequestFulfilled,
    kycRequestRejected
} from "./reducers";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: sliceMixin<KYCState>(initialState),
    extraReducers: builder => {
        builder
            .addCase(getManualBulkKYCsImportHistoryThunk.pending, kycRequestLoading)
            .addCase(getManualBulkKYCsImportHistoryThunk.fulfilled, kycRequestFulfilled)
            .addCase(getManualBulkKYCsImportHistoryThunk.rejected, kycRequestRejected)
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState
    }
} = slice;

export default slice.reducer;
