import { BooleanSwitchValue } from "consts/general";
import { TicketingSystemNotification } from "consts/ticketing";
import type { TicketingSystemInitActionPayload, TicketingSystemServiceState } from "../types";

export const getInitActionPayload = (): TicketingSystemInitActionPayload => ({
    merchantSettings: {
        showTicketingSystemOnInit: TicketingSystemNotification.None,
        showTicketingSystemOnRedirect: TicketingSystemNotification.None,
        showTicketingSystemOnRedirectOnlyOnStatuses: null
    },
    merchantsNotificationChannelsPivot: {
        isEnabled: BooleanSwitchValue.Off,
        secondsInterval: null
    }
});

export const getTicketingSystemNotificationFromServiceState = ({ enabled }: Pick<TicketingSystemServiceState, 'enabled'>) =>
    enabled
        ? TicketingSystemNotification.Ticketing
        : TicketingSystemNotification.None
