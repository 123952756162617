import { useEffect, useState } from "react";
import { ProfileFinance } from "ui/forms/Merchant";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantProfileRepository } from "../repository";
import { getAccountType } from "features/merchants/helpers";
import type { MerchantProfileFinance } from "ui/forms/Merchant/ProfileFinance";
import { useTypedSelector } from "hooks";
import { selectIsBootstrapDataLoading } from "features/general/selectors";
import BusinessLogicException from "exceptions/BusinessLogicException";
import type { MerchantPayoutAbstractAccount } from "features/merchants/types";

export default function useMerchantProfileFinance(): ProfileFinance.MerchantProfileFinanceForm<
    MerchantProfileFinance,
    {
        readonly toggleEditMode: () => void;
        readonly onConfirmDeletePayoutAccount: (account?: MerchantPayoutAbstractAccount | null) => Promise<void>;
    }
> {
    const [isEditMode, setEditMode] = useState(false);
    const toggleEditMode = () => setEditMode(state => !state);

    const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);

    const repository = useMerchantDetailsTabContext<MerchantProfileRepository>();

    const validationSchema = ([
        ProfileFinance.useAccountValidationSchema(),
        ProfileFinance.useCryptoAccountValidationSchema()
    ][getAccountType(repository.account ?? {})]);

    const isLoading = repository.isLoading;
    const account = repository.account;

    const onSubmit = async (_: ProfileFinance.MerchantProfileFinance) => { };

    const profileFinanceForm = ProfileFinance.useForm<MerchantProfileFinance>({
        onSubmit,
        initialValues: account ?? {},
        isInitialValid: true,
        validateOnMount: false,
        validationSchema
    });

    const onConfirmDeletePayoutAccount = () => {
        if (!account) {
            throw new BusinessLogicException('Account is not selected', {});
        }

        return repository.deletePayoutAccount(account);
    };

    useEffect(() => {
        if (!account) {
            return;
        }

        setEditMode(false);
    }, [
        isLoading,
        account
    ]);

    return {
        ...profileFinanceForm,
        onSaveOrCreate: onSubmit,
        bootstrapData: {},
        isBootstrapDataLoading,
        isEditMode,
        toggleEditMode,
        onConfirmDeletePayoutAccount
    };
}