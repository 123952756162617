import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { memo, HTMLAttributes } from "react";
import { renderElement } from "lib/country-flag";
import type { Country } from "features/general/types";
import type { AutocompleteRenderOptionState } from "@mui/material/Autocomplete";

type Props = {
    readonly props: HTMLAttributes<HTMLLIElement>;
    readonly option: Country;
    readonly state: AutocompleteRenderOptionState;
};

const CountrySelectorOption = ({ props, option: { iso2, iso3, shortName }, state }: Props) => (
    <Stack
        {...props}
        component='li'
        divider={<Divider orientation='vertical' flexItem />}
        direction='row'
        spacing={1}
    >
        {renderElement(iso2, { ext: 'svg', src: '' })}
        <Box>{iso3}</Box>
        <Box>{shortName}</Box>
    </Stack>
);

export default memo(CountrySelectorOption);
