import { DataGridPremium } from "@mui/x-data-grid-premium";
import { memo } from "react";
import type { Settings, UseImportResultArg } from "./Props";
import useImportResult from "./useImportResult";

type Props = UseImportResultArg & Settings;

const ImportResult = (props: Props) => {
    const { getColumns, getRows, getRowId } = useImportResult(props);

    return (
        <DataGridPremium
            disableSelectionOnClick
            columns={getColumns()}
            rows={getRows()}
            getRowId={getRowId}
            pinnedColumns={{
                left: ['id']
            }}
        />
    );
};

export default memo(ImportResult);
