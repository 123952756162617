import { useImperativeHandle } from "react";
import type { UserFormRefs, UserFormikForm } from "./types";

export default function useFormRef(
    { formRef }: UserFormRefs,
    form: UserFormikForm
) {
    useImperativeHandle(formRef, () => ({
        ...form
    }), [form]);
};
