import { useMemo, useReducer, useCallback } from 'react';
import {
    TicketingSystemActionTypes,
    TicketingSystemInitActionPayload,
    TicketingSystemUpdateEnabledStateActionPayload,
    TicketingSystemUpdateAdditionalOptionsActionPayload,
    TicketingSystemSyncTicketingSystemSettingsOnSavePayload
} from './types';
import reducer from './reducer';
import stateFactory from './stateFactory';
import { ApiState } from 'infrastructure/api';

export default function useTicketingSystem() {
    const initialState = useMemo(stateFactory, []);

    const [ticketingSystemState, dispatch] = useReducer(reducer, initialState);

    const initTicketingSystemSettings = useCallback((payload: TicketingSystemInitActionPayload) =>
        dispatch({
            type: TicketingSystemActionTypes.TicketingSystemSettingsInit,
            payload
        })
    , [dispatch]);

    const updateTicketingSystemSettingsRequestState = useCallback((payload: ApiState) =>
        dispatch({
            type: TicketingSystemActionTypes.TicketingSystemSettingsApiState,
            payload
        })
    , [dispatch]);

    const updateTicketingSystemSettingsEnabledState = useCallback((payload: TicketingSystemUpdateEnabledStateActionPayload) => {
        dispatch({
            type: TicketingSystemActionTypes.UpdateEnabledState,
            payload
        });
    }, [dispatch]);

   const updateTicketingSystemSettingsAdditionalOptions = useCallback((payload: TicketingSystemUpdateAdditionalOptionsActionPayload) =>
        dispatch({
            type: TicketingSystemActionTypes.UpdateAdditionalOptions,
            payload
        })
    , [dispatch]);

    const syncTicketingSystemSettingsOnSave = useCallback((payload: TicketingSystemSyncTicketingSystemSettingsOnSavePayload) =>
        dispatch({
            type: TicketingSystemActionTypes.SyncTicketingSystemSettingsOnSave,
            payload
        })
    , [dispatch]);

    return {
        ticketingSystemState,
        initTicketingSystemSettings,
        updateTicketingSystemSettingsRequestState,
        updateTicketingSystemSettingsEnabledState,
        updateTicketingSystemSettingsAdditionalOptions,
        syncTicketingSystemSettingsOnSave
    };
};
