import type {
    MerchantPayoutAbstractAccount,
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount,
    MerchantSettings
} from "features/merchants/types";
import type { IDomainRepository } from "hooks/useDomainState";
import { ApiState } from "infrastructure/api";
import type { DomainAction } from "types";
import { MerchantProfileTab } from "../MerchantProfileProvider";
import type { MerchantRepository, MerchantSettingsRepository } from "../../repository";
import type { MerchantProfileFinance } from "ui/forms/Merchant/ProfileFinance";
import { AccountType } from "consts/merchants";

export const enum MerchantProfileActionType {
    FetchPayoutAccounts = 'MerchantProfileActionType:FetchPayoutAccounts',
    CreatePayoutAccount = 'MerchantProfileActionType:CreatePayoutAccount',
    UpdatePayoutAccount = 'MerchantProfileActionType:UpdatePayoutAccount',
    DeletePayoutAccount = 'MerchantProfileActionType:DeletePayoutAccount',
    SelectPayoutAccount = 'MerchantProfileActionType:SelectPayoutAccount',
    Reset = 'MerchantProfileActionType:Reset'
};

export type MerchantProfileFinanceWallet = {
    readonly [AccountType.Bank]: Array<MerchantPayoutAccount>;
    readonly [AccountType.Crypto]: Array<MerchantPayoutCryptoAccount>;
};

export type MerchantProfileFinanceMeta = {
    account: MerchantPayoutAbstractAccount | null;
    apiState: ApiState;
};

export type MerchantProfileRepositoryState = {
    readonly [MerchantProfileTab.Finance]: MerchantProfileFinanceWallet & MerchantProfileFinanceMeta;
};

export type MerchantSettingsActionPayload = Pick<
    MerchantSettings,
    | 'withdrawal'
    | 'payoutCycle'
    | 'rollingReserveCap'
    | 'coreId'
>;

export type MerchantProfileRepositoryAction =
    | DomainAction<
        MerchantProfileActionType.FetchPayoutAccounts,
        MerchantProfileFinanceWallet & MerchantProfileFinanceMeta
    >
    | DomainAction<
        MerchantProfileActionType.CreatePayoutAccount,
        MerchantPayoutAbstractAccount
    >
    | DomainAction<
        MerchantProfileActionType.UpdatePayoutAccount,
        MerchantPayoutAbstractAccount
    >
    | DomainAction<
        MerchantProfileActionType.DeletePayoutAccount,
        MerchantPayoutAbstractAccount
    >
    | DomainAction<
        MerchantProfileActionType.SelectPayoutAccount,
        MerchantPayoutAbstractAccount
    >
    | DomainAction<
        MerchantProfileActionType.Reset,
        undefined
    >;

export interface MerchantProfileRepository extends MerchantRepository, MerchantSettingsRepository, IDomainRepository {
    readonly state: MerchantProfileRepositoryState;

    readonly account: MerchantPayoutAbstractAccount | null | undefined;

    readonly accountName: string;

    readonly accounts: Array<MerchantPayoutAbstractAccount>;

    readonly isLoading: boolean;

    readonly isError: boolean;

    readonly isEmpty: boolean;

    fetchPayoutAccounts(): Promise<void>;

    createPayoutAccount(account: MerchantProfileFinance): Promise<void>;

    updatePayoutAccount(account: MerchantPayoutAbstractAccount): Promise<void>;

    deletePayoutAccount(account: MerchantPayoutAbstractAccount): Promise<void>;

    selectPayoutAccount(account: MerchantProfileRepository['account']): void;
}
