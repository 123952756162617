import Box from '@mui/material/Box';
import withGridCellParams from './withGridCellParams';
import { renderElement } from 'lib/country-flag';

const CountryISO = withGridCellParams(({ value }) => {
    if (value) {
        return (
            <Box display='flex' columnGap={2}>
                {renderElement(value)}
                <span>{value}</span>
            </Box>
        );
    }

    return null;
});

export default CountryISO;
