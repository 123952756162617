import type useMerchantProfile from "./useMerchantProfile";
import type useMerchantProfileInfo from "./useMerchantProfileInfo";
import type useMerchantProfileFinance from "./useMerchantProfileFinance";
import type useMarchantPayoutSettings from "./useMarchantPayoutSettings";

export const enum MerchantProfileTab {
    Info = 'Info',
    Finance = 'Finance',
    PayoutSettings = 'PayoutSettings'
};

export type MerchantProfileTabState = {
    readonly [MerchantProfileTab.Info]: ReturnType<typeof useMerchantProfileInfo>;
    readonly [MerchantProfileTab.Finance]: ReturnType<typeof useMerchantProfileFinance>;
    readonly [MerchantProfileTab.PayoutSettings]: ReturnType<typeof useMarchantPayoutSettings>;
};

export type MerchantProfileContext = ReturnType<typeof useMerchantProfile>;
