import type { GridValueFormatterParams } from "@mui/x-data-grid";
import { FLOAT_NUMBER_PRECISION } from "consts/general";

const financial = (params: GridValueFormatterParams<string>) => {
    const value = parseFloat(params.value);
    const factor = Math.pow(10, FLOAT_NUMBER_PRECISION);
    const roundedValue = Math.round(value * factor) / factor;
    return roundedValue.toFixed(FLOAT_NUMBER_PRECISION);
};

export default financial;
