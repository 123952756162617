import { memo, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from "formik";
import useValidationSchema, { formFields } from "./useValidationSchema";
import { getFormInputProps } from "util/forms";
import Iconify from "lib/iconify";
import useActions from "features/auth/useActions";
import type { Theme } from "@mui/material";

const SignIn = () => {
    const { login, getUser } = useActions();

    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            [formFields.email]: "",
            [formFields.password]: "",
        },
        validateOnMount: true,
        validationSchema: useValidationSchema(),
        onSubmit: async (values) => {
            const [request] = login({
                email: values.email,
                password: values.password,
            });

            await request;

            const [userRequest] = getUser();

            await userRequest;
        },
    });

    const changePasswordVisibility = () => {
        setShowPassword(state => !state);
    };

    const getThemeColor = (theme: Theme) =>
        (theme.palette.mode === 'light'
            ? 'common.white'
            : 'grey.800');

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id={formFields.email}
                label="Email Address"
                autoComplete="email"
                type="email"
                autoFocus
                name={formFields.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...getFormInputProps(formFields.email, formik)}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name={formFields.password}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id={formFields.password}
                autoComplete="current-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                        >
                            <IconButton
                                edge="end"
                                onClick={changePasswordVisibility}
                            >
                                <Iconify
                                    icon={showPassword
                                        ? 'eva:eye-fill'
                                        : 'eva:eye-off-fill'}
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...getFormInputProps(formFields.password, formik)}
            />
            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                disabled={!formik.isValid}
                loading={formik.isSubmitting}
                sx={{
                    my: 3,
                    bgcolor: 'text.primary',
                    color: getThemeColor,
                    '&:hover': {
                        bgcolor: 'text.primary',
                        color: getThemeColor,
                    },
                }}
            >
                Login
            </LoadingButton>
        </Box>
    );
};

export default memo(SignIn);
