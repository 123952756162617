import jwtDecode from "jwt-decode";

export type JWTPayload = {
    readonly exp: number;
    readonly iat: number;
    readonly iss: string;
    readonly jti: string;
    readonly nbf: number;
    readonly sub: string;
    readonly _hash: string;
};

const decodeToken = (token: string) =>
    jwtDecode<JWTPayload>(token);

export default decodeToken;
