import { useState } from 'react';
import type { ResourceResponse } from 'types';
import { useSimpleRequest } from 'hooks/useRequest';
import useAggregates from './useAggregates';
import { RequestQueryMapper } from 'util/request-query-mapper';

export type UseQueryArg = {
    readonly getRequestQueryParams: (requestQueryMapper: RequestQueryMapper) => RequestQueryMapper;
    readonly fetchQuery: (queryString: string) => Promise<Response>;
    readonly initialState?: {
        readonly isLoading?: boolean;
    };
};

export function useQuery<TQueryResponse extends ResourceResponse<unknown>>({
    getRequestQueryParams,
    fetchQuery,
    initialState
}: UseQueryArg) {
    const [payload, setPayload] = useState<TQueryResponse['data']>();
    const [isLoading, setLoading] = useState<boolean>(initialState?.isLoading ?? true);

    const request = useSimpleRequest({
        setLoading
    });

    const applyAggregates = useAggregates<Promise<ResourceResponse<unknown>>>((requestQueryMapper) => (
        getRequestQueryParams(requestQueryMapper)
    ));

    const query = applyAggregates((searchQueryParams: string) =>
        request(() => fetchQuery(searchQueryParams), {
            onSuccess: ({ data }: ResourceResponse<unknown>) => setPayload(data)
        })
    );

    return {
        isLoading,
        payload,
        query
    };
};

export function useFetch(...queries: readonly ReturnType<typeof useQuery>[]) {
    const fetch = (searchQueryParams: string) =>
        Promise.allSettled(
            queries.map(({ query }) => query(searchQueryParams))
        );

    const isLoading = queries.some(({ isLoading }) => isLoading);

    return {
        fetch,
        isLoading
    };
};
