import { memo, PropsWithChildren } from 'react';
import { GenerateFileActionProps, GenerateFileDialog } from './GenerateFile';
import { MergeFilesActionProps, MergeFilesDialog } from './MergeFiles';

export type FrontPanelWidgetProviderProps = GenerateFileActionProps & MergeFilesActionProps;

const FrontPanelWidgetProvider = ({
    formId,
    getInitialValues,
    getBootstrapData,
    dialogTitleSlot,
    createNewFileRequestHandler,
    mergeFilesRequestHandler,
    children
}: PropsWithChildren<FrontPanelWidgetProviderProps>) => (
    <MergeFilesDialog
        mergeFilesRequestHandler={mergeFilesRequestHandler}
    >
        <GenerateFileDialog
            formId={formId}
            getInitialValues={getInitialValues}
            getBootstrapData={getBootstrapData}
            createNewFileRequestHandler={createNewFileRequestHandler}
            dialogTitleSlot={dialogTitleSlot}
        >
            {children}
        </GenerateFileDialog>
    </MergeFilesDialog>
);

export default memo(FrontPanelWidgetProvider);
