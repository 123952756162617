import {
    memo,
    type PropsWithChildren,
    type ReactNode
} from "react";
import type { useFormik } from "formik";
import Box, { type BoxProps } from "@mui/material/Box";
import { TabLayout } from "ui/widgets/StatefulTabs";
import ActionPanel from "ui/molecules/ActionPanel";
import type { MerchantDetailProps } from "../../types";
import useMerchantAccountSettingsTab from "./useMerchantAccountSettingsTab";
import { isFormChanged } from "util/forms";

type Props =
    & Pick<BoxProps, 'sx'>
    & MerchantDetailProps
    & Pick<
        ReturnType<typeof useFormik>,
        | 'isValid'
        | 'touched'
        | 'submitForm'
        | 'dirty'
    >
    & {
        readonly title?: ReactNode;
    };

const MerchantAccountSettingsTab = ({
    isValid,
    children,
    title,
    sx,
    onClose,
    submitForm,
    ...props
}: PropsWithChildren<Props>) => {
    const {
        isSaving,
        repository
    } = useMerchantAccountSettingsTab();

    return (
        <TabLayout
            title={title}
            isLoading={repository.isMerchantSettingsLoading}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    ...sx
                }}
            >
                {children}
            </Box>
            <ActionPanel
                sx={{
                    mx: 2
                }}
                isLoading={isSaving}
                SaveActionProps={{
                    disabled: (
                        !isValid ||
                        !isFormChanged(props)
                    )
                }}
                onCancel={onClose}
                onSave={submitForm}
            />
        </TabLayout>
    );
};

export default memo(MerchantAccountSettingsTab);
