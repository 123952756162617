import { memo, PropsWithChildren } from "react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from "ui/atoms/Icon";

const FailedColumnCell = ({ children }: PropsWithChildren) => (
    <Tooltip
        arrow
        title={children}
        placement='top-start'
    >
        <IconButton>
            <Icon name="Info" />
        </IconButton>
    </Tooltip>
);

export default memo(FailedColumnCell);
