import { memo, type PropsWithChildren } from "react";
import Box, { type BoxProps } from "@mui/material/Box";

const BusinessEntityToolbar = ({ children, sx }: PropsWithChildren<BoxProps>) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: 'fit-content',
            ...sx
        }}
        onClick={event => event.stopPropagation()}
    >
        {children}
    </Box>
);

export default memo(BusinessEntityToolbar);
