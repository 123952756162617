import { Paginate } from 'consts/table';
import type { PaginateResourceResponse } from 'types';
import { RequestQueryMapper } from 'util/request-query-mapper';
import { useSimpleRequest, UseSimpleRequestArg } from './useRequest';

export type FetchResourceSettings = {
    readonly shouldFetchAll?: boolean;
    readonly perPage?: number;
};

export type UseFetchResourceArg =
    & UseSimpleRequestArg
    & FetchResourceSettings;

export default function useFetchResource(simpleRequestSettings: UseFetchResourceArg = {}) {
    const request = useSimpleRequest(simpleRequestSettings);

    return async <TResource>(fetchResource: (queryParams?: string) => Promise<any>) => {
        const setPerPage = (perPage?: number) =>
            perPage
                ? RequestQueryMapper.from()
                    .contains(Paginate.perPage, String(perPage))
                    .toString()
                : undefined;

        const response: PaginateResourceResponse<TResource> = await request(
            () => fetchResource(
                setPerPage(simpleRequestSettings.perPage)
            ),
            simpleRequestSettings
        );

        const { total = 0, per_page = 0 } = response.data ?? {};

        if (
            simpleRequestSettings.shouldFetchAll &&
            (total > 1) &&
            (total > per_page)
        ) {
            return request(() => fetchResource(
                setPerPage(total)
            ));
        }

        return response;
    };
};
