import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { TableGridMode } from 'consts/table';
import { useState } from 'react';
import { RequestQueryMapper } from 'util/request-query-mapper';
import type { Adapters } from '../types';
import * as adapters from './adapters';

type UseColumnVisibilityArgs = {
    readonly getColumns: () => GridColDef[];
    readonly mode: TableGridMode;
    readonly adapters?: Adapters;
};

export default function useColumnVisibility({
    getColumns,
    mode,
    adapters: {
        useUserPreferences = adapters.useUserPreferences
    } = {}
}: UseColumnVisibilityArgs) {
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<
        GridColumnVisibilityModel
    >(() => getColumns()
        .reduce((accFields, { field, hide }) =>
        ({
            ...accFields,
            [field]: !hide
        }), {}));

    const { createOrUpdateUserPreferences } = useUserPreferences({
        mode,
        onUserPreferencesFetched: ({ preferences }) => {
            const tablePreferences = preferences[mode] ?? {};

            if ('columnVisibilityModel' in tablePreferences) {
                setColumnVisibilityModel(tablePreferences.columnVisibilityModel);
            }
        }
    });

    const isColumnVisible = (
        field: string,
        visibilityModel = columnVisibilityModel
    ) =>
        (field in visibilityModel)
            ? visibilityModel[field]
            : true;

    const onColumnVisibilityModelChange = (visibilityModel: GridColumnVisibilityModel) => {
        const updatedVisibilityModel = getColumns()
            .reduce((acc, { field }) => ({
                ...acc,
                [field]: isColumnVisible(field, visibilityModel)
            }), {});

        setColumnVisibilityModel(state => ({
            ...state,
            ...updatedVisibilityModel
        }));

        createOrUpdateUserPreferences({
            columnVisibilityModel: updatedVisibilityModel
        });
    };

    const getColumnVisibilityUrlSearchParams = (
        params = new URLSearchParams(),
        validator: (columnSettings: GridColDef) => boolean = () => true
    ) => {
        const columns = getColumns()
            .reduce((accColumns: Array<string>, gridColDef) => {
                const { field } = gridColDef;

                if (isColumnVisible(field) && validator(gridColDef)) {
                    return [
                        ...accColumns,
                        field
                    ];
                }

                return accColumns;
            }, []);

        return RequestQueryMapper.from(params)
            .exportVisibility(columns)
            .searchQueryParams;
    };


    return {
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        getColumnVisibilityUrlSearchParams
    };
};
