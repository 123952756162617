import { paginate } from "consts/table";
import type { PaginatableFilterType } from "ui/widgets/Filters";
import type { Adapters } from "../types";
import * as adapters from "./adapters";

type UsePaginateArgs = {
    readonly fetchData: (urlSearchParams?: URLSearchParams) => Promise<void>;
    readonly adapters?: Adapters;
};

export default function usePaginate({
    fetchData,
    adapters: {
        useSearchParams = adapters.useSearchParams
    } = {}
}: UsePaginateArgs) {
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    return (pgParams: Partial<
        Record<
            PaginatableFilterType,
            | number
            | string
            | undefined
        >
    >) => {
        for (const param of (paginate as Array<PaginatableFilterType>)) {
            if (param in pgParams) {
                urlSearchParams.set(param, `${pgParams[param]}`);
            }
        }

        setUrlSearchParams(urlSearchParams);

        fetchData(urlSearchParams);
    };
};
