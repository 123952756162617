import { memo } from "react";
import FiltersActionable from "ui/layouts/FiltersActionable";
import TriggerKYC from "./TriggerKYC";
import BulkKYC from "./BulkKYC";

const FrontPanelWidget = () => (
    <FiltersActionable>
        <BulkKYC />
        <TriggerKYC />
    </FiltersActionable>
);

export default memo(FrontPanelWidget);
