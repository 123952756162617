import { useFiltersModel } from "ui/widgets/Filters";
import { Filters } from "consts/merchants";
import useMerchantPaymentMethods from "../useMerchantPaymentMethods";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const MerchantPaymentMethodsFilters = {
    [Filters.paymentMethodTag]: 'paymentMethodTag',
    [Filters.paymentMethodPSP]: 'paymentMethodPSP',
    [Filters.MOR]: 'MOR'
} as const;

export default function useMerchantPaymentMethodsFiltersModel() {
    const { getFilteredMerchantPaymentMethodsEntries } = useMerchantPaymentMethods();

    const merchantsMethods = getFilteredMerchantPaymentMethodsEntries()
        .flatMap(([_, methods]) => methods);

    const fromMerchantsMethods = (key: keyof MerchantsMethodsPivot) => (
        Array.from(
            new Set(
                merchantsMethods
                    .map(({ [key]: value }) => value)
            ))
            .map(value => ({ id: value, name: value }))
    );

    const selectTags = () => fromMerchantsMethods(MerchantPaymentMethodsFilters.paymentMethodTag);

    const selectPSPs = () => fromMerchantsMethods(MerchantPaymentMethodsFilters.paymentMethodPSP);

    const selectMORs = () => fromMerchantsMethods(MerchantPaymentMethodsFilters.MOR);

    return useFiltersModel({
        [Filters.paymentMethodTag]: selectTags(),
        [Filters.paymentMethodPSP]: selectPSPs(),
        Domains: selectMORs()
    });
};
