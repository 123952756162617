export const Filters = {
    roleName: 'roleName',
    status: 'status',
    lastLogin: 'lastLogin'
};

export const enum Status {
    Active = 'Active',
    Inactive = 'Inactive',
    Banned = 'Banned'
};

export const single = [
    Filters.status
];
