import { PropsWithChildren, createContext } from "react";
import useMerchantProfile from "./useMerchantProfile";
import type { MerchantProfileContext as MerchantProfileContextType } from "./types";

export const MerchantProfileContext = createContext({} as MerchantProfileContextType);

const MerchantProfileProvider = ({ children }: PropsWithChildren) => (
    <MerchantProfileContext.Provider
        value={useMerchantProfile()}
    >
        {children}
    </MerchantProfileContext.Provider>
);

export default MerchantProfileProvider;
