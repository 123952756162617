import type { ReactNode } from 'react';
import { BaseStatusProps } from "../renderers";

const withDetailsValue = (fn?: (arg: BaseStatusProps) => ReactNode) =>
    (fieldValue: unknown) => {
        const fieldValueValidator = () => fieldValue != null;

        if (!fieldValueValidator()) {
            return null;
        }

        if (typeof fn === 'function') {
            return fn({ value: fieldValue as string });
        }

        return String(fieldValue) || '-'.repeat(17);
    };

export default withDetailsValue;
