import { memo, type PropsWithChildren } from "react";
import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";
import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props =
    & Partial<TooltipProps>
    & Omit<IconButtonProps, 'title'>
    & {
        readonly TooltipProps?: Partial<TooltipProps>;
        readonly IconButtonProps?: IconButtonProps;
    };

const ClipboardTrigger = ({
    onOpen,
    onClose,
    onClick,
    title,
    children,
    TooltipProps,
    IconButtonProps
}: PropsWithChildren<Props>) => (
    <Tooltip
        title={title}
        arrow
        placement='top'
        onOpen={onOpen}
        onClose={onClose}
        {...TooltipProps}
    >
        <IconButton
            onClick={onClick}
            {...IconButtonProps}
        >
            {children ?? (
                <ContentCopyIcon />
            )}
        </IconButton>
    </Tooltip>
);

export default memo(ClipboardTrigger);
