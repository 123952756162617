import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthToken } from "features/auth/helpers";
import { getUser, login, logout } from "features/auth/api";
import type { RootState } from "infrastructure/store";
import { payloadCreator } from "util/api";
import type { AuthResponse, UserCredentials } from "./types";
import { AuthStatus } from "./types";
import { fetchUserThunkType, loginThunkType, logoutThunkType } from "./actionTypes";
import type { ResourceResponse, ThunkReturnType } from "types";
import type { UserResponse } from "features/users/types";

export const userThunk: AsyncThunk<
    ThunkReturnType<UserResponse>,
    void,
    {}
> = createAsyncThunk(
    fetchUserThunkType,
    payloadCreator(getUser),
    {
        condition: (_, { getState }) => {
            const { auth } = getState() as RootState;

            return ![AuthStatus.Pending].includes(auth.status) &&
                !auth.user &&
                Boolean(getAuthToken())
        }
    }
);

export const loginThunk: AsyncThunk<
    ThunkReturnType<AuthResponse>,
    UserCredentials,
    {}
> = createAsyncThunk(
    loginThunkType,
    payloadCreator(login)
);

export const logoutThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<void>>,
    void,
    {}
> = createAsyncThunk(
    logoutThunkType,
    payloadCreator(logout)
);
