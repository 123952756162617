import { memo } from "react";
import type { GridCellParams } from "@mui/x-data-grid-premium";
import Filable from "../Filable";
import Actions from "./Actions";
import { MerchantPayoutsTabs } from "consts/merchantPayouts";
import useActions from "features/merchantPayouts/useActions";
import type { MerchantPayoutsTableProps } from "../MerchantPayoutsTable";

const SummaryFiles = (props: MerchantPayoutsTableProps) => {
    const {
        postMerchantPayoutSummaryFiles,
        mergeMerchantPayoutSummaryFiles
    } = useActions();

    return (
        <Filable
            {...props}
            formId={MerchantPayoutsTabs.SummaryFiles}
            dialogTitleSlot='Create Payout Summary File'
            createNewFileRequestHandler={postMerchantPayoutSummaryFiles}
            mergeFilesRequestHandler={mergeMerchantPayoutSummaryFiles}
            columns={[
                {
                    field: 'actions',
                    renderCell: (params: GridCellParams) => (
                        <Actions {...params} />
                    )
                }
            ]}
        />
    );
};

export default memo(SummaryFiles);
