import type { MerchantPayoutAbstractAccount } from "features/merchants/types";
import type { useForm } from "./useForm";
import type { BootstrapableForm } from "types";

export const enum FormField {
    AccountHolderName = 'accountHolderName',
    Currency = 'currency',
    Iban = 'iban',
    Swift = 'swift',
    Vat = 'vat',
    Address = 'address',
    Chain = 'chain'
};

export type MerchantProfileFinance = Partial<
    Pick<
        MerchantPayoutAbstractAccount,
        | 'accountHolderName'
        | 'currency'
        | 'iban'
        | 'swift'
        | 'vat'
        | 'address'
        | 'chain'
    >
>;

export type MerchantProfileFinanceForm<TForm extends MerchantProfileFinance = MerchantProfileFinance, TAdditional extends object = {}> =
    & ReturnType<typeof useForm<TForm>>
    & BootstrapableForm<TForm, {}>
    & {
        readonly isNew?: boolean;
        readonly isEditMode?: boolean;
    }
    & TAdditional;
