import type { TransactionDetail } from "features/transactions/types";
import type { ReactNode } from 'react';
import type { SxProps } from "@mui/material";
import { useMemo } from 'react';
import { fieldParser, labelParser, transactionDetailTransformer } from "./transactionDetailsTransformer";
import withDetailsValue from "./withDetailsValue";
import {
    transactionFundStatus,
    transactionStatus,
    settlementStatus,
    booleanStatus
} from "../renderers";

type RendererTypes = keyof Pick<TransactionDetail,
    | 'status'
    | 'fundStatus'
    | 'createdAt'
    | 'updatedAt'
    | 'isSettled'
    | 'isPaid'
    | 'settlementStatus'
    | 'settlementStatusDateTime'>;

type GeneralDetailsEntity = {
    readonly label: ReactNode;
    readonly value: ReactNode;
};

export default function useDetailsGeneralInfo(props: TransactionDetail) {
    const transactionDetailProps = transactionDetailTransformer(props);

    const rendererStrategies = useMemo(() => new Map<
        RendererTypes,
        (<T extends unknown>(value: T) => ReactNode)
    >()
        .set('status', withDetailsValue(transactionStatus))
        .set('fundStatus', withDetailsValue(transactionFundStatus))
        .set('isSettled', withDetailsValue(booleanStatus))
        .set('settlementStatus', withDetailsValue(settlementStatus))
        .set('isPaid', withDetailsValue(booleanStatus))
        , []);

    const getTransactionEntries = () =>
        Object.entries<string[]>(transactionDetailProps);

    const getGeneralDetails = () =>
        getTransactionEntries()
            .reduce((generalDetailsArray: Array<GeneralDetailsEntity>, [field, labelValue]) => {
                const fn = rendererStrategies.get(field as RendererTypes) ?? withDetailsValue();

                const label = labelParser(labelValue, props);
                const value = fn(fieldParser(field, props));

                if (!label || !value) {
                    return generalDetailsArray;
                }

                return [
                    ...generalDetailsArray,
                    {
                        label,
                        value
                    }
                ];
            }, []);

    const getRowDecoration = (index: number, defaultDecoration: SxProps = {}) => {
        if (index % 2) {
            return {};
        }

        return defaultDecoration;
    };

    return {
        getGeneralDetails,
        getRowDecoration
    };
};
