import { memo } from 'react';
import {
    ResourceDetail,
    type ResourceDetailProps
} from "ui/widgets/Resource";
import useLedger from './useLedger';
import LedgerCurrency from './LedgerCurrency';
import ActionPanel from 'ui/molecules/ActionPanel';

const Ledger = (props: ResourceDetailProps) => {
    const {
        isLoading,
        getIsChecked,
        getAvailableLedgerCurencies,
        selectLedgerCurrency,
        createLedgers,
        closeDialog
    } = useLedger();

    return (
        <ResourceDetail
            {...props}
            DialogProps={{
                sx: {
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 490
                    }
                }
            }}
            DialogActionsProps={{
                sx: {
                    pt: '0px !important'
                }
            }}
            actionsSlot={(
                <ActionPanel
                    isLoading={isLoading}
                    onSave={createLedgers}
                    onCancel={closeDialog}
                    SaveActionProps={{
                        children: 'Create Ledger'
                    }}
                    CancelActionProps={{
                        children: 'Close'
                    }}
                    sx={{
                        width: '100%',
                        mt: 0
                    }}
                />
            )}
        >
            {getAvailableLedgerCurencies().map((availableLedgerCurrency: string, index) => (
                <LedgerCurrency
                    key={availableLedgerCurrency}
                    label={availableLedgerCurrency}
                    checked={getIsChecked(availableLedgerCurrency)}
                    onChange={selectLedgerCurrency}
                />
            ))}
        </ResourceDetail>
    );
};

export default memo(Ledger);
