import type { TextFieldProps } from "../../FilterVariants/TextFieldFilter";
import type { SelectedFilterContentProps } from "../types";
import { withSelectedFilterContent } from "./SelectedFilterContent";

const TextFieldFilterContent = ({ filter: { props }, onChange }: SelectedFilterContentProps<TextFieldProps>) => {
    const { label, value, id } = props;

    return withSelectedFilterContent({
        onDelete: () => () => onChange({
            type: id,
            payload: ''
        }),
        label,
        values: [String(value).trim()]
            .filter(Boolean)
    });
};

export default TextFieldFilterContent;
