import { useEffect, useRef } from "react";
import { FilterRenderTypes } from "../types";

type UseFiltersEventsArg = {
    readonly [FilterRenderTypes.PopoverDropdown]: Partial<
        Readonly<{
            onApply: (event: MouseEvent) => void;
        }>
    >;
};

export default function useFiltersEvents({
    [FilterRenderTypes.PopoverDropdown]: {
        onApply: onApplyPopoverDropdown
    }
}: UseFiltersEventsArg) {
    const clickEventHandlers = {
        onApplyPopoverDropdown
    };
    const clickEventHandlersRef = useRef(clickEventHandlers);
    clickEventHandlersRef.current = clickEventHandlers;

    useEffect(() => {
        const clickHandler = (event: MouseEvent) => {
            const {
                onApplyPopoverDropdown
            } = clickEventHandlersRef.current;

            const { target } = event;

            if (!(target instanceof Element)) {
                return;
            }

            if (target.closest(`.js-apply-${FilterRenderTypes.PopoverDropdown}`)) {
                onApplyPopoverDropdown?.(event);
            }
        };

        window.addEventListener('click', clickHandler);
        return () => {
            window.removeEventListener('click', clickHandler);
        };
    }, []);
};
