import { useMemo, useState } from 'react';
import { ConsumerActionType, ConsumerState } from './types';
import type { GridRowParams, GridSelectionModel } from '@mui/x-data-grid-premium';
import type { Consumer } from 'features/consumers/types';
import type { ManualBulkKYCsImportHistory } from 'features/kyc/types';
import { useDomainState } from 'hooks';

export default function useConsumer() {
    const {
        processingActionTypeSet: requestProcessingType,
        domainState: consumersState,
        setDomainState: setConsumersState,
        setProcessingActionTypeSet: setRequestProcessingType
    } = useDomainState<ConsumerState, ConsumerActionType>();

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const markConsumerAsKYCed = (consumer: GridRowParams<Consumer>['row']) => () =>
        setConsumersState({
            type: ConsumerActionType.MarkAsKYCed,
            payload: consumer
        });

    const manageDuplicates = (consumer: GridRowParams<Consumer>['row']) => () =>
        setConsumersState({
            type: ConsumerActionType.ManageDuplicates,
            payload: consumer
        });

    const moreDetails = (consumer: GridRowParams<Consumer>['row']) => () =>
        setConsumersState({
            type: ConsumerActionType.MoreDetails,
            payload: consumer
        });

    const triggerKYC = () =>
        setConsumersState({
            type: ConsumerActionType.TriggerKYC,
            payload: selectionModel
        });

    const bulkKYC = () =>
        setConsumersState({
            type: ConsumerActionType.BulkKYC,
            payload: null
        });

    const bulkKYCHistoryResults = (manualBulkKYCImportHistory: GridRowParams<ManualBulkKYCsImportHistory>['row']) => () =>
        setConsumersState({
            type: ConsumerActionType.BulkKYCHistoryResults,
            payload: manualBulkKYCImportHistory
        });

    const getConsumersStatePayloadByType = <T extends unknown, F = T>(type: ConsumerActionType, fallbackValue: F | null = null) => (
        consumersState?.type === type
            ? consumersState.payload
            : fallbackValue
    ) as T;

    const resetConsumersState = () =>
        setConsumersState(null);

    const isRequestProcessing = useMemo(() => {
        if (consumersState) {
            return requestProcessingType.has(consumersState.type);
        }

        return false;
    }, [consumersState, requestProcessingType]);

    const toggleRequestProcessing = (consumersActionType: ConsumerActionType, isImplicit?: boolean) =>
        setRequestProcessingType(state => {
            switch (isImplicit) {
                case true:
                    return new Set(state)
                        .add(consumersActionType);

                case false: {
                    state.delete(consumersActionType);

                    return new Set(state);
                }

                default: {
                    if (state.has(consumersActionType)) {
                        state.delete(consumersActionType);

                        return new Set(state);
                    }

                    return new Set(state)
                        .add(consumersActionType);
                }
            }
        });

    return {
        requestProcessingType,
        consumersActionTypeProcessingState: [
            isRequestProcessing,
            toggleRequestProcessing
        ] as const,
        consumersState,
        selectionModel,
        setSelectionModel,
        getConsumersStatePayloadByType,
        markConsumerAsKYCed,
        manageDuplicates,
        moreDetails,
        triggerKYC,
        bulkKYC,
        bulkKYCHistoryResults,
        resetConsumersState
    };
};
