import type { User as UserType } from "features/users/types";
import { memo, useCallback } from "react";
import Resource from "ui/widgets/Resource";
import {
    type GridRowParams,
    type GridRowIdGetter,
    GRID_ACTIONS_COLUMN_TYPE,
} from "@mui/x-data-grid-premium";
import useUsers from "./useUsers";
import { TableGridMode } from "consts/table";
import {
    selectIsUsersLoading,
    selectIsUsersUninitialized,
    selectUserSlice
} from "features/users/selectors";
import { DeleteUser } from "./UserActions";
import UserToolbar from "./UserToolbar";
import UserCreateEdit from "./UserCreateEdit";

const User = () => {
    const getRowId: GridRowIdGetter<UserType> = useCallback(({ coreId }: UserType) =>
        coreId, []);

    return (
        <Resource
            name='User'
            useResource={useUsers}
            mode={TableGridMode.User}
            selectIsTableUninitialized={selectIsUsersUninitialized}
            selectIsTableLoading={selectIsUsersLoading}
            selectTableDataSlice={selectUserSlice}
            getRowId={getRowId}
            Toolbar={UserToolbar}
            Detail={UserCreateEdit}
            pinnedColumns={{
                right: [GRID_ACTIONS_COLUMN_TYPE]
            }}
            columns={[
                {
                    field: GRID_ACTIONS_COLUMN_TYPE,
                    type: GRID_ACTIONS_COLUMN_TYPE,
                    width: 50,
                    maxWidth: 50,
                    getActions: (params: GridRowParams) => [
                        <DeleteUser
                            {...params}
                        />
                    ]
                }
            ]}

        />
    );
};

export default memo(User);
