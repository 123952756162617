import { memo } from "react";
import Cassette from "../Cassette";
import useStatisticCassettesContext from "../useStatisticCassettesContext";
import { activeMerchantUsersAggregateQueryToken } from "../useStatisticCassettes";
import { selectInactiveUsers } from "features/users/helpers";

const Inactive = () => {
    const {
        [activeMerchantUsersAggregateQueryToken]: query,
        getTotalMerchantUsersCount
    } = useStatisticCassettesContext();

    return (
        <Cassette
            isLoading={query.isLoading}
            labelSlot='Inactive Users'
            amountSlot={getTotalMerchantUsersCount(selectInactiveUsers, query.payload?.data)}
        />
    );
};

export default memo(Inactive);
