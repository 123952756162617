import { memo } from "react";
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { ConfirmableAction } from "ui/molecules/Action";
import Icon from 'ui/atoms/Icon';
import type { DeleteUserProps } from "./types";
import useDeleteUser from "./useDeleteUser";
import DialogContent from "./DialogContent";

const DeleteUser = (props: DeleteUserProps) => {
    const {
        renderLabel,
        getIsRowOwner,
        deleteUser
    } = useDeleteUser(props);

    const dialogContent = {
        dialogContent: [
            <DialogContent {...props} />
        ],
        actionContent: 'Delete user'
    };

    return (
        <GridActionsCellItem
            sx={{
                visibility: getIsRowOwner()
                    ? 'hidden'
                    : 'visible'
            }}
            label={renderLabel({
                prefix: 'Delete user'
            })}
            icon={(
                <ConfirmableAction
                    ActionProps={{
                        children: (
                            <Icon name='DeleteForever' />
                        )
                    }}
                    DialogProps={{
                        content: [
                            dialogContent,
                            dialogContent
                        ],
                        onConfirm: () => deleteUser(props.row.coreId),
                        checkPredicate: () => false,
                        onCancel: () => { },
                        cancelActionContentSlot: 'Cancel'
                    }}
                />
            )}
        />
    );
};

export default memo(DeleteUser);
