import type { MerchantDetailRepositoryState } from "./types";
import { ApiState } from "infrastructure/api";

export const stateFactory: () => MerchantDetailRepositoryState = () => ({
    merchantSettings: {
        apiState: ApiState.Idle,
        data: null
    },
    merchantUsers: {
        apiState: ApiState.Idle,
        data: {} as MerchantDetailRepositoryState['merchantUsers']['data']
    }
});

export default stateFactory;
