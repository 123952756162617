import type { FilterVariant } from "consts/filters";
import { memo } from "react";
import Table from "ui/widgets/Table";
import ResourceProvider from "./ResourceProvider";
import type { ResourceProps } from "./types";

export const Resource = ({
    name,
    useResource,
    Detail,
    DetailProps,
    LayoutProps,
    filterTypes = new Map<FilterVariant, string[]>(),
    ...restTableProps
}: ResourceProps) => {
    const {
        getResource,
        downloadCsv,
        onRowClick
    } = useResource();

    return (
        <>
            <Table
                {...restTableProps}
                fetchTableData={getResource}
                downloadCsv={downloadCsv}
                filterTypes={filterTypes}
                LayoutProps={LayoutProps}
                CardProps={{
                    sx: {
                        height: 'calc(100vh - 100px)'
                    }
                }}
                onRowClick={onRowClick}
            />
            <Detail
                {...DetailProps}
                resourceName={name}
            />
        </>
    );
};

export default memo((props: ResourceProps) => (
    <ResourceProvider>
        <Resource
            {...props}
        />
    </ResourceProvider>
));
