import type { ReactNode } from 'react';
import type { ChipProps } from '@mui/material/Chip';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props<T,> = Omit<ChipProps, 'onDelete'> & {
    readonly values: T[];
    readonly label: ReactNode;
    readonly onDelete: (value: T) => ChipProps['onDelete'];
    readonly renderValues?: (values: T[]) => ReactNode;
    readonly renderChipLabel?: (value: T) => ReactNode;
};

export const SelectedFilterContent = <T,>({
    values,
    label,
    onDelete,
    renderValues,
    renderChipLabel = String,
    ...chipProps
}: Props<T>) => {
    const renderContent = () => {
        const defaultProps = {
            size: 'small',
            color: 'primary'
        } as const;

        if (renderValues) {
            return (
                <Chip
                    {...defaultProps}
                    {...chipProps}
                    label={renderValues?.(values)}
                    onDelete={onDelete(values[0])}
                />
            );
        }

        return values.map((value, index) => (
            <Chip
                key={String(index)}
                {...defaultProps}
                {...chipProps}
                label={renderChipLabel(value)}
                onDelete={onDelete(value)}
            />
        ));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1
            }}
        >
            <Typography
                variant='caption'
                sx={{
                    fontWeight: 'bold'
                }}
            >
                {label}:
            </Typography>&nbsp;
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: .5
                }}
            >
                {renderContent()}
            </Box>
        </Box>
    );
};

export const withSelectedFilterContent = <T,>(props: Props<T>) => {
    const { values } = props;

    if (values.length > 0) {
        return (
            <SelectedFilterContent
                {...props}
            />
        )
    }

    return null;
};

export default SelectedFilterContent;
