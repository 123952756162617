import { PropsWithChildren, createContext } from "react";
import type { QueryBuilderContext } from "./types";
import useQueryBuilder from "./useQueryBuilder";

export const QueryBuilder = createContext({} as QueryBuilderContext);

const QueryBuilderProvider = ({ children }: PropsWithChildren) => (
    <QueryBuilder.Provider
        value={useQueryBuilder()}
    >
        {children}
    </QueryBuilder.Provider>
);

export default QueryBuilderProvider;
