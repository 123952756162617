import { ApiState } from "infrastructure/api";
import type { RootState, ResourceSlice } from "infrastructure/store";
import type { CoreBankingEntity, ID } from "types";

// Active tab
export const selectMerchantPayoutsActiveTab = (state: RootState) =>
    state.merchantPayouts.activeTab;

export const selectIsMerchantPayoutEntityUninitialized = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].tableLoadingState === ApiState.Idle;

export const selectIsMerchantPayoutEntityLoading = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].tableLoadingState === ApiState.Pending;

// export const selectIsExportSummaryFilesLoading = (state: RootState) =>
//     state.merchantPayouts[MerchantPayoutsTabs.SummaryFiles].exportLoadingState === ApiState.Pending;

export const selectMerchantPayoutEntitySlice = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].tableSlice;

// Generic export selector
export const selectIsExportMerchantPayoutsLoading = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].exportLoadingState === ApiState.Pending;

export const selectIsMultiFileMergeEnabled = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].isMultiFileMergeEnabled;

export const selectSelectionModel = (state: RootState) =>
    state.merchantPayouts[selectMerchantPayoutsActiveTab(state)].selectionModel ?? [];

export const selectIsMerchantPayoutLoading = (state: RootState) => {
    for (const {
        exportLoadingState,
        tableLoadingState
    } of Object.values<ResourceSlice<unknown>>(state.merchantPayouts)) {
        if ([exportLoadingState, tableLoadingState].includes(ApiState.Pending)) {
            return true;
        }
    }

    return false;
};

export const selectRowsById = (sliceIds: Array<ID>) => (state: RootState) => {
    const data = selectMerchantPayoutEntitySlice(state)?.data ?? [];

    return data.filter(({ coreId }: CoreBankingEntity) => sliceIds.includes(coreId));
};
