import { memo } from "react";
import AccountAdvancedSettingsForm from "ui/forms/Merchant/AccountAdvancedSettings";
import { useMerchantAccountSettingsContext } from "../MerchantAccountSettingsProvider";

const AccountAdvancedSettings = () => {
    const {
        selectAccountApiSettings
    } = useMerchantAccountSettingsContext();

    return (
        <AccountAdvancedSettingsForm
            {...selectAccountApiSettings()}
        />
    );
};

export default memo(AccountAdvancedSettings);
