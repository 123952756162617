import { useFormik, type FormikConfig } from "formik";
import { FormField, type MerchantProfileInfo } from "./types";
import type { Merchant } from "features/merchants/types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = (initialValues: Partial<Merchant>) => ({
    [FormField.Avatar]: '',
    [FormField.Company]: '',
    [FormField.Address]: '',
    [FormField.ShowPrimaryContactFullName]: 0,
    [FormField.City]: '',
    [FormField.VatNumber]: '',
    [FormField.State]: '',
    [FormField.Phone]: '',
    [FormField.ZipCode]: '',
    [FormField.DefaultLanguage]: '',
    [FormField.Country]: '',
    ...initialValues,
});

export const useForm = (merchantProfileInfoForm: FormikConfig<MerchantProfileInfo>) => {
    const {
        initialValues,
        ...restFormArgs
    } = merchantProfileInfoForm;

    const validationSchema = useValidationSchema();

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        ...restFormArgs,
        validationSchema: validationSchema,
        // isInitialValid: validationSchema.isValidSync(initialValues),
        initialValues: getInitialValues(initialValues)
    });
};