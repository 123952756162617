import type { ReactNode, FC } from "react";
import { FilterRenderTypes } from "ui/widgets/Filters/types";
import DateFilterContent from "./SelectedFilterContent/DateFilterContent";
import SelectFilterContent from "./SelectedFilterContent/SelectFilterContent";
import NumericRangeFilterContent from "./SelectedFilterContent/NumericRangeFilterContent";
import TextFieldFilterContent from "./SelectedFilterContent/TextFieldFilterContent";
import type { RenderFilterValuesArg, SelectedFilterContentProps } from "./types";

const renderFilterContentStrategy: Map<
    FilterRenderTypes,
    FC<SelectedFilterContentProps>
> = new Map()
    .set(FilterRenderTypes.Dropdown, SelectFilterContent)
    .set(FilterRenderTypes.PopoverDropdown, SelectFilterContent)
    .set(FilterRenderTypes.DateRange, DateFilterContent)
    .set(FilterRenderTypes.PopoverPeriodDropdown, DateFilterContent)
    .set(FilterRenderTypes.TextField, TextFieldFilterContent)
    .set(FilterRenderTypes.PopoverTextField, TextFieldFilterContent)
    .set(FilterRenderTypes.NumericRange, NumericRangeFilterContent);

export default function useSelectedFilters() {
    const renderFilterValues = ({
        filters,
        getFilterOptions,
        onChange
    }: RenderFilterValuesArg) => {
        return filters
            .reduce((filterContents: Array<ReactNode>, filter) => {
                const { renderAs } = filter;
                let content: ReactNode = null;

                if (renderFilterContentStrategy.has(renderAs)) {
                    content = renderFilterContentStrategy.get(renderAs)!({
                        filter,
                        getFilterOptions,
                        onChange
                    });
                }

                return [
                    ...filterContents,
                    content
                ];
            }, [])
            .filter(Boolean);
    };

    return {
        renderFilterValues
    };
};
