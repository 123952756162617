import { memo } from 'react';
import type { Consumer } from 'features/consumers/types';
import Box from '@mui/material/Box';
import Clipboard from 'ui/molecules/Clipboard';

type Props = Pick<
    Consumer,
    | 'coreId'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'hashedEmail'
    | 'samePersonIdentifierSource'
>;

const ConsumerDetails = ({
    coreId,
    firstName,
    lastName,
    email,
    hashedEmail,
    samePersonIdentifierSource: reason
}: Props) => {

    const renderEmail = email ? (
      <Clipboard
        sx={{
          fontWeight: 700,
        }}
      >
        {email}
      </Clipboard>
    ) : (
      <Box
        sx={{
          color: "text.disabled",
        }}
      >
        example@example.com
      </Box>
    );
    const hashedEmailLabel = hashedEmail ? hashedEmail : 'No hashed email'

    return (
    <Box>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, max-content)',
                columnGap: 2,
                fontWeight: 700,
                fontSize: '1.2rem'
            }}
        >
            <Box
                sx={{
                    color: 'text.disabled'
                }}
            >
                ID: {coreId}
            </Box>
            <Box>{[firstName, lastName].join(' ')}</Box>
        </Box>
        { renderEmail }
        <Box
            sx={{
                fontSize: 14
            }}
        >
            <Box
                sx={{
                    color: 'text.disabled'
                }}
            >
                Hashed email:
            </Box>
            <Box>
                {hashedEmailLabel}
            </Box>
        </Box>
    </Box>
    );
}
export default memo(ConsumerDetails);
