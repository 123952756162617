import useCountries from "features/general/useCountries";
import useMerchantActions from "features/merchants/useMerchantActions";
import useUser from "features/users/useUser";
import {
    selectIsMerchantGroupsLoading,
    selectIsMerchantLoading,
    selectMerchantGroupsOptions
} from "features/merchants/selectors";
import { useTypedSelector } from "hooks";
import {
    type Dispatch,
    type SetStateAction
} from "react";
import { Create } from "ui/forms/Merchant";
import { FormField, useForm as useUserForm } from 'ui/forms/User';
import { type MerchantFormState, MerchantStepForm } from "./types";
import { Merchant, MerchantResponse } from "features/merchants/types";
import { useResourceContext } from "ui/widgets/Resource";
import { getOptions } from "util/option";
import useSessionEffect from "hooks/useSessionEffect";
import { MerchantOption } from "features/merchants/helpers";
import useMerchantUserActions from "features/merchants/useMerchantUserActions";
import type { UserResponse } from "features/users/types";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";

const merchantGroupsSessionEffectSymbol = Symbol('merchantGroupsSessionEffect');

export default function useMerchant(setActiveStep: Dispatch<SetStateAction<MerchantStepForm>>) {
    const { onClose } = useResourceContext();

    const { showNotication } = useActions();
    const { getAllMerchantGroups, postMerchant } = useMerchantActions();
    const { updateOrAddMerchantUserById } = useMerchantUserActions();

    const { countries, isCountriesLoading } = useCountries();

    const isMerchantRequestProcessing = useTypedSelector(selectIsMerchantLoading);
    const merchantGroupsOptions = useTypedSelector(selectMerchantGroupsOptions);
    const isMerchantGroupsLoading = useTypedSelector(selectIsMerchantGroupsLoading);

    useSessionEffect(() => {
        getAllMerchantGroups();
    }, merchantGroupsSessionEffectSymbol);

    const user = useUser({
        user: {},
        onUserUpdateOrCreate: ({ data }: UserResponse) => updateOrAddMerchantUserById(data)
    });
    const userForm = useUserForm(user);

    const createMerchant = async (merchant: Partial<Merchant>): Promise<MerchantResponse> => {
        const [request] = postMerchant(merchant);
        const response = await request as MerchantResponse
        showNotication({
            severity: NotificationSeverity.Success,
            message: response.message
        });
        return response;
    };

    const merchantCreateForm = Create.useForm({
        initialValues: {},
        isInitialValid: false,
        onSaveOrCreate: createMerchant,
    });

    return new Map<MerchantStepForm, MerchantFormState>([
        [MerchantStepForm.Merchant, {
            ...merchantCreateForm,
            onSaveOrCreate: createMerchant,
            bootstrapData: {
                countries,
                merchantGroupsOptions
            },
            isBootstrapDataLoading: (
                isCountriesLoading ||
                isMerchantGroupsLoading
            ),
            renderTitle: () => 'Merchant',
            getActionPanelProps: () => ({
                CancelActionProps: {
                    children: 'Save',
                    disabled: !merchantCreateForm.isValid,
                    loading: isMerchantRequestProcessing
                },
                SaveActionProps: {
                    children: 'Save and Create Merchant User',
                    disabled: !merchantCreateForm.isValid,
                    loading: isMerchantRequestProcessing
                },
                onCancel: async () => {
                    await createMerchant(merchantCreateForm.values);

                    onClose();
                    merchantCreateForm.resetForm();
                },
                onSave: async () => {
                    const { data } = await createMerchant(merchantCreateForm.values);

                    setActiveStep(MerchantStepForm.MerchantUser);
                    userForm.setFieldValue(
                        FormField.Merchants,
                        getOptions(
                            [MerchantOption.fromMerchant(data), ...user.bootstrapData.merchants],
                            [data.coreId]
                        ),
                        true
                    );
                    merchantCreateForm.resetForm();
                },
            }),
            id: String(MerchantStepForm.Merchant)
        }],
        [MerchantStepForm.MerchantUser, {
            ...userForm,
            ...user,
            renderTitle: () => 'Merchant User',
            getActionPanelProps: () => ({
                cancelActionSlot: (
                    <></>
                ),
                SaveActionProps: {
                    children: 'Create Merchant User',
                    disabled: !userForm.isValid,
                    loading: user.isCreateOrUpdaterequestLoading
                },
                onSave: async () => {
                    await userForm.submitForm();

                    onClose();
                    userForm.resetForm();
                    setActiveStep(MerchantStepForm.Merchant);
                }
            }),
            id: String(MerchantStepForm.MerchantUser)
        }],
    ]);
};
