import { ApiRoutes } from "consts/enpoints/api";
import { Filters } from "consts/users";
import type {
    Ledger,
    Merchant,
    MerchantBusiness,
    MerchantDomain,
    MerchantKYCService,
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount,
    MerchantSettings
} from "features/merchants/types";
import { fetchApi } from "infrastructure/api";
import { createOrUpdateResource } from "infrastructure/api/api";
import type { ID } from "types";
import { RequestQueryMapper } from "util/request-query-mapper";

export const getMerchants = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchants}${queryString}`);

export const getMerchantDomains = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantDomains}${queryString}`);

export const createOrUpdateMerchantDomain = (body: Partial<MerchantDomain>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostMerchantDomains,
        getId: body => body.coreId
    });
    
export const deleteMerchantDomain = (coreId: ID) =>
    fetchApi(ApiRoutes.DeleteMerchantDomains(coreId), {
        method: 'DELETE'
    });

export const getMerchantBusinesses = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantBusinesses}${queryString}`);

export const createOrUpdateMerchantBusiness = (body: Partial<MerchantBusiness>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostMerchantBusinesses,
        getId: body => body.coreId
    });
    
export const deleteMerchantBusiness = (coreId: ID) =>
    fetchApi(ApiRoutes.DeleteMerchantBusinesses(coreId), {
        method: 'DELETE'
    });

export const getMerchantUsers = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantUsers}${
        RequestQueryMapper
            .from(queryString)
            .contains(Filters.roleName, 'Merchant')
            .toString()
    }`);

export const getMerchantGroups = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantGroups}${queryString}`);

export const createOrUpdateMerchant = (body: Partial<Merchant>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostMerchant,
        getId: body => body.coreId
    });

export const getMerchantKYCSettings = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantKYCSettings}${queryString}`);

export const updateOrCreateMerchantKYCSettings = (body: Partial<MerchantKYCService> & Required<Pick<
    MerchantKYCService,
    | 'merchantId'
    | 'kycTypeId'
    | 'summedAmountEurLimit'
>>) =>
    fetchApi(ApiRoutes.PutMerchantKYCSettings(
        body.merchantId,
        body.kycTypeId
    ), {
        method: 'PUT',
        body
    });

export const getMerchantSettings = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantSettings}${queryString}`);

export const createOrUpdateMerchantSettings = (body: Partial<MerchantSettings>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PutMerchantSettings,
        getId: body => body.coreId
    });

export const getPayoutAccounts = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetPayoutAccounts}${queryString}`);

export const createOrUpdatePayoutAccount = (body: Partial<MerchantPayoutAccount>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PutPayoutAccounts,
        getId: body => body.coreId
    });

export const deletePayoutAccount = (coreId: ID) =>
    fetchApi(ApiRoutes.DeletePayoutAccounts(coreId), {
        method: 'DELETE'
    });

export const getPayoutCryptoAccounts = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetPayoutCryptoAccounts}${queryString}`);

export const createOrUpdatePayoutCryptoAccount = (body: Partial<MerchantPayoutCryptoAccount>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PutPayoutCryptoAccounts,
        getId: body => body.coreId
    });

export const deletePayoutCryptoAccount = (coreId: ID) =>
    fetchApi(ApiRoutes.DeletePayoutCryptoAccounts(coreId), {
        method: 'DELETE'
    });

export const getLedgers = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetLedgers}${queryString}`);

export const createOrUpdateLedger = (body: Partial<Ledger>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostLedgers,
        getId: body => body.coreId
    });
