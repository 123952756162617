const download = async ({
    fileName,
    obj
}: {
    readonly fileName: string;
    readonly obj: Blob | MediaSource
}) => {
    // const link = document.createElement("a");

    // link.setAttribute("href", href);
    // link.setAttribute("download", fileName);

    // document.body.appendChild(link);

    // link.click();

    // await onDownload();

    // link.remove();
    const url = window.URL.createObjectURL(obj);
    const a = document.createElement('a');

    a.style.display = 'none';

    a.href = url;

    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);

    a.remove();
};

export default download;
