import { memo, useImperativeHandle, forwardRef, type PropsWithChildren } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { getFormInputProps } from 'util/forms';
import useConsumerIDVerificationForm, { type FormValues } from './useConsumerIDVerificationForm';
import type { Form } from 'types';
import { FormFields } from './validators';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UiDateTimeFormatter, formatDate } from 'util/formaters';
import { DEFAULT_MAX_DATE } from 'consts/general';

type Props = Omit<
    Form<FormValues>,
    | 'isLoading'
>;

const ConsumerIDVerificationForm = forwardRef(({ children, ...props }: PropsWithChildren<Props>, ref) => {
    const formik = useConsumerIDVerificationForm(props);

    const {
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue
    } = formik;

    useImperativeHandle(ref, () => formik);

    return (
        <Box
            id={props.id}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2
            }}
        >
            <TextField
                {...getFormInputProps(FormFields.FirstName, { errors, touched })}
                name={FormFields.FirstName}
                value={values[FormFields.FirstName]}
                label="First name"
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                {...getFormInputProps(FormFields.LastName, { errors, touched })}
                name={FormFields.LastName}
                value={values[FormFields.LastName]}
                label="Last name"
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <DatePicker
                label='Date of birth'
                disableFuture
                inputFormat={UiDateTimeFormatter.Ui}
                value={values[FormFields.DateOfBirth]}
                maxDate={moment(DEFAULT_MAX_DATE)}
                defaultCalendarMonth={moment(DEFAULT_MAX_DATE)}
                onChange={date =>
                    setFieldValue(
                        FormFields.DateOfBirth,
                        formatDate(date, { pattern: UiDateTimeFormatter.Default }),
                        true
                    )}
                renderInput={params => (
                    <TextField
                        {...params}
                    />
                )}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    width: '100%',
                    mt: 2
                }}
            >
                {children}
                <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!isValid}
                    loading={isSubmitting}
                >
                    {props.actionLabel}
                </LoadingButton>
            </Box>
        </Box>
    );
});

export default memo(ConsumerIDVerificationForm);
