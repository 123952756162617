import { memo } from "react";
import type { MerchantBusiness } from "features/merchants/types";
import MerchantBusinessActivator from "./MerchantBusinessActivator";
import DeleteMerchantBusiness from "./DeleteMerchantBusiness";
import EditMerchantBusiness from "./EditMerchantBusiness";
import BusinessEntityToolbar from "../../shared/BusinessEntityToolbar";

const MerchantBusinessToolbar = (merchantBusiness: MerchantBusiness) => (
    <BusinessEntityToolbar
        sx={{
            display: 'grid',
            gridTemplateColumns: '50px 50px 150px'
        }}
    >
        <DeleteMerchantBusiness
            {...merchantBusiness}
        />
        <EditMerchantBusiness
            {...merchantBusiness}
        />
         <MerchantBusinessActivator
            {...merchantBusiness}
        />
    </BusinessEntityToolbar>
);

export default memo(MerchantBusinessToolbar);
