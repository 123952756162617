import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { Ledger, Merchant, MerchantGroup } from "./types";
import type { ID } from "types";
import { compareIds } from "util/support";
import { MerchantTabs } from "consts/merchants";
import { MerchantOption } from "./helpers";

export const selectMerchantsActiveTab = (state: RootState) =>
  state.merchants.activeTab;

export const selectIsMerchantsUninitialized = (state: RootState) =>
  state.merchants[selectMerchantsActiveTab(state)].tableLoadingState === ApiState.Idle;

export const selectIsMerchantsSucceeded = (tab?: MerchantTabs) => (state: RootState) =>
  state.merchants[tab ?? selectMerchantsActiveTab(state)].tableLoadingState === ApiState.Succeeded;

export const selectIsMerchantsLoading = (tab?: MerchantTabs) => (state: RootState) =>
  state.merchants[tab ?? selectMerchantsActiveTab(state)].tableLoadingState === ApiState.Pending;

export const selectIsMerchantsModelLoading = (state: RootState) =>
  state.merchants.merchants.tableLoadingState === ApiState.Pending;

export const selectIsExportMerchantsLoading = (state: RootState) =>
  state.merchants[selectMerchantsActiveTab(state)].exportLoadingState === ApiState.Pending;

export const selectMerchantsSlice = (tab?: MerchantTabs) => (state: RootState) =>
  state.merchants[tab ?? selectMerchantsActiveTab(state)].tableSlice;

export const selectMerchantsModelSlice = (state: RootState) =>
  state.merchants.merchants.tableSlice;

export const selectMerchantsTotalCount = (state: RootState) =>
  state.merchants.merchants.tableSlice?.total ?? 0;

export const selectIsMerchantLoading = (state: RootState) =>
  state.merchants[MerchantTabs.Merchants].entityLoadingState === ApiState.Pending;

export const selectMerchantOptions = (state: RootState) =>
  state.merchants.merchants
    .tableSlice
    ?.data
    .map((merchant: Merchant) => MerchantOption.fromMerchant(merchant)) ?? [];

export const selectMerchantById = (merchantId: ID) =>
  (state: RootState) =>
    state.merchants[MerchantTabs.Merchants]
      .tableSlice
      ?.data
      .find(({ coreId }: Merchant) => compareIds(coreId, merchantId));

export const selectAllMerchants = (state: RootState) =>
  selectMerchantsModelSlice(state)?.data ?? [];

export const selectActiveMerchants = (state: RootState) =>
  selectMerchantsModelSlice(state)
    ?.data
    .filter(({ isActive }: Merchant) => isActive) ?? [];

export const selectInactiveMerchants = (state: RootState) =>
  selectMerchantsModelSlice(state)
    ?.data
    .filter(({ isActive }: Merchant) => !isActive) ?? [];

export const selectIsMerchantGroupsLoading = (state: RootState) =>
  state.merchants[MerchantTabs.Merchants]
    .bootstrapData.merchantGroups.loadingState === ApiState.Pending;

export const selectMerchantGroups = (state: RootState) =>
  state.merchants[MerchantTabs.Merchants]
    .bootstrapData.merchantGroups.slice ?? [];

export const selectMerchantGroupsOptions = (state: RootState) =>
  selectMerchantGroups(state)
    .map(({ coreId, merchantGroupName }: MerchantGroup) => ({
      id: coreId,
      name: merchantGroupName
    }));

export const selectIsMerchantUsersUninitialized = (state: RootState) =>
  state.merchants.merchantUsers.tableLoadingState === ApiState.Idle;

export const selectIsMerchantUsersLoading = (state: RootState) =>
  state.merchants.merchantUsers.tableLoadingState === ApiState.Pending;

export const selectIsMerchantUsersSucceeded = (state: RootState) =>
  state.merchants.merchantUsers.tableLoadingState === ApiState.Succeeded;

export const selectMerchantUsersSlice = (state: RootState) =>
  state.merchants.merchantUsers.tableSlice;

export const selectIsExportMerchantUsersLoading = (state: RootState) =>
  state.merchants.merchantUsers.exportLoadingState === ApiState.Pending;

export const selectIsMerchantLedgersUninitialized = (state: RootState) =>
  state.merchants.ledgers.tableLoadingState === ApiState.Idle;

export const selectIsMerchantLedgersLoading = (state: RootState) =>
  state.merchants.ledgers.tableLoadingState === ApiState.Pending;

export const selectIsMerchantLedgersSucceeded = (state: RootState) =>
  state.merchants.ledgers.tableLoadingState === ApiState.Succeeded;

export const selectMerchantLedgersSlice = (state: RootState) =>
  state.merchants.ledgers.tableSlice;

export const selectMerchantLedgerCurrencies = (state: RootState) =>
  selectMerchantLedgersSlice(state)
    ?.data
    .map(({ currency }: Ledger) => currency) ?? [];

export const selectIsExportMerchantLedgersLoading = (state: RootState) =>
  state.merchants.ledgers.exportLoadingState === ApiState.Pending;
