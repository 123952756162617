import moment from "moment";
import type { DateRangeFilterProps } from "../../FilterVariants/DateFilter/DaterangeFilter";
import type { PopoverPeriodFilterProps } from "../../FilterVariants/DateFilter/PopoverPeriodFilter";
import type { SelectedFilterContentProps } from "../types";
import { UiDateTimeFormatter } from "util/formaters";
import { withSelectedFilterContent } from "./SelectedFilterContent";

const DateFilterContent = ({ filter: { props }, onChange }: SelectedFilterContentProps<DateRangeFilterProps>) => {
    const { startProps, endProps, value, label, id } = props;

    const getDateFormat = () => {
        let format: string | undefined = UiDateTimeFormatter.Ui;

        if ('InputDateRangeFieldProps' in props) {
            format = (props as PopoverPeriodFilterProps).InputDateRangeFieldProps?.format;
        }

        return format;
    };

    const values = value;

    return withSelectedFilterContent({
        onDelete: () => () => onChange({
            type: id,
            payload: [null, null]
        }),
        label: [
            startProps?.label,
            endProps?.label,
            label
        ]
        .filter(Boolean)
        .join(' - '),
        values: values
            .filter(Boolean),
        renderValues: () => values
            .map((v, index) =>
                index > 0
                    ? moment(v).endOf('d')
                    : moment(v).startOf('d')
            )
            .filter(date => date.isValid())
            .map(date => date.format(getDateFormat()))
            .join(' - ')
    });
};

export default DateFilterContent;
