import type { MerchantSettings } from "features/merchants/types";
import {
    FormField,
    type MerchantAccountApiSettings,
    type MerchantAccountApiSettingsProps
} from "./types";
import useValidationSchema from "./useValidationSchema";
import { useForm as useFormHook } from "hooks";

export const getInitialValues = (initialValues?: Partial<MerchantSettings>) => ({
    [FormField.ApiId]: '',
    [FormField.ApiGuid]: '',
    [FormField.CpKey]: '',
    [FormField.CpSecret]: '',
    [FormField.SharedKey]: '',
    ...initialValues,
});

export const useForm = (accountApiSettingsProps: MerchantAccountApiSettingsProps) =>
    useFormHook<MerchantAccountApiSettings>({
        validateOnMount: true,
        ...accountApiSettingsProps,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(accountApiSettingsProps.initialValues)
    });
