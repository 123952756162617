import { memo } from "react";
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Skeleton from "@mui/material/Skeleton";
import useSelectedFilters from "./useSelectedFilters";
import type { RenderFilterValuesArg } from "./types";

type Props = {
    readonly isOpen: boolean;
    readonly isBootstrapDataLoading: boolean;
} & RenderFilterValuesArg;

const SelectedFilters = ({
    isOpen,
    isBootstrapDataLoading,
    ...restProps
}: Props) => {
    const { renderFilterValues } = useSelectedFilters();

    const renderContent = () => {
        const fitlerContents = renderFilterValues(restProps);
        const { length } = fitlerContents;

        if (isBootstrapDataLoading && length) {
            return (
                <>
                    {Array.from({ length })
                        .map((_, index) => (
                            <Skeleton
                                key={String(index)}
                                width={50}
                                height={24}
                            />
                        ))}
                </>
            );
        }

        return fitlerContents;
    };



    return (
        <Box
            sx={{
                gridColumn: '1 / span 3'
            }}
        >
            <Fade
                in={!isOpen}
                unmountOnExit
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1
                    }}
                >
                    {renderContent()}
                </Box>
            </Fade>
        </Box>
    );
};

export default memo(SelectedFilters);
