import { memo } from "react";
import Box from "@mui/material/Box";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import useBulkKYCStepper from "./useBulkKYCStepper";
import type useBulkKYCDialog from "../useBulkKYCDialog";

type Props = Pick<
    ReturnType<typeof useBulkKYCDialog>,
    | 'bulkKYCState'
    | 'onChange'
    | 'onComplete'
    | 'validator'
    | 'servicesState'
    | 'onKYCServiceChange'
    | 'isSubmitting'
    | 'onSkip'
>;

const BulkKYCStepper = ({
    bulkKYCState,
    servicesState,
    isSubmitting,
    onChange,
    onComplete,
    onKYCServiceChange,
    validator,
    onSkip
}: Props) => {
    const {
        activeStep,
        handleBack,
        handleNext,
        fromSteps,
        getIsOptional,
        getComponentSlots,
        isLastStep
    } = useBulkKYCStepper();

    const {
        mainArea: MainArea,
        actionArea: ActionArea
    } = getComponentSlots();

    const handleSkip = () => {
        onSkip(activeStep);
        handleNext(false);
    };

    const renderActionArea = () => {
        const isValid = validator(activeStep);

        if (ActionArea) {
            return (
                <ActionArea
                    onComplete={onComplete}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                />
            );
        }

        if (isLastStep()) {
            return (
                <LoadingButton
                    color='primary'
                    variant='contained'
                    onClick={onComplete}
                    disabled={!isValid}
                    loading={isSubmitting}
                >
                    Submit
                </LoadingButton>
            );
        }

        return (
            <Button
                color='secondary'
                variant='contained'
                onClick={() => handleNext(true)}
                disabled={!isValid}
            >
                Next
            </Button>
        );
    };

    return (
        <Box
            sx={{
                p: 4
            }}
        >
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                sx={{
                    mb: 4
                }}
            >
                {fromSteps()
                    .map(([key, { label, isCompleted, isOptional }]) => (
                        <Step
                            key={key}
                            completed={isCompleted}
                        >
                            <StepLabel
                                optional={isOptional && (
                                    <Typography
                                        variant='caption'
                                    >
                                        Optional
                                    </Typography>
                                )}

                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
            <Box>
                <MainArea
                    value={bulkKYCState[activeStep]}
                    servicesState={servicesState}
                    onKYCServiceChange={onKYCServiceChange}
                    onChange={onChange<number>(activeStep)}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 4
                }}
            >
                <Button
                    color='inherit'
                    disabled={!activeStep || isSubmitting}
                    onClick={handleBack}
                    sx={{
                        gap: 1
                    }}
                >
                    Back
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 1
                    }}
                >
                    {getIsOptional() && (
                        <Button
                            color='warning'
                            variant='outlined'
                            disabled={isSubmitting}
                            onClick={handleSkip}
                            sx={{
                                mr: 1
                            }}
                        >
                            Skip
                        </Button>
                    )}
                    {renderActionArea()}
                </Box>
            </Box>
        </Box>
    );
};

export default memo(BulkKYCStepper);
