import { createContext, memo, PropsWithChildren, MutableRefObject } from "react";
import { FiltersPublicApi } from "ui/widgets/Table";

export const FiltersPublicApiContext = createContext<FiltersPublicApi<string> | null>(null);

type Props = {
    readonly filtersRef: MutableRefObject<FiltersPublicApi<string> | null>;
};

const FiltersPublicApiProvider = ({ children, filtersRef }: PropsWithChildren<Props>) => (
    <FiltersPublicApiContext.Provider value={filtersRef.current}>
        {children}
    </FiltersPublicApiContext.Provider>
);

export default memo(FiltersPublicApiProvider);
