import type { MerchantKYCService } from "features/merchants/types";
import { useKYCSettingsActionsContext, useKYCSettingsStateSelectorsContext } from "ui/organizms/Merchants/KYC/KYCSettingsProvider";
import type { UseOptionControllerArg } from "./useOptionController";

type UseAdditionalOptionsArg = Pick<MerchantKYCService, 'kycTypeId'>;

export default function useAdditionalOptions({
    kycTypeId
}: UseAdditionalOptionsArg) {
    const {
        updateAdditionalOptions
    } = useKYCSettingsActionsContext();

    const {
        selectAdditionalSettingsByTypeId
    } = useKYCSettingsStateSelectorsContext();

    const onChange: UseOptionControllerArg['onChange'] = kycSettings => {
        updateAdditionalOptions({
            kycTypeId,
            kycSettings
        });
    };

    return {
        value: selectAdditionalSettingsByTypeId(kycTypeId),
        onChange
    };
};
