import { memo } from "react";
import Divider from "@mui/material/Divider";
import TabLayout from "../TabLayout";
import { TabIndex, TabProps } from "../types";
import Box from "@mui/material/Box";
import ConsumerDetails from "../ConsumerDetails";
import UserActivity from "./UserActivity";
import IbanList from "./IbanList";
import useMoreDetails from "./useMoreDetails";
import TransactionStats from "./TransactionStats";

const MoreDetails = (props: TabProps<TabIndex.MoreDetails>) => {
    const { getTabPayload } = props;

    useMoreDetails(props);

    return (
        <TabLayout
            getTabPayload={getTabPayload}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: 3
                }}
            >
                <ConsumerDetails
                    {...getTabPayload()}
                />
                <UserActivity
                    {...props}
                />
                <IbanList
                    {...props}
                />
            </Box>
            <Divider
                sx={{
                    my: 3
                }}
            />
            <TransactionStats
                {...props}
            />
        </TabLayout>
    );
};

export default memo(MoreDetails);
