import { memo } from "react";
import MerchantUserToolbar from "./MerchantUserToolbar";
import { ActiveInactiveSelector } from "../../shared/ToolbarFilters";
import type { MerchantUserToolbarProps } from "./types";

const MerchantUserToolbarExtended = ({ onExport, ...props }: MerchantUserToolbarProps) => (
    <MerchantUserToolbar
        {...props}
        search={ActiveInactiveSelector}
        onExport={onExport}
    />
);

export default memo(MerchantUserToolbarExtended);
