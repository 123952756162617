import useForm from "hooks/useForm";
import type { IncrementExchangeRateForm } from "./types";
import { getInitialValues, useValidationSchema } from "./validators";

export default function useIncrementExchangeRateForm({
    initialValues,
    ...restFormArgs
}: IncrementExchangeRateForm) {
    return useForm({
        ...restFormArgs,
        initialValues: getInitialValues(initialValues),
        validationSchema: useValidationSchema()
    });
};
