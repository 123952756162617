import { stateFactory } from "./state";
import {
    MerchantUsersActionType,
    type MerchantUsersRepositoryAction,
    type MerchantUsersRepositoryState
} from "./types";

export function reducer(state: MerchantUsersRepositoryState, action: MerchantUsersRepositoryAction) {
    switch (action.type) {
        case MerchantUsersActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
