export const ACCESS_TOKEN_EXPIRY_THRESHOLD = 3;

export const enum UserRoles {
    Admin,
    Employee
};

export const enum UserRolesNames {
    Admin = 'Admin',
    Employee = 'Employee',
    RootAdmin = 'RootAdmin',
    Merchant = 'Merchant',
    Mors = 'mors',
    MerchantsAgent = 'MerchantsAgent',
    MorsAgent = 'MorsAgent'
};
