import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";
import { ApiState } from "infrastructure/api";
import type { PaginateResourceResponse, ThunkReturnType } from "types";
import type { MerchantsMethodsPivot, PivotsState } from "./types";

export const merchantsMethodsPivotRequestLoading = (state: WritableDraft<PivotsState>) => {
    state.merchantsMethods.loadingState = ApiState.Pending;
};

export const merchantsMethodsPivotRequestFulfilled = (
    state: WritableDraft<PivotsState>,
    { payload }: PayloadAction<ThunkReturnType<PaginateResourceResponse<MerchantsMethodsPivot>>>
) => {
    state.merchantsMethods.loadingState = ApiState.Succeeded;
    state.merchantsMethods.slice = payload!.data;
};

export const merchantsMethodsPivotRequestRejected = (state: WritableDraft<PivotsState>) => {
    state.merchantsMethods.loadingState = ApiState.Failed;
    state.merchantsMethods.slice = null;
};
