import {
    selectIsMerchantPayoutEntityLoading,
    selectIsMerchantPayoutEntityUninitialized,
    selectMerchantPayoutEntitySlice
} from "features/merchantPayouts/selectors";
import { memo } from "react";
import Table, { type TableProps } from "ui/widgets/Table";
import MerchantPayoutsToolbar from "./MerchantPayoutsTableToolbar";
import { TableGridMode } from "consts/table";
import { FilterVariant } from "consts/filters";
import useActions from "features/merchantPayouts/useActions";

export type MerchantPayoutsTableProps = {
    readonly mode: TableGridMode;
    readonly fetchTableData: (filterParams: string) => void;
    readonly filterTypes: Map<FilterVariant, string[]>;
} & Partial<TableProps>;

const MerchantPayoutsTable = ({
    mode,
    fetchTableData,
    filterTypes,
    ...restProps
}: MerchantPayoutsTableProps) => {
    const { downloadCsv } = useActions();

    return (
        <Table
            {...restProps}
            mode={mode}
            selectIsTableUninitialized={selectIsMerchantPayoutEntityUninitialized}
            selectIsTableLoading={selectIsMerchantPayoutEntityLoading}
            selectTableDataSlice={selectMerchantPayoutEntitySlice}
            fetchTableData={fetchTableData}
            downloadCsv={downloadCsv}
            filterTypes={filterTypes}
            Toolbar={MerchantPayoutsToolbar}
        />
    );
};

export default memo(MerchantPayoutsTable);
