import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import MuiTableCell, { type TableCellProps } from '@mui/material/TableCell';

type Props = TableCellProps & {
    readonly isLoading?: boolean;
};

const TableCell = ({ children, isLoading, ...props }: Props) => (
    <MuiTableCell
        {...props}
    >
        {isLoading
            ? (
                <Skeleton width='100%' />
            )
            : children}
    </MuiTableCell>
);

export default memo(TableCell);
