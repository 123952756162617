import { memo } from "react";
import { KYCServiceType, KYCSettingsServicePropertyName } from "consts/kyc";
import { SingleOption, useAdditionalOptions } from "../AdditionalOptions";

const AdditionalOptions = () => {
    const { onChange, value } = useAdditionalOptions({
        kycTypeId: KYCServiceType.AMLVerification
    });

    return (
        <SingleOption
            id='AMLScreening-additional-options'
            options={[
                {
                    id: KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers,
                    name: 'Include Verified Users'
                }
            ]}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(AdditionalOptions);
