import { memo, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Icon from 'ui/atoms/Icon';
import type { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { useGridApiContext } from '@mui/x-data-grid';

const EditableCell = ({ id, field, children }: PropsWithChildren<GridRenderEditCellParams<number>>) => {
    const apiRef = useGridApiContext();

    const handleClick = () => {
        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr min-content',
                gap: 1
            }}
        >
            {children}
            <IconButton
                onClick={handleClick}
            >
                <Icon name='Save' />
            </IconButton>
        </Box>
    );
};

export default memo(EditableCell);
