import { getMerchantPayoutFilesSettlementXLS } from "features/merchantPayouts/api";
import useActions from "features/merchantPayouts/useActions";
import { ActionRequestHandlers, MenuActions } from "ui/organizms/MerchantPayouts/ActionMenu";

export default function useSummaryFilesActions() {
    const { deleteMerchantPayoutSummaryFiles } = useActions();
    const getMenuItems = () => [
        MenuActions.SettlementXLS,
        MenuActions.Delete
    ];

    const getRequestMappings = () =>
        new Map<MenuActions, ActionRequestHandlers>([
            [MenuActions.Delete, { dialog: deleteMerchantPayoutSummaryFiles }],
            [MenuActions.SettlementXLS, { menu: getMerchantPayoutFilesSettlementXLS }]
        ]);

    return {
        getMenuItems,
        getRequestMappings
    };
};
