import { memo, type PropsWithChildren, type ReactNode } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Account, { type AccountProps } from "ui/molecules/Account";

type Props = {
    readonly AccountProps: AccountProps;
    readonly backButtonSlot?: ReactNode;
} & BoxProps;

const Profile = ({ children, backButtonSlot, AccountProps, ...containerProps }: PropsWithChildren<Props>) => (
    <Box
        {...containerProps}
    >
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center'
            }}
        >
            {backButtonSlot}
            <Account
                {...AccountProps}
            />
        </Box>
        {children}
    </Box>
);

export default memo(Profile);
