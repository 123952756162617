import { useMemo } from "react";
import { FormField } from "./types";
import { number, object, string } from "yup";

export function useValidationSchema() {
    return useMemo(() => object().shape({
        [FormField.AccountWithdrawalOption]: string()
            .required()
            .max(255)
            .label('Account Withdrawal Options'),
        [FormField.PayoutCycle]: string()
            .required()
            .max(255)
            .label('Payout Cycle'),
        [FormField.RollingReserveCap]: number()
            .typeError('Rolling Reserve Cap must be a number')
            .min(0)
            .max(100_000_000)
            .label('Rolling Reserve Cap')
    }), []);
};
