import { memo } from 'react';
import Card from "@mui/material/Card";
import useFetchServices from './useFetchServices';
import { useParams } from 'react-router-dom';
import { Filters } from 'consts/transactions';
import SupportPageOnInit from './SupportPageOnInit';
import SupportPageOnRedirect from './SupportPageOnRedirect';
import EmailConfirmation from './EmailConfirmation';

const Services = () => {
    const params = useParams();

    useFetchServices({
        merchantId: params[Filters.merchantId]
    });

    return (
        <Card
            sx={{
                py: 2,
                px: 3,
                boxShadow: theme => theme.shadows[5]
            }}
        >
            <SupportPageOnInit />
            <SupportPageOnRedirect />
            <EmailConfirmation />
        </Card>
    );
};

export default memo(Services);
