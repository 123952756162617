import { createSlice } from "@reduxjs/toolkit";
import {
    merchantsMethodsPivotRequestFulfilled,
    merchantsMethodsPivotRequestLoading,
    merchantsMethodsPivotRequestRejected
} from "./reducers";
import initialState, { sliceToken } from "./state";
import { getMerchantsMethodsPivotThunk } from "./thunks";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        changeMerchantsMethodsPivotLoadingState: (state, { payload }) => {
            state.merchantsMethods.loadingState = payload;
        },
        changeMerchantsMethodsPivotSlice: (state, { payload }) => {
            state.merchantsMethods.slice = payload;
        }
    },
    extraReducers:  builder => {
        builder
            .addCase(getMerchantsMethodsPivotThunk.pending, merchantsMethodsPivotRequestLoading)
            .addCase(getMerchantsMethodsPivotThunk.fulfilled, merchantsMethodsPivotRequestFulfilled)
            .addCase(getMerchantsMethodsPivotThunk.rejected, merchantsMethodsPivotRequestRejected)
    }
});

export const {
    name,
    actions: {
        changeMerchantsMethodsPivotLoadingState,
        changeMerchantsMethodsPivotSlice
    }
} = slice;

export default slice.reducer;
