import { memo, useState } from 'react';
import Checkbox, { type CheckboxProps } from "@mui/material/Checkbox";
import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";

type Props = CheckboxProps & Pick<TooltipProps, 'title'>;

const HoverableLegend = ({ title, ...checkboxProps }: Props) => {
    const [isOpen, setOpen] = useState(false);

    const onMouseEnter = () => setOpen(true);

    const onMouseLeave = () => setOpen(false);

    return (
        <Tooltip
            arrow
            title={title}
            placement='top'
            open={isOpen}
        >
            <Checkbox
                {...checkboxProps}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        </Tooltip>
    );
};

export default memo(HoverableLegend);