import React, { useState } from 'react';
import columns from './columns.json';
import aggregations from './aggregations.json';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

interface AggregationsProps {
    onUpdateAggregations: (aggregations: any[]) => void;
}

const Aggregations: React.FC<AggregationsProps> = ({ onUpdateAggregations }) => {
    const [rows, setRows] = useState<{
        dropdown1: string;
        dropdown2: string;
        aggregation: string;
    }[]>([
        { dropdown1: '', dropdown2: '', aggregation: '' }
    ]);

    const handleDropdown1Change = (e: (EventTarget & HTMLInputElement) | (EventTarget & {
        value: string;
        name: string
    }) | (null & { value: unknown; name: string }), index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].dropdown1 = e.value;
        updatedRows[index].aggregation = `aggregates[${e.value}]=${updatedRows[index].dropdown2}`;
        setRows(updatedRows);
        onUpdateAggregations(updatedRows);
    };

    const handleDropdown2Change = (e: (EventTarget & HTMLInputElement) | (EventTarget & {
        value: string;
        name: string
    }) | (null & { value: unknown; name: string }), index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].dropdown2 = e.value;
        updatedRows[index].aggregation = `aggregates[${updatedRows[index].dropdown1}]=${e.value}`;
        setRows(updatedRows);
        onUpdateAggregations(updatedRows);
    };

    const handleAddRowClick = () => {
        setRows([...rows, { dropdown1: '', dropdown2: '', aggregation: '' }]);
    };

    const handleDeleteRowClick = (index: number) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
        onUpdateAggregations(updatedRows);
    };

    const handleSubmitClick = () => {
    };

    return (
        <Container>
            Aggregations
            {rows.map((row, index) => (
                <Container key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <select
                        value={row.dropdown1}
                        onChange={(e) => handleDropdown1Change(e.target, index)}
                        style={{ marginRight: '8px' }}
                    >
                        <option value="" disabled>Select condition</option>
                        {aggregations.map(option => (
                            <option key={option.field} value={option.field}>{option.field}</option>
                        ))}
                    </select>
                    <select
                        value={row.dropdown2}
                        onChange={(e) => handleDropdown2Change(e.target, index)}
                        style={{ marginRight: '8px' }}
                    >
                        <option value="" disabled>Select column</option>
                        {columns.map(option => (
                            <option key={option.field} value={option.field}>{option.field}</option>
                        ))}
                    </select>
                    <Button onClick={() => handleDeleteRowClick(index)}>{<DeleteIcon />}</Button>
                </Container>
            ))}
            <Button onClick={handleAddRowClick}>Add Aggregation</Button>
            <Button onClick={handleSubmitClick}>Submit</Button>
        </Container>
    );
};

export default Aggregations;
