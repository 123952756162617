import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import type { ID, ResourceResponse, ThunkReturnType } from "types";
import type {
    RolesResponse,
    UserResponse,
    UsersMerchantsResponse,
    UsersMorsResponse,
    UsersResponse,
    UsersState
} from "./types";
import { compareIds } from "util/support";

export const usersRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersLoadingState = ApiState.Pending;
};

export const usersRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersResponse>>) => {
    state.usersLoadingState = ApiState.Succeeded;
    state.usersSlice = payload!.data;
};

export const userByIdRequestFilfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UserResponse>>
) => {
    state.usersLoadingState = ApiState.Succeeded;
    let isUpdated = false;

    if (!state.usersSlice) {
        return;
    }

    state.usersSlice.data = state.usersSlice.data.map(userEntity => {
        if (compareIds(userEntity.coreId, payload!.data.coreId)) {
            isUpdated = true;

            return {
                ...userEntity,
                ...payload!.data
            };
        }

        return userEntity;
    });

    if (!isUpdated) {
        state.usersSlice.data.unshift(payload!.data);
    }
};

export const usersRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersLoadingState = ApiState.Failed;
    // state.usersSlice = null;
};

export const rolesRequestLoading = (state: WritableDraft<UsersState>) => {
    state.rolesLoadingState = ApiState.Pending;
};

export const rolesRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<RolesResponse>>) => {
    state.rolesLoadingState = ApiState.Succeeded;
    state.rolesSlice = payload!.data;
};

export const rolesRequestRejected = (state: WritableDraft<UsersState>) => {
    state.rolesLoadingState = ApiState.Failed;
    state.rolesSlice = null;
};

export const usersMerchantsRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersMerchantsLoadingState = ApiState.Pending;
};

export const usersMerchantsRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersMerchantsResponse>>) => {
    state.usersMerchantsLoadingState = ApiState.Succeeded;
    state.usersMerchantsSlice = payload!.data;
};

export const usersMerchantsRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersMerchantsLoadingState = ApiState.Failed;
    state.usersMerchantsSlice = null;
};

export const usersMorsRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersMorsLoadingState = ApiState.Pending;
};

export const usersMorsRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersMorsResponse>>) => {
    state.usersMorsLoadingState = ApiState.Succeeded;
    state.usersMorsSlice = payload!.data;
};

export const usersMorsRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersMorsLoadingState = ApiState.Failed;
    state.usersMorsSlice = null;
};

export const deleteUserRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ResourceResponse<ID>>
) => {
    state.usersLoadingState = ApiState.Succeeded;
    state.usersSlice!.data = state.usersSlice!.data.filter(userEntity =>
        !compareIds(userEntity.coreId, payload.data)
    );
    state.usersSlice!.total -= 1;
};
