import { memo } from "react";
import { OptionableAutocomplete } from "ui/atoms/Autocomplete";
import type { AdditionalOptionsProps } from "./types";
import type { getAdditionalOptionsStatus } from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/getters/additionalOptions";
import { getStatuses } from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/getters/autocompleteOptions";

const Statuses = ({ value, onChange }: AdditionalOptionsProps<
    ReturnType<typeof getAdditionalOptionsStatus>
>) => {
    const getValue = () => {
        if (!value?.id) {
            return [];
        }

        return value.id.split(',').map(status => {
            const id = status.trim();

            return {
                id,
                name: id
            };
        });
    };

    return (
        <OptionableAutocomplete
            multiple
            options={getStatuses()}
            TextFieldProps={{
                sx: {
                    minWidth: 300
                }
            }}
            value={getValue()}
            onChange={onChange}
        />
    );
};

export default memo(Statuses);