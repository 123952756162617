import { memo } from "react";
import { selectIsMerchantsModelLoading, selectActiveMerchants } from "features/merchants/selectors";
import { useTypedSelector } from "hooks";
import Cassette from "../Cassette";

const Active = () => {
    const activeMerchants = useTypedSelector(selectActiveMerchants);

    return (
        <Cassette
            isLoading={useTypedSelector(selectIsMerchantsModelLoading)}
            labelSlot='Active Merchants'
            amountSlot={activeMerchants.length}
        />
    );
};

export default memo(Active);
