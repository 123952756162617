import { useDomainRepository } from "hooks";
import { MerchantDetailTab } from "./types";
import useFetchResource from "hooks/useFetchResource";
import { PER_PAGE_SIZE } from "consts/transactions";
import {
    getMerchantsMethodsPivot,
    getMerchantsMethodsPivotHistory,
    updateMerchantsMethodsPivot
} from "features/pivots/api";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import {
    createOrUpdateMerchantBusiness,
    createOrUpdateMerchantDomain,
    createOrUpdatePayoutAccount,
    createOrUpdatePayoutCryptoAccount,
    deleteMerchantBusiness,
    deleteMerchantDomain,
    deletePayoutAccount,
    deletePayoutCryptoAccount,
    getMerchantBusinesses,
    getMerchantDomains,
    getPayoutAccounts,
    getPayoutCryptoAccounts
} from "features/merchants/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { AccountType, Filters } from "consts/merchants";
import useMerchantDetail from "./useMerchantDetail";
import { useRepository } from "./repository";
import { useSimpleRequest, type UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import { getAccountType } from "features/merchants/helpers";
import type { Merchant, MerchantBusiness, MerchantDomain, MerchantPayoutAbstractAccount } from "features/merchants/types";
import type { MerchantProfileFinance } from "ui/forms/Merchant/ProfileFinance";
// MerchantProfile
import {
    reducer as merchantProfileReducer,
    stateFactory as merchantProfileStateFactory,
    Repository as MerchantProfileRepository,
    MerchantProfileActionType,
    type MerchantProfileRepositoryState
} from "./MerchantProfile/repository";
// MerchantUsers
import {
    reducer as merchantUsersReducer,
    stateFactory as merchantUsersStateFactory,
    Repository as MerchantUsersRepository,
    MerchantUsersActionType,
    type MerchantUsersRepositoryState
} from "./MerchantUsers/repository";
// MerchantAccountSettings
import {
    reducer as merchantAccountSettingsReducer,
    stateFactory as merchantAccountSettingsStateFactory,
    Repository as MerchantAccountSettingsRepository,
    MerchantAccountSettingsActionType,
    type MerchantAccountSettingsRepositoryState
} from "./MerchantAccountSettings/repository";
// MerchantPaymentMethods
import {
    reducer as merchantPaymentMethodsReducer,
    stateFactory as merchantPaymentMethodsStateFactory,
    Repository as MerchantPaymentMethodsRepository,
    MerchantPaymentMethodsActionType,
    type MerchantPaymentMethodsRepositoryState
} from "./MerchantPaymentMethods/repository";
// MerchantDomains
import {
    reducer as merchantDomainsReducer,
    stateFactory as merchantDomainsStateFactory,
    Repository as MerchantDomainsRepository,
    MerchantDomainsActionType,
    type MerchantDomainsRepositoryState
} from "./MerchantDomains/repository";

export default function useMerchantDetailRepository() {
    const fetchResourceSettings = {
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE
    };

    const getPayoutAccountsRequest = useFetchResource(fetchResourceSettings);
    const getPayoutCryptoAccountsRequest = useFetchResource(fetchResourceSettings);
    const getMerchantsMethodsPivotRequest = useFetchResource(fetchResourceSettings);
    const getMerchantsMethodsPivotHistoryRequest = useFetchResource({
        ...fetchResourceSettings,
        perPage: PER_PAGE_SIZE ** 2
    });
    const getMerchantDomainsRequest = useFetchResource(fetchResourceSettings);
    const getMerchantBusinessesRequest = useFetchResource(fetchResourceSettings);

    const request = useSimpleRequest();

    const { merchant } = useMerchantDetail();

    const merchantDetailRepository = useRepository(merchant);

    return new Map()
        .set(MerchantDetailTab.Profile, useDomainRepository<
            MerchantProfileRepositoryState,
            MerchantProfileActionType
        >({
            reducer: merchantProfileReducer,
            initializerArg: merchantProfileStateFactory(),
            factory: (state, dispatch) => new MerchantProfileRepository(
                state,
                dispatch,
                merchantDetailRepository.inject(),
                () => getPayoutAccountsRequest((queryParams?: string) => getPayoutAccounts(
                    RequestQueryMapper.from(queryParams)
                        .contains(Filters.merchantId, String(merchant.coreId))
                        .toString()
                )),
                () => getPayoutCryptoAccountsRequest((queryParams?: string) => getPayoutCryptoAccounts(
                    RequestQueryMapper.from(queryParams)
                        .contains(Filters.merchantId, String(merchant.coreId))
                        .toString()
                )),
                (account: MerchantProfileFinance) => request<MerchantPayoutAbstractAccount>(() =>
                    new Map()
                        .set(AccountType.Bank, createOrUpdatePayoutAccount)
                        .set(AccountType.Crypto, createOrUpdatePayoutCryptoAccount)
                        .get(getAccountType(account))({
                            ...account,
                            merchantId: merchant.coreId
                        })
                    , { notifyOnSuccess: true }),
                (account: MerchantPayoutAbstractAccount) => request(() =>
                    new Map()
                        .set(AccountType.Bank, createOrUpdatePayoutAccount)
                        .set(AccountType.Crypto, createOrUpdatePayoutCryptoAccount)
                        .get(getAccountType(account))(account)
                ),
                (account: MerchantPayoutAbstractAccount) => request(() =>
                    new Map()
                        .set(AccountType.Bank, deletePayoutAccount)
                        .set(AccountType.Crypto, deletePayoutCryptoAccount)
                        .get(getAccountType(account))(account.coreId)
                    , { notifyOnSuccess: true })
            )
        }))
        .set(MerchantDetailTab.Users, useDomainRepository<
            MerchantUsersRepositoryState,
            MerchantUsersActionType
        >({
            reducer: merchantUsersReducer,
            initializerArg: merchantUsersStateFactory(),
            factory: (state, dispatch) => new MerchantUsersRepository(
                state,
                dispatch,
                merchantDetailRepository.inject()
            )
        }))
        .set(MerchantDetailTab.AccountSettings, useDomainRepository<
            MerchantAccountSettingsRepositoryState,
            MerchantAccountSettingsActionType
        >({
            reducer: merchantAccountSettingsReducer,
            initializerArg: merchantAccountSettingsStateFactory(),
            factory: (state, dispatch) => new MerchantAccountSettingsRepository(
                state,
                dispatch,
                merchantDetailRepository.inject()
            )
        }))
        .set(MerchantDetailTab.PaymentMethods, useDomainRepository<
            MerchantPaymentMethodsRepositoryState,
            MerchantPaymentMethodsActionType
        >({
            reducer: merchantPaymentMethodsReducer,
            initializerArg: merchantPaymentMethodsStateFactory(),
            factory: (state, dispatch) => new MerchantPaymentMethodsRepository(
                state,
                dispatch,
                () => getMerchantsMethodsPivotRequest((queryParams?: string) => getMerchantsMethodsPivot(
                    RequestQueryMapper.from(queryParams)
                        .containsIn(Filters.merchantId, String(merchant.coreId))
                        .toString()
                )),
                ({ methodId }: Pick<MerchantsMethodsPivot, 'methodId'>) => getMerchantsMethodsPivotHistoryRequest((queryParams?: string) => getMerchantsMethodsPivotHistory(
                    RequestQueryMapper.from(queryParams)
                        .containsIn(Filters.merchantId, String(merchant.coreId))
                        .containsIn(Filters.methodId, String(methodId))
                        .toString()
                )),
                (merchantsMethodsPivot: Pick<
                    MerchantsMethodsPivot,
                    | 'coreId'
                > & Partial<Omit<MerchantsMethodsPivot, 'coreId'>>) => request(() =>
                    updateMerchantsMethodsPivot(merchantsMethodsPivot)
                )
            )
        }))
        .set(MerchantDetailTab.Domains, useDomainRepository<
            MerchantDomainsRepositoryState,
            MerchantDomainsActionType
        >({
            reducer: merchantDomainsReducer,
            initializerArg: merchantDomainsStateFactory(),
            factory: (state, dispatch) => new MerchantDomainsRepository(
                state,
                dispatch,
                (requestQueryMapper: RequestQueryMapper) => getMerchantDomainsRequest((queryParams: string = '') => getMerchantDomains(
                    requestQueryMapper
                        .concat(queryParams)
                        .toString()
                )),
                (merchant?: Partial<Merchant>) => getMerchantBusinessesRequest((queryParams?: string) => getMerchantBusinesses(
                    merchant?.coreId
                        ? RequestQueryMapper.from(queryParams)
                            .containsIn(Filters.merchantId, String(merchant.coreId))
                            .toString()
                        : queryParams
                )),
                (merchantBusiness: Partial<MerchantBusiness>, requestSettings?: UseSimpleRequestReturnCallbackArg) => request(() =>
                    createOrUpdateMerchantBusiness(merchantBusiness),
                    requestSettings
                ),
                (merchantDomain: Partial<MerchantDomain>, requestSettings?: UseSimpleRequestReturnCallbackArg) => request(() =>
                    createOrUpdateMerchantDomain(merchantDomain),
                    requestSettings
                ),
                (merchantBusinessId: MerchantBusiness['coreId']) => request(() =>
                    deleteMerchantBusiness(merchantBusinessId),
                    { notifyOnSuccess: true }
                ),
                (merchantDomainId: MerchantDomain['coreId']) => request(() =>
                    deleteMerchantDomain(merchantDomainId),
                    { notifyOnSuccess: true }
                )
            )
        }));
};
