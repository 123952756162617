import {
    memo,
    type PropsWithChildren,
    type ReactNode
} from "react";
import { AdvancedTabLabel } from "ui/molecules/Label";
import type { Scalar } from "types";
import type { useFormableTab } from "../hooks";

type Props =
    & Pick<
        ReturnType<typeof useFormableTab>,
        | 'tabManager'
        | 'selectState'
    >
    & {
        readonly value: Scalar<string>;
        readonly tab: Scalar<string>;
        readonly message?: ReactNode;
    };

const TabLabel = ({
    value,
    tab,
    children,
    message,
    tabManager,
    selectState
}: PropsWithChildren<Props>) => (
    <AdvancedTabLabel
        isError={(
            !Object.is(tabManager.getTab(value), tab) &&
            !selectState(tab).isValid
        )}
        message={message}
    >
        {children ?? tab}
    </AdvancedTabLabel>
);

export default memo(TabLabel);
