import { memo } from 'react';
import Box from "@mui/material/Box";
import Fade from '@mui/material/Fade';
import Card from "@mui/material/Card";
import type { TransactionStatsCard } from './types';

const StatisticCard = ({ value, palette, title }: TransactionStatsCard) => (
    <Fade in>
        <Card
            sx={{
                p: '15px 20px',
                fontFamily: 'var(--manrope-font)',
                bgcolor: palette,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <Box
                sx={{
                    fontSize: '20px',
                    lineHeight: '27px',
                }}
            >
                {title}
            </Box>
            <Box
                sx={{
                    fontWeight: 700,
                    fontSize: '32px',
                    lineHeight: '44px',
                }}
            >
                {value}
            </Box>
        </Card>
    </Fade>
);

export default memo(StatisticCard);
