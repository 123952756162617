import { memo } from "react";
import { selectMerchantsTotalCount, selectIsMerchantsModelLoading } from "features/merchants/selectors";
import { useTypedSelector } from "hooks";
import Cassette from "../Cassette";

const Total = () => (
    <Cassette
        labelSlot='Total Merchants'
        isLoading={useTypedSelector(selectIsMerchantsModelLoading)}
        amountSlot={useTypedSelector(selectMerchantsTotalCount)}
    />
);

export default memo(Total);
