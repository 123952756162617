import { ResponseCode } from "consts/api";
import { Locale } from "consts/general";
import { ApiState } from "infrastructure/api";
import type { PaginateResourceResponse, ResourceResponse } from "types";

type RequestStatus = "rejected" | 'fulfilled';

type Meta<TRequestArg, TRequestStatus = RequestStatus> = {
    readonly requestId: string
    readonly arg: TRequestArg
    readonly aborted: boolean;
    readonly requestStatus: TRequestStatus;
    readonly condition: boolean;
};

type ThunkApiConfig<TRequestArg, TResource> = {
    readonly fulfillWithValue: (
        resolveWith: ResourceResponse<TResource>,
        meta?: Meta<TRequestArg, 'fulfilled'>
    ) => void
    readonly rejectWithValue: (
        rejectWith: ResourceResponse<TResource> | Response,
        meta?: Meta<TRequestArg, 'rejected'>
    ) => void
};

export function payloadCreator<TRequestPayload, TRequestTransformed, TResource>(
    apiService: (requestPayload: TRequestTransformed | TRequestPayload) => Promise<Response>,
    applyTransformers?: (requestPayload: TRequestPayload) => TRequestTransformed
) {
    return async (
        requestPayload: TRequestPayload,
        { rejectWithValue, fulfillWithValue }: ThunkApiConfig<
            TRequestTransformed | TRequestPayload,
            TResource
        >
    ) => {
        let transformedRequestPayload!: TRequestTransformed;
        if (typeof applyTransformers === 'function') {
            transformedRequestPayload = applyTransformers(requestPayload);
        }

        const response = await apiService(
            (transformedRequestPayload ?? requestPayload)
        );

        if (response.ok) {
            const payload: ResourceResponse<TResource> = await response.json();

            if (payload.success) {
                fulfillWithValue(payload);
                return payload;
            }

            return rejectWithValue(payload);
        }

        rejectWithValue(response.clone());
    };
}

export function getApiStateFromResponse<T>(
    resourceResponse: ResourceResponse<T>,
    apiState: ApiState
) {
    return ResponseCode.Unathenticated === resourceResponse.code
        ? ApiState.Idle
        : apiState;
};

export function processJsonResponse<T = any>(response: Response) {
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json() as T;
};

export class ManagementApiResponseFactory {
    public static make<T = null>({
        success = false,
        code = 0,
        locale = Locale.En,
        message = '',
        ...rest
    }: Partial<ResourceResponse<T>> = {}): ResourceResponse<T> {
        return {
            data: null as T,
            ...rest,
            success,
            code,
            locale,
            message
        };
    }
}

export class ManagementApiResponseAdapter {
    public static from<TDataType>(
        responsePayload: unknown,
        { ok, status, statusText }: Pick<
        Response,
        | 'ok'
        | 'status'
        | 'statusText'
    >): ResourceResponse<TDataType> {
        const invariantResponseAttributes = [
            'success',
            'code',
            'locale',
            'message',
            'data'
        ] as const;

        if (
            Array.from<keyof ResourceResponse<TDataType>>(
                invariantResponseAttributes
            ).some(key =>
                !Object.keys(Object(responsePayload))
                    .includes(key))
        ) {
            return ManagementApiResponseFactory.make<TDataType>({
                message: statusText,
                success: ok,
                code: status,
                data: responsePayload as TDataType
            });
        }

        return responsePayload as ResourceResponse<TDataType>;
    }
}

export const fromPaginateResourceResponse = <T>(
    paginateResourceResponse?: PaginateResourceResponse<T> | null,
    fallback: Array<T> = []
): Array<T> => (
    paginateResourceResponse
        ?.data
        .data || fallback
);
