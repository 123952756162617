import { ApiState } from "infrastructure/api";
import type { WritableDraft } from "immer/dist/internal";
import type { TransactionsResponse, TransactionsState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { getApiStateFromResponse } from "util/api";
import { ResourceResponse, ThunkReturnType } from "types";

export const transactionsRequestLoading = (state: WritableDraft<TransactionsState>) => {
    state.transactionsLoadingState = ApiState.Pending;
};

export const transactionsRequestFulfilled = (
    state: WritableDraft<TransactionsState>,
    { payload }: PayloadAction<ThunkReturnType<TransactionsResponse>>
) => {
    state.transactionsLoadingState = ApiState.Succeeded;
    state.transactionSlice = payload!.data;
};

export const transactionsRequestRejected = (
    state: WritableDraft<TransactionsState>,
    { payload }: PayloadAction<unknown>
) => {
    state.transactionsLoadingState = getApiStateFromResponse(
        payload as ResourceResponse<null>,
        ApiState.Failed
    );
};
