import { useEffect, useRef, useState } from "react";
import type { Scalar } from "types";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";
import {
    aggregateFormableRecord,
    fromFormableRecord,
    hasActiveChanges as hasActiveChangesFormableRecord,
    canSubmit as canSubmitFormableRecord,
    validate as validateFormableRecord,
    resetActiveChanges as resetActiveChangesFormableRecord,
    type FormableRecord
} from "util/forms";
import { TabManager } from "util/tab";

type UseFormableTabArg<TTab> = {
    readonly entries: readonly [string, TTab][];
    readonly state: FormableRecord;
};

export default function useFormableTab<TTab>({
    entries,
    state
}: UseFormableTabArg<TTab>) {
    const {
        addUnsavedChangesSubscriber,
        removeUnsavedChangesSubscriber
    } = useUnsavedChangesContext();

    const effectHelpers = {
        state,
        removeUnsavedChangesSubscriber
    };
    const effectHelpersRef = useRef(effectHelpers);
    effectHelpersRef.current = effectHelpers;

    const [tabManager] = useState(() => TabManager.fromEntries<TTab>(entries));

    const [activeTab, setActiveTab] = useState(() => tabManager.getKeys().at(0)!);

    const selectState = (tab: Scalar<string>) =>
        fromFormableRecord(state, tab);

    const hasActiveChanges = () =>
        hasActiveChangesFormableRecord(state);

    const canSubmit = () =>
        canSubmitFormableRecord(state);

    const resetActiveChanges = () =>
        resetActiveChangesFormableRecord(state);

    const validate = () =>
        validateFormableRecord(state);

    const onChange = (activeTab: string) => {
        validate();
        setActiveTab(activeTab);
    };

    aggregateFormableRecord(state, ([tab]) =>
        addUnsavedChangesSubscriber([
            state[tab].subscriberKey,
            hasActiveChanges
        ])
    );

    useEffect(() => () => {
        const {
            state,
            removeUnsavedChangesSubscriber
        } = effectHelpersRef.current;

        aggregateFormableRecord(state, ([tab]) =>
            removeUnsavedChangesSubscriber(
                state[tab].subscriberKey
            )
        );
    }, []);

    return {
        message: 'Please fill in all required fields',
        activeTab,
        tabManager,
        hasActiveChanges,
        selectState,
        canSubmit,
        resetActiveChanges,
        validate,
        onChange
    };
};
