import type { UserResponse } from "features/users/types";
import { useRequest } from "hooks";
import type { ResourceResponse, ThunkReturnType } from "types";
import { loginThunk, logoutThunk, userThunk } from "./thunk";
import type { AuthResponse, UserCredentials } from "./types";

const useActions = () => {
  const login = useRequest<ThunkReturnType<AuthResponse>, UserCredentials>({
    thunk: loginThunk
  });

  const getUser = useRequest<ThunkReturnType<UserResponse>, void>({
    thunk: userThunk
  });

  const logout = useRequest<ThunkReturnType<ResourceResponse<void>>, void>({
    thunk: logoutThunk
  });

  return {
    login,
    getUser,
    logout,
  };
};

export default useActions;
