import { ResponseSettlementStatus } from "consts/api";
import type { ResourceResponse } from "types";

export const getPromiseSettledResourceResult = <TResource>(
    promiseSettledResult: PromiseSettledResult<
        ResourceResponse<TResource> | null | undefined
    >
) => {
    if (promiseSettledResult.status === ResponseSettlementStatus.Fullfilled) {
        return promiseSettledResult.value;
    }

    return null;
};
