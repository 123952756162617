import withGridCellParams from "./withGridCellParams";
import Icon from "ui/atoms/Icon";

const BooleanStatus = withGridCellParams<boolean>(({ value }) => {
    if (value) {
        return (
            <Icon
                name="Check"
                color='success'
            />
        );
    }

    return (
        <Icon
            name='Close'
            color='error'
        />
    );
});

export default BooleanStatus;
