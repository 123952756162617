import { UserRolesNames } from "consts/auth";
import { RootState } from "infrastructure/store";

export const selectAuthStatus = (state: RootState) => state.auth.status;

export const selectAuthUser = (state: RootState) => state.auth.user;

export const selectIsNotMor = (state: RootState) => Boolean(
    state.auth.user &&
    ![UserRolesNames.Mors]
        .includes(state.auth.user.roleName)
);
