import { memo } from "react";
import Box from "@mui/material/Box";
import InfoUnit from "./InfoUnit";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const Info = (props: MerchantsMethodsPivot) => (
    <Box
        sx={{
            display: 'grid',
            gap: 2
        }}
    >
        <InfoUnit
            properties={[
                ['enabled', enabled =>
                    enabled
                        ? 'Enabled'
                        : 'Disabled'
                ],
                ['createdAt']
            ]}
            merchantMethod={props}
        >
            Merchant Method State
        </InfoUnit>
        <InfoUnit
            properties={[['paymentMethodTag']]}
            merchantMethod={props}
        >
            Tag
        </InfoUnit>
        <InfoUnit
            properties={[['paymentMethodForCountries']]}
            merchantMethod={props}
        >
            Countries available
        </InfoUnit>
        <InfoUnit
            properties={[['paymentMethodForCurrencies']]}
            merchantMethod={props}
        >
            Currencies Available
        </InfoUnit>
    </Box>
);

export default memo(Info);
