import type { Option } from "types";
import { isOption } from "util/option";
import type { AutocompleteProps } from "ui/widgets/Filters/components";
import type { Filter } from "ui/widgets/Filters/types";
import { compareIds } from "util/support";

const dropdown = (filter: Filter<AutocompleteProps>, entity: Record<string, unknown>) => {
    const { id, value } = filter.props;

    switch (true) {
        case !value: {
            return true;
        }

        case isOption(value): {
            return compareIds(value.id, String(entity[id]));
        }

        case Array.isArray(value): {
            return value.length
                ? value.some((option: Option) => (
                    isOption(option) &&
                    compareIds(option.id, String(entity[id]))
                ))
                : true;
        }

        default: {
            return false;
        }
    }
};

export default dropdown;
