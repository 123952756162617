import { memo } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "ui/atoms/Card";
import Typography from "@mui/material/Typography";
import useTopMerchants from "./useTopMerchants";
import Amount from "./Amount";
import TableCell from "./TableCell";

const TopMerchants = () => {
    const {
        isLoading,
        getTopMerchantSucceededTransactionsByUniqueEmail
    } = useTopMerchants();

    return (
        <Card
            sx={{
                p: 3
            }}
        >
            <Typography
                variant='h6'
                component='div'
            >
                Top Merchants
            </Typography>
            <TableContainer
                sx={{
                    mt: 3
                }}
                component={Paper}
            >
                <Table aria-label='top merchants table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell
                                align='right'
                            >
                                Succeeded trx.
                            </TableCell>
                            <TableCell
                                align='right'
                            >
                                Amount
                            </TableCell>
                            {/* <TableCell
                                align='right'
                            >
                                Unique emails
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTopMerchantSucceededTransactionsByUniqueEmail()
                            .map(({
                                name,
                                succeededTransactions,
                                amount,
                                // uniqueEmails
                            }, index) => (
                                <TableRow
                                    key={String(index)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell
                                        isLoading={isLoading}
                                        component='th'
                                        scope='row'
                                        sx={{
                                            fontWeight: 600
                                        }}
                                    >
                                        {name}
                                    </TableCell>
                                    <TableCell
                                        isLoading={isLoading}
                                        align='right'
                                    >
                                        {succeededTransactions}
                                    </TableCell>
                                    <TableCell
                                        isLoading={isLoading}
                                        align='right'
                                    >
                                        <Amount
                                            amount={amount}
                                        />
                                    </TableCell>
                                    {/* <TableCell
                                        isLoading={isLoading}
                                        align='right'
                                    >
                                        {uniqueEmails}
                                    </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default memo(TopMerchants);
