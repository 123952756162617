import { memo } from "react";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { selectIsExportMerchantLedgersLoading } from "features/merchants/selectors";
import { TableToolbar } from "ui/widgets/Table";
import AddEntity from "ui/organizms/Merchants/Merchants/shared/AddEntity";

type Props = {
    readonly onExport: () => void;
};

const Add = () => (
    <AddEntity
        size='small'
        startIcon={<AccountBalanceWalletIcon />}
    >
        Add Ledger
    </AddEntity>
);

const LedgerResourceToolbar = ({ onExport }: Props) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportMerchantLedgersLoading}
        add={Add}
    />
);

export default memo(LedgerResourceToolbar);
