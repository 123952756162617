import {
    useTicketingSystemActionsContext,
    useTicketingSystemStateSelectorsContext
} from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider";
import { TicketingSystemService } from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/types";

export default function useServiceEnable(service: TicketingSystemService) {
    const { selectTicketingSystemEnabledState } = useTicketingSystemStateSelectorsContext();
    const { updateTicketingSystemSettingsEnabledState } = useTicketingSystemActionsContext();

    return {
        value: selectTicketingSystemEnabledState(service),
        onChange: (isEnabled: boolean) =>
            updateTicketingSystemSettingsEnabledState({ service, isEnabled })
    };
};
