import {
    memo,
    useState,
    MouseEvent,
    FC,
    PropsWithChildren,
    forwardRef,
    useImperativeHandle,
    useCallback
} from 'react';
import type { ButtonProps } from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';

export type MenuImperativeProps = {
    readonly handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
    readonly handleClose: () => void;
};

type Props = Omit<MenuProps, 'open'>
    & {
        readonly onOpen?: (event: MouseEvent<HTMLButtonElement>) => void;
        readonly onClose?: () => void;
        readonly ActionControl: FC<ButtonProps>;
        readonly RootProps?: BoxProps;
    };

const Menu = forwardRef(({
    children,
    ActionControl,
    onOpen,
    onClose,
    RootProps = {},
    ...restProps
}: PropsWithChildren<Props>, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        onOpen?.(event);
    }, [onOpen]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        onClose?.();
    }, [onClose]);

    useImperativeHandle(ref, () => ({
        handleOpen,
        handleClose
    }), [handleOpen, handleClose]);

    return (
        <Box
            {...RootProps}
        >
            <ActionControl
                // id="basic-button"
                // aria-controls={open ? 'basic-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
            />
            <MuiMenu
                // id="basic-menu"
                {...restProps}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
            // MenuListProps={{
            //     'aria-labelledby': 'basic-button',
            // }}
            >
                <MenuList>
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                    {children}
                </MenuList>
            </MuiMenu>
        </Box>
    );
});

export default memo(Menu);
