import { memo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Props } from "./Props";
import useOptionController from "./useOptionController";

const SingleOption = ({ id, options, value, onChange }: Props) => {
    const { isSelected, handleChange } = useOptionController({
        value,
        onChange,
    });

    return (
        <>
            {options.map(option => (
                <FormControlLabel
                    key={option.id}
                    id={id}
                    sx={{
                        whiteSpace: 'nowrap'
                    }}
                    control={(
                        <Checkbox
                            name={String(option.id)}
                            checked={isSelected(option)}
                            onChange={handleChange}
                        />
                    )}
                    label={option.name}
                />
            ))}
        </>
    );
};

export default memo(SingleOption);
