import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { AccordionProps } from "ui/atoms/Accordion";

type Props = Pick<AccordionProps, 'expanded'>;

const MerchantBusinessLabel = ({ expanded }: Props) => (
    <Typography
        sx={{
            color: 'primary.main',
            fontSize: '.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            textAlign: 'right',
            pr: 1
        }}
    >
        {expanded ? 'Hide' : 'Manage'} Domains
    </Typography>
);

export default memo(MerchantBusinessLabel);
