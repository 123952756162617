import type { NumericRangeValue } from "./types";

export const enum ValidationStrategy {
    Min = 'min',
    Max = 'max'
};

type ValidationMessage = string | undefined;
type Strategy = (value: number, comparable: number) => ValidationMessage;

export default function useValidator([min, max]: NumericRangeValue) {
    const withExistedRangeValue = (fn: Strategy, comparable: number) => (value: number) => {
        if (['undefined', 'null', ''].includes(`${comparable}`)) {
            return '';
        }

        return fn(value, comparable) ?? '';
    }

    const validationStrategyRegistry = new Map<
        ValidationStrategy,
        (value: number) => string
    >([
        [ValidationStrategy.Min, withExistedRangeValue((value, comparable) => {
            if (value > comparable) {
                return `Value must be less or equal than ${comparable}`;
            }
        }, max)],
        [ValidationStrategy.Max, withExistedRangeValue((value, comparable) => {
            if (value < comparable) {
                return `Value must be greater or equal than ${comparable}`;
            }
        }, min)]
    ]);

    return (validationStrategy: ValidationStrategy) => {
        const validator = validationStrategyRegistry.get(validationStrategy);
        if (!validator) {
            throw new Error(`Unknown validation strategy: ${validationStrategy}`);
        }

        return validator;
    };
};
