import { KYCServiceType } from "consts/kyc";
import getKYCAdditionalSettingsByTypeId from "./getKYCAdditionalSettingsByTypeId";

export default function getKYCServices() {
    return {
        [KYCServiceType.FaceVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: []
        },
        [KYCServiceType.EIDVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: [],
            isActive: false
        },
        [KYCServiceType.IDVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: getKYCAdditionalSettingsByTypeId(KYCServiceType.IDVerification)
        },
        [KYCServiceType.AMLVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: getKYCAdditionalSettingsByTypeId(KYCServiceType.AMLVerification)
        },
        [KYCServiceType.AddressVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: getKYCAdditionalSettingsByTypeId(KYCServiceType.AddressVerification)
        },
        [KYCServiceType.PhoneVerification]: {
            kycLimit: null,
            summedAmountEurLimit: 0,
            kycSettings: getKYCAdditionalSettingsByTypeId(KYCServiceType.PhoneVerification)
        }
    };
};
