import { FilterValueProvider, TValueStrategy, ValueStrategy } from "./types";

class TextFilterValueProvider extends FilterValueProvider<string> {
    static getDefaultValue() {
        return '';
    }

    public getInitialValue(values: Array<string>) {
        let value = this.getDefaultValue();

        if (values.length > 0) {
            const [v] = values;

            value = v;
        }

        return value;
    }

    public handleChangeValue(value: string) {
        return value;
    }
}

const textFilterValueStrategy: TValueStrategy<string> = {
    [ValueStrategy.Default]:
        new TextFilterValueProvider(
            TextFilterValueProvider.getDefaultValue
        )
};

export default textFilterValueStrategy;
