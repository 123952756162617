import type { FormikValues } from "formik";
import type { ID, Option } from "types";
import type { User } from "features/users/types";
import { isEmployee, isMerchant, isMors, isMorsAgent, isMerchantsAgent } from "features/auth/helpers";
import { getOptionById, getOptionName } from "util/option";

export const isRoleEnabled = (userRoleName?: User['roleName']) =>
    !isEmployee(`${userRoleName}`);

export const isMerchantRequired = (roles: Array<Option>) => (roleId: Option['id']) => {
    const roleOption = getOptionById(roles, roleId);

    return isMerchant( getOptionName(roleOption)) || isMerchantsAgent(getOptionName(roleOption));
};
export const isDomainRequired = (roles: Array<Option>) => (roleId: Option['id']) => {
    const roleOption = getOptionById(roles, roleId);

    return isMors( getOptionName(roleOption)) || isMorsAgent(getOptionName(roleOption));
};

export const isMerchantEnabled = (roles: Array<Option>, userRoleName?: User['roleName']) =>
    ({ roleId }: FormikValues) => Boolean(
        roleId &&
        isRoleEnabled(userRoleName) &&
        isMerchantRequired(roles)(roleId)
    );

export const isMorEnabled = (roles: Array<Option>, userRoleName?: User['roleName']) =>
({ roleId }: FormikValues) =>
    Boolean(
        roleId &&
        isRoleEnabled(userRoleName)&&
        isDomainRequired(roles)(roleId)

    );
export const getDomainsOptions = (roles: Option[], domainOptions: Option[]) =>
    ({ roleId }: FormikValues) => {
        const roleOption = getOptionById(roles, roleId);
        return (isMorsAgent(getOptionName(roleOption)) || isMors(getOptionName(roleOption))) ?
            domainOptions.filter(item => item.id !== 'default') :
            domainOptions;
    };

export const isMorMultiple = (_?: ID | null) => false;

export const isMerchantMultiple = (roles: Array<Option>, { roleId }: FormikValues) => {
    const roleOption = getOptionById(roles, roleId); 
    return !isMerchant(getOptionName(roleOption))
}
