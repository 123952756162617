import { ReactNode, memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import type { MerchantKYCService } from "features/merchants/types";
import useService from "./useService";
import { KYCServiceState } from "consts/kyc";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";

export type ServiceProps = {
    readonly serviceLabel: ReactNode;
    readonly sx?: BoxProps['sx'];
    readonly additionalOptionsSlot?: ReactNode;
} & Pick<MerchantKYCService, 'kycTypeId'>;

const Service = ({
    serviceLabel,
    kycTypeId,
    additionalOptionsSlot,
    sx = {}
}: ServiceProps) => {
    const {
        isFetchingProcessing,
        serviceState,
        transactionsLimit,
        summedAmountEurLimit,
        onServiceStateChange,
        onTransactionsLimitChange,
        onSummedAmountEurLimitBlur,
        onSummedAmountEurLimitChange,
        isTransactionLimitDisabled
    } = useService({ kycTypeId });

    return (
        <Box
            sx={{
                ...sx,
                py: '20px',
                mt: '10px',
                borderTop: '1px solid',
                borderColor: 'divider'
            }}
        >
            <Box
                sx={{
                    fontSize: '1rem',
                    whiteSpace: 'nowrap',
                    fontWeight: 600
                }}
            >
                {serviceLabel}
            </Box>
            {isFetchingProcessing
                ? (
                    <>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant='rounded'
                                width='100%'
                                height={40}
                            />
                        ))}
                    </>
                )
                : (
                    <>

                        <Select
                            value={serviceState}
                            onChange={onServiceStateChange}
                            size='small'
                        >
                            <MenuItem value={KYCServiceState.Off}>Off</MenuItem>
                            <MenuItem value={KYCServiceState.Strict}>Strict</MenuItem>
                            <MenuItem value={KYCServiceState.Soft}>Soft</MenuItem>
                        </Select>
                        <TextField
                            value={transactionsLimit}
                            onChange={onTransactionsLimitChange}
                            disabled={isTransactionLimitDisabled(serviceState)}
                            size='small'
                        />
                        <TextField
                            value={summedAmountEurLimit}
                            onChange={onSummedAmountEurLimitChange}
                            onBlur={onSummedAmountEurLimitBlur}
                            disabled={isTransactionLimitDisabled(serviceState)}
                            size='small'
                        />
                        <Box>
                            {additionalOptionsSlot}
                        </Box>
                    </>
                )}
        </Box>
    );
};

export default memo(Service);
