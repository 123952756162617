export const Filters = {
    channel: 'channel'
};

export const enum NotificationType {
    In = 'IN',
    Out = 'OUT'
}

export const enum NotificationChannel {
    Email = 1
};
