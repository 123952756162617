import { memo } from "react";
import { TableToolbar, TableToolbarProps } from "ui/widgets/Table";
import { selectIsExportUsersLoading } from "features/users/selectors";
import Button from "@mui/material/Button";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useResourceContext } from "ui/widgets/Resource";

const Add = () => {
    const { onOpen } = useResourceContext();

    return (
        <Button
            size='small'
            startIcon={<GroupAddIcon />}
            onClick={() => onOpen()}
        >
            Add User
        </Button>
    );
};

const UserToolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportUsersLoading}
        add={Add}
    />
);

export default memo(UserToolbar);
