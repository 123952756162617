import React, {memo, useEffect} from 'react';
import QueryBuilderProvider from "./QueryBuilderProvider";
import {Outlet} from "react-router-dom";
import ConditionTextArea from 'ui/screens/QueryBuilder/ConditionTextArea';
import Container from "@mui/material/Container";

interface Condition {
    dropdown1: string;
    dropdown2: string;
    inputValue: string;
    condition: string;
}

interface Aggregation {
    dropdown1: string;
    dropdown2: string;
    aggregation: string;
}

interface QueryBuilderProps {
    updatedConditions: Condition[];
    updatedAggregations: Aggregation[];
    onConditionTextAreaChange: (value: string) => void;
}

const QueryBuilder = (props: QueryBuilderProps) => {
    const {updatedConditions, updatedAggregations, onConditionTextAreaChange} = props;

    useEffect(() => {
    }, [updatedConditions, updatedAggregations]);

    return (
        <QueryBuilderProvider>
            <Container>
                <Container>
                    Resulting query
                </Container>
                <ConditionTextArea
                    updatedConditions={updatedConditions}
                    updatedAggregations={updatedAggregations}
                    onChange={onConditionTextAreaChange}
                />
                <Outlet/>
            </Container>
        </QueryBuilderProvider>
    );
};

export default memo(QueryBuilder);