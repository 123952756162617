import { memo } from "react";
import { FormField, type MerchantProfileFinanceForm } from "../types";
import { getFormInputProps } from "util/forms";
import { ClipboardTextField } from "ui/molecules/Input";
import StatefulSelector from "ui/widgets/StatefulSelector";
import { selectCryptoChains, selectCryptoCurrencies } from "features/general/selectors";

const AccountCrypto = ({
    isNew,
    isEditMode,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
}: MerchantProfileFinanceForm) => {
    const renderTooltip = (value: string = '') => (clipboardContent: string) => {
        if (clipboardContent) {
            return 'Copied!';
        }

        return value;
    };

    return (
        <>
            <ClipboardTextField
                {...getFormInputProps(FormField.Address, { errors, touched })}
                disabled={!isEditMode}
                size='small'
                name={FormField.Address}
                value={values[FormField.Address] || ''}
                label='Address'
                renderTooltip={renderTooltip(values[FormField.Address])}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
            />
            <StatefulSelector
                selectOptions={selectCryptoChains}
                disabled={!isEditMode}
                size='small'
                value={values[FormField.Chain] ?? null}
                TextFieldProps={{
                    label: 'Chain used',
                    required: true
                }}
                onChange={(_, chain) => {
                    setFieldTouched(FormField.Chain, true);
                    setFieldValue(
                        FormField.Chain,
                        chain,
                        true
                    );
                }}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <StatefulSelector
                disabled={!isNew || !isEditMode}
                selectOptions={selectCryptoCurrencies}
                size='small'
                value={values[FormField.Currency] ?? null}
                TextFieldProps={{
                    label: 'Currency',
                    required: true
                }}
                onChange={(_, currency) => {
                    setFieldTouched(FormField.Currency, true);
                    setFieldValue(
                        FormField.Currency,
                        currency,
                        true
                    );
                }}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
        </>
    );
};

export default memo(AccountCrypto);
