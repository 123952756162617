import type { Consumer } from 'features/consumers/types';
import { useMemo } from 'react';
import { date } from 'util/validators';
import { object, string } from 'yup';

export const enum FormFields {
    FirstName = 'firstName',
    LastName = 'lastName',
    DateOfBirth = 'DOB'
};

export const getInitialValues = (initialValues: Partial<Consumer>) => ({
    [FormFields.FirstName]: '',
    [FormFields.LastName]: '',
    [FormFields.DateOfBirth]: null,
    ...initialValues,
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormFields.FirstName]: string()
                .min(2, 'First name must be at least 2 characters')
                .max(50, 'First name must be at most 50 characters')
                .required('First name is required'),
            [FormFields.LastName]: string()
                .min(2, 'Last name must be at least 2 characters')
                .max(50, 'Last name must be at most 50 characters')
                .required('Last name is required'),
            [FormFields.DateOfBirth]: string()
                .required('Date of birth is required')
                .test(FormFields.DateOfBirth, date)
        })
        , []);
