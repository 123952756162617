import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { ImportHistoryTable } from "ui/organizms/Consumers";

const ImportHistory = () => (
    <Screen
        title='Consumers | Bulk KYC Imports'
    >
        <ImportHistoryTable />
    </Screen>
);

export default memo(ImportHistory);
