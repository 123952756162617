import { memo, useState } from "react";
import type { Option, PaginateResourceResponse } from "types";
import EditBusinessEntity from "../../shared/EditBusinessEntity";
import type { MerchantBusiness, MerchantDomain } from "features/merchants/types";
import useMerchantDomains from "../../../useMerchantDomains";
import { EditMerchantDomain as EditMerchantDomainForm, useEditMerchantDomainForm } from "ui/forms/Merchant/Domains";
import { useTypedSelector } from "hooks";
import { selectMerchantOptions } from "features/merchants/selectors";
import { compareIds } from "util/support";
import { fromPaginateResourceResponse } from "util/api";

const EditMerchantDomain = (merchantDomain: MerchantDomain) => {
    const { sourceUrl, businessName } = merchantDomain;
    const { updateMerchantDomain, fetchMerchantBusinesses } = useMerchantDomains();
    const editMerchantDomainForm = useEditMerchantDomainForm({
        initialValues: merchantDomain,
        onSaveOrCreate: ({
            coreId,
            sourceUrl,
            merchantBusinessId,
            businessMerchantId
        }) => updateMerchantDomain({
            coreId,
            sourceUrl,
            businessMerchantId,
            merchantBusinessId
        })
    });
    const merchantOptions = useTypedSelector(selectMerchantOptions);
    const [{ isBusinessResourceLoading, businessResourceData }, setBusinessResourceState] = useState({
        isBusinessResourceLoading: false,
        businessResourceData: Array.of<MerchantBusiness>()
    });

    const sortBusinessByBusinessName = (a: MerchantBusiness, b: MerchantBusiness) =>
        a.businessName.localeCompare(b.businessName);

    const mapBusinessToOptions = ({ coreId, businessName, isEnabled }: MerchantBusiness): Option => ({
        id: coreId,
        name: `${businessName} ${isEnabled ? '(Enabled)' : '(Disabled)'}`
    });

    const filterBusinessByMerchantId = ({ merchantId }: MerchantBusiness) =>
        editMerchantDomainForm.values.businessMerchantId
            ? compareIds(merchantId, editMerchantDomainForm.values.businessMerchantId)
            : true;

    const changeBusinessResourceLoadingState = (isBusinessResourceLoading: boolean) =>
        setBusinessResourceState(state => ({
            ...state,
            isBusinessResourceLoading
        }));

    const changeBusinessResourceData = (businessResourceResponse: PaginateResourceResponse<MerchantBusiness>) =>
        setBusinessResourceState(state => ({
            ...state,
            businessResourceData: fromPaginateResourceResponse(businessResourceResponse)
                .sort(sortBusinessByBusinessName)
        }));

    const refetchBusinesses = async () => {
        changeBusinessResourceLoadingState(true);

        return fetchMerchantBusinesses()
            .then(changeBusinessResourceData)
            .finally(() => changeBusinessResourceLoadingState(false));
    };

    const renderDialogTitle = () => (
        `Edit domain "${sourceUrl}" for business "${businessName}"`
    );

    return (
        <EditBusinessEntity
            form={editMerchantDomainForm}
            onEdit={refetchBusinesses}
            renderDialogTitle={renderDialogTitle}
        >
            <EditMerchantDomainForm
                {...editMerchantDomainForm}
                merrchantDomain={merchantDomain}
                isBootstrapDataLoading={isBusinessResourceLoading}
                bootstrapData={{
                    businessMerchantId: merchantOptions,
                    merchantBusinessId: businessResourceData
                        .filter(filterBusinessByMerchantId)
                        .map(mapBusinessToOptions)
                }}
            />
        </EditBusinessEntity>
    );
};

export default memo(EditMerchantDomain);
