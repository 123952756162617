import type { BooleanSwitch, Mapped } from "types";

export default function getKYCAdditionalSettings<T extends Mapped<BooleanSwitch>>(
    currectOptions: Mapped<BooleanSwitch | null | undefined>,
    fallbackOptions: Mapped<BooleanSwitch>
) {
    return Object.entries(
        Object.keys(currectOptions)
            .reduce((additionalOptions, option) => ({
                ...additionalOptions,
                [option]: validateOption(currectOptions[option])
                    ? currectOptions[option]
                    : fallbackOptions[option],
            }), {} as T)
    )
    .map(([name, id]) => ({
        id,
        name
    }));
};

function validateOption(option: BooleanSwitch | null | undefined) {
    return ['0', '1'].includes(String(option));
}
