import { KYCServiceType, KYCSettingsDefaults } from "consts/kyc";
import { KYCSettingsAction, KYCSettingsActionTypes, KYCSettingsInitActionPayload, KYCSettingsState } from "./types";
import { compareIds, isEqual } from "util/support";
import getKYCAdditionalSettings from "./getters/getKYCAdditionalSettings";

export default function reducer(state: KYCSettingsState, action: KYCSettingsAction): KYCSettingsState {
    switch (action.type) {
        case KYCSettingsActionTypes.KYCSettingsInit: {
            const kycServices = init(state.kycServices, action.payload);

            return {
                ...state,
                initialKYCServicesSnapshot: kycServices,
                kycServices
            };
        }
        case KYCSettingsActionTypes.KYCSettingsApiState:
            return {
                ...state,
                kycSettingsLoadingState: action.payload
            };
        case KYCSettingsActionTypes.UpdateTrxLimit:
            return withStateChanges({
                ...state,
                kycServices: {
                    ...state.kycServices,
                    [action.payload.kycTypeId]: {
                        ...state.kycServices[action.payload.kycTypeId],
                        kycLimit: action.payload.kycLimit,
                    }
                }
            });
        case KYCSettingsActionTypes.UpdateSummedAmountEurLimit:
            return withStateChanges({
                ...state,
                kycServices: {
                    ...state.kycServices,
                    [action.payload.kycTypeId]: {
                        ...state.kycServices[action.payload.kycTypeId],
                        summedAmountEurLimit: action.payload.summedAmountEurLimit,
                    }
                }
            });
        case KYCSettingsActionTypes.UpdateAdditionalOptions:
            return withStateChanges({
                ...state,
                kycServices: {
                    ...state.kycServices,
                    [action.payload.kycTypeId]: {
                        ...state.kycServices[action.payload.kycTypeId],
                        kycSettings: action.payload.kycSettings
                    }
                }
            });
        case KYCSettingsActionTypes.SyncInitialKYCServicesSnapshot:
            return withStateChanges({
                ...state,
                initialKYCServicesSnapshot: state.kycServices
            });
        default:
            return state;
    }
};

function withStateChanges(state: KYCSettingsState): KYCSettingsState {
    return {
        ...state,
        isKycSettingsChanged: !isEqual(
            state.initialKYCServicesSnapshot,
            state.kycServices
        )
    };
}

function init(kycServices: KYCSettingsState['kycServices'], { kysServices, kycSettings }: KYCSettingsInitActionPayload) {
    const {
        documentBacksideProofRequired,
        documentShowOcrForm,
        addressShowOcrForm
    } = kycSettings;

    return kysServices.reduce((services, kycService) => {
        if (compareIds(kycService.kycTypeId, KYCServiceType.FaceVerification)) {
            return {
                ...services,
                [KYCServiceType.FaceVerification]: {
                    ...services[KYCServiceType.FaceVerification],
                    kycLimit: kycService.kycLimit,
                    summedAmountEurLimit: kycService.summedAmountEurLimit,
                }
            };
        }

        if (compareIds(kycService.kycTypeId, KYCServiceType.IDVerification)) {
            return {
                ...services,
                [KYCServiceType.IDVerification]: {
                    kycLimit: kycService.kycLimit,
                    summedAmountEurLimit: kycService.summedAmountEurLimit,
                    kycSettings: getKYCAdditionalSettings({
                        documentBacksideProofRequired,
                        documentShowOcrForm,
                    }, {
                        documentBacksideProofRequired: KYCSettingsDefaults.documentBacksideProofRequired,
                        documentShowOcrForm: KYCSettingsDefaults.documentShowOcrForm
                    })
                }
            };
        }

        if (compareIds(kycService.kycTypeId, KYCServiceType.AMLVerification)) {
            return {
                ...services,
                [KYCServiceType.AMLVerification]: {
                    kycLimit: kycService.kycLimit,
                    summedAmountEurLimit: kycService.summedAmountEurLimit,
                    kycSettings: getKYCAdditionalSettings({
                        includeIdVerifiedConsumers: kycService.includeIdVerifiedConsumers,
                    }, {
                        includeIdVerifiedConsumers: KYCSettingsDefaults.includeIdVerifiedConsumers
                    })
                }
            };
        }

        if (compareIds(kycService.kycTypeId, KYCServiceType.AddressVerification)) {
            return {
                ...services,
                [KYCServiceType.AddressVerification]: {
                    kycLimit: kycService.kycLimit,
                    summedAmountEurLimit: kycService.summedAmountEurLimit,
                    kycSettings: getKYCAdditionalSettings({
                        includeIdVerifiedConsumers: kycService.includeIdVerifiedConsumers,
                        addressShowOcrForm
                    }, {
                        includeIdVerifiedConsumers: KYCSettingsDefaults.includeIdVerifiedConsumers,
                        addressShowOcrForm: KYCSettingsDefaults.addressShowOcrForm
                    })
                }
            };
        }

        if (compareIds(kycService.kycTypeId, KYCServiceType.PhoneVerification)) {
            return {
                ...services,
                [KYCServiceType.PhoneVerification]: {
                    kycLimit: kycService.kycLimit,
                    summedAmountEurLimit: kycService.summedAmountEurLimit,
                    kycSettings: getKYCAdditionalSettings({
                        includeIdVerifiedConsumers: kycService.includeIdVerifiedConsumers
                    }, {
                        includeIdVerifiedConsumers: KYCSettingsDefaults.includeIdVerifiedConsumers
                    })
                }
            };
        }

        return services;
    }, kycServices);
}
