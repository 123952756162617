import { memo } from "react";
import type { GridRowParams } from '@mui/x-data-grid-premium';
import ListItem from '@mui/material/ListItem';
import ListItemButton, { type ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useAuth from "features/auth/useAuth";
import { compareIds } from "util/support";

type Props = Pick<ListItemButtonProps, 'onMouseDown'> & GridRowParams;

const Delete = ({ row, onMouseDown }: Props) => {
    const { user } = useAuth();

    const getIsRowOwner = () => compareIds(row.coreId, user.coreId);

    if (getIsRowOwner()) {
        return null;
    }

    return (
        <ListItem disablePadding>
            <ListItemButton
                onMouseDown={onMouseDown}
            >
                <ListItemText
                    primary="Delete"
                    sx={{
                        color: 'error.main'
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default memo(Delete);
