import { memo } from "react";
// import { v4 as uuidv4 } from 'uuid';
import { LinearStatisticDiagram } from "ui/molecules/StatisticDiagram";
import type { LinearDiagramProps } from "../../types";
import { formatXDateAxis, getAxisBottomTickRotation } from "../../support/helpers";

const LinearDiagram = ({
    isLoading,
    title,
    subtitle,
    subheader,
    data,
    axisLeft,
    chartMargin,
    yFormat,
    sliceTooltip,
    sx,
    renderAxisBottomTick,
    colors,
    className = '',
    columns = []
}: LinearDiagramProps) => {
    // TODO: this is workaround for re-rendering chart when data is loaded
    // const [key, setKey] = useState('');
    // useEffect(() => {
    //     !isLoading && setKey(uuidv4());
    // }, [isLoading]);

    return (
        <LinearStatisticDiagram
            // key={key}
            sx={{
                position: 'relative',
                overflow: 'visible',
                ...sx
            }}
            className={className}
            isLoading={isLoading}
            isDataAvailable={Boolean(data.length)}
            title={title}
            subtitle={subtitle}
            subheader={subheader}
            chartProps={{
                margin: {
                    top: 50,
                    bottom: 50,
                    left: 60,
                    ...chartMargin
                },
                xScale: {
                    type: 'point'
                },
                yScale: {
                    type: 'linear'
                },
                enableSlices: 'x',
                sliceTooltip,
                yFormat,
                curve: 'monotoneX',
                // enableArea: true,
                axisTop: null,
                axisRight: null,
                axisBottom: {
                    format: formatXDateAxis(columns),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: getAxisBottomTickRotation(data),
                    legendOffset: 36,
                    legendPosition: 'middle',
                    renderTick: renderAxisBottomTick
                },
                axisLeft: {
                    format: '.2s',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -45,
                    legendPosition: 'middle',
                    ...axisLeft
                },
                enablePoints: true,
                colors: colors ?? { scheme: 'category10' },
                // pointSize: 10,
                // pointColor: { theme: 'background' },
                // pointBorderWidth: 2,
                // pointBorderColor: { from: 'serieColor' },
                // pointLabelYOffset: -12,
                useMesh: true,
                legends: [
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        // itemTextColor: 'transparent',
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        /*effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]*/
                    }
                ],
                data
            }}
        />
    );
};

export default memo(LinearDiagram);
