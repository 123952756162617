import { Filters } from "consts/consumers";
import { ManualBulkKycImportStatus } from "consts/kyc";
import { TableProps } from "ui/widgets/Table";

export default function useFiltersProps(): TableProps['FiltersProps'] {
    return {
        filtersModelOverrides: {
            [Filters.active]: [
                { id: 0, name: 'Inactive' },
                { id: 1, name: 'Active' }
            ],
            [Filters.hasImmunityForForbiddenWords]: [
                { id: 0, name: 'Exclude' },
                { id: 1, name: 'Add' }
            ],
            [Filters.manualBulkKycImportStatus]: [
                {
                    id: ManualBulkKycImportStatus.Created,
                    name: `Is Bulk KYC'ed`
                },
                {
                    id: ManualBulkKycImportStatus.NotCreated,
                    name: `Is not Bulk KYC'ed`
                }
            ]
        }
    };
};
