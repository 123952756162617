import { useState } from "react";
import {
    useTicketingSystemStateSelectorsContext,
    useTicketingSystemActionsContext
} from "../TicketingSystemProvider";
import { ApiState } from "infrastructure/api";
import { useSimpleRequest } from "hooks/useRequest";
import { createOrUpdateMerchantSettings } from "features/merchants/api";
import { updateOrCreateMerchantsNotificationChannelsPivot } from "features/pivots/api";
import { servicesApiAdapter } from "../TicketingSystemProvider/adapters";
import type { MerchantSettings } from "features/merchants/types";
import type { MerchantsNotificationChannelsPivot } from "features/pivots/types";

export default function useTicketingSystemActionPanel() {
    const [isLoading, setLoading] = useState(false);
    const {
        syncTicketingSystemSettingsOnSave
    } = useTicketingSystemActionsContext();
    const {
        selectTicketingSystemResources,
        selectTicketingSystemServicesState,
        selectTicketingSystemApiState,
        selectIsTicketingSystemSettingsChanged
    } = useTicketingSystemStateSelectorsContext();

    const request = useSimpleRequest();

    const getLoading = () =>
        isLoading;

    const getDisabled = () =>
        !selectIsTicketingSystemSettingsChanged() ||
        selectTicketingSystemApiState({
            apiState: [ApiState.Succeeded, ApiState.Failed],
            notIn: true
        });

    const onSave = async () => {
        setLoading(true);

        const ticketingSystemServicesState = selectTicketingSystemServicesState();
        const serviceResources = selectTicketingSystemResources() ?? {};
        const services = servicesApiAdapter(ticketingSystemServicesState);

        const merchantSettings = {
            ...serviceResources.merchantSettings,
            ...services.merchantSettings
        };

        const merchantsNotificationChannelsPivot = {
            ...serviceResources.merchantsNotificationChannelsPivot,
            ...services.merchantsNotificationChannelsPivot
        };

        await Promise.allSettled([
            request(() => createOrUpdateMerchantSettings({
                coreId: merchantSettings.coreId!,
                showTicketingSystemOnInit: merchantSettings.showTicketingSystemOnInit,
                showTicketingSystemOnRedirect: merchantSettings.showTicketingSystemOnRedirect,
                showTicketingSystemOnRedirectOnlyOnStatuses: merchantSettings.showTicketingSystemOnRedirectOnlyOnStatuses
            })),
            request(() => updateOrCreateMerchantsNotificationChannelsPivot({
                merchantId: merchantSettings.merchantId!,
                notificationChannelId: serviceResources.notificationChannels!.coreId,
                isEnabled: merchantsNotificationChannelsPivot.isEnabled,
                secondsInterval: merchantsNotificationChannelsPivot.secondsInterval
            }))
        ]);

        syncTicketingSystemSettingsOnSave({
            merchantSettings: merchantSettings as MerchantSettings,
            merchantsNotificationChannelsPivot: merchantsNotificationChannelsPivot as MerchantsNotificationChannelsPivot,
        });

        setLoading(false);
    };

    return {
        isDisabled: getDisabled(),
        isLoading: getLoading(),
        onSave
    };
};
