import { memo, PropsWithChildren } from "react";
import Stack from '@mui/material/Stack';
import Box, { type BoxProps } from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import type useBulkKYCHistoryResultsDialog from "../useBulkKYCHistoryResultsDialog";
import { ManualBulkKycImportStatus } from "consts/kyc";
import { ConsumersManualBulkKYCsImportHistoryPivot } from "features/consumers/types";
import useKYCServices from "features/kyc/useKYCServices";

type Props = Pick<
    ReturnType<typeof useBulkKYCHistoryResultsDialog>,
    | 'consumersManualBulkKYCsImportHistoryPivotAggregatable'
>;

const BulkKYCHistoryResultsStats = ({
    consumersManualBulkKYCsImportHistoryPivotAggregatable
}: Props) => {
    const { getKYCServicesCollection } = useKYCServices();

    const getStatsGroupedBy = ({ importStatus, kycTypeId }: Pick<
        ConsumersManualBulkKYCsImportHistoryPivot,
        | 'importStatus'
        | 'kycTypeId'
    >) =>
        consumersManualBulkKYCsImportHistoryPivotAggregatable
            .find(consumersManualBulkKYCsImportHistoryPivot => (
                Object.is(consumersManualBulkKYCsImportHistoryPivot.importStatus, importStatus) &&
                Object.is(consumersManualBulkKYCsImportHistoryPivot.kycTypeId, kycTypeId)
            ))
            ?.group_count || 0;

    return (
        <Stack
            direction='row'
            spacing={2}
            sx={{
                px: 2,
                mt: 2
            }}
        >
            {getKYCServicesCollection({
                getStatus: _ => null,
            })
                .map(({ type, name }) => {
                    const createdCount = getStatsGroupedBy({
                        importStatus: ManualBulkKycImportStatus.Created,
                        kycTypeId: type
                    });

                    const ignoredCount = getStatsGroupedBy({
                        importStatus: ManualBulkKycImportStatus.Ignored,
                        kycTypeId: type
                    });

                    const notFoundCount = getStatsGroupedBy({
                        importStatus: ManualBulkKycImportStatus.NotFound,
                        kycTypeId: type
                    });

                    const totalCount = createdCount + ignoredCount + notFoundCount;

                    if (totalCount <= 0) {
                        return null;
                    }

                    return (
                        <Box key={type}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                gutterBottom
                            >
                                {name}
                            </Typography>
                            <Group
                                count={createdCount}
                                sx={{
                                    color: 'success.main'
                                }}
                            >
                                KYC'd
                            </Group>
                            <Group
                                count={ignoredCount}
                                sx={{
                                    color: 'warning.main'
                                }}
                            >
                                Already KYC'd
                            </Group>
                            <Group
                                count={notFoundCount}
                                sx={{
                                    color: 'error.main'
                                }}
                            >
                                Not in database
                            </Group>
                        </Box>
                    );
                })}
        </Stack>
    );
};

export default memo(BulkKYCHistoryResultsStats);

function Group({ count, children, sx }: PropsWithChildren<Pick<BoxProps, 'sx'> & {
    readonly count: number;
}>) {
    if (count > 0) {
        return (
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, auto)',
                    alignItems: 'center',
                    gap: 1,
                    fontWeight: 'bold'
                }}
            >
                <Box>{children}:</Box>
                <Box
                    sx={{
                        ...sx,
                        fontSize: 20
                    }}
                >
                    {count}
                </Box>
            </Box>
        );
    }

    return null;
}
