import {
    forwardRef,
    type ComponentType,
    type ForwardRefRenderFunction,
    type PropsWithChildren
} from "react";
import type {
    AutocompleteProps,
    TextFieldProps,
    FormControlProps
} from "@mui/material";
import MuiAutocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputLabel from "ui/atoms/InputLabel";

type LayoutProps = PropsWithChildren<
    FormControlProps & {
        readonly label?: string;
    }
>;

export type Props = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
    readonly TextFieldProps?: TextFieldProps;
    readonly FormControlProps?: FormControlProps;
    readonly layout?: ComponentType<LayoutProps> | null;
} & Pick<LayoutProps, 'label'>;

const Autocomplete: ForwardRefRenderFunction<any, Props> = ({
    loading,
    label,
    TextFieldProps = {},
    FormControlProps = {},
    layout: LayoutComponent = Layout,
    ...props
}, ref) => {
    const component = (
        <MuiAutocomplete
            ref={ref}
            size='small'
            {...props}
            loading={loading}
            renderInput={params => (
                <TextField
                    {...TextFieldProps}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        ...({
                            endAdornment: (
                                <>
                                    {loading
                                        ? (
                                            <CircularProgress
                                                color='primary'
                                                size={20}
                                            />
                                        )
                                        : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            ...TextFieldProps?.InputProps
                        })
                    }}
                />
            )}
        />
    );

    return LayoutComponent
        ? (
            <LayoutComponent
                label={label}
                {...FormControlProps}
            >
                {component}
            </LayoutComponent>
        )
        : component;
};

export default forwardRef(Autocomplete);

function Layout({ label, children, ...props }: LayoutProps) {
    return (
        <FormControl
            variant='standard'
            {...props}
        >
            <InputLabel>
                {label}
            </InputLabel>
            {children}
        </FormControl>
    );
}
