import { createContext, PropsWithChildren } from "react";
import useTicketingSystem from "./useTicketingSystem";
import useTicketingSystemStateSelectors from "./useTicketingSystemStateSelectors";

export const TicketingSystemStateSelectorsContext = createContext({} as ReturnType<
    typeof useTicketingSystemStateSelectors
>);
export const TicketingSystemActionsContext = createContext({} as Omit<
    ReturnType<typeof useTicketingSystem>,
    | 'ticketingSystemState'
>);

const TicketingSystemProvider = ({ children }: PropsWithChildren) => {
    const {
        ticketingSystemState,
        ...ticketingSystemActions
    } = useTicketingSystem();

    return (
        <TicketingSystemActionsContext.Provider
            value={ticketingSystemActions}
        >
            <TicketingSystemStateSelectorsContext.Provider
                value={useTicketingSystemStateSelectors(ticketingSystemState)}
            >
                {children}
            </TicketingSystemStateSelectorsContext.Provider>
        </TicketingSystemActionsContext.Provider>
    );
};

export default TicketingSystemProvider;
