import { isMerchantRequired } from "./helpers";
import { FormField, type ValidatorParams } from "./types";

class Validator {
    public static from(validatorParams: ValidatorParams) {
        return new this(validatorParams);
    }

    protected constructor(
        protected validatorParams: ValidatorParams
    ) { }

    public get message(): string | undefined {
        return '';
    }

    public get isValid(): boolean {
        return false;
    }

    public get isInvalid(): boolean {
        return !this.isValid;
    }
}

export default class FormValidator extends Validator {
    public override get isValid(): boolean {
        if (!this.validatorParams.isValid) {
            return false;
        }

        return MerchantValidator.from(this.validatorParams)
            .isValid;
    }
}

export class MerchantValidator extends Validator {
    public override get message() {
        if (!this.isValid) {
            return 'Merchant is required';
        }

        return '';
    }

    public override get isValid(): boolean {
        const {
            isBootstrapDataLoading,
            bootstrapData,
            values
        } = this.validatorParams;

        if (
            !isBootstrapDataLoading &&
            isMerchantRequired(bootstrapData.roles)(values[FormField.RoleId])
        ) {
            return Boolean(
                Array.isArray(values[FormField.Merchants])
                    ? values[FormField.Merchants].length
                    : values[FormField.Merchants]
            );
        }

        return true;
    }
};
