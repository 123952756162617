import { memo } from 'react';
import Stack from '@mui/material/Stack';
import type { GridRowParams } from '@mui/x-data-grid';
import LabeledSwitch from 'ui/atoms/LabeledSwitch';
import Modal from 'ui/atoms/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import usePayableControl from './usePayableControl';
import PayableRollingReserveCalculationDescription from './PayableRollingReserveCalculationDescription';
import usePayableRollingReserveCalculationDescription from './usePayableRollingReserveCalculationDescription';

const PayableControl = ({ row }: Pick<GridRowParams, 'row'>) => {
    const {
        getLabel,
        getDisabled,
        getChecked,
        onChange,
        onProceed,
        onCancel,
        open,
        isLoading
    } = usePayableControl(row);

    const payableRollingReserveCalculationDescription = usePayableRollingReserveCalculationDescription({
        merchantId: open
            ? row.merchantId
            : null,
        currency: row.currency
    });

    return (
        <>
            <LabeledSwitch
                checked={getChecked()}
                disabled={getDisabled()}
                label={getLabel()}
                onChange={onChange}
                FormControlLabelProps={{
                    sx: {
                        ml: '3px',
                        mr: 0,
                        flexDirection: 'row-reverse ',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)'
                    }
                }}
            />
            <Modal
                DialogProps={{
                    PaperProps: {
                        sx: {
                            maxWidth: '600px !important'
                        }
                    }
                }}
                isOpen={open}
                onClose={() => { }}
                actionsSlot={(
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <Button
                            disabled={isLoading || payableRollingReserveCalculationDescription.isLoading}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="outlined"
                            loading={isLoading || payableRollingReserveCalculationDescription.isLoading}
                            onClick={onProceed}
                        >
                            Proceed
                        </LoadingButton>
                    </Stack>
                )}
            >
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    {`Are you sure you want to ${getLabel()} ?`}
                </Box>
                <PayableRollingReserveCalculationDescription
                    {...row}
                    {...payableRollingReserveCalculationDescription}
                />
            </Modal>
        </>
    );
};

export default memo(PayableControl);
