import { memo, useMemo, useRef } from 'react';
import Menu, { MenuImperativeProps } from 'ui/atoms/Menu';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Icon from 'ui/atoms/Icon';
import useActionMenu from './useActionMenu';
import type { GridRowParams } from '@mui/x-data-grid-premium';
import { ManageFileTaxesProvider } from './Dialogs';
import { DialogProvider } from './Dialogs';
import type { ActionMenuArg } from './Props';
import { CoreBankingEntity } from 'types';
import FileProvider from './Dialogs/ManageFileTaxes/FileProvider';

type Props = Pick<GridRowParams, 'row'> & ActionMenuArg<CoreBankingEntity>;

const ActionMenu = ({ row, requestMappings, getMenuItems }: Props) => {
    const menuRef = useRef<MenuImperativeProps>();

    const getActionMenuItem = useActionMenu({
        row,
        menuRef,
        requestMappings,
        getMenuItems
    });

    const ActionControl = useMemo(() =>
        ({ onClick }: ButtonProps) => (
            <IconButton
                onClick={onClick}
            >
                <Icon name='MoreVert' />
            </IconButton>
        ), []);

    return (
        <Menu
            ref={menuRef}
            ActionControl={ActionControl}
        >
            {getActionMenuItem()}
        </Menu>
    );
};

export default memo((props: Props) => (
    <FileProvider value={props.row}>
        <ManageFileTaxesProvider>
            <DialogProvider>
                <ActionMenu {...props} />
            </DialogProvider>
        </ManageFileTaxesProvider>
    </FileProvider>
));
