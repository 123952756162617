import { memo } from "react";
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import type { BootstrapableForm, Option } from "types";
import type { IncrementTaxes } from "features/merchantPayouts/types";
import type { ChangePayload, FilterChangePayload, FiltersChangeHandler } from "ui/widgets/Filters";
import { Autocomplete, TextField } from "ui/widgets/Filters";
import { Filters } from "consts/merchantPayouts";
import { getFormInputProps } from "util/forms";


export type FormValues =
    & Partial<
        Record<
            keyof Pick<
                IncrementTaxes,
                | "merchantId"
                | 'taxName'
                | 'tag'
                | "refColFrom"
            >,
            Option
        >
    >
    & Pick<Partial<IncrementTaxes>, 'currentIncMultiplierForEachRefColumn' | "refColFromMinSum">

export type IncrementTaxesBootstrapData = Record<
    | 'merchantId'
    | 'taxName'
    | 'tag'
    | 'refColFrom',
    Option[]
>;

type Props = ReturnType<typeof useFormik<FormValues>> & Pick<
    BootstrapableForm<
        FormValues,
        IncrementTaxesBootstrapData
    >,
    | 'id'
    | 'bootstrapData'
    | 'isBootstrapDataLoading'
>;

const IncrementTax = ({
    id,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    touched,
    errors,
    bootstrapData,
    isBootstrapDataLoading
}: Props) => {
    const onChange: FiltersChangeHandler = ({ type, payload }: ChangePayload<string, FilterChangePayload>) => {
        setFieldValue(type, payload);
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={id}
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                flexWrap: 'wrap'
            }}
        >
            <Autocomplete
                {...getFormInputProps(Filters.merchantId, { touched, errors })}
                options={bootstrapData.merchantId}
                loading={isBootstrapDataLoading}
                id={Filters.merchantId}
                name={Filters.merchantId}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.merchantId}
                label="Merchant *"
                placeholder={Filters.merchantId}
            />
            <Autocomplete
                {...getFormInputProps(Filters.taxName, { touched, errors })}
                options={bootstrapData.taxName}
                loading={isBootstrapDataLoading}
                id={Filters.taxName}
                name={Filters.taxName}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.taxName}
                label="Tax Name *"
                placeholder={Filters.taxName}
            />
            <Autocomplete
                {...getFormInputProps(Filters.tag, { touched, errors })}
                options={bootstrapData.tag}
                loading={isBootstrapDataLoading}
                id={Filters.tag}
                name={Filters.tag}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.tag}
                label='Payment Method TAG'
                placeholder='Tag'
            />
            <Autocomplete
                {...getFormInputProps(Filters.refColFrom, { touched, errors })}
                options={bootstrapData.refColFrom}
                loading={isBootstrapDataLoading}
                id={Filters.refColFrom}
                name={Filters.refColFrom}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.refColFrom}
                label='Ref Column From'
                placeholder='Ref Column From'
            />
            <TextField
                {...getFormInputProps(Filters.refColFromMinSum, { touched, errors })}
                id={Filters.refColFromMinSum}
                name={Filters.refColFromMinSum}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.refColFromMinSum}
                label='Ref Column From Min Sum'
                // placeholder='Ref Column From Min Sum'
                type='number'
            />
            <TextField
                {...getFormInputProps(Filters.currentIncMultiplierForEachRefColumn, { touched, errors })}
                id={Filters.currentIncMultiplierForEachRefColumn}
                name={Filters.currentIncMultiplierForEachRefColumn}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.currentIncMultiplierForEachRefColumn}
                label='Multiplier *'
                // placeholder='Multiplier'
                type='number'
            />
        </Box>
    );
};

export default memo(IncrementTax);
