import { Fragment, memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useQueryBuilderForm from "./useQueryBuilderForm";
import { FormFields, type QueryBuilderProps } from "./types";
import { getFormInputProps } from "util/forms";

const QueryBuilder = ({
    label,
    placeholder,
    isLoading,
    renderChildren,
    children,
    ...props
}: PropsWithChildren<QueryBuilderProps>) => {
    const form = useQueryBuilderForm(props);
    const {
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit
    } = form;

    const renderControlPanel = () => {
        const submitButtonSlot = (
            <LoadingButton
                type='submit'
                variant='outlined'
                loading={isLoading || isSubmitting}
                disabled={!isValid}
                sx={{
                    mt: 2
                }}
            >
                Submit
            </LoadingButton>
        );

        const Children = renderChildren ?? Fragment;

        return children || (
            <Children
                {...form}
            >
                {submitButtonSlot}
            </Children>
        );
    };

    return (
        <Box
            component='form'
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
        >
            <TextField
                {...getFormInputProps(FormFields.SearchQuery, { errors, touched })}
                value={values[FormFields.SearchQuery]}
                name={FormFields.SearchQuery}
                onChange={handleChange}
                onBlur={handleBlur}
                label={label}
                placeholder={placeholder}
                multiline
                rows={4}
                fullWidth
            />
            {renderControlPanel()}
        </Box>
    );
};

export default memo(QueryBuilder);
