import type { FormableTodoProps } from "./types";

export default function useFormableTodo({
    values,
    name,
    itemsName,
    setFieldValue,
    setFieldTouched
}: FormableTodoProps) {
    const getValue = () => values[name] ?? '';
    const getItems = () => values[itemsName] ?? [];

    const hasValue = () => Boolean(getValue().trim());

    const removeItem = (removeIndex: number) => {
        setFieldValue(
            itemsName,
            getItems()
                .filter((_: string, order: number) => !Object.is(
                    order,
                    removeIndex
                ))
        );

        setFieldTouched(itemsName, true, true);
    };

    const addItem = () => {
        setFieldValue(
            itemsName,
            Array.from(
                new Set([
                    ...getItems(),
                    getValue()
                ])
            )
        );

        setFieldValue(name, '');

        setFieldTouched(name, true, true);
    };

    return {
        hasValue,
        getValue,
        getItems,
        removeItem,
        addItem
    };
};
