import type { Option } from "types";
import { FilterValueProvider, TValueStrategy, ValueStrategy } from "./types";

class MultiAutocompleteFilterValueProvider extends FilterValueProvider<Array<Option>> {
    static getDefaultValue() {
        return [];
    }

    public getInitialValue(values: Array<string>) {
        return values.reduce((acc: Array<Option>, currentValue) => [
            ...acc,
            {
                id: currentValue,
                name: currentValue
            }
        ], []);;
    }

    public handleChangeValue(values: Array<Option>) {
        return values;
    }
}

const multiAutocompleteFilterValueStrategy: TValueStrategy<Array<Option>> = {
    [ValueStrategy.Default]:
        new MultiAutocompleteFilterValueProvider(
            MultiAutocompleteFilterValueProvider.getDefaultValue
        )
};

export default multiAutocompleteFilterValueStrategy;
