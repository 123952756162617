import type { Dispatch, SetStateAction } from "react";
import { AccountAdvancedSettings } from "ui/forms/Merchant";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import { MerchantAccountSettingsRepository } from "../repository";
import { FormField } from "ui/forms/Merchant/AccountAdvancedSettings";
import { MerchantOption } from "features/merchants/helpers";
import { FormAdapter } from "hooks/useForm";
import BusinessLogicException from "exceptions/BusinessLogicException";

export default function useMerchantAccountAdvancedSettings(setLoading: Dispatch<SetStateAction<boolean>>): AccountAdvancedSettings.MerchantAccountAdvancedSettingsProps {
    const repository = useMerchantDetailsTabContext<MerchantAccountSettingsRepository>();

    const merchantSettings = repository.merchantSettings;

    const onSubmit: AccountAdvancedSettings.MerchantAccountAdvancedSettingsForm['onSaveOrCreate'] = async ({
        obs,
        activeStatus,
        isEchoSystem,
        redirectUrl,
        notificationUrl,
        kycType,
        isDomainDriven,
        maxTotalAmount24
    }, formikHelpers) => {
        setLoading(true);

        try {
            const response = await repository.updateMerchantSettings({
                coreId: merchantSettings?.coreId,
                merchantId: merchantSettings?.merchantId,
                obs,
                activeStatus,
                isEchoSystem,
                redirectUrl,
                notificationUrl,
                kycType,
                isDomainDriven,
                maxTotalAmount24
            });

            return FormAdapter.fromResponse(response);
        } catch (error) {
            if (error instanceof BusinessLogicException) {
                return FormAdapter.fromResponse(error.cause);
            }
        } finally {
            setLoading(false);
            formikHelpers!.setTouched({});
        }
    };

    const accountAdvancedSettingsProps = {
        onSaveOrCreate: onSubmit,
        bootstrapData: {
            [FormField.AccountStatus]: MerchantOption.accountStatus,
            [FormField.AccountKYCOption]: MerchantOption.kycTypes
        },
        isBootstrapDataLoading: false
    };

    return {
        ...accountAdvancedSettingsProps,
        ...AccountAdvancedSettings.useForm({
            ...accountAdvancedSettingsProps,
            initialValues: merchantSettings ?? {}
        }),
    };
};
