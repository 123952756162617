import { KYCServiceState, kycServiceTransactionLimit } from 'consts/kyc';
import type { MerchantKYCService } from 'features/merchants/types';
import { useState, useEffect, useCallback, useRef } from 'react';
import type { SelectProps } from '@mui/material/Select';
import type { TextFieldProps } from '@mui/material';
import { useKYCSettingsActionsContext, useKYCSettingsStateSelectorsContext } from '../KYCSettingsProvider';
import {
    isServiceSoft,
    isServiceStrict,
    summedAmountEurLimitConstraintValidator,
    transactionLimitConstraintValidator
} from './helpers';
import { ApiState } from 'infrastructure/api';
import { subscribe, unsubscribe } from 'util/custom-event';
import { MERCHANT_KYC_QUICK_SWITCH_CHANGE_EVENT } from './ServicesControlPanel';

type UseServiceArg =
    & Pick<MerchantKYCService, 'kycTypeId'>

export default function useService({ kycTypeId }: UseServiceArg) {
    const isComputedServiceStateLoadedRef = useRef<boolean | undefined>();

    const {
        updateTrxLimit,
        updateSummedAmountEurLimit
    } = useKYCSettingsActionsContext();

    const updateHandlersRef = useRef({
        updateTrxLimit,
        updateSummedAmountEurLimit
    });

    updateHandlersRef.current = {
        updateTrxLimit,
        updateSummedAmountEurLimit
    };

    const {
        selectKYCLimitByTypeId,
        selectKYCSummedAmountEurLimitByTypeId,
        selectKYCSettingsLoadingState
    } = useKYCSettingsStateSelectorsContext();

    const kycLimit = selectKYCLimitByTypeId(kycTypeId);
    const summedAmountEurLimit = selectKYCSummedAmountEurLimitByTypeId(kycTypeId);

    const isFetchingSucceded = selectKYCSettingsLoadingState({
        apiState: ApiState.Succeeded,
    });
    const isFetchingProcessing = selectKYCSettingsLoadingState({
        apiState: [ApiState.Succeeded, ApiState.Failed],
        notIn: true
    });

    const getComputedServiceState = useCallback((merchantKYCService: Pick<
        MerchantKYCService,
        | 'kycLimit'
        | 'summedAmountEurLimit'
    >) => {
        if (isServiceSoft(merchantKYCService)) {
            return KYCServiceState.Soft;
        }

        if (isServiceStrict(merchantKYCService)) {
            return KYCServiceState.Strict;
        }

        return KYCServiceState.Off;
    }, []);

    const [serviceState, setServiceState] = useState<KYCServiceState>(() =>
        getComputedServiceState({ kycLimit, summedAmountEurLimit })
    );

    const isTransactionLimitDisabled = (serviceState: KYCServiceState) => [
        KYCServiceState.Off,
        KYCServiceState.Strict
    ].includes(serviceState);

    const handleServiceStateChange = useCallback((value: KYCServiceState) => {
        const { updateTrxLimit, updateSummedAmountEurLimit } = updateHandlersRef.current;

        setServiceState(value);

        updateTrxLimit({
            kycTypeId,
            kycLimit: kycServiceTransactionLimit.get(value)!
        });

        updateSummedAmountEurLimit({
            kycTypeId,
            summedAmountEurLimit: kycServiceTransactionLimit.get(KYCServiceState.Strict)!
        });
    }, [kycTypeId]);

    const onServiceStateChange: SelectProps<KYCServiceState>['onChange'] = ({ target }) => {
        const value = Number(target.value) as KYCServiceState;

        handleServiceStateChange(value);
    };

    const onTransactionsLimitChange: TextFieldProps['onChange'] = ({ target: { value } }) => {
        const kycLimit = transactionLimitConstraintValidator.get(serviceState)!(value);

        updateTrxLimit({
            kycTypeId,
            kycLimit
        });

        updateSummedAmountEurLimit({
            kycTypeId,
            summedAmountEurLimit: summedAmountEurLimitConstraintValidator.get(serviceState)!(
                kycLimit,
                String(summedAmountEurLimit)
            )
        });
    };

    const onSummedAmountEurLimitBlur: TextFieldProps['onBlur'] = ({ target: { value } }) =>
        updateSummedAmountEurLimit({
            kycTypeId,
            summedAmountEurLimit: summedAmountEurLimitConstraintValidator.get(serviceState)!(kycLimit, value)
        });

    const onSummedAmountEurLimitChange: TextFieldProps['onChange'] = ({ target: { value } }) =>
        updateSummedAmountEurLimit({
            kycTypeId,
            summedAmountEurLimit: Number.isNaN(Number.parseFloat(value))
                ? ''
                : value
        });

    useEffect(() => {
        if (isComputedServiceStateLoadedRef.current) {
            return;
        }

        if (isFetchingSucceded) {
            setServiceState(getComputedServiceState({
                kycLimit,
                summedAmountEurLimit
            }));
            isComputedServiceStateLoadedRef.current = true;
        }
    }, [
        isFetchingSucceded,
        kycLimit,
        summedAmountEurLimit,
        getComputedServiceState
    ]);

    useEffect(() => {
        if (isFetchingProcessing) {
            isComputedServiceStateLoadedRef.current = false;
        }
    }, [isFetchingProcessing]);

    useEffect(() => {
        const onMerchantKycQuickSwitchChange = () =>
            handleServiceStateChange(KYCServiceState.Off);

        subscribe(MERCHANT_KYC_QUICK_SWITCH_CHANGE_EVENT, onMerchantKycQuickSwitchChange);

        return () => {
            unsubscribe(MERCHANT_KYC_QUICK_SWITCH_CHANGE_EVENT, onMerchantKycQuickSwitchChange);
        };
    }, [handleServiceStateChange]);

    return {
        isFetchingProcessing,
        transactionsLimit: kycLimit ?? '',
        summedAmountEurLimit,
        serviceState,
        isTransactionLimitDisabled,
        onServiceStateChange,
        onTransactionsLimitChange,
        onSummedAmountEurLimitBlur,
        onSummedAmountEurLimitChange
    };
};
