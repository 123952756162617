import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from 'ui/atoms/Modal';
import { useProcessRowUpdate } from 'ui/molecules/TableGrids';
import { Filters } from 'consts/merchantPayouts';

type Props = ReturnType<typeof useProcessRowUpdate>;

const UpdateIncrementsDialog = ({
    isConfirmationPending,
    handleCancelAction,
    handleConfirmAction,
    isLoading,
    getProcessRowArguments
}: Props) => {
    const getContent = () => {
        const processRowArguments = getProcessRowArguments();

        return processRowArguments && [
            processRowArguments.oldRow[Filters.merchantName],
            processRowArguments.oldRow[Filters.MOR],
            processRowArguments.oldRow[Filters.PSP],
            processRowArguments.oldRow[Filters.tag],
            processRowArguments.oldRow[Filters.via],
            processRowArguments.oldRow[Filters.incrementStatus],
            processRowArguments.oldRow[Filters.currency],
            processRowArguments.oldRow[Filters.dateFrom],
            processRowArguments.oldRow[Filters.dateTo]
        ].join('-');
    };

    const getCurrency = (property: 'oldRow' | 'newRow') => {
        const processRowArguments = getProcessRowArguments();

        return processRowArguments?.[property][Filters.currencyExchangeRate];
    };

    return (
        <Modal
            isOpen={isConfirmationPending}
            onClose={handleCancelAction}
            titleSlot='Are you sure you want to update this currency exchange rate?'
            actionsSlot={(
                <Stack
                    direction='row'
                    spacing={2}
                >
                    <Button
                        disabled={isLoading}
                        onClick={handleCancelAction}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={handleConfirmAction}
                        color='error'
                    >
                        Update
                    </LoadingButton>
                </Stack>
            )}
            DialogProps={{
                sx: {
                    '& .MuiDialog-container .MuiPaper-root': {
                        maxWidth: '620px'
                    }
                }
            }}
            DialogContentProps={{
                sx: {
                    fontFamily: 'var(--primary-font)',
                    fontSize: '14px',
                    textAlign: 'center'
                }
            }}
        >
            <Box>
                {getContent()}
            </Box>
            <Box
                sx={{
                    mt: '10px',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '25px',
                    textAlign: 'center'
                }}
            >
                {getCurrency('oldRow')} ➔ {getCurrency('newRow')}
            </Box>
            <Box
                sx={{
                    color: 'var(--sub-grey)',
                    lineHeight: '18px',
                    mt: '26px'
                }}
            >
                Updating the currency exchange rate will delete and regenerate the payout
            </Box>
        </Modal>
    );
};

export default memo(UpdateIncrementsDialog);
