import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const LimitationsInfo = (data: MerchantsMethodsPivot) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}
        >
            <Typography>
                {data.maxNumberOfTransaction24}
                <strong>  - Trx/24</strong>
            </Typography>
            {data.velocity ? (
                <Typography>
                    {data.velocity}  
                    <strong> - velocity </strong>
                </Typography>
            ) : (
                <Typography
                    sx={{
                        color: 'grey.500',
                        fontStyle: 'italic'
                    }}
                >
                    N/A - velocity
                </Typography>
            )}
            <Typography>
                {`${data.minTicketSize} - ${data.maxTicketSize} `}
                <strong>/24</strong>
            </Typography>
        </Box>
    );
};

export default memo(LimitationsInfo);