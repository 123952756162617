import { memo } from "react";
import Box from "@mui/material/Box";
import Services from "./Services";
import TicketingSystemActionPanel from "./TicketingSystemActionPanel";

const Settings = () => (
    <Box>
        <Services />
        <TicketingSystemActionPanel />
    </Box>
);

export default memo(Settings);
