import { ApiState } from 'infrastructure/api';
import { useReducer, useMemo, useCallback } from 'react';
import reducer from './reducer';
import stateFactory from './stateFactory';
import {
    KYCSettingsActionTypes,
    KYCSettingsInitActionPayload,
    KYCSettingsUpdateAdditionalOptionsActionPayload,
    KYCSettingsUpdateSummedAmountEurLimitActionPayload,
    KYCSettingsUpdateTrxLimitActionPayload
} from './types';

export default function useKYCSettings() {
    const initialState = useMemo(stateFactory, []);

    const [kycSettingsState, dispatch] = useReducer(reducer, initialState);

    const initKYCSettings = useCallback((payload: KYCSettingsInitActionPayload) => {
        dispatch({
            type: KYCSettingsActionTypes.KYCSettingsInit,
            payload
        });
    }, [dispatch]);

    const updateRequestState = useCallback((payload: ApiState) => {
        dispatch({
            type: KYCSettingsActionTypes.KYCSettingsApiState,
            payload
        });
    }, [dispatch]);

    const updateTrxLimit = useCallback((payload: KYCSettingsUpdateTrxLimitActionPayload) => {
        dispatch({
            type: KYCSettingsActionTypes.UpdateTrxLimit,
            payload
        });
    }, [dispatch]);

    const updateSummedAmountEurLimit = useCallback((payload: KYCSettingsUpdateSummedAmountEurLimitActionPayload) => {
        dispatch({
            type: KYCSettingsActionTypes.UpdateSummedAmountEurLimit,
            payload
        });
    }, [dispatch]);

    const updateAdditionalOptions = useCallback((payload: KYCSettingsUpdateAdditionalOptionsActionPayload) => {
        dispatch({
            type: KYCSettingsActionTypes.UpdateAdditionalOptions,
            payload
        });
    }, [dispatch]);

    const updateKYCProvider = useCallback((payload: string) => {
        dispatch({
            type: KYCSettingsActionTypes.UpdateKYCProvider,
            payload
        });
    }, [dispatch]);

    const syncInitialKYCSettings = useCallback(() => {
        dispatch({
            type: KYCSettingsActionTypes.SyncInitialKYCServicesSnapshot,
            payload: undefined
        });
    }, [dispatch]);

    return {
        kycSettingsState,
        initKYCSettings,
        updateRequestState,
        updateAdditionalOptions,
        updateTrxLimit,
        updateSummedAmountEurLimit,
        updateKYCProvider,
        syncInitialKYCSettings
    };
};
