import Box, { type BoxProps } from "@mui/material/Box";
import { memo } from "react";
import jsonFormatHighlight from 'json-format-highlight';
import Loader from "ui/molecules/Loader";

export type CodeSnippetProps<T> = BoxProps & {
    readonly data: T;
    readonly isLoading?: boolean;
};

const CodeSnippet = <T extends unknown>({ data, sx, isLoading = !data }: CodeSnippetProps<T>) => (
    <Loader
        isLoading={isLoading}
    >
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                border: '1px solid #ccc',
                overflow: 'auto',
                p: 1,
                '& pre span': {
                    whiteSpace: 'nowrap !important'
                },
                ...sx
            }}
        >
            <pre
                dangerouslySetInnerHTML={{
                    __html: jsonFormatHighlight(
                        typeof data === 'string'
                            ? JSON.parse(data)
                            : data
                    )
                }}
            />
        </Box>
    </Loader>
);

export default memo(CodeSnippet);
