import type useKYCServices from "features/kyc/useKYCServices";
import type { UploadProps } from "lib/upload";
import type { FC, ReactNode } from "react";
import type { Option as SelectedMerchant } from "types";

export const enum BulkKYCStepperStep {
    UploadCSVFile = 0,
    UploadVerificationArchive,
    SelectKYCTypesAndSubmit
};

export type BulkKYCStepperState = {
    readonly [BulkKYCStepperStep.UploadCSVFile]: UploadProps['file'];
    readonly [BulkKYCStepperStep.UploadVerificationArchive]: UploadProps['file'] | null;
    readonly [BulkKYCStepperStep.SelectKYCTypesAndSubmit]: SelectedMerchant | null;
};

export type MainAreaProps<TStep extends BulkKYCStepperStep> = {
    readonly value: BulkKYCStepperState[TStep];
    readonly onChange: (value: BulkKYCStepperState[TStep]) => void;
} & Pick<
    ReturnType<typeof useKYCServices>,
    | 'onKYCServiceChange'
    | 'servicesState'
>;

export type ActionAreaProps = {
    readonly onComplete: () => void;
    readonly isValid: boolean;
    readonly isSubmitting: boolean;
};

export interface BulkKYCStep<TStep extends BulkKYCStepperStep = number> {
    readonly id: TStep;
    readonly label: ReactNode;
    readonly isOptional?: boolean;
    readonly isCompleted?: boolean;
    readonly componentSlots: {
        readonly mainArea: FC<
            MainAreaProps<TStep>
        >;
        readonly actionArea?: FC<ActionAreaProps>;
    }
};
