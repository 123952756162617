import { memo } from 'react';
import SignIn from "ui/forms/SignIn";
import { Stack, Typography } from '@mui/material';
import LoginLayout from "ui/layouts/Login";
import useLogin from './useLogin';

const Login = () => {
    useLogin();

    return (
        <LoginLayout>
            <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
                <Typography variant="h4">Sign in to Macropay</Typography>
            </Stack>
            <SignIn />
        </LoginLayout>
    );
};

export default memo(Login);
