import type { MerchantDomain, MerchantBusiness } from "features/merchants/types";
import type { FormikValues } from "formik";
import type useForm from "hooks/useForm";
import type { BootstrapableForm, CoreBankingEntity } from "types";

export const enum FormField {
    BusinessName = 'businessName',
    SourceUrl = 'sourceUrl',
    BusinessMerchantId = 'businessMerchantId',
    MerchantBusinessId = 'merchantBusinessId'
};

export type CreateMerchantDomainForm =
    & Pick<MerchantDomain, 'businessName'>
    & Pick<
        BootstrapableForm<MerchantDomain, {}>,
        | 'onSaveOrCreate'
    >;

export type CreateMerchantBusinessForm =
    & Pick<
        BootstrapableForm<MerchantBusiness, {}>,
        | 'onSaveOrCreate'
    >;

export type EditMerchantDomainEntityForm<TFormValues extends CoreBankingEntity, TBootstrapData = {}> =
    & Pick<
        BootstrapableForm<TFormValues, TBootstrapData>,
        | 'onSaveOrCreate'
        | 'initialValues'
    >;

export type EditMerchantDomainEntityFormProps<T extends FormikValues, TBootstrapData = {}> =
    & ReturnType<typeof useForm<T>>
    & TBootstrapData;
