import { createContext, memo, type PropsWithChildren } from "react";
import useConsumer from "./useConsumer";
import type { ConsumerContext as ConsumerContextType } from "./types";

export const ConsumerContext = createContext<ConsumerContextType>({} as ConsumerContextType);

const ConsumerProvider = ({ children }: PropsWithChildren) => (
    <ConsumerContext.Provider
        value={useConsumer()}
    >
        {children}
    </ConsumerContext.Provider>
);

export default memo(ConsumerProvider);
