import { memo } from "react";
import QueryBuilderForm from "./QueryBuilderEntity";
import { getEsMerchantPayoutFiles } from "features/merchantPayouts/api";

const EsMerchantPayoutFiles = () => (
    <QueryBuilderForm
        label='ES-MERCHANT-PAYOUT-FILES'
        query={getEsMerchantPayoutFiles}
    />
);

export default memo(EsMerchantPayoutFiles);
