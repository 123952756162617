import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { fNumber } from "util/formaters";

type Props = BoxProps & {
    readonly children: number;
    readonly isLoading?: boolean;
    readonly format?: string;
};

const TitleNumber = ({ isLoading, children, format, sx, ...props }: Props) => (
    <Box
        {...props}
        sx={{
            color: ({ palette }) => palette.text.primary,
            ...sx
        }}
    >
        {isLoading
            ? (
                <Skeleton width={100} />
            )
            : fNumber(children, format)}
    </Box>
);

export default memo(TitleNumber);
