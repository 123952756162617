import IncludeMethods from "./IncludeMethods";
import IncludeMethodsConfirmationDialog from "./IncludeMethodsConfirmationDialog";
import IncludeMethodsProvider from "./IncludeMethodsProvider";

const Component = () => (
    <IncludeMethodsProvider>
        <IncludeMethods />
        <IncludeMethodsConfirmationDialog />
    </IncludeMethodsProvider>
);

export default Component;
