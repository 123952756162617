import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BulkKYCStepperStep, type MainAreaProps } from "../types";
import useKYCServices from "features/kyc/useKYCServices";
import { KYCProcessingStatus, KYCServiceType } from "consts/kyc";
import { KYCService } from "ui/molecules/KYCService";
import MerchantSelector from "ui/widgets/MerchantSelector";

const KYCServices = ({ servicesState, onKYCServiceChange, value, onChange }: MainAreaProps<BulkKYCStepperStep.SelectKYCTypesAndSubmit>) => (
    <Box>
        <Typography
            variant="h5"
            gutterBottom
            fontWeight='bold'
            textAlign='center'
        >
            Select which KYC type you want to mark as KYC’d
        </Typography>
        {useKYCServices()
            .getKYCServicesCollection({
                getStatus: _ => KYCProcessingStatus.NA
            })
            .filter(kycService => [
                KYCServiceType.FaceVerification,
                KYCServiceType.IDVerification,
                KYCServiceType.AddressVerification,
            ].includes(kycService.type))
            .map(({ type, name, status }) => (
                <KYCService
                    key={type}
                    type={type}
                    status={status}
                    value={servicesState[type]}
                    onChange={onKYCServiceChange}
                >
                    {name}
                </KYCService>
            ))}
        <Box
            sx={{
                mt: 2
            }}
        >
            <MerchantSelector
                value={value}
                onChange={(_, merchant) => onChange(merchant)}
                tooltipProps={{
                    title: 'Merchant is mandatory for bulk KYC'
                }}
            />
        </Box>
    </Box>
);

export default KYCServices;
