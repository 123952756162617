import { memo } from "react";
import { DataGridPremium, type DataGridPremiumProps } from '@mui/x-data-grid-premium';
import useMerchantPaymentMethodHistory from "./useMerchantPaymentMethodHistory";
import type { MerchantsMethodsPivot } from "features/pivots/types";

type Props = {
    readonly merchantMethod: MerchantsMethodsPivot;
    readonly DataGridPremiumProps?: Omit<DataGridPremiumProps, 'columns' | 'rows'>;
};

const MerchantPaymentMethodHistory = ({ merchantMethod, DataGridPremiumProps }: Props) => {
    const merchantPaymentMethodHistory = useMerchantPaymentMethodHistory(merchantMethod);

    return (
        <DataGridPremium
            {...DataGridPremiumProps}
            sx={{
                minWidth: 650,
                height: 450,
                ...DataGridPremiumProps?.sx
            }}
            {...merchantPaymentMethodHistory}
        />
    );
};

export default memo(MerchantPaymentMethodHistory);
