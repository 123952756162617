import { memo } from "react";
import Box from "@mui/material/Box";
import { TextFieldLoadable } from "ui/atoms/TextField";
import { FormField } from "./types";
import StatefulSelector from "ui/widgets/StatefulSelector";
import { selectAccountWithdrawalOptions, selectPayoutCycles } from "features/general/selectors";
import type { Option } from "types";
import type { MerchantPayoutSettingsForm } from "./types";
import { getFormInputProps } from "util/forms";

const PayoutSettings = ({
    isBootstrapDataLoading,
    bootstrapData,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched
}: MerchantPayoutSettingsForm) => {
    const getOptionLabel = (bootstrapData: Array<Option> = []) => (option: Option | string) => (
        typeof option === 'string'
            ? bootstrapData.find(({ id }) => id === option)?.name
            : option.name
    ) || '';
    const getValue = (formFieldName: FormField) => values[formFieldName] ?? null;
    const isOptionEqualToValue = (option: Option, value: string) => option.id === value;

    const handleNumericInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        if (value === '' || re.test(value)) {
            handleChange(e);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 2
            }}
        >
            <StatefulSelector
                size='small'
                disableClearable
                selectOptions={selectAccountWithdrawalOptions}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={getOptionLabel(bootstrapData.withdrawal)}
                value={getValue(FormField.AccountWithdrawalOption)}
                loading={isBootstrapDataLoading}
                TextFieldProps={{
                    label: 'Account Withdrawal Options'
                }}
                onChange={(_, option) => {
                    setFieldTouched(FormField.AccountWithdrawalOption, true);
                    setFieldValue(
                        FormField.AccountWithdrawalOption,
                        option?.id,
                        true
                    );
                }}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <StatefulSelector
                size='small'
                disableClearable
                selectOptions={selectPayoutCycles}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={getOptionLabel(bootstrapData.payoutCycle)}
                value={getValue(FormField.PayoutCycle)}
                loading={isBootstrapDataLoading}
                TextFieldProps={{
                    label: 'Payout Cycle'
                }}
                onChange={(_, option) => {
                    setFieldTouched(FormField.PayoutCycle, true);
                    setFieldValue(
                        FormField.PayoutCycle,
                        option?.id,
                        true
                    );
                }}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <TextFieldLoadable
                {...getFormInputProps(FormField.RollingReserveCap, { errors, touched })}
                fullWidth
                size='small'
                loading={isBootstrapDataLoading}
                name={FormField.RollingReserveCap}
                value={values[FormField.RollingReserveCap]}
                label='Rolling Reserve Retained - CAP'
                onChange={handleNumericInput}
                onBlur={handleBlur}
            />
        </Box>
    );
};

export default memo(PayoutSettings);
