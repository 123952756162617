import Box from '@mui/material/Box';
import useAddDuplicateEmailForm, { type FormValues } from './useAddDuplicateEmailForm';
import { Form } from 'types';
import { getFormInputProps } from "util/forms";
import { FormFields } from './validators';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { SamePersonIdentifierSource } from 'consts/consumers';

type Props = Omit<
    Form<FormValues>,
    | 'isLoading'
>;

const AddDuplicateEmailForm = forwardRef((props: Props, ref) => {
    const formikForm = useAddDuplicateEmailForm(props);
    const formikFormRef = useRef(formikForm);
    formikFormRef.current = formikForm;

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        isSubmitting
    } = formikForm;

    useImperativeHandle(ref, () => formikFormRef.current);

    return (
        <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 2
                }}
            >
                <TextField
                    {...getFormInputProps(FormFields.Email, { errors, touched })}
                    size='small'
                    name={FormFields.Email}
                    value={values[FormFields.Email]}
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    {...getFormInputProps(FormFields.SamePersonIdentifierSource, { errors, touched })}
                    size='small'
                    name={FormFields.SamePersonIdentifierSource}
                    value={values[FormFields.SamePersonIdentifierSource]}
                    label="Reason"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                            >
                                {SamePersonIdentifierSource.Manual}:&nbsp;
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Box>
            <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
            >
                {props.actionLabel}
            </LoadingButton>
        </Box>
    );
});

export default AddDuplicateEmailForm;
