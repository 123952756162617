import { memo } from "react";
import { FormField, type MerchantProfileFinanceForm } from "../types";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import StatefulSelector from "ui/widgets/StatefulSelector";
import { selectCurrencies } from "features/general/selectors";

const AccountBank = ({
    isNew,
    isEditMode,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
}: MerchantProfileFinanceForm) => (
    <>
        <TextField
            {...getFormInputProps(FormField.Iban, { errors, touched })}
            disabled={!isEditMode}
            size='small'
            name={FormField.Iban}
            value={values[FormField.Iban] || ''}
            label='IBAN'
            onChange={handleChange}
            onBlur={handleBlur}
            required
            fullWidth
        />
        <TextField
            {...getFormInputProps(FormField.Swift, { errors, touched })}
            disabled={!isEditMode}
            size='small'
            name={FormField.Swift}
            value={values[FormField.Swift] || ''}
            label='SWIFT / BIC'
            onChange={handleChange}
            onBlur={handleBlur}
            required
            fullWidth
        />
        <StatefulSelector
            disabled={!isNew || !isEditMode}
            selectOptions={selectCurrencies}
            size='small'
            value={values[FormField.Currency] ?? null}
            TextFieldProps={{
                label: 'Currency',
                required: true
            }}
            onChange={(_, currency) => {
                setFieldTouched(FormField.Currency, true);
                setFieldValue(
                    FormField.Currency,
                    currency,
                    true
                );
            }}
            FormControlProps={{
                sx: {
                    width: '100%'
                }
            }}
        />
        <TextField
            {...getFormInputProps(FormField.Vat, { errors, touched })}
            disabled={!isEditMode}
            size='small'
            name={FormField.Vat}
            value={values[FormField.Vat] || ''}
            label='Vat Number'
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
        />
    </>
);

export default memo(AccountBank);
