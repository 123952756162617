import { useFormik, type FormikConfig } from "formik";
import { FormField, type MerchantProfileFinance } from "./types";
import { AccountType } from "consts/merchants";

export const getInitialValues = new Map()
    .set(AccountType.Bank, (initialValues?: MerchantProfileFinance) => ({
        [FormField.AccountHolderName]: '',
        [FormField.Iban]: '',
        [FormField.Currency]: '',
        [FormField.Vat]: '',
        [FormField.Swift]: '',
        ...initialValues
    }))
    .set(AccountType.Crypto, (initialValues?: MerchantProfileFinance) => ({
        [FormField.AccountHolderName]: '',
        [FormField.Address]: '',
        [FormField.Currency]: '',
        [FormField.Chain]: '',
        ...initialValues
    }));

export const useForm = <TForm extends MerchantProfileFinance = MerchantProfileFinance>(merchantProfileFinanceForm: FormikConfig<TForm>) => {
    return useFormik<TForm>({
        validateOnMount: true,
        enableReinitialize: true,
        ...merchantProfileFinanceForm,
    });
};
