import type { GridCellParams, GridRowModel } from "@mui/x-data-grid-premium";
import { Increments } from "features/merchantPayouts/types";
import useActions from "features/merchantPayouts/useActions";
import { isEqual } from "util/support";
import useFilterModelOverrides from "./useFilterModelOverrides";

export default function useIncrements() {
    const {
        onFilterChange,
        onFiltersReset,
        ...filtersModelOverrides
    } = useFilterModelOverrides();

    const { putMerchantPayoutIncrements } = useActions();

    const validateBusinessInvariant = (_: Increments) => true; // => change condition to disable editing
        // ['INCLUDED', 'PENDING'].includes(row.incrementStatus) &&
        // !row.isPaid;
        // TODO: this is for showcasing only
        // && (row.currency !== row.exchangeCurrency);

    const isCellEditable = ({ row }: GridCellParams) => validateBusinessInvariant(row);

    const getInitialState = () => ({
        pinnedColumns: { right: ['currencyExchangeRate'] }
    });

    const updateRequestHandler = (updatedRow: GridRowModel) => {
        const [request] = putMerchantPayoutIncrements(updatedRow);
        return request
            .then(result => {
                if (!result) {
                    throw new Error('Unable to update row');
                }

                return updatedRow;
            });
    };

    const isRowModelEqual = (
        newRow: GridRowModel,
        oldRow: GridRowModel
    ) => (!newRow.currencyExchangeRate || isEqual(newRow, oldRow));

    return {
        isCellEditable,
        initialState: getInitialState(),
        updateRequestHandler,
        isRowModelEqual,
        onFilterChange,
        onFiltersReset,
        FiltersProps: {
            filtersModelOverrides
        }
    };
};
