import { memo } from "react";
import Box from "@mui/material/Box";
import Services from "./Services";
import IncludeMethods from "./IncludeMethods";
import KYCActionPanel from "./KYCActionPanel";

const Settings = () => (
    <Box>
        <Services />
        <KYCActionPanel />
        <IncludeMethods />
    </Box>
);

export default memo(Settings);
