import { AnyAction } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { isResponseValid } from 'features/auth/helpers';
import { logout } from 'features/auth/slice';
import { ResourceResponse } from 'types';

export const globalErrorHandler: Middleware =
    ({ dispatch }: MiddlewareAPI) => (next) => (action: AnyAction) => {
        if (!isResponseValid(action.payload as ResourceResponse<any>)) {
            dispatch(logout());
        }

        return next(action)
    };
