import { memo } from "react";
import { BasicDialog } from 'ui/molecules/Dialog';
import useBulkKYCDialog from "./useBulkKYCDialog";
import BulkKYCStepper from "./BulkKYCStepper";

const BulkKYCDialog = () => {
    const {
        open,
        handleDialogClose,
        ...bulkKYCProps
    } = useBulkKYCDialog();

    return (
        <BasicDialog
            isOpen={open}
            onClose={handleDialogClose}
            DialogProps={{
                sx: {
                    "& .MuiDialog-container .MuiPaper-root": {
                        maxWidth: 634
                    }
                }
            }}
            titleSlot={<></>}
        >
            <BulkKYCStepper
                {...bulkKYCProps}
            />
        </BasicDialog>
    );
};

export default memo(BulkKYCDialog);
