import type { Status } from "types";
import type { UseQuery } from "./types";
import { Paginate } from "consts/table";
import { Filters } from "consts/transactions";
import { Filters as FiltersConsumers } from "consts/consumers";
import { Aggregates } from "consts/request-query";
import { PeriodAdapter, backendRequestSearchQueryParamsAdapterFactory } from "util/period-adapter";
import { BooleanSwitchValue } from "consts/general";
import { RequestQueryMapper } from "util/request-query-mapper";
import { withTransaction } from "features/transactions/helpers";

export const totalCountRequestQueryParamsFactory: (
    perPage?: number
) => UseQuery['getRequestQueryParams'] = (perPage = 1) => requestQueryMapper => (
    requestQueryMapper
        .contains(Paginate.perPage, String(perPage))
);

export const transactionsRequestQueryParamsFactory: (
    groupBy:
        | typeof Filters.currency
        | typeof Filters.status

) => UseQuery['getRequestQueryParams'] = groupBy => requestQueryMapper => (
    requestQueryMapper
        .aggregates(
            Aggregates.GroupBys,
            PeriodAdapter.make(backendRequestSearchQueryParamsAdapterFactory())
                .get(requestQueryMapper.searchQueryParams)
        )
        .aggregates(Aggregates.GroupBys, groupBy)
);

export const distinctEmailTransactionsRequestQueryParamsFactory: (
    transactionStatus?: Status
) => UseQuery['getRequestQueryParams'] = transactionStatus => requestQueryMapper => {
    if (transactionStatus) {
        requestQueryMapper
            .containsIn(Filters.status, transactionStatus);
    }

    return totalCountRequestQueryParamsFactory()(
        requestQueryMapper
            .distincts(Filters.consumerId)
    );
};

export const consumerEmailsRequestQueryParamsFactory: (
    transactionStatus: Status
) => UseQuery['getRequestQueryParams'] = transactionStatus => requestQueryMapper => {
    const ignoreFilters = [Filters.email, Filters.hashedEmail];

    const requestSearchQueryParamEntries = Array.from(
        requestQueryMapper
            .searchQueryParams
            .entries()
    ).map(([key, value]) => {
        for (const ignoreFilter of ignoreFilters) {
            if (key.startsWith(ignoreFilter)) {
                return [key, value];
            }
        }

        return [
            withTransaction(key),
            value
        ];
    });

    const requestQueryMapperInstance = RequestQueryMapper.from();

    for (const [key, value] of requestSearchQueryParamEntries) {
        requestQueryMapperInstance
            .searchQueryParams
            .append(key, value);
    }

    return requestQueryMapperInstance
        .distincts(FiltersConsumers.samePersonIdentifier)
        .containsIn(withTransaction(Filters.parentId), `${BooleanSwitchValue.Off}`)
        .containsIn(withTransaction(Filters.status), transactionStatus)
        .contains(Paginate.perPage, String(0))
};
