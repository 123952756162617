import { memo } from "react";
import { ResourceDetail, type ResourceDetailProps } from "ui/widgets/Resource";
import Profile from "./Profile";

const UserCreateEdit = (props: ResourceDetailProps) => (
    <ResourceDetail
        {...props}
    >
         <Profile />
    </ResourceDetail>
);

export default memo(UserCreateEdit);
