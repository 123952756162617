import { memo } from "react";
import Box from "@mui/material/Box";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import PaymentMethodName from "./PaymentMethodName";
import RiskLevel from "./RiskLevel";
import PaymentMethodSwitch from "./PaymentMethodSwitch";
import LimitationsInfo from "./LimitationsInfo";

const MerchantPaymentMethodPSPSummary = (props: MerchantsMethodsPivot) => {
    return (
        <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto 2fr 1fr',
            alignItems: 'center',
            width: '100%',
            gap: 4
        }}
        >
            <PaymentMethodName
                {...props}
            />
            <RiskLevel
                {...props}
            />
            <LimitationsInfo {...props}/>
            <PaymentMethodSwitch
                {...props}
            />
        </Box>
    );
};

export default memo(MerchantPaymentMethodPSPSummary);
