import { ApiState } from "infrastructure/api";
import { PivotsState } from "./types";

const state: PivotsState = {
    merchantsMethods: {
        loadingState: ApiState.Idle,
        slice: null
    }
};

export const sliceToken = 'pivots';

export default state;
