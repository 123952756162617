import { memo } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormField, type MerchantAccountMerchantSetupForm } from "./types";

const FraudProtectionSetup = ({
    values,
    handleBlur,
    setFieldTouched,
    setFieldValue
}: MerchantAccountMerchantSetupForm) => (
    <Box
        sx={{
            mt: 2
        }}
    >
        <FormControlLabel
            sx={{
                whiteSpace: 'nowrap'
            }}
            control={(
                <Checkbox
                    inputProps={{
                        'aria-label': 'Block fake redirect for blocking fake redirects to prevent fraudulent activities'
                    }}
                    name={FormField.BlockFakeRedirect}
                    onChange={(_, value) => {
                        setFieldTouched(FormField.BlockFakeRedirect, true);
                        setFieldValue(
                            FormField.BlockFakeRedirect,
                            value,
                            true
                        );
                    }}
                    onBlur={handleBlur}
                    checked={Boolean(values[FormField.BlockFakeRedirect])}
                />
            )}
            label='Block fake redirect'
        />
    </Box>
);

export default memo(FraudProtectionSetup);
