import type { Merchant, MerchantSettings } from "features/merchants/types";
import type { BootstrapableForm } from "types";

export const enum FormField {
    ApiId = 'apiId',
    ApiGuid = 'apiGuid',
    CpKey = 'cpKey',
    CpSecret = 'cpSecret',
    SharedKey = 'sharedKey'
};

export type MerchantAccountApiSettings = Partial<
    Pick<
        MerchantSettings,
        | 'apiId'
        | 'apiGuid'
        | 'cpKey'
        | 'cpSecret'
        | 'sharedKey'
    >
>;

export type MerchantAccountApiSettingsProps = Pick<
    BootstrapableForm<MerchantAccountApiSettings, {}>,
    'initialValues' | 'onSaveOrCreate'
> & Partial<Pick<Merchant, "fullName">>;
