import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export const useMerchantBusinessValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormField.BusinessName]: string()
                .required()
                .max(255)
                .label('Business Name')
        })
        , []);
        
export const useMerchantDomainValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormField.SourceUrl]: string()
                .required()
                .max(255)
                .url()
                .label('Domain URL')
        })
        , []);
