import { Scalar } from "types";
import { StringMappingType } from "typescript";

export class TabManager<T> {
    public static fromEntries<T>(entries: Iterable<readonly [Scalar<string>, T]>) {
        return new this(entries);
    }

    public static getTabValue<T extends string>(tabMap: Map<string, T>, activeTab: StringMappingType) {
        try {
            tabMap.forEach((value: T, key: string) => {
                if (Object.is(value, activeTab)) {
                    throw key;
                }
            });
        } catch(key) {
            return String(key);
        }
    }

    private readonly tabs: Map<Scalar<string>, T>;

    private constructor(entries: Iterable<readonly [Scalar<string>, T]>) {
        this.tabs = new Map<Scalar<string>, T>(entries);
    }

    public getTab<TTab extends T>(tab: Scalar<string>, defaultValue?: TTab) {
        return (this.tabs.get(tab) ?? defaultValue)!;
    }

    public getPreviousTab(tab: Scalar<string>) {
        const tabs = this.getKeys();
        const index = tabs.indexOf(tab);

        return tabs[index - 1];
    }

    public getKeys() {
        return Array.from(this.tabs.keys());
    }
}
