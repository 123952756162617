import { memo, PropsWithChildren } from 'react';
import { Helmet, type HelmetProps } from "react-helmet-async";

const Screen = ({ children, ...helmetProps }: PropsWithChildren<HelmetProps>) => (
    <>
        <Helmet
            {...helmetProps}
        />
        {children}
    </>
);

export default memo(Screen);
