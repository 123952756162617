import { memo } from "react";
import Cassette from "../Cassette";
import useStatisticCassettesContext from "../useStatisticCassettesContext";
import { activeMerchantUsersAggregateQueryToken } from "../useStatisticCassettes";
import { selectActiveUsers } from "features/users/helpers";

const Active = () => {
    const {
        [activeMerchantUsersAggregateQueryToken]: query,
        getTotalMerchantUsersCount
    } = useStatisticCassettesContext();

    return (
        <Cassette
            isLoading={query.isLoading}
            labelSlot='Active Users'
            amountSlot={getTotalMerchantUsersCount(selectActiveUsers, query.payload?.data)}
        />
    );
};

export default memo(Active);
