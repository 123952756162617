import { memo } from "react";
import { DeleteBusinessEntity } from "../../shared/DeleteBusinessEntity";
import type { MerchantBusiness } from "features/merchants/types";
import useMerchantDomains from "../../../useMerchantDomains";

const DeleteMerchantBusiness = (merchantBusiness: MerchantBusiness) => {
    const {  businessName, isEnabled, isDeletable, coreId } = merchantBusiness;
    const { deleteMerchantBusiness, getMerchantDomainsByBusinessId } = useMerchantDomains();

    const onDelete = () => deleteMerchantBusiness(merchantBusiness);

    const renderDialogTitle = () => (
        `Are you sure you want to delete ${businessName}`
    );

    const getIsDeletable = () => {
        if (
            getMerchantDomainsByBusinessId(coreId).length ||
            isEnabled ||
            !isDeletable
        ) {
            return false;
        }

        return true;
    };

    const renderHint = () => {
        if (isEnabled) {
            return 'Business is enabled, please disable first';
        }

        if (!isDeletable) {
            return 'Have linked transactions';
        }

        if (getMerchantDomainsByBusinessId(coreId).length) {
            return 'Have linked domains';
        }
    };

    return (
        <DeleteBusinessEntity
            isDeletable={getIsDeletable()}
            onDelete={onDelete}
            renderDialogTitle={renderDialogTitle}
            renderHint={renderHint}
        />
    );
};

export default memo(DeleteMerchantBusiness);
