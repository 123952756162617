import { useTypedSelector } from 'hooks';
import {
    selectIsProfileLoading,
    selectMerchantsUserBelongs,
    selectMorsUserBelongs
} from 'features/users/selectors';
import useUserActions from 'features/users/useActions';
import type { ID, ResourceResponse, Option } from 'types';
import type { User } from 'features/users/types';

type UpdateAssociationArgs = {
    readonly user: User;
    readonly associations: Option[];
};

export default function useUserProfile(user: Partial<User>) {
    const {
        postUser,
        associateUserMerchant,
        associateUserMor,
        disassociateUserMerchant,
        disassociateUserMor
    } = useUserActions();
    const isLoading = useTypedSelector(selectIsProfileLoading);

    const merchantIds: Array<ID> = useTypedSelector(selectMerchantsUserBelongs(user.coreId));
    const mors: Array<string> = useTypedSelector(selectMorsUserBelongs(user.coreId));

    const updateUserMerchantAssosiation = async ({
        user,
        associations
    }: UpdateAssociationArgs) => {
        // Clear Pivot
        for (const merchantId of merchantIds) {
            const [request] = disassociateUserMerchant({
                userId: user.coreId,
                merchantId
            });

            await request;
        }

        // Set new associations
        for (const { id: merchantId } of [associations].flat()) {
            const [request] = associateUserMerchant({
                userId: user.coreId,
                merchantId
            });

            await request;
        }
    };

    const updateUserMorAssociation = async ({
        user,
        associations
    }: UpdateAssociationArgs) => {
        // Clear Pivot
        for (const MOR of mors) {
            const [request] = disassociateUserMor({
                userId: user.coreId,
                MOR
            });

            await request;
        }

        // Set new associations
        for (const { id: MOR } of [associations].flat()) {
            const [request] = associateUserMor({
                userId: user.coreId,
                MOR: String(MOR)
            });

            await request;
        }
    };

    const createOrUpdateUser = async (userRequest: Partial<User>): Promise<ResourceResponse<User>> => {
        const [request] = postUser(userRequest);

        return (await request);
    };

    return {
        createOrUpdateUser,
        updateUserMerchantAssosiation,
        updateUserMorAssociation,
        isLoading
    };
};
