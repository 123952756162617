import { createContext, PropsWithChildren } from 'react';
import useManageFileTaxes from './useManageFileTaxes';

export const ManageFileTaxesContext = createContext({} as ReturnType<typeof useManageFileTaxes>);

const ManageFileTaxesProvider = ({ children }: PropsWithChildren) => {
    const manageFileTaxesProps = useManageFileTaxes();

    return (
        <ManageFileTaxesContext.Provider
            value={manageFileTaxesProps}
        >
            {children}
        </ManageFileTaxesContext.Provider>
    );
};

export default ManageFileTaxesProvider;
