import type { AbstractFileEntity } from "features/merchantPayouts/types";
import Icon from "ui/atoms/Icon";
import {
    ManageFileTaxes,
    ProcessingAlert,
    DeleteDialog
} from "./Dialogs";
import { ActionMenuArg, ActionMenuItemDialogable, ActionRequestHandlerType, MenuActions } from "./Props";
import useGenerateSettlementXLS from "./actionHooks/useGenerateSettlementXLS";
import useManageFileTaxes from "./actionHooks/useManageFileTaxes";
import usePreviewSettlementXLS from "./actionHooks/usePreviewSettlementXLS";
import useSettlementXLS from "./actionHooks/useSettlementXLS";
import useStillPendingSettlementCSV from "./actionHooks/useStillPendingSettlementCSV";
import useTransactionsCSV from "./actionHooks/useTransactionsCSV";

export default function useActionMenuItemRepository<T extends AbstractFileEntity>({
    row,
    requestMappings
}: Omit<ActionMenuArg<T>, 'getMenuItems'>) {
    const getRequestHandler = <T extends unknown>(
        menuAction: MenuActions,
        type: ActionRequestHandlerType = 'menu'
    ) => {
        const requestHandler = requestMappings.get(menuAction);

        if (requestHandler) {
            return requestHandler[type] as T;
        }
    };

    const generateSettlementXLS = useGenerateSettlementXLS({
        row,
        handler: getRequestHandler(MenuActions.GenerateSettlementXLS)
    });

    const manageFileTaxes = useManageFileTaxes({ row });

    const previewSettlementXLS = usePreviewSettlementXLS({
        row,
        handler: getRequestHandler(MenuActions.PreviewSettlementXLS)
    });

    const settlementXLS = useSettlementXLS({
        row,
        handler: getRequestHandler(MenuActions.SettlementXLS)
    });

    const stillPendingSettlementCSV = useStillPendingSettlementCSV({
        row,
        handler: getRequestHandler(MenuActions.StillPendingSettlementCSV)
    });

    const transactionsCSV = useTransactionsCSV({
        row,
        handler: getRequestHandler(MenuActions.TransactionsCSV)
    });

    const actionMenuItemRepository = new Map<
        MenuActions,
        () => ActionMenuItemDialogable
    >()
        .set(MenuActions.SettlementXLS, () => ({
            handler: settlementXLS,
            label: "Settlement .XLS",
            icon: <Icon name="Download" />,
            optionType: MenuActions.SettlementXLS,
            dialogSlot: (
                <ProcessingAlert
                    message=".XLS is processing..."
                />
            ),
        }))
        .set(MenuActions.TransactionsCSV, () => ({
            handler: transactionsCSV,
            label: "Transactions .CSV",
            icon: <Icon name="Download" />,
            optionType: MenuActions.TransactionsCSV,
            dialogSlot: (
                <ProcessingAlert
                    message=".CSV is processing..."
                />
            )
        }))
        .set(MenuActions.StillPendingSettlementCSV, () => ({
            handler: stillPendingSettlementCSV,
            label: "Still pending settlement .CSV",
            icon: (
                <Icon name="Download" />
            ),
            optionType: MenuActions.StillPendingSettlementCSV,
            dialogSlot: (
                <ProcessingAlert
                    message=".CSV is processing..."
                />
            )
        }))
        .set(MenuActions.ManageFileTaxes, () => ({
            handler: manageFileTaxes,
            label: 'Manage File Taxes',
            icon: (
                <Icon name='AddToPhotos' />
            ),
            optionType: MenuActions.ManageFileTaxes,
            dialogSlot: (
                <ManageFileTaxes
                    file={row as AbstractFileEntity}
                />
            )
        }))
        .set(MenuActions.Delete, () => ({
            handler: async () => { },
            label: "Delete",
            icon: (
                <Icon name="DeleteForever" />
            ),
            optionType: MenuActions.Delete,
            dialogSlot: (
                <DeleteDialog
                    file={row}
                    requestHandler={getRequestHandler(MenuActions.Delete, 'dialog')}
                />
            )
        }))
        .set(MenuActions.PreviewSettlementXLS, () => ({
            handler: previewSettlementXLS,
            label: "Preview settlement .XLS",
            icon: (
                <Icon name="Download" />
            ),
            optionType: MenuActions.PreviewSettlementXLS,
            dialogSlot: (
                <ProcessingAlert
                    message=".XLS is processing..."
                />
            )
        }))
        .set(MenuActions.GenerateSettlementXLS, () => ({
            handler: generateSettlementXLS,
            label: "Generate settlement .XLS",
            icon: (
                <Icon name="Save" />
            ),
            optionType: MenuActions.GenerateSettlementXLS,
            dialogSlot: (
                <ProcessingAlert
                    message="Generate settlement .XLS processing..."
                />
            )
        }));

    const getActionMenuItemEntity = (actionType: MenuActions) => {
        if (!actionMenuItemRepository.has(actionType)) {
            throw new Error(`${actionType} doesn't exists in ActionMenuItemRepository`);
        }

        return actionMenuItemRepository.get(actionType)!;
    };

    return {
        getActionMenuItemEntity
    };
};
