import { memo } from "react";
import Button from '@mui/material/Button';
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

const TriggerKYC = () => {
    const { triggerKYC, selectionModel } = useConsumerContext();

    return (
        <Button
            variant="contained"
            onClick={triggerKYC}
            disabled={!selectionModel.length}
        >
            Trigger KYC
        </Button>
    );
};

export default memo(TriggerKYC);
