import type { Exportable, Slice, Tabbable } from "infrastructure/store";

export function sliceStateGuard<T extends object>(state: T): state is Slice<T> {
    return !tabbableStateGuard(state);
}

export function tabbableStateGuard<T extends object, TTabs extends string>(state: T): state is Tabbable<TTabs, T> {
    return 'activeTab' in state;
}

export function exportableStateGuard<T extends object>(state: T): state is Exportable<T> {
    return 'exportLoadingState' in state;
}
