import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import { memo } from "react";
import Button from "ui/atoms/Button";
import useDownloadCSVTemplateContext from "./useDownloadCSVTemplateContext";

const DownloadCSVTemplateAction = () => {
    const { open, onOpen } = useDownloadCSVTemplateContext();

    return (
        <Button
            variant="outlined"
            onClick={onOpen}
            disabled={open}
        >
            <IconButton
                aria-label="close"
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <DownloadIcon/>
            </IconButton>
            .CSV Template
        </Button>
                        
    );
};

export default memo(DownloadCSVTemplateAction);
