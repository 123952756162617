import type { Merchant } from "features/merchants/types";
import { useResourceContext } from "ui/widgets/Resource";

type UseMerchantDetailArg = {
    readonly onBeforeClose?: (merchant?: Merchant) => Promise<void>;
};

export default function useMerchantDetail({
    onBeforeClose = () => Promise.resolve()
}: UseMerchantDetailArg = {}) {
    const {
        resource: merchant,
        ...restMerchantResourceProps
    } = useResourceContext<Merchant>();

    const onClose = () =>
        onBeforeClose(merchant)
            .then(() => restMerchantResourceProps.onClose())
            .catch(() => {});

    return {
        ...restMerchantResourceProps,
        onClose,
        merchant
    };
};
