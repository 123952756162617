import { buttonClasses, ButtonProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import Button from 'ui/atoms/Button';

type ToggleButtonProps = ButtonProps & {
    readonly isActive?: boolean
};

const ToggleButton = styled(Button)<ToggleButtonProps>(({ isActive, theme }) => {
    const commonStyles = {
        [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
            color: 'inherit'
        }
    };

    return isActive
        ? {
            borderColor: theme.palette.primary.main,
            [`&:not(:hover)`]: {
                ...commonStyles,
                color: theme.palette.primary.main
            }
        }
        : {
            [`&:not(:hover)`]: {
                ...commonStyles,
                color: theme.palette.text.primary
            }
        };
});

export default ToggleButton;
