import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Tooltip from '@mui/material/Tooltip';
import { KYCProcessingStatus as KYCProcessingStatusEnum } from "consts/kyc";
import withGridCellParams from "./withGridCellParams";
import { useCellContext } from '../contexts/CellProvider';
import { downloadKYCFile } from 'features/kyc/api';
import { DownloadableAction } from 'ui/molecules/Action';
import type { UseDownloadable } from 'ui/molecules/Action/Downloadable';

export type KYCProcessingStatusCellProps = Partial<UseDownloadable> & {
    readonly title: ReactNode | null;
    readonly fileKey: string | null;
};

const KYCProcessingStatus = withGridCellParams<KYCProcessingStatusEnum>(props => {
    const { value, field } = props;

    const cellContext = useCellContext();

    const {
        title,
        fileKey,
        downloadFile = downloadKYCFile
    } = cellContext[field]?.getCellProps?.<KYCProcessingStatusCellProps>(props) ?? {};

    let Component = (typeof value === 'number')
        ? (
            <>
                {[
                    <AccessTimeFilledIcon
                        key={KYCProcessingStatusEnum.Pending}
                        color='info'
                    />,
                    <CheckCircleIcon
                        key={KYCProcessingStatusEnum.Success}
                        color='success'
                    />,
                    <CancelIcon
                        key={KYCProcessingStatusEnum.Failed}
                        color='error'
                    />
                ][value || KYCProcessingStatusEnum.Pending]}
            </>
        )
        : (
            <TripOriginIcon color='disabled' />
        );

    Component = fileKey
        ? (
            <DownloadableAction
                file={fileKey}
                downloadFile={fileKey => downloadFile(fileKey, fileKey)}
                sx={{
                    '&.MuiButton-root': {
                        '&:hover': {
                            '& > svg': {
                                display: 'block',
                                '&:first-child': {
                                    display: 'none'
                                }
                            }
                        },
                        '& > svg': {
                            display: 'none',
                            '&:first-child': {
                                display: 'block'
                            }
                        }
                    }
                }}
            >
                {Component}
                <SaveAltIcon
                    color='disabled'
                />
            </DownloadableAction>
        )
        : Component;

    return (
        <Tooltip
            title={title}
            placement='top'
            arrow
        >
            <Box
                sx={{
                    display: 'grid',
                    placeContent: 'center',
                    cursor: title
                        ? 'pointer'
                        : 'default'
                }}
                component='span'
            >
                {Component}
            </Box>
        </Tooltip>
    );
});

export default KYCProcessingStatus;
