import { memo, type PropsWithChildren, type ChangeEvent } from "react";
import Checkbox, { type CheckboxProps } from '@mui/material/Checkbox';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { KYCProcessingStatus as KYCProcessingStatusEnum, KYCServiceType } from "consts/kyc";
import { KYCProcessingStatus } from "ui/widgets/Table/renderers";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import useKYCServices from "features/kyc/useKYCServices";

export type KYCServiceProps = {
    readonly type: KYCServiceType;
    readonly status: KYCProcessingStatusEnum | null | undefined;
    readonly onChange: ReturnType<typeof useKYCServices>['onKYCServiceChange'];
    readonly value: Pick<
        CheckboxProps,
        | 'disabled'
        | 'checked'
    >;
    readonly isServiceLoading?: boolean;
    readonly provider?: string | null;
} & Pick<BoxProps, 'sx'>;

const KYCService = ({
    type,
    status,
    value,
    onChange,
    children,
    provider,
    isServiceLoading = false,
    sx = {}
}: PropsWithChildren<KYCServiceProps>) => {
    const hadleKYCServiceValueChange: (kycServiceType: KYCServiceType) => CheckboxProps['onChange'] = (kycServiceType: KYCServiceType) =>
        (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            onChange([
                kycServiceType,
                { checked }
            ]);
        };

    const KYCProcessingStatusProps = {
        value: status
    } as GridRenderCellParams;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr auto',
                alignItems: 'center',
                columnGap: 1,
                py: 1,
                borderBottom: '1px solid',
                borderColor: 'divider',
                ...sx
            }}
        >
            <Box>
                <KYCProcessingStatus
                    {...KYCProcessingStatusProps}
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, auto)',
                    columnGap: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap'
                }}
            >
                <Box>{children}</Box>
                {Boolean(provider) && (
                    <Chip
                        label={provider}
                        size="small"
                        variant="outlined"
                        color="primary"
                    />
                )}
            </Box>
            <Box>
                {isServiceLoading
                    ? <CircularProgress size={35} />
                    : (
                        <Checkbox
                            {...value}
                            value={type}
                            onChange={hadleKYCServiceValueChange(type)}
                        />
                    )}
            </Box>
        </Box>
    );
};

export default memo(KYCService);
