import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";

const InputGroup = ({ children }: PropsWithChildren) => (
    <Box
        sx={{
            borderRadius: 1,
            alignSelf: 'flex-start',
            border: 1,
            borderColor: 'divider',
            borderStyle: 'solid',
            p: 2
        }}
    >
        {children}
    </Box>
);

export default memo(InputGroup);
