import type { SVGProps, ReactSVGElement } from "react";

const Mini = ({ width, height, fill }: SVGProps<ReactSVGElement>) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 72 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d="M0 15.737H13.7324L21.1219 8.04015L28.7582 16.0028L13.4158 32H27.1375L50.2557 8.04015L57.892 16.0028L50.2396 23.8879L44.7552 18.2288L37.8702 25.3276L44.342 32H56.2713L71.619 16.0028L56.2713 0H44.1005L35.6217 8.83752L27.1375 0H15.0955L0 15.737Z" fill={fill} />
    </svg>
);

export default Mini;
