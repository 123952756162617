import { useEffect, useRef } from "react";
import { FormAdapter } from "hooks/useForm";
import { convertToBooleanSwitch } from "util/transformers";
import useMerchantDetailsTabContext from "../useMerchantDetailsTabContext";
import type { MerchantDomainsRepository } from "./repository";
import type { MerchantBusiness, MerchantDomain } from "features/merchants/types";
import { useMerchantContext } from "../MerchantProvider";

export default function useMerchantDomains() {
    const merchant = useMerchantContext();
    const merchantRef = useRef(merchant);
    merchantRef.current = merchant;

    const repository = useMerchantDetailsTabContext<MerchantDomainsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const createMerchantBusiness = async ({ businessName }: Partial<MerchantBusiness>) => {
        const response = await repository.createMerchantBusiness({
            businessName: String(businessName),
            merchantId: merchant.coreId
        });

        return FormAdapter.fromResponse(response);
    };

    const createMerchantDomain = async ({ merchantBusinessId, sourceUrl }: Partial<MerchantDomain>) => {
        const response = await repository.createMerchantDomain({
            merchantBusinessId: String(merchantBusinessId),
            sourceUrl: String(sourceUrl)
        });

        return FormAdapter.fromResponse(response);
    };

    const deleteMerchantBusiness = async (merchantBusiness: MerchantBusiness) => (
        repository.deleteMerchantBusiness(merchantBusiness)
    );

    const deleteMerchantDomain = async (merchantDomain: MerchantDomain) => (
        repository.deleteMerchantDomain(merchantDomain)
    );

    const toggleEnabledMerchantBusiness = (
        merchantBusiness:
            & Omit<Partial<MerchantBusiness>, 'isEnabled'>
            & {
                isEnabled: boolean;
            }
    ) => (
        repository.updateMerchantBusiness({
            ...merchantBusiness,
            isEnabled: convertToBooleanSwitch(merchantBusiness.isEnabled)
        }, { notifyOnSuccess: true })
    );

    const updateMerchantBusiness = async (merchantBusiness: Partial<MerchantBusiness>) => (
        FormAdapter.fromResponse(
            await repository.updateMerchantBusiness(merchantBusiness, { notifyOnSuccess: false })
        )
    );

    const updateMerchantDomain = async (merchantDomain: Partial<MerchantDomain>) => (
        FormAdapter.fromResponse(
            await repository.updateMerchantDomain(merchantDomain)
        )
    );

    const getMerchantDomainsByBusinessId = (merchantBusinessId: MerchantBusiness['coreId']) => (
        repository.getMerchantDomainsByBusinessId(merchantBusinessId)
    );

    const getMerchantBusinesses = () => (
        repository.merchantBusinesses
    );

    const fetchMerchantBusinesses = () => (
        repository.fetchMerchantBusinesses()
    );

    useEffect(() => {
        repositoryRef.current
            .fetchMerchantDomains(merchantRef.current);
    }, []);

    return {
        repository,
        fetchMerchantBusinesses,
        getMerchantDomainsByBusinessId,
        getMerchantBusinesses,
        createMerchantBusiness,
        updateMerchantBusiness,
        updateMerchantDomain,
        toggleEnabledMerchantBusiness,
        createMerchantDomain,
        deleteMerchantBusiness,
        deleteMerchantDomain
    };
};
