import { useTypedSelector } from "hooks";
import type { RootState } from "infrastructure/store";
import useInit from 'hooks/useInit';

type UseInitArgs = {
    readonly selectIsTableUninitialized: (state: RootState) => boolean;
    readonly onInit?: () => void;
};

export default function useTableInit({
    selectIsTableUninitialized,
    onInit
}: UseInitArgs) {
    const isTableUninitialized = useTypedSelector(selectIsTableUninitialized);

    useInit({
        shouldInit: () => isTableUninitialized,
        onInit
    });
};
