import { useFormik } from "formik";
import { getInitialValues, useValidationSchema } from "./validators";
import type { Consumer } from "features/consumers/types";
import { UseFormArg } from "hooks/useForm";

export type FormValues = Pick<
    Consumer,
    | 'firstName'
    | 'lastName'
    | 'DOB'
>;

type UseFilesFormArgs =
    & UseFormArg<Partial<FormValues>>;

export default function useConsumerIDVerificationForm({
    ...restFormArgs
}: UseFilesFormArgs) {
    const { initialValues } = restFormArgs;
    const validationSchema = useValidationSchema();

    return useFormik({
        ...restFormArgs,
        onSubmit: restFormArgs.onSaveOrCreate,
        validationSchema,
        validateOnMount: true,
        isInitialValid: validationSchema.isValidSync(initialValues),
        initialValues: getInitialValues(initialValues)
    });
};
