import React, { useState, ChangeEvent } from 'react';
import columns from './columns.json';
import conditions from './conditions.json';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {Input} from "@mui/material";

interface ConditionsProps {
    onUpdateRows: (conditions: any[]) => void;
}

const Conditions: React.FC<ConditionsProps> = ({ onUpdateRows }) => {
    const [rows, setRows] = useState<{
        dropdown1: string;
        dropdown2: string;
        inputValue: string;
        condition: string;
    }[]>([
        { dropdown1: '', dropdown2: '', inputValue: '', condition: '' }
    ]);

    const handleDropdown1Change = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].dropdown1 = e.target.value;
        updatedRows[index].condition = `${updatedRows[index].dropdown2}[${e.target.value}]=${updatedRows[index].inputValue}`;
        setRows(updatedRows);
        onUpdateRows(updatedRows);
    };

    const handleDropdown2Change = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].dropdown2 = e.target.value;
        updatedRows[index].condition = `${e.target.value}[${updatedRows[index].dropdown1}]=${updatedRows[index].inputValue}`;
        setRows(updatedRows);
        onUpdateRows(updatedRows);
    };

    const handleInputChange = (value: string, index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].inputValue = value;
        updatedRows[index].condition = `${updatedRows[index].dropdown2}[${updatedRows[index].dropdown1}]=${value}`;
        setRows(updatedRows);
        onUpdateRows(updatedRows);
    };

    const handleAddRowClick = () => {
        setRows([...rows, { dropdown1: '', dropdown2: '', inputValue: '', condition: '' }]);
    };

    const handleDeleteRowClick = (index: number) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
        onUpdateRows(updatedRows);
    };

    const handleSubmitClick = () => {
       //
    };

    return (
        <Container>
            Conditions
            {rows.map((row, index) => (
                <Container key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <select
                        value={row.dropdown1}
                        onChange={(e) => handleDropdown1Change(e, index)}
                        style={{ marginRight: '8px' }}
                    >
                        <option value="" disabled>Select condition</option>
                        {conditions.map(option => (
                            <option key={option.field} value={option.field}>{option.field}</option>
                        ))}
                    </select>
                    <select
                        value={row.dropdown2}
                        onChange={(e) => handleDropdown2Change(e, index)}
                        style={{ marginRight: '8px' }}
                    >
                        <option value="" disabled>Select column</option>
                        {columns.map(option => (
                            <option key={option.field} value={option.field}>{option.field}</option>
                        ))}
                    </select>
                    <Input
                        type="text"
                        value={row.inputValue}
                        onChange={(e) => handleInputChange(e.target.value, index)}
                        placeholder="Value"
                    />
                    <Button onClick={() => handleDeleteRowClick(index)}>{<DeleteIcon />}</Button>
                </Container>
            ))}
            <Button onClick={handleAddRowClick}>Add Condition</Button>
            <Button onClick={handleSubmitClick}>Submit</Button>
        </Container>
    );
};

export default Conditions;
