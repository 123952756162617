export const Filters = {
    parentId: 'parentId',
    transactionId: 'transactionId',
    refId: 'refId',
    sourceIp: 'sourceIp',
    paymentMethodName: 'paymentMethodName',
    email: 'email',
    status: 'status',
    reason: 'reason',
    amountEur: 'amountEur',
    fundStatus: 'fundStatus',
    PSP: 'PSP',
    consumerFirstName: 'consumerFirstName',
    consumerLastName: 'consumerLastName',
    countryIso2: 'countryIso2',
    settlementStatus: 'settlementStatus',
    settlementStatusDateTime: 'settlementStatusDateTime',
    settledDate: 'settledDate',
    isSettled: 'isSettled',
    consumerIp: 'consumerIp',
    methodId: 'methodId',
    amount: 'amount'
};

export const text = [
    Filters.refId,
    Filters.transactionId,
    Filters.email,
    Filters.parentId,
    Filters.consumerFirstName,
    Filters.consumerLastName,
    Filters.amount
];

export const multi = [
    Filters.settlementStatus,
    Filters.fundStatus,
    Filters.status,
    Filters.PSP,
    Filters.methodId
];

export const single = [
    Filters.isSettled
];
