import { memo, createContext, type PropsWithChildren, type ChangeEvent } from "react";
import type { GridCellParams } from "@mui/x-data-grid-premium";

type ActionableCellProps = {
    onChange?(gridCellParams: GridCellParams, event: ChangeEvent<HTMLInputElement>): void;
    onChange?(
        gridCellParams: GridCellParams,
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void;
}

interface CellProps extends ActionableCellProps {
    readonly getCellProps?: <T>(gridCellParams?: GridCellParams) => T;
}

export const CellContext = createContext<Record<string, CellProps>>({});

/**
 * {
 *   [key: string]: {}
 * }
 */
export type CellProviderProps<T extends object> = Record<string, T>;

const CellProvider = <T extends object>({ children, ...cellProps }: PropsWithChildren<CellProviderProps<T>>) => (
    <CellContext.Provider
        value={cellProps}
    >
        {children}
    </CellContext.Provider>
);

export default memo(CellProvider);
