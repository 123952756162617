import type { AbstractFileEntity, Files } from "features/merchantPayouts/types";
import { useRef } from "react";
import { useLabeledSwitch } from "ui/atoms/LabeledSwitch";
import { useModal } from "ui/atoms/Modal";
import moment from "moment";
import useActions from "features/merchantPayouts/useActions";
import { useTypedSelector } from "hooks";
import { selectIsMerchantPayoutEntityLoading } from "features/merchantPayouts/selectors";
import { ResourceResponse } from "types";

export default function usePayableControl(entity: Files) {
    const { open, onOpen, onClose } = useModal();
    const isRequestLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);
    const { togglePayMerchantPayoutFiles } = useActions();
    const stubHandler = (_: boolean) => { };

    const actionControlsRef = useRef({
        resolve: stubHandler,
        reject: stubHandler
    });

    // const handleRequest = useSimpleRequest();

    // const { isLoading, handleProcess } = useProcess((isChecked = Boolean(entity.isPaid)) =>
    //     handleRequest(() =>
    //         paidMerchantPayoutFiles(
    //             entity.coreId,
    //            {[Filters.isPaid]:`${Number(isChecked)}`}
    //         ), { notifyOnSuccess: true }));

    const confirmationHandler = () => {
        onOpen();

        return new Promise<boolean>((resolve, reject) => {
            actionControlsRef.current = {
                resolve,
                reject
            };
        });
    };

    const getDisabled = () => {
      const { parentId, updatedAt, isPaid, fileKey } = entity;
      return (parentId === null) && Boolean(Number(isPaid))
        ? !moment(updatedAt).isSame(new Date(), "day")
        : Boolean(fileKey === null);
    };

    const labeledSwitchProps = useLabeledSwitch({
        i18n: ['Unpaid', 'Paid'],
        getDefaultChecked: () => Boolean(Number(entity.isPaid)),
        confirmationHandler
    });

    const onProceed = async () => {
        const [request] = togglePayMerchantPayoutFiles({
            coreId: entity.coreId,
            isPaid:`${Number(labeledSwitchProps.getChecked())}`
        });

        const { success } = await request as ResourceResponse<AbstractFileEntity>;

        actionControlsRef.current.resolve(success);

        onClose();
        // handleProcess(labeledSwitchProps.getChecked())
        //     .then(({ success }) => actionControlsRef.current.resolve(success))
        //     .finally(onClose);
    };

    const onCancel = () => {
        actionControlsRef.current.reject(false);
        onClose();
    };

    return {
        ...labeledSwitchProps,
        getDisabled,
        open,
        isLoading: isRequestLoading,
        onProceed,
        onCancel
    };
};
