import BusinessLogicException from "exceptions/BusinessLogicException";
import useMerchantUserActions from "features/merchants/useMerchantUserActions";
import { selectMerchantRoleOptions } from "features/users/selectors";
import type { UserResponse } from "features/users/types";
import { useTypedSelector } from "hooks";
import type { UserFormProps } from "ui/forms/User";
import { useResourceContext } from "ui/widgets/Resource";

export default function useMerchantUser(props: UserFormProps) {
    const { getIsNew } = useResourceContext();
    const { updateOrAddMerchantUserById } = useMerchantUserActions();
    const onResolve = () => {
        throw new BusinessLogicException('Merchant user created or updated', {});
    };

    return {
        domainsSlot: <></>,
        onUserUpdateOrCreate: ({ data }: UserResponse) => updateOrAddMerchantUserById(data),
        ...props,
        actionLabel: getIsNew()
            ? 'Create Merchant User'
            : 'Save',
        onResolve,
        bootstrapData: {
            ...props.bootstrapData,
            roles: useTypedSelector(selectMerchantRoleOptions),
        }
    };
};
