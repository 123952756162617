import { memo } from "react";
import MarkAsKYCTitle from "./MarkAsKYCTitle";
import TabLayout from "../TabLayout";
import FilableKYCServices from "./FilableKYCServices";
import { TabIndex, TabProps } from "../types";

const MarkAsKYC = (props: TabProps<TabIndex.MarkAsKYCed>) => (
    <TabLayout
        getTabPayload={props.getTabPayload}
    >
        <MarkAsKYCTitle />
        <FilableKYCServices {...props} />
    </TabLayout>
);

export default memo(MarkAsKYC);
