import { memo } from "react";
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import Icon from "ui/atoms/Icon";
import ViewResults from "./ViewResults";
import DownloadResultsCSV from "./DownloadResultsCSV";

const TableActions = (gridRowParams: GridRowParams) => (
    <Tooltip
        arrow
        placement="left"
        title={(
            <List dense disablePadding>
                <ViewResults {...gridRowParams} />
                <DownloadResultsCSV {...gridRowParams} />
            </List>
        )}
    >
        <GridActionsCellItem
            label="More"
            icon={(
                <Icon name="MoreVert" />
            )}
        />
    </Tooltip>
);

export default memo(TableActions);
