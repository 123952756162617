import { createContext, memo, PropsWithChildren } from 'react';
import { useModal } from 'ui/atoms/Modal';

type GenerateFileContextType = Pick<ReturnType<typeof useModal>, 'open' | 'onOpen'>;

export const GenerateFileContext = createContext<GenerateFileContextType>({} as GenerateFileContextType);

const GenerateFileProvider = ({ children, ...restProps }: PropsWithChildren<GenerateFileContextType>) => (
    <GenerateFileContext.Provider value={restProps}>
        {children}
    </GenerateFileContext.Provider>
);

export default memo(GenerateFileProvider);
