import { memo, PropsWithChildren } from "react";
import { CreateTaxableEntityContextType, CreateTaxableEntityProvider } from "./CreateTaxableEntity";
import { DownloadCSVTemplateDialog, DownloadCSVTemplateDialogProps } from "./DownloadCSVTemplate";
import ImportCSVProvider from "./ImportCSV/ImportCSVProvider";
import type { ImportCSVProviderProps } from "./ImportCSV";

export type FrontPanelWidgetProviderProps =
    & Pick<CreateTaxableEntityContextType, "createTaxableActionLabel">
    & DownloadCSVTemplateDialogProps
    & ImportCSVProviderProps;

export const FrontPanelWidgetProvider = ({
    children,
    createTaxableActionLabel,
    downloadTemplateHandler,
    importBulkRequestThunk
}: PropsWithChildren<FrontPanelWidgetProviderProps>) => (
    <CreateTaxableEntityProvider
        createTaxableActionLabel={createTaxableActionLabel}
    >
        <ImportCSVProvider
            importBulkRequestThunk={importBulkRequestThunk}
        >
            <DownloadCSVTemplateDialog
                downloadTemplateHandler={downloadTemplateHandler}
            >
                {children}
            </DownloadCSVTemplateDialog>
        </ImportCSVProvider>
    </CreateTaxableEntityProvider>
);

export default memo(FrontPanelWidgetProvider);
