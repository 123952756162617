import type { Transaction } from "features/transactions/types";
import type { ReactNode } from "react";
import type { Aggregatable, Paginator, Scalar } from "types";

export const enum TransactionStatsUnit {
    AmountProcessed,
    Suceeded = 'SUCCEEDED',
    Failed = 'FAILED',
    Rejected = 'REJECT',
    Chargeback = 'CHARGE_BACK'
};

export type TransactionStats = Transaction & Aggregatable;

export type PaginatedTransactionStats = Paginator<TransactionStats>;

export type TransactionStatsCard = {
    readonly value: Scalar<number> | undefined;
    readonly palette: string;
    readonly title: ReactNode;
};

export interface TransactionStatsAggregator {
    groupCountByStatus(status: TransactionStatsUnit): TransactionStatsCard['value'];
    amountProcessed(): TransactionStatsCard['value'];
}
