import type { ID, Option } from "types";
import { compareIds } from "./support";

export const isOption = (option: unknown): option is Option => (
    option instanceof Object &&
    ('id' in option) &&
    ('name' in option)
);

export const getOptionName = (option: Option | undefined) =>
    option?.name ?? '';

export const isOptionEqualToValue = (option: Option, value: Option) =>
    compareIds(option.id, value.id);

export const getOptionById = (options: Array<Option>, id: Option['id'] | undefined | null) =>
    options.find(option => compareIds(option.id, `${id}`));

export const getOptions = (collection: Array<Option>, ids: Array<ID>) =>
    collection.reduce((acc: Option[], option) => {
        if (ids.some(id => compareIds(id, option.id))) {
            return [
                ...acc,
                option
            ];
        }
        return acc;
    }, []);
