import { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SummaryCard, { type SummaryCardProps } from "ui/molecules/SummaryCard";
import { useStatisticDashboardContext } from "../StatisticDashboardProvider";
import type { useQuery } from "hooks/useQuery";
import type { ResourceResponse } from "types";
import numeral from "numeral";

type Props = SummaryCardProps & {
    readonly lazyQuery: ReturnType<typeof useQuery>;
    readonly countGetter: <
        TResponse extends ResourceResponse<any>
    >(data: TResponse) => number;
    readonly countFormat?: string;
};

const Cassette = ({
    isLoading,
    tooltipProps,
    footerSlot,
    lazyQuery,
    countGetter,
    countFormat = '0,0',
    ...props
}: Props) => {
    const statisticDashboard = useStatisticDashboardContext();

    const [count, setCount] = useState<number | null>(null);

    const onClick = () =>
        lazyQuery.query(statisticDashboard.requestSearchParamsRef.current)
            .then(response => setCount(countGetter(response)));

    const isCountLoaded = typeof count === 'number';

    useEffect(() => {
        if (isLoading) {
            setCount(null);
        }
    }, [isLoading]);

    return (
        <SummaryCard
            {...props}
            isLoading={isLoading}
            sx={{
                width: 'auto',
                minWidth: 250,
                ...props.sx
            }}
            actionSlot={(
                <Tooltip
                    arrow
                    placement='top'
                    title={null}
                    {...tooltipProps}
                >
                    <IconButton
                        size='small'
                        disabled={isCountLoaded}
                        sx={{
                            ml: 1
                        }}
                        onClick={onClick}
                    >
                        {lazyQuery?.isLoading
                            ? <CircularProgress size={24} />
                            : <CloudSyncIcon />}
                    </IconButton>
                </Tooltip>
            )}
            chipProps={{
                size: 'small',
                variant: 'soft',
                color: 'success',
                ...props.chipProps
            }}
            footerSlot={isCountLoaded && (
                <Box
                    sx={{
                        bgcolor: 'divider',
                        mx: '-16px',
                        mb: '-20px',
                        px: 2,
                        py: .5,
                        fontSize: 12,
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        opacity: 0,
                        animation: 'appear .3s ease-in-out forwards'
                    }}
                >
                    {footerSlot}: &nbsp;
                    <Box
                        sx={{
                            color: 'text.primary',
                            fontWeight: 600,
                            fontSize: 16
                        }}
                    >
                        {numeral(count).format(countFormat)}
                    </Box>
                </Box>
            )}
        />
    );
};

export default memo(Cassette);
