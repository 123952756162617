import { columnVisibilityQueryField, Paginate, sortModelQueryField, summsQueryField } from "consts/table";
import { RequestQueryMapper } from "util/request-query-mapper";

export type SortableFilterType = (typeof sortModelQueryField);
export type ColumnVisibilityFilterType = (typeof columnVisibilityQueryField);
export type PaginatableFilterType = (keyof typeof Paginate);
export type SummableFilterType = (typeof summsQueryField);

export enum RequestMappingStrategy {
    Equal,
    Exclusive,
    Sort,
    Paginate,
    ColumnVisibility
};

export type QueryMapperArgs = Readonly<{
    filterName: string,
    filterValue: string,
    queryMapper: RequestQueryMapper,
    index?: number
}>;

export interface QueryMapper {
    (args: QueryMapperArgs): void;
}
