import { ReactNode, memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch, { type SwitchProps } from "@mui/material/Switch";

type Props =
    & SwitchProps
    & {
        readonly label: ReactNode;
    };

const LedgerCurrency = ({ sx, label, checked, onChange }: Props) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...sx
        }}
    >
        <Typography
            variant='body1'
            sx={{
                fontWeight: 'bold'
            }}
        >
            {label}
        </Typography>
        <Switch
            checked={checked}
            onChange={onChange}
            value={label}
            inputProps={{ 'aria-label': 'ledger currency' }}
        />
    </Box>
);

export default memo(LedgerCurrency);
