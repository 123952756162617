import Chip from "@mui/material/Chip";
import withGridCellParams from "./withGridCellParams";
import type { ReactNode } from "react";
import { useCellContext } from "../contexts/CellProvider";
import { replaceUnderscore } from "../formatters";

const Label = withGridCellParams<ReactNode>(props => {
    const { value, field, ...restProps } = props;

    const cellProps = useCellContext();

    const { getCellProps } = cellProps[field];

    const formattedValue = replaceUnderscore({
        ...props,
        value
    });

    return (
        <Chip
            variant='outlined'
            label={formattedValue}
            color='success'
            {...(getCellProps?.({
                ...restProps,
                value: formattedValue,
                field
            }) ?? {})}
        />
    );
});

export default Label;
