import moment, { type Moment } from 'moment';
import type { DateRange } from '@mui/x-date-pickers-pro-v.6';
import { getEndOf } from "util/period-adapter";
export class SecondsInterval {
    public static daily() {
        return 1000 * 60 * 60 * 24;
    }

    public static weekly() {
        return this.daily() * 7;
    }

    public static monthly() {
        return this.daily() * 30;
    }
}

type PickersShortcutsItem = {
    label: string;
    getValue: () => DateRange<Moment>;
};

export class PredefinedCalendarInterval {
    public static defaultValue(label = 'Default Strategy'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(1, 'day'),
                moment()
            ]
        };
    }

    public static emptyValue(label = 'Empty Strategy'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                null,
                null
            ]
        };
    }

    public static selectedToValue(label = 'Selected to Strategy'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                null,
                moment()
            ]
        };
    }

    public static today(label = 'Today'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .startOf('day'),
                moment()
                    .endOf('day')
            ]
        };
    }

    public static yesterday(label = 'Yesterday'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(1, 'days')
                    .startOf('day'),
                moment()
                    .subtract(1, 'days')
                    .endOf('day')
            ]
        };
    }

    public static thisWeek(label = 'This week'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .startOf('week'),
                getEndOf('week')
            ]
        };
    }

    public static thisMonth(label = 'This month'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .startOf('month'),
                getEndOf('month')
            ]
        };
    }

    public static last7Days(label = 'Last 7 days'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(6, 'days'),
                moment()
            ]
        };
    }

    public static lastWeek(label = 'Last week'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(1, 'weeks')
                    .startOf('week'),
                moment()
                    .subtract(1, 'weeks')
                    .endOf('week')
            ]
        };
    }

    public static lastMonth(label = 'Last month'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(1, 'months')
                    .startOf('month'),
                moment()
                    .subtract(1, 'months')
                    .endOf('month')
            ]
        };
    }

    public static last30Days(label = 'Last 30 days'): PickersShortcutsItem {
        return {
            label,
            getValue: () => [
                moment()
                    .subtract(29, 'days'),
                moment()
            ]
        };
    }

    public static toArray() {
        return Array.of<PickersShortcutsItem>(
            this.today(),
            this.yesterday(),
            this.thisWeek(),
            this.thisMonth(),
            this.last7Days(),
            this.lastWeek(),
            this.lastMonth(),
            this.last30Days()
        );
    }
}
