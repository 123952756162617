import { memo } from "react";
import MerchantProvider from "../../MerchantProvider";
import LedgerResource from "./LedgerResource";

const Ledger = () => (
    <MerchantProvider>
        <LedgerResource />
    </MerchantProvider>
);

export default memo(Ledger);
