import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTypedSelector } from 'hooks';
import { MerchantTabs } from 'consts/merchants';
import { selectMerchantsActiveTab, selectIsMerchantsSucceeded } from 'features/merchants/selectors';
import type { CoreBankingEntity, ID } from 'types';

export const enum SearchQueryName {
    MerchantId = 'merchantId',
    MerchantActiveTab = 'merchantActiveTab'
};

export default function usePersistMerchant({
    onMerchantsLoaded = () => { }
}: {
    readonly onMerchantsLoaded?: (merchantId: ID | null) => void;
} = {}) {
    const eventHandlers = {
        onMerchantsLoaded
    };
    const eventHandlersRef = useRef(eventHandlers);
    eventHandlersRef.current = eventHandlers;

    const isMerchantsSucceeded = useTypedSelector(selectIsMerchantsSucceeded(MerchantTabs.Merchants));
    const activeTab = useTypedSelector(selectMerchantsActiveTab);

    const searchParams = useSearchParams();
    const searchParamsRef = useRef(searchParams);
    searchParamsRef.current = searchParams;
    const [searchQueryParams, setSearchParams] = searchParams;

    const withActiveTab = <T>(fn: (param: T, searchParams: URLSearchParams) => void, tab: MerchantTabs = MerchantTabs.Merchants) =>
        (param: T) => {
            if (!Object.is(activeTab, tab)) {
                return;
            }

            setSearchParams(searchParams => {
                fn(param, searchParams);

                return searchParams;
            });
        };

    const persistMerchant = withActiveTab(({ coreId }: CoreBankingEntity, searchParams) =>
        searchParams.set(SearchQueryName.MerchantId, String(coreId))
    );

    const persistMerchantActiveTab = withActiveTab((activeTab: string, searchParams) =>
        searchParams.set(SearchQueryName.MerchantActiveTab, activeTab)
    );

    const getMerchantActiveTab = () =>
        searchQueryParams.get(SearchQueryName.MerchantActiveTab) ?? undefined;

    const reset = () =>
        setSearchParams(searchParams => {
            [SearchQueryName.MerchantId, SearchQueryName.MerchantActiveTab]
                .forEach(arg => searchParams.delete(arg));

            return searchParams;
        });

    useEffect(() => {
        const [searchParams] = searchParamsRef.current;
        const { onMerchantsLoaded } = eventHandlersRef.current;

        if (isMerchantsSucceeded && Object.is(activeTab, MerchantTabs.Merchants)) {
            onMerchantsLoaded(searchParams.get(SearchQueryName.MerchantId));
        }
    }, [isMerchantsSucceeded, activeTab]);

    return {
        persistMerchant,
        persistMerchantActiveTab,
        getMerchantActiveTab,
        reset
    };
};