import { memo } from "react";
import { FormField, type MerchantProfileFinanceForm } from "./types";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import { getAccountType } from "features/merchants/helpers";
import { AccountBank, AccountCrypto } from './Account';
import { AccountType } from "consts/merchants";

const ProfileFinance = (merchantProfileFinanceForm: MerchantProfileFinanceForm) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isEditMode = true
    } = merchantProfileFinanceForm;

    const Account = {
        [AccountType.Bank]: AccountBank,
        [AccountType.Crypto]: AccountCrypto
    }[getAccountType(values)];

    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 2,
                maxWidth: 344
            }}
        >
            <TextField
                {...getFormInputProps(FormField.AccountHolderName, { errors, touched })}
                required
                fullWidth
                size='small'
                name={FormField.AccountHolderName}
                value={values[FormField.AccountHolderName] || ''}
                label='Account Holder Name'
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isEditMode}
            />
            <Account
                {...merchantProfileFinanceForm}
                isEditMode={isEditMode}
            />
        </Box>
    );
};
export default memo(ProfileFinance);
