import Box from "@mui/material/Box";
// import { selectTransactionSlice } from "features/transactions/selectors";
// import { useTypedSelector } from "hooks";
import { memo } from "react";
import type { FiltersWidgetComponentProps } from "ui/widgets/Filters";
// import useSummableWidget from "./useSummableWidget";

const TransactionsSummableWidget = ({ isFiltersOpen }: FiltersWidgetComponentProps) => {
    // const tableDataSlice = useTypedSelector(selectTransactionSlice);
    // const {
    //     getSummableAmountProps,
    //     getSummableAmountEurProps
    // } = useSummableWidget(tableDataSlice?.sums);

    const gap = '15px';

    // const rootProps = {
    //     sx: {
    //         ml: gap
    //     }
    // };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${Number(!isFiltersOpen) + 1}, auto)`,
                alignContent: 'flex-start',
                '& > div:nth-child(even)': {
                    mt: isFiltersOpen ? gap : 0
                }
            }}
        >
            {/* <SummableAmountCard
                {...getSummableAmountProps()}
                rootProps={rootProps}
            /> */}
            {/* <SummableAmountCard
                {...getSummableAmountEurProps()}
                rootProps={rootProps}
                amountSlotProps={{
                    sx: {
                        color: theme => theme.palette.primary.main
                    }
                }}
            /> */}
        </Box>
    );
};

export default memo(TransactionsSummableWidget);

// function SummableAmountCard({ amount, currency, ...restProps }: SummableCardProps) {
//     return (
//         <>
//             {Boolean(amount && currency) && (
//                 <SummableCard
//                     {...restProps}
//                     amount={amount}
//                     currency={currency}
//                 />
//             )}
//         </>
//     );
// }
