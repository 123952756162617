import Icon from "ui/atoms/Icon";
import type { SettlementStatus as SettlementStatusType } from "types";
import withStatus from "./withStatus";

const SettlementStatus = withStatus<SettlementStatusType>({
    SUCCEEDED: {
        icon: <Icon name="CheckCircleOutline" />,
        color: 'success'
    },
    FUNDSMISSING: {
        icon: <Icon name="SmsFailed" />,
        color: 'error'
    },
    FUNDSRECEIVED: {
        icon: <Icon name="ThumbUp" />,
        color: 'secondary'
    },
    FUNDSREFUNDED: {
        icon: <Icon name="LocalAtm" />,
        color: 'warning'
    },
    FUNDSSENT: {
        icon: <Icon name="Outbox" />,
        color: 'info'
    }
});

export default SettlementStatus;
