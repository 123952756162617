import { useState } from 'react';
import useMountedState from './useMountedState';

export default function useProcess<TResponse, THandler>(handler: (arg?: THandler) => Promise<TResponse>) {
    const [isLoading, setLoading] = useState(false);
    const setMountedLoadingState = useMountedState(setLoading);

    const handleProcess = async (arg?: THandler) => {
        setMountedLoadingState(true);

        let result;
        try {
            result = await handler(arg);
        }finally {
            setMountedLoadingState(false);
        }

        return result;
    };

    return {
        isLoading,
        handleProcess
    };
};
