import { Aggregates } from "consts/request-query";
import { Paginate } from "consts/table";
import { Filters } from "consts/transactions";
import { getConsumersBlacklist } from "features/consumers/api";
import type { BlacklistedEmailEntity, Consumer } from "features/consumers/types";
import { getTransactionOrigins, getTransactions } from "features/transactions/api";
import type { Transaction, TransactionOrigin } from "features/transactions/types";
import { useSimpleRequest } from "hooks/useRequest";
import { useEffect, useRef } from "react";
import type { ID, ResourceResponse, Paginator, Timestamps, Aggregatable } from "types";
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumersDialogs/MultiTabDialog/types";
import { fromPaginateResourceResponse } from "util/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { getPromiseSettledResourceResult } from "util/resource";
import { compareIds } from "util/support";

const prevConsumerCoreIdSymbol = Symbol('prevConsumerCoreId');

interface UseMoreDetails {
    (props: TabProps<TabIndex.MoreDetails>): void;
    [prevConsumerCoreIdSymbol]?: ID;
}

const useMoreDetails: UseMoreDetails = ({
    getTabPayload,
    dialogRequestProcessing: [
        isLoading,
        setLoading
    ],
    tabRegistry: [, updateDialogDataRegistry]
}) => {
    const request = useSimpleRequest();
    const {
        email,
        hashedEmail,
        coreId
    } = getTabPayload<Consumer>();

    const fetchLastActive = () => request(() => getTransactions(
        RequestQueryMapper.from()
            .containsIn(Filters.consumerId, `${coreId}`)
            .contains(Paginate.perPage, '1')
            .toString()
    ));

    const fetchBlacklisted = async () => {
        const [
            emailSettledResult,
            hashedEmailSettledResult
        ] = await Promise.allSettled([
            request(() => getConsumersBlacklist(
                RequestQueryMapper.from()
                    .containsIn(Filters.email, email)
                    .contains(Paginate.perPage, '1')
                    .toString()
            )),
            request(() => getConsumersBlacklist(
                RequestQueryMapper.from()
                    .containsIn(Filters.hashedEmail, hashedEmail)
                    .contains(Paginate.perPage, '1')
                    .toString()
            ))
        ]);

        return [
            getPromiseSettledResourceResult<
                Paginator<BlacklistedEmailEntity>
            >(emailSettledResult),
            getPromiseSettledResourceResult<
                Paginator<BlacklistedEmailEntity>
            >(hashedEmailSettledResult)
        ].find(response => Boolean(fromPaginateResourceResponse(response)[0]?.createdAt));
    };

    const fetchTransactionOrigins = () => request(() => getTransactionOrigins(
        RequestQueryMapper.from()
            .containsIn(Filters.consumerId, `${coreId}`)
            .containsNotIn(Filters.iban, '')
            .distincts(Filters.iban)
            .toString()
    ));

    const fetchTransactionStats = () => request(() => getTransactions(
        RequestQueryMapper.from()
            .containsIn(Filters.consumerId, `${coreId}`)
            .containsIn(Filters.parentId, '0')
            .aggregates(Aggregates.GroupBys, Filters.consumerId)
            .aggregates(Aggregates.GroupBys, Filters.status)
            .aggregates(Aggregates.Subtotals, Filters.amountEur)
            .sum(Filters.amountEur)
            .toString()
    ));

    const helpers = {
        setLoading,
        updateDialogDataRegistry,
        getTabPayload,
        fetchLastActive,
        fetchBlacklisted,
        fetchTransactionOrigins,
        fetchTransactionStats
    };

    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const {
            setLoading,
            getTabPayload,
            fetchLastActive,
            fetchBlacklisted,
            fetchTransactionOrigins,
            fetchTransactionStats,
            updateDialogDataRegistry
        } = helpersRef.current;

        if (!compareIds(coreId, useMoreDetails[prevConsumerCoreIdSymbol]!)) {
            setLoading(true);

            Promise.allSettled([
                fetchBlacklisted(),
                fetchLastActive(),
                fetchTransactionOrigins(),
                fetchTransactionStats()
            ])
            .then(([
                blacklistedSettledResult,
                lastActiveSettledResult,
                transactionOriginsSettledResult,
                transactionStatsSettledResult
            ]) => {
                const blacklistedAt = getCreationDate<BlacklistedEmailEntity>(blacklistedSettledResult);
                const lastActiveAt = getCreationDate<Transaction>(lastActiveSettledResult);
                const importedFrom = getblacklistingReason<BlacklistedEmailEntity>(blacklistedSettledResult)
                const {
                    createdAt,
                    whitelistedAt
                } = getTabPayload<Consumer>();

                const transactionOrigins = fromPaginateResourceResponse(
                    getPromiseSettledResourceResult<
                        Paginator<TransactionOrigin>
                    >(transactionOriginsSettledResult)
                );

                const transactionStats = getPromiseSettledResourceResult<
                    Paginator<Transaction & Aggregatable>
                >(transactionStatsSettledResult)?.data ?? null;


                updateDialogDataRegistry({
                    activityDetails: {
                        whitelistedAt,
                        createdAt,
                        blacklistedAt,
                        lastActiveAt,
                        importedFrom
                    },
                    transactionOrigins,
                    transactionStats
                })
            })
            .finally(() => setLoading(false));
        }

        useMoreDetails[prevConsumerCoreIdSymbol] = coreId;
    }, [coreId, isLoading]);
};

useMoreDetails[prevConsumerCoreIdSymbol] = '';

export default useMoreDetails;

function getCreationDate<TEntity extends Timestamps>(
    promiseSettledResult: PromiseSettledResult<
        ResourceResponse<Paginator<TEntity>> | null | undefined
    >
) {
    return fromPaginateResourceResponse(
        getPromiseSettledResourceResult<Paginator<TEntity>>(promiseSettledResult)
    )[0]?.createdAt || null;
}
function getblacklistingReason<TEntity extends BlacklistedEmailEntity>(
    promiseSettledResult: PromiseSettledResult<
        ResourceResponse<Paginator<TEntity>> | null | undefined
    >
) {
    return fromPaginateResourceResponse(
        getPromiseSettledResourceResult<Paginator<TEntity>>(promiseSettledResult)
    )[0]?.importedFrom || null;
}
