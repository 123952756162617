import { memo } from "react";
import Box from "@mui/material/Box";
import { UrlTextField } from "ui/atoms/TextField";
import Autocomplete from "ui/atoms/Autocomplete";
import type { BootstrapableForm, Option } from "types";
import { FormField, type EditMerchantDomainEntityFormProps } from "./types";
import type { MerchantDomain } from "features/merchants/types";
import { getFormInputProps } from "util/forms";
import { getOptionName, isOptionEqualToValue } from "util/option";
import { compareIds } from "util/support";
import { ClipboardTextField } from "ui/molecules/Input";

type Props = EditMerchantDomainEntityFormProps<
    Partial<MerchantDomain>,
    & Pick<
        BootstrapableForm<
            Partial<MerchantDomain>,
            {
                readonly [P in keyof Pick<
                    MerchantDomain,
                    | 'businessMerchantId'
                    | 'merchantBusinessId'
                >]: Option[];
            }
        >,
        | 'bootstrapData'
        | 'isBootstrapDataLoading'
    >
    & {
        readonly merrchantDomain: MerchantDomain;
    }
>;

const EditMerchantDomain = ({
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
    bootstrapData,
    isBootstrapDataLoading,
    merrchantDomain,
    ...merchantDomainForm
}: Props) => {
    const getValue = (
        options: Array<Option>,
        filedName: keyof MerchantDomain
    ) => options.find(option =>
        compareIds(option.id, Number(values[filedName]))
    ) ?? null;

    return (
        <Box
            component='form'
            autoComplete='off'
            onSubmit={handleSubmit}
            sx={{
                display: 'grid',
                gap: 1
            }}
        >
            <ClipboardTextField
                {...getFormInputProps(FormField.SourceUrl, merchantDomainForm)}
                required
                fullWidth
                size='small'
                variant='outlined'
                disabled={merrchantDomain.transactions_exist}
                name={FormField.SourceUrl}
                value={values[FormField.SourceUrl]}
                onChange={handleChange}
                onBlur={handleBlur}
                TextFieldComponent={UrlTextField}
            />
            <Autocomplete
                fullWidth
                disableClearable
                size='small'
                layout={null}
                loading={isBootstrapDataLoading}
                disabled={isBootstrapDataLoading}
                options={bootstrapData.businessMerchantId}
                getOptionLabel={getOptionName}
                isOptionEqualToValue={isOptionEqualToValue}
                value={getValue(
                    bootstrapData.businessMerchantId,
                    FormField.BusinessMerchantId
                )}
                onChange={(_, businessMerchantOption: Option) =>
                    setFieldValue(
                        FormField.BusinessMerchantId,
                        businessMerchantOption.id,
                        true
                    )}
                TextFieldProps={{
                    label: 'Merchant',
                    required: true
                }}
            />
            <Autocomplete
                fullWidth
                disableClearable
                size='small'
                layout={null}
                loading={isBootstrapDataLoading}
                disabled={isBootstrapDataLoading}
                options={bootstrapData.merchantBusinessId}
                getOptionLabel={getOptionName}
                isOptionEqualToValue={isOptionEqualToValue}
                value={getValue(
                    bootstrapData.merchantBusinessId,
                    FormField.MerchantBusinessId
                )}
                onChange={(_, merchantBusinessOption: Option) =>
                    setFieldValue(
                        FormField.MerchantBusinessId,
                        merchantBusinessOption.id,
                        true
                    )}
                TextFieldProps={{
                    label: 'Business',
                    required: true
                }}
            />
        </Box>
    );
};

export default memo(EditMerchantDomain);
