import BusinessLogicException from "exceptions/BusinessLogicException";
import type { ID } from "types";
import type { AbstractFileEntity } from "features/merchantPayouts/types";
import { generateFileName } from "util/support";
import type { ActionHookArg } from "../Props";
import useActionHandler from "./useActionHandler";

const defaultRequestHandler = async (coreId: ID, fileName: string) => new Response();

export default function useStillPendingSettlementCSV<T extends AbstractFileEntity>({
    row,
    handler = defaultRequestHandler
}: ActionHookArg<
    T,
    typeof defaultRequestHandler
>) {
    const actionHandler = useActionHandler(row);

    return actionHandler(async () => {
        if (!row.coreId) {
            throw new BusinessLogicException("Invalid ID!", {});
        }

        const response = await handler(
            row.coreId,
            generateFileName({
                prefix: 'Still Pending Settlement',
                fileKey: row.fileKey,
                extension: 'csv'
            })
        );

        if (!response.ok) {
            throw new BusinessLogicException("Something went wrong while generating .CSV file", { response });
        }
    });
};
