import type {
    PaginateResourceResponse,
    ResourceResponse
} from "types";
import type {
    MerchantsMethodsPivot,
    MerchantsMethodsPivotHistory
} from "features/pivots/types";
import { DomainRepository, type Dispatcher } from "hooks/useDomainState";
import {
    MerchantPaymentMethodsActionType,
    type MerchantPaymentMethodsRepositoryState,
    type MerchantPaymentMethodsRepository
} from "./types";
import { ApiState } from "infrastructure/api";
import { compareIds } from "util/support";

export class Repository extends DomainRepository<
    MerchantPaymentMethodsRepositoryState,
    MerchantPaymentMethodsActionType
> implements MerchantPaymentMethodsRepository {
    public constructor(
        public readonly state: MerchantPaymentMethodsRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantPaymentMethodsActionType>,
        private readonly getMerchantsMethodsPivotRequest: () => Promise<PaginateResourceResponse<MerchantsMethodsPivot>>,
        private readonly getMerchantsMethodsPivotHistoryRequest: ({ methodId }: Pick<MerchantsMethodsPivot, 'methodId'>) =>
            Promise<PaginateResourceResponse<MerchantsMethodsPivotHistory>>,
        private readonly updateMerchantsMethodsPivotRequest: (merchantsMethodsPivot: Pick<
            MerchantsMethodsPivot,
            | 'coreId'
        > & Partial<Omit<MerchantsMethodsPivot, 'coreId'>>) =>
            Promise<ResourceResponse<MerchantsMethodsPivot>>
    ) {
        super(state, dispatch);
    }

    public get isMerchantMethodsLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state.merchantsMethods.apiState);
    }

    public get isMerchantMethodsHistoryLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state.merchantsMethodsHistory.apiState);
    }

    public get isMerchantMethodsError() {
        return Object.is(
            this.state.merchantsMethods.apiState,
            ApiState.Failed
        );
    }

    public get isMerchantMethodsHistoryError() {
        return Object.is(
            this.state.merchantsMethodsHistory.apiState,
            ApiState.Failed
        );
    }

    public get isMerchantMethodsEmpty() {
        return Object.is(
            this.state.merchantsMethods.apiState,
            ApiState.Succeeded
        ) && !this.state.merchantsMethods.data?.total;
    }

    public get isMerchantMethodsHistoryEmpty() {
        return Object.is(
            this.state.merchantsMethodsHistory.apiState,
            ApiState.Succeeded
        ) && !this.state.merchantsMethodsHistory.data?.total;
    }

    public get merchantsMethods() {
        return this.state.merchantsMethods.data?.data ?? [];
    }

    public get merchantsMethodsHistory() {
        return this.state.merchantsMethodsHistory.data?.data ?? [];
    }

    public async fetchMerchantsMethods() {
        if (this.shouldSendRequest(this.state.merchantsMethods.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
            payload: {
                apiState: ApiState.Pending
            }
        });

        try {
            const response = await this.getMerchantsMethodsPivotRequest();

            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
                payload: {
                    apiState: ApiState.Succeeded,
                    data: this.throwOnFailedResponse(response).data
                }
            });
        } catch {
            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
                payload: {
                    apiState: ApiState.Failed
                }
            });
        }
    }

    public toggleEnableMerchantsMethodsPivot({
        coreId,
        enabled,
        MOR
    }: Pick<
        MerchantsMethodsPivot,
        | 'coreId'
        | 'enabled'
        | 'MOR'
    >): Promise<ResourceResponse<MerchantsMethodsPivot>> {
        return this.updateMerchantsMethodsPivot(
            enabled
                ? {
                    coreId,
                    enabled,
                    MOR: Object.is(MOR, String(null))
                        ? null
                        : MOR
                }
                : {
                    coreId,
                    enabled
                }
        );
    }

    public updateMethodLimits({
        coreId,
        minTicketSize,
        maxTicketSize,
        maxNumberOfTransaction24,
        maxNumberOfTransactionWeek,
        maxNumberOfTransactionMonth,
        maxTotalAmountWeek,
        maxNumberOfTransactionProfile24,
        adjustMaxNumberOfTransactionProfile24ByConsumerRisk,
        maxTotalAmountProfile24,
        adjustMaxTotalAmountProfile24ByConsumerRisk,
        maxTotalAmountMonth,
        adjustMaxTotalAmountMonthByConsumerRisk,
        velocity,
        adjustVelocityByConsumerRisk,
        maxRiskLevel,
        requireConsumerData,
        limitsEnabled
    }: Pick<
        MerchantsMethodsPivot,
        | 'coreId'
        | 'minTicketSize'
        | 'maxTicketSize'
        | 'maxNumberOfTransaction24'
        | 'maxNumberOfTransactionWeek'
        | 'maxNumberOfTransactionMonth'
        | 'maxTotalAmountWeek'
        | 'maxNumberOfTransactionProfile24'
        | 'adjustMaxNumberOfTransactionProfile24ByConsumerRisk'
        | 'maxTotalAmountProfile24'
        | 'adjustMaxTotalAmountProfile24ByConsumerRisk'
        | 'maxTotalAmountMonth'
        | 'adjustMaxTotalAmountMonthByConsumerRisk'
        | 'velocity'
        | 'adjustVelocityByConsumerRisk'
        | 'maxRiskLevel'
        | 'requireConsumerData'
        | 'limitsEnabled'
    >): Promise<ResourceResponse<MerchantsMethodsPivot>> {
        return this.updateMerchantsMethodsPivot({
            coreId,
            minTicketSize,
            maxTicketSize,
            maxNumberOfTransaction24,
            maxNumberOfTransactionWeek,
            maxNumberOfTransactionMonth,
            maxTotalAmountWeek,
            maxNumberOfTransactionProfile24,
            adjustMaxNumberOfTransactionProfile24ByConsumerRisk,
            maxTotalAmountProfile24,
            adjustMaxTotalAmountProfile24ByConsumerRisk,
            maxTotalAmountMonth,
            adjustMaxTotalAmountMonthByConsumerRisk,
            velocity,
            adjustVelocityByConsumerRisk,
            maxRiskLevel,
            requireConsumerData,
            limitsEnabled
        });
    }

    public async fetchMerchantsMethodsHistory({ methodId }: Pick<MerchantsMethodsPivot, 'methodId'>) {
        this.dispatch({
            type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
            payload: {
                apiState: ApiState.Pending
            }
        });

        try {
            const response = await this.getMerchantsMethodsPivotHistoryRequest({ methodId });

            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
                payload: {
                    apiState: ApiState.Succeeded,
                    data: this.throwOnFailedResponse(response).data
                }
            });
        } catch {
            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
                payload: {
                    apiState: ApiState.Failed
                }
            });
        }
    }

    public reset(): void {
        this.dispatch({
            type: MerchantPaymentMethodsActionType.Reset,
            payload: undefined
        });
    }

    private async updateMerchantsMethodsPivot(merchantMethod: Pick<
        MerchantsMethodsPivot,
        | 'coreId'
    > & Partial<Omit<MerchantsMethodsPivot, 'coreId'>>) {
        const response = await this.updateMerchantsMethodsPivotRequest(merchantMethod);

        const { data } = response;

        this.dispatch({
            type: MerchantPaymentMethodsActionType.UpdateMerchantsMethod,
            payload: {
                ...this.state.merchantsMethods,
                data: {
                    ...this.state.merchantsMethods.data,
                    data: this.state.merchantsMethods.data!.data.map(merchantsMethod => {
                        if (compareIds(merchantsMethod.coreId, data.coreId)) {
                            return data;
                        }

                        return merchantsMethod;
                    })
                }
            }
        });

        return response;
    }
}
