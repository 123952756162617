import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { MerchantBusiness } from "features/merchants/types";
import useMerchantDomains from "../../useMerchantDomains";
import MerchantDomain from "./MerchantDomain";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import MerchantDomainToolbar from "./MerchantDomainToolbar";
import { Collection } from "ui/molecules/Resource";

const MerchantBusinessDetail = ({ coreId }: MerchantBusiness) => {
    const { repository, getMerchantDomainsByBusinessId } = useMerchantDomains();

    return (
        <Collection
            sx={{
                display: 'grid',
                gap: 2
            }}
            isEmpty={repository.getIsMerchantDomainsByBusinessIdEmpty(coreId)}
            emptySlot={<NoDataOverlay />}
            collection={getMerchantDomainsByBusinessId(coreId)}
            renderResource={(merchantDomain, index) => (
                <Box
                    key={merchantDomain.coreId}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        gap: 3,
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 600
                        }}
                    >
                        {index! + 1}.
                    </Typography>
                    <MerchantDomain
                        {...merchantDomain}
                    />
                    <MerchantDomainToolbar
                        {...merchantDomain}
                    />
                </Box>
            )}
        />
    );
};

export default memo(MerchantBusinessDetail);
