import { memo } from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { PopoverableAction } from 'ui/molecules/Action';
import type { PopoverTextFieldFilterProps } from './types';
import TextFieldFilter from './TextFieldFilter';

const PopoverTextFieldFilter = ({
    id,
    label,
    PopoverTriggerProps,
    ...restTextFieldProps
}: PopoverTextFieldFilterProps) => (
    <Badge
        color='secondary'
        variant='dot'
        invisible={!String(restTextFieldProps.value)
            .trim()
            .length}
    >
        <PopoverableAction
            {...PopoverTriggerProps}
            id={id}
            label={label}
        >
            <Box
                sx={{
                    p: 2
                }}
            >
                <TextFieldFilter
                    id={id}
                    {...restTextFieldProps}
                />
            </Box>
        </PopoverableAction>
    </Badge>
);

export default memo(PopoverTextFieldFilter);
