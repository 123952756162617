import { memo } from "react";
import Screen from "ui/layouts/Screen";
import Transactions from "ui/organizms/Transactions";

const TransactionsScreen = () => (
    <Screen
        title='Transactions'
    >
        <Transactions />
    </Screen>
);

export default memo(TransactionsScreen);
