import { memo } from 'react';
import Box from '@mui/material/Box';
import OptionAction from './OptionAction';
import type { OptionActionProps } from './types';

type Props = OptionActionProps<'toggleBlacklist'>;

const ToggleBlacklistAction = ({
    duplicatedEmailRegistryEntry,
    toggleBlacklist,
    ...restButtonProps
}: Props) => (
    <OptionAction
        {...restButtonProps}
        dialogContent={[
            <Box>
                Are you sure you want to {
                    duplicatedEmailRegistryEntry.isBlacklisted
                        ? <strong>Whitelist</strong>
                        : <strong>Blacklist</strong>}
                &nbsp;
                <Box
                    component='span'
                    sx={{
                        fontWeight: 'bold',
                        color: 'primary.main'
                    }}
                >
                    {duplicatedEmailRegistryEntry.email}
                </Box>?
            </Box>
        ]}
        onConfirm={toggleBlacklist}
    >
        {duplicatedEmailRegistryEntry.isBlacklisted
            ? 'Whitelist'
            : 'Blacklist'}
    </OptionAction>
);

export default memo(ToggleBlacklistAction);
