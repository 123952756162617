import { memo } from "react";
import { ConfirmatableDialog } from "ui/molecules/Dialog";
import useIncludeMethodsContext from "./useIncludeMethodsContext";

const IncludeMethodsConfirmationDialog = () => {
    const {
        processingMethodRef,
        open,
        isProcessing,
        onConfirm,
        onCancel
    } = useIncludeMethodsContext();

    return (
        <ConfirmatableDialog
            isProcessing={isProcessing}
            open={open}
            content={[
                {
                    dialogContent: [
                        'Are you sure you want to include this method in the KYC process checks?'
                    ],
                    actionContent: 'Include'
                },
                {
                    dialogContent: [
                        'Are you sure you want to exclude this method from the KYC process checks?'
                    ],
                    actionContent: 'Exclude'
                }
            ]}
            onConfirm={onConfirm}
            onCancel={onCancel}
            checkPredicate={() => Boolean(processingMethodRef.current?.shouldKyc)}
        />
    );
};

export default memo(IncludeMethodsConfirmationDialog);
