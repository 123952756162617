import { ApiState } from "infrastructure/api";
import { servicesStateAdapter } from "./adapters";
import type { TicketingSystemState } from "./types";
import { getInitActionPayload } from "./getters/payload";

export default function stateFactory(): TicketingSystemState {
    const ticketingSystemSettings = servicesStateAdapter(
        getInitActionPayload()
    );

    return {
        ticketingSystemSettingsApiState: ApiState.Idle,
        isTicketingSystemSettingsChanged: false,
        ticketingSystemSettings: ticketingSystemSettings,
        initialTicketingSystemSettingsSnapshot: ticketingSystemSettings
    };
};
