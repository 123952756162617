import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { Merchants } from "ui/organizms/Merchants";

const MerchantsScreen = () => (
    <Screen
        title='Merchants | General'
    >
        <Merchants />
    </Screen>
);

export default memo(MerchantsScreen);
