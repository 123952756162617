import type { Transaction } from "features/transactions/types";


// import { isBefore } from "date-fns";

export default function usePingOrderActions({ pingTransactionCoreId }: Pick<Transaction, "pingTransactionCoreId" >) {
  const checkIfSettlePingOrderAvailable = () => {
    return pingTransactionCoreId !== null
  };
  const checkIfClosePingOrderAvailable = () => {
    return pingTransactionCoreId !== null
  };

  return {
    checkIfSettlePingOrderAvailable,
    checkIfClosePingOrderAvailable
  };
}
