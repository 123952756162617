import { ElementType, FC, memo, PropsWithChildren } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

type Props = BoxProps & {
    readonly isLoading: boolean;
    readonly component?: ElementType;
};

const Loader: FC<PropsWithChildren<Props>> = ({
    isLoading,
    children,
    sx,
    component: LoadingComponent = CircularProgress
}) => (
    <>
        {isLoading
            ? (
                <Box
                    sx={sx}
                >
                    <LoadingComponent />
                </Box>
            )
            : children}
    </>
);

Loader.defaultProps = {
    sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    component: CircularProgress
};

export default memo(Loader);
