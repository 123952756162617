import { ReactNode, memo } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';
import { ProcessingAlert } from 'ui/molecules/Dialog';

export type ActionPanelProps = BoxProps & {
    readonly onSave: () => Promise<void>;
    readonly onCancel?: () => Promise<void> | void;
    readonly isLoading?: boolean;
    readonly withProcessingAlert?: boolean;
    readonly CancelActionProps?: LoadingButtonProps;
    readonly SaveActionProps?: LoadingButtonProps;
    readonly cancelActionSlot?: ReactNode;
    readonly saveActionSlot?: ReactNode;
};

const ActionPanel = ({
    withProcessingAlert,
    isLoading,
    onSave,
    onCancel,
    CancelActionProps = {},
    cancelActionSlot = null,
    SaveActionProps = {},
    saveActionSlot = null,
    sx,
    ...restProps
}: ActionPanelProps) => (
    <Box
        {...restProps}
        sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid',
            borderColor: 'divider',
            mt: '20px',
            pt: '20px',
            gap: 2,
            ...sx
        }}
    >
        {cancelActionSlot ?? (
            <LoadingButton
                variant='outlined'
                {...CancelActionProps}
                onClick={onCancel}
            >
                {CancelActionProps.children ?? 'Cancel'}
            </LoadingButton>
        )}
        {saveActionSlot ?? (
            <LoadingButton
                variant='contained'
                loading={isLoading}
                {...SaveActionProps}
                onClick={onSave}
            >
                {SaveActionProps.children ?? 'Save'}
            </LoadingButton>
        )}
        {Boolean(withProcessingAlert) && (
            <ProcessingAlert
                isOpen={isLoading}
            />
        )}
    </Box>
);

export default memo(ActionPanel);
