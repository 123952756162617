import { memo, ChangeEvent } from 'react';
import Slider, { type SliderProps } from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from 'ui/atoms/InputLabel';
import TextFieldInput from './TextFieldInput';
import type { NumericRangeValue } from './types';
import useValidator, { ValidationStrategy } from './useValidator';

type ChangeArgs = {
    readonly event: Event;
    readonly value: NumericRangeValue;
    readonly activeThumb: number;
};

export type NumericRangeProps = Omit<SliderProps, 'onChange' | 'value'> & {
    readonly label?: string;
    readonly value: NumericRangeValue;
    readonly onChange: (arg: ChangeArgs) => void;
};

const NumericRange = ({ label, value, onChange, ...props }: NumericRangeProps) => {
    const validator = useValidator(value);

    const getAriaLabel = () => 'Numeric Range';
    const getAriaValueText = (value: number) => `${value}`;

    const handleTextValueChange = (activeThumb: number) => (
        textFieldValue: number | string,
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const sliderRangeValues: Array<number | string> = [...value];
        sliderRangeValues[activeThumb] = textFieldValue;

        const [minValue = 0, maxValue] = sliderRangeValues;

        onChange({
            event: event.nativeEvent,
            value: [minValue, maxValue] as NumericRangeValue,
            activeThumb
        });
    };

    const handleChange: SliderProps['onChange'] = (event, value, activeThumb) =>
        onChange({
            event,
            value: [value].flat() as NumericRangeValue,
            activeThumb
        });

    return (
        <FormControl variant='standard'>
            <InputLabel shrink htmlFor={props.id}>
                {label}
            </InputLabel>
            <Stack direction="row" spacing={2}>
                <TextFieldInput
                    value={value[0]}
                    onChange={handleTextValueChange(0)}
                    placeholder='Min'
                    validator={validator(ValidationStrategy.Min)}
                />
                <Slider
                    disableSwap
                    valueLabelDisplay="auto"
                    getAriaLabel={getAriaLabel}
                    getAriaValueText={getAriaValueText}
                    value={value}
                    onChange={handleChange}
                    {...props}
                />
                 <TextFieldInput
                    value={value[1]}
                    onChange={handleTextValueChange(1)}
                    placeholder='Max'
                    validator={validator(ValidationStrategy.Max)}
                />
            </Stack>
        </FormControl>
    );
};

export default memo(NumericRange);
