import type { LinearChartDatum } from "../types";

export class Datum implements Omit<LinearChartDatum, 'pivot'> {
    public constructor(
        public readonly x: string,
        public readonly y: number | string
    ) { }
}

export class ChartRepository {
    public static make() {
        return new this();
    }

    private constructor(
        private readonly chart = new Map<string, Map<string, number | string>>()
    ) { }

    public get ticks() {
        return Array.from(
            new Set(
                Array.from(this.chart.values())
                    .map(datum => Array.from(datum.keys()))
                    .flat()
            )
        );
    }

    public get entries() {
        return Array.from(this.chart.entries());
    }

    public getSerieTicks(pivot: string) {
        return Array.from(this.chart.get(pivot)?.keys() ?? []);
    }

    public has({ pivot, x }: Omit<LinearChartDatum, 'y'>) {
        return this.chart.get(pivot)?.has(`${x}`) ?? false;
    }

    public update({ pivot, x, y = 0 }: LinearChartDatum) {
        const datum = this.getDatum(pivot);

        datum.set(String(x), Number(y));
        this.chart.set(pivot, datum);

        return this;
    }

    public getSerie(sortFn?: (a: string, b: string) => number) {
        return this.entries
            .map(([id, datum]) => {
                const data = Array.from(datum.entries(), ([x, y]) => new Datum(x, y));

                if (sortFn) {
                    data.sort((a, b) => sortFn(a.x, b.x));
                }

                return {
                    id,
                    data
                };
            });
    }

    private getDatum(pivot: string) {
        return this.chart.get(pivot) || new Map<string, string>();
    }
}
