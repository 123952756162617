import { useMemo } from "react";
import { array, object, ref, string } from "yup";
import { FormField, type UserFormProps } from "./types";
import { isDomainRequired } from "./helpers";


type UseValidationSchemaArg = Pick<UserFormProps, 'bootstrapData'> & {
    readonly isNew: boolean;
};

const useValidationSchema = ({
    isNew,
    bootstrapData: {
        roles
    }
}: UseValidationSchemaArg) =>
    useMemo(() =>
        object()
            .shape({
                [FormField.FirstName]: string()
                    .min(3)
                    .max(50)
                    .required()
                    .label('First Name'),
                [FormField.LastName]: string()
                    .min(3)
                    .max(50)
                    .required()
                    .label('Last Name'),
                [FormField.Email]: string()
                    .email("Email must be a valid email")
                    .required("Email is a required field"),
                [FormField.RoleId]: string()
                    .required(),
                // [formFields.merchants]: array()
                //     .of(object().shape({
                //         id: string().required(),
                //         name: string().required()
                //     }))
                //     .when(formFields.roleId, {
                //         is: isMerchantRequired(roles),
                //         then: shema => shema
                //             .min(1, "Merchants required")
                //     }),
                [FormField.Domains]: array()
                    .of(object().shape({
                        id: string().required(),
                        name: string().required()
                    }))
                    .when(FormField.RoleId, {
                        is: isDomainRequired(roles),
                        then: shema => shema
                            .min(1, "This field required")
                    }),
                ...(
                    isNew
                        ? ({
                            [FormField.Password]: string()
                                .min(8, "Password length not enough")
                                .max(50, "You are crazy?")
                                .required("Password required"),
                            [FormField.PasswordConfirmation]: string()
                                .required("Confirmation password is a required field")
                                .oneOf([ref(FormField.Password), null], 'Passwords must match'),
                        })
                        : ({
                            [FormField.Password]: string()
                                .min(8, "Password length not enough")
                                .max(50, "You are crazy?")
                                .nullable()
                                .notRequired(),
                            [FormField.PasswordConfirmation]: string()
                                .test('passwords-match', 'Passwords must match', function (value = '') {
                                    return (this.parent.passwd || '') === value;
                                })
                        })
                )
            }), [isNew, roles]);

export default useValidationSchema;
