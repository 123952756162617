import type { PropsWithChildren } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Card, { type CardProps } from "@mui/material/Card";
import { ThemePaletteMode } from "consts/ui";

export type LayoutProps = BoxProps & {
    readonly isCellEditable?: boolean;
    readonly CardProps?: CardProps;
};

const Layout = ({ CardProps, isCellEditable, children, ...props }: PropsWithChildren<LayoutProps>) => (
    <Box
        m={3}
        pb={3}
        {...props}
        sx={{
            ...(isCellEditable ? {
                '& .MuiDataGrid-cell--editable': {
                    bgcolor: theme =>
                        Object.is(theme.palette.mode, ThemePaletteMode.Dark)
                            ? '#376331'
                            : 'rgb(217 243 190)'
                }
            } : {}),
            ...props.sx
        }}
    >
        <Card
            sx={{
                height: `calc(100vh - 210px)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
                ...CardProps?.sx
            }}
        >
            {children}
        </Card>
    </Box>
);

export default Layout;
