import { memo } from 'react';
import { TableGridMode } from 'consts/table';
import StatefulTabs from 'ui/widgets/StatefulTabs';
import IncrementExchangeRates from './IncrementExchangeRates';
import Files from './Files';
import Increments from './Increments';
import IncrementTax from './IncrementTax';
import SummaryFiles from './SummaryFiles';
import FileTaxCalculations from './FileTaxCalculations';
import useMerchantPayouts from './useMerchantPayouts';
import MerchantPayoutsTable from './MerchantPayoutsTable';

const MerchantPayouts = () => {
    const {
        isMerchantPayoutsLoading,
        filterTypes,
        onChangeTab,
        getMerchantPayoutSummaryFiles,
        getMerchantPayoutFiles,
        getMerchantPayoutCalculations,
        getMerchantPayoutIncrements,
        getTransactionsMerchantPayoutIncrementsPivot,
        getMerchantPayoutFileTaxCalculations,
        getMerchantPayoutIncrementTaxes,
        getMerchantPayoutIncrementExchangeRates,
        getTabValue,
        getFiltersModelOverrides
    } = useMerchantPayouts();

    return (
        <StatefulTabs
            TabProps={{
                disabled: isMerchantPayoutsLoading
            }}
            model={[
                {
                    label: 'Summary Files',
                    component: (
                        <SummaryFiles
                            mode={TableGridMode.MerchantPayoutsSummaryFiles}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutSummaryFiles}
                            FiltersProps={{
                                filtersModelOverrides: getFiltersModelOverrides()
                            }}
                        />
                    )
                },
                {
                    label: 'Files',
                    component: (
                        <Files
                            mode={TableGridMode.MerchantPayoutsFiles}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutFiles}
                            FiltersProps={{
                                filtersModelOverrides: getFiltersModelOverrides()
                            }}
                        />
                    )
                },
                {
                    label: 'Calculations',
                    component: (
                        <MerchantPayoutsTable
                            mode={TableGridMode.MerchantPayoutCalculations}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutCalculations}
                            FiltersProps={{
                                filtersModelOverrides: getFiltersModelOverrides()
                            }}
                        />
                    )
                },
                {
                    label: 'Increments',
                    component: (
                        <Increments
                            mode={TableGridMode.MerchantPayoutIncrements}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutIncrements}
                            FiltersProps={{
                                filtersModelOverrides: getFiltersModelOverrides()
                            }}
                        />
                    )
                },
                {
                    label: 'Increments Pivot',
                    component: (
                        <MerchantPayoutsTable
                            mode={TableGridMode.TransactionsMerchantPayoutIncrementsPivot}
                            filterTypes={filterTypes}
                            fetchTableData={getTransactionsMerchantPayoutIncrementsPivot}
                        />
                    )
                },
                {
                    label: 'Files Tax Calc.',
                    component: (
                        <FileTaxCalculations
                            mode={TableGridMode.MerchantPayoutFileTaxCalculations}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutFileTaxCalculations}
                        />
                    )
                },
                {
                    label: 'Increments Taxes',
                    component: (
                        <IncrementTax
                            mode={TableGridMode.MerchantPayoutIncrementTaxes}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutIncrementTaxes}
                        />
                    )
                },
                {
                    label: 'Increment Exchange Rates',
                    component: (
                        <IncrementExchangeRates
                            mode={TableGridMode.MerchantPayoutIncrementExchangeRates}
                            filterTypes={filterTypes}
                            fetchTableData={getMerchantPayoutIncrementExchangeRates}
                        />
                    )
                }
            ]}
            value={getTabValue()}
            onChange={onChangeTab}
            TabListContainerProps={{
                borderBottom: 1,
                borderColor: 'divider'
            }}
            TabListProps={{
                'aria-label': "merchant payouts",
                variant: "scrollable",
                scrollButtons: "auto"
            }}
            TabPanelProps={{
                sx: {
                    padding: 1
                }
            }}
            ContainerProps={{
                pl: 1,
                sx: {
                    width: '100%'
                }
            }}
        />
    );
};

export default memo(MerchantPayouts);
