import { useRef } from "react";
import type { FiltersPublicApi } from "ui/widgets/Table";
import type { ToolbarFiltersProviderProps } from "./types";

export default function useToolbarFilters({ toolbarFiltersPropsSelectorRegistry }: ToolbarFiltersProviderProps) {
    const filtersRef = useRef<FiltersPublicApi<string> | null>(null);
    
    const getSelectorByComponentId = (componentId: string) =>
        toolbarFiltersPropsSelectorRegistry.get(componentId);
    
    return {
        filtersRef,
        getSelectorByComponentId
    };
};
