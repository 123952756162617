import Box, { type BoxProps } from "@mui/material/Box";
import { memo, type PropsWithChildren } from "react";

const Cassette = ({ children, ...props }: PropsWithChildren<BoxProps>) => (
    <Box
        {...props}
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: 2,
            mt: 5.5,
            ...props.sx
        }}
    >
        {children}
    </Box>
);

export default memo(Cassette);
