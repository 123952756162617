import { memo } from "react";
import Box from '@mui/material/Box';
import type { ChangePayload, FilterChangePayload, FiltersChangeHandler } from "ui/widgets/Filters";
import { Autocomplete, DateRange, TextField } from "ui/widgets/Filters";
import { Filters } from "consts/merchantPayouts";
import { getFormInputProps } from "util/forms";
import { multiColumnFilterFactory } from "util/filters";
import type { IncrementExchangeRateProps } from "./types";
import MerchantSelector from "ui/widgets/MerchantSelector";
import { MerchantOption } from "features/merchants/helpers";

const IncrementExchangeRate = ({
    id,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    bootstrapData,
    isBootstrapDataLoading,
    ...restFormProps
}: IncrementExchangeRateProps) => {
    const onChange: FiltersChangeHandler = ({ type, payload }: ChangePayload<string, FilterChangePayload>) => {
        setFieldValue(type, payload);
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={id}
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                flexWrap: 'wrap'
            }}
        >
            <DateRange
                id={multiColumnFilterFactory([
                    Filters.dateFrom,
                    Filters.dateTo
                ])}
                name={multiColumnFilterFactory([
                    Filters.dateFrom,
                    Filters.dateTo
                ])}
                onChange={onChange}
                value={values["dateFrom|dateTo"]}
                startProps={{
                    label: 'Date From'
                }}
                endProps={{
                    label: 'Date To'
                }}
            />
            <Autocomplete
                {...getFormInputProps(Filters.MOR, restFormProps)}
                options={bootstrapData.MOR}
                loading={isBootstrapDataLoading}
                id={Filters.MOR}
                name={Filters.MOR}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.MOR}
                label={Filters.MOR}
                placeholder={Filters.MOR}
            />
            <Autocomplete
                {...getFormInputProps(Filters.PSP, restFormProps)}
                options={bootstrapData.PSP}
                loading={isBootstrapDataLoading}
                id={Filters.PSP}
                name={Filters.PSP}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.PSP}
                label={Filters.PSP}
                placeholder={Filters.PSP}
            />
            <Autocomplete
                {...getFormInputProps(Filters.transactionCurrency, restFormProps)}
                options={bootstrapData.transactionCurrency}
                loading={isBootstrapDataLoading}
                id={Filters.transactionCurrency}
                name={Filters.transactionCurrency}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.transactionCurrency}
                label='Transaction Currency'
                placeholder='Transaction Currency'
            />
            <Autocomplete
                {...getFormInputProps(Filters.payoutCurrency, restFormProps)}
                options={bootstrapData.payoutCurrency}
                loading={isBootstrapDataLoading}
                id={Filters.payoutCurrency}
                name={Filters.payoutCurrency}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.payoutCurrency}
                label='Payout Currency'
                placeholder='Payout Currency'
            />
            <TextField
                {...getFormInputProps(Filters.currencyExchangeRate, restFormProps)}
                id={Filters.currencyExchangeRate}
                name={Filters.currencyExchangeRate}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.currencyExchangeRate}
                inputProps={{
                    maxLength: 12
                }}
                label='Exchange Rate'
                placeholder='Exchange Rate'
                type='number'
            />
            <MerchantSelector
                FormControlProps={{
                    sx: {
                        maxWidth: 211
                    }
                }}
                tooltipProps={{
                    title: null
                }}
                loading={isBootstrapDataLoading}
                label='Merchant'
                multiple={false}
                value={values.merchantId}
                getOption={MerchantOption.fromMerchant}
                onChange={(_, value) => {
                    setFieldTouched(Filters.merchantId, true);
                    setFieldValue(Filters.merchantId, value, true);
                }}
                TextFieldProps={{
                    ...getFormInputProps(Filters.merchantId, restFormProps),
                    name: Filters.merchantId,
                    required: true,
                    placeholder: 'Select Merchant'
                }}
            />
            <Autocomplete
                {...getFormInputProps(Filters.tag, restFormProps)}
                options={bootstrapData.tag}
                loading={isBootstrapDataLoading}
                id={Filters.tag}
                name={Filters.tag}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.tag}
                label='Tag'
                placeholder='Select Tag'
            />
        </Box>
    );
};

export default memo(IncrementExchangeRate);
