import { memo, type FC } from "react";
import Box from "@mui/material/Box";
import {
    useStatisticDashboardContext,
    esTransactionsGroupByCurrencyQueryToken,
    esTransactionsGroupByStatusQueryToken
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import { CurrencySymbol } from "consts/general";
import type { LinearChartProps, LinearDiagramProps } from "../../types";
import type { ElasticSearchResourceResponse } from "types";
import TitleNumber from "../shared/TitleNumber";
import LinearDiagram from "../shared/LinearDiagram";
import SliceTooltip from "../shared/SliceTooltip";
import AxisBottomTick from "../shared/AxisBottomTick";
import { diagramPropsPredicate, getAverage, getChartData, applyTotals, formatXDateAxis } from "../../support/helpers";
import useLinearDiagram from "../../hooks/useLinearDiagram";

type ProcessedAmountByCurrencyDiagramProps =
    & Pick<LinearDiagramProps, 'className' | 'isLoading'>
    & {
        readonly esTransactionsGroupByCurrencyPayload: ElasticSearchResourceResponse<string>['data'];
        readonly esTransactionsGroupByStatusPayload: ElasticSearchResourceResponse<string>['data'];
    };

const ProcessedAmountByCurrencyDiagram = memo(({
    className,
    isLoading,
    esTransactionsGroupByCurrencyPayload,
    esTransactionsGroupByStatusPayload
}: ProcessedAmountByCurrencyDiagramProps) => {
    const {
        total: totalAmountEur,
        data,
        columns
    } = useLinearDiagram({
        isLoading,
        onLoad: () => {
            let totalAmountEur = 0;

            getChartData(esTransactionsGroupByStatusPayload, {
                getDatum: row => {
                    const amountEur = Number(row.at(1));

                    if (Object.is(row.at(-1), 'SUCCEEDED')) {
                        totalAmountEur += amountEur;
                    }

                    return {
                        pivot: '',
                        x: 0,
                        y: 0
                    };
                }
            });

            const data = applyTotals(
                getChartData(esTransactionsGroupByCurrencyPayload, {
                    getDatum: row => ({
                        pivot: String(row.at(-1)),
                        x: row.at(-2),
                        y: Number(row.at(1))
                    })
                }));

            return {
                total: totalAmountEur,
                data,
                columns: esTransactionsGroupByCurrencyPayload.columns
            };
        }
    });

    return (
        <LinearDiagram
            className={className}
            sx={{
                zIndex: 1
            }}
            title='Processed Amounts'
            subtitle={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 27
                    }}
                >
                    {CurrencySymbol.EUR}
                    <TitleNumber
                        isLoading={isLoading}
                        format=",.2f"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {totalAmountEur}
                    </TitleNumber>
                </Box>
            )}
            subheader={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 14
                    }}
                >
                    Average per interval unit: {CurrencySymbol.EUR}
                    <TitleNumber
                        isLoading={isLoading}
                        format=",.2f"
                    >
                        {getAverage(
                            totalAmountEur,
                            columns
                        )}
                    </TitleNumber>
                </Box>
            )}
            axisLeft={{
                legend: `Amount (${CurrencySymbol.EUR})`
            }}
            yFormat=' >-,.2f'
            data={data}
            columns={columns}
            sliceTooltip={props => (
                <SliceTooltip
                    {...props}
                    columns={columns}
                    postfix={CurrencySymbol.EUR}
                />
            )}
            renderAxisBottomTick={props => (
                <AxisBottomTick
                    {...props}
                    isLoading={isLoading}
                    data={data}
                    formatValue={formatXDateAxis(columns)}
                />
            )}
            isLoading={isLoading}
            chartMargin={{
                right: 75
            }}
        />
    );
}, diagramPropsPredicate);

const ProcessedAmountByCurrency: FC<LinearChartProps> = ({ className }) => {
    const statisticDashboard = useStatisticDashboardContext();
    const {
        payload: esTransactionsGroupByCurrencyPayload,
        isLoading: isEsTransactionsGroupByCurrencyLoading
    } = statisticDashboard[esTransactionsGroupByCurrencyQueryToken];
    const {
        payload: esTransactionsGroupByStatusPayload,
        isLoading: isEsTransactionsGroupByStatusLoading
    } = statisticDashboard[esTransactionsGroupByStatusQueryToken];


    const isLoading = (
        isEsTransactionsGroupByCurrencyLoading ||
        isEsTransactionsGroupByStatusLoading
    );

    return (
        <ProcessedAmountByCurrencyDiagram
            className={className!}
            isLoading={isLoading}
            esTransactionsGroupByCurrencyPayload={esTransactionsGroupByCurrencyPayload!}
            esTransactionsGroupByStatusPayload={esTransactionsGroupByStatusPayload!}
        />
    );
};

ProcessedAmountByCurrency.defaultProps = {
    className: 'js-processed-amounts-by-currency'
};

export default memo(ProcessedAmountByCurrency);
