import { useCallback, Dispatch, SetStateAction } from 'react';
import useDetectComponentMounted from './useDetectComponentMounted';

export default function useMountedState<T>(setState: Dispatch<SetStateAction<T>>) {
    const isComponentMountedRef = useDetectComponentMounted();

    return useCallback((state: T | ((state: T) => T)) => {
        if (isComponentMountedRef.current) {
            setState(state);
        }
    }, [setState, isComponentMountedRef]);
};
