import { DateTimeFormatRepository } from "consts/general";
import { Aggregates } from "consts/request-query";
import { Filters } from "consts/users";
import { getMerchantUsers } from "features/merchants/api";
import { Users, UsersResponse } from "features/users/types";
import { useFetch, useQuery } from "hooks/useQuery";
import moment from "moment";

export const activeMerchantUsersAggregateQueryToken = Symbol('activeMerchantUsersAggregateQueryToken');
export const loggedTodayMerchantUsersAggregateQueryToken = Symbol('loggedTodayMerchantUsersAggregateQueryToken');

export default function useStatisticCassettes() {
    const activeMerchantUsersAggregateQuery = useQuery<UsersResponse>({
        getRequestQueryParams: requestQueryMapper => (
            requestQueryMapper
                .aggregates(Aggregates.GroupBys, Filters.status)
        ),
        fetchQuery: getMerchantUsers
    });

    const loggedTodayMerchantUsersAggregateQuery = useQuery<UsersResponse>({
        getRequestQueryParams: requestQueryMapper => (
            requestQueryMapper
                .inclusiveRange(
                    Filters.lastLogin,
                    moment()
                        .startOf('day')
                        .format(DateTimeFormatRepository.Read),
                    0
                )
        ),
        fetchQuery: getMerchantUsers
    });

    const getTotalMerchantUsersCount = (
        selector: (users: Users) => Users,
        users: Users = []
    ) => selector(users).at(0)?.group_count || 0;

    return {
        ...useFetch(
            activeMerchantUsersAggregateQuery,
            loggedTodayMerchantUsersAggregateQuery
        ),
        [activeMerchantUsersAggregateQueryToken]: activeMerchantUsersAggregateQuery,
        [loggedTodayMerchantUsersAggregateQueryToken]: loggedTodayMerchantUsersAggregateQuery,
        getTotalMerchantUsersCount
    };
};
