import { memo } from "react";
import AccountMerchantSetup from "ui/forms/Merchant/AccountMerchantSetup";
import { useMerchantAccountSettingsContext } from "../MerchantAccountSettingsProvider";
import MerchantAccountSettingsTab from "../MerchantAccountSettingsTab";
import type { MerchantDetailProps } from "../../types";

const MerchantSetup = (props: MerchantDetailProps) => {
    const { selectAccountMerchantSetup } = useMerchantAccountSettingsContext();

    const accountMerchantSetupForm = selectAccountMerchantSetup();

    return (
        <MerchantAccountSettingsTab
            title='Merchant Setup'
            {...props}
            {...accountMerchantSetupForm}
            sx={{
                gridTemplateColumns: '1fr'
            }}
        >
            <AccountMerchantSetup
                {...accountMerchantSetupForm}
            />
        </MerchantAccountSettingsTab>
    );
};

export default memo(MerchantSetup);
