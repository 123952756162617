import { useEffect, useRef } from 'react';
import useActions from "features/auth/useActions";
import useErrorHandler from "./useErrorHandler";

export default function useBootstrap() {
    const { getUser } = useActions();
    useErrorHandler();

    const apiHandlers = {
        getUser
    };

    const apiHandlersRef = useRef(apiHandlers);
    apiHandlersRef.current = apiHandlers;
    // useProfilerTool();

    useEffect(() => {
        const { getUser } = apiHandlersRef.current;

        getUser();
    }, []);
};
