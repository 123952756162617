import { PropsWithChildren, memo } from 'react';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, { type AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AccordionDetails, { type AccordionDetailsProps } from '@mui/material/AccordionDetails';
import AccordionActions, { type AccordionActionsProps } from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type Props = Omit<AccordionProps, 'children'> & {
    readonly AccordionSummaryProps?: AccordionSummaryProps;
    readonly AccordionDetailsProps?: AccordionDetailsProps;
    readonly AccordionActionsProps?: AccordionActionsProps;
};

const Accordion = ({
    AccordionSummaryProps,
    AccordionDetailsProps,
    AccordionActionsProps,
    children,
    ...props
}: PropsWithChildren<Props>) => (
    <MuiAccordion
        {...props}
    >
        <AccordionSummary
            expandIcon={(
                <ExpandMoreIcon />
            )}
            {...AccordionSummaryProps}
        >
            {AccordionSummaryProps?.children}
        </AccordionSummary>
        <AccordionDetails
            {...AccordionDetailsProps}
        >
            {children ?? AccordionDetailsProps?.children}
        </AccordionDetails>
        <AccordionActions
            {...AccordionActionsProps}
        >
            {AccordionActionsProps?.children}
        </AccordionActions>
    </MuiAccordion>
);

export default memo(Accordion);
