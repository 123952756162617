import { MouseEvent, memo, useState } from "react";
import Popover from '@mui/material/Popover';
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import type { MultipleFileProps } from "./types";
import Single from "./Single";

const Multiple = ({ value, title, downloadFile }: MultipleFileProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const onOpen = ({ currentTarget }: MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(currentTarget);

    const onClose = () => setAnchorEl(null);

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'multiple-file-download' : undefined;

    return value.length > 0
        ? (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <SaveAltIcon color='disabled' />
                    <Tooltip
                        title={title ?? 'Click to open'}
                        placement='top'
                        arrow
                    >
                        <IconButton
                            onClick={onOpen}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>

                </Box>
                <Popover
                    id={id}
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={onClose}
                >
                    <Box
                        sx={{
                            minWidth: 491,
                            minHeight: 30,
                            py: 1,
                        }}
                    >
                        {value.map((file, index) => (
                            <Single
                                key={index}
                                value={file}
                                title={null}
                                downloadFile={downloadFile}
                            />
                        ))}
                    </Box>
                </Popover>
            </>
        )
        : (
            <>
                -
            </>
        );
};

export default memo(Multiple);
