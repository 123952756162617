import { memo } from "react";
import Box from "@mui/material/Box";
import { Merchant } from "features/merchants/types";
import HoverableLegend from "./HoverableLegend";

type Props = Pick<
    Merchant,
    | 'showTicketingSystemOnInit'
    | 'showTicketingSystemOnRedirect'
    | 'mailNotificationChannelIsEnabled'
>;

const TicketingSystemLegend = ({
    showTicketingSystemOnInit,
    showTicketingSystemOnRedirect,
    mailNotificationChannelIsEnabled
}: Props) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)'
        }}
    >
        {[
            [showTicketingSystemOnInit, 'Support page on init'],
            [showTicketingSystemOnRedirect, 'Support page on redirect'],
            [mailNotificationChannelIsEnabled, 'User Email Confirmation']
        ].map(([checked, title]) => (
            <HoverableLegend
                key={String(title)}
                defaultChecked={Boolean(checked)}
                title={String(title)}
            />
        ))}
    </Box>
);

export default memo(TicketingSystemLegend);
