import { useRef, useEffect } from 'react';
import type { StatisticDashboardProps } from './types';
import { useFilterRequestMapper } from 'ui/widgets/Filters';
import { useSearchParams } from 'react-router-dom';
import useQuery from './useStatisticDashboardQuery';
import useStatisticDashboardFilters from './useStatisticDashboardFilters';

type UseStatisticDashboardArg = StatisticDashboardProps;

export default function useStatisticDashboard({ filterTypes }: UseStatisticDashboardArg) {
    const requestSearchParamsRef = useRef<string>('');
    const [browserUrlSearchParams] = useSearchParams();

    const { fetch, ...queries } = useQuery();

    const getParamsForRequest = useFilterRequestMapper({
        filterTypes
    });

    const fetchData = async (urlSearchParams: URLSearchParams = browserUrlSearchParams) => {
        const params = requestSearchParamsRef.current = getParamsForRequest(urlSearchParams);

        return fetch(params);
    };

    const dashboardFilters = useStatisticDashboardFilters({
        fetchData,
        filterTypes
    });

    const fetchDataRef = useRef<typeof dashboardFilters.onApply | null>(dashboardFilters.onApply);

    useEffect(() => {
        fetchDataRef.current?.();
        fetchDataRef.current = null;
    }, []);

    return {
        ...queries,
        ...dashboardFilters,
        requestSearchParamsRef
    };
};
