import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loader from "ui/molecules/Loader";
import type { ResourceProps } from "./types";

const Resource = ({
    isError,
    errorSlot,
    isEmpty,
    emptySlot,
    isLoading,
    children
}: PropsWithChildren<ResourceProps>) => {
    const renderContent = () => {
        if (isError) {
            return errorSlot ?? (
                <Box>
                    <Typography
                        variant='body1'
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        An error occurred, please try again later
                    </Typography>
                </Box>
            );
        }

        if (isEmpty) {
            return emptySlot ?? (
                <Box>
                    <Typography
                        variant='body1'
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        No data available
                    </Typography>
                </Box>
            );
        }

        return children;
    };

    return (
        <Loader
            isLoading={Boolean(isLoading)}
        >
            {renderContent()}
        </Loader>
    );
};

export default memo(Resource);
