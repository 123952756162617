import { memo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import type { TextFieldLoadableProps } from "./types";
import useTextField from "./useTextField";

const TextFieldLoadable = ({ loading, CircularProgressProps, ...props }: TextFieldLoadableProps) => (
    <TextField
        {...props}
        {...useTextField(props)}
        InputProps={{
            endAdornment: (
                <>
                    {loading
                        ? (
                            <CircularProgress
                                color='primary'
                                size={20}
                                {...CircularProgressProps}
                            />
                        )
                        : null}
                </>
            ),
            ...props.InputProps
        }}
    />
);

export default memo(TextFieldLoadable);
