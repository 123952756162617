import { useRef, useEffect } from "react";
import { useKYCSettingsActionsContext } from "../KYCSettingsProvider";
import useFetchResource from "hooks/useFetchResource";
import { ApiState } from "infrastructure/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import type { ID } from "types";
import { Filters, PER_PAGE_SIZE } from "consts/transactions";
import { getMerchantKYCSettings } from "features/merchants/api";
import { getKYCSettings } from "features/kyc/api";
import type { KYCSettingsInitActionPayload } from "../KYCSettingsProvider/types";
import { ResponseSettlementStatus } from "consts/api";

export type UseFetchServicesArg = {
    readonly merchantId?: ID;
};

export default function useFetchServices({ merchantId }: UseFetchServicesArg) {
    const kycSettingsActionsRef = useRef<
        ReturnType<typeof useKYCSettingsActionsContext>
    >();

    const kycSettingsActions = useKYCSettingsActionsContext();

    kycSettingsActionsRef.current = kycSettingsActions;

    const isRequestsProcessingRef = useRef(false);
    const getMerchantKYCSettingsRef = useRef(
        useFetchResource({
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE
        })
    );

    const getKYCSettingsRef = useRef(
        useFetchResource({
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE
        })
    );

    useEffect(() => {
        if (!isRequestsProcessingRef.current && (typeof merchantId !== 'undefined')) {
            isRequestsProcessingRef.current = true;
            kycSettingsActionsRef.current!.updateRequestState(ApiState.Pending);

            const requestSearchQueryParams = RequestQueryMapper.from()
                .containsIn(Filters.merchantId, String(merchantId))
                .toString();

            Promise.allSettled([
                getMerchantKYCSettingsRef.current(() =>
                    getMerchantKYCSettings(
                        requestSearchQueryParams
                    )
                ),
                getKYCSettingsRef.current(() =>
                    getKYCSettings(
                        requestSearchQueryParams
                    )
                )
            ])
                .then(([merchantKYCSettings, kycSettings]) => {
                    const kycSettingsInitPayload = {} as KYCSettingsInitActionPayload;

                    if (merchantKYCSettings.status === ResponseSettlementStatus.Fullfilled) {
                        kycSettingsInitPayload.kysServices = merchantKYCSettings.value.data?.data ?? [];
                    }

                    if (kycSettings.status === ResponseSettlementStatus.Fullfilled) {
                        kycSettingsInitPayload.kycSettings = kycSettings.value.data?.data[0] ?? {};
                    }

                    kycSettingsActionsRef.current!.initKYCSettings(kycSettingsInitPayload);
                    kycSettingsActionsRef.current!.updateRequestState(ApiState.Succeeded);
                })
                .finally(() => {
                    isRequestsProcessingRef.current = false;
                });
        }
    }, [merchantId]);
};
