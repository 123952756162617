import type { GridCellParams } from "@mui/x-data-grid-premium";
import type { Files } from "features/merchantPayouts/types";
import Box from '@mui/material/Box';
import { memo, MouseEvent } from "react";
import PayableControl from "./PayableControl";
import ActionMenu from "ui/organizms/MerchantPayouts/ActionMenu";
import useFilesActions from "./useFilesActions";

const Actions = ({ row }: GridCellParams<Files>) => {
    const { getMenuItems, getRequestMappings } = useFilesActions(row);

    const onClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, auto)'
            }}
            onClick={onClick}
        >
            <PayableControl row={row} />
            <ActionMenu
                row={row}
                getMenuItems={getMenuItems}
                requestMappings={getRequestMappings()}
            />
        </Box>
    );
};

export default memo(Actions);
