import { memo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    readonly message: String
}
const Processing = ({ message }:Props) => (
    <Box
        sx={{
            maxWidth: '300px',
            width: '100%'
        }}
    >
        <Box>{message}</Box>
        <Box
            sx={{
                mt: '10px',
                display: 'grid',
                placeContent: 'center'
            }}
        >
            <CircularProgress />
        </Box>
    </Box>
);

export default memo(Processing);