import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import type { Transaction } from "features/transactions/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { memo} from "react";
import usePingOrderActions from "./usePingOrderActions";



type Props = LoadingButtonProps & Pick<Transaction, 'pingTransactionCoreId' >;

const SettlePingOrderAction = ({ pingTransactionCoreId, ...restProps }: Props) => {
  const {checkIfSettlePingOrderAvailable} = usePingOrderActions({pingTransactionCoreId})
  return (
    <>
    {checkIfSettlePingOrderAvailable() && (
        <LoadingButton
                {...restProps}
        >
            Settle PING order
        </LoadingButton>
    )}
     
    </>
  );
};

export default memo(SettlePingOrderAction);
