import { memo } from "react";
import ActionPanel from "ui/molecules/ActionPanel";
import useTicketingSystemActionPanel from "./useTicketingSystemActionPanel";

const TicketingSystemActionPanel = () => {
    const {
        isLoading,
        isDisabled,
        onSave
    } = useTicketingSystemActionPanel();

    return (
        <ActionPanel
            withProcessingAlert
            isLoading={isLoading}
            onSave={onSave}
            SaveActionProps={{
                disabled: isDisabled,
                children: 'Save Changes'
            }}
            cancelActionSlot={<></>}
        />
    );
};

export default memo(TicketingSystemActionPanel);
