import { memo } from "react";
import { BarStatisticDiagram } from "ui/molecules/StatisticDiagram";
import {
    esTransactionsByCompanyPerPSPQueryToken,
    useStatisticDashboardContext
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import type { BarDatum } from "@nivo/bar";
import type { AxisTickProps, BarTooltipProps } from "../shared/types";
import { randomColor } from "util/support";
import { CurrencySymbol } from "consts/general";
import BarTooltip from "../shared/BarTooltip";
import AxisBottomTick from "../shared/AxisBottomTick";
import { fNumber } from "util/formaters";

const ProcessedAmountsByCompanyPerPSP = () => {
    const statisticDashboard = useStatisticDashboardContext();
    const { payload, isLoading } = statisticDashboard[esTransactionsByCompanyPerPSPQueryToken];

    const keysSet = new Set<string>();
    const pspSet = new Set<string>();

    const getChartData = () => {
        if (!payload) {
            return [];
        }

        const processedAmountByPSPMap = payload.rows.reduce((pspMap, row) => {
            const amount = Number(row.at(0));
            const psp = String(row.at(-1));
            const mor = String(row.at(-2));

            pspSet.add(psp);
            keysSet.add(mor);

            return pspMap.set(psp, {
                ...pspMap.get(psp),
                PSP: psp,
                [mor]: amount,
                [`${mor}Color`]: randomColor()
            });
        }, new Map<string, BarDatum>());

        return Array.from(processedAmountByPSPMap.values());
    };

    const isMorField = ([key, value]: [string, string | number]) => (
        !key.endsWith('Color') &&
        !Number.isNaN(Number(value))
    );

    const getAmountFromDatum = (barDatum: BarDatum): number =>
        Object.entries(barDatum).reduce((acc, [key, value]) => {
            if (isMorField([key, value])) {
                return Number(acc) + Number(value);
            }

            return acc;
        }, 0);

    const getMorNamesFromDatum = (barDatum: BarDatum): string =>
        Object.entries(barDatum).reduce((acc, [key, value]) => {
            if (isMorField([key, value])) {
                return [acc, key]
                    .filter(Boolean)
                    .join();
            }

            return acc;
        }, '');

    const calculateAxisBottomTickRotation = () => {
        if (pspSet.size > 4) {
            return 30;
        }

        return 0;
    };

    const data = getChartData();

    const getBottomTooltipProps = ({ value }: AxisTickProps): BarTooltipProps => {
        const {
            companies,
            totalAmount
        } = getChartData()
            .reduce((acc, barDatum) => {
                if (Object.is(barDatum.PSP, value)) {
                    return {
                        totalAmount: Number(acc.totalAmount) + getAmountFromDatum(barDatum),
                        companies: [acc.companies, getMorNamesFromDatum(barDatum)]
                            .filter(Boolean)
                            .join()
                    };
                }

                return acc;
            }, {
                companies: '',
                totalAmount: 0
            });

        return {
            label: `${companies} - ${value}`,
            formattedValue: fNumber(totalAmount, ',.2f'),
            postfix: CurrencySymbol.EUR
        } as BarTooltipProps;
    };

    return (
        <BarStatisticDiagram
            sx={{
                position: 'relative',
                overflow: 'visible'
            }}
            isLoading={isLoading}
            isDataAvailable={Boolean(data.length)}
            title='Processed Amounts (Company)'
            subheader='Per PSP'
            chartProps={{
                style: {
                    maxHeight: 478,
                    height: '100%'
                },
                keys: Array.from(keysSet),
                indexBy: 'PSP',
                // margin: { top: 50, right: 130, bottom: 50, left: 60 },
                // padding: 0.3,
                valueScale: { type: 'linear' },
                indexScale: {
                    type: 'band',
                    round: true
                },
                colors: { scheme: 'category10' },
                valueFormat: " >-,.2f",
                /*defs: [
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#ea9b57',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ],
                fill: [
                    {
                        match: {
                            id: MOR.Default
                        },
                        id: 'dots'
                    },
                    // {
                    //     match: {
                    //         id: 'sandwich'
                    //     },
                    //     id: 'lines'
                    // }
                ],*/
                enableGridX: true,
                enableLabel: false,
                borderColor: {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                },
                tooltip: props => (
                    <BarTooltip
                        {...props}
                        postfix={CurrencySymbol.EUR}
                    />
                ),
                axisTop: null,
                axisRight: null,
                axisBottom: {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: calculateAxisBottomTickRotation(),
                    // legend: 'PSP',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    renderTick: props => (
                        <AxisBottomTick
                            {...props}
                            isLoading
                            renderTitle={props => {
                                const {
                                    label,
                                    formattedValue,
                                    postfix
                                } = getBottomTooltipProps(props);

                                return `${label}: ${formattedValue} ${postfix}`;
                            }}
                        />
                    )
                },
                axisLeft: {
                    format: '.2s',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: `Amounts (${CurrencySymbol.EUR})`,
                    legendPosition: 'middle',
                    legendOffset: -45
                },
                labelSkipWidth: 12,
                labelSkipHeight: 12,
                labelTextColor: {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                },
                legends: [
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ],
                role: 'application',
                ariaLabel: 'Processed Amounts (Company) Per PSP',
                barAriaLabel: e => `${e.id}: ${e.formattedValue} per PSP ${e.indexValue}`,
                data
            }}
        />
    );
};

export default memo(ProcessedAmountsByCompanyPerPSP);
