import type { Files } from "features/merchantPayouts/types";
import type usePayableRollingReserveCalculationDescription from "./usePayableRollingReserveCalculationDescription";

export const enum RollingReserve {
    RetainedCap = 'rollingReserveCap',
    Balance = 'rollingReserved',
    PayoutCurrency = 'payoutCurrency'
};

export type PayableRollingReserveCalculationDescriptionProps =
    & ReturnType<typeof usePayableRollingReserveCalculationDescription>
    & Pick<
        Files,
        | 'merchantId'
        | 'merchantName'
        | 'currency'
        | 'rollingReserveRetained'
        | 'rollingReservePaid'
        | 'isPaid'
    >;
