import { ProfileInfo } from "ui/forms/Merchant";
import useMerchantDetail from "../../useMerchantDetail";
import useCountries from "features/general/useCountries";

export default function useMerchantProfileInfo(): ProfileInfo.MerchantProfileInfoForm {
    const { merchant } = useMerchantDetail();
    const { countries, isCountriesLoading } = useCountries();

    const onSubmit = async (_: Partial<ProfileInfo.MerchantProfileInfo>) => {};

    return {
        ...ProfileInfo.useForm({
            initialValues: merchant,
            onSubmit
        }),
        onSaveOrCreate: onSubmit,
        bootstrapData: {
            countries
        },
        isBootstrapDataLoading: isCountriesLoading,
    };
};
