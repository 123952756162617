import { memo, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import MerchantPaymentMethodsFilters from "./MerchantPaymentMethodsFilters";

const MerchantPaymentMethodsFiltersPanel = ({ children }: PropsWithChildren) => {
    const sx = {
        display: 'flex',
        alignItems: 'center',
        gap: 2
    };

    return (
        <Box
            sx={{
                ...sx,
                justifyContent: 'space-between',
            }}
        >
            <MerchantPaymentMethodsFilters
                sx={{
                    ...sx,
                    mb: 2
                }}
            />
            {children}
        </Box>
    );
};

export default memo(MerchantPaymentMethodsFiltersPanel);
