import type { FormikConfig, FormikState, FormikValues } from "formik";
import type useForm from "hooks/useForm";
import type { ReactNode } from "react";
import type { Scalar } from "types";
import type { WithSubscription } from "providers/ContextPublisher";

export const getFormInputProps = (
  fieldName = "",
  { errors, touched }: Pick<FormikState<FormikValues>, 'errors' | 'touched'>
) => {
  return {
    error: Boolean(touched[fieldName] && errors[fieldName]),
    helperText:
      (touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined) as ReactNode,
  };
};

export const getDirtyFields = <T extends object>({
  values,
  touched,
  initialValues
}: Pick<
  FormikState<FormikValues>,
  | 'values'
  | 'touched'
> & Pick<FormikConfig<T>, 'initialValues'>) =>
  Object.entries(values).reduce((acc: T, [key, value]) => {
    if (touched[key] && (initialValues[key as keyof T] !== value)) {
      return {
        ...acc,
        [key]: value
      };
    }

    return acc;
  }, {} as T);

export const fromFormableRecord = (record: FormableRecord, tab: Scalar<string>) => record[
  tab
];

export const aggregateFormableRecord = (
  record: FormableRecord,
  aggregateFn: ([key, value]: [Scalar<string>, UnsavedChangesFormState]) => void
) => Object.entries(record)
  .forEach(entries =>
    aggregateFn(entries)
  );

export const isFormChanged = ({ touched, dirty }: PickFormState<'touched' | 'dirty'>) =>
  Object.values(touched).includes(true) && dirty;

export const hasActiveChanges = (record: FormableRecord) => Object.values(record)
  .some(isFormChanged);

export const canSubmit = (record: FormableRecord) => (
  Object.values(record)
    .every(form => form.isValid) &&
  hasActiveChanges(record)
);

export const resetActiveChanges = (record: FormableRecord) =>
  Object.values(record)
    .forEach(form => form.setTouched({}));

export const validate = (record: FormableRecord) =>
  Object.values(record)
    .forEach(form => form.validateForm());

export type PickFormState<T extends keyof ReturnType<typeof useForm>> =
  & Pick<ReturnType<typeof useForm>, T>;

export type UnsavedChangesFormState = WithSubscription<
  PickFormState<
    | 'isValid'
    | 'setTouched'
    | 'touched'
    | 'dirty'
  > & {
    readonly validateForm: () => void;
  }
>;

export type FormableRecord = Record<
  Scalar<string>,
  UnsavedChangesFormState
>;
