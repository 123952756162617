import { FilterVariant } from "consts/filters";
import { paginate, sort, summable, visible } from "consts/table";
import { RequestQueryMapper } from "util/request-query-mapper";
import { stringifyUrlQueryParams } from "util/support";
import useFiltersVariants from "../useFiltersVariants";
import commonQueryMapper from "./commonQueryMapper";
import filtrableQueryMapper from "./filtrableQueryMapper";

type UseFiltersRequestMappingArg<TFilterType> = {
    readonly filterTypes: Map<FilterVariant, TFilterType[]>;
    readonly requestQueryOverrideDecorator?: (
        aggregator: (
            fontendQueryParams: URLSearchParams,
            backendQueryParams: URLSearchParams
        ) => void
    ) => (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void;
};

export default function useFiltersRequestMapping<TFilterType extends string>({
    filterTypes,
    requestQueryOverrideDecorator = _ => _
}: UseFiltersRequestMappingArg<TFilterType>) {
    const {
        getFilterVariantByFilterName,
        getAllFilterNames
    } = useFiltersVariants(filterTypes);

    const getAllParamsByFilterName = (
        fontendQueryParams: URLSearchParams,
        filterName: TFilterType
    ) => fontendQueryParams
        .getAll(filterName);

    const traverseBySpecificFilterNames = (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        for (const filterName of getAllFilterNames()) {
            getAllParamsByFilterName(
                fontendQueryParams,
                filterName
            )
                .forEach((filterValue, index) => {
                    const filterVariant = getFilterVariantByFilterName(filterName);
                    filtrableQueryMapper(filterVariant)({
                        filterName,
                        filterValue,
                        queryMapper: RequestQueryMapper.from(backendQueryParams),
                        index
                    });
                });
        }
    };

    const traverseByCommonFilterNames = (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        for (const filterName of [
            ...paginate,
            ...sort,
            ...visible,
            ...summable
        ] as Array<TFilterType>) {
            getAllParamsByFilterName(
                fontendQueryParams,
                filterName
            )
                .forEach((filterValue, index) => {
                    commonQueryMapper({
                        filterName,
                        filterValue,
                        queryMapper: RequestQueryMapper.from(backendQueryParams),
                        index
                    });
                })
        }
    }

    return (
        fontendQueryParams: URLSearchParams,
        backendQueryParams = new URLSearchParams()
    ) => {
        const resultingbackendQueryParams = [
            traverseBySpecificFilterNames,
            traverseByCommonFilterNames
        ]
            .reduce((searchParams: URLSearchParams, aggregator) => {
                requestQueryOverrideDecorator(aggregator)(
                    fontendQueryParams,
                    searchParams
                );

                return searchParams;
            }, backendQueryParams);

        return stringifyUrlQueryParams(resultingbackendQueryParams);
    };
};
