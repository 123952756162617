import { PayoutSettings } from "ui/forms/Merchant";
import {
    selectAccountWithdrawalOptions,
    selectIsBootstrapDataLoading,
    selectPayoutCycles
} from "features/general/selectors";
import { useTypedSelector } from "hooks";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantProfileRepository } from "../repository";
import { getInitialValues } from "ui/forms/Merchant/PayoutSettings";

export default function useMarchantPayoutSettings(): PayoutSettings.MerchantPayoutSettingsForm {
    const payoutCycle = useTypedSelector(selectPayoutCycles);
    const withdrawal = useTypedSelector(selectAccountWithdrawalOptions);

    const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);

    const repository = useMerchantDetailsTabContext<MerchantProfileRepository>();

    const onSubmit = async (_: PayoutSettings.MerchantPayoutSettings) => { };

    const merchantPayoutSettingsForm = PayoutSettings.useForm({
        onSubmit,
        initialValues: getInitialValues(repository.merchantSettings ?? {})
    });

    return {
        ...merchantPayoutSettingsForm,
        onSaveOrCreate: onSubmit,
        bootstrapData: {
            payoutCycle,
            withdrawal
        },
        isBootstrapDataLoading: (
            isBootstrapDataLoading ||
            repository.isMerchantSettingsLoading
        )
    };
};
