import { memo } from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from "ui/molecules/Table";
import Chip, { type ChipProps } from "@mui/material/Chip";
import { ManualBulkKycImportStatus } from "consts/kyc";
import type useBulkKYCHistoryResultsDialog from "../useBulkKYCHistoryResultsDialog";
import { Typography } from "@mui/material";

type Props = Pick<
    ReturnType<typeof useBulkKYCHistoryResultsDialog>,
    | 'isDataLoading'
    | 'consumersManualBulkKYCsImportHistoryPivotGroup'
>;

const sharedStyles = {
    fontWeight: 'bold',
    borderRadius: '5px',
    minWidth: 88
};
const reason = {
    "CREATED": 'Manually created',
    "IGNORED": "Already KYC`d",
    "NOT_FOUND": " Not in DB",
    "NOT_CREATED": ' - '
}

const chipPropsMap = new Map<ManualBulkKycImportStatus, ChipProps>()
    .set(ManualBulkKycImportStatus.Created, {
        label: 'KYC`D',
        sx: {
            ...sharedStyles,
            bgcolor: '#3366FF',
            color: "#FFFFFF"
        }
    })
    .set(ManualBulkKycImportStatus.Ignored, {
        label: 'IGNORED',
        sx: {
            ...sharedStyles,
            bgcolor: '#FFE4DE',
            color: '#FF5630'
        }
    })
    .set(ManualBulkKycImportStatus.NotFound, {
        label: 'NOT FOUND',
        sx: {
            ...sharedStyles,
            bgcolor: '#DFE3E8',
            color: '#212B36'
        }
    });

const BulkKYCHistoryResultsTable = ({ isDataLoading, consumersManualBulkKYCsImportHistoryPivotGroup }: Props) => (
    <Table
        sx={(isDataLoading || !consumersManualBulkKYCsImportHistoryPivotGroup.length)
            ? ({
                minHeight: 300
            })
            : ({
                alignItems: 'flex-start'
            })}
        isLoading={isDataLoading}
        isEmpty={!consumersManualBulkKYCsImportHistoryPivotGroup.length}
    >
        <TableHead>
            <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="center">Face Verification</TableCell>
                <TableCell align="center">ID Verification</TableCell>
                <TableCell align="center">Address Verification</TableCell>
                <TableCell align="center">Reason</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {consumersManualBulkKYCsImportHistoryPivotGroup.map(({
                emailOrHash,
                kycTypeIds,
                importStatuses
            }) => {
                const [importStatus] = importStatuses;
                const [faceKYCTypeId, idKYCTypeId, addressKYCTypeId] = kycTypeIds;
                const kycTypes = [
                    faceKYCTypeId,
                    idKYCTypeId,
                    addressKYCTypeId
                ];

                return (
                    <TableRow
                        key={emailOrHash}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0
                            }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            {emailOrHash}
                        </TableCell>
                        {kycTypes.map(kycTypeId => (
                            <TableCell
                                key={kycTypeId}
                                align="center"
                            >

                                {
                                    typeof kycTypeId === 'number' ?
                                        <Chip
                                            {...chipPropsMap.get(importStatus)}
                                        /> :
                                        '-'
                                }
                            </TableCell>
                        ))}
                        <TableCell component="th" scope="row">
                            <Typography 
                            sx={ {
                                minWidth: '150px',
                                fontWeight: 600
                            }}> 
                                {reason[importStatus]}
                            </Typography>
                        
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    </Table>
);

export default memo(BulkKYCHistoryResultsTable);
