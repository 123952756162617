// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
import { WebRoutes } from 'consts/enpoints/web';
import useAuth from 'features/auth/useAuth';
// locales
import { useLocales } from 'locales';

// ----------------------------------------------------------------------

export type NavDocsProps = {
  readonly shouldRenderDocumentation?: boolean;
};

export default function NavDocs({ shouldRenderDocumentation }: NavDocsProps) {
  const { getUserName } = useAuth();

  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box component="img" src="/assets/illustrations/illustration_docs.svg" />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {translate('docs.hi')}, {getUserName()}
        </Typography>

        {Boolean(shouldRenderDocumentation) && (
          <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
            <>{translate('docs.description')}</>
          </Typography>
        )}
      </div>

      {Boolean(shouldRenderDocumentation) && (
        <Button href={WebRoutes.Root} target="_blank" rel="noopener" variant="contained">
          <>{translate('docs.documentation')}</>
        </Button>
      )}
    </Stack>
  );
}
