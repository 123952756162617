import { useParams } from "react-router-dom";
import { useState } from "react";
import { useKYCSettingsActionsContext, useKYCSettingsStateSelectorsContext } from "../KYCSettingsProvider";
import { Filters } from "consts/transactions";
import { ApiState } from "infrastructure/api";
import { MerchantKYCService } from "features/merchants/types";
import { KYCSettingsServicePropertyName } from "consts/kyc";
import { updateOrCreateMerchantKYCSettings } from "features/merchants/api";
import { useSimpleRequest } from "hooks/useRequest";
import { updateOrCreateKYCSettings } from "features/kyc/api";

export default function useKYCActionPanel() {
    const params = useParams();

    const merchantId = params[Filters.merchantId];

    const [isLoading, setLoading] = useState(false);

    const apiRequest = useSimpleRequest();

    const {
        selectKYCSettingsLoadingState,
        selectKYCServices,
        selectAdditionalSettingsByTypeId,
        selectKYCSettings,
        selectIsKYCSettingsChanged,
        selectKYCProvider
    } = useKYCSettingsStateSelectorsContext();

    const {
        syncInitialKYCSettings
    } = useKYCSettingsActionsContext();

    const withIncludeIdVerifiedConsumers = <T extends Partial<MerchantKYCService>>(
        merchantKYCService: T,
        kycSettings: ReturnType<typeof selectAdditionalSettingsByTypeId>
    ): T => {
        const kycSettingsWithIncludeIdVerifiedConsumers = kycSettings.find(({ name }) =>
            name === KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers);

        if (kycSettingsWithIncludeIdVerifiedConsumers) {
            return {
                ...merchantKYCService,
                includeIdVerifiedConsumers: kycSettingsWithIncludeIdVerifiedConsumers.id
            };
        }

        return merchantKYCService;
    };

    const handleCreateOrUpdateMerchantKYCSettings = () => {
        const requests = [];

        for (const [kycTypeId, { kycLimit, summedAmountEurLimit, kycSettings, isActive }] of Object.entries(selectKYCServices())) {
            if (isActive === false) {
                continue;
            }

            const requestBody = withIncludeIdVerifiedConsumers({
                    merchantId: Number(merchantId),
                    kycTypeId: Number(kycTypeId),
                    provider: selectKYCProvider(),
                    kycLimit,
                    summedAmountEurLimit
                }, kycSettings);

            requests.push(
                apiRequest(() => updateOrCreateMerchantKYCSettings(requestBody))
            );
        }

        return Promise.allSettled(requests);
    };

    const handleCreateOrUpdateKYCSettings = () =>
        apiRequest(() => updateOrCreateKYCSettings({
            ...selectKYCSettings(),
            merchantId: Number(merchantId)
        }));

    const onSave = async () => {
        setLoading(true);

        await Promise.allSettled([
            handleCreateOrUpdateMerchantKYCSettings(),
            handleCreateOrUpdateKYCSettings()
        ]);

        syncInitialKYCSettings();

        setLoading(false);
    };

    const isDomainBoundaryContextLoading = () => [
        selectKYCSettingsLoadingState,
    ].some(selector => selector({
        apiState: ApiState.Succeeded,
        notIn: true
    }));

    return {
        isLoading,
        isDisabled: isLoading
            || isDomainBoundaryContextLoading()
            || !selectIsKYCSettingsChanged(),
        onSave,
    };
};
