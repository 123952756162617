import {
    TicketingSystemInitActionPayload,
    TicketingSystemResources,
    TicketingSystemService,
    TicketingSystemServicesState
} from "./types";
import {
    getAdditionalOptionsStatus,
    getAdditionalOptionsInterval
} from "./getters/additionalOptions";
import { getTicketingSystemNotificationFromServiceState } from "./getters/payload";
import { BooleanSwitch } from "types";

export const servicesStateAdapter = ({
    merchantSettings: {
        showTicketingSystemOnInit,
        showTicketingSystemOnRedirect,
        showTicketingSystemOnRedirectOnlyOnStatuses
    },
    merchantsNotificationChannelsPivot: {
        isEnabled,
        secondsInterval
    }
}: Omit<TicketingSystemInitActionPayload, 'modifyResourceStrategy'>): TicketingSystemServicesState => ({
    [TicketingSystemService.SupportPageOnInit]: {
        enabled: Boolean(showTicketingSystemOnInit),
        additionalOptions: null
    },
    [TicketingSystemService.SupportPageOnRedirect]: {
        enabled: Boolean(showTicketingSystemOnRedirect),
        additionalOptions: getAdditionalOptionsStatus(
            showTicketingSystemOnRedirectOnlyOnStatuses
        )
    },
    [TicketingSystemService.EmailConfirmation]: {
        enabled: Boolean(isEnabled),
        additionalOptions: getAdditionalOptionsInterval(
            secondsInterval
        )
    }
});

export const servicesApiAdapter = (ticketingSystemServicesState: TicketingSystemServicesState): TicketingSystemResources => ({
    merchantSettings: {
        showTicketingSystemOnInit: getTicketingSystemNotificationFromServiceState(
            ticketingSystemServicesState[TicketingSystemService.SupportPageOnInit]
        ),
        showTicketingSystemOnRedirect: getTicketingSystemNotificationFromServiceState(
            ticketingSystemServicesState[TicketingSystemService.SupportPageOnRedirect]
        ),
        showTicketingSystemOnRedirectOnlyOnStatuses:
            ticketingSystemServicesState[TicketingSystemService.SupportPageOnRedirect]
                .additionalOptions
                ?.id ?? ''
    },
    merchantsNotificationChannelsPivot: {
        isEnabled: Number(
            ticketingSystemServicesState[TicketingSystemService.EmailConfirmation]
                .enabled
        ) as BooleanSwitch,
        secondsInterval: Number(
            ticketingSystemServicesState[TicketingSystemService.EmailConfirmation]
                .additionalOptions
                .id
        )
    }
});
