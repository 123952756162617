import { useRef, useState } from "react";
import { copyToClipboard } from "util/clipboard";

export default function useClipboard({
    initialState = '',
    resetTimeout = 1000
} = {}) {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const [content, setContent] = useState<string>(initialState);

    const copy = (context: string) =>
        copyToClipboard(context)
            .then(() =>
                setContent(context)
            );

    const reset = () => {
        timeoutRef.current = setTimeout(() => {
            setContent(initialState);
        }, resetTimeout);
    };

    const cancel = () =>
        clearTimeout(timeoutRef.current);

    return {
        timeoutRef,
        content,
        copy,
        reset,
        cancel
    };
};
