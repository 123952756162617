import { FC, FunctionComponent, memo, useCallback } from "react";

import { DataGridPremium, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

import merchantDetailsTableParser from "../merchantDetailsTableConfigTransformer";
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import NoDataOverlay from './NoDataOverlay';
import { Consumers } from "features/consumers/types";

// TODO: unused please delete this file

type Props = {
    readonly merchants: Consumers;
    readonly isLoading: boolean;
    readonly Toolbar?: FunctionComponent;
};

const MerchantDetailsTable: FC<Props> = ({
    merchants,
    isLoading,
    Toolbar
}) => {
    const columns = merchantDetailsTableParser();

    const getDetailPanelHeight: DataGridPremiumProps['getDetailPanelHeight'] =
        useCallback(() => 'auto' as const, []);

    return (
        <Box
            m={3}
            pb={3}
        >
            <Card
                sx={{
                    height: `calc(100vh - 210px)`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <DataGridPremium
                    rows={merchants ?? []}
                    loading={isLoading}
                    columns={columns}
                    components={{
                        Toolbar,
                        NoRowsOverlay: NoDataOverlay,
                        LoadingOverlay: LinearProgress,
                    }}
                    // componentsProps={{
                    //     toolbar: { showQuickFilter: true, sx: { margin: 2 } }
                    // }}
                    density='compact'
                    pagination
                    getDetailPanelHeight={getDetailPanelHeight}
                    hideFooterSelectedRowCount
                />
            </Card>
        </Box>
    );
};

export default memo(MerchantDetailsTable);
