import { stateFactory } from "./state";
import {
    MerchantAccountSettingsActionType,
    type MerchantAccountSettingsRepositoryAction,
    type MerchantAccountSettingsRepositoryState
} from "./types";

export function reducer(state: MerchantAccountSettingsRepositoryState, action: MerchantAccountSettingsRepositoryAction) {
    switch (action.type) {
        case MerchantAccountSettingsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
