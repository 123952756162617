import { type ReactNode, memo } from "react";
import Box, { type BoxProps } from '@mui/material/Box';
import Checkbox, { type CheckboxProps } from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import { useTicketingSystemStateSelectorsContext } from "../../TicketingSystemProvider";
import { ApiState } from "infrastructure/api";

export type ServiceProps = Pick<
    CheckboxProps,
    | 'value'
> & {
    readonly onChange: (isEnabled: boolean) => void;
    readonly label: ReactNode;
    readonly additionalOptionsSlot?: ReactNode;
    readonly sx?: BoxProps['sx'];
};

const Service = ({
    value,
    onChange,
    label,
    additionalOptionsSlot,
    sx
}: ServiceProps) => {
    const { selectTicketingSystemApiState } = useTicketingSystemStateSelectorsContext();

    const handleChange: CheckboxProps['onChange'] = (_, isEnabled) =>
        onChange(isEnabled);

    return (
        <Box
            sx={{
                ...sx,
                display: 'grid',
                gridTemplateColumns: 'auto 1fr max-content',
                alignItems: 'center',
                py: 1
            }}
        >
            {selectTicketingSystemApiState({
                apiState: [ApiState.Succeeded, ApiState.Failed],
                notIn: true
            })
                ? (
                    <>
                        {Array.from({ length: 3 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant='rounded'
                                width='100%'
                                height={40}
                            />
                        ))}
                    </>
                )
                : (
                    <>
                        <Checkbox
                            checked={Boolean(value)}
                            onChange={handleChange}
                        />
                        <Box>{label}</Box>
                        <Box>{additionalOptionsSlot}</Box>
                    </>
                )}
        </Box>
    );
};

export default memo(Service);
