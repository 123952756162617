import { PropsWithChildren, memo } from "react";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import BasicDialog from "./Basic";
import type { BasicDialogProps } from "./Props";

type Props = Partial<
    Pick<
        BasicDialogProps,
        | 'isOpen'
        | 'onClose'
        | 'DialogProps'
    >
>;

const Processing = ({ children, ...dialogProps }: PropsWithChildren<Props>) => (
    <BasicDialog
        isOpen={false}
        onClose={() => { }}
        DialogProps={{
            sx: {
                "& .MuiDialog-container .MuiPaper-root": {
                    maxWidth: 300
                }
            }
        }}
        {...dialogProps}
    >
        <Box
            sx={{
                maxWidth: '300px',
                width: '100%',
                textAlign: 'center'
            }}
        >
            <Box>{children || 'Processing...'}</Box>
            <Box
                sx={{
                    mt: '10px',
                    display: 'grid',
                    placeContent: 'center'
                }}
            >
                <CircularProgress />
            </Box>
        </Box>
    </BasicDialog>
);

export default memo(Processing);
