import { useAppDispatch } from "hooks";
import { useCallback } from "react";
import { addNotification, removeNotification, toggleSideMenu } from "./slice";
import type { Notification } from "./types";
import { getEnvironment } from "util/support";

const useActions = () => {
  const dispatch = useAppDispatch();
  return {
    toggleSideMenu: useCallback(() => {
      dispatch(toggleSideMenu());
    }, [dispatch]),
    showNotication: useCallback(
      (notification: Notification) => {
        dispatch(addNotification(notification));
      },
      [dispatch]
    ),
    hideNotication: useCallback(() => {
      dispatch(removeNotification());
    }, [dispatch]),
    getEnvironmentVariable: getEnvironment(),
  };
};

export default useActions;
