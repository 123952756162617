import { memo } from 'react';
import type { WidgetProps } from '../types';
import Widgetable from '../HOCs/Widgetable';

export type SummablesProps = WidgetProps;

const Summables = (props: SummablesProps) => (
    <Widgetable {...props} />
);

export default memo(Summables);
