import { useFormik, type FormikConfig } from "formik";
import { AccountWithdrawalOption, PayoutCycle } from "consts/merchants";
import { FormField, type MerchantPayoutSettings } from "./types";
import { useValidationSchema } from "./useValidationSchema";

export const getInitialValues = (initialValues?: MerchantPayoutSettings) => ({
    [FormField.AccountWithdrawalOption]: AccountWithdrawalOption.Manual,
    [FormField.PayoutCycle]: PayoutCycle.Weekly,
    [FormField.RollingReserveCap]: 0,
    ...initialValues
});

export const useForm = (payoutSettingsForm: FormikConfig<MerchantPayoutSettings>) =>
    useFormik<MerchantPayoutSettings>({
        enableReinitialize: true,
        validateOnMount: true,
        ...payoutSettingsForm,
        validationSchema: useValidationSchema()
    });
