import type { FormikValues } from "formik";
import type { BootstrapableForm } from "types";
import type { MerchantCreateForm } from "ui/forms/Merchant/Create";
import type { UserFormComponentProps } from "ui/forms/User";
import type { ActionPanelProps } from "ui/molecules/ActionPanel";
import { compareIds } from "util/support";

export const enum MerchantStepForm {
    Merchant,
    MerchantUser
};

export interface MerchantFormState<
    TFormValues extends FormikValues = FormikValues,
    TBootsrapData extends object = object
> extends BootstrapableForm<TFormValues, TBootsrapData> {
    renderTitle(): string;
    getActionPanelProps(): ActionPanelProps;
}

export function isMerchant(
    merchantFormState: MerchantFormState | MerchantCreateForm
): merchantFormState is MerchantCreateForm {
    return compareIds(
        merchantFormState.id!,
        MerchantStepForm.Merchant
    );
};

export function isMerchantUser(
    merchantFormState: MerchantFormState | UserFormComponentProps
): merchantFormState is UserFormComponentProps {
    return compareIds(
        merchantFormState.id!,
        MerchantStepForm.MerchantUser
    );
};
