import { createContext, memo, PropsWithChildren } from 'react';
import { useModal } from 'ui/atoms/Modal';

type MergeFilesContextType = Pick<ReturnType<typeof useModal>, 'open' | 'onOpen'>;

export const MergeFilesContext = createContext<MergeFilesContextType>({} as MergeFilesContextType);

const MergeFilesProvider = ({ children, ...restProps }: PropsWithChildren<MergeFilesContextType>) => (
    <MergeFilesContext.Provider value={restProps}>
        {children}
    </MergeFilesContext.Provider>
);

export default memo(MergeFilesProvider);
