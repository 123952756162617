import { memo } from "react";
import { selectIsExportTransactionsLoading } from "features/transactions/selectors";
import { TableToolbar } from "ui/widgets/Table";

type Props = {
    readonly onExport: () => void;
};

const TransactionListToolbar = ({ onExport }: Props) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportTransactionsLoading}
    />
);

export default memo(TransactionListToolbar);
