import { PropsWithChildren, createContext } from "react";
import useFetchPaymentMethods from "./useFetchPaymentMethods";
import { useParams } from "react-router-dom";
import { Filters } from "consts/transactions";

export const IncludeMethodsContext = createContext({} as ReturnType<typeof useFetchPaymentMethods>);

const IncludeMethodsProvider = ({ children }: PropsWithChildren) => (
    <IncludeMethodsContext.Provider
        value={useFetchPaymentMethods({
            merchantId: useParams()[Filters.merchantId]
        })}
    >
        {children}
    </IncludeMethodsContext.Provider>
);

export default IncludeMethodsProvider;
