import { createSlice } from "@reduxjs/toolkit";
import {
    paymentMethodsRequestFulfilled,
    paymentMethodsRequestLoading,
    paymentMethodsRequestRejected
} from "./reducers";
import initialState, { sliceToken } from "./state";
import { getPaymentMethodsThunk } from "./thunks";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        changePaymentMethodsLoadingState: (state, { payload }) => {
            state.paymentMethodsLoadingState = payload;
        },
        changePaymentMethodsSlice: (state, { payload }) => {
            state.paymentMethodsSlice = payload.data;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPaymentMethodsThunk.pending, paymentMethodsRequestLoading)
            .addCase(getPaymentMethodsThunk.fulfilled, paymentMethodsRequestFulfilled)
            .addCase(getPaymentMethodsThunk.rejected, paymentMethodsRequestRejected)
    }
});

export const {
    name,
    actions: {
        changePaymentMethodsLoadingState,
        changePaymentMethodsSlice
    }
} = slice;
export default slice.reducer;
