import { memo, useContext } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModesModel,
    GridRowModes,
    DataGridPremium,
    GridToolbarContainer
} from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import useFileTaxesGrid from './useFileTaxesGrid';
import type { ManageFileTaxes } from 'features/merchantPayouts/types';
import { ManageFileTaxesContext } from './ManageFileTaxesProvider';
import type { ManageFileTaxesProps } from './Props';
import type { ID } from 'types';

const FileTaxesGrid = ({ file }: ManageFileTaxesProps) => {
    const manageFileTaxesProps = useContext(ManageFileTaxesContext);

    const {
        columns,
        rowModesModel,
        setRowModesModel,
        handleRowEditStart,
        handleRowEditStop,
        processRowUpdate,
        getRowId
    } = useFileTaxesGrid(manageFileTaxesProps);

    return (
        <DataGridPremium
            rows={manageFileTaxesProps.data}
            columns={columns}
            editMode="row"
            loading={manageFileTaxesProps.isLoading}
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowId={getRowId}
            components={{
                Toolbar
            }}
            componentsProps={{
                toolbar: {
                    setRows: manageFileTaxesProps.addOne,
                    setRowModesModel,
                    coreId: file.coreId,
                    dateFromFile: file.dateFrom,
                    dateToFile: file.dateTo
                },
            }}
            experimentalFeatures={{ newEditingApi: true }}
        />
    );
};

export default memo(FileTaxesGrid);

function Toolbar(props: {
    setRows: (data: ManageFileTaxes) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    coreId: ID;
    dateFromFile: string,
    dateToFile: string

}) {
    const { setRows, setRowModesModel, coreId, dateFromFile, dateToFile } = props;

    const handleClick = () => {
        const id = randomId();

        setRows({
            merchantPayoutFileId: coreId,
            taxName: '',
            dateFrom: dateFromFile,
            dateTo: dateToFile,
            amount: 0.01,
            coreId: id,
        });

        setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'taxName' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClick}
            >
                Add new file tax
            </Button>
        </GridToolbarContainer>
    );
}

