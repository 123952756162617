import { useMemo } from 'react';
import { Filters } from "consts/merchantPayouts";
import { number, object, string } from 'yup';

export const getInitialValues = () => ({
    [Filters.merchantId]: undefined,
    [Filters.taxName]: undefined,
    [Filters.tag]: undefined,
    [Filters.refColFrom]: undefined,
    [Filters.refColFromMinSum]: undefined,
    [Filters.currentIncMultiplierForEachRefColumn]: undefined
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [Filters.merchantId]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .required("This is a required field!"),
            [Filters.taxName]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .required(),
            [Filters.tag]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .notRequired(),
            [Filters.refColFromMinSum]: number()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER),
            [Filters.currentIncMultiplierForEachRefColumn]: number()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .required("This is a required field!"),
            [Filters.refColFrom]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .notRequired()
        })
        , []);
