import type { MerchantGroup, MerchantsState } from "./types";
import { resourceSliceFactory, withBootstrapData, withEntityFactory } from "infrastructure/store/state";
import { MerchantTabs, merchantTabValueMapping } from "consts/merchants";
import { getSearchParameterFromUrl } from "util/support";
import { ApiState } from "infrastructure/api";

const state: MerchantsState = {
    merchants: resourceSliceFactory(),
    activeTab: merchantTabValueMapping.get(getSearchParameterFromUrl({
        name: 'activeTab',
        fallbackValue: '1'
    })) ?? MerchantTabs.Merchants,
    [MerchantTabs.Merchants]: resourceSliceFactory({
        ...withBootstrapData({
            merchantGroups: {
                slice: Array.of<MerchantGroup>(),
                loadingState: ApiState.Idle
            }
        }),
        ...withEntityFactory()
    }),
    [MerchantTabs.MerchantUsers]: resourceSliceFactory(),
    merchantUsers: resourceSliceFactory(),
    ledgers: resourceSliceFactory()
};

export const sliceToken = 'merchants';

export default state;
