import type { Tabs } from "features/merchants/types";

export const Filters = {
    isActive: 'isActive',
    isLiveMode: 'isLiveMode',
    merchantId: 'merchantId',
    methodId: 'methodId',
    merchantBusinessId: 'merchantBusinessId',
    fullName: 'fullName',
    paymentMethodTag: 'paymentMethodTag',
    paymentMethodPSP: 'paymentMethodPSP',
    MOR: 'MOR'
};

export const enum MerchantTabs {
    Merchants = 'Merchants',
    MerchantUsers = 'MerchantUsers'
};

export const merchantTabValueMapping = new Map<string, Tabs>([
    ['1', MerchantTabs.Merchants],
    ['2', MerchantTabs.MerchantUsers]
]);

export const enum AccountType {
    Bank,
    Crypto
};

export const enum PayoutCycle {
    Daily = 'daily',
    BiWeekly = 'bi-weekly',
    Weekly = 'weekly',
    Monthly = 'monthly'
};

export const enum AccountWithdrawalOption {
    Manual = 'M',
    Automatic = 'A'
};

export const enum ActiveStatus {
    Disabled = 'D',
    Active = 'A'
};

export const enum KycType {
    None = 'N/A',
    Soft = 'soft',
    Strict = 'strict'
};

export const single = [
    Filters.isActive
];
