import { memo } from 'react';
import { Filters } from 'consts/merchantPayouts';
import UpdateIncrementsDialog from './UpdateIncrementsDialog';
import { useProcessRowUpdate } from 'ui/molecules/TableGrids';
import useIncrements from './useIncrements';
import type {
    GridCellEditStopParams,
    GridEventListener,
    GridRenderEditCellParams,
    MuiEvent
} from '@mui/x-data-grid-premium';
import EditCurrencyExchangeRateCell from './EditCurrencyExchangeRateCell';
import MerchantPayoutsTable, { type MerchantPayoutsTableProps } from '../MerchantPayoutsTable';

const Increments = (props: MerchantPayoutsTableProps) => {
    const {
        isCellEditable,
        initialState,
        updateRequestHandler,
        isRowModelEqual,
        onFilterChange,
        onFiltersReset,
        FiltersProps
    } = useIncrements();

    const updateRowProps = useProcessRowUpdate({
        isRowModelEqual,
        requestHandler: updateRequestHandler
    });

    const onCellEditStop: GridEventListener<"cellEditStop"> = (_: GridCellEditStopParams, event: MuiEvent) => {
        event.defaultMuiPrevented = true;
    };

    return (
        <>
            <UpdateIncrementsDialog
                {...updateRowProps}
            />
            <MerchantPayoutsTable
                {...props}
                onFilterInit={onFilterChange}
                onFilterChange={onFilterChange}
                onFiltersReset={onFiltersReset}
                FiltersProps={{
                    ...props.FiltersProps,
                    ...FiltersProps,
                    filtersModelOverrides: {
                        ...props.FiltersProps?.filtersModelOverrides,
                        ...FiltersProps?.filtersModelOverrides
                    }
                }}
                initialState={initialState}
                isCellEditable={isCellEditable}
                processRowUpdate={updateRowProps.processRowUpdate}
                onCellEditStop={onCellEditStop}
                columns={[
                    {
                        field: Filters.currencyExchangeRate,
                        headerName: "Currency Exchange Rate",
                        minWidth: 150,
                        resizable: false,
                        // disableColumnMenu: true,
                        editable: true,
                        renderEditCell: (params: GridRenderEditCellParams<number>) => (
                            <EditCurrencyExchangeRateCell {...params} />
                        )
                    },
                ]}
            />
        </>
    );
};

export default memo(Increments);
