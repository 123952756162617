import { memo } from "react";
import Screen from "ui/layouts/Screen";
import TicketingSystem from 'ui/organizms/Merchants/TicketingSystem';

const TicketingSystemScreen = () => (
    <Screen
        title='Merchants | Ticketing System'
    >
        <TicketingSystem />
    </Screen>
);

export default memo(TicketingSystemScreen);
