import { memo } from "react";
import { ConfirmatableDialog } from "ui/molecules/Dialog";
import useUnsavedChangesContext from "./useUnsavedChangesContext";

const UnsavedChangesConfirmationDialog = () => {
    const {
        hasUnsavedChanges,
        confirmExit,
        cancelExit
    } = useUnsavedChangesContext();

    return (
        <ConfirmatableDialog
            open={hasUnsavedChanges()}
            content={[
                {
                    dialogContent: [
                        'Changes that you made are not saved, are you sure you want to quit?'
                    ],
                    actionContent: 'Yes'
                },
                {
                    dialogContent: [
                        'Changes that you made are not saved, are you sure you want to quit?'
                    ],
                    actionContent: 'Yes'
                }
            ]}
            onConfirm={confirmExit}
            onCancel={cancelExit}
            checkPredicate={() => true}
            cancelActionContentSlot='No'
        />
    );
};

export default memo(UnsavedChangesConfirmationDialog);
