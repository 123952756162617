import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { EsTransactions } from "ui/organizms/QueryBuilder";

const EsTransactionsScreen = () => (
    <Screen
        title='Query Builder | Es-Transactions'
    >
        <EsTransactions />
    </Screen>
);

export default memo(EsTransactionsScreen);
