import { memo, ChangeEvent } from "react";
import TextField from '@mui/material/TextField';
import type { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { useGridApiContext } from '@mui/x-data-grid';
import { EditableCell } from "ui/molecules/TableGrids/Table/Cells";

const EditCurrencyExchangeRateCell = (props: GridRenderEditCellParams<number>) => {
    const { id, field, value } = props;

    const apiRef = useGridApiContext();

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        apiRef.current.setEditCellValue({ id, field, value: target.value });
    };

    return (
        <EditableCell
            {...props}
        >
            <TextField
                type='number'
                onChange={handleChange}
                value={value}
            />
        </EditableCell>
    );
};

export default memo(EditCurrencyExchangeRateCell);
