import { useState, SyntheticEvent, useCallback } from 'react';
import type {
    GridColumns,
    GridEventListener,
    GridRowId,
    GridRowIdGetter,
    GridRowModel,
    GridRowModesModel,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {
    GridRowModes,
    GridActionsCellItem
} from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import useManageFileTaxes from './useManageFileTaxes';
import type { ManageFileTaxes } from 'features/merchantPayouts/types';
import { UiDateTimeFormatter, dateRangeFormatter } from 'util/formaters';
import { compareIds } from 'util/support';

export default function useFileTaxesGrid({
    data,
    deleteFileTaxes,
    deleteById,
    editById,
    updateFileTaxes,
    createFileTaxes
}: ReturnType<typeof useManageFileTaxes>) {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleRowEditStart = (
        _: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const isRowCommitted = (id: GridRowId) => {
        const editedRow = data.find(row => compareIds(row.coreId, id));
        if (!editedRow) {
            return false;
        }

        return !Number.isNaN(Number(`${editedRow.coreId}`));
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        if (!isRowCommitted(id)) {
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });
            deleteById(id);
            return;
        }

        deleteFileTaxes(id);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        if (!isRowCommitted(id)) {
            deleteById(id);
        }
    };

    const processRowUpdate = async (fileTaxes: GridRowModel<ManageFileTaxes>) => {
        const [dateFrom, dateTo] = dateRangeFormatter([fileTaxes.dateFrom, fileTaxes.dateTo], {
            pattern: UiDateTimeFormatter.Write
        });

        const fileTaxesData = {
            coreId: fileTaxes.coreId,
            merchantPayoutFileId: fileTaxes.merchantPayoutFileId,
            amount: fileTaxes.amount,
            taxName: fileTaxes.taxName,
            dateFrom,
            dateTo
        };

        const requestHandler = isRowCommitted(fileTaxes.coreId)
            ? updateFileTaxes
            : createFileTaxes;

        const { success, message, data } = await requestHandler(fileTaxesData);

        if (success) {
            editById(fileTaxes.coreId, data);

            return data;
        }

        throw new Error(message);
    };

    const getRowId: GridRowIdGetter<ManageFileTaxes> = useCallback(({ coreId }: ManageFileTaxes) =>
        coreId, []);

    const columns: GridColumns = [
        { field: 'coreId', hide: true },
        {
            field: 'taxName',
            headerName: 'Tax Name',
            type: 'string',
            width: 180,
            editable: true
        },
        {
            field: 'dateFrom',
            headerName: 'Date From',
            type: 'date',
            width: 180,
            editable: true,
            valueFormatter: ({ value }) => dateRangeFormatter([value], {
                pattern: UiDateTimeFormatter.Write
            })[0]
        },
        {
            field: 'dateTo',
            headerName: 'Date To',
            type: 'date',
            width: 180,
            editable: true,
            valueFormatter: ({ value }) => dateRangeFormatter([undefined, value], {
                pattern: UiDateTimeFormatter.Write
            })[1]
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'number',
            width: 100,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    // <GridActionsCellItem
                    //     icon={<EditIcon />}
                    //     label="Edit"
                    //     className="textPrimary"
                    //     onClick={handleEditClick(id)}
                    //     color="inherit"
                    // />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return {
        columns,
        rowModesModel,
        setRowModesModel,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        processRowUpdate,
        getRowId
    };
};
