import { range } from "util/support";

export class TickVisibilityController {
    private static visibilityStrategy = (reductionFactor: number) =>
        (tickIndex: number) => (tickIndex % reductionFactor) === 0;

    private static visibilityStrategyRepository = new Map<number, (tickIndex: number) => boolean>(
        range(30, 100, 10)
            .map(reductionFactor => [
                reductionFactor,
                this.visibilityStrategy(reductionFactor / 10)
            ])
    );

    public static boot(ticksCount: number) {
        return new this(ticksCount);
    }

    private constructor(
        private readonly ticksCount: number
    ) { }

    public checkVisibility(tickIndex: number): boolean {
        for (const [
            minTickCount,
            visibilityStrategy
        ] of Array.from(TickVisibilityController.visibilityStrategyRepository)) {
            if (this.ticksCount < minTickCount) {
                return true;
            }

            return visibilityStrategy(tickIndex);
        }

        return true;
    }
}
