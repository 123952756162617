import { memo, PropsWithChildren, ReactNode } from "react";
import { useGridApiContext } from "@mui/x-data-grid-premium";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";

import type { DetailContentProps } from "../Props";
import type { Transaction } from "features/transactions/types";

type Props = DetailContentProps & {
    readonly transactionActionsSlot: ReactNode;
};

const Legend = ({ row, transactionActionsSlot, children }: PropsWithChildren<Props>) => {
    const { current: gridApiContext } = useGridApiContext();

    const getHiddenColumns = () =>
        gridApiContext
            .getAllColumns()
            .filter(({ hide }) => hide);

    const renderRowDetail = (column: any) => {
        const renderer = getMethod(column, 'renderCell');
        const formater = getMethod(column, 'valueFormatter');

        return renderer({
            value: formater({
                value: row[column.field as keyof Transaction]
            })
        })
    };

    return (
        <Paper sx={{ flex: 1, ml: 2, width: 'max-content', p: 1 }}>
            <Box>
                {transactionActionsSlot}
            </Box>
            {getHiddenColumns()
                .map((column: any) => (
                    <Stack
                        key={column.field}
                        direction='row'
                        spacing={3}
                        py={1}
                        px={2}
                    >
                        <Box sx={{ fontWeight: 'bold' }}>
                            {column.headerName}:
                        </Box>
                        <Box sx={{ userSelect: 'all' }}>
                            {renderRowDetail(column)}
                        </Box>
                    </Stack>
                ))}
            {children}
        </Paper>
    );
};

function getMethod<T extends { value: string }>(
    column: any,
    methodName: 'renderCell' | 'valueFormatter'
) {
    return (methodName in column)
        ? column[methodName]
        : (({ value }: T) => value);
}

export default memo(Legend);
