import { WebRoutes } from "consts/enpoints/web";
import { EnabledMerchants } from "ui/organizms/Merchants";
import { useTicketingSystemStateSelectorsContext } from "../TicketingSystemProvider";
import TicketingSystemMasterConnections from "./TicketingSystemMasterConnections";
import TicketingSystemLegend from "./TicketingSystemLegend";

const TicketingSystemMerchantList = () => {
    const { selectTicketingSystemApiState } = useTicketingSystemStateSelectorsContext();

    return (
        <EnabledMerchants
            selectApiState={selectTicketingSystemApiState}
            title="Ticketing System"
            heading="Ticketing System"
            links={[{ name: 'Ticketing System' }]}
            Route={WebRoutes.Merchants.TicketingSystem}
            action={(
                <TicketingSystemMasterConnections />
            )}
            secondaryAction={TicketingSystemLegend}
        />
    );
};

export default TicketingSystemMerchantList;
