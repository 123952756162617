import { SelectChangeEvent } from "@mui/material";
import { BooleanSwitchValue, MOR as MorEnum } from "consts/general";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { MouseEvent, useState } from "react";

type ChangeValue = Pick<
    MerchantsMethodsPivot,
    | 'MOR'
    | 'enabled'
>;

type UsePaymentMethodSwitchArg = MerchantsMethodsPivot & {
    readonly change: ({ enabled, MOR }: ChangeValue) => Promise<unknown>;
};

export default function usePaymentMethodSwitch({ enabled, MOR, change }: UsePaymentMethodSwitchArg) {
    const [isLoading, setLoading] = useState(false);
    const getPaymentMethodState = (): ChangeValue => (
        Object.is(Number(enabled), BooleanSwitchValue.Off)
            ? {
                enabled: BooleanSwitchValue.Off,
                MOR: `${BooleanSwitchValue.Off}`
            }
            : {
                enabled: BooleanSwitchValue.On,
                MOR: Object.is(MOR, MorEnum.Default)
                    ? String(null)
                    : MOR
            }
    );
    const [paymentMethodState, setPaymentMethodState] = useState<ChangeValue>(getPaymentMethodState);
    const [resolvers, setResolvers] = useState<any>(null);

    const onChange = ({ target }: SelectChangeEvent<number>) => {
        const value = target.value;

        // TODo: remove any type in TypeScript 5 +
        const resolvers = (Promise as any).withResolvers();

        setResolvers(resolvers);
        setPaymentMethodState(
            Object.is(value, `${BooleanSwitchValue.Off}`)
                ? {
                    enabled: BooleanSwitchValue.Off,
                    MOR: `${BooleanSwitchValue.Off}`
                }
                : {
                    enabled: BooleanSwitchValue.On,
                    MOR: `${value}`
                }
        );

        resolvers.promise
            .then((state: ChangeValue) => {
                setLoading(true);
                return change(state);
            })
            .catch(() => setPaymentMethodState(getPaymentMethodState))
            .finally(() => {
                setLoading(false);
                setResolvers(null);
            });
    };

    const onCancel = () =>
        resolvers!.reject();

    const onConfirm = () =>
        resolvers!.resolve(paymentMethodState);

    const onClick = (event: MouseEvent) =>
        event.stopPropagation();

    return {
        isLoading,
        isOpen: Boolean(resolvers),
        paymentMethodState,
        onChange,
        onCancel,
        onConfirm,
        onClick
    };
};
