import { memo, Children } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import type { TodoProps } from "./types";

const Todo = ({
    TextFieldContainerProps,
    TextFieldProps,
    actionSlot,
    ActionProps,
    ItemContainerProps,
    children,
    ...props
}: TodoProps) => (
    <Box
        {...props}
    >
        <Box
            {...TextFieldContainerProps}
        >
            <TextField
                {...TextFieldProps}
                InputProps={{
                    endAdornment: actionSlot ?? (
                        <LoadingButton
                            {...ActionProps}
                        >
                            {ActionProps?.children ?? 'Add'}
                        </LoadingButton>
                    ),
                    ...TextFieldProps?.InputProps
                }}
            />
        </Box>
        {Boolean(Children.count(children)) && (
            <Box
                {...ItemContainerProps}
            >
                {children}
            </Box>
        )}
    </Box>
);

export default memo(Todo);
