import { useRef, useCallback } from "react";
import { ApiState } from "infrastructure/api";

export default function useApiState(apiState: ApiState) {
    const apiStateRef = useRef<ApiState>(apiState);
    apiStateRef.current = apiState;

    return useCallback(({ apiState, notIn }: {
        readonly apiState: ApiState | readonly ApiState[];
        readonly notIn?: boolean;
    }) => {
        const apiStateArray = [apiState].flat();

        return notIn
            ? !apiStateArray.includes(apiStateRef.current)
            : apiStateArray.includes(apiStateRef.current);
    }, []);
};
