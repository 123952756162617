import type { IDomainRepository } from "hooks/useDomainState";
import type useMerchantDetail from "./useMerchantDetail";

export const enum MerchantDetailTab {
    Profile = 'Profile',
    Users = 'Users',
    AccountSettings = 'AccountSettings',
    PaymentMethods = 'PaymentMethods',
    FeeAndCommissions = 'FeeAndCommissions',
    Domains = 'Domains'
};

export type MerchantDetailTabProps = {
    readonly repository: IDomainRepository;
};

export type MerchantDetailProps = ReturnType<typeof useMerchantDetail>;
