import { memo } from 'react';
import {
    ResourceDetail,
    type ResourceDetailProps
} from "ui/widgets/Resource";
import MerchantUser, { MerchantUserAction } from './MerchantUser';
import type { UserFormProps } from 'ui/forms/User';

const MerchantUserCreateEdit = ({ component, resourceName, ...restProps }: ResourceDetailProps) => (
    <ResourceDetail
        {...restProps}
        component={component}
        resourceName={resourceName}
    >
        <MerchantUser
            ActionComponent={MerchantUserAction}
            {...restProps as UserFormProps}
        />
    </ResourceDetail>
);

export default memo(MerchantUserCreateEdit);
