import { selectFileTaxName } from "features/general/selectors";
import { useTypedSelector } from "hooks";
import { memo } from "react";
import MerchantPayoutsTable, { type MerchantPayoutsTableProps } from "../MerchantPayoutsTable";

const FileTaxCalculations = (props: MerchantPayoutsTableProps) => {
    const taxName = useTypedSelector(selectFileTaxName);

    return (
        <MerchantPayoutsTable
            {...props}
            FiltersProps={{
                ...props.FiltersProps,
                filtersModelOverrides: {
                    ...props.FiltersProps?.filtersModelOverrides,
                    MerchantPayoutIncrementTaxName: taxName
                }
            }}
        />
    );
};

export default memo(FileTaxCalculations);
