import Button, { type ButtonProps } from "@mui/material/Button";
import { MerchantTabs } from "consts/merchants";
import { isAdmin } from "features/auth/helpers";
import useAuth from "features/auth/useAuth";
import { selectMerchantsActiveTab } from "features/merchants/selectors";
import { useTypedSelector } from "hooks";
import { ReactNode } from "react";
import { useResourceContext } from "ui/widgets/Resource";

const AddEntity = (props: ButtonProps) => {
    const { user } = useAuth();
    const { onOpen } = useResourceContext();

    const activeTab = useTypedSelector(selectMerchantsActiveTab);

    const roleName = user?.roleName;

    if (!isAdmin(roleName)) {
        return null;
    }

    const renderChildren = () => props.children ?? new Map<MerchantTabs, ReactNode>()
        .set(MerchantTabs.Merchants, 'New Merchant')
        .set(MerchantTabs.MerchantUsers, 'New Merchant User')
        .get(activeTab);

    return (
        <Button
            {...props}
            onClick={() => onOpen()}
        >
            {renderChildren()}
        </Button>
    );
};

export default AddEntity;
