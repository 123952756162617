import { createContext, memo, PropsWithChildren, useState } from "react";
import type { Dispatch, SetStateAction } from 'react';
import type { ModalProps } from "ui/atoms/Modal";
import Modal from "ui/atoms/Modal";

const contextDispatchNoop = () => { };

export const DialogVisibilityDispatchContext = createContext<Dispatch<SetStateAction<boolean>>>(contextDispatchNoop);

export const DialogSettingsDispatchContext = createContext<Dispatch<SetStateAction<ModalProps>>>(contextDispatchNoop);

const DialogProvider = ({ children }: PropsWithChildren) => {
    const [isOpen, setOpenState] = useState(false);
    const [modalProps, setModalProps] = useState({
        isOpen,
        onClose: () => { },
        DialogProps: {
            sx: {
                fontWeight: 700,
                '& .MuiDialog-container .MuiPaper-root': {
                    width: 'auto'
                }
            }
        }
    } as ModalProps);

    return (
        <DialogVisibilityDispatchContext.Provider
            value={setOpenState}
        >
            <DialogSettingsDispatchContext.Provider
                value={setModalProps}
            >
                {children}
                <Modal
                    {...modalProps}
                    isOpen={isOpen}
                />
            </DialogSettingsDispatchContext.Provider>
        </DialogVisibilityDispatchContext.Provider>
    );
};

export default memo(DialogProvider);
