import type { MerchantSettings } from "features/merchants/types";
import type { useForm } from "hooks";
import type { BootstrapableForm, Option } from "types";

export const enum FormField {
    AccountWithdrawalOption = 'withdrawal',
    PayoutCycle = 'payoutCycle',
    RollingReserveCap = 'rollingReserveCap'
};

export type MerchantPayoutSettings = Partial<
    Pick<
        MerchantSettings,
        | 'payoutCycle'
        | 'withdrawal'
        | 'rollingReserveCap'
    >
>;

export type MerchantPayoutSettingsForm =
    & ReturnType<typeof useForm>
    & BootstrapableForm<MerchantPayoutSettings, {
        readonly payoutCycle: Option[];
        readonly withdrawal: Option[];
    }>;
