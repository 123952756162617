import { ApiState } from "infrastructure/api";
import type { ResourceSlice } from "infrastructure/store";

export const resourceSliceFactory = <
    TResourceSlice,
    TMixins extends Record<PropertyKey, unknown> = {}
>(...mixins: Array<TMixins>): ResourceSlice<
    TResourceSlice,
    TMixins
> => ({
    ...mixins.reduce((acc, mixin) => ({ ...acc, ...mixin }), {} as TMixins),

    tableLoadingState: ApiState.Idle,
    exportLoadingState: ApiState.Idle,
    tableSlice: null,

    searchParams: ''
});
