import { ApiState } from "infrastructure/api";
import type { BootstrapEntity, WithBootstrapData, WithEntityFactory } from "../types";

export const withBootstrapData = <TBootEntity = unknown>(bootstrapData: WithBootstrapData<
    Record<PropertyKey, BootstrapEntity<TBootEntity>>
>['bootstrapData']): WithBootstrapData<Record<PropertyKey, BootstrapEntity<TBootEntity>>> => ({
    bootstrapData
});

export const withEntityFactory = (): WithEntityFactory => ({
    entityLoadingState: ApiState.Idle
});
