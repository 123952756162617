import { memo } from "react";
import NumericRange, { type NumericRangeProps } from "ui/atoms/NumericRange";
import type { AbstractActionableFilterProps } from "ui/widgets/Filters/types";

export interface NumericRangeFilterProps extends Omit<
    NumericRangeProps,
    | 'onChange'
    | 'id'
    | 'name'
>, AbstractActionableFilterProps { }

const NumericRangeFilter = ({ onChange, ...props }: NumericRangeFilterProps) => {
    const handleChange: NumericRangeProps['onChange'] = ({ value }) =>
        onChange({
            type: props.id,
            payload: value
        });

    return (
        <NumericRange
            {...props}
            onChange={handleChange}
        />
    );
};

export default memo(NumericRangeFilter);

/*export class NumericRangeValueObject extends FilterValueObject<Array<number | string>> {
    public toArray() {
        return this.value;
    }

    public serialize(value: number, index: number): string {
        return String(
            !index
                ? value || 0
                : value);
    }

    public isEmpty(): boolean {
        return false;
    }
}

export const NumericRangeValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new NumericRangeValueObject(
            filterValueStrategy[FilterVariant.NumericRange][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](values: NumericRangeValue, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new NumericRangeValueObject(
            filterValueStrategy[FilterVariant.NumericRange][changeValueStrategyName]!.handleChangeValue(values)
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.NumericRange][defaultValueStrategyName]!.getDefaultValue();
    }
};*/
