import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";

export const selectIsManualBulkKYCsImportHistoryUninitialized = (state: RootState) =>
    state.kyc.tableLoadingState === ApiState.Idle;

export const selectIsManualBulkKYCsImportHistoryLoading = (state: RootState) =>
    state.kyc.tableLoadingState === ApiState.Pending;

export const selectManualBulkKYCsImportHistorySlice = (state: RootState) =>
    state.kyc.tableSlice;

export const selectIsExportManualBulkKYCsImportHistoryLoading = (state: RootState) =>
    state.kyc.exportLoadingState === ApiState.Pending;
