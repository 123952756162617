import { memo } from 'react';
import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import type { OptionsProps } from '../types';
import ToggleBlacklistAction from './ToggleBlacklistAction';
import RemoveDuplicateAction from './RemoveDuplicateAction';
import useOptionActions from './useOptionActions';

const Options = (props: OptionsProps) => {
    const {
        removeDuplicate,
        toggleBlacklist
    } = useOptionActions(props);

    const { duplicatedEmailRegistryEntry } = props;

    const actionProps: ButtonProps = {
        sx: {
            whiteSpace: 'nowrap',
            width: 'max-content',
            color: '#454F5B',
            border: '1px solid #CBD3DA'
        },
        size: 'small',
        variant: 'outlined'
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                columnGap: 2
            }}
        >
            <ToggleBlacklistAction
                {...actionProps}
                toggleBlacklist={toggleBlacklist}
                duplicatedEmailRegistryEntry={duplicatedEmailRegistryEntry}
            />
            <RemoveDuplicateAction
                {...actionProps}
                removeDuplicate={removeDuplicate}
                duplicatedEmailRegistryEntry={duplicatedEmailRegistryEntry}
            />
        </Box>
    );
};

export default memo(Options);
