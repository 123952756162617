import { BooleanSwitchValue } from 'consts/general';
import { Filters } from 'consts/merchants';
import { PER_PAGE_SIZE } from 'consts/transactions';
import { getMerchants } from 'features/merchants/api';
import { selectActiveMerchants } from 'features/merchants/selectors';
import { changeMerchantsLoadingState, changeMerchantsSlice } from 'features/merchants/slice';
import { useTypedSelector } from 'hooks';
import useFetchFeatureResource from 'hooks/useFetchFeatureResource';
import { useEffect, useRef, useState } from 'react';
import { RequestQueryMapper } from 'util/request-query-mapper';

export default function useFetchMerchantList() {
    const isRequestsProcessingRef = useRef<boolean | undefined>();
    const [isLoading, setLoading] = useState(false);

    const getAllMerchantsRef = useRef(
        useFetchFeatureResource({
            resourceRequest: (queryString?: string) => getMerchants(
                RequestQueryMapper
                    .from(queryString)
                    .containsIn(Filters.isActive, `${BooleanSwitchValue.On}`)
                    .toString()
            ),
            changeLoadingState: changeMerchantsLoadingState,
            changeSlice: changeMerchantsSlice,
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE,
            condition: () => typeof isRequestsProcessingRef.current === "boolean"
        })
    );

    const enabledMerchants = useTypedSelector(selectActiveMerchants);

    const getActiveMerchantsSlice = () => enabledMerchants;

    useEffect(() => {
        if (!isRequestsProcessingRef.current) {
            let isLoading = isRequestsProcessingRef.current = true;
            setLoading(isLoading);

            getAllMerchantsRef.current()
                .finally(() => {
                    isLoading = isRequestsProcessingRef.current = false;
                    setLoading(isLoading);
                });
        }
    }, []);

    return {
        getActiveMerchantsSlice,
        isLoading
    };
};
