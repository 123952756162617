import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormField, type MerchantCreateForm } from "./types";
import { getFormInputProps } from "util/forms";
import StatefulSelector from "ui/widgets/StatefulSelector";
import {
    selectCountry,
    selectCurrencies
} from "features/general/selectors";
import CountrySelector from "ui/widgets/CountrySelector";
import type { Country } from "features/general/types";
import type { Option } from "types";
import { getOptionName, getOptions, isOptionEqualToValue } from "util/option";
import { toIds } from "util/mappers";

const Create = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    bootstrapData
}: MerchantCreateForm) => {
    const country = selectCountry({
        coreId: values[FormField.Country]
    })({
        general: {
            countries: bootstrapData.countries
        }
    });

    const selectMerchantGoupOptions = (): Option[] => bootstrapData.merchantGroupsOptions ?? [];

    return (
        <Box
            component="form"
            autoComplete="off"
            noValidate
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 3
            }}
        >
            <TextField
                {...getFormInputProps(FormField.Company, { errors, touched })}
                size="small"
                name={FormField.Company}
                value={values[FormField.Company]}
                label='Company'
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.Address, { errors, touched })}
                size="small"
                name={FormField.Address}
                value={values[FormField.Address]}
                label='Address'
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.VatNumber, { errors, touched })}
                size="small"
                name={FormField.VatNumber}
                value={values[FormField.VatNumber]}
                label='VAT Number'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.City, { errors, touched })}
                size="small"
                name={FormField.City}
                value={values[FormField.City]}
                label='City'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.Phone, { errors, touched })}
                size="small"
                type='tel'
                name={FormField.Phone}
                value={values[FormField.Phone]}
                label='Phone'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.State, { errors, touched })}
                size="small"
                name={FormField.State}
                value={values[FormField.State]}
                label='State'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.Website, { errors, touched })}
                size="small"
                type='url'
                name={FormField.Website}
                value={values[FormField.Website]}
                label='Website'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <TextField
                {...getFormInputProps(FormField.ZipCode, { errors, touched })}
                size="small"
                name={FormField.ZipCode}
                value={values[FormField.ZipCode]}
                label='Zip Code'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
            <StatefulSelector
                multiple
                size="small"
                selectOptions={selectMerchantGoupOptions}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={getOptionName}
                value={getOptions(
                    selectMerchantGoupOptions(),
                    toIds(values[FormField.Groups])
                )}
                TextFieldProps={{
                    label: 'Groups'
                }}
                onChange={(_, groups) => setFieldValue(
                    FormField.Groups,
                    toIds(groups),
                    true
                )}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <CountrySelector
                value={country}
                size="small"
                TextFieldProps={{
                    label: 'Country'
                }}
                onChange={(_, value) => {
                    const country: Country = value;

                    setFieldValue(
                        FormField.Country,
                        country.coreId,
                        true
                    );
                }}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <StatefulSelector
                size="small"
                selectOptions={selectCurrencies}
                value={values[FormField.Currency] ?? null}
                TextFieldProps={{
                    label: 'Currency',
                    required: false
                }}
                onChange={(_, currency) => setFieldValue(
                    FormField.Currency,
                    currency,
                    true
                )}
                FormControlProps={{
                    sx: {
                        width: '100%'
                    }
                }}
            />
            <TextField
                {...getFormInputProps(FormField.DefaultLanguage, { errors, touched })}
                size="small"
                name={FormField.DefaultLanguage}
                value={values[FormField.DefaultLanguage]}
                label='Default Language'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
            />
        </Box>
    );
};

export default memo(Create);
