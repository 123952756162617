import { memo, type PropsWithChildren } from "react";
import type { Option } from "types";
import type { FilterType } from "ui/widgets/Filters";
import type { FiltersPublicApi } from "ui/widgets/Table";
import { ToolbarFiltersProvider } from "./ToolbarFiltersProvider";
import { componentKey as activeInactiveSelectorKey } from "./ActiveInactiveSelector";

type Props = {
    readonly toolbarFilters: Map<string, {
        readonly filterId: FilterType<string>;
        readonly options: ReadonlyArray<Option>;
    }>;
};

const ToolbarFilters = ({
    children,
    toolbarFilters
}: PropsWithChildren<Props>) => (
    <ToolbarFiltersProvider
        toolbarFiltersPropsSelectorRegistry={new Map([
            [activeInactiveSelectorKey, (filtersPublicApi: FiltersPublicApi<string> | null) => {
                if (!filtersPublicApi) {
                    return;
                }

                const { filters, onChange, onApply } = filtersPublicApi;

                const handleChange: typeof onChange = changePayload => {
                    onChange(changePayload);
                    onApply();
                };
                
                const toolbarFilter = toolbarFilters.get(activeInactiveSelectorKey);

                const props = filters
                    .find(filter => Object.is(
                        filter.props.id,
                        toolbarFilter?.filterId
                    ))
                    ?.props;

                return props && {
                    ...props,
                    onChange: handleChange,
                    label: '',
                    options: toolbarFilter?.options
                };
            }]
        ])}
    >
        {children}
    </ToolbarFiltersProvider>
);

export default memo(ToolbarFilters);
