import React, { memo, useCallback } from 'react';
import {
    selectIsTransactionsLoading,
    selectIsTransactionsUninitialized,
    selectTransactionSlice
} from 'features/transactions/selectors';
import Table from "ui/widgets/Table";
import {  TableGridMode } from 'consts/table';
import useActions from 'features/transactions/useActions';
import type { GridRowIdGetter } from '@mui/x-data-grid';
import type { Transaction } from 'features/transactions/types';
import useRequestQueryOverrideDecorator from './useRequestQueryOverrideDecorator';
import {date, FilterVariant} from "../../../../consts/filters";
import {multi, single, text} from "../../../../consts/transactions";
import TransactionsToolbar from "../../Transactions/TransactionsToolbar";

const Transactions = () => {
    const {
        getTransactions,
        downloadCsv,
    } = useActions();

    const getRowId: GridRowIdGetter<Transaction> = useCallback(({ transactionId }: Transaction) =>
        transactionId, []);

    return (
        <Table
            mode={TableGridMode.Transaction}
            selectIsTableUninitialized={selectIsTransactionsUninitialized}
            selectIsTableLoading={selectIsTransactionsLoading}
            selectTableDataSlice={selectTransactionSlice}
            fetchTableData={getTransactions}
            downloadCsv={downloadCsv}
            getRowId={getRowId}
            filterTypes={new Map<FilterVariant, string[]>([
                [FilterVariant.Text, text],
                [FilterVariant.DateRange, date],
                [FilterVariant.MultiOption, multi],
                [FilterVariant.SingleOption, single]
            ])}
            Toolbar={TransactionsToolbar}
            requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
            // getSummableColumns={getSummableColumns}
        />
    );
};

export default memo(Transactions);
