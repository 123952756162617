import { ApiState } from "infrastructure/api";
import { TransactionsState } from "./types";

const state: TransactionsState = {
    transactionsLoadingState: ApiState.Idle,
    transactionDetailsLoadingState: ApiState.Idle,
    exportLoadingState: ApiState.Idle,
    transactionHistoryLoadingState: ApiState.Idle,
    notificationsInLoadingState: ApiState.Idle,
    notificationsOutLoadingState: ApiState.Idle,
    transactionSlice: null
};

export const sliceToken = 'transactions';

export default state;
