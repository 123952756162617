import { memo } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import useMergeFilesAction from "./useMergeFilesAction";

const MergeFilesAction = () => {
    const {
        isMerchantPayoutEntityLoading,
        isMultiFileMergeEnabled,
        isDisabled,
        onOpen,
        toggleEnableMultiFileMerge
    } = useMergeFilesAction();

    return (
        <Stack direction="row">
            <Checkbox
                inputProps={{
                    "aria-label": "Merge Files",
                }}
                onChange={toggleEnableMultiFileMerge}
                disabled={isMerchantPayoutEntityLoading}
                checked={isMultiFileMergeEnabled}
            />
            <Button
                variant="contained"
                onClick={onOpen}
                disabled={isDisabled()}
            >
                Merge
            </Button>
        </Stack>
    );
};

export default memo(MergeFilesAction);
