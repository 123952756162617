import { useEffect, useRef } from 'react';
import type { Option } from "types";
import { FormField, type UserFormWithRelations } from "./types";
import { isEqual } from "util/support";

export default function useUserPivots({
    merchants,
    domains
}: Pick<
    UserFormWithRelations,
    | 'merchants'
    | 'domains'
>,
    setFieldValue: (
        field: string,
        value: Array<Option>,
        shouldValidate?: boolean | undefined
    ) => any
) {
    const prevPivotValuesRef = useRef({} as Pick<
        UserFormWithRelations,
        | 'merchants'
        | 'domains'
    >);

    useEffect(() => {
        const values = {
            merchants,
            domains
        };
        if (isEqual(prevPivotValuesRef.current, values)) {
            return;
        }

        setFieldValue(FormField.Merchants, merchants, true);
        setFieldValue(FormField.Domains, domains, true);

        prevPivotValuesRef.current = values;
    }, [
        merchants,
        domains,
        setFieldValue
    ]);
};
