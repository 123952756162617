import { memo } from "react";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import type { MerchantBusiness } from "features/merchants/types";
import { FormField, type CreateMerchantBusinessForm } from "./types";
import AddBusinessUnit from "./AddBusinessUnit";
import { useCreateMerchantBusinessForm } from "./useForm";

type MerchantBusinessType = Pick<MerchantBusiness, 'businessName'>;

const AddMerchantBusiness = (merchantBusinessForm: CreateMerchantBusinessForm) => {
    const form = useCreateMerchantBusinessForm(merchantBusinessForm);

    return (
        <AddBusinessUnit
            <MerchantBusinessType>
            form={form}
            TextFieldProps={{
                name: FormField.BusinessName,
                label: 'Business Name',
                placeholder: 'Enter business name'
            }}
            ButtonProps={{
                loading: form.isSubmitting,
                disabled: !form.isValid,
                variant: 'contained',
                children: 'Add Business',
                startIcon: (
                    <AddBusinessIcon />
                ),
                sx: {
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content'
                }
            }}
            DialogActionProps={{
                children: 'Add Business'
            }}
            renderDialogTitle={({ values }) => (
                `Are you sure you want to add “${values[FormField.BusinessName]}”?`
            )}
        />
    );
};

export default memo(AddMerchantBusiness);
