import { memo } from 'react';
import MuiTableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DuplicateStatus from './Status';
import type { TableBodyProps } from './types';
import Options from './Options';

const TableBody = (props: TableBodyProps) => {
    const { tabRegistry: [manageDuplicatesDataRegistry] } = props;

    return (
        <MuiTableBody>
            {manageDuplicatesDataRegistry.map((duplicatedEmailRegistryEntry, index) => (
                <TableRow
                    key={duplicatedEmailRegistryEntry.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell>{duplicatedEmailRegistryEntry.email}</TableCell>
                    <TableCell>
                        <DuplicateStatus
                            isBlacklisted={duplicatedEmailRegistryEntry.isBlacklisted}
                        />
                    </TableCell>
                    <TableCell>
                        <Options
                           {...props}
                            duplicatedEmailRegistryEntry={duplicatedEmailRegistryEntry}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </MuiTableBody>
    );
};

export default memo(TableBody);
