import type { Option } from "types";
import { FilterValueProvider, TValueStrategy, ValueStrategy } from "./types";

class SingleAutocompleteFilterValueProvider extends FilterValueProvider<Option | null> {
    static getDefaultValue() {
        return null;
    }

    public getInitialValue(values: Array<string>) {
        let value: Option | null = this.getDefaultValue();

        if (values.length > 0) {
            const [v] = values;

            value = {
                id: v,
                name: v
            };
        }

        return value;
    }

    public handleChangeValue(value: Option | null) {
        return value;
    }
}

const singleAutocompleteFilterValueStrategy: TValueStrategy<Option | null> = {
    [ValueStrategy.Default]:
        new SingleAutocompleteFilterValueProvider(
            SingleAutocompleteFilterValueProvider.getDefaultValue
        )
};

export default singleAutocompleteFilterValueStrategy;
