import { useState, useCallback } from 'react';

export default function useModal(isOpen = false) {
    const [open, setOpenState] = useState(isOpen);

    const onOpen = useCallback(() => {
        setOpenState(true);
    }, []);

    const onClose = useCallback(() => {
        setOpenState(false);
    }, []);

    return {
        open,
        onOpen,
        onClose
    };
};
