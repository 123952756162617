import { memo, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { Files } from "features/merchantPayouts/types";
import type { ID } from "types";
import { DialogVisibilityDispatchContext } from "./DialogProvider";
import { useTypedSelector } from "hooks";
import { selectIsMerchantPayoutEntityLoading } from "features/merchantPayouts/selectors";
import { useFiltersPublicApiContext } from "ui/organizms/MerchantPayouts/FiltersPublicApiProvider";

type Props = {
    readonly file: Files;
    readonly requestHandler?: (coreId: ID) => [Promise<Response>];
};

const Delete = ({
    file,
    requestHandler = (coreId: ID) => [Promise.resolve(new Response())]
}: Props) => {
    const filtersPublicApi = useFiltersPublicApiContext();

    const isRequestLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);
    const dialogVisibilityDispatch = useContext(DialogVisibilityDispatchContext);

    const handleClose = () => {
        dialogVisibilityDispatch(false);
    };

    // const { isLoading, handleProcess } = useProcess(() =>
    //     requestHandler(file.coreId)
    //         .finally(handleClose)
    // );
    const onDelete = async () => {
        const [request] = requestHandler(file.coreId);

        await request;

        // This is a workaround to refresh the table
        filtersPublicApi?.onApply();

        handleClose();
    };

    return (
        <Box
            sx={{
                maxWidth: '300px',
                width: '100%'
            }}>
            <Box>Are you sure you want to delete this file?</Box>
            <Box sx={{
                mt: '10px',
                display: 'flex',
                placeContent: 'flex-end'
            }}>
                <Button
                    onClick={handleClose}
                >
                    Close
                </Button>
                <LoadingButton
                    color="error"
                    onClick={onDelete}
                    loading={isRequestLoading}
                >
                    Delete
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default memo(Delete);
