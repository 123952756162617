import { memo } from "react";
import TextField from "@mui/material/TextField";
import { FormField, type MerchantAccountMerchantSetupForm } from "./types";
import AddEmail from "./AddEmail";
import { getFormInputProps } from "util/forms";
import AccountMerchantSetupLayout from "./AccountMerchantSetupLayout";

const ComplianceSetup = (props: MerchantAccountMerchantSetupForm) => {
    const {
        values,
        handleChange,
        handleBlur
    } = props;

    return (
        <AccountMerchantSetupLayout
            title='Email for compliance'
        >
            <TextField
                {...getFormInputProps(FormField.TotalAmountEuroPerDay, props)}
                fullWidth
                type='number'
                name={FormField.TotalAmountEuroPerDay}
                value={values[FormField.TotalAmountEuroPerDay]}
                label='Total amount eur / day (EDD)'
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                {...getFormInputProps(FormField.TotalAmountEuroPerWeek, props)}
                fullWidth
                type='number'
                name={FormField.TotalAmountEuroPerWeek}
                value={values[FormField.TotalAmountEuroPerWeek]}
                label='Total amount / week'
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                {...getFormInputProps(FormField.TotalNumberOfTransactionsPerWeek, props)}
                fullWidth
                type='number'
                name={FormField.TotalNumberOfTransactionsPerWeek}
                value={values[FormField.TotalNumberOfTransactionsPerWeek]}
                label='Number of transactions / week'
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                {...getFormInputProps(FormField.MinNumberEmailsSelectedForKYCCheck, props)}
                fullWidth
                type='number'
                name={FormField.MinNumberEmailsSelectedForKYCCheck}
                value={values[FormField.MinNumberEmailsSelectedForKYCCheck]}
                label='Min. no. of emails selected to be sent for KYC check'
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <AddEmail
                {...props}
                name={FormField.EmailCsvForCompliance}
                itemsName={FormField.EmailsCsvForCompliance}
            />
        </AccountMerchantSetupLayout>
    );
};

export default memo(ComplianceSetup);
