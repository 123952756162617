import {
    memo,
    type MouseEventHandler,
    type PropsWithChildren,
    type ReactNode
} from "react";
import type { FormikValues } from "formik";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import useForm from "hooks/useForm";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import { TriggerableDialog } from "ui/molecules/Dialog";

type Props<T extends FormikValues> = {
    readonly form: ReturnType<typeof useForm<T>>;
    readonly renderDialogTitle: () => ReactNode;
    readonly onEdit?: MouseEventHandler;
    readonly DialogActionProps?: LoadingButtonProps;
};

const EditBusinessEntity = <T extends FormikValues>({
    children,
    form,
    renderDialogTitle,
    onEdit,
    DialogActionProps
}: PropsWithChildren<Props<T>>) => {
    const {
        isSubmitting,
        resetForm,
        submitForm
    } = form;

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <IconButton
                    color='warning'
                    onClick={event => {
                        onEdit?.(event);
                        onClick?.(event);
                    }}
                    disabled={isSubmitting}
                >
                    <EditIcon />
                </IconButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                        onClick={() => {
                            onClose();
                            resetForm();
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='warning'
                        onClick={() =>
                            submitForm()
                                .then(() => {
                                    onClose();
                                    resetForm({
                                        values: form.values
                                    });
                                })}
                        loading={isSubmitting}
                        {...DialogActionProps}
                    >
                        Edit
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                onClose: () => resetForm(),
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: '100%',
                            maxWidth: 'sm',
                            '& .MuiDialogContent-root': {
                                border: 'none'
                            },
                            '& .MuiDialogActions-root': {
                                pt: 0
                            }
                        }
                    }
                },
                titleSlot: renderDialogTitle()
            }}
        >
            {children}
        </TriggerableDialog>
    );
};

export default memo(EditBusinessEntity);
