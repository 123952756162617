import { ApiRoutes } from "consts/enpoints/api";
import { UserCredentials } from "features/auth/types";
import { getUser as getUserApi } from "features/users/api";
import { fetchApi } from "infrastructure/api";
import AuthException from "./exceptions";
import { getUserId } from "./helpers";

export const getUser = () => {
  const userId = getUserId();

  if (!userId) {
    return Promise.reject(
      new AuthException(
        `User ID is not provided`,
        {}
      )
    );
  }

  return getUserApi(userId);
};

export const login = (body: UserCredentials) =>
  fetchApi(ApiRoutes.PostLogin, {
    method: "post",
    body,
  });

export const logout = () =>
  fetchApi(ApiRoutes.PostLogout, {
    method: 'post'
  });
