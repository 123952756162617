import type { PayloadAction } from "@reduxjs/toolkit";
import { revokeAuthData, setAuthData } from "features/auth/helpers";
import type { UserResponse } from "features/users/types";
import type { WritableDraft } from "immer/dist/internal";
import type { ThunkReturnType } from "types";
import type { AuthResponse, AuthState } from "./types";
import { AuthStatus } from "./types";

export const userRequestLoading = (state: WritableDraft<AuthState>) => {
    state.status = AuthStatus.Pending;
};

export const userRequestFulfilled = (
    state: WritableDraft<AuthState>,
    action: PayloadAction<ThunkReturnType<UserResponse>>
) => {
    state.status = AuthStatus.Authenticated;
    state.user = action.payload!.data;
};

export const userRequestRejected = (state: WritableDraft<AuthState>) => {
    state.status = AuthStatus.Guest;
    state.user = null;
};

export const loginRequestFulfiled = (
    _: WritableDraft<AuthState>,
    action: PayloadAction<ThunkReturnType<AuthResponse>>
) => {
    setAuthData({
        ...action.payload!.data,
        timestamp: Date.now()
    });
};

export const logoutRequestFulfiled = (state: WritableDraft<AuthState>) => {
    revokeAuthData();
    userRequestRejected(state);
};
