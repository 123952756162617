import { memo } from "react";
import Resource from "ui/widgets/Resource";
import useLedgerResource from "./useLedgerResource";
import { TableGridMode } from "consts/table";
import {
    selectIsMerchantLedgersUninitialized,
    selectIsMerchantLedgersLoading,
    selectMerchantLedgersSlice
} from "features/merchants/selectors";
import LedgerResourceToolbar from "./LedgerResourceToolbar";
import Ledger from "./Ledger";
import { useSearchParamsNoop, useUserPreferencesNoop } from "ui/widgets/Table";

const LedgerResource = () => (
    <Resource
        name='Ledger'
        useResource={useLedgerResource}
        mode={TableGridMode.MerchantLedger}
        selectIsTableUninitialized={selectIsMerchantLedgersUninitialized}
        selectIsTableLoading={selectIsMerchantLedgersLoading}
        selectTableDataSlice={selectMerchantLedgersSlice}
        LayoutProps={{
            sx: {
                m: 0
            }
        }}
        Toolbar={LedgerResourceToolbar}
        Detail={Ledger}
        adapters={{
            useSearchParams: useSearchParamsNoop,
            useUserPreferences: useUserPreferencesNoop
        }}
    />
);

export default memo(LedgerResource);
