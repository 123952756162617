export const Filters = {
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    parentVisibility: 'parentVisibility',
    mins: 'mins',
    maxs: 'maxs'
};

export const enum FilterVariant {
    Text = 'textual',
    DateRange = 'dateRange',
    MultiOption = 'multiOption',
    SingleOption = 'singleOption',
    NumericRange = 'numericRange'
}

export const enum FilterValueModifier {
    Contains = 'contains',
    IsNull = 'isNull',
    IsNotNull = 'isNotNull'
};

export const date = [
    Filters.createdAt,
    Filters.updatedAt
];

export const MULTI_COLUMN_SEPARATOR = '|';

export const enum Via {
    Direct = 'd',
    Gateway = 'g'
};

export const enum ParentVisibility {
    Children,
    Parents
};
