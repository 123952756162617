import type { GridRowParams } from "@mui/x-data-grid";
import { PluginSettings } from "./Table/Plugins/PluginSettings";

export const enum TablePlugin {
    CopyToClipboard = 'copyToClipboard'
};

export type DetailContentProps = Pick<GridRowParams, 'row'>;

export type PaginationArgs = {
    readonly page?: number | string;
    readonly perPage?: number | string;
};

export type EnabledTablePlugins = Record<TablePlugin.CopyToClipboard, PluginSettings>;
