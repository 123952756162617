import type { PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useAppDispatch } from "./store";

const useResetState = (resetState: () => PayloadAction<undefined>) => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(resetState());
    }, [dispatch, resetState]);
};

export default useResetState;
