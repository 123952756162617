import { memo } from 'react';
import Service, { ServiceProps } from './Service';

const FaceVerification = (props: Omit<ServiceProps, 'serviceLabel'>) => (
    <Service
        {...props}
        serviceLabel='Face verification'
    />
);

export default memo(FaceVerification);
