import { FilterValueModifier, FilterVariant } from "consts/filters";
import { splitWithModifier } from "util/filters";
import { exclusiveMultiColumnQueryMapper, exclusiveSingleColumnQueryMapper } from "./exclusiveQueryMapper";
import { isExclusiveFilterVariant, isExclusiveMulticolumn } from "./predicates";
import type { QueryMapperArgs } from "./types";

export default function filtrableQueryMapper(filterVariant: FilterVariant) {
    if (isExclusiveFilterVariant(filterVariant)) {
        return exclusiveFilterStrategy;
    }

    return equalFilterStrategy;
}

function exclusiveFilterStrategy({
    filterName,
    filterValue,
    queryMapper,
    index = 0
}: QueryMapperArgs) {
    const exclusiveQueryMapper = isExclusiveMulticolumn(filterName)
        ? exclusiveMultiColumnQueryMapper
        : exclusiveSingleColumnQueryMapper;

    return exclusiveQueryMapper({
        filterName,
        filterValue,
        queryMapper,
        index
    });
}

function equalFilterStrategy({
    filterName,
    filterValue,
    queryMapper
}: QueryMapperArgs) {

    const [value, modifier] = splitWithModifier(filterValue);

    if (modifier === FilterValueModifier.Contains) {
        queryMapper.contains(filterName, value);
        return;
    }

    queryMapper.containsIn(filterName, filterValue);
}
