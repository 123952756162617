import { memo, ReactNode } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { TabIndex, TabProps } from "ui/organizms/Consumers/ConsumersDialogs/MultiTabDialog/types";
import { formatDate } from "util/formaters";

const UserActivity = ({
    tabRegistry: [
        dialogDataRegistry
    ],
    dialogRequestProcessing: [
        isLoading
    ]
}: TabProps<TabIndex.MoreDetails>) => {
    const getActivities = () => {
        const {
            activityDetails: {
                createdAt,
                whitelistedAt,
                blacklistedAt,
                importedFrom,
                lastActiveAt
            }
        } = dialogDataRegistry;

        const formatter = (dateTime: Parameters<typeof formatDate>[0]) =>
            () => formatDate(dateTime, {
                pattern: 'DD/MM/YYYY HH:mm'
            });
            
        const reasonOfBlsckListing = (importedFrom?: string): () => string => {
            return () => importedFrom ? importedFrom : ' - ';
            };

        return Array.from(
            new Map<ReactNode, () => string>()
                .set('Created at', formatter(createdAt))
                .set('Blacklisted', formatter(blacklistedAt))
                .set('Whitelisted', formatter(whitelistedAt))
                .set('Last Active', formatter(lastActiveAt))
                .set('Reason of blacklisting', reasonOfBlsckListing(importedFrom!))
                .entries()
        );
    };

    return (
        <Box>
            {getActivities().map(([label, getTimestamp]) => (
                <Box
                    key={String(label)}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'min-content 1fr',
                        mb: 2
                    }}
                >
                    <Box
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {label}: &nbsp;
                    </Box>
                    <Box
                        sx={{
                            fontWeight: 600
                        }}
                    >
                        {isLoading
                            ? <Skeleton />
                            : getTimestamp()}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default memo(UserActivity);
