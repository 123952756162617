import { memo } from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
    selectIsExportMerchantUsersLoading,
    selectIsExportMerchantsLoading
} from "features/merchants/selectors";
import { TableToolbar } from "ui/widgets/Table";
import AddEntity from "../../shared/AddEntity";
import type { MerchantUserToolbarProps } from "./types";

const Add = () => (
    <AddEntity
        size='small'
        startIcon={<AccountBoxIcon />}
    >
        Add Merchant User
    </AddEntity>
);

const MerchantUserToolbar = ({ onExport, ...props }: MerchantUserToolbarProps) => (
    <TableToolbar
        {...props}
        onExport={onExport}
        selectIsExportLoading={state => (
            selectIsExportMerchantsLoading(state) ||
            selectIsExportMerchantUsersLoading(state)
        )}
        add={Add}
    />
);

export default memo(MerchantUserToolbar);
