import { createContext, useMemo, type PropsWithChildren } from "react";
import { FilterVariant } from "consts/filters";
import type {
    FeatureFiltersContext as FeatureFiltersContextType,
    FeatureFiltersProviderProps
} from './types';
import useFeatureFilters from "./useFeatureFilters";

export const FeatureFiltersContext = createContext<
FeatureFiltersContextType
>({
    withFilters: _ => _,
} as FeatureFiltersContextType);

const FeatureFiltersProvider = ({
    children,
    filtersConfig
}: PropsWithChildren<FeatureFiltersProviderProps>) => (
    <FeatureFiltersContext.Provider
        value={useFeatureFilters({
            filtersConfig,
            filterTypes: useMemo(() => (
                filtersConfig.reduce((filterTypes, { filterId, filterVariant }) => (
                    filterTypes.set(filterVariant, [
                        ...filterTypes.get(filterVariant) ?? [],
                        filterId
                    ])
                ), new Map<FilterVariant, string[]>())
            ), [filtersConfig])
        })}
    >
        {children}
    </FeatureFiltersContext.Provider>
);

export default FeatureFiltersProvider;
