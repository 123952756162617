import { useRef } from 'react';
import type { GridEventListener } from "@mui/x-data-grid";
import useActions from "features/users/useActions";
import { User } from 'features/users/types';
import { useResourceContext } from 'ui/widgets/Resource';

export default function useUsers() {
    const {
        getUsers: getResource,
        getUserById,
        downloadCsv,
        getRoles,
        getUsersMerchants,
        getUsersMors
    } = useActions();

    const { onOpen } = useResourceContext();

    const userRef = useRef<Partial<User>>({});

    const onRowClick: GridEventListener<'rowClick'> = ({ row }) => {
        userRef.current = {
            ...row
        };

        onOpen(row);
    };

    return {
        downloadCsv,
        getResource,
        getUserById,
        onRowClick,
        getRoles,
        getUsersMerchants,
        getUsersMors,
        resource: userRef.current
    };
}


// import { useTypedSelector } from 'hooks';
// import { selectIsUserProfileLoading } from 'features/users/selectors';
// import useUsersActions from 'features/users/useActions';

// export default function useUserProfile() {
//     const { postSaveOrUpdateUser } = useUsersActions();
//     const isLoading = useTypedSelector(selectIsUserProfileLoading);

//     return {
//         postSaveOrUpdateUser,
//         isLoading
//     };
// };
