import { memo } from "react";
import { FormField, type EditMerchantDomainEntityFormProps } from "./types";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import type { MerchantBusiness } from "features/merchants/types";

const EditMerchantBusiness = ({
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    ...merchantBusinessForm
}: EditMerchantDomainEntityFormProps<
    Partial<MerchantBusiness>
>) => (
    <Box
        component='form'
        autoComplete='off'
        onSubmit={handleSubmit}
        sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1
        }}
    >
        <TextField
            {...getFormInputProps(FormField.BusinessName, merchantBusinessForm)}
            required
            fullWidth
            size='small'
            variant='outlined'
            name={FormField.BusinessName}
            value={values[FormField.BusinessName]}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    </Box>
);

export default memo(EditMerchantBusiness);
