import { memo } from "react";
import LabeledSwitch, { type LabeledSwitchProps } from "ui/atoms/LabeledSwitch";
import {
    ConfirmatableDialog,
    useUndoableDialog,
    type ConfirmatableDialogProps
} from "../Dialog";

type Props = {
    readonly undoableDialog: Parameters<typeof useUndoableDialog>[0];
    readonly ConfirmatableDialogProps: Omit<
        ConfirmatableDialogProps,
        | 'open'
        | 'onConfirm'
        | 'onCancel'
    >;
    readonly LabeledSwitchProps?: LabeledSwitchProps;
};

const Switchable = ({
    undoableDialog,
    ConfirmatableDialogProps,
    LabeledSwitchProps
}: Props) => {
    const {
        open,
        onProceed,
        onCancel,
        getChecked,
        getLabel,
        onChange,
        getDisabled
    } = useUndoableDialog(undoableDialog);

    return (
        <>
            <LabeledSwitch
                {...LabeledSwitchProps}
                RootProps={{
                    onClick: event => event.stopPropagation()
                }}
                checked={getChecked()}
                label={getLabel()}
                disabled={getDisabled?.()}
                onChange={onChange}
            />
            <ConfirmatableDialog
                {...ConfirmatableDialogProps}
                open={open}
                onConfirm={onProceed}
                onCancel={onCancel}
            />
        </>
    );
};

export default memo(Switchable);
