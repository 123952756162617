import ActionPanel from "ui/molecules/ActionPanel";
import useKYCActionPanel from "./useKYCActionPanel";

const KYCActionPanel = () => {
    const {
        isLoading,
        isDisabled,
        onSave
    } = useKYCActionPanel();

    return (
        <ActionPanel
            withProcessingAlert
            isLoading={isLoading}
            onSave={onSave}
            SaveActionProps={{
                disabled: isDisabled,
                children: 'Save Changes'
            }}
            cancelActionSlot={<></>}
        />
    );
};

export default KYCActionPanel;
