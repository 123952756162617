import { GridRowParams } from '@mui/x-data-grid-premium';
import { Filters } from 'consts/merchantPayouts';
import { memo } from 'react';

const DeleteIncrementTaxessDialogBody = ({ row }: GridRowParams) => (
    <>
        "{[
            row[Filters.merchantName],
            row[Filters.taxName],
            row[Filters.tag],
            row[Filters.refColFrom],
            row[Filters.refColFromMinSum],
            row[Filters.currentIncMultiplierForEachRefColumn],
        ].join('-')}"
    </>
);

export default memo(DeleteIncrementTaxessDialogBody);