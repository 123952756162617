import { memo, type ComponentType } from "react";
import UserForm from 'ui/forms/User';
import { useResourceContext } from "ui/widgets/Resource";
import useAuth from "features/auth/useAuth";
import type { UseUserArg, User } from "features/users/types";
import useUser from "features/users/useUser";
import type { UserFormProps } from "ui/forms/User";
import Form, { type FormProps } from "ui/layouts/Form";

type Props =
    & Partial<
        Omit<
            UserFormProps,
            | 'bootstrapData'
        >
    >
    & Pick<
        UseUserArg,
        | 'onUserUpdateOrCreate'
    >
    & {
        readonly bootstrapData?: Partial<UserFormProps['bootstrapData']>;
        readonly layout?: ComponentType<FormProps>;
    };

const Profile = ({
    onUserUpdateOrCreate,
    layout: ProfileLayout = Form,
    ...props
}: Props) => {
    const {
        getIsNew,
        onClose: close,
        resource = {}
    } = useResourceContext<Partial<User>>();

    const { user } = useAuth();

    const {
        isCreateOrUpdaterequestLoading: isLoading,
        bootstrapData,
        ...restProps
    } = useUser({ user: resource, onUserUpdateOrCreate });

    const onResolve: UserFormProps['onResolve'] = (...args) => {
        try {
            props.onResolve?.(...args);
            close();
        } catch {}
    };

    return (
        <ProfileLayout>
            <UserForm
                {...restProps}
                isLoading={isLoading}
                user={user}
                actionLabel={getIsNew()
                    ? 'Save and create user'
                    : 'Update user'}
                {...props}
                initialValues={{
                    ...restProps.initialValues,
                    ...props.initialValues
                }}
                bootstrapData={{
                    ...bootstrapData,
                    ...props.bootstrapData
                }}
                onResolve={onResolve}
            />
        </ProfileLayout>
    );
};

export default memo(Profile);
