import { useEffect, useRef, useState } from "react";
import type { UseUserArg, User, UserResponse } from "./types";
import useMerchantActions from "features/merchants/useMerchantActions";
import useActions from "./useActions";
import { useTypedSelector } from "hooks";
import {
    selectIsRolesLoading,
    selectIsUsersMerchantsLoading,
    selectIsUsersMorsLoading,
    selectMerchantsUserBelongs,
    selectMorsUserBelongs,
    selectRoleOptions
} from "./selectors";
import type { ID } from "types";
import { selectDomainOptions, selectIsBootstrapDataLoading } from "features/general/selectors";
import { selectMerchantOptions } from "features/merchants/selectors";
import useUserProfile from "./useUserProfile";
import { getOptions } from "util/option";
import { FormAdapter } from "hooks/useForm";

export default function useUser({ user, onUserUpdateOrCreate }: UseUserArg) {
    const [isCreateOrUpdaterequestLoading, setUpdateOrCreateRequestState] = useState(false);

    const merchantActions = useMerchantActions();
    const merchantActionsRef = useRef(merchantActions);
    merchantActionsRef.current = merchantActions;

    const userActions = useActions();
    const userActionsRef = useRef(userActions);
    userActionsRef.current = userActions;

    const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);
    const isRolesLoading = useTypedSelector(selectIsRolesLoading);
    const isUsersMerchantsLoading = useTypedSelector(selectIsUsersMerchantsLoading);
    const isUsersMorsLoading = useTypedSelector(selectIsUsersMorsLoading);

    const merchantIds: Array<ID> = useTypedSelector(selectMerchantsUserBelongs(user.coreId));
    const mors: Array<string> = useTypedSelector(selectMorsUserBelongs(user.coreId));

    const bootstrapData = {
        domains: useTypedSelector(selectDomainOptions),
        merchants: useTypedSelector(selectMerchantOptions),
        roles: useTypedSelector(selectRoleOptions)
    };

    const {
        createOrUpdateUser,
        updateUserMerchantAssosiation,
        updateUserMorAssociation,
    } = useUserProfile(user);

    const onSaveOrCreate = async ({
        role,
        merchants = [],
        domains = [],
        ...userRequest
    }: Partial<User>) => {
        setUpdateOrCreateRequestState(true);

        try {
            const response = await createOrUpdateUser(userRequest);

            if (!response) {
                return;
            }

            const { data: user } = response;

            await Promise.allSettled([
                updateUserMerchantAssosiation({
                    user,
                    associations: merchants
                }),
                updateUserMorAssociation({
                    user,
                    associations: domains
                })
            ]);

            const [request] = userActionsRef.current.getUserById(user.coreId);
            onUserUpdateOrCreate?.(await request as UserResponse);

            return FormAdapter.fromResponse(response);

        } finally {
            setUpdateOrCreateRequestState(false);
        }
    };

    const coreId = user.coreId;

    useEffect(() => {
        merchantActionsRef.current.getAllMerchants();

        const {
            getRoles,
            getUsersMerchants,
            getUsersMors
        } = userActionsRef.current;
        getRoles();

        if (coreId) {
            getUsersMerchants(coreId);
            getUsersMors(coreId);
        }
    }, [coreId]);

    const initialValues = {
        ...user,
        merchants: getOptions(bootstrapData.merchants, merchantIds),
        domains: getOptions(bootstrapData.domains, mors)
    };

    return {
        initialValues,
        bootstrapData,
        isBootstrapDataLoading: (
            isRolesLoading ||
            isBootstrapDataLoading ||
            isUsersMerchantsLoading ||
            isUsersMorsLoading
        ),
        isCreateOrUpdaterequestLoading,
        isRolesLoading,
        isUsersMerchantsLoading,
        isUsersMorsLoading,
        merchantIds,
        mors,
        onSaveOrCreate
    };
};
