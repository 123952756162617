import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AVATAR_MAX_SIZE, AVATAR_SUPPORTED_FORMATS } from "consts/validation";
import Card from "ui/atoms/Card";
import { UploadAvatar } from "lib/upload";
import { getFormInputProps } from "util/forms";
import { fNumber } from "util/formaters";
import { FormField, type MerchantProfileInfoForm } from "./types";
import CountrySelector from "ui/widgets/CountrySelector";
import type { Country } from "features/general/types";
import { selectCountry } from "features/general/selectors";
import { FileManager } from "util/file";

const ProfileInfo = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    bootstrapData
}: MerchantProfileInfoForm) => {
    const country = selectCountry({
        coreId: values[FormField.Country]
    })({
        general: {
            countries: bootstrapData.countries
        }
    });

    return (
        <Box
            component="form"
            autoComplete="off"
            noValidate
            sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gap: 3,
                width: '100%',
                maxWidth: 1024
            }}
        >
            <Card
                sx={{
                    borderWidth: 1,
                    borderColor: 'divider',
                    borderStyle: 'solid',
                    p: 2
                }}
            >
                <UploadAvatar
                    // file={values[FormField.Avatar]}
                    accept={FileManager
                        .fromMimeTypes(AVATAR_SUPPORTED_FORMATS)
                        .accept}
                    multiple={false}
                    helperText={(
                        <Typography
                            variant='caption'
                            color='text.secondary'
                            sx={{
                                display: 'inline-block',
                                textAlign: 'center',
                                mt: 3
                            }}
                        >
                            Allowed file types: {
                                FileManager
                                    .fromMimeTypes(AVATAR_SUPPORTED_FORMATS)
                                    .getExtensions('*.')
                                    .join(', ')}
                            <br />
                            Max file size of {fNumber(AVATAR_MAX_SIZE, '0.0 b')}
                        </Typography>
                    )}
                />
            </Card>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2
                }}
            >
                <TextField
                    {...getFormInputProps(FormField.Company, { errors, touched })}
                    size='small'
                    name={FormField.Company}
                    value={values[FormField.Company]}
                    label='Company'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.Address, { errors, touched })}
                    size='small'
                    name={FormField.Address}
                    value={values[FormField.Address]}
                    label='Address'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                />
                <FormControlLabel
                    sx={{
                        whiteSpace: 'nowrap'
                    }}
                    control={(
                        <Checkbox
                            inputProps={{
                                'aria-label': 'Show primary contact full name',
                            }}
                            name={FormField.ShowPrimaryContactFullName}
                            onChange={(_, value) => {
                                setFieldTouched(FormField.ShowPrimaryContactFullName, true);
                                setFieldValue(
                                    FormField.ShowPrimaryContactFullName,
                                    Number(value),
                                    true
                                );
                            }}
                            onBlur={handleBlur}
                            checked={Boolean(values[FormField.ShowPrimaryContactFullName])}
                        />
                    )}
                    label='Show primary contact full name'
                />
                <TextField
                    {...getFormInputProps(FormField.City, { errors, touched })}
                    size='small'
                    name={FormField.City}
                    value={values[FormField.City]}
                    label='City'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.VatNumber, { errors, touched })}
                    size='small'
                    name={FormField.VatNumber}
                    value={values[FormField.VatNumber]}
                    label='VAT Number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.State, { errors, touched })}
                    size='small'
                    name={FormField.State}
                    value={values[FormField.State]}
                    label='State'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.Phone, { errors, touched })}
                    size='small'
                    type='tel'
                    name={FormField.Phone}
                    value={values[FormField.Phone]}
                    label='Phone'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.ZipCode, { errors, touched })}
                    size='small'
                    name={FormField.ZipCode}
                    value={values[FormField.ZipCode]}
                    label='Zip Code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <TextField
                    {...getFormInputProps(FormField.DefaultLanguage, { errors, touched })}
                    size='small'
                    name={FormField.DefaultLanguage}
                    value={values[FormField.DefaultLanguage]}
                    label='Default Language'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <CountrySelector
                    value={country}
                    size='small'
                    TextFieldProps={{
                        label: 'Country'
                    }}
                    onChange={(_, value) => {
                        const country: Country = value;

                        setFieldTouched(FormField.Country, true);
                        setFieldValue(
                            FormField.Country,
                            country?.coreId,
                            true
                        );
                    }}
                    FormControlProps={{
                        sx: {
                            width: '100%'
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default memo(ProfileInfo);
