import {
    createContext,
    memo,
    type PropsWithChildren
} from "react";
import useMerchantAccountSettings from "./useMerchantAccountSettings";
import type { MerchantAccountSettingsContext as MerchantAccountSettingsContextType } from "./types";

export const MerchantAccountSettingsContext = createContext({} as MerchantAccountSettingsContextType);

const MerchantAccountSettingsProvider = ({ children }: PropsWithChildren) => (
    <MerchantAccountSettingsContext.Provider
        value={useMerchantAccountSettings()}
    >
        {children}
    </MerchantAccountSettingsContext.Provider>
);

export default memo(MerchantAccountSettingsProvider);
