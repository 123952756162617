import { MerchantPayoutsTabs, merchantPayoutTabValueMapping } from "consts/merchantPayouts";
import { getSearchParameterFromUrl } from "util/support";
import type { MerchantPayoutsState } from "./types";
import { resourceSliceFactory } from "infrastructure/store/state";

const merchantPayoutFilableMixin = {
    isMultiFileMergeEnabled: false,
    selectionModel: []
};

const state: MerchantPayoutsState = {
    activeTab: merchantPayoutTabValueMapping.get(getSearchParameterFromUrl({
        name: 'activeTab',
        fallbackValue: '1'
    })) ?? MerchantPayoutsTabs.SummaryFiles,
    [MerchantPayoutsTabs.SummaryFiles]: resourceSliceFactory({
        ...merchantPayoutFilableMixin
    }),
    [MerchantPayoutsTabs.Files]: resourceSliceFactory({
        ...merchantPayoutFilableMixin
    }),
    [MerchantPayoutsTabs.Calculations]: resourceSliceFactory(),
    [MerchantPayoutsTabs.FileTaxCalculations]: resourceSliceFactory(),
    [MerchantPayoutsTabs.IncrementsPivot]: resourceSliceFactory(),
    [MerchantPayoutsTabs.IncrementsTaxCalculations]: resourceSliceFactory(),
    [MerchantPayoutsTabs.Increments]: resourceSliceFactory(),
    [MerchantPayoutsTabs.IncrementExchangeRates]: resourceSliceFactory()
};

export const sliceToken = 'merchantPayouts';

export default state;
