import { TableGridMode } from "consts/table";

import transactions from 'config/filters/transactions.json';
import transactionList from 'config/filters/transactionList.json';
import merchants from 'config/filters/merchants.json';
import users from 'config/filters/users.json';
import merchantPayoutSummaryFiles from 'config/filters/merchantPayoutSummaryFiles.json';
import merchantPayoutFiles from 'config/filters/merchantPayoutFiles.json';
import merchantPayoutCalculations from 'config/filters/merchantPayoutCalculations.json';
import merchantPayoutFileTaxCalculations from 'config/filters/merchantPayoutFileTaxCalculations.json';
import transactionsMerchantPayoutIncrementsPivot from 'config/filters/transactionsMerchantPayoutIncrementsPivot.json';
import merchantPayoutIncrementTaxes from 'config/filters/merchantPayoutIncrementTaxes.json';
import merchantPayoutIncrements from 'config/filters/merchantPayoutIncrements.json';
import merchantPayoutIncrementExchangeRates from 'config/filters/merchantPayoutIncrementExchangeRates.json';
import consumers from 'config/filters/consumers.json';
import manualBulkKYCsImportHistory from 'config/filters/manualBulkKYCsImportHistory.json';
import merchantUsers from 'config/filters/merchantUsers.json';
import ledgers from 'config/filters/ledgers.json';

import { typed, type Config } from "ui/widgets/Filters/hooks/useFiltersConfigParser";
import InvalidTableGridModeException from "../exceptions/InvalidTableGridModeException";

const configRegistry = new Map<TableGridMode, Config[]>([
    [TableGridMode.Transaction, typed(transactions)],
    [TableGridMode.Merchant, typed(merchants)],
    [TableGridMode.User, typed(users)],
    [TableGridMode.MerchantPayoutsSummaryFiles, typed(merchantPayoutSummaryFiles)],
    [TableGridMode.MerchantPayoutsFiles, typed(merchantPayoutFiles)],
    [TableGridMode.MerchantPayoutCalculations, typed(merchantPayoutCalculations)],
    [TableGridMode.MerchantPayoutFileTaxCalculations, typed(merchantPayoutFileTaxCalculations)],
    [TableGridMode.TransactionsMerchantPayoutIncrementsPivot, typed(transactionsMerchantPayoutIncrementsPivot)],
    [TableGridMode.MerchantPayoutIncrementTaxes, typed(merchantPayoutIncrementTaxes)],
    [TableGridMode.MerchantPayoutIncrements, typed(merchantPayoutIncrements)],
    [TableGridMode.MerchantPayoutIncrementExchangeRates, typed(merchantPayoutIncrementExchangeRates)],
    [TableGridMode.Consumer, typed(consumers)],
    [TableGridMode.ManualBulkKYCsImportHistory, typed(manualBulkKYCsImportHistory)],
    [TableGridMode.TransactionList, typed(transactionList)],
    [TableGridMode.MerchantUser, typed(merchantUsers)],
    [TableGridMode.MerchantLedger, typed(ledgers)]
]);

export default function useTableFiltersConfig(mode: TableGridMode) {
    if (!configRegistry.has(mode)) {
        throw new InvalidTableGridModeException(
            `Unknown table grid mode`,
            {}
        );
    }

    return configRegistry.get(mode)!;
};
