import { useSearchParams as useSearchParamsRouterDom } from 'react-router-dom';

export function useSearchParamsNoop(): ReturnType<typeof useSearchParamsRouterDom> {
    return [
       new URLSearchParams(),
        () => {}
    ];
};

export function useSearchParams(): ReturnType<typeof useSearchParamsRouterDom> {
    return useSearchParamsRouterDom();
};
