import {
    useForm as useFormikForm,
    type UseFormArg
} from 'hooks';
import { FormField, type MerchantCreate } from "./types";
import type { Merchant } from "features/merchants/types";
import useValidationSchema from "./useValidationSchema";
import { getInitialValues as getMerchantInitialValues } from "../Merchant";

export const getInitialValues = (initialValues: Partial<Merchant>) => ({
    [FormField.Groups]: [],
    [FormField.Website]: '',
    [FormField.Currency]: '',
    ...getMerchantInitialValues(initialValues),
});

export const useForm = (merchantCreateForm: UseFormArg<MerchantCreate>) => {
    const {
        initialValues,
        ...restFormArgs
    } = merchantCreateForm;

    return useFormikForm({
        ...restFormArgs,
        validationSchema: useValidationSchema(),
        validateOnMount: true,
        initialValues: getInitialValues(initialValues)
    });
};
