import { memo, PropsWithChildren, ReactNode } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MuiTable from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

type Props = Pick<BoxProps, 'sx'> & {
    readonly isLoading: boolean;
    readonly isEmpty: boolean;
    readonly emptyMessageSlot?: ReactNode;
};

const Table = ({
    isLoading,
    isEmpty,
    children,
    sx: sxProps,
    emptyMessageSlot = 'No data found'
}: PropsWithChildren<Props>) => {
    const sx = {
        display: 'grid',
        placeItems: 'center',
        maxHeight: 'calc(100vh - 300px)',
        ...sxProps
    };

    if (isLoading) {
        return (
            <Box
                sx={sx}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (isEmpty) {
        return (
            <Box
                sx={{
                   ...sx,
                    fontWeight: 'bold'
                }}
            >
                {emptyMessageSlot}
            </Box>
        );
    }

    return (
        <TableContainer
            sx={sx}
            component={Paper}
        >
            <MuiTable
                stickyHeader
                aria-label="simple table"
            >
                {children}
            </MuiTable>
        </TableContainer>
    );
};

export default memo(Table);
