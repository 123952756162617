import { memo } from 'react';
import Box from '@mui/material/Box';
import type { KYCTypeProps } from './types';
import { KYCProcessingStatus, KYCServiceType } from 'consts/kyc';
import { triggerConsumerKYCSettings } from 'features/consumers/api';
import { KYCService } from 'ui/molecules/KYCService';
import ActionPanel from '../DialogActionPanel';

const TriggerKYC = ({
    isProcessing,
    isTriggerKYCServicesLoading,
    servicesState,
    handleProcessKYCService,
    onKYCServiceChange,
    getKYCServicesCollection
}: KYCTypeProps) => {
    const getKYCServiceStatus = (kycServiceType: KYCServiceType) =>
        servicesState[kycServiceType].disabled
            ? KYCProcessingStatus.Success
            : KYCProcessingStatus.Pending;

    return (
        <Box>
            {getKYCServicesCollection({ getStatus: getKYCServiceStatus })
                .map(({ type, status, name }) => (
                    <KYCService
                        key={type}
                        type={type}
                        isServiceLoading={isTriggerKYCServicesLoading}
                        status={status}
                        value={servicesState[type]}
                        onChange={onKYCServiceChange}
                    >
                        {name}
                    </KYCService>
                ))}
            <ActionPanel
                loading={isProcessing || isTriggerKYCServicesLoading}
                onClick={handleProcessKYCService(
                    triggerConsumerKYCSettings
                )}
            >
                Trigger KYC
            </ActionPanel>
        </Box>
    );
};

export default memo(TriggerKYC);
