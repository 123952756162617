import { memo } from "react";
import Table from "ui/widgets/Table";
import {
    selectManualBulkKYCsImportHistorySlice,
    selectIsManualBulkKYCsImportHistoryLoading,
    selectIsManualBulkKYCsImportHistoryUninitialized
} from "features/kyc/selectors";
import ImportHistoryTableToolbar from "./ImportHistoryTableToolbar";
import { TableGridMode } from "consts/table";
import useImportHistoryTable from "./useImportHistoryTable";

const ImportHistoryTable = () => {
    const {
        filterTypes,
        columns,
        getInitialState,
        getManualBulkKYCsImportHistoryRecords,
        downloadCsv
    } = useImportHistoryTable();

    return (
        <Table
            mode={TableGridMode.ManualBulkKYCsImportHistory}
            filterTypes={filterTypes}
            fetchTableData={getManualBulkKYCsImportHistoryRecords}
            initialState={getInitialState()}
            columns={columns}
            downloadCsv={downloadCsv}
            selectIsTableUninitialized={selectIsManualBulkKYCsImportHistoryUninitialized}
            selectIsTableLoading={selectIsManualBulkKYCsImportHistoryLoading}
            selectTableDataSlice={selectManualBulkKYCsImportHistorySlice}
            Toolbar={ImportHistoryTableToolbar}
            pinnedColumnsSx={{
                right: {
                    width: 50
                }
            }}
        />
    );
};

export default memo(ImportHistoryTable);

