import { memo } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormField, type MerchantAccountMerchantSetupForm } from "./types";
import AddEmail from "./AddEmail";
import AccountMerchantSetupLayout from "./AccountMerchantSetupLayout";

const IpnSetup = (props: MerchantAccountMerchantSetupForm) => {
    const {
        values,
        handleBlur,
        setFieldValue,
        setFieldTouched
    } = props;

    return (
        <AccountMerchantSetupLayout
            title='Emails for IPN when already succeeded status changed'
            sx={{
                gap: 4
            }}
        >
            <FormControlLabel
                sx={{
                    whiteSpace: 'nowrap'
                }}
                control={(
                    <Checkbox
                        inputProps={{
                            'aria-label': 'Enable email IPN notification on status change'
                        }}
                        name={FormField.EnableEmailIpnNotificationOnStatusChange}
                        onChange={(_, value) => {
                            setFieldTouched(FormField.EnableEmailIpnNotificationOnStatusChange, true);
                            setFieldValue(
                                FormField.EnableEmailIpnNotificationOnStatusChange,
                                Number(value),
                                true
                            );
                        }}
                        onBlur={handleBlur}
                        checked={Boolean(values[FormField.EnableEmailIpnNotificationOnStatusChange])}
                    />
                )}
                label='Enable email IPN notification on status change'
            />
            <AddEmail
                {...props}
                name={FormField.EmailCsvForIpnNotificationOnStatusChange}
                itemsName={FormField.EmailsCsvForIpnNotificationOnStatusChange}
            />
        </AccountMerchantSetupLayout>
    );
};

export default memo(IpnSetup);
