import { memo } from "react";
import Box from "@mui/material/Box";
import Tabs from "ui/atoms/Tabs";
import { MerchantPaymentMethodPSPDetailTab } from "./types";
import useMerchantPaymentMethodPSPDetail from "./useMerchantPaymentMethodPSPDetail";
import { TabLabel } from "ui/widgets/StatefulTabs";
import MethodLimits from "./MethodLimits";
import History from "./History";
import Info from "./Info";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const MerchantPaymentMethodPSPDetail = (merchantMethod: MerchantsMethodsPivot) => {
    const {
        activeTab,
        tabManager,
        message,
        selectState,
        onChange,
        selectMethodLimits
    } = useMerchantPaymentMethodPSPDetail(merchantMethod);

    const tabLabelProps = {
        message,
        tabManager,
        selectState
    };

    return (
        <Box>
            <Tabs
                model={[
                    {
                        key: MerchantPaymentMethodPSPDetailTab.MethodLimits,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantPaymentMethodPSPDetailTab.MethodLimits}
                            >
                                Method Limits
                            </TabLabel>
                        ),
                        component: (
                            <MethodLimits
                                {...selectMethodLimits()}
                            />
                        )
                    },
                    {
                        key: MerchantPaymentMethodPSPDetailTab.History,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantPaymentMethodPSPDetailTab.History}
                            >
                                History
                            </TabLabel>
                        ),
                        component: (
                            <History
                                {...merchantMethod}
                            />
                        )
                    },
                    {
                        key: MerchantPaymentMethodPSPDetailTab.Info,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantPaymentMethodPSPDetailTab.Info}
                            >
                                Info
                            </TabLabel>
                        ),
                        component: (
                            <Info
                                {...merchantMethod}
                            />
                        )
                    }
                ]}
                TabListContainerProps={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
                TabListProps={{
                    'aria-label': 'merchant payment method psp detail',
                    variant: 'scrollable',
                    scrollButtons: 'auto'
                }}
                onChange={onChange}
            />
        </Box>
    );
};

export default memo(MerchantPaymentMethodPSPDetail);
