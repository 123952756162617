import { memo } from "react";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import Accordion, { AccordionProps } from "ui/atoms/Accordion";
import MerchantPaymentMethodPSPSummary from "./MerchantPaymentMethodPSPSummary";
import MerchantPaymentMethodPSPDetail from "./MerchantPaymentMethodPSPDetail";

type Props = Pick<
    AccordionProps,
    | 'expanded'
    | 'onChange'
> & {
    readonly merchantMethod: MerchantsMethodsPivot;
};

const MerchantPaymentMethodPSP = ({ merchantMethod, ...accordionProps }: Props) => (
    <Accordion
        {...accordionProps}
        AccordionSummaryProps={{
            children: (
                <MerchantPaymentMethodPSPSummary
                    {...merchantMethod}
                />
            ),
            'aria-controls': `${merchantMethod.paymentMethodPSP} content`,
            id: `${merchantMethod.paymentMethodPSP} summary`
        }}
    >
        <MerchantPaymentMethodPSPDetail
            {...merchantMethod}
        />
    </Accordion>
);

export default memo(MerchantPaymentMethodPSP);
