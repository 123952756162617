import type { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { v4 as uuidv4 } from 'uuid';
// import moment from "moment";
import { useTypedSelector } from 'hooks';
import { selectIsTransactionHistoryLoading } from 'features/transactions/selectors';

export type TransactionHistoryRecord = Record<string, TransactionHistoryRecordValue>;
export type TransactionHistoryCollection = Array<TransactionHistoryRecord>;

type TransactionHistoryRecordValue = string | number | null;
type TransactionHistoryDiffEntity<TPrev, TCurr> = {
    readonly previous: TPrev;
    readonly next: TCurr;
};

type TransactionHistoryRecordEntity = {
    readonly id: string;
    readonly action: string;
    readonly historyCreatedAt: string;
    readonly field: keyof TransactionHistoryRecord;
    readonly previous: TransactionHistoryRecordValue;
    readonly next: TransactionHistoryRecordValue;
};

export default function useHistory() {
    const isTransactionDetailsLoading = useTypedSelector(selectIsTransactionHistoryLoading);

    // const sortByAsc = (collection: TransactionHistoryCollection, field = 'historyCreatedAt') =>
    //     collection.sort((historyRecordA: TransactionHistoryRecord, historyRecordB: TransactionHistoryRecord) =>
    //         moment(historyRecordA[field]).valueOf() - moment(historyRecordB[field]).valueOf()
    //     );

    const getRecordKeys = (
        historyRecord: TransactionHistoryRecord,
        excludeKeys: Array<string> = []) =>
        Object.keys(historyRecord)
            .filter(key => !excludeKeys.includes(key));

    const getRowGroupingModel = () => ['action'];

    const getDiffRecord = (
        historyRecordA: TransactionHistoryRecord,
        historyRecordB: TransactionHistoryRecord,
        extractRecordKeysFn: () => Array<string>
    ) => extractRecordKeysFn().reduce((aggregatedHistoryRecordMap: Map<
        string, TransactionHistoryDiffEntity<
            TransactionHistoryRecordValue,
            TransactionHistoryRecordValue
        >>, historyRecordKey) => {
        const isPropertyExists = (historyRecordKey in historyRecordA) &&
            (historyRecordKey in historyRecordB);

        const isEmpty = (String(historyRecordA[historyRecordKey]) === '') ||
            (String(historyRecordB[historyRecordKey]) === '');

        if (isPropertyExists && !isEmpty && !Object.is(
            String(historyRecordA[historyRecordKey]),
            String(historyRecordB[historyRecordKey])
        )) {
            aggregatedHistoryRecordMap.set(historyRecordKey, {
                previous: historyRecordA[historyRecordKey],
                next: historyRecordB[historyRecordKey]
            });
        }

        return aggregatedHistoryRecordMap;
    }, new Map<string, TransactionHistoryDiffEntity<
        TransactionHistoryRecordValue,
        TransactionHistoryRecordValue
    >>());

    const mapReduce = (
        collection: TransactionHistoryCollection,
        getDiffFn: (
            previousRecord: TransactionHistoryRecord,
            currentRecord: TransactionHistoryRecord
        ) => Map<
            string,
            TransactionHistoryDiffEntity<
                TransactionHistoryRecordValue,
                TransactionHistoryRecordValue
            >
        >,
        pivotKey = 'action') =>
        collection.reduce((groupRowProp, historyRecord, index, transactionHistoryRecords) => {
            if (!index || !historyRecord) {
                return groupRowProp;
            }

            const groupedKey = String(historyRecord[pivotKey] ?? 'other');
            const previousRecord = transactionHistoryRecords[index - 1];
            const currentRecord = transactionHistoryRecords[index];

            // const diffRecordMap = getDiffRecord(
            //     previousRecord,
            //     currentRecord,
            //     () => getRecordKeys(currentRecord, [pivotKey, 'historyCreatedAt'])
            // );

            getDiffFn(previousRecord, currentRecord)
                .forEach((value, key) => {
                    groupRowProp.push({
                        id: uuidv4(),
                        action: groupedKey,
                        historyCreatedAt: String(historyRecord.historyCreatedAt ?? historyRecord.createdAt),
                        field: key,
                        ...value
                    });
                });

            return groupRowProp;
        }, [] as Array<TransactionHistoryRecordEntity>);

    const getRows = (collection: TransactionHistoryCollection, transaction: TransactionHistoryRecord) => {
        // const sortedData = sortByAsc(collection);
        const pivotColumn = getRowGroupingModel()[0];
        const excludeKeys = [pivotColumn, 'historyCreatedAt', 'transactionRequest', 'transactionDecision'];
        // TODO: clarify if we need exclude 'transactionRequest', 'transactionDecision'
        // const sampleRecord = sortedData[0];

        return mapReduce(
            // Say 'thank you' for Marius
            [...collection.reverse(), transaction],
            (previousRecord: TransactionHistoryRecord, currentRecord: TransactionHistoryRecord) =>
                getDiffRecord(
                    previousRecord,
                    currentRecord,
                    () => getRecordKeys(previousRecord, excludeKeys)
                ),
            pivotColumn
        );
    };

    const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = row => row.path;

    return {
        getRows,
        getTreeDataPath,
        getRowGroupingModel,
        isTransactionDetailsLoading
    };
};
