import { memo } from 'react';
import Box from '@mui/material/Box';
import QuickSwitch from './QuickSwitch';
import KYCProvidersSelector from './KYCProvidersSelector';

const ServicesControlPanel = () => (
    <Box
        sx={{
            mt: '25px',
            px: '40px',
            display: 'flex',
            justifyContent: 'space-between'
        }}
    >
        <QuickSwitch />
        <KYCProvidersSelector />
    </Box>
);

export default memo(ServicesControlPanel);
