import type { NotificationsResponse, Transaction } from "features/transactions/types";
import useTransactionSubject from "./useTransactionSubject";
import { urlSearchparamTransformer } from "util/transformers";
import { PER_PAGE_SIZE } from "consts/transactions";

export default function useNotifications<T>(requestThunk: (params: string) => any, {
    transactionId,
    transactionGuid
}: Pick<Transaction, 'transactionId' | 'transactionGuid'>) {

    const fetchData = async () => {
        const [request] = requestThunk(urlSearchparamTransformer({
            transactionId,
            transactionGuid,
            perPage: PER_PAGE_SIZE
        }));

        const response = await request;

        return (response as NotificationsResponse<T>).data;
    };

    return useTransactionSubject({
        transactionId,
        fetchData
    });
};
