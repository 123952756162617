import { type PropsWithChildren, memo, ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import KYCService, { type KYCServiceProps } from '../KYCService';
import FileArea, { type FileAreaProps } from './FileArea';

export type FilableKYCServiceProps = KYCServiceProps & {
    readonly FileAreaProps?: FileAreaProps;
    readonly detailsSlot?: ReactNode;
};

const FilableKYCService = ({
    detailsSlot,
    FileAreaProps = {},
    ...props
}: PropsWithChildren<FilableKYCServiceProps>) => {
    const { type, value } = props;

    return (
        <Accordion
            expanded={value.checked}
            sx={{
                '&.MuiAccordion-root.MuiPaper-root': {
                    maxWidth: 300,
                    '&.Mui-expanded:first-of-type': {
                        mt: '20px'
                    }
                },
                borderRadius: '8px',
                borderWidth: 1,
                borderColor: 'var(--sub-grey)',
                borderStyle: 'solid',
                mt: '20px'
            }}
        >
            <AccordionSummary
                aria-controls={`kyc-service-type-${type}-content`}
                id={`kyc-service-type-${type}-header`}
            >
                <KYCService
                    {...props}
                    sx={{
                        border: 'none',
                        width: '100%'
                    }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <FileArea
                    {...FileAreaProps}
                />
                {detailsSlot}
            </AccordionDetails>
        </Accordion>
    );
};

export default memo(FilableKYCService);
