import type { Consumer } from "features/consumers/types";
import type { ManualBulkKYCsImportHistory } from "features/kyc/types";
import type { DomainAction, ID } from "types";
import type useConsumer from "./useConsumer";

export const enum ConsumerActionType {
    MarkAsKYCed,
    TriggerKYC,
    ManageDuplicates,
    MoreDetails,
    BulkKYC,
    BulkKYCHistoryResults
};

export type ConsumerState =
    | DomainAction<
        ConsumerActionType.MarkAsKYCed,
        Consumer
    >
    | DomainAction<
        ConsumerActionType.TriggerKYC,
        ID[]
    >
    | DomainAction<
        ConsumerActionType.BulkKYC,
        null
    >
    | DomainAction<
        ConsumerActionType.ManageDuplicates,
        Consumer
    >
    | DomainAction<
        ConsumerActionType.MoreDetails,
        Consumer
    >
    | DomainAction<
        ConsumerActionType.BulkKYCHistoryResults,
        ManualBulkKYCsImportHistory
    >;

export type ConsumerContext = ReturnType<typeof useConsumer>;
