import useAuth from "features/auth/useAuth";
import { WebRoutes } from "consts/enpoints/web";
import { FC, memo, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RedirectIfAuthenticated: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isPending } = useAuth();

  if (isPending()) {
    return null;
  }

  const from = (location.state as any)?.from?.pathname || WebRoutes.Root;

  if (isAuthenticated()) {
    return <Navigate to={from} state={{ from: undefined }} replace />;
  }

  return <>{children}</>;
};

export default memo(RedirectIfAuthenticated);
