import { memo } from "react";
import SummaryCard, { type SummaryCardProps } from "ui/molecules/SummaryCard";
import { fNumber } from "util/formaters";

type Props = SummaryCardProps;

const Cassette = ({ amountSlot, ...props }: Props) => (
    <SummaryCard
        {...props}
        sx={{
            width: 'auto',
            whiteSpace: 'nowrap',
            ...props.sx
        }}
        amountSlot={fNumber(String(amountSlot), '0,0')}
    />
);

export default memo(Cassette);
