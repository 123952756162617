import { memo } from "react";
import FiltersActionable from "ui/layouts/FiltersActionable";
import { ImportCSVAction } from "./ImportCSV";
import { CreateTaxableEntityAction } from "./CreateTaxableEntity";
import { DownloadCSVTemplateAction } from "./DownloadCSVTemplate";

const FrontPanelWidget = () => (
    <FiltersActionable>
        <DownloadCSVTemplateAction />
        <ImportCSVAction />
        <CreateTaxableEntityAction />
    </FiltersActionable>
);

export default memo(FrontPanelWidget);
