import { useEffect, useRef } from 'react';

export default function useDetectComponentMounted() {
    const isComponentMountedRef = useRef(false);

    useEffect(() => {
        isComponentMountedRef.current = true;

        return () => {
            isComponentMountedRef.current = false;
        };
    }, []);

    return isComponentMountedRef;
};
