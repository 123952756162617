import { stateFactory } from "./state";
import {
    MerchantPaymentMethodsActionType,
    type MerchantPaymentMethodsRepositoryState,
    type MerchantPaymentMethodsRepositoryAction
} from "./types";

export function reducer(state: MerchantPaymentMethodsRepositoryState, action: MerchantPaymentMethodsRepositoryAction) {
    switch (action.type) {
        case MerchantPaymentMethodsActionType.FetchMerchantsMethods: {
            return {
                ...state,
                merchantsMethods: {
                    ...state.merchantsMethods,
                    ...action.payload
                }
            };
        }

        case MerchantPaymentMethodsActionType.UpdateMerchantsMethod: {
            return {
                ...state,
                merchantsMethods: {
                    ...state.merchantsMethods,
                    ...action.payload
                }
            };
        }

        case MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory: {
            return {
                ...state,
                merchantsMethodsHistory: {
                    ...state.merchantsMethodsHistory,
                    ...action.payload
                }
            };
        }

        case MerchantPaymentMethodsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
