import type { DateRange } from "@mui/x-date-pickers-pro";
import { FormikValues } from "formik";
import useForm, { UseFormArg } from "hooks/useForm";
import type { Moment } from "moment";
import type { Form } from "types";
import { getInitialValues, useValidationSchema } from "./validators";

type UseFilesFormArgs<TFormValues extends FormikValues> =
    & UseFormArg<TFormValues>
    & {
        readonly initialValues: Form<TFormValues>['initialValues'] & {
            readonly ['dateFrom|dateTo']: DateRange<Moment>;
        }
    };

export default function useFilesForm<TFormValues extends FormikValues>({
    initialValues,
    ...restFormArgs
}: UseFilesFormArgs<TFormValues>) {
    const validationSchema = useValidationSchema();

    return useForm({
        validationSchema,
        ...restFormArgs,
        initialValues: {
            ...getInitialValues(),
            ...(initialValues ?? {})
        }
    });
};