import type { Option } from "types";
import {
    useTicketingSystemActionsContext,
    useTicketingSystemStateSelectorsContext
} from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider";
import { TicketingSystemService } from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/types";

export default function useServiceAdditionalOptions<TValue>(service: TicketingSystemService) {
    const { selectTicketingSystemAdditionalOption } = useTicketingSystemStateSelectorsContext();
    const { updateTicketingSystemSettingsAdditionalOptions } = useTicketingSystemActionsContext();

    return {
        value: selectTicketingSystemAdditionalOption<TValue>(service),
        onChange: (value: Option | Option[]) => {
            let option: Option = [value]
                .flat()
                .map(({ id }) => id)
                .map((_, __, statuses) => {
                    const id = statuses.join(',');

                    return {
                        id,
                        name: id
                    };
                })[0];

            if (!Array.isArray(value)) {
                option = {
                   ...value
                };
            }

            updateTicketingSystemSettingsAdditionalOptions({
                service,
                option
            })
        }
    };
};
