import { memo } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import type { MerchantDomain } from "features/merchants/types";

const TransactionExistanceIndicator = (merchantDomain: MerchantDomain) => (
    <Tooltip
        arrow
        placement='top'
        title={merchantDomain.transactions_exist
            ? 'Transactions exists'
            : 'No transactions exists'}
    >
        <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
        >
            {merchantDomain.transactions_exist
                ? (
                    <CheckCircleIcon
                        color='success'
                    />
                )
                : (
                    <CancelIcon
                        color='error'
                    />
                )}
        </IconButton>
    </Tooltip>
);

export default memo(TransactionExistanceIndicator);
