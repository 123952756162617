import type { CoreBankingEntity, PaginateResourceResponse, ResourceResponse, ThunkReturnType } from "types";
import type { WritableDraft } from "immer/dist/internal";
import type { PayloadAction } from "@reduxjs/toolkit";
import { sliceStateGuard, tabbableStateGuard } from "./guards";
import { ApiState } from "infrastructure/api";
import { getApiStateFromResponse } from "util/api";

export default function thunkReducerFactory<
    TResponse extends PaginateResourceResponse<CoreBankingEntity>,
    TState extends object,
>() {
    return {
        requestLoading: (state: WritableDraft<TState>) => {
            if (sliceStateGuard(state)) {
                state.tableLoadingState = ApiState.Pending;
            }

            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].tableLoadingState = ApiState.Pending;
            }
        },
        requestFulfilled: (
            state: WritableDraft<TState>,
            { payload }: PayloadAction<ThunkReturnType<TResponse>>
        ) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].tableLoadingState = ApiState.Succeeded;
                state[activeTab].tableSlice = payload!.data;
            }

            if (sliceStateGuard(state)) {
                state.tableLoadingState = ApiState.Succeeded;
                state.tableSlice = payload!.data;
            }
        },
        requestRejected: (
            state: WritableDraft<TState>,
            { payload }: PayloadAction<unknown>
        ) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].tableLoadingState = getApiStateFromResponse(
                    payload as ResourceResponse<null>,
                    ApiState.Failed
                );
            }

            if (sliceStateGuard(state)) {
                state.tableLoadingState = getApiStateFromResponse(
                    payload as ResourceResponse<null>,
                    ApiState.Failed
                );
            }
        }
    };
};
