import type { GridValueGetterParams } from "@mui/x-data-grid";

type FieldAliasArg = {
    readonly aliasField: string;
};

const fieldAlias = ({ aliasField }: FieldAliasArg) => ({ row, value }: GridValueGetterParams) => (
    row[aliasField] ?? value
);

export default fieldAlias;