import Iconify from "lib/iconify";
import Box from '@mui/material/Box';
import { memo, type PropsWithChildren } from "react";

const FileUploadPlaceholder = ({ children }: PropsWithChildren) => (
    <Box
        sx={{
            fontSize: 14,
            fontWeight: 500,
            display: 'grid',
            gridTemplateColumns: 'auto max-content',
            columnGap: 1,
            alignItems: 'center'
        }}
    >
        <Iconify
            icon="eva:cloud-upload-fill"
            width={28}
        />
        <Box>{children}</Box>
    </Box>
);

export default memo(FileUploadPlaceholder);
