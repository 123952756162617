import { Option } from "types";
import { FilterValueObject } from "../types";

export default class SelectValue extends FilterValueObject<Option | Option[] | null> {
    // public toSerialized(): string[] {
    //     return [this.value]
    //         .flat()
    //         .map(option => `${option?.id ?? ''}`)
    //         .filter(Boolean);
    // }

    public toArray() {
        return [this.value]
            .flat() as Option[];
    }

    public serialize(value: Option): string {
        return String(value.id);
    }

    public isEmpty(value: Option | null): boolean {
        return value === null;
    }
}
