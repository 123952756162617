import type { Option } from "types";
import Autocomplete, { type Props as AutocompleteProps } from "./Autocomplete";
import { getOptionName, isOptionEqualToValue } from "util/option";

export type OptionableAutocompleteProps = Omit<AutocompleteProps, 'onChange'> & {
    readonly onChange: (option: Option | Option[]) => void;
};

const OptionableAutocomplete = (props: OptionableAutocompleteProps) => {
    const handleChange: AutocompleteProps['onChange'] = (_, value) =>
        props.onChange(value);

    return (
        <Autocomplete
            {...props}
            onChange={handleChange}
            getOptionLabel={getOptionName}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
};

export default OptionableAutocomplete;
