import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

export default function useThemeColorMode() {
    const { palette: { mode } } = useTheme();

    useEffect(() => {
        document.documentElement.dataset.muiColorScheme = mode;
    }, [mode]);
};
