import type { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import useProcess from 'hooks/useProcess';
import { useState } from 'react';
import { isEqual } from 'util/support';

type UpdateRowArguments<
    TRowModel extends GridValidRowModel = GridValidRowModel,
    TRejectReason = any
> = {
    readonly resolve: (value: TRowModel | PromiseLike<TRowModel>) => void;
    readonly reject: (reason?: TRejectReason) => void;
    readonly newRow: GridRowModel<TRowModel>;
    readonly oldRow: GridRowModel<TRowModel>;
};

type UseProcessRowUpdateArg<TRowModel extends GridValidRowModel = GridValidRowModel> = {
    readonly requestHandler: (row: GridRowModel<TRowModel>) => Promise<GridRowModel<TRowModel>>;
    readonly isRowModelEqual?: (newRow: GridRowModel<TRowModel>, oldRow: GridRowModel<TRowModel>) => boolean;
};

export default function useProcessRowUpdate<
    TRowModel extends GridValidRowModel = GridValidRowModel,
    TRejectReason = unknown
>({ requestHandler, isRowModelEqual = isEqual }: UseProcessRowUpdateArg<TRowModel>) {
    const { isLoading, handleProcess } = useProcess((row?: GridRowModel<TRowModel>) => requestHandler(row!));

    const [promiseArguments, setPromiseArguments] = useState<
        UpdateRowArguments<TRowModel, TRejectReason> | null
    >(null);

    const processRowUpdate = (
        newRow: GridRowModel<TRowModel>,
        oldRow: GridRowModel<TRowModel>
    ) => new Promise<GridRowModel<TRowModel>>((resolve, reject) => {
        if (!isRowModelEqual(newRow, oldRow)) {
            // Save the arguments to resolve or reject the promise later
            setPromiseArguments({ resolve, reject, newRow, oldRow });
            return;
        }

        // Nothing was changed
        resolve(oldRow);
    });

    const withPromiseArguments = (handler: () => void) => () => {
        if (!promiseArguments) {
            return;
        }

        return handler();
    };

    const handleCancelAction = withPromiseArguments(() => {
        const { oldRow, resolve } = promiseArguments!;

        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    });

    const handleConfirmAction = withPromiseArguments(async () => {
        const { newRow, reject, resolve } = promiseArguments!;

        try {
            // Make the HTTP request to save in the backend
            const response = await handleProcess(newRow);

            resolve(response);
        } catch (error) {
            reject(error as TRejectReason);
        } finally {
            setPromiseArguments(null);
        }
    });

    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
    };

    const getProcessRowArguments = () => promiseArguments;

    return {
        isConfirmationPending: Boolean(promiseArguments),
        isLoading,
        processRowUpdate,
        handleEntered,
        handleCancelAction,
        handleConfirmAction,
        getProcessRowArguments
    };
};
