import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import type { CollectionProps } from "./types";
import Resource from "./Resource";

const Collection = ({
    isEmpty,
    emptySlot,
    isError,
    errorSlot,
    isLoading,
    children,
    collection,
    renderResource,
    ...restProps
}: PropsWithChildren<CollectionProps>) => {
    const render = () => {
        if (Array.isArray(collection) && renderResource) {
            return collection.map(renderResource);
        }

        return children;
    };

    return (
        <Box
            {...restProps}
        >
            <Resource
                isEmpty={isEmpty}
                emptySlot={emptySlot}
                isError={isError}
                errorSlot={errorSlot}
                isLoading={isLoading}
            >
                {render()}
            </Resource>
        </Box>
    );
};

export default memo(Collection);
