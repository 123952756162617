import BaseException from "exceptions/BaseException";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";
import { useContext } from "react";
import type { CoreBankingEntity } from "types";
import { DialogVisibilityDispatchContext } from "../Dialogs";

export default function useActionHandler(row: CoreBankingEntity) {
    const dialogVisibilityDispatch = useContext(DialogVisibilityDispatchContext);
    const { showNotication } = useActions();

    return (fn: (row: CoreBankingEntity) => Promise<void>) => async () => {
        try {
            return await fn(row);
        } catch (e) {
            if (e instanceof BaseException) {
                showNotication({
                    severity: NotificationSeverity.Error,
                    message: e.message,
                });
            }
        } finally {
            dialogVisibilityDispatch(false);
        }
    };
};
