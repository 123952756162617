import { memo, useState, useMemo, type ReactNode } from 'react';
import Box from '@mui/material/Box';
import { TextField, type TextFieldProps } from '../../TextFieldFilter';
import type { Option } from 'types';

type Props = Partial<Omit<
    TextFieldProps,
    | 'children'
>> & {
    readonly children: (options: Array<Option>) => ReactNode;
    readonly options: ReadonlyArray<Option>;
};

const SearchableOptions = ({
    options,
    children,
    id = '',
    name = id,
    ...textFieldProps
}: Props) => {
    const [value, setValue] = useState('');

    const onChange: TextFieldProps['onChange'] = ({ payload }) =>
        setValue(String(payload).toLocaleLowerCase());

    const reducedOptions = useMemo(() =>
        options.filter(({ id, name }) =>
            [id, name].some(searchSubject =>
                String(searchSubject)
                    .toLocaleLowerCase()
                    .includes(value)
            ))
        , [options, value]);

    return (
        <>
            <Box
                sx={{
                    px: 1,
                    py: .5,
                    '& > .MuiFormControl-root': {
                        width: '100%'
                    }
                }}
            >
                <TextField
                    id={id}
                    name={name}
                    sx={{
                        width: 'inherit',
                        ...textFieldProps.sx
                    }}
                    {...textFieldProps}
                    value={value}
                    onChange={onChange}
                />
            </Box>
            {children(reducedOptions)}
        </>
    );
};

export default memo(SearchableOptions);
