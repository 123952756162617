import { memo, useMemo } from 'react';
import Chip, { type ChipProps } from '@mui/material/Chip';
import type { StatusProps } from './types';

const Status = ({ isBlacklisted }: StatusProps) => {
    const chipProps = useMemo<ChipProps>(() => {
        if (isBlacklisted) {
            return {
                label: 'Blacklisted',
                sx: {
                    bgcolor: '#000',
                    color: '#fff',
                    fontWeight: 'bold'
                }
            };
        }

        return {
            label: 'Active',
            color: 'primary'
        };
    }, [isBlacklisted]);

    return (
        <Chip
            {...chipProps}
            size='small'
        />
    );
};

export default memo(Status);
