import { PropsWithChildren, memo } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';

type Props = Pick<
    LoadingButtonProps,
    | 'onClick'
    | 'loading'
    | 'disabled'
> & Pick<BoxProps, 'sx'>;

const DialogActionPanel = ({ sx, children, loading, disabled, onClick }: PropsWithChildren<Props>) => (
    <Box
        sx={{
            mt: 6,
            display: 'flex',
            justifyContent: 'flex-end',
            ...sx
        }}
    >
        <LoadingButton
            variant='contained'
            color='secondary'
            loading={loading}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </LoadingButton>
    </Box>
);

export default memo(DialogActionPanel);
