import { memo } from 'react';
import {
    useResourceContext,
    type ResourceDetailProps
} from "ui/widgets/Resource";
import Merchant from './Merchant';
import MerchantDetail from './MerchantDetail';

const MerchantCreateEdit = (props: ResourceDetailProps) => {
    const { getIsNew } = useResourceContext();

    if (getIsNew()) {
        return (
            <Merchant {...props} />
        );
    }

    return (
        <MerchantDetail />
    );
};

export default memo(MerchantCreateEdit);
