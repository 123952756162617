import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import LogoDark from './logo-dark.png';
import LogoLight from './logo-light.png';
import { WebRoutes } from 'consts/enpoints/web';
import { ThemePaletteMode } from 'consts/ui';
import Mini from './Mini';

// ----------------------------------------------------------------------

const logoRepository = new Map<string, string>([
  [ThemePaletteMode.Dark, LogoDark],
  [ThemePaletteMode.Light, LogoLight]
]);

export interface LogoProps extends BoxProps {
  readonly disabledLink?: boolean;
  readonly brandingImage?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, brandingImage = false, sx, ...other }, ref) => {
    const theme = useTheme();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = brandingImage
      ? (
        <Box
          sx={{
            backgroundImage: theme => `url(${logoRepository.get(theme.palette.mode)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width: 160,
            height: 70,
            ...sx
          }}
          {...other}
        />
      )
      : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 40,
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Mini
            fill={{
              [ThemePaletteMode.Light]: PRIMARY_MAIN,
              [ThemePaletteMode.Dark]: PRIMARY_DARK
            }[theme.palette.mode]}
            width={40}
            height={40}
          />
        </Box>
      );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link
        to={WebRoutes.Root}
        component={RouterLink}
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

export default Logo;
