import { useMemo, type ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import withGridCellParams from "./withGridCellParams";
import type { Transaction } from "features/transactions/types";
import { OpenBankingMessageForAccountsResultStatus, OpenBankingMessageResultStatus, OpenBankingResultStatus } from 'consts/transactions';
import { useAppStore } from 'hooks';
import { selectObResultStatusText } from 'features/general/selectors';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LaunchIcon from '@mui/icons-material/Launch';
import LockClockIcon from '@mui/icons-material/LockClock';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TuneIcon from '@mui/icons-material/Tune';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

type ObResultStatusCellData = [ReactNode, ReactNode];

const ObResultStatus = withGridCellParams<
    | string
    | null
    | OpenBankingMessageResultStatus
    | OpenBankingMessageForAccountsResultStatus,
    Transaction
>(({ value, row }) => {
    const store = useAppStore();
    const { updatedAt, methodTag } = row;

    const [Icon, tooltipTitle] = useMemo(() => {
        const appState = store.getState();
        const defaultObResultStatusCellData: ObResultStatusCellData = [
            <HelpCenterIcon color= "error"/>,
            'Undefined error'
        ];

        if (!value) {
            const obResultStatus: null | string | undefined = value;

            if (methodTag && (obResultStatus === null)) {
                return [
                    <CommentBankIcon color="warning"/>,
                    selectObResultStatusText(OpenBankingResultStatus.BankInfoNotSubmitted)(appState)
                ] as ObResultStatusCellData;
            }

            if (typeof obResultStatus === 'string') {
                return [
                    <AccountBalanceIcon color="success"/>,
                    selectObResultStatusText(OpenBankingResultStatus.BankInfoSubmitted)(appState)
                ] as ObResultStatusCellData;
            }
        }

        if (updatedAt) {
            const obResultStatus = value as OpenBankingMessageResultStatus;

            return new Map<OpenBankingMessageResultStatus, ObResultStatusCellData>([
                [OpenBankingMessageResultStatus.PaymentFinalizedWaitingConfirmation, [
                    <HourglassTopIcon color="secondary" />,
                    selectObResultStatusText(OpenBankingResultStatus.PaymentFinalizedWaitingConfirmation)(appState)
                ]],
                [OpenBankingMessageResultStatus.RedirectedConsumerToAuthorizePayment, [
                    <LaunchIcon color='action' />,
                    selectObResultStatusText(OpenBankingResultStatus.RedirectedConsumerToAuthorizePayment)(appState)
                ]],
                [OpenBankingMessageResultStatus.RequestedConsumerToAuthorizePayment, [
                    <PendingActionsIcon color='action' />,
                    selectObResultStatusText(OpenBankingResultStatus.RequestedConsumerToAuthorizePayment)(appState)
                ]],
                [OpenBankingMessageResultStatus.RequestedPasswordForConsumer, [
                    <LockClockIcon color='action'/>,
                    selectObResultStatusText(OpenBankingResultStatus.RequestedPasswordForConsumer)(appState)
                ]],
                [OpenBankingMessageResultStatus.RequestedMoreInfoFromConsumer, [
                    <ContactEmergencyIcon color="info"/>,
                    selectObResultStatusText(OpenBankingResultStatus.RequestedMoreInfoFromConsumer)(appState)
                ]],
                [OpenBankingMessageResultStatus.RequestedOptionFromConsumer, [
                    <TuneIcon color='action'/>,
                    selectObResultStatusText(OpenBankingResultStatus.RequestedOptionFromConsumer)(appState)
                ]],
                [OpenBankingMessageResultStatus.NotDefined, defaultObResultStatusCellData],
                [OpenBankingMessageResultStatus.RetryToFinalizePaymentAfterPeriod, [
                    <AvTimerIcon color='action'/>,
                    selectObResultStatusText(OpenBankingResultStatus.RetryToFinalizePaymentAfterPeriod)(appState)
                ]]
            ])
            .get(obResultStatus) ?? defaultObResultStatusCellData;
        }

        const obResultStatus = value as OpenBankingMessageForAccountsResultStatus;

        return new Map<OpenBankingMessageForAccountsResultStatus, ObResultStatusCellData>([
            [OpenBankingMessageForAccountsResultStatus.AccountInitFinalizedWaitingPaymentInit, [
                <RequestQuoteIcon color='primary'/>,
                selectObResultStatusText(OpenBankingResultStatus.AccountInitFinalizedWaitingPaymentInit)(appState)
            ]],
            [OpenBankingMessageForAccountsResultStatus.RedirectedConsumerForAccountConfirmation, [
               <DriveFileMoveIcon color='action'/>,
                selectObResultStatusText(OpenBankingResultStatus.RedirectedConsumerForAccountConfirmation)(appState)
            ]],
            [OpenBankingMessageForAccountsResultStatus.RequestedConsumerToAuthorizeAccount, [
                <AdminPanelSettingsIcon color='action'/>,
                selectObResultStatusText(OpenBankingResultStatus.RequestedConsumerToAuthorizeAccount)(appState)
            ]],
            [OpenBankingMessageForAccountsResultStatus.RetryToFinalizeAccountAfterPeriod, [
                <ManageHistoryIcon color='info'/>,
                selectObResultStatusText(OpenBankingResultStatus.RetryToFinalizeAccountAfterPeriod)(appState)
            ]]
        ])
        .get(obResultStatus) ?? defaultObResultStatusCellData;
    }, [updatedAt, methodTag, value, store]);

    return (
        <Tooltip
            title={tooltipTitle}
            placement='top'
        >
            <Box
                component='span'
            >
                {Icon}
            </Box>
        </Tooltip>
    );
});

export default ObResultStatus;
