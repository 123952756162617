import { memo, useState } from "react";

import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import type { MerchantPayoutAbstractAccount } from "features/merchants/types";
import { getAccountType } from "features/merchants/helpers";

import Autocomplete, { type Props as AutocompleteProps } from "ui/atoms/Autocomplete";

import AccountOption from "./PayoutAccountOption";
import AddIbanPayoutAccount from "./AddIbanPayoutAccount";
import AddCryptoPayoutAccount from "./AddCryptoPayoutAccount";
import type { PayoutAccountSelectorProps } from "./types";
import { AccountType } from "consts/merchants";

const PayoutAccountSelector = ({ onChange, onAdd, ...props }: PayoutAccountSelectorProps) => {
    const [isOpen, setOpen] = useState(false);

    const onOpen = () => setOpen(true);

    const onClose = () => setOpen(false);

    const getOptionLabel: AutocompleteProps['getOptionLabel'] = (option: MerchantPayoutAbstractAccount) => (
        option.address ||
        option.iban ||
        ''
    );

    const isOptionEqualToValue: AutocompleteProps['isOptionEqualToValue'] = (option, value) => {
        if (!value) {
            return false;
        }

        switch (getAccountType(value)) {
            case AccountType.Bank: {
                return value.iban === option.iban;
            }

            case AccountType.Crypto: {
                return value.address === option.address;
            }

            default: {
                return false;
            }
        }
    };

    const handleChange: AutocompleteProps['onChange'] = (_, account) => {
        onChange(account);
        onClose();
    };

    const handleAddPayoutAccount: PayoutAccountSelectorProps['onAdd'] = account =>
        onAdd(account)
            .then(onClose)
            .catch(_ => {});

    return (
        <ClickAwayListener
            onClickAway={onClose}
        >
            <Box
                component='span'
            >
                <Autocomplete
                    {...props}
                    disablePortal
                    disableClearable
                    sx={{
                        minWidth: 250
                    }}
                    size='small'
                    open={isOpen}
                    onOpen={onOpen}
                    onChange={handleChange}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={getOptionLabel}
                    noOptionsText='No payout accounts'
                    TextFieldProps={{
                        label: 'Payout Account'
                    }}
                    renderOption={(props, option, state) => (
                        <AccountOption
                            props={props}
                            option={option}
                            state={state}
                        />
                    )}
                    PaperComponent={({ children, ...props }) => (
                        <Paper {...props}>
                            {children}
                            <Divider />
                            <AddIbanPayoutAccount
                                onClose={onClose}
                                onAdd={handleAddPayoutAccount}
                            />
                            <AddCryptoPayoutAccount
                                onClose={onClose}
                                onAdd={handleAddPayoutAccount}
                            />
                        </Paper>
                    )}
                />
            </Box>
        </ClickAwayListener>
    );
};

export default memo(PayoutAccountSelector);
