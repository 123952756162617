import { memo, type ReactNode, type ComponentType } from "react";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import useClipboard from "hooks/useClipboard";
import { ClipboardTrigger } from "../Clipboard";

type Props<TTextFieldComponentProps = TextFieldProps> = TextFieldProps & {
    readonly renderTooltip?: (clipboardContent: string) => ReactNode;
    readonly TextFieldComponent?: ComponentType<TTextFieldComponentProps>;
};

const ClipboardTextField = ({
    renderTooltip,
    value,
    defaultValue,
    TextFieldComponent = TextField,
    ...props
}: Props) => {
    const {
        content,
        copy,
        reset,
        cancel
    } = useClipboard();

    const handleCopyToClipboard = () =>
        copy(String(value ?? defaultValue));

    return (
        <TextFieldComponent
            {...props}
            value={value}
            defaultValue={defaultValue}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <ClipboardTrigger
                        title={renderTooltip!(content)}
                        onOpen={cancel}
                        onClose={reset}
                        onClick={handleCopyToClipboard}
                    />
                )
            }}
        />
    );
};

ClipboardTextField.defaultProps = {
    renderTooltip: (clipboardContent: string) =>
        clipboardContent
            ? 'Copied!'
            : 'Copy to clipboard'
};

export default memo(ClipboardTextField);
