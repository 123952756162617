import { memo } from "react";
import type { TodoItemProps } from "./types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

const TodoItem = ({
    ItemContentProps,
    actionSlot,
    IconButtonProps,
    ...props
}: TodoItemProps) => (
    <Box
        {...props}
    >
        <Box
            {...ItemContentProps}
        >
            {props.children ?? ItemContentProps?.children}
        </Box>
        {actionSlot ?? (
            <IconButton
                {...IconButtonProps}
            />
        )}
    </Box>
);

export default memo(TodoItem);
