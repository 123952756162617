import { useEffect } from "react";

const useErrorHandler = () => {
  useEffect(() => {
    // const intervalRef = setInterval(console.clear);

    return () => {
      // clearInterval(intervalRef);
    };
  }, []);
};

export default useErrorHandler;
