import type { ReactNode } from 'react';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import type { CircularProgressProps } from '@mui/material/CircularProgress';
import type { InputBaseComponentProps } from '@mui/material/InputBase';

export type TextFieldProps = MuiTextFieldProps & {
    readonly label?: ReactNode;
};

export type TextFieldLoadableProps = TextFieldProps & {
    readonly loading?: boolean;
    readonly CircularProgressProps?: CircularProgressProps;
};

export type ValueModifierRegistry<T extends unknown> = Map<string, (arg: {
    readonly value: T;
    readonly rule: unknown;
}) => T>;

export abstract class ValueInterceptorModifier<T extends unknown> {
    public constructor(
        private inputProps: InputBaseComponentProps = {},
        private valueModifierRegistry: ValueModifierRegistry<T>
    ) {}

    public modifyValue(value: T): T {
        let modifiedValue = value;

        for (const modifier in this.inputProps) {
            if (this.valueModifierRegistry.has(modifier)) {
                modifiedValue = this.valueModifierRegistry.get(modifier)!({
                    value,
                    rule: this.inputProps[modifier]
                });
            }
        }

        return modifiedValue;
    }
}
