import { useTypedSelector } from "hooks";
import { selectIsConsumersLoading } from "features/consumers/selectors";
import useConsumersActions from "features/consumers/useActions";
import { FilterVariant, date } from "consts/filters";
import useCellProps from "./useCellProps";
import useFiltersProps from "./useFiltersProps";
import useTableActions from "./useTableActions";
import { multi, numericRange, single, text } from "consts/consumers";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

export default function useTable() {
    const isConsumersLoading = useTypedSelector(selectIsConsumersLoading);

    const { selectionModel, setSelectionModel } = useConsumerContext();

    const CellProps = useCellProps();

    const FiltersProps = useFiltersProps();

    const {
        getInitialState,
        getColumnsOverrides
    } = useTableActions();

    const {
        getConsumers,
        downloadCsv
    } = useConsumersActions();

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.Text, text],
        [FilterVariant.DateRange, date],
        [FilterVariant.MultiOption, multi],
        [FilterVariant.SingleOption, single],
        [FilterVariant.NumericRange, numericRange]
    ]);

    const isRowSelectable = () => !isConsumersLoading;

    return {
        isConsumersLoading,
        isRowSelectable,
        getConsumers,
        getInitialState,
        getColumnsOverrides,
        downloadCsv,
        filterTypes,
        CellProps,
        FiltersProps,
        selectionModel,
        setSelectionModel
    };
}
