import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs, { type CustomBreadcrumbsProps } from 'lib/custom-breadcrumbs';
import { type ReactNode, type PropsWithChildren, memo } from "react";
import Box from '@mui/material/Box';

type Props = {
    readonly title?: ReactNode;
    readonly breadcrumbsProps: CustomBreadcrumbsProps;
};

const Resource = ({ title, breadcrumbsProps, children }: PropsWithChildren<Props>) => (
    <Box
        sx={{
            px: '20px',
        }}
    >
        {Boolean(title) && (
            <Helmet>
                <title>{title}</title>
            </Helmet>
        )}
        {Boolean(breadcrumbsProps) && (
            <CustomBreadcrumbs
                {...breadcrumbsProps}
            />
        )}
        {children}
    </Box>
);

export default memo(Resource);
