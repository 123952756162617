import { memo, createContext, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import type { MerchantDetailTabProps } from "./types";

export const MerchantDetailTabContext = createContext<MerchantDetailTabProps['repository']>({} as MerchantDetailTabProps['repository']);

const MerchantDetailTab = ({ children, repository }: PropsWithChildren<MerchantDetailTabProps>) => (
    <MerchantDetailTabContext.Provider
        value={repository}
    >
        <Box>
            {children}
        </Box>
    </MerchantDetailTabContext.Provider>
);

export default memo(MerchantDetailTab);
