import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import type { ComponentType } from 'react';

const withGridCellParams = <
    V = string,
    R extends GridValidRowModel = {},
    F = V
>(WrappedComponent: ComponentType<GridRenderCellParams<V, R, F>>) =>
    (gridCellProps: GridRenderCellParams<V, R, F>) => (
        <WrappedComponent
            {...gridCellProps}
        />
    );

export default withGridCellParams;
