import type { Option } from "types";
import type {
    Merchant,
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount
} from "./types";
import { AccountType, ActiveStatus, KycType } from "consts/merchants";
import { compareIds } from "util/support";

export const getAccountType = (account: Partial<MerchantPayoutAccount | MerchantPayoutCryptoAccount>) => {
    if (['chain', 'address'].some(accountProperty => accountProperty in account)) {
        return AccountType.Crypto;
    }

    return AccountType.Bank;
};

export class MerchantOption {
    public static get accountStatus(): Array<Option> {
        return [
            { id: ActiveStatus.Active, name: 'Active' },
            { id: ActiveStatus.Disabled, name: 'Inactive' }
        ];
    }

    public static get kycTypes(): Array<Option> {
        return [
            { id: KycType.None, name: 'OFF' },
            { id: KycType.Strict, name: 'ON' }
        ];
    }

    public static fromAccountStatus = (status: ActiveStatus): Option | null =>
        this.accountStatus.find(option => compareIds(option.id, status)) ?? null;

    public static fromKycType = (kycType: KycType): Option | null =>
        this.kycTypes.find(option => compareIds(option.id, kycType)) ?? null;

    public static fromMerchant = ({ coreId, fullName }: Merchant): Option => ({
        id: coreId,
        name: `${fullName} (${coreId})`
    });
}
