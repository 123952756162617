import { memo } from "react";
import { TriggerableDialog, type TriggerableDialogProps } from "ui/molecules/Dialog";
import AddPayoutAccountTrigger from "./AddPayoutAccountTrigger";
import ActionPanel from "ui/molecules/ActionPanel";
import type { AddPayoutAccountTriggerProps } from "./types";
import AddPayoutAccountForm from "./AddPayoutAccountForm";
import { ProfileFinance } from "ui/forms/Merchant";
import ProfileFinanceForm, { FormField } from "ui/forms/Merchant/ProfileFinance";
import { AccountType } from "consts/merchants";
import useMerchantDetail from "../../../useMerchantDetail";

const TriggerComponent: TriggerableDialogProps['TriggerComponent'] = memo(({ isOpen, onClick }) => (
    <AddPayoutAccountTrigger
        isOpen={isOpen}
        onClick={onClick}
    >
        Add IBAN Account
    </AddPayoutAccountTrigger>
));

const AddIbanPayoutAccount = ({ onAdd, onClose }: AddPayoutAccountTriggerProps) => {
    const { merchant } = useMerchantDetail();

    const profileFinanceForm = ProfileFinance.useForm({
        initialValues: ProfileFinance.getInitialValues.get(AccountType.Bank)({
            [FormField.AccountHolderName]: merchant.fullName
        }),
        validationSchema: ProfileFinance.useAccountValidationSchema(),
        isInitialValid: false,
        onSubmit: onAdd
    });

    const renderActions = () => (
        <ActionPanel
            sx={{
                width: '100%',
                justifyContent: 'center'
            }}
            isLoading={profileFinanceForm.isSubmitting}
            SaveActionProps={{
                children: 'Add Account',
                disabled: !profileFinanceForm.isValid
            }}
            onSave={profileFinanceForm.submitForm}
            onCancel={onClose}
        />
    );

    return (
        <TriggerableDialog
            TriggerComponent={TriggerComponent}
            DialogActionsComponent={renderActions}
            ModalProps={{
                onClose: () => {
                    throw new Error('Don\'t close me');
                },
                DialogActionsProps: {
                    sx: {
                        width: '100%',
                        display: 'block'
                    }
                }
            }}
        >
            <AddPayoutAccountForm
                title='Add IBAN Account'
                sx={{
                    minWidth: 376
                }}
            >
                <ProfileFinanceForm
                    {...profileFinanceForm}
                    isNew
                    onSaveOrCreate={onAdd}
                    bootstrapData={{}}
                    isBootstrapDataLoading={false}
                />
            </AddPayoutAccountForm>
        </TriggerableDialog>
    );
};

export default memo(AddIbanPayoutAccount);
