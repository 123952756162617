import { useMemo } from 'react';
import { Filters } from "consts/merchantPayouts";
import { array, object, string } from 'yup';
import { multiColumnFilterFactory } from 'util/filters';
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { Moment } from 'moment';

export const getInitialValues = () => ({
    [Filters.MOR]: undefined,
    [Filters.via]: undefined,
    [multiColumnFilterFactory([
        Filters.dateFrom,
        Filters.dateTo
    ]) as 'dateFrom|dateTo']: [null, null] as DateRange<Moment>,
    [Filters.merchantId]: undefined,

});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [Filters.MOR]: object({
                id: string()
                    .required(),
                name: string()
                    .required()
            })
                .nullable()
                .required("This is a required field!"),
            [Filters.via]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .notRequired(),
            [multiColumnFilterFactory([
                Filters.dateFrom,
                Filters.dateTo
            ])]: array().notRequired(),
            [Filters.merchantId]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .notRequired(),
        })
        , []);
