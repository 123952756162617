import { WebRoutes } from "consts/enpoints/web";
import { selectIsNotMor } from "features/auth/selectors";
import { useAppStore } from "hooks";
import { memo } from "react";
import { Navigate, useLocation } from 'react-router-dom';

const DashboardRedirect = () => {
    const store = useAppStore();
    const { pathname } = useLocation();

    const getHomeRedirectRoute = () => {
        if (selectIsNotMor(store.getState())) {
            return WebRoutes.Dashboard;
        }

        return WebRoutes.TransactionList;
    };

    return (
        <>
            {pathname.length <= 1 && (
                <Navigate
                    to={getHomeRedirectRoute()}
                    replace
                />
            )}
        </>
    );
};

export default memo(DashboardRedirect);
