import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { memo, PropsWithChildren, ReactNode } from 'react';

type Props = {
    readonly label: ReactNode;
    readonly bgColor: string | ((theme: Theme) => string);
};

const Legend = ({ label, bgColor: backgroundColor }: PropsWithChildren<Props>) => (
    <Box
        sx={{
            fontSize: '14px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, auto)',
            gap: '8px',
            ':before': {
                content: "''",
                display: 'block',
                width: '20px',
                height: '20px',
                backgroundColor
            }
        }}
    >
        {label}
    </Box>
);

export default memo(Legend);
