import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import { Filters, useFeatureFiltersContext } from "ui/widgets/Filters";
import useMerchantPaymentMethodsFiltersModel from "./useMerchantPaymentMethodsFiltersModel";

const MerchantPaymentMethodsFilters = (props: BoxProps) => {
    const { onChange, filters = [] } = useFeatureFiltersContext();
    const { getFilterOptions } = useMerchantPaymentMethodsFiltersModel();

    return (
        <Box
            {...props}
        >
            {filters.map(filter => (
                <Filters
                    key={filter.props.id}
                    {...filter}
                    props={{
                        ...filter.props,
                        ...getFilterOptions(filter.props.id)
                    }}
                    onChange={onChange}
                />
            ))}
        </Box>
    );
};

export default memo(MerchantPaymentMethodsFilters);
