import { memo } from "react";
import type {
    GridCellParams,
    GridRowModel
} from "@mui/x-data-grid-premium";
import useActions from "features/merchantPayouts/useActions";
import { Filters, MerchantPayoutsTabs } from "consts/merchantPayouts";
import { isEqual } from "util/support";
import Filable from "../Filable";
import Actions from "./Actions";
import type { MerchantPayoutsTableProps } from "../MerchantPayoutsTable";
import { UiDateTimeFormatter, dateRangeFormatter } from "util/formaters";
import { useProcessRowUpdate } from "ui/molecules/TableGrids";
import UpdateRollingReserveRetainedUntilDialog from "./UpdateRollingReserveRetainedUntilDialog";
import { BooleanSwitchValue } from "consts/general";
import { FileResponse } from "features/general/types";

const Files = (props: MerchantPayoutsTableProps) => {
    const {
        postMerchantPayoutFiles,
        putMerchantPayoutFiles,
        mergeMerchantPayoutFiles
    } = useActions();

    const isCellEditable = ({ row }: GridCellParams) => (
        Object.is(row[Filters.rollingReserveRetainedPaidInFileId], BooleanSwitchValue.Off) &&
        Object.is(row[Filters.isPaid], BooleanSwitchValue.On) &&
        Object.is(row[Filters.parentId], null)
    );

    const updateRowProps = useProcessRowUpdate({
        isRowModelEqual: (
            newRow: GridRowModel,
            oldRow: GridRowModel
        ) => (!newRow[Filters.rollingReserveRetainedUntil] || isEqual(newRow, oldRow)),
        requestHandler: async row => {
            const [request] = putMerchantPayoutFiles({
                coreId: row.coreId,
                rollingReserveRetainedUntil: row.rollingReserveRetainedUntil
            });

            const response = (await request) as FileResponse;

            return response.data;
        }
    });

    // const {
    //     checkboxSelection,
    //     pinnedColumns,
    //     isRowSelectable,
    //     selectionModel,
    //     onSelectionModelChange
    // } = useFiles();

    // return (
    //     <MerchantPayoutsTab
    //         {...props}
    //         checkboxSelection={checkboxSelection}
    //         pinnedColumns={pinnedColumns}
    //         isRowSelectable={isRowSelectable}
    //         selectionModel={selectionModel}
    //         onSelectionModelChange={onSelectionModelChange}
    //         columns={[
    //             {
    //                 resizable: false,
    //                 disableColumnMenu: true,
    //                 field: 'actions',
    //                 headerName: 'Options',
    //                 minWidth: 170,
    //                 renderCell: (params: GridCellParams) => (
    //                     <Options {...params} />
    //                 )
    //             }
    //         ]}
    //         FiltersProps={{
    //             FrontPanelWidget: FilesFrontPanelWidget
    //         }}
    //     />
    // );

    return (
        <>
            <UpdateRollingReserveRetainedUntilDialog
                {...updateRowProps}
            />
            <Filable
                {...props}
                formId={MerchantPayoutsTabs.Files}
                dialogTitleSlot='Create Payout File'
                createNewFileRequestHandler={postMerchantPayoutFiles}
                mergeFilesRequestHandler={mergeMerchantPayoutFiles}
                isCellEditable={isCellEditable}
                processRowUpdate={updateRowProps.processRowUpdate}
                columns={[
                    {
                        field: 'rollingReserveRetainedUntil',
                        headerName: 'Rolling Reserve Retained Until',
                        type: 'date',
                        editable: true,
                        minWidth: 200,
                        align: 'center',
                        valueParser: value =>
                            dateRangeFormatter([undefined, value], {
                                pattern: UiDateTimeFormatter.Ui
                            })[1]
                    },
                    {
                        field: 'actions',
                        minWidth: 170,
                        renderCell: (params: GridCellParams) => (
                            <Actions {...params} />
                        )
                    }
                ]}
            />
        </>
    );
};

export default memo(Files);
