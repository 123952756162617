import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Card from "@mui/material/Card";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import type { Merchant } from 'features/merchants/types';
import Resource from 'ui/layouts/Resource';
import { WebRoutes } from 'consts/enpoints/web';
import Box from '@mui/material/Box';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Filters } from 'consts/transactions';
import { compareIds } from 'util/support';
import useFetchMerchantList from './useFetchMerchantList';
import type { MerchantListProps } from './types';

const MerchantList = ({
    title,
    heading,
    links,
    Route,
    action,
    disabled,
    onClick,
    secondaryAction: SecondaryAction
}: MerchantListProps) => {
    const params = useParams();

    const { getActiveMerchantsSlice, isLoading } = useFetchMerchantList();

    return (
        <Resource
            title={`Merchants | ${title}`}
            breadcrumbsProps={{
                heading,
                links: [
                    { name: 'Merchants', href: WebRoutes.Merchants.List },
                    ...links
                ],
                action
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `minmax(min-content, ${SecondaryAction ? 350 : 252}px) 1fr`,
                    columnGap: '24px',
                    alignItems: 'flex-start'
                }}
            >
                <Card
                    sx={{
                        boxShadow: theme => theme.shadows[5],
                    }}
                >
                    <List component="nav" aria-label="merchants">
                        {isLoading && (
                            Array.from({ length: 10 }).map((_, index) => (
                                <ListItem
                                    key={index}
                                >
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                        height={50}
                                    />
                                </ListItem>
                            ))
                        )}
                        {getActiveMerchantsSlice()
                            .map(({ fullName, coreId, ...merchant }: Merchant) => (
                                <ListItemButton
                                    disabled={disabled}
                                    key={coreId}
                                    component={Link}
                                    to={Route.Merchant(coreId)}
                                    selected={compareIds(
                                        String(params[Filters.merchantId]),
                                        coreId
                                    )}
                                    onClick={event => onClick?.(event, coreId)}
                                >
                                    <ListItemText primary={fullName} />
                                    {SecondaryAction && (
                                        <SecondaryAction
                                            {...merchant}
                                            coreId={coreId}
                                            fullName={fullName}
                                        />
                                    )}
                                </ListItemButton>
                            ))}
                    </List>
                </Card>
                <Outlet />
            </Box>
        </Resource>
    );
};

export default memo(MerchantList);
