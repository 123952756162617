import { useTypedSelector } from "hooks";
import { selectNotification } from "features/general/selectors";
import useActions from "features/general/useActions";
import { memo, useEffect, useState } from "react";
import Notificator from "ui/molecules/Notificator";

const AppNotificator = () => {
    const { hideNotication } = useActions();
    const notifications = useTypedSelector(selectNotification);
    const [notification, setNotification] = useState(Boolean(notifications[0]));

    const onClose = () => {
        setNotification(false);
        setTimeout(hideNotication, 1000);
    };

    useEffect(() => {
        setNotification(notifications.length > 0);
    }, [notifications.length]);

    return (
        <Notificator
            snackbarProps={{
                open: Boolean(notification),
                onClose
            }}
            alertProps={{
                severity: notifications[0]?.severity,
                onClose
            }}
        >
            {notifications[0]?.message}
        </Notificator>
    );
};

export default memo(AppNotificator);
