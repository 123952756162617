import { memo } from "react";
import Screen from "ui/layouts/Screen";
import UserProfile from "ui/organizms/Users";

const User = () => (
    <Screen
        title='Users'
    >
        <UserProfile />
    </Screen>
);

export default memo(User);
