import { memo, type ReactNode } from "react";
import Box from "@mui/material/Box";
import { CustomAvatar, type CustomAvatarProps } from "lib/custom-avatar";

export type AccountProps = Omit<CustomAvatarProps, 'title'> & {
    readonly title: ReactNode;
    readonly subTitle: ReactNode;
};

const Account = ({ title, subTitle, ...avatarProps }: AccountProps) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'min-content auto',
            gap: 3,
            alignItems: 'center'
        }}
    >
        <CustomAvatar
            {...avatarProps}
        />
        <Box
            sx={{
                fontFamily: 'var(--primary-font)'
            }}
        >
            {title}
            {subTitle}
        </Box>
    </Box>
);

export default memo(Account);
