import type { ImgHTMLAttributes, ComponentType } from 'react';

type CountryFlagSettings = Omit<ImgHTMLAttributes<{}>, 'srcSet'> & Partial<{
    readonly resource: string;
    readonly ext: string;
    readonly srcSet: Array<{
        readonly d: string;
        readonly src: string;
    }>;
    readonly component?: ComponentType | string;
}>;

const defaultSettings: CountryFlagSettings = {
    resource: '//flagcdn.com',
    width: 20,
    src: 'w20',
    loading: 'lazy',
    srcSet: [
        { "d": "2x", "src": "w40" }
    ],
    ext: 'png'
};

export const getImgProps = (iso: string, settings = {} as CountryFlagSettings) => {
    const { resource, ext, srcSet, src, ...props } = {
        ...defaultSettings,
        ...settings
    };

    return {
        ...props,
        alt: iso,
        src: getSrc(iso, settings),
        srcSet: srcSet!.map(({ src, d }) => getSrc(src, settings, d))
    };
};

export const renderElement = (
    value: string,
    {
        component: Component = 'img',
        ...restSettings
    } = {} as CountryFlagSettings
) => (
    <Component
        {...getImgProps(value, {
            ...defaultSettings,
            ...restSettings
        })}
    />
);

function getSrc(iso: string, { resource, src, ext }: CountryFlagSettings, appendWith = '') {
    return `${[resource, src, iso.toLocaleLowerCase()]
        .filter(Boolean)
        .join('/')}.${ext} ${appendWith}`.trim();
}
