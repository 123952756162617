import { useTypedSelector } from "hooks";
import { selectBitsoBanks, selectIsBitsoBanksLoading } from "./selectors";
import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { getBitsoBanks as getBitsoBanksRequest } from "./api";
import { changeBitsoBanksLoadingState, changeBitsoBanksSlice } from "./slice";
import useSessionEffect from "hooks/useSessionEffect";
import { PER_PAGE_SIZE } from "consts/transactions";
import { FetchResourceSettings } from "hooks/useFetchResource";
import { ApiState } from "infrastructure/api";

const bitsoBanksSessionEffectSymbol = Symbol('bitsoBanksSessionEffect');

export default function useBitsoBanks({
    shouldFetchAll = true,
    perPage = PER_PAGE_SIZE
}: FetchResourceSettings = {}) {
    const bitsoBanks = useTypedSelector(selectBitsoBanks);
    const isBitsoBanksLoading = useTypedSelector(selectIsBitsoBanksLoading);

    const getBitsoBanks = useFetchFeatureResource({
        shouldFetchAll,
        perPage,
        resourceRequest: getBitsoBanksRequest,
        changeLoadingState: changeBitsoBanksLoadingState,
        changeSlice: changeBitsoBanksSlice,
        condition: getState => {
            const { bitsoBanksLoadingState } = getState().general;

            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(bitsoBanksLoadingState);
        }
    });

    useSessionEffect(() => {
        getBitsoBanks();
    }, bitsoBanksSessionEffectSymbol);

    return {
        bitsoBanks,
        isBitsoBanksLoading
    };
};
