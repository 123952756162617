import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { type CheckboxProps } from "@mui/material/Checkbox";
import { OptionableAutocomplete, type OptionableAutocompleteProps } from "ui/atoms/Autocomplete";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { FormField, type MerchantAccountAdvancedSettingsProps } from "./types";
import { getFormInputProps } from "util/forms";
import { MerchantOption } from "features/merchants/helpers";

const AccountAdvancedSettings = ({
    isBootstrapDataLoading,
    bootstrapData,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched
}: MerchantAccountAdvancedSettingsProps) => {
    const onSelectChange: (formField: FormField) => OptionableAutocompleteProps['onChange'] = (formField: FormField) =>
        option => {
            if (Array.isArray(option)) {
                return;
            }

            setFieldTouched(formField, true);
            setFieldValue(
                formField,
                option?.id,
                true
            );
        };

    const onCheckboxChange: (formField: FormField) => CheckboxProps['onChange'] = (formField: FormField) =>
        (_, value) => {
            setFieldTouched(formField, true);
            setFieldValue(
                formField,
                Number(value),
                true
            );
        };

    return (
        <TabLayout
            title='Advanced Options'
        >
            <Box
                sx={{
                    pt: 2,
                    display: 'grid',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 2
                    }}
                >
                    <TextField
                        {...getFormInputProps(FormField.Descriptor, { errors, touched })}
                        fullWidth
                        size='small'
                        name={FormField.Descriptor}
                        value={values[FormField.Descriptor]}
                        label='Descriptor'
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <OptionableAutocomplete
                        size='small'
                        options={bootstrapData[FormField.AccountStatus]}
                        value={MerchantOption.fromAccountStatus(values[FormField.AccountStatus]!)}
                        loading={isBootstrapDataLoading}
                        TextFieldProps={{
                            label: 'Account Status'
                        }}
                        onChange={onSelectChange(FormField.AccountStatus)}
                        FormControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />
                    <FormControlLabel
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                        control={(
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Enable/Disable Whitelist Check',
                                }}
                                name={FormField.WhitelistCheck}
                                onChange={onCheckboxChange(FormField.WhitelistCheck)}
                                onBlur={handleBlur}
                                checked={Boolean(Number(values[FormField.WhitelistCheck]))}
                            />
                        )}
                        label='Enable/Disable Whitelist Check'
                    />
                    <OptionableAutocomplete
                        size='small'
                        options={bootstrapData[FormField.AccountKYCOption]}
                        value={MerchantOption.fromKycType(values[FormField.AccountKYCOption]!)}
                        loading={isBootstrapDataLoading}
                        TextFieldProps={{
                            label: 'Account KYC Option'
                        }}
                        onChange={onSelectChange(FormField.AccountKYCOption)}
                        FormControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />
                </Box>
                <TextField
                    {...getFormInputProps(FormField.ClientCallbackUrl, { errors, touched })}
                    fullWidth
                    size='small'
                    name={FormField.ClientCallbackUrl}
                    value={values[FormField.ClientCallbackUrl]}
                    label='Client Callback URL'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    {...getFormInputProps(FormField.ClientNotificationUrl, { errors, touched })}
                    fullWidth
                    size='small'
                    name={FormField.ClientNotificationUrl}
                    value={values[FormField.ClientNotificationUrl]}
                    label='Client Notification URL'
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 2
                    }}
                >
                    <FormControlLabel
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                        control={(
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Enable Domain Check',
                                }}
                                name={FormField.DomainCheck}
                                onChange={onCheckboxChange(FormField.DomainCheck)}
                                onBlur={handleBlur}
                                checked={Boolean(Number(values[FormField.DomainCheck]))}
                            />
                        )}
                        label='Enable Domain Check'
                    />
                    <TextField
                        {...getFormInputProps(FormField.DailyAmountLimit, { errors, touched })}
                        fullWidth
                        type='number'
                        size='small'
                        name={FormField.DailyAmountLimit}
                        value={values[FormField.DailyAmountLimit]}
                        label='Merchant Daily Amount Limit'
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Box>
            </Box>
        </TabLayout>
    );
};

export default memo(AccountAdvancedSettings);
