import { memo } from "react";
import ProfileInfo from "ui/forms/Merchant/ProfileInfo";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { useMerchantProfileContext } from "../MerchantProfileProvider";

const Info = () => {
    const { selectInfo } = useMerchantProfileContext();

    return (
        <TabLayout
            title='General Information'
        >
            <ProfileInfo
                {...selectInfo()}
            />
        </TabLayout>
    );
};

export default memo(Info);
