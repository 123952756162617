import { memo } from "react";
import { Statuses } from "./AdditionalOptions";
import {
    TicketingSystemService,
    TicketingSystemServicesState
} from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/types";
import {
    Service,
    useServiceEnable,
    useServiceAdditionalOptions
} from "./Service";

type SupportPageOnRedirectAdditionalOptions = TicketingSystemServicesState[
    TicketingSystemService.SupportPageOnRedirect
]['additionalOptions'];

const SupportPageOnRedirect = () => (
    <Service
        {...useServiceEnable(TicketingSystemService.SupportPageOnRedirect)}
        label='Support page on redirect'
        additionalOptionsSlot={(
            <Statuses
                {...useServiceAdditionalOptions<SupportPageOnRedirectAdditionalOptions>(
                    TicketingSystemService.SupportPageOnRedirect
                )}
            />
        )}
    />
);

export default memo(SupportPageOnRedirect);