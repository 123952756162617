import { MIN_ALLOWED_AGE } from "consts/general";
import type { Moment } from "moment";
import moment from "moment";

export const date = (value?: string | null | Moment) => {
    if (!value) {
        return false;
    }

    if (!moment(value).isValid()) {
        return false;
    }

    if (moment().diff(moment(value), 'years') < MIN_ALLOWED_AGE) {
        return false;
    }

    return true;
};
