import type { Consumer } from "features/consumers/types";
import type { FilableKYCServiceState, KYCEntity } from "features/kyc/types";
import useKYCServices from "features/kyc/useKYCServices";
import { useEffect, useRef, useState } from "react";
import { TabIndex, TabProps } from "../types";
import type { ID, PaginateResourceResponse } from "types";
import { compareIds } from "util/support";
import useFetchResource from "hooks/useFetchResource";
import { getKYCEntities } from "features/kyc/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/consumers";
import { KYCServiceType } from "consts/kyc";
import { useAppStore } from "hooks";
import { selectCountryByProperty } from "features/general/selectors";
import { MULTI_COLUMN_SEPARATOR } from "consts/filters";

const prevConsumerCoreIdSymbol = Symbol('prevConsumerCoreId');

interface UseFilableKYCServices {
    (props: TabProps<TabIndex.MarkAsKYCed>): ReturnType<
        typeof useKYCServices<FilableKYCServiceState>
    > & {
        readonly isServiceLoading: boolean;
    };
    [prevConsumerCoreIdSymbol]?: ID;
}

const useFilableKYCServices: UseFilableKYCServices = ({
    getTabPayload,
    tabRegistry
}: TabProps<TabIndex.MarkAsKYCed>) => {
    const [isServiceLoading, setLoading] = useState<boolean>(false);
    const { coreId } = getTabPayload<Consumer>();

    const store = useAppStore();

    const request = useFetchResource({
        setLoading
    });

    const kycServices = useKYCServices<FilableKYCServiceState>({
        getKYCServiceState: () => ({
            checked: false,
            disabled: false,
            files: [],
            merchant: null,
            country: null
        })
    });

    const helpers = {
        ...kycServices,
        tabRegistry,
        request
    };

    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        if (isServiceLoading) {
            return;
        }

        const {
            request,
            onKYCServiceChange,
            tabRegistry: [kycsRegistry, updateDialogDataRegistry],
            resetKYCServicesState
        } = helpersRef.current;

        const updateKYCservices = (kycEntities: KYCEntity[]) => {
            resetKYCServicesState();

            const kycServicesMapping = new Map<
                KYCServiceType,
                Pick<
                    FilableKYCServiceState,
                    | 'checked'
                    | 'files'
                    | 'country'
                    | 'merchant'
                >
            >();

            for (const { kycTypeId, fileKey, countryIso2 = '', reason = '' } of kycEntities) {
                const filableKYCServiceState = kycServicesMapping.get(kycTypeId) ?? {
                    files: []
                };

                const [merchantId, merchantName] = reason.split(MULTI_COLUMN_SEPARATOR);

                kycServicesMapping.set(kycTypeId, {
                    ...filableKYCServiceState,
                    checked: true,
                    files: [
                        ...filableKYCServiceState.files,
                        fileKey || ''
                    ]
                    .filter(Boolean)
                    .slice(0, 1),
                    country: selectCountryByProperty(['iso2', countryIso2])(store.getState()) ?? null,
                    merchant: reason.length
                        ? {
                            id: merchantId,
                            name: merchantName
                        }
                        : null
                });
            }

            kycServicesMapping.forEach((filableKYCServiceState, kycTypeId) => {
                onKYCServiceChange([
                    kycTypeId,
                    filableKYCServiceState
                ]);
            });
        };

        if (compareIds(coreId, useFilableKYCServices[prevConsumerCoreIdSymbol]!)) {
            updateKYCservices(kycsRegistry);
        } else {
            request<PaginateResourceResponse<KYCEntity>>(() => getKYCEntities(
                RequestQueryMapper.from()
                    .containsIn(Filters.consumerId, `${coreId}`)
                    .toString()
            ))
                .then(({ data: kycs }: PaginateResourceResponse<KYCEntity>) => {
                    const { data: kycEntities } = kycs;

                    updateDialogDataRegistry(kycEntities);
                    updateKYCservices(kycEntities);
                });
        }

        useFilableKYCServices[prevConsumerCoreIdSymbol] = coreId;
    }, [coreId, isServiceLoading, store]);

    return {
        ...kycServices,
        isServiceLoading
    };
};

useFilableKYCServices[prevConsumerCoreIdSymbol] = '';

export default useFilableKYCServices;
