import { memo, useState, MouseEvent } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from "@mui/material/Card";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import type { Props } from "./Props";
import useOptionController from "./useOptionController";

const MultiOption = ({ id, options, value, onChange }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const { isSelected, handleChange } = useOptionController({
        value,
        onChange,
    });

    const open = Boolean(anchorEl);
    const describedBy = open ? id : undefined;

    return (
        <Box>
            <Button
                aria-describedby={describedBy}
                sx={{
                    whiteSpace: 'nowrap',
                    '& .MuiButton-endIcon': {
                        transition: 'transform 0.3s',
                        transform: open
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)'
                    }
                }}
                variant='outlined'
                endIcon={<ExpandMoreIcon />}
                onClick={handleOpen}
            >
                Additional Options
            </Button>
            <Popover
                id={describedBy}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Card>
                    {options.map(({ id, name }) => (
                        <Box
                            key={id}
                            sx={{
                                pl: '20px',
                                '&:first-child': {
                                    pt: '10px'
                                },
                                '&:last-child': {
                                    pb: '10px'
                                }
                            }}
                        >
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name={String(id)}
                                        checked={isSelected({
                                            id,
                                            name
                                        })}
                                        onChange={handleChange}
                                    />
                                )}
                                label={name}
                            />
                        </Box>
                    ))}
                </Card>
            </Popover>
        </Box>
    );
};

export default memo(MultiOption);
