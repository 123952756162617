import { memo } from "react";
import Autocomplete, { type Props as AutocompleteProps } from "ui/atoms/Autocomplete";
import type { Option } from 'types';
import type { AutocompleteFilterProps } from "./types";
import { compareIds } from "util/support";
import useSelectFilter from "./useSelectFilter";

const AutocompleteFilter = (props: AutocompleteFilterProps) => {
    const { label, id, onChange, InputProps, ...restProps } = props;

    const autocompleteDefaultProps: Partial<AutocompleteProps> = {
        isOptionEqualToValue: (option: Option, value: Option) => compareIds(option.id, value.id),
        getOptionLabel: (option: Option) => option.name,
        id,
        sx: {
            width: 211
        },
        TextFieldProps: {
            sx: {
                width: 211
            },
            InputProps: {
                placeholder: label,
                ...InputProps
            }
        }
    };

    const { getValue, handleChange } = useSelectFilter({
        ...autocompleteDefaultProps,
        ...props
    });

    /*const handleChange: AutocompleteProps['onChange'] = (_, option: Option) => {
        onChange({
            type: id,
            payload: option
        });
    };

    const getValue = () => {
        const { value, options = [] } = props;

        const getValueFromOptions: (value: Option | Option[] | null) => (Option | Option[] | null) =
            (value: Option | Option[] | null) => {
                if (!value) {
                    return value;
                }

                if (Array.isArray(value)) {
                    const values = [];

                    for (const option of options) {
                        for (const v of value) {
                            if (compareIds(v.id, option.id)) {
                                values.push(option);
                            }
                        }
                    }

                    return values;
                }

                return options.find(option => autocompleteDefaultProps.isOptionEqualToValue!(option, value)) ?? null;
            };

        return value
            ? getValueFromOptions(value)
            : value;
    };*/

    return (
        <Autocomplete
            {...autocompleteDefaultProps}
            {...restProps}
            value={getValue()}
            label={label}
            onChange={handleChange}
        />
    );
};

export default memo(AutocompleteFilter);

/*export class AutocompleteValueObject extends FilterValueObject<Option | Option[] | null> {
    // public toSerialized(): string[] {
    //     return [this.value]
    //         .flat()
    //         .map(option => `${option?.id ?? ''}`)
    //         .filter(Boolean);
    // }

    public toArray() {
        return [this.value]
            .flat() as Option[];
    }

    public serialize(value: Option): string {
        return String(value.id);
    }

    public isEmpty(value: Option | null): boolean {
        return value === null;
    }
}

// TODO: refactor into factory

export const MultipleValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new AutocompleteValueObject(
            filterValueStrategy[FilterVariant.MultiOption][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](values: Array<Option>, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new AutocompleteValueObject(
            filterValueStrategy[FilterVariant.MultiOption][changeValueStrategyName]!.handleChangeValue(values)
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.MultiOption][defaultValueStrategyName]!.getDefaultValue();
    }
};

export const SingleValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new AutocompleteValueObject(
            filterValueStrategy[FilterVariant.SingleOption][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](value: Option | null, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new AutocompleteValueObject(
            filterValueStrategy[FilterVariant.SingleOption][changeValueStrategyName]!.handleChangeValue(value)
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.SingleOption][defaultValueStrategyName]!.getDefaultValue();
    }
};*/
