import { PropsWithChildren, memo } from "react";
import Box from "@mui/material/Box";
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

type Props = PropsWithChildren<Pick<TooltipProps, 'title'>>;

const MasterConnectionLabel = ({ children, ...tooltipProps }: Props) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            columnGap: 1,
            alignItems: 'center'
        }}
    >
        <Box>{children}</Box>
        <Box>
            <Tooltip
                arrow
                placement='top'
                {...tooltipProps}
            >
                <InfoIcon color='primary' />
            </Tooltip>
        </Box>
    </Box>
);

export default memo(MasterConnectionLabel);
