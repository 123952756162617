import { memo, type SVGAttributes } from "react";
import Tooltip from "@mui/material/Tooltip";
import type { AxisBottomTickProps } from "./types";
import useTickVisibilityController from "../../hooks/useTickVisibilityController";

const AxisBottomTick = ({
    isLoading,
    data,
    renderTitle,
    tooltipProps,
    formatValue = _ => _,
    ...props
}: AxisBottomTickProps) => {
    const {
        value,
        textX,
        textY,
        lineX,
        lineY,
        rotate,
        tickIndex,
        textAnchor,
        textBaseline,
        x,
        y,
    } = props;
    const tickVisibilityController = useTickVisibilityController({ data });
    const formattedValue = formatValue(value);

    return (
        <Tooltip
            arrow
            title={renderTitle?.(props) ?? formattedValue}
            placement='bottom'
            {...tooltipProps}
        >
            <g
                transform={`translate(${x}, ${y})`}
                style={{
                    opacity: Number(isLoading),
                    animation: isLoading
                        ? 'none'
                        : 'appear .3s .9s ease-in-out forwards'
                }}
            >
                <line
                    x1="0"
                    x2="0"
                    y1={lineX}
                    y2={lineY}
                    style={{
                        stroke: 'rgb(119, 119, 119)',
                        strokeWidth: 1
                    }}
                />
                {tickVisibilityController.checkVisibility(tickIndex) && (
                    <text
                        alignmentBaseline={textBaseline as SVGAttributes<SVGTextElement>['alignmentBaseline']}
                        // dominantBaseline="central"
                        textAnchor={textAnchor}
                        transform={`translate(${textX}, ${textY}) rotate(${rotate})`}
                        style={{
                            fontFamily: 'var(--manrope-font)',
                            fontSize: '0.75rem',
                            fill: 'rgb(51, 51, 51)'
                        }}
                    >
                        {formattedValue}
                    </text>
                )}
            </g>
        </Tooltip>
    );
};

export default memo(AxisBottomTick);
