import type { FormikConfig } from "formik";
import type useQueryBuilderForm from "./useQueryBuilderForm";
import type { ComponentType } from "react";
import type { TextFieldProps } from "@mui/material/TextField";

export const enum FormFields {
    SearchQuery = 'searchQuery'
};

export type FormValues = {
    readonly [FormFields.SearchQuery]: string;
};

export type QueryBuilderFormProps = Pick<FormikConfig<FormValues>, 'onSubmit'> & {
    readonly isLoading?: boolean;
};

export type QueryBuilderForm = ReturnType<typeof useQueryBuilderForm>;

export type QueryBuilderProps =
    & QueryBuilderFormProps
    & Pick<
        TextFieldProps,
        | 'label'
        | 'placeholder'
    >
    & {
        readonly renderChildren?: ComponentType<QueryBuilderForm>;
    };
