import { Filters, SamePersonIdentifierSource, LastKycs, Relations } from "consts/consumers";
import { KYCProcessingStatus, KYCProviders, KYCServiceType, KYCStatus, ManualBulkKycImportStatus } from "consts/kyc";
import { Aggregates } from "consts/request-query";
import { ManagementApiMapper } from "util/mappers";
import { RequestQueryMapper } from "util/request-query-mapper";
import { compareIds } from "util/support";

const handleQueryExtensionForKycStatus = (kycServiceType: KYCServiceType) =>
    (statuses: Array<string>, requestQueryMapper: RequestQueryMapper) => {
        const lastKycN = ManagementApiMapper.from(`${Filters.lastKyc}${kycServiceType}`);

        requestQueryMapper
            .containsIn(lastKycN.get(LastKycs.KycTypeId), `${kycServiceType}`)

        for (const status of statuses) {
            if (compareIds(status, KYCProcessingStatus.NA)) {
                requestQueryMapper
                    .doesntHaveRelations(`${Relations.LastKycs}${kycServiceType}`);
            } else {
                requestQueryMapper
                    .containsIn(lastKycN.get(LastKycs.Status), status)
            }
        }
    };

const queryExtensionMap = new Map<string, ((values: string[], requestQueryMapper: RequestQueryMapper) => void)>()
    .set(Filters.kycStatus, ([kycStatus]: string[], requestQueryMapper: RequestQueryMapper) => {
        const lastKyc11 = ManagementApiMapper.from(Filters.lastKyc11);
        const lastKyc22 = ManagementApiMapper.from(Filters.lastKyc22);

        return new Map<KYCStatus, (() => RequestQueryMapper)>()
            .set(
                KYCStatus.Success,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.Status), `${KYCProcessingStatus.Success}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Pending}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Failed}`)
                    .doesntHaveRelations(Relations.LastKycs22)
            )
            .set(
                KYCStatus.Failed,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.Status), `${KYCProcessingStatus.Failed}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Pending}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Success}`)
                    .doesntHaveRelations(Relations.LastKycs22)
            )
            .set(
                KYCStatus.Pending,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.Status), `${KYCProcessingStatus.Pending}`)
                    .containsIn(lastKyc11.get(LastKycs.Status), `${KYCProcessingStatus.Failed}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Pending}`)
                    .containsIn(lastKyc22.get(LastKycs.Status), `${KYCProcessingStatus.Success}`)
            )
            .set(
                KYCStatus.NA,
                () => requestQueryMapper
                    .doesntHaveRelations(Relations.LastKycs11)
            )
            .set(
                KYCStatus.Triggered,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.IsPerConsumer), `${KYCProcessingStatus.Success}`)
                    .containsIn(lastKyc11.get(LastKycs.Status), `${KYCProcessingStatus.Pending}`)
            )
            .set(
                KYCStatus.ManuallyCompleted,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.Provider), KYCProviders.Manual)
                    .containsIn(lastKyc11.get(LastKycs.KycTypeId), `${KYCServiceType.IDVerification}`)
                    .containsIn(lastKyc22.get(LastKycs.Provider), KYCProviders.Manual)
                    .containsIn(lastKyc22.get(LastKycs.KycTypeId), `${KYCServiceType.AddressVerification}`)
            )
            .set(
                KYCStatus.ManuallyPending,
                () => requestQueryMapper
                    .containsIn(lastKyc11.get(LastKycs.Provider), KYCProviders.Manual)
                    .containsIn(lastKyc11.get(LastKycs.KycTypeId), `${KYCServiceType.IDVerification}`)
                    .containsIn(lastKyc11.get(LastKycs.KycTypeId), `${KYCServiceType.AddressVerification}`)
                    .hasRelations(Relations.LastKycs11, `${KYCProcessingStatus.Success}`)
            )
            .get(kycStatus as KYCStatus)?.();
    })
    .set(
        Filters.kycStatusFace,
        handleQueryExtensionForKycStatus(KYCServiceType.FaceVerification)
    )
    .set(
        Filters.kycStatusId,
        handleQueryExtensionForKycStatus(KYCServiceType.IDVerification)
    )
    .set(
        Filters.kycStatusAml,
        handleQueryExtensionForKycStatus(KYCServiceType.AMLVerification)
    )
    .set(
        Filters.kycStatusAddress,
        handleQueryExtensionForKycStatus(KYCServiceType.AddressVerification)
    )
    .set(
        Filters.kycStatusPhone,
        handleQueryExtensionForKycStatus(KYCServiceType.PhoneVerification)
    )
    .set(
        Filters.kycStatusEidv,
        handleQueryExtensionForKycStatus(KYCServiceType.EIDVerification)
    )
    .set(Filters.samePersonIdentifierSource, ([samePersonIdentifierSource]: string[], requestQueryMapper: RequestQueryMapper) => {
        return new Map<string, (() => RequestQueryMapper)>()
            .set(
                '',
                () => requestQueryMapper
                    .containsIn(Filters.samePersonIdentifierSource, '')
            )
            .set(
                SamePersonIdentifierSource.Iban,
                () => requestQueryMapper
                    .contains(Filters.samePersonIdentifierSource, SamePersonIdentifierSource.Iban)
            )
            .set(
                SamePersonIdentifierSource.Manual,
                () => requestQueryMapper
                    .contains(Filters.samePersonIdentifierSource, SamePersonIdentifierSource.Manual)
            )
            .set(
                SamePersonIdentifierSource.KYC,
                () => requestQueryMapper
                    .contains(Filters.samePersonIdentifierSource, SamePersonIdentifierSource.KYC)
            )
            .get(samePersonIdentifierSource)?.();
    })
    .set(Relations.SamePersonConsumerEmails, (duplicatesRange: Array<string>, requestQueryMapper: RequestQueryMapper) => {
        duplicatesRange.forEach((range: string, index: number) => {
            if (String(range).length) {
                requestQueryMapper
                .hasDistinctRelations(
                    Relations.SamePersonConsumerEmails,
                    range,
                    index
                );
            }
        });
    })
    .set(Filters.manualBulkKycImportStatus, ([manualBulkKycImportStatus]: string[], requestQueryMapper: RequestQueryMapper) => {
         return new Map<
         string,
            () => RequestQueryMapper
        >()
            .set(ManualBulkKycImportStatus.NotCreated,
                () =>requestQueryMapper
                    .containsIn(Filters.manualBulkKycImportStatus, `${ManualBulkKycImportStatus.Created}`)
                    .doesntHaveRelations(Relations.ConsumersManualBulkKycsImportHistoryPivot)
            )
            .set (
                ManualBulkKycImportStatus.Created,
                () =>requestQueryMapper
                    .containsIn(Filters.manualBulkKycImportStatus, `${ManualBulkKycImportStatus.Created}`)
            )
            .get(manualBulkKycImportStatus)?.()
    })

export default function useRequestQueryOverrideDecorator(
    requestQueryAggregator: (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void
) {
    return (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        applyAggregates(backendQueryParams);
        RequestQueryMapper.applyWithCustomQueryAggregator({
            fontendQueryParams,
            backendQueryParams,
            queryExtensionMap
        });

        return requestQueryAggregator(fontendQueryParams, backendQueryParams);
    };
};

function applyAggregates(
    backendQueryParams: URLSearchParams
) {
    const requestQueryMapper = RequestQueryMapper.from(backendQueryParams);

    [Relations.SamePersonConsumerEmails, Relations.Merchants]
        .forEach(relation => {
            if (!Array.from(backendQueryParams.values()).includes(relation)) {
                requestQueryMapper
                    .aggregates(Aggregates.CountRelations, relation);
            }
        });
}
