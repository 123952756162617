import type { MerchantSettings } from "features/merchants/types";
import type { MerchantsNotificationChannelsPivot } from "features/pivots/types";
import type { Option } from "types";
import { getIntervals, getIntervalsByValue, getStatuses } from "./autocompleteOptions";

export const getAdditionalOptionsStatus = (
    status: MerchantSettings['showTicketingSystemOnRedirectOnlyOnStatuses']
) => {
    const id = status;

    const option = {
        id,
        name: String(id)
    };

    if (!status) {
        return getStatuses()[0];
    }

    return option;
};

export const getAdditionalOptionsInterval = (
    secondsInterval: MerchantsNotificationChannelsPivot['secondsInterval']
): Option => {
    const intervalOption = getIntervalsByValue(secondsInterval);

    return intervalOption ?? getIntervals()[1];
};
