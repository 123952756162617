import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from "ui/atoms/InputLabel";
import { inputBaseClasses, svgIconClasses } from "@mui/material";
import type { TextFieldProps } from "./types";
import useTextField from "./useTextField";

const StyledTextField = styled(MuiTextField)(() => ({
    [`& .${inputBaseClasses.root}`]: {
        borderRadius: 4,
        [`& .${svgIconClasses.root}`]: {
            marginRight: 10
        }
    }
}));

export type Props = TextFieldProps & {
    readonly label?: ReactNode;
};

const TextField: ForwardRefRenderFunction<any, Props> = ({ label, ...props }, ref) => (
    <FormControl variant='standard'>
        <InputLabel shrink htmlFor={props.id}>
            {label}
        </InputLabel>
        <StyledTextField
            ref={ref}
            size='small'
            {...props}
            {...useTextField(props)}
        />
    </FormControl>
);

export default forwardRef(TextField);
