import { PropsWithChildren, memo } from "react";
import Box from "@mui/material/Box";
import useMultiTabDialog from "./useMultiTabDialog";
import type { Consumer } from "features/consumers/types";

type Props = Pick<ReturnType<typeof useMultiTabDialog>, 'getTabPayload'>;

const TabLayout = ({ children, getTabPayload }: PropsWithChildren<Props>) => {
    if (!getTabPayload<Consumer>()) {
        return null;
    }

    return (
        <Box
            // sx={{
            //     px: '47px',
            //     py: '43px'
            // }}
        >
            {children}
        </Box>
    );
};

export default memo(TabLayout);
