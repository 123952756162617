import { memo } from "react";
import Taxable from "../Taxable";
import AddIncrementExchangeRateAction from "./AddIncrementExchangeRateAction";
import DeleteIncrementExchangeRatesDialogBody from "./DeleteIncrementExchangeRatesDialogBody";
import useActions from "features/merchantPayouts/useActions";
import { postMerchantPayoutIncrementExchangeRatesBulkThunk } from "features/merchantPayouts/thunks";
import { downloadIncremetExchangeRatesCSVTemplate } from "features/merchantPayouts/api";
import type { MerchantPayoutsTableProps } from "../MerchantPayoutsTable";

const IncrementExchangeRates = (props: MerchantPayoutsTableProps) => {
    const { deleteMerchantPayoutIncrementExchangeRates } = useActions();

    return (
        <Taxable
            {...props}
            downloadTemplateHandler={downloadIncremetExchangeRatesCSVTemplate}
            deleteActionRequestHandler={deleteMerchantPayoutIncrementExchangeRates}
            importBulkRequestThunk={postMerchantPayoutIncrementExchangeRatesBulkThunk}
            createTaxableActionLabel="Add new rate"
            createTaxableEntitySlot={(
                <AddIncrementExchangeRateAction />
            )}
            label="Delete exchange rate"
            dialogProps={{
                children: DeleteIncrementExchangeRatesDialogBody
            }}
        />
    );
};

export default memo(IncrementExchangeRates);
