import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { KYCMerchantList, KYCSettingsProvider } from "ui/organizms/Merchants/KYC";

const KYCMerchantListScreen = () => (
    <Screen
        title='Merchants | KYC Settings'
    >
        <KYCSettingsProvider>
            <KYCMerchantList />
        </KYCSettingsProvider>
    </Screen>
);

export default memo(KYCMerchantListScreen);
