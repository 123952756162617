import { useRef, useState, useEffect } from 'react';
import { ConsumerActionType, useConsumerContext } from 'ui/organizms/Consumers/ConsumerProvider';
import { useModal } from 'ui/atoms/Modal';
import { KYCServiceType } from 'consts/kyc';
import { useSimpleRequest } from 'hooks/useRequest';
import type {
    ConsumerKYCServiceResponse,
    ConsumerKYCServiceTypeRegistry,
    TriggerKYCServiceRequestParams
} from './types';
import type { GridSelectionModel } from '@mui/x-data-grid-premium';
import { RequestQueryMapper } from 'util/request-query-mapper';
import { Filters } from 'consts/consumers';
import { getConsumerKYCSettings } from 'features/consumers/api';
import type { PaginateResourceResponse } from 'types';
import { ConsumerKYCService } from 'features/consumers/types';
import { Paginate } from 'consts/table';
import useKYCServices from 'features/kyc/useKYCServices';
import { fromPaginateResourceResponse } from 'util/api';

export default function useTriggerKYCDialog() {
    const { open, onOpen, onClose } = useModal();

    const consumerKYCServiceStateRegistryRef = useRef<ConsumerKYCServiceTypeRegistry>(
        {} as ConsumerKYCServiceTypeRegistry
    );

    const request = useSimpleRequest();
    const requestRef = useRef(request);
    requestRef.current = request;

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const [isProcessing, setProcessingState] = useState(false);
    const [isTriggerKYCServicesLoading, setTriggerKYCServicesLoading] = useState(false);

    const {
        servicesState,
        serviceStateRef,
        setServicesState,
        onKYCServiceChange,
        resetKYCServicesState,
        getKYCServicesCollection
    } = useKYCServices();

    const kycServicesRef = useRef({
        setServicesState,
        serviceStateRef
    });
    kycServicesRef.current = {
        setServicesState,
        serviceStateRef
    };

    const modalControllerRef = useRef({
        onOpen,
        onClose
    });

    modalControllerRef.current = {
        onOpen,
        onClose
    };

    const {
        selectionModel,
        setSelectionModel,
        consumersState,
        resetConsumersState
    } = useConsumerContext();

    const selectionModelRef = useRef<GridSelectionModel>(selectionModel);
    selectionModelRef.current = selectionModel;

    const closeDialog = () => {
        onClose();

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            resetConsumersState();
            resetKYCServicesState();
            setProcessingState(false);
            setSelectionModel([]);

            timeoutRef.current = undefined;
        }, 500);
    };

    const handleDialogClose = () => {
        if (isProcessing || isTriggerKYCServicesLoading) {
            return;
        }

        closeDialog();
    };

    const handleProcessKYCService = (
        processKYC: (consumerKYCService: TriggerKYCServiceRequestParams) => Promise<Response>
    ) => async () => {
        setProcessingState(true);

        try {
            const requests = selectionModel.reduce((acc, consumerId) => {
                const serviceRequests = Object.entries(servicesState)
                    .reduce((acc, [kycTypeId, { checked, disabled }]) => {
                        if (checked && !disabled) {
                            return [
                                ...acc,
                                request(() => processKYC({
                                    consumerId,
                                    kycTypeId: Number(kycTypeId)
                                }))
                            ];
                        }

                        return acc;
                    }, [] as Promise<ConsumerKYCServiceResponse>[]);

                return [
                    ...acc,
                    ...serviceRequests
                ];
            }, [] as Promise<ConsumerKYCServiceResponse>[]);

            await Promise.allSettled(requests);
        } finally {
            closeDialog();
        }
    };

    useEffect(() => {
        if (!consumersState || ![
            ConsumerActionType.TriggerKYC
        ].includes(consumersState.type)) {
            return;
        }

        const { serviceStateRef, setServicesState } = kycServicesRef.current;

        setTriggerKYCServicesLoading(true);
        modalControllerRef.current.onOpen();

        const requestQueryParams = selectionModelRef.current.reduce((
            requestQueryMapper: RequestQueryMapper,
            consumerId
        ) =>
            requestQueryMapper.containsIn(
                Filters.consumerId,
                `${consumerId}`
            )
            , RequestQueryMapper.from());

        const kycServicesCollection = Object.keys(serviceStateRef.current);

        consumerKYCServiceStateRegistryRef.current = kycServicesCollection.reduce((kycServices, kycServiceTypeId) => ({
            ...kycServices,
            [kycServiceTypeId]: []
        }), {} as ConsumerKYCServiceTypeRegistry);

        requestRef.current(() =>
            getConsumerKYCSettings(
                requestQueryParams
                    .contains(
                        Paginate.perPage,
                        `${selectionModelRef.current.length * kycServicesCollection.length}`
                    )
                    .toString()
            ))
            .then((response: PaginateResourceResponse<ConsumerKYCService>) => {
                for (const { consumerId, kycTypeId } of fromPaginateResourceResponse(response)) {
                    consumerKYCServiceStateRegistryRef.current[kycTypeId].push(consumerId);
                }

                setServicesState(servicesState =>
                    Object.entries(consumerKYCServiceStateRegistryRef.current)
                        .reduce((accServicesState, [kycTypeId, consumerIds]) => ({
                            ...accServicesState,
                            [kycTypeId]: (consumerIds.length === selectionModelRef.current.length)
                                ? {
                                    checked: true,
                                    disabled: true
                                }
                                : serviceStateRef.current[Number(kycTypeId) as KYCServiceType]
                        }), servicesState)
                );
            })
            .finally(() => {
                setTriggerKYCServicesLoading(false);
            });
    }, [consumersState]);

    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);

    return {
        isProcessing,
        isTriggerKYCServicesLoading,
        open,
        servicesState,
        consumersState,
        handleDialogClose,
        handleProcessKYCService,
        onKYCServiceChange,
        getKYCServicesCollection
    };
};
