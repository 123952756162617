import { memo } from "react";
import type { GridRowParams } from '@mui/x-data-grid-premium';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useResourceContext } from "ui/widgets/Resource";

const Edit = ({ row }: GridRowParams) => {
    const { onOpen } = useResourceContext();

    const onMouseDown = () => onOpen(row);

    return (
        <ListItem disablePadding>
            <ListItemButton
                onMouseDown={onMouseDown}
            >
                <ListItemText primary="Edit" />
            </ListItemButton>
        </ListItem>
    );
};

export default memo(Edit);
