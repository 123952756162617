import { memo } from 'react';
import type { Dispatch, SetStateAction, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { UploadBox, type UploadProps } from "lib/upload";
import ActionableFile from './ActionableFile';
import FileUploadPlaceholder from './FileUploadPlaceholder';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
import type { UseDownloadable } from 'ui/molecules/Action/Downloadable';

type FileActionHandler = (
    file: string | File,
    setLoading?: Dispatch<SetStateAction<boolean>>
) => void | Promise<any>;

export type FileAreaProps =
    & UploadProps
    & Partial<UseDownloadable>
    & {
        readonly onRemoveFile?: FileActionHandler;
        readonly renderDeleteFileAction?: (file: string | File) => ReactNode;
        readonly TooltipProps?: Omit<TooltipProps, 'children'>;
    };

const FileArea = ({
    onRemoveFile,
    downloadFile,
    renderDeleteFileAction,
    TooltipProps = { title: null },
    ...props
}: FileAreaProps) => {
    const getFiles = () => {
        const { files } = props;

        return Array.isArray(files)
            ? files
            : [];
    };

    const renderFileUploadPlaceholderContent = () => {
        if (getFiles().length > 0) {
            return 'Upload new file';
        }

        return 'Upload the file';
    };

    return (
        <Box>
            <Box>
                {getFiles().map((file, index) => (
                    <ActionableFile
                        key={String(index)}
                        file={file}
                        renderDeleteFileAction={renderDeleteFileAction}
                        onRemoveFile={() => onRemoveFile?.(file)}
                        downloadFile={downloadFile}
                    />
                ))}
            </Box>
            <Tooltip
                arrow
                {...TooltipProps}
            >
                <Box component='span'>
                    <UploadBox
                        sx={{
                            width: '100%',
                            color: 'primary.main'
                        }}
                        placeholder={(
                            <FileUploadPlaceholder>
                                {renderFileUploadPlaceholderContent()}
                            </FileUploadPlaceholder>
                        )}
                        {...props}
                    />
                </Box>
            </Tooltip>
        </Box>
    );
};

export default memo(FileArea);
