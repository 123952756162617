import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import type { TabIndex, TabProps } from '../types';
import TabLayout from '../TabLayout';
import ConsumerDetails from '../ConsumerDetails';
import DuplicateEmails from './DuplicateEmails';
import AddDuplicateEmail from './AddDuplicateEmail';

const ManageDuplicates = (props: TabProps<TabIndex.ManageDuplicates>) => {
    const [isLoading, setLoading] = useState(false);
    const { getTabPayload } = props;

    return (
        <TabLayout
            getTabPayload={getTabPayload}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    columnGap: 2
                }}
            >
                <ConsumerDetails
                    {...getTabPayload()}
                />
                <DuplicateEmails
                    {...props}
                    requestLoading={[isLoading, setLoading]}
                />
            </Box>
            <Divider
                sx={{
                    my: 2
                }}
            />
            <AddDuplicateEmail
                {...props}
                isRequestLoading={isLoading}
            />
        </TabLayout>
    );
};

export default memo(ManageDuplicates);
