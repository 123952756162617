export interface ExtendedRequestInfo<T> extends Omit<RequestInit, "body"> {
  readonly body?: T;
  readonly baseUrl?: string;
  readonly abortController?: AbortController;
  readonly serializer?: FormSerializers;
  getBody?: <T>(body?: T, csrfToken?: string) => object;
  getHeaders?: (headers?: object) => HeadersInit;
}

export type DownloadableRequestInfo<T> = ExtendedRequestInfo<T> & {
  readonly fileName: string;
};

export enum ApiState {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum FormSerializers {
  FormData,
  Json
}
