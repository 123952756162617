import {
    memo,
    useState,
    useRef,
    type PropsWithChildren,
    type ReactNode,
    type FC
} from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import { copyToClipboard } from 'util/clipboard';
import ClipboardTrigger from './ClipboardTrigger';

type Props = BoxProps & {
    readonly renderTooltip?: (clipboardContent: string) => ReactNode;
};

const Clipboard: FC<PropsWithChildren<Props>> = ({ children, sx, renderTooltip }) => {
    const targetRef = useRef<HTMLElement>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const [clipboardContent, setClipboardContent] = useState('');

    const handleCopyToClipboard = () => {
        const currentTarget = targetRef.current!;

        copyToClipboard(currentTarget)
            .then(() =>
                setClipboardContent(currentTarget.textContent ?? '')
            );
    };

    const resetClipboardContent = () => {
        timeoutRef.current = setTimeout(() => {
            setClipboardContent('');
        }, 1000);
    };

    const onOpenTooltip = () =>
        clearTimeout(timeoutRef.current);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                ...sx
            }}
        >
            <Box
                ref={targetRef}
            >
                {children}
            </Box>
            <ClipboardTrigger
                title={renderTooltip!(clipboardContent)}
                onOpen={onOpenTooltip}
                onClose={resetClipboardContent}
                onClick={handleCopyToClipboard}
            />
        </Box>
    );
};

Clipboard.defaultProps = {
    renderTooltip: (clipboardContent: string) =>
        clipboardContent
            ? 'Copied!'
            : 'Copy to clipboard'
};

export default memo(Clipboard);