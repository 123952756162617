import { BulkKYCStepperStep, type MainAreaProps } from '../types';
import { Upload } from 'lib/upload';
import useFileUploader from '../useFileUploader';
import Typography from '@mui/material/Typography';

const ArchiveFileUploader = ({ value, onChange }: MainAreaProps<BulkKYCStepperStep.UploadVerificationArchive>) => {
    const {
        handleDrop,
        renderHelperText
    } = useFileUploader({
        value,
        onChange,
        preview: '/assets/icons/files/ic_zip.svg'
    });

    return (
        <Upload
            file={value}
            multiple={false}
            onDrop={handleDrop}
            accept={{
                'application/vnd.rar': ['.rar'],
                'application/zip': ['.zip']
            }}
            placeholderProps={{
                titleSlot: (
                    <Typography gutterBottom variant="h6">
                        Drop or Select KYC archive file
                    </Typography>
                )
            }}
            helperText={renderHelperText()}
        />
    );
};

export default ArchiveFileUploader;
