import { MerchantsMethodsPivot } from "features/pivots/types";
import { useEffect, useRef, useState } from "react";
import { useModal } from "ui/atoms/Modal";

type ProcessingMethod = Pick<
    MerchantsMethodsPivot,
    | 'coreId'
    | 'shouldKyc'
>;

export default function useConfirmationDialog() {
    const { open, onOpen, onClose } = useModal();

    const [isProcessing, setProcessing] = useState(false);

    const dialogTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const processingMethodRef = useRef<ProcessingMethod | null>(null);

    const promiseResolversRef = useRef<{
        readonly resolve: (value: boolean) => void;
        readonly reject: (reason?: any) => void;
    } | null>(null);

    const openConfirmationDialog = ({ coreId, shouldKyc }: ProcessingMethod) => {
        clearTimeout(dialogTimeoutRef.current);

        processingMethodRef.current = {
            coreId,
            shouldKyc
        };

        onOpen();

        return new Promise<boolean>((resolve, reject) => {
            promiseResolversRef.current = {
                resolve,
                reject
            };
        });
    };

    const closeConfirmationDialog = () => {
        onClose();

        dialogTimeoutRef.current = setTimeout(() => {
            promiseResolversRef.current = processingMethodRef.current = null;
            setProcessing(false);
        }, 500);
    };

    const onConfirm = async () => {
        const { resolve } = promiseResolversRef.current!;

        resolve(true);

        setProcessing(true);
    };

    const onCancel = () => {
        const { reject } = promiseResolversRef.current!;

        reject('Cancelled by user');
    };

    useEffect(() => () => {
        clearTimeout(dialogTimeoutRef.current);
    }, []);

    return {
        openConfirmationDialog,
        closeConfirmationDialog,
        onConfirm,
        onCancel,
        open,
        processingMethodRef,
        isProcessing
    };
};
