import { memo } from "react";
import Box from "@mui/material/Box";

const MarkAsKYCTitle = () => (
    <Box>
        <Box
            sx={{
                fontSize: 20,
                fontWeight: 600
            }}
        >
            Manual verification
        </Box>
        <Box
            sx={{
                color: '#637381'
            }}
        >
            In order to manually mark as verified, files must be uploaded as (.zip/.rar).
        </Box>
    </Box>
);

export default memo(MarkAsKYCTitle);
