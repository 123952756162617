import { useTypedSelector } from "hooks";
import { selectTransactionCurrencies } from "features/general/selectors";
import useMerchantActions from "features/merchants/useMerchantLedgerActions";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/merchants";
import { Paginate } from "consts/table";
import { PER_PAGE_SIZE } from "consts/transactions";
import { useMerchantContext } from "../../../MerchantProvider";

export default function useLedgerActions() {
    const transactionCurrencies = useTypedSelector(selectTransactionCurrencies);
    const merchant = useMerchantContext();

    const {
        getMerchantLedgers,
        downloadCsv
    } = useMerchantActions();

    return {
        getResource: (searchQueryParams?: string) => getMerchantLedgers(
            RequestQueryMapper.from(searchQueryParams)
                .containsIn(Filters.merchantId, String(merchant.coreId))
                .contains(Paginate.perPage, transactionCurrencies.length || PER_PAGE_SIZE)
                .toString()
        ),
        downloadCsv: (searchQueryParams?: string) => downloadCsv(
            RequestQueryMapper.from(searchQueryParams)
                .containsIn(Filters.merchantId, String(merchant.coreId))
                .toString()
        )
    };
};
