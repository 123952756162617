import {
    DomainRepository,
    type Dispatcher
} from "hooks/useDomainState";
import {
    MerchantUsersActionType,
    type MerchantUsersRepository,
    type MerchantUsersRepositoryState
} from "./types";
import type { MerchantUsersRepository as IMerchantUsersRepository } from "../../repository";

export class Repository extends DomainRepository<
    MerchantUsersRepositoryState,
    MerchantUsersActionType
> implements MerchantUsersRepository {
    public constructor(
        public readonly state: MerchantUsersRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantUsersActionType>,
        private readonly merchantRepository: IMerchantUsersRepository,
    ) {
        super(state, dispatch);
    }

    public get merchantUsers() {
        return this.merchantRepository.merchantUsers;
    }

    public get isMerchantUsersLoading() {
        return this.merchantRepository.isMerchantUsersLoading;
    }

    public get isMerchantUsersUninitialized() {
        return this.merchantRepository.isMerchantUsersUninitialized;
    }

    public async fetchMerchantUsers(): Promise<void> {
        return this.merchantRepository.fetchMerchantUsers();
    }

    public reset(): void {
        this.dispatch({
            type: MerchantUsersActionType.Reset,
            payload: undefined
        });
    }
}
