import { memo } from "react";
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import { RollingReserve, type PayableRollingReserveCalculationDescriptionProps } from "./types";
import { fNumber } from "util/formaters";

const PayableRollingReserveCalculationDescription = ({ isLoading, ...props }: PayableRollingReserveCalculationDescriptionProps) => {
    const rollingReserveRetained = Number(props.rollingReserveRetained);
    const rollingReservePaid = Number(props.rollingReservePaid);
    const rollingReserveRetainedCap = props[RollingReserve.RetainedCap];
    const rollingReserved = props[RollingReserve.Balance];
    const payoutCurrency = props[RollingReserve.PayoutCurrency];

    const getValue = (value: string | number, formatPattern = '0,0.000') => `${fNumber(value, formatPattern)} ${payoutCurrency}`;

    if (isLoading) {
        return (
            <Skeleton
                width='100%'
                height={40}
            />
        );
    }

    return (
        <>
            {props.isPaid && <></>}
            {!props.isPaid && (
                <Typography
                    variant='caption'
                    color='textSecondary'
                >
                    <strong>{props.merchantName}</strong> &nbsp;
                    has a Rolling Reserve Retained CAP of &nbsp;
                    <strong>{getValue(rollingReserveRetainedCap, '0,0')}</strong> &nbsp;
                    with a current Rolling Reserve Balance of &nbsp;
                    <strong>{getValue(rollingReserved)}</strong>. &nbsp;
                    With the Rolling Reserve Retained from this payout &nbsp;
                    <strong>{getValue(rollingReserveRetained)}</strong>, &nbsp;
                    it will take you to &nbsp;
                    <strong>{getValue((rollingReserved + rollingReserveRetained) - rollingReservePaid)}</strong>.
                </Typography>
            )}
        </>
    );
};

export default memo(PayableRollingReserveCalculationDescription);
