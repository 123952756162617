export const enum ValueStrategy {
    Default = 'Default',
    Empty = 'Empty',
    SelectedTo = 'SelectedTo',
    Last7Days = 'Last7Days',
}

export type TValueStrategy<TValue> = {
    readonly [key in ValueStrategy]?: IValueProvider<TValue>;
}

interface IValueProvider<TValue> {
    getInitialValue(initialValues: Array<string>): TValue;
    handleChangeValue(value: TValue): TValue;
    getDefaultValue(): TValue;
}

export abstract class FilterValueProvider<TValue> implements IValueProvider<TValue> {
    public constructor(
        private defaultValueGetter: () => TValue
    ) { }

    public getDefaultValue() {
        return this.defaultValueGetter();
    }

    public abstract getInitialValue(initialValues: Array<string>): TValue;
    public abstract handleChangeValue(value: TValue): TValue;

}
