import { memo } from "react";
import type { GridRowParams } from '@mui/x-data-grid-premium';
import { useTypedSelector } from "hooks";
import { ConfirmatableDialog, type ConfirmatableDialogProps } from "ui/molecules/Dialog";
import useActions from "features/users/useActions";
import { selectIsUsersLoading } from "features/users/selectors";
import DialogContent from "./DialogContent";

type Props = Pick<
    ConfirmatableDialogProps,
    | 'open'
    | 'onClose'
> & GridRowParams;

const Dialog = ({ open, onClose, ...gridRowParams }: Props) => {
    const isLoading = useTypedSelector(selectIsUsersLoading);

    const { deleteUser } = useActions();

    const onConfirm = async () => deleteUser(gridRowParams.row.coreId);

    const onCancel = () => onClose?.();

    const dialogContent = {
        dialogContent: [
            <DialogContent
                {...gridRowParams}
            />
        ],
        actionContent: 'Delete user'
    };

    return (
        <ConfirmatableDialog
            content={[
                dialogContent,
                dialogContent
            ]}
            cancelActionContentSlot='Cancel'
            isProcessing={isLoading}
            open={open}
            checkPredicate={() => false}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    );
};

export default memo(Dialog);
