import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import type { ElasticSearchResourceResponse } from "types";
import type { QueryBuilderEntityProps } from "./types";
import Resource from "ui/layouts/Resource";
import QueryBuilder, {
    FormFields,
    type FormValues,
    type QueryBuilderForm
} from "ui/forms/QueryBuilder";
import { useSimpleRequest } from "hooks/useRequest";
import QueryBuilderPaginator from "./QueryBuilderPaginator";

const QueryBuilderEntity = ({ label, query }: QueryBuilderEntityProps) => {
    const [data, setData] = useState<ElasticSearchResourceResponse<string>['data']>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const request = useSimpleRequest({
        setLoading
    });

    const onSubmit = async (formValues: FormValues) => {
        await request(() => query(`?${formValues[FormFields.SearchQuery]}`), {
            onSuccess: ({ data }: ElasticSearchResourceResponse<string>) => setData(data)
        });
    };

    const helpers = {
        data,
        onSubmit
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    const renderChildren = useCallback((queryBuilderForm: QueryBuilderForm) => (
        <QueryBuilderPaginator
            {...queryBuilderForm}
            {...helpersRef.current}
        />
    ), []);

    useEffect(() => {
        helpersRef.current.onSubmit({ [FormFields.SearchQuery]: '' });
    }, []);

    return (
        <Resource
            title={`Query builder | ${label}`}
            breadcrumbsProps={{
                heading: 'Query builder',
                links: [
                    { name: String(label) }
                ]
            }}
        >
            <QueryBuilder
                isLoading={isLoading}
                label={label}
                onSubmit={onSubmit}
                renderChildren={renderChildren}
            />
        </Resource>
    );
};

export default memo(QueryBuilderEntity);
