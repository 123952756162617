import { memo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import type { MerchantAccountMerchantSetupForm } from "./types";
import IpnSetup from "./IpnSetup";
import ComplianceSetup from "./ComplianceSetup";
import FraudProtectionSetup from "./FraudProtectionSetup";

const AccountMerchantSetup = (props: MerchantAccountMerchantSetupForm) => (
    <Box>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
            }}
        >
            <IpnSetup
                {...props}
            />
            <ComplianceSetup
                {...props}
            />
        </Box>
        <Divider
            sx={{
                my: 2
            }}
        />
        <FraudProtectionSetup
            {...props}
        />
    </Box>
);

export default memo(AccountMerchantSetup);
