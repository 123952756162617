import { memo } from "react";
import Box from "@mui/material/Box";
import Tabs from "ui/atoms/Tabs";
import type { MerchantDetailProps } from "../types";
import { MerchantAccountSettingsTab } from "./MerchantAccountSettingsProvider/types";
import { TabLabel } from "ui/widgets/StatefulTabs";
import MerchantAccountSettingsProvider, { useMerchantAccountSettingsContext } from "./MerchantAccountSettingsProvider";
import Ledger from "./Ledger";
import ApiSettings from "./ApiSettings";
import MerchantSetup from "./MerchantSetup";

const MerchantAccountSettings = (merchantDetailProps: MerchantDetailProps) => {
    const {
        activeTab,
        tabManager,
        message,
        selectState,
        onChange
    } = useMerchantAccountSettingsContext();

    const tabLabelProps = {
        message,
        tabManager,
        selectState
    };

    return (
        <Box>
            <Tabs
                model={[
                    {
                        key: MerchantAccountSettingsTab.Ledger,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantAccountSettingsTab.Ledger}
                            />
                        ),
                        component: (
                            <Ledger />
                        ),
                        sxProps: {
                            padding: "24px 0px"
                        }
                    },
                    {
                        key: MerchantAccountSettingsTab.ApiSettings,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantAccountSettingsTab.ApiSettings}
                            >
                                API Settings
                            </TabLabel>
                        ),
                        component: (
                            <ApiSettings
                                {...merchantDetailProps}
                            />
                        )
                    },
                    {
                        key: MerchantAccountSettingsTab.MerchantSetup,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantAccountSettingsTab.MerchantSetup}
                            >
                                Merchant Setup
                            </TabLabel>
                        ),
                        component: (
                            <MerchantSetup
                                {...merchantDetailProps}
                            />
                        )
                    }
                ]}
                TabListContainerProps={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
                TabListProps={{
                    'aria-label': 'merchant account settings',
                    variant: 'scrollable',
                    scrollButtons: 'auto'
                }}
                onChange={onChange}
            />
        </Box>
    );
};

export default memo((props: MerchantDetailProps) => (
    <MerchantAccountSettingsProvider>
        <MerchantAccountSettings
            {...props}
        />
    </MerchantAccountSettingsProvider>
));
