import { FilterRenderTypes, type Filter } from "ui/widgets/Filters/types";
import type { AutocompleteProps } from "ui/widgets/Filters/components";
import dropdown from "./dropdown";

const filterComparatorStrategyRegistry = new Map<
    FilterRenderTypes,
    (
        filter: Filter<AutocompleteProps>,
        value: Record<string, unknown>
    ) => boolean
>([
    [FilterRenderTypes.Dropdown, dropdown]
]);

export default filterComparatorStrategyRegistry;
