import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export const useAccountValidationSchema = () =>
    useMemo(() => object().shape({
        [FormField.Currency]: string()
            .required()
            .max(3)
            .label('Currency'),
        [FormField.Iban]: string()
            .required()
            .max(34)
            .label('IBAN'),
        [FormField.Swift]: string()
            .required()
            .max(11)
            .label('SWIFT'),
        [FormField.Vat]: string()
            .optional()
            .max(255)
            .label('VAT')
    }), []);

export const useCryptoAccountValidationSchema = () =>
    useMemo(() => object().shape({
        [FormField.Currency]: string()
            .required()
            .max(4)
            .label('Currency'),
        [FormField.Address]: string()
            .required()
            .max(255)
            .label('Address'),
        [FormField.Chain]: string()
            .required()
            .max(255)
            .label('Chain')
    }), []);
