import { createContext, type PropsWithChildren } from "react";
import type { ContextPublisherContextType } from "./types";
import useContextPublisher from "./useContextPublisher";

export const ContextPublisherContext = createContext<ContextPublisherContextType>({} as ContextPublisherContextType);

const ContextPublisher = ({ children }: PropsWithChildren) => (
    <ContextPublisherContext.Provider
        value={useContextPublisher()}
    >
        {children}
    </ContextPublisherContext.Provider>
);

export default ContextPublisher;
