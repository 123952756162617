import { type HTMLAttributes, memo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import type { AutocompleteRenderOptionState } from "@mui/material/Autocomplete";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import type { MerchantPayoutAbstractAccount } from "features/merchants/types";
import { getAccountType } from "features/merchants/helpers";

type Props = {
    readonly props: HTMLAttributes<HTMLLIElement>;
    readonly option: MerchantPayoutAbstractAccount;
    readonly state: AutocompleteRenderOptionState;
};

const PayoutAccountOption = ({ props, option, state }: Props) => (
    <MenuItem
        {...props}
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
        }}
        selected={state.selected}
    >
        {[<AccountBalanceIcon />, <CurrencyBitcoinIcon />][getAccountType(option)]}
        <Box
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}
        >
            {option.address || option.iban}
            ({['IBAN', 'Crypto'][getAccountType(option)]})
        </Box>
    </MenuItem>
);

export default memo(PayoutAccountOption);
