import { memo, type ReactNode } from "react";
import Box from "@mui/material/Box";
import Chip, { type ChipProps } from "@mui/material/Chip";
import Card, { type CardProps } from "ui/atoms/Card";
import Skeleton from "@mui/material/Skeleton";

export type Props = CardProps & {
    readonly labelSlot: ReactNode;
    readonly amountSlot: ReactNode;
    readonly isLoading?: boolean;
    readonly currencySlot?: ReactNode;
    readonly actionSlot?: ReactNode;
    readonly footerSlot?: ReactNode;
    readonly chipProps?: ChipProps;
};

const SummaryCard = ({
    isLoading,
    labelSlot,
    amountSlot,
    currencySlot,
    actionSlot,
    footerSlot,
    chipProps,
    ...restCardProps
}: Props) => {
    const renderChip = () => {
        if (isLoading) {
            return (
                <Skeleton width='20%' />
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {Boolean(chipProps?.label) && (
                    <Chip
                        {...chipProps}
                    />
                )}
                {actionSlot}
            </Box>
        );
    };

    return (
        <Card
            {...restCardProps}
            sx={{
                width: 203,
                p: 2,
                fontFamily: 'var(--manrope-font)',
                ...restCardProps.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1
                }}
            >
                <Box
                    sx={{
                        fontSize: 14
                    }}
                >
                    {labelSlot}
                </Box>
                {renderChip()}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: .5,
                    fontWeight: 'bolder',
                    fontSize: 24,
                    lineHeight: '33px',
                    mt: 1
                }}
            >
                {isLoading
                    ? (
                        <Skeleton width='70%' />
                    )
                    : (
                        <>
                            <Box>{amountSlot}</Box>
                            {Boolean(currencySlot) && (
                                <Box>
                                    {currencySlot}
                                </Box>
                            )}
                        </>
                    )}
            </Box>
            {footerSlot}
        </Card>
    );
};

export default memo(SummaryCard);
