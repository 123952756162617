import { memo } from "react";
import Box from "@mui/material/Box";
import {
    GridToolbarQuickFilter,
    type GridToolbarQuickFilterProps
} from "@mui/x-data-grid-premium";
import ActiveInactiveAutocomplete from "./ActiveInactiveAutocomplete";

const ActiveInactiveSelector = (props: GridToolbarQuickFilterProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
        }}
    >
        <ActiveInactiveAutocomplete />
        <GridToolbarQuickFilter
            {...props}
        />
    </Box>
);

export default memo(ActiveInactiveSelector);
