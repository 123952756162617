import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import IncrementTaxes, {
    IncrementTaxesBootstrapData,
    useIncrementTaxesForm
} from "ui/forms/IncrementTaxes"
import useFiltersModel from "ui/widgets/Filters/hooks/useFiltersModel";
import { Filters } from "consts/merchantPayouts";
import { getInitialValues } from "ui/forms/IncrementTaxes/validators";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import { selectPaymentMethodsTags } from "features/paymentMethods/selectors";
import { useTypedSelector } from "hooks";
import { useCreateTaxableEntityContext } from "../Taxable";
import { BasicDialog } from "ui/molecules/Dialog";

const AddIncrementExchangeRateAction = () => {
    const { open, onClose } = useCreateTaxableEntityContext();

    const formId = "incrementTaxesForm";

    const paymentMethodsTags = useTypedSelector(selectPaymentMethodsTags);

    const { postMerchantPayoutIncrementTax } = useMerchantPayoutsActions();

    const { getFilterOptions, isLoading: isBootstrapDataLoading } = useFiltersModel();

    const incrementTaxesForm = useIncrementTaxesForm({
        initialValues: getInitialValues(),
        isInitialValid: false,
        onResolve: () => {
            incrementTaxesForm.resetForm();
            onClose();
        },
        onSaveOrCreate: (values) =>
            postMerchantPayoutIncrementTax({
                merchantId: values.merchantId?.id,
                taxName: values.taxName?.name,
                tag: values.tag?.id as string,
                refColFrom: values.refColFrom?.name,
                refColFromMinSum: values.refColFromMinSum,
                currentIncMultiplierForEachRefColumn: values.currentIncMultiplierForEachRefColumn
            }),
    });

    const getBootstrapData = () =>
        [
            Filters.merchantId,
            Filters.taxName,
            Filters.refColFrom
        ].reduce(
            (bootstrapData, bootstrapDataToken) => ({
                ...bootstrapData,
                [bootstrapDataToken]: getFilterOptions(bootstrapDataToken).options ?? [],
            }),
            {
                tag: paymentMethodsTags,
            } as IncrementTaxesBootstrapData
        );

    const handleClose = () => {
        onClose();
        incrementTaxesForm.resetForm();
    };

    return (
        <BasicDialog
            isOpen={open}
            isCanClose={!incrementTaxesForm.isSubmitting}
            onClose={handleClose}
            titleSlot='Create new increment tax'
            actionsSlot={(
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    form={formId}
                    loading={incrementTaxesForm.isSubmitting}
                    disabled={!incrementTaxesForm.isValid}
                >
                    Create
                </LoadingButton>
            )}
        >
            <IncrementTaxes
                {...incrementTaxesForm}
                id={formId}
                bootstrapData={getBootstrapData()}
                isBootstrapDataLoading={isBootstrapDataLoading}
            />
        </BasicDialog>
    );
};

export default memo(AddIncrementExchangeRateAction);
