import { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TableHead = () => (
    <MuiTableHead>
        <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Duplicate emails</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Options</TableCell>
        </TableRow>
    </MuiTableHead>
);

export default memo(TableHead);
