import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { MerchantsMethodsPivot } from "features/pivots/types";

type Props = {
    readonly merchantMethod: MerchantsMethodsPivot;
    readonly properties: ReadonlyArray<[
        keyof MerchantsMethodsPivot,
        ((property: MerchantsMethodsPivot[keyof MerchantsMethodsPivot]) => string)?
    ]>;
};

const InfoUnit = ({ children, properties, merchantMethod }: PropsWithChildren<Props>) => (
    <Box>
        <Typography
            variant='body1'
        >
            {children}
        </Typography>
        <Box
            sx={{
                fontWeight: 'bold'
            }}
        >
            {properties.map(([property, formatter = _ => _]) =>
                formatter(merchantMethod[property]))
                    .join(', ')}
        </Box>
    </Box>
);

export default memo(InfoUnit);
