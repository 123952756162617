import { UiDateTimeFormatter } from "util/formaters";
import { Environment, FilterValueObject } from "../types";
import type { Moment } from "moment";
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

export default class DateRangeValue extends FilterValueObject<DateRange<Moment>, Moment | null> {
    // public toSerialized(environment?: Environment): string[] {
    //     const serializedValue = this.value
    //         .map(this.setTimeOfDate.bind(this))
    //         .map(date => date?.format(this.getEnvironmentFormatter(environment)) ?? null);

    //     if (serializedValue.every(date => !date)) {
    //         return [];
    //     }

    //     return serializedValue.map(String);
    // }

    public serialize(value: Moment | null, environment?: Environment | undefined): string {
        return String(value?.format(this.getEnvironmentFormatter(environment)) ?? null);
    }

    public toArray() {
        const dateRange = this.value
            .map(this.setTimeOfDate.bind(this))
            .map(date => date?.isValid() ? date : null);

        if (dateRange.every(date => !date)) {
            return [] as unknown as DateRange<Moment>;
        }

        return dateRange as DateRange<Moment>;
    }

    public isEmpty(_: DateRange<Moment> | Moment): boolean {
        return false;
    }

    private getEnvironmentFormatter(environment = Environment.Browser): string {
        return {
            [Environment.Browser]: UiDateTimeFormatter.Read,
            [Environment.Server]: UiDateTimeFormatter.Read
        }[environment];
    }

    private setTimeOfDate(date: Moment | null, modifier: number) {
        if (modifier <= 0) {
            return date?.startOf('day');
        }

        return date?.endOf('day');
    }
}
