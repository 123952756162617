import type { ReactElement, ReactNode } from "react";

export const enum MenuActions {
    SettlementXLS,
    TransactionsCSV,
    StillPendingSettlementCSV,
    ManageFileTaxes,
    Delete,
    PreviewSettlementXLS,
    GenerateSettlementXLS,
    ManageTaxes
}

export type ActionMenuItem = {
    readonly handler: () => Promise<void>;
    readonly icon: ReactElement;
    readonly label: ReactNode;
    readonly optionType: MenuActions;
};

export type ActionMenuItemDialogable = ActionMenuItem & {
    readonly dialogSlot?: ReactNode;
};

export type ActionHookArg<TRow, THandler> = {
    readonly row: TRow;
    readonly handler?: THandler;
};

export type ActionRequestHandlerType = 'menu' | 'dialog';

export type ActionRequestHandlers<TMenu = Function, TDialog = Function> = {
    readonly menu?: TMenu;
    readonly dialog?: TDialog;
};

export type ActionMenuArg<T> = {
    readonly row: T;
    readonly requestMappings: Map<MenuActions, ActionRequestHandlers>;
    readonly getMenuItems: (row: T) => Array<MenuActions>;
};

export type ActionRepositoryStrategy = () => ActionMenuItemDialogable;
