import { memo } from "react";
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import type { BootstrapableForm, Option } from "types";
import type { Files } from "features/merchantPayouts/types";
import type { ChangePayload, FilterChangePayload, FiltersChangeHandler } from "ui/widgets/Filters";
import { Autocomplete, DateRange } from "ui/widgets/Filters";
import { Filters } from "consts/merchantPayouts";
import { getFormInputProps } from "util/forms";
import { multiColumnFilterFactory } from "util/filters";
import type { DateRange as DateRangeType } from "@mui/x-date-pickers-pro";
import type { Moment } from "moment";

export type FormValues =
    & Partial<
        Record<
            keyof Pick<
                Files,
                | 'MOR'
                | 'via'
                | 'merchantId'

            >,
            Option
        >
    >
    & {
        readonly ['dateFrom|dateTo']: DateRangeType<Moment>;
    };

export type FilesBootstrapData = Record<
    keyof Pick<
        Files,
        | 'MOR'
        | 'via'
        | 'merchantId'
    >,
    Option[]
>;

type Props = ReturnType<typeof useFormik<FormValues>> & Pick<
    BootstrapableForm<
        FormValues,
        FilesBootstrapData
    >,
    | 'id'
    | 'bootstrapData'
    | 'isBootstrapDataLoading'
>;

const FilesForm = ({
    id,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    touched,
    errors,
    bootstrapData,
    isBootstrapDataLoading
}: Props) => {
    const onChange: FiltersChangeHandler = ({ type, payload }: ChangePayload<string, FilterChangePayload>) => {
        setFieldValue(type, payload);
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={id}
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                flexWrap: 'wrap'
            }}
        >
            <DateRange
                id={multiColumnFilterFactory([
                    Filters.dateFrom,
                    Filters.dateTo
                ])}
                name={multiColumnFilterFactory([
                    Filters.dateFrom,
                    Filters.dateTo
                ])}
                onChange={onChange}
                value={values["dateFrom|dateTo"]}
                startProps={{
                    label: 'Date From'
                }}
                endProps={{
                    label: 'Date To'
                }}
            />
            <Autocomplete
                {...getFormInputProps(Filters.MOR, { touched, errors })}
                options={bootstrapData.MOR}
                loading={isBootstrapDataLoading}
                id={Filters.MOR}
                name={Filters.MOR}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.MOR}
                label={`${Filters.MOR}*`}
                placeholder={Filters.MOR}
            />
            <Autocomplete
                {...getFormInputProps(Filters.via, { touched, errors })}
                options={bootstrapData.via}
                loading={isBootstrapDataLoading}
                id={Filters.via}
                name={Filters.via}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.via}
                label='Via'
                placeholder={Filters.via}
            />
            <Autocomplete
                {...getFormInputProps(Filters.merchantId, { touched, errors })}
                options={bootstrapData.merchantId}
                loading={isBootstrapDataLoading}
                id={Filters.merchantId}
                name={Filters.merchantId}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.merchantId}
                label='Merchant Id'
                placeholder={Filters.merchantId}
            />
        </Box>
    );
};

export default memo(FilesForm);
