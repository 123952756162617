import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type { PaymentMethodsResponse } from "./types";
import { getPaymentMethods } from "./api";
import type { ThunkReturnType } from "types";
import { selectIsNotMor } from "features/auth/selectors";

export const getPaymentMethodsThunk: AsyncThunk<
    ThunkReturnType<PaymentMethodsResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetPaymentMethods]),
    payloadCreator(getPaymentMethods),
    {
        condition: (_, { getState }) => {
            const { paymentMethods } = getState() as RootState;

            return (
                selectIsNotMor(getState()) &&
                !paymentMethods.paymentMethodsSlice &&
                ![ApiState.Pending].includes(paymentMethods.paymentMethodsLoadingState)
            );
        }
    }
);
