import { memo } from "react";
import { ResourceProvider } from "ui/widgets/Resource";
import MerchantUsers, { MerchantUserToolbar } from "ui/organizms/Merchants/Merchants/MerchantUsers";
import useMerchantUsersResource from "./useMerchantUsersResource";
import useMerchantUsers from "./useMerchantUsers";
import { useSearchParamsNoop, useUserPreferencesNoop } from "ui/widgets/Table";
import { useTypedSelector } from "hooks";
import { selectMerchantOptions } from "features/merchants/selectors";
import { getOptionById, getOptionName, getOptions } from "util/option";
import Autocomplete from "ui/atoms/Autocomplete";
import type { useFormik } from "formik";
import { UserResponse } from "features/users/types";
import MerchantProvider, { useMerchantContext } from "../MerchantProvider";
import useMerchantUsersActions from "./useMerchantUsersActions";

const MerchantUsersTab = () => {
    const merchant = useMerchantContext();
    const merchants = useTypedSelector(selectMerchantOptions);

    const { updateOrAddMerchantByIdUsers } = useMerchantUsersActions();

    return (
        <ResourceProvider>
            <MerchantUsers
                {...useMerchantUsers()}
                useResource={useMerchantUsersResource}
                Toolbar={MerchantUserToolbar}
                LayoutProps={{
                    sx: {
                      m: 0
                    }
                  }}
                DetailProps={{
                    initialValues: {
                        merchants: getOptions(
                            merchants,
                            [merchant.coreId]
                        )
                    },
                    merchantsSlot: (
                        <Autocomplete
                            fullWidth
                            disablePortal
                            disabled
                            size='medium'
                            layout={null}
                            options={merchants}
                            getOptionLabel={getOptionName}
                            value={getOptionById(merchants, merchant.coreId) ?? null}
                            TextFieldProps={{
                                label: 'Merchants',
                                required: true
                            }}
                            sx={{
                                mt: 2
                            }}
                        />
                    ),
                    getActionButtonProps: ({ isValid }: ReturnType<typeof useFormik>) => ({
                        disabled: !isValid
                    }),
                    onUserUpdateOrCreate: ({ data }: UserResponse) => updateOrAddMerchantByIdUsers(data)
                }}
                adapters={{
                    useSearchParams: useSearchParamsNoop,
                    useUserPreferences: useUserPreferencesNoop
                }}
            />
        </ResourceProvider>
    );
};

export default memo(() => (
    <MerchantProvider>
        <MerchantUsersTab />
    </MerchantProvider>
));
