import { memo, type FC } from "react";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { Filters } from "consts/merchants";
import { FilterVariant } from "consts/filters";
import { FeatureFiltersProvider, FilterRenderTypes, type FeatureFiltersProviderProps } from "ui/widgets/Filters";
import MerchantPaymentMethodsWithFilters from "./MerchantPaymentMethodsWithFilters";
import useMerchantPaymentMethods from "./useMerchantPaymentMethods";

const MerchantPaymentMethods: FC<Partial<FeatureFiltersProviderProps>> = ({ filtersConfig }) => {
    const { repository } = useMerchantPaymentMethods();

    return (
        <TabLayout
            isLoading={repository.isMerchantMethodsLoading}
            isEmpty={repository.isMerchantMethodsEmpty}
            isError={repository.isMerchantMethodsError}
        >
            <FeatureFiltersProvider
                filtersConfig={filtersConfig!}
            >
                <MerchantPaymentMethodsWithFilters />
            </FeatureFiltersProvider>
        </TabLayout>
    );
};

MerchantPaymentMethods.defaultProps = {
    filtersConfig: [
        {
            filterId: Filters.paymentMethodTag,
            filterVariant: FilterVariant.MultiOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method Tag',
                    multiple: true,
                    InputProps: {
                        placeholder: 'Select tag'
                    }
                }
            ]
        },
        {
            filterId: Filters.paymentMethodPSP,
            filterVariant: FilterVariant.MultiOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method PSP',
                    multiple: true,
                    InputProps: {
                        placeholder: 'Select PSP'
                    }
                }
            ]
        },
        {
            filterId: Filters.MOR,
            filterVariant: FilterVariant.MultiOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Merchant Of Records',
                    multiple: true,
                    InputProps: {
                        placeholder: 'Select MORs'
                    }
                }
            ]
        }
    ]
};

export default memo(MerchantPaymentMethods);
