import { memo } from 'react';
import Screen from 'ui/layouts/Screen';
import MerchantPayouts from 'ui/organizms/MerchantPayouts/MerchantPayouts';

const MerchantPayoutsScreen = () => (
    <Screen
        title='Merchant Payouts'
    >
        <MerchantPayouts />
    </Screen>
);

export default memo(MerchantPayoutsScreen);
