import { memo, type ReactNode, type PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import Box, { type BoxProps } from "@mui/material/Box";
import { TabLayout } from "ui/widgets/StatefulTabs";

type Props = {
    readonly title: ReactNode;
    readonly sx?: BoxProps['sx'];
};

const AccountMerchantSetupLayout = ({ title, sx, children }: PropsWithChildren<Props>) => (
    <TabLayout
        title={(
            <Typography
                sx={{
                    fontWeight: 'bold'
                }}
            >
                {title}
            </Typography>
        )}
    >
        <Box
            sx={{
                display: 'grid',
                gap: 2,
                ...sx
            }}
        >
            {children}
        </Box>
    </TabLayout>
);

export default memo(AccountMerchantSetupLayout);
