import type { GridEventListener } from "@mui/x-data-grid";
import { useResourceContext } from "ui/widgets/Resource";
import useMerchantUsersActions from './useMerchantUsersActions';

export default function useMerchantUsersResource() {
    const { onOpen } = useResourceContext();

    const onRowClick: GridEventListener<'rowClick'> = ({ row }) => onOpen(row);

    return {
        ...useMerchantUsersActions(),
        onRowClick
    };
};
