import Typography from '@mui/material/Typography';
import { Upload } from 'lib/upload';
import { BulkKYCStepperStep, type MainAreaProps } from '../types';
import useFileUploader from '../useFileUploader';

const CSVFileUploader = ({ value, onChange }: MainAreaProps<BulkKYCStepperStep.UploadCSVFile>) => {
    const {
        handleDrop,
        renderHelperText
    } = useFileUploader({
        value,
        onChange,
        preview: '/assets/icons/files/ic_csv.png'
    });

    return (
        <Upload
            file={value}
            multiple={false}
            onDrop={handleDrop}
            accept={{
                'text/csv': ['.csv']
            }}
            placeholderProps={{
                titleSlot: (
                    <Typography gutterBottom variant="h5">
                        Drop or Select .CSV file
                    </Typography>
                )
            }}
            helperText={renderHelperText()}
        />
    );
};

export default CSVFileUploader;
