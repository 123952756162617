import { isEqual } from "util/support";
import { TicketingSystemActionTypes, type TicketingSystemAction, type TicketingSystemState } from "./types";
import { servicesStateAdapter } from "./adapters";

export default function reducer(state: TicketingSystemState, action: TicketingSystemAction): TicketingSystemState {
    switch (action.type) {
        case TicketingSystemActionTypes.TicketingSystemSettingsInit: {
            const ticketingSystemSettings = servicesStateAdapter(action.payload);

            return {
                ...state,
                ticketingSystemSettings,
                initialTicketingSystemSettingsSnapshot: ticketingSystemSettings,
                resources: action.payload.resources,
            };
        }

        case TicketingSystemActionTypes.TicketingSystemSettingsApiState: {
            return {
                ...state,
                ticketingSystemSettingsApiState: action.payload
            };
        }

        case TicketingSystemActionTypes.UpdateEnabledState: {
            return withStateChanges({
                ...state,
                ticketingSystemSettings: {
                    ...state.ticketingSystemSettings,
                    [action.payload.service]: {
                        ...state.ticketingSystemSettings[action.payload.service],
                        enabled: action.payload.isEnabled
                    }
                }
            });
        }

        case TicketingSystemActionTypes.UpdateAdditionalOptions: {
            return withStateChanges({
                ...state,
                ticketingSystemSettings: {
                    ...state.ticketingSystemSettings,
                    [action.payload.service]: {
                        ...state.ticketingSystemSettings[action.payload.service],
                        additionalOptions: action.payload.option
                    }
                }
            });
        }

        case TicketingSystemActionTypes.SyncTicketingSystemSettingsOnSave: {
            return withStateChanges({
                ...state,
                initialTicketingSystemSettingsSnapshot: state.ticketingSystemSettings,
                resources: action.payload
            });
        }

        default:
            return state;
    }
}

function withStateChanges(state: TicketingSystemState): TicketingSystemState {
    return {
        ...state,
        isTicketingSystemSettingsChanged: !isEqual(
            state.initialTicketingSystemSettingsSnapshot,
            state.ticketingSystemSettings
        )
    };
}
