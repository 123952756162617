import type { MerchantPaymentMethodsRepositoryState } from "./types";
import { ApiState } from "infrastructure/api";

export const stateFactory: () => MerchantPaymentMethodsRepositoryState = () => ({
    merchantsMethods: {
        apiState: ApiState.Idle,
        data: null
    },
    merchantsMethodsHistory: {
        apiState: ApiState.Idle,
        data: null
    }
});
