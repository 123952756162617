import type { Moment, unitOfTime } from "moment";
import { Filters } from "consts/filters";
import { RequestQueryMapper } from "./request-query-mapper";
import moment from "moment";
import { Period, groupMaxThresholdDiffMap } from "consts/period";

export type PeriodType<TName extends string = typeof Filters.createdAt> =
    | `${TName}${Period.Hour}`
    | `${TName}${Period.Day}`
    | `${TName}${Period.Week}`
    | `${TName}${Period.Month}`
    | `${TName}${Period.Year}`;

export type InputAdapter<TParams> = (params: TParams) => Moment[];

export type Settings = {
    readonly field?: string;
};

export class PeriodAdapter<TAdapterParams> {
    public static getPeriodType({
        period,
        field = Filters.createdAt
    }: Settings & {
        readonly period: Period;
    }): PeriodType {
        return `${field}${period}`;
    }

    public static make<TAdapterParams>(
        adapter: InputAdapter<TAdapterParams>,
        field?: string
    ) {
        return new this(adapter, field);
    }

    public static incrementPeriod(
        date: Moment,
        period: PeriodType,
        settings: Settings & {
            readonly amount?: number;
        } = {}
    ) {
        const { amount = 1, field = Filters.createdAt } = settings;

        return date
            .clone()
            .add(amount, periodOf(this.fromPeriod(period, field)));
    }

    private static fromPeriod(period: PeriodType, field: string): Period {
        return period.replace(field, '') as Period;
    }

    private constructor(
        private adapter: InputAdapter<TAdapterParams>,
        private field: string = Filters.createdAt
    ) { }

    public get(params: TAdapterParams): PeriodType {
        return this.computeRange(
            this.adapter(params)
        );
    }

    public getDifferenceFromPeriod(
        params: TAdapterParams,
        period: PeriodType,
        precise?: boolean
    ): number {
        const [from, to] = this.adapter(params);

        return to.diff(
            from,
            periodOf(PeriodAdapter.fromPeriod(period, this.field)),
            precise
        );
    }

    private computeRange([from, to]: ReturnType<InputAdapter<TAdapterParams>>): PeriodType {
        const field = this.field;

        for (const period of Array.from(groupMaxThresholdDiffMap.keys())) {
            const differenceValue = to.diff(from, periodOf(period), true);

            if (differenceValue <= groupMaxThresholdDiffMap.get(period)!) {
                return `${field}${period}` as PeriodType;
            }
        }

        return `${field}${Period.Year}` as PeriodType;
        /*
        Initial algorithm
        for (const [createdAtPeriod, differenceValue] of Array.from(
            new Map<PeriodType, number>()
                .set(`${field}${Period.Year}`, to.diff(from, periodOf(Period.Year)))
                .set(`${field}${Period.Month}`, to.diff(from, periodOf(Period.Month)))
                .set(`${field}${Period.Week}`, to.diff(from, periodOf(Period.Week)))
                .set(`${field}${Period.Day}`, to.diff(from, periodOf(Period.Day))).entries())
        ) {
            if (differenceValue > 0) {
                return createdAtPeriod;
            }
        }

        return `${field}${Period.Day}`;*/
    }
}

export function periodOf(period: Period) {
    return period.toLocaleLowerCase() as unitOfTime.Base;
}

export function getEndOf(unitOfTime: unitOfTime.Base) {
    return moment()
        .endOf(unitOfTime)
        .isAfter(moment())
        ? moment()
        : moment()
            .endOf(unitOfTime);
}

export function browserUrlSearchParamsAdapterFactory(
    field = Filters.createdAt,
    fallbackRange: Array<Moment> = []
) {
    return (urlSearchParams: URLSearchParams): ReturnType<InputAdapter<string>> => {
        const [fallbackFrom, fallbackTo] = fallbackRange;

        const [
            from = fallbackFrom,
            to = fallbackTo
        ] = urlSearchParams.getAll(field);

        return Array.of<Moment>(
            moment(from),
            moment(to)
        ).map((date, order) =>
            date.isValid()
                ? date
                : fallbackRange[order]);
    };
}

export function backendRequestSearchQueryParamsAdapterFactory(
    field = Filters.createdAt
) {
    return (params: string | URLSearchParams): ReturnType<InputAdapter<string>> => {
        const userSearchQueryParams = new URLSearchParams(params);

        return Array.of<Moment>(
            moment(
                userSearchQueryParams
                    .get(RequestQueryMapper.getInclusiveRangeQueryName(field, 0))
            ),
            moment(
                userSearchQueryParams
                    .get(RequestQueryMapper.getInclusiveRangeQueryName(field, 1)) ?? moment().endOf('day')
            )
        );
    };
}
