import type { ReactNode } from "react";
import type { BooleanSwitch } from "types";
import LabeledSwitch from "ui/atoms/LabeledSwitch";
import { ConfirmatableDialog } from "ui/molecules/Dialog";
import Card from "@mui/material/Card";
import useMasterConnection from "./useMasterConnection";

type DialogContent = {
    readonly dialogContent: Array<ReactNode>;
    readonly actionContent: ReactNode;
};

type Props<
    TIdentifier extends string,
    TSetupValue = BooleanSwitch
> = Parameters<typeof useMasterConnection<TIdentifier, TSetupValue>>[0] & {
    readonly content: [
        DialogContent,
        DialogContent
    ];
};

const MasterConnection = <TIdentifier extends string, TSetupValue = BooleanSwitch>({
    content,
    ...restMasterConnectionProps
}: Props<TIdentifier, TSetupValue>) => {
    const {
        isSwitchDisabled,
        isUpdateMasterConnectionRequestLoading,
        setupMasterConnectionSwitch,
        open,
        isMasterConnectionEnabled,
        onProceed,
        onCancel,
        getChecked,
        getLabel,
        onChange,
    } = useMasterConnection<TIdentifier, TSetupValue>(restMasterConnectionProps);

    return (
        <>
            <Card
                sx={{
                    px: 2,
                    py: 1,
                    boxShadow: theme => theme.shadows[5],
                }}
            >
                <LabeledSwitch
                    checked={getChecked()}
                    label={getLabel()}
                    disabled={isSwitchDisabled}
                    onChange={onChange}
                />
            </Card>
            <ConfirmatableDialog
                isProcessing={isUpdateMasterConnectionRequestLoading}
                open={open}
                content={content}
                onConfirm={onProceed}
                onCancel={onCancel}
                checkPredicate={() => isMasterConnectionEnabled(setupMasterConnectionSwitch)}
            />
        </>
    );
};

export default MasterConnection;
