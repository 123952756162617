import { ApiRoutes } from "consts/enpoints/api";
import type { User, UserMerchantRequestParams, UserMorRequestParams } from "features/users/types";
import { fetchApi } from "infrastructure/api";
import { createOrUpdateResource } from "infrastructure/api/api";
import type { ID } from "types";

export const getUser = (userId?: ID) =>
    fetchApi(ApiRoutes.GetUsers(userId));

export const getUsers = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetUsers()}${queryString}`);

export const getUserById = (userId: ID) =>
    fetchApi(ApiRoutes.GetUsers(userId));

export const getRoles = () =>
    fetchApi(ApiRoutes.GetRoles);

export const getUsersMerchants = (userId?: ID) =>
    fetchApi(ApiRoutes.GetUsersMerchants(userId));

export const getUsersMors = (userId?: ID) =>
    fetchApi(ApiRoutes.GetUsersMors(userId));

export const deleteUser = (coreId: ID) =>
    fetchApi(ApiRoutes.DeleteUser(coreId), {
        method: "DELETE",
    });

export const associateUserMerchant = ({ merchantId, userId }: UserMerchantRequestParams) =>
    fetchApi(ApiRoutes.PostUserMerchant, {
        method: 'POST',
        body: {
            merchantId,
            userId
        }
    });

export const disassociateUserMerchant = ({ merchantId, userId }: UserMerchantRequestParams) =>
    fetchApi(ApiRoutes.DeleteUserMerchant(merchantId, userId), {
        method: 'DELETE'
    });

export const associateUserMor = ({ MOR, userId }: UserMorRequestParams) =>
    fetchApi(ApiRoutes.PostUserMor, {
        method: 'POST',
        body: {
            MOR,
            userId
        }
    });

export const disassociateUserMor = ({ MOR, userId }: UserMorRequestParams) =>
    fetchApi(ApiRoutes.DeleteUserMor(MOR, userId), {
        method: 'DELETE'
    });

export const createOrUpdateUser = (body: Partial<User>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostUser,
        getId: user => user.coreId
    });
