import { memo } from 'react';
import type { getAdditionalOptionsInterval } from "ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/getters/additionalOptions";
import type { AdditionalOptionsProps } from "./types";
import { OptionableAutocomplete } from "ui/atoms/Autocomplete";
import { getIntervals } from 'ui/organizms/Merchants/TicketingSystem/TicketingSystemProvider/getters/autocompleteOptions';

const SecondsIntervalComponent = ({ value, onChange }: AdditionalOptionsProps<
    ReturnType<typeof getAdditionalOptionsInterval>
>) => (
    <OptionableAutocomplete
        disableClearable
        options={getIntervals()}
        TextFieldProps={{
            sx: {
                minWidth: 300
            }
        }}
        multiple={false}
        value={value}
        onChange={onChange}
    />
);

export default memo(SecondsIntervalComponent);
