import { useEffect, useRef, useState } from "react";
import type { PaginateResourceResponse } from "types";
import { Filters } from "consts/merchantPayouts";
import { Paginate } from "consts/table";
import BusinessLogicException from "exceptions/BusinessLogicException";
import { getLedgers, getMerchantSettings } from "features/merchants/api";
import { LedgersResponse, MerchantSettings } from "features/merchants/types";
import { useSimpleRequest } from "hooks/useRequest";
import { RequestQueryMapper } from "util/request-query-mapper";
import { RollingReserve } from "./types";

type UsePayableRollingReserveCalculationDescriptionArg = {
    readonly currency: string;
    readonly merchantId?: number | null;
};

export default function usePayableRollingReserveCalculationDescription({
    merchantId,
    currency
}: UsePayableRollingReserveCalculationDescriptionArg) {
    const request = useSimpleRequest();
    const requestRef = useRef(request);
    requestRef.current = request;

    const [isLoading, setLoading] = useState(false);
    const [state, setState] = useState({
        [RollingReserve.RetainedCap]: 0,
        [RollingReserve.Balance]: 0,
        [RollingReserve.PayoutCurrency]: currency
    });

    useEffect(() => {
        if (!merchantId) {
            return;
        }

        setLoading(true);

        requestRef.current<PaginateResourceResponse<MerchantSettings>['data']>(() => getMerchantSettings(
            RequestQueryMapper.from()
                .containsIn(Filters.merchantId, String(merchantId))
                .contains(Paginate.perPage, '1')
                .toString()
        ))
            .then(({ data }) => {
                const merchantSettings = data.data.at(0);

                if (!merchantSettings) {
                    throw new BusinessLogicException('Merchant settings not found', { merchantId });
                }

                const { rollingReserveCap, payoutCurrency } = merchantSettings;

                setState(state => ({
                    ...state,
                    [RollingReserve.RetainedCap]: rollingReserveCap,
                    [RollingReserve.PayoutCurrency]: payoutCurrency
                }));

                return requestRef.current<LedgersResponse['data']>(() => getLedgers(
                    RequestQueryMapper.from()
                        .containsIn(Filters.merchantId, String(merchantId))
                        .containsIn(Filters.currency, payoutCurrency)
                        .contains(Paginate.perPage, '1')
                        .toString()
                ));
            })
            .then(({ data }) => {
                const ledger = data.data.at(0);

                if (!ledger) {
                    throw new BusinessLogicException('Ledger not found', {});
                }

                const { rollingReserved } = ledger;

                setState(state => ({
                    ...state,
                    [RollingReserve.Balance]: rollingReserved
                }));
            })
            .finally(() => setLoading(false));
    }, [merchantId]);

    return {
        ...state,
        isLoading
    };
};
