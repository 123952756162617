import { memo, PropsWithChildren } from "react";
import { BasicDialog } from "ui/molecules/Dialog";
import { ProcessingAlert } from "ui/organizms/MerchantPayouts/ActionMenu/Dialogs";
import DownloadCSVTemplateProvider from "./DownloadCSVTemplateProvider";
import useDownloadCSVTemplate from "./useDownloadCSVTemplate";

export type DownloadCSVTemplateDialogProps = {
    readonly downloadTemplateHandler: (fileName: string) => Promise<Response>;
};

const DownloadCSVTemplateDialog = ({ children, downloadTemplateHandler }: PropsWithChildren<DownloadCSVTemplateDialogProps>) => {
    const {
        open,
        onClose,
        ...restModalProps
    } = useDownloadCSVTemplate({
        downloadTemplateHandler
    });

    return (
        <DownloadCSVTemplateProvider
            {...restModalProps}
            open={open}
        >
            <BasicDialog
                isOpen={open}
                onClose={onClose}
                isCanClose={false}
                DialogProps={{
                    sx: {
                        fontWeight: 700,
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto'
                        }
                    }
                }}
            >
                <ProcessingAlert message='Template is generating...' />
            </BasicDialog>
            {children}
        </DownloadCSVTemplateProvider>
    );
};

export default memo(DownloadCSVTemplateDialog);
