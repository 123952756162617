import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Collapse from "@mui/material/Collapse";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import type {
    Filter as FilterType,
    AbstractFilterProps,
    FiltersProps
} from 'ui/widgets/Filters';
import Button from 'ui/atoms/Button';
import ToggleButton from 'ui/atoms/ToggleButton';
import {
    Filters,
    Summables,
    Widgetable,
    SelectedFilters
} from 'ui/widgets/Filters';
import useTableFilters, { type TableFiltersProps } from '../hooks/useTableFilters';
import type { FiltersPublicApi } from '../types';

type Props =
    & TableFiltersProps<string>
    & Pick<
        FiltersProps,
        | 'FrontPanelWidget'
        | 'SummableWidget'
        | 'sx'
    >;

const TableFilters = forwardRef<FiltersPublicApi<string> | null, Props>(({ sx, ...props }, ref) => {
    const {
        SummableWidget,
        FrontPanelWidget
    } = props;

    const {
        filters,
        onChange,
        onReset,
        onApply,
        isFiltersOpen,
        handleToggleFilters,
        getWidgetProps,
        isFiltersAvailable,
        filterPropsOverride,
        filterVisibilityPredicate,
        getFilterOptions,
        isBootstrapDataLoading
    } = useTableFilters({
        ...props,
        ref
    });
    // const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);

    // const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);
    // const { getFilterOptions } = useFiltersModel(filtersModelOverrides);

    // const filterPropsOverride = useFilterPropsOverride!(filters);
    // const filterVisibilityPredicate = useVisibilityRules!(filters);

    // const handleToggleFilters = () => setFiltersOpen(state => !state);

    // const getWidgetProps = (Component?: WidgetProps['Component']) => ({
    //     Component,
    //     filters,
    //     isFiltersOpen
    // });

    // const isFiltersAvailable = () => filters.length > 0;

    if (!isFiltersAvailable()) {
        return null;
    }

    return (
        <Box
            sx={{
                m: 3,
                display: 'grid',
                gridTemplateColumns: `1fr ${SummableWidget ? 'auto' : ''}`,
                gridTemplateRows: 'repeat(2, auto)',
                ...sx
            }}
        >
            <Card>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto'
                    }}
                >
                    <Stack
                        m={2}
                        direction="row"
                        spacing={2}
                    >
                        <ToggleButton
                            isActive={isFiltersOpen}
                            onClick={handleToggleFilters}
                            variant="outlined"
                            startIcon={<FilterAltOutlinedIcon />}
                            endIcon={<ArrowDropDownIcon />}
                        >
                            {isFiltersOpen ? 'Hide Filter' : 'Show Filter'}
                        </ToggleButton>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowUpwardIcon />}
                            size='small'
                            onClick={onApply}
                        >
                            Apply
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<RestartAltIcon />}
                            size='small'
                            onClick={onReset}
                        >
                            Reset
                        </Button>
                    </Stack>
                    <Box
                        m={2}
                    >
                        <Widgetable
                            {...getWidgetProps(FrontPanelWidget)}
                        />
                    </Box>
                </Box>
                <Collapse in={isFiltersOpen} timeout="auto" unmountOnExit>
                    <Stack m={2} direction="row" flexWrap='wrap' gap={2}>
                        {filters.map(filter => {
                            const filterProps = {
                                renderAs: filter.renderAs,
                                props: {
                                    ...getFilterOptions(filter.props.id),
                                    ...filter.props,
                                    ...filterPropsOverride(filter)
                                }
                            };

                            if (!filterVisibilityPredicate(filter)) {
                                return null;
                            }

                            return (
                                <Filters
                                    key={filter.props.id}
                                    {...filterProps}
                                    onChange={onChange}
                                />
                            );
                        })}
                    </Stack>
                </Collapse>
            </Card>
            <Summables
                {...getWidgetProps(SummableWidget)}
            />
            <SelectedFilters
                isOpen={isFiltersOpen}
                isBootstrapDataLoading={isBootstrapDataLoading}
                filters={filters}
                getFilterOptions={getFilterOptions}
                onChange={onChange}
            />
        </Box>
    );
});

TableFilters.defaultProps = {
    useVisibilityRules: () => _ => true,
    useFilterPropsOverride: () => _ => ({}) as FilterType<AbstractFilterProps>
};

export default TableFilters;
