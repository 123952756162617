import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Filters } from "consts/kyc";
import type { ManualBulkKYCsImportHistory } from "features/kyc/types";
import type { BooleanSwitch, Mapped } from "types";
import Icon, { IconProps } from "ui/atoms/Icon";
import withGridCellParams from "ui/widgets/Table/renderers/withGridCellParams";

const Status = withGridCellParams<unknown, Mapped<ManualBulkKYCsImportHistory>>(({ row }) => {
    const handleStatus = ({
        isQueued,
        totalIgnoredRows,
        totalProcessedRows,
        totalRows
    }: Pick<
        ManualBulkKYCsImportHistory,
        | 'isQueued'
        | 'totalIgnoredRows'
        | 'totalProcessedRows'
        | 'totalRows'
    >): IconProps => {
        if (!isQueued) {
            return {
                color: 'warning',
                name: 'PendingActions',
                title: 'Pending'
            };
        }
        else if (totalRows < 1) {
            return {
                color: 'error',
                name: 'FolderOffIcon',
                title: 'Invalid File'
            };
        }
        else if (totalProcessedRows + totalIgnoredRows !== totalRows) {
            return {
                color: 'warning',
                name: 'HourglassEmpty',
                title: 'Processing'
            };
        }
        return {
            color: 'success',
            name: 'CheckCircleOutline',
            title: 'Processed'
        };
    };

    const {
        color,
        name,
        title
    } = handleStatus({
        isQueued: Number(row[Filters.isQueued]) as BooleanSwitch,
        totalIgnoredRows: Number(row[Filters.totalIgnoredRows]),
        totalProcessedRows: Number(row[Filters.totalProcessedRows]),
        totalRows: Number(row[Filters.totalRows])
    })

    return (
        <Tooltip
            arrow
            title={title}
            placement='top'
        >
            <Box>
                <Icon
                    name={name}
                    color={color}
                />
            </Box>
        </Tooltip>
    );
});

export default Status;
