import { createContext, type PropsWithChildren } from "react";
import type { ResourceContext as ResourceContextType } from "./types";
import useResource from "./useResource";

export const ResourceContext = createContext({} as ResourceContextType);

const ResourceProvider = ({ children }: PropsWithChildren) => (
        <ResourceContext.Provider
            value={useResource()}
        >
            {children}
        </ResourceContext.Provider>
    );

export default ResourceProvider;
