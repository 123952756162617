import { memo } from "react";
import Service, { type ServiceProps } from "../Service";
import AdditionalOptions from "./AdditionalOptions";

const AMLScreening = (props: Omit<ServiceProps, 'serviceLabel'>) => (
    <Service
        {...props}
        serviceLabel='AML Screening'
        additionalOptionsSlot={(
            <AdditionalOptions />
        )}
    />
);

export default memo(AMLScreening);
