import { memo } from "react";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Todo, TodoItem } from "ui/molecules/Todo";
import { getFormInputProps } from "util/forms";
import type { FormableTodoProps } from "./types";
import useFormableTodo from "./useFormableTodo";

const FormableTodo = (props: FormableTodoProps) => {
    const {
        errors,
        touched,
        handleChange,
        handleBlur,
        name,
        sx,
        TextFieldContainerProps,
        TextFieldProps,
        actionSlot,
        ActionProps,
        ItemContainerProps,
        ItemProps
    } = props;

    const {
        getValue,
        getItems,
        hasValue,
        removeItem,
        addItem
    } = useFormableTodo(props);

    const { error, ...restTextFieldProps } = getFormInputProps(name, { errors, touched });

    return (
        <Todo
            sx={sx}
            TextFieldContainerProps={TextFieldContainerProps}
            actionSlot={actionSlot}
            ItemContainerProps={{
                ...ItemContainerProps,
                sx: {
                    borderColor: 'divider',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 1,
                    mt: 2,
                    p: 1,
                    pl: 2,
                    ...ItemContainerProps?.sx
                }
            }}
            TextFieldProps={{
                ...TextFieldProps,
                ...restTextFieldProps,
                error,
                onChange: handleChange,
                onBlur: handleBlur,
                name,
                value: getValue()
            }}
            ActionProps={{
                ...ActionProps,
                disabled: error || !hasValue(),
                onClick: event => {
                    ActionProps?.onClick?.(event);
                    addItem();
                }
            }}
        >
            {getItems().map((item: string, index: number) => (
                <TodoItem
                    key={index}
                    {...ItemProps}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                        gap: 1,
                        opacity: 0,
                        animation: 'appear .3s ease-in-out forwards',
                        ...ItemProps?.sx
                    }}
                    IconButtonProps={{
                        children: (
                            <BackspaceIcon
                                color='error'
                            />
                        ),
                        ...ItemProps?.IconButtonProps,
                        onClick: event => {
                            ItemProps?.IconButtonProps?.onClick?.(event);
                            removeItem(index);
                        }
                    }}
                >
                    {item}
                </TodoItem>
            ))}
        </Todo>
    );
};

export default memo(FormableTodo);
