import { memo } from "react";
import type { useAccordion } from "ui/atoms/Accordion";
import { capitalize } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CustomAvatar } from "lib/custom-avatar";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import MerchantPaymentMethodPSP from "./MerchantPaymentMethodPSP";
import { compareIds } from "util/support";

type Props = ReturnType<typeof useAccordion> & {
    readonly name: string;
    readonly merchantsMethods: Array<MerchantsMethodsPivot>;
};

const MerchantPaymentMethod = ({ name, merchantsMethods, expanded, handleChange }: Props) => (
    <Box>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, min-content)',
                alignItems: 'center',
                gap: 2
            }}
        >
            <CustomAvatar
                name={name}
                alt={name}
            />
            <Typography
                variant='h5'
            >
                {capitalize(name)}
            </Typography>
        </Box>
        <Box>
            {merchantsMethods.map(merchantMethod => (
                <MerchantPaymentMethodPSP
                    key={merchantMethod.coreId}
                    merchantMethod={merchantMethod}
                    expanded={compareIds(String(expanded), merchantMethod.coreId)}
                    onChange={handleChange(merchantMethod.coreId)}
                />
            ))}
        </Box>
    </Box>
);

export default memo(MerchantPaymentMethod);
