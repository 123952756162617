import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type { ManualBulkKYCsImportHistoryResponse } from "./types";
import { getManualBulkKYCsImportHistory } from "features/kyc/api";
import type { ThunkReturnType } from "types";

export const getManualBulkKYCsImportHistoryThunk: AsyncThunk<
    ThunkReturnType<ManualBulkKYCsImportHistoryResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetManualBulkKYCsImportHistory]),
    payloadCreator(getManualBulkKYCsImportHistory),
    {
        condition: (_, { getState }) => {
            const { kyc } = getState() as RootState;

            return ![ApiState.Pending].includes(kyc.tableLoadingState);
        }
    }
);
