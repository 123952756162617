import { useMemo, useEffect } from 'react';

type UseInitArg = {
    readonly shouldInit: () => boolean;
    readonly onInit?: () => void;
};

export default function useInit({
    shouldInit,
    onInit
}: UseInitArg) {
    const handleInit = useMemo(() => onInit, [onInit]);
    const isUninitialized = shouldInit();

    useEffect(() => {
        if (isUninitialized && handleInit) {
            handleInit();
        }
    }, [
        isUninitialized,
        handleInit
    ]);
};
