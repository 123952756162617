import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { ConsumersTable } from "ui/organizms/Consumers";

const Consumers = () => (
    <Screen
        title='Consumers | General'
    >
        <ConsumersTable />
    </Screen>
);

export default memo(Consumers);
