export default abstract class BaseException<TCause extends ErrorOptions = {}> extends Error {
    readonly date = new Date();

    constructor(...params: [string, TCause]) {
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, new.target);
        }
    }
}
