import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type {
    RolesResponse,
    User,
    UsersMerchantsPivot,
    UsersMorsPivot,
    UsersMerchantsResponse,
    UsersMorsResponse,
    UsersResponse,
    UserMerchantRequestParams,
    UserMorRequestParams,
    UserResponse
} from "./types";
import {
    associateUserMerchant,
    associateUserMor,
    createOrUpdateUser,
    deleteUser,
    disassociateUserMerchant,
    disassociateUserMor,
    getRoles,
    getUserById,
    getUsers,
    getUsersMerchants,
    getUsersMors
} from "features/users/api";
import type { ID, ResourceResponse, ThunkReturnType } from "types";
import { requestFalsyValueSanitizer } from "util/transformers";

export const getUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsers]),
    payloadCreator(getUsers),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersLoadingState);
        }
    }
);

export const getUserByIdThunk: AsyncThunk<
    ThunkReturnType<UserResponse>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, `${ApiRouteTypes.GetUsers}ById`]),
    payloadCreator(getUserById),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersLoadingState);
        }
    }
);

export const getRolesThunk: AsyncThunk<
    ThunkReturnType<RolesResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetRoles]),
    payloadCreator(getRoles),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.rolesLoadingState) &&
                !users.rolesSlice?.data.length;
        }
    }
);

export const getUsersMerchantsThunk: AsyncThunk<
    ThunkReturnType<UsersMerchantsResponse>,
    ID | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersMerchants]),
    payloadCreator(getUsersMerchants),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersMerchantsLoadingState);
        }
    }
);

export const getUsersMorsThunk: AsyncThunk<
    ThunkReturnType<UsersMorsResponse>,
    ID | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersMors]),
    payloadCreator(getUsersMors),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersMorsLoadingState);
        }
    }
);

export const associateUserMerchantThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<UsersMerchantsPivot>>,
    UserMerchantRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUserMerchant]),
    payloadCreator(associateUserMerchant)
);

export const disassociateUserMerchantThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<null>>,
    UserMerchantRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteUserMerchant]),
    payloadCreator(disassociateUserMerchant)
);

export const associateUserMorThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<UsersMorsPivot>>,
    UserMorRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUserMor]),
    payloadCreator(associateUserMor)
);

export const disassociateUserMorThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<null>>,
    UserMorRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteUserMor]),
    payloadCreator(disassociateUserMor)
);

export const postUserThunk: AsyncThunk<any, Partial<User>, {}> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUser]),
    payloadCreator(createOrUpdateUser, requestFalsyValueSanitizer(['string', null])),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.userProfileLoadingState);
        }
    }
);

export const deleteUserThunk: AsyncThunk<
    ResourceResponse<ID>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteUser]),
    async (coreId: ID, { rejectWithValue, fulfillWithValue }) => {
        const response = await deleteUser(coreId);

        if (!response.ok) {
            return rejectWithValue(response.clone());
        }

        const payload: ResourceResponse<unknown> = await response.json();

        const customPayload: ResourceResponse<ID> = {
            ...payload,
            data: coreId
        };

        fulfillWithValue(customPayload);
        return customPayload;
    },
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersLoadingState);
        }
    }
);
