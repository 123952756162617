import { memo } from "react";
import numeral from "numeral";
import type { Status } from "types";
import type { TransactionsResponse } from "features/transactions/types";
import {
    esTransactionsGroupByStatusQueryToken,
    useStatisticDashboardContext,
    useStatisticDashboardUtils
} from "../StatisticDashboardProvider";
import Cassette from "./Cassette";
import { fNumber } from "util/formaters";
import { useQuery } from "hooks/useQuery";
import { distinctEmailTransactionsRequestQueryParamsFactory } from "../StatisticDashboardProvider";
import { Filters } from "consts/transactions";
import { BooleanSwitchValue } from "consts/general";
import { getTransactions } from "features/transactions/api";

type Props = {
    readonly statusLabel: string;
    readonly status: Status;
};

const TransactionStatusCassette = ({
    statusLabel,
    status
}: Props) => {
    const initialState = {
        isLoading: false
    };

    const distinctEmailsBySucceededTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: requestQueryMapper =>
            distinctEmailTransactionsRequestQueryParamsFactory('SUCCEEDED')(
                requestQueryMapper
                    .contains(Filters.parentId, `${BooleanSwitchValue.Off}`)
            ),
        fetchQuery: getTransactions,
        initialState
    });

    const distinctEmailsByFailedTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: distinctEmailTransactionsRequestQueryParamsFactory('FAILED'),
        fetchQuery: getTransactions,
        initialState
    });

    const distinctEmailsByRejectedTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: distinctEmailTransactionsRequestQueryParamsFactory('REJECT'),
        fetchQuery: getTransactions,
        initialState
    });

    const statisticDashboard = useStatisticDashboardContext();

    const {
        getTransactionAmountByStatus,
        getTransactionAmountPercentageByStatus
    } = useStatisticDashboardUtils();

    const getUniqueEmailCount = (response: TransactionsResponse) => response.data.total;

    return (
        <Cassette
            isLoading={statisticDashboard[esTransactionsGroupByStatusQueryToken].isLoading}
            labelSlot={statusLabel}
            amountSlot={fNumber(getTransactionAmountByStatus(status)[0])}
            chipProps={{
                label: numeral(getTransactionAmountPercentageByStatus({
                    status
                }))
                    .format('0.00%'),
            }}
            footerSlot='Unique emails'
            tooltipProps={{
                title: 'Calculate unique emails'
            }}
            lazyQuery={new Map<Status, ReturnType<typeof useQuery<TransactionsResponse>>>()
                .set('SUCCEEDED', distinctEmailsBySucceededTransactionsQuery)
                .set('FAILED', distinctEmailsByFailedTransactionsQuery)
                .set('REJECT', distinctEmailsByRejectedTransactionsQuery)
                .get(status)!}
            countGetter={getUniqueEmailCount}
        />
    );
};

export default memo(TransactionStatusCassette);
