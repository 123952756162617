import { selectIsExportMerchantPayoutsLoading } from 'features/merchantPayouts/selectors';
import { memo } from 'react';
import { TableToolbar, type TableToolbarProps } from 'ui/widgets/Table';

const MerchantPayoutsTableToolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportMerchantPayoutsLoading}
    />
);

export default memo(MerchantPayoutsTableToolbar);
