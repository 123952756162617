import type { Status } from "types";
import Icon from "ui/atoms/Icon";
import withStatus from "./withStatus";

export const TransactionStatus = withStatus<Status>({
    PENDING: {
        icon: <Icon name="HourglassEmpty" />,
        color: 'warning'
    },
    FAILED: {
        icon: <Icon name="SmsFailed" />,
        color: 'error'
    },
    REJECT: {
        icon: <Icon name="ThumbDownOffAlt" />,
        color: 'secondary'
    },
    SUCCEEDED: {
        icon: <Icon name="CheckCircleOutline" />,
        color: 'success'
    },
    REFUNDED: {
        icon: <Icon name="LocalAtm" />,
        color: 'primary'
    },
    CHARGE_BACK: {
        icon: <Icon name="CurrencyExchange" />,
        color: 'info'
    }
});

export default TransactionStatus;
