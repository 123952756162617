import { FilterValueProvider, TValueStrategy, ValueStrategy } from "./types";

class NumericRangeFilterValueProvider extends FilterValueProvider<Array<string | number>> {
    static getDefaultValue() {
        return [];
    }

    public getInitialValue(values: Array<string | number>) {
        return values
            .map(Number)
            .filter(value => !Number.isNaN(value));
    }

    public handleChangeValue(values: Array<string | number>) {
        return values;
    }
}

const numericRangeFilterValueStrategy: TValueStrategy<Array<string | number>> = {
    [ValueStrategy.Default]:
        new NumericRangeFilterValueProvider(
            NumericRangeFilterValueProvider.getDefaultValue
        )
};

export default numericRangeFilterValueStrategy;
