import { memo } from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Profile from "ui/layouts/Profile";
import useMerchantDetail from "./useMerchantDetail";
import { Typography } from "@mui/material";
import Tabs from "ui/atoms/Tabs";
import MerchantDetailTab from "./MerchantDetailTab";
import MerchantProfile from "./MerchantProfile";
import useMerchantDetailRepository from "./useMerchantDetailRepository";
import { MerchantDetailTab as MerchantDetailTabEnum } from "./types";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";
import MerchantUsers from "./MerchantUsers";
import MerchantAccountSettings from "./MerchantAccountSettings";
import useResetMerchantDetail from "./useResetMerchantDetail";
import usePersistMerchant from "../usePersistMerchant";
import MerchantPaymentMethods from "./MerchantPaymentMethods";
import MerchantDomains from "./MerchantDomains";

const MerchantDetail = () => {
    const { checkUnsavedChanges } = useUnsavedChangesContext();

    const {
        persistMerchantActiveTab,
        getMerchantActiveTab
    } = usePersistMerchant();

    const merchantDetail = useMerchantDetail({
        onBeforeClose: () => checkUnsavedChanges({
            shouldResetResolvers: true
        })
    });

    const {
        merchant,
        onClose,
    } = merchantDetail;

    const repository = useMerchantDetailRepository();

    const handleTabChange = (tab: string) =>
        checkUnsavedChanges({
            shouldResetResolvers: true
        })
            .then(() => persistMerchantActiveTab(tab));

    useResetMerchantDetail();

    return (
        <Paper
            sx={{
                position: 'absolute',
                inset: 0,
                transform: 'translateX(100%)',
                animation: 'slideLeft .3s ease-in-out forwards',
                height: '100%',
                p: 2
            }}
        >
            <Profile
                backButtonSlot={(
                    <IconButton
                        onClick={onClose}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                )}
                AccountProps={{
                    title: (
                        <Typography
                            variant='body2'
                        >
                            #{merchant.coreId}
                        </Typography>
                    ),
                    subTitle: (
                        <Typography
                            variant='h6'
                        >
                            {merchant.fullName}
                        </Typography>
                    ),
                    name: merchant.fullName
                }}
            >
                <Tabs
                    model={[
                        {
                            label: 'Profile',
                            component: (
                                <MerchantDetailTab
                                    repository={repository.get(MerchantDetailTabEnum.Profile)!}
                                >
                                    <MerchantProfile
                                        {...merchantDetail}
                                    />
                                </MerchantDetailTab>
                            )
                        },
                        {
                            label: 'Users',
                            component: (
                                <MerchantDetailTab
                                    repository={repository.get(MerchantDetailTabEnum.Users)!}
                                >
                                    <MerchantUsers />
                                </MerchantDetailTab>
                            ),
                            sxProps: {
                                padding: 0
                            }
                        },
                        {
                            label: 'Account Settings',
                            component: (
                                <MerchantDetailTab
                                    repository={repository.get(MerchantDetailTabEnum.AccountSettings)!}
                                >
                                    <MerchantAccountSettings
                                        {...merchantDetail}
                                    />
                                </MerchantDetailTab>
                            ),
                            sxProps: {
                                padding: 0
                            }
                        },
                        {
                            label: 'Payment Methods',
                            component: (
                                <MerchantDetailTab
                                    repository={repository.get(MerchantDetailTabEnum.PaymentMethods)!}
                                >
                                    <MerchantPaymentMethods />
                                </MerchantDetailTab>
                            )
                        },
                        // {
                        //     label: 'Fees & Commissions',
                        //     component: (
                        //         'Here goes the fees & commissions'
                        //         // <MerchantDetailTab>
                        //         //     Here goes the fees & commissions
                        //         // </MerchantDetailTab>
                        //     )
                        // },
                        {
                            label: 'Domains',
                            component: (
                                <MerchantDetailTab
                                    repository={repository.get(MerchantDetailTabEnum.Domains)!}
                                >
                                    <MerchantDomains />
                                </MerchantDetailTab>
                            )
                        }
                    ]}
                    TabListContainerProps={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                    TabListProps={{
                        'aria-label': 'merchant details',
                        variant: 'scrollable',
                        scrollButtons: 'auto'
                    }}
                    value={getMerchantActiveTab()}
                    onChange={handleTabChange}
                />
            </Profile>
        </Paper>
    );
};

export default memo(MerchantDetail);
