import { memo, RefObject, PropsWithChildren, isValidElement, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import type { MenuImperativeProps } from 'ui/atoms/Menu';
import type { ActionMenuItem } from './Props';
import { DialogSettingsDispatchContext, DialogVisibilityDispatchContext } from './Dialogs';

export type MenuItemProps = ActionMenuItem
    & {
        readonly menuRef: RefObject<MenuImperativeProps | undefined>
    };

const ActionItem = ({
    icon,
    label,
    handler,
    menuRef,
    children,
}: PropsWithChildren<MenuItemProps>) => {
    const dialogVisibilityDispatch = useContext(DialogVisibilityDispatchContext);
    const dialogSettingsDispatch = useContext(DialogSettingsDispatchContext);

    const onClick = () => {
        menuRef.current?.handleClose();

        if (isValidElement(children)) {
            dialogVisibilityDispatch(true);
            dialogSettingsDispatch(state => ({
                ...state,
                children
            }));
        }

        return handler();
    };

    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    );
};

export default memo(ActionItem);