import type { AutocompleteFilterProps } from "../../FilterVariants/SelectFilter/types";
import type { Option } from "types";
import { withSelectedFilterContent } from "./SelectedFilterContent";
import { compareIds } from "util/support";
import type { SelectedFilterContentProps } from "../types";

const SelectFilterContent = ({ filter: { props }, onChange, getFilterOptions }: SelectedFilterContentProps<
    Omit<AutocompleteFilterProps, 'options' | 'onChange'>
>) => {
    const { label, value, id: filterId, multiple } = props;

    return withSelectedFilterContent({
        onDelete: (option: Option) => () => onChange({
            type: filterId,
            payload: multiple
                ? value.filter(({ id }: Option) => !compareIds(id, option.id))
                : null
        }),
        label,
        values: [value]
            .flat()
            .filter(Boolean),
        renderChipLabel: ({ id }: Option) =>
            getFilterOptions(filterId)
                .options
                ?.find((option: Option) => compareIds(option.id, id))
                ?.name
    });
};

export default SelectFilterContent;
