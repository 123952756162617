import { useContext } from "react";
import type { CoreBankingEntity } from "types";
import { ManageFileTaxesContext, DialogSettingsDispatchContext } from "../Dialogs";
import type { ActionHookArg } from "../Props";

export default function useManageFileTaxes<T extends CoreBankingEntity>({ row }: Pick<ActionHookArg<T, void>, 'row'>) {
    const { getFileTaxes } = useContext(ManageFileTaxesContext);
    const dialogSettingsDispatch = useContext(DialogSettingsDispatchContext);

    return () => {
        dialogSettingsDispatch(state => ({
            ...state,
            DialogProps: {
                sx: {
                    '& .MuiDialog-container .MuiPaper-root': {
                        maxWidth: '865px'
                    }
                }
            }
        }));

        return getFileTaxes(row.coreId);
    };
};
