import { KYCServiceType, KYCSettingsDefaults, KYCSettingsServicePropertyName } from "consts/kyc";

export default function getKYCAdditionalSettingsByTypeId(kycTypeId: KYCServiceType) {
    return {
        [KYCServiceType.FaceVerification]: [],
        [KYCServiceType.EIDVerification]: [],
        [KYCServiceType.IDVerification]: [
            {
                id: KYCSettingsDefaults.documentBacksideProofRequired,
                name: KYCSettingsServicePropertyName.DocumentBacksideProofRequired
            },
            {
                id: KYCSettingsDefaults.documentShowOcrForm,
                name: KYCSettingsServicePropertyName.DocumentShowOcrForm
            }
        ],
        [KYCServiceType.AMLVerification]: [
            {
                id: KYCSettingsDefaults.includeIdVerifiedConsumers,
                name: KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers
            }
        ],
        [KYCServiceType.AddressVerification]: [
            {
                id: KYCSettingsDefaults.includeIdVerifiedConsumers,
                name: KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers
            },
            {
                id: KYCSettingsDefaults.addressShowOcrForm,
                name: KYCSettingsServicePropertyName.AddressShowOcrForm
            }
        ],
        [KYCServiceType.PhoneVerification]: [
            {
                id: KYCSettingsDefaults.includeIdVerifiedConsumers,
                name: KYCSettingsServicePropertyName.IncludeIdVerifiedConsumers
            }
        ]
    }[kycTypeId];
};
