export const PHONE_NUMBER_REGEXP = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const AVATAR_SUPPORTED_FORMATS = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif'
];

export const AVATAR_MAX_SIZE = 3100000;
