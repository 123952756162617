import { memo } from "react";
import Box from "@mui/material/Box";
import Tabs from "ui/atoms/Tabs";
import ActionPanel from "ui/molecules/ActionPanel";
import Info from "./Info";
import Finance from "./Finance";
import MerchantProfileProvider, {
    MerchantProfileTab,
    useMerchantProfileContext
} from "./MerchantProfileProvider";
import { TabLabel } from "ui/widgets/StatefulTabs";
import type { MerchantDetailProps } from "../types";

const MerchantProfile = ({ onClose }: MerchantDetailProps) => {
    const {
        isSaving,
        message,
        activeTab,
        tabManager,
        selectState,
        canSubmit,
        saveChanges,
        onChange
    } = useMerchantProfileContext();

    const tabLabelProps = {
        message,
        tabManager,
        selectState
    };

    return (
        <Box>
            <Tabs
                model={[
                    {
                        key: MerchantProfileTab.Info,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantProfileTab.Info}
                            />
                        ),
                        component: (
                            <Info />
                        )
                    },
                    {
                        key: MerchantProfileTab.Finance,
                        label: (
                            <TabLabel
                                {...tabLabelProps}
                                value={activeTab}
                                tab={MerchantProfileTab.Finance}
                            />
                        ),
                        component: (
                            <Finance />
                        )
                    }
                ]}
                TabListContainerProps={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
                TabListProps={{
                    'aria-label': 'merchant profile',
                    variant: 'scrollable',
                    scrollButtons: 'auto'
                }}
                onChange={onChange}
            />
            <ActionPanel
                sx={{
                    mx: 2
                }}
                isLoading={isSaving}
                SaveActionProps={{
                    disabled: !canSubmit()
                }}
                onCancel={onClose}
                onSave={saveChanges}
            />
        </Box>
    );
};

export default memo((props: MerchantDetailProps) => (
    <MerchantProfileProvider>
        <MerchantProfile
            {...props}
        />
    </MerchantProfileProvider>
));
