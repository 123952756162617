import { memo } from "react";
import type { AccordionProps } from "ui/atoms/Accordion";
import type { MerchantBusiness } from "features/merchants/types";
import Box from "@mui/material/Box";
import MerchantBusinessName from "./MerchantBusinessName";
import MerchantBusinessLabel from "./MerchantBusinessLabel";
import MerchantBusinessToolbar from "./MerchantBusinessToolbar";

type Props = Pick<AccordionProps, 'expanded'> & {
    readonly merchantBusiness: MerchantBusiness;
};

const MerchantBusinessSummary = ({ merchantBusiness, ...accordeonProps }: Props) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto 120px',
            gap: 5,
            width: '100%',
            alignItems: 'center'
        }}
    >
        <MerchantBusinessName
            {...merchantBusiness}
        />
       <MerchantBusinessToolbar
            {...merchantBusiness}
        />
        <MerchantBusinessLabel
            {...accordeonProps}
        />
    </Box>
);

export default memo(MerchantBusinessSummary);
