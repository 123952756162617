import type { AnyAction, Reducer } from "@reduxjs/toolkit";
import type { RootState } from "infrastructure/store/types";
import { authLogoutActionType } from "features/auth/actionTypes";
import { logoutThunk } from "features/auth/thunk";
import combinedReducer from "./combined";

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    let appState = state;

    if (logoutThunk.fulfilled.match(action) ||
        [authLogoutActionType].includes(action.type)) {
        appState = undefined;
    }

    return combinedReducer(appState, action);
};

export default rootReducer;
