import { FunctionComponent, memo, useState } from 'react';
import SelectMenuItem from './SelectMenuItem';
import type { AutocompleteFilterProps } from '../types';
import type { Option } from 'types';

type Props = Pick<
    AutocompleteFilterProps,
    | 'onChange'
    | 'options'
    | 'id'
>;

interface ToggleSelectAllComponent<TProps> extends FunctionComponent<TProps> {
    isChecked: {
        [key: string]: boolean;
    };
}

const ToggleSelectAll: ToggleSelectAllComponent<Props> = ({ onChange, id, options }) => {
    const [isChecked, setChecked] = useState(ToggleSelectAll.isChecked[id] ?? false);

    const onClick = () => {
        onChange({
            type: id,
            payload: Array.from<Option>(options)
                .filter(() => !isChecked)
        });

        const checked = !isChecked;

        setChecked(checked);
        ToggleSelectAll.isChecked[id] = checked;
    };

    return (
        <SelectMenuItem
            checked={isChecked}
            value={{
                id: 'all',
                name: 'Select All'
            }}
            onClick={onClick}
        />
    );
};

ToggleSelectAll.isChecked = {};

export default memo(ToggleSelectAll);
