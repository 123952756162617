import { useRequest } from "hooks";
import { changeExportLoading } from "./slice";
import type { ManualBulkKYCsImportHistoryResponse } from "./types";
import { getManualBulkKYCsImportHistoryThunk } from "./thunks";
import type { ThunkReturnType } from "types";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";

const useActions = () => {
    const getManualBulkKYCsImportHistoryRecords = useRequest<
        ThunkReturnType<
            ManualBulkKYCsImportHistoryResponse
        >, string | undefined
    >({
        thunk: getManualBulkKYCsImportHistoryThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetManualBulkKYCsImportHistory
    });

    return {
        getManualBulkKYCsImportHistoryRecords,
        downloadCsv
    };
};

export default useActions;
