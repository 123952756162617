import useMerchantActions from "features/merchants/useMerchantUserActions";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/merchants";
import { useMerchantContext } from "../MerchantProvider";

export default function useMerchantUsersActions() {
    const merchant = useMerchantContext();

    const {
        updateOrAddMerchantByIdUsers,
        getMerchantByIdUsers,
        downloadCsv
    } = useMerchantActions();

    return {
        updateOrAddMerchantByIdUsers,
        getResource: (searchQueryParams?: string) => getMerchantByIdUsers(
            RequestQueryMapper.from(searchQueryParams)
                .containsIn(Filters.merchantId, String(merchant.coreId))
                .toString()
        ),
        downloadCsv: (searchQueryParams?: string) => downloadCsv(
            RequestQueryMapper.from(searchQueryParams)
                .containsIn(Filters.merchantId, String(merchant.coreId))
                .toString()
        )
    };
};
