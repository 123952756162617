import { createSlice } from "@reduxjs/toolkit";
import {
  loginRequestFulfiled,
  logoutRequestFulfiled,
  userRequestFulfilled,
  userRequestLoading,
  userRequestRejected
} from "./reducers";
import initialState, { sliceToken } from "./state";
import { loginThunk, logoutThunk, userThunk } from "./thunk";

const slice = createSlice({
  name: sliceToken,
  initialState,
  reducers: {
    logout: logoutRequestFulfiled,
  },
  extraReducers: builder =>
    builder
      .addCase(userThunk.pending, userRequestLoading)
      .addCase(userThunk.fulfilled, userRequestFulfilled)
      .addCase(userThunk.rejected, userRequestRejected)
      .addCase(loginThunk.fulfilled, loginRequestFulfiled)
      .addCase(logoutThunk.fulfilled, logoutRequestFulfiled)
});

export const { name } = slice;
export const { logout } = slice.actions;
export default slice.reducer;
