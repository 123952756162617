import { memo } from "react";
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Icon from "ui/atoms/Icon";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

const TableActions = ({ row }: GridRowParams) => {
    const {
        markConsumerAsKYCed,
        manageDuplicates,
        moreDetails
    } = useConsumerContext();

    return (
        <Tooltip
            arrow
            placement="left"
            title={(
                <List dense disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton
                            onMouseDown={markConsumerAsKYCed(row)}
                        >
                            <ListItemText primary="Mark as KYC'd" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onMouseDown={manageDuplicates(row)}
                        >
                            <ListItemText primary="Manage duplicates" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onMouseDown={moreDetails(row)}
                        >
                            <ListItemText primary="See more details" />
                        </ListItemButton>
                    </ListItem>
                </List>
            )}
        >
            <GridActionsCellItem
                label="More"
                icon={(
                    <Icon name="MoreVert" />
                )}
            />
        </Tooltip>
    );
};

export default memo(TableActions);
