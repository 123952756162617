import { getAccountType } from "features/merchants/helpers";
import { MerchantProfileTab } from "../MerchantProfileProvider";
import { stateFactory } from "./state";
import {
    MerchantProfileActionType,
    type MerchantProfileFinanceWallet,
    type MerchantProfileRepositoryAction,
    type MerchantProfileRepositoryState
} from "./types";
import { AccountType } from "consts/merchants";
import { compareIds } from "util/support";
import type { CoreBankingEntity } from "types";
import type { MerchantPayoutAbstractAccount } from "features/merchants/types";

export function reducer(state: MerchantProfileRepositoryState, action: MerchantProfileRepositoryAction) {
    switch (action.type) {
        case MerchantProfileActionType.FetchPayoutAccounts: {
            return {
                ...state,
                [MerchantProfileTab.Finance]: {
                    ...state[MerchantProfileTab.Finance],
                    ...action.payload,
                    account: decorateAccount(action.payload.account)
                }
            };
        }

        case MerchantProfileActionType.SelectPayoutAccount: {
            return {
                ...state,
                [MerchantProfileTab.Finance]: {
                    ...state[MerchantProfileTab.Finance],
                    account: decorateAccount(action.payload)
                }
            };
        }

        case MerchantProfileActionType.CreatePayoutAccount: {
            const accountType: AccountType = getAccountType(action.payload);
            const account = action.payload;

            return {
                ...state,
                [MerchantProfileTab.Finance]: {
                    ...state[MerchantProfileTab.Finance],
                    account,
                    [accountType]: [
                        ...state[MerchantProfileTab.Finance][accountType],
                        account
                    ]
                }
            };
        }

        case MerchantProfileActionType.UpdatePayoutAccount: {
            const accountType: AccountType = getAccountType(action.payload);
            const account = action.payload;

            return {
                ...state,
                [MerchantProfileTab.Finance]: {
                    ...state[MerchantProfileTab.Finance],
                    account,
                    [accountType]: state[MerchantProfileTab.Finance][accountType]
                        .map(payoutAccount => (
                            compareIds(payoutAccount.coreId, account.coreId)
                                ? account
                                : payoutAccount
                        ))
                }
            };
        }

        case MerchantProfileActionType.DeletePayoutAccount: {
            const accountType: AccountType = getAccountType(action.payload);
            const payoutAccounts: CoreBankingEntity[] = state[MerchantProfileTab.Finance][accountType];
            const updateState = {
                ...state[MerchantProfileTab.Finance],
                [accountType]: payoutAccounts
                    .filter(payoutAccount => !compareIds(
                        payoutAccount.coreId,
                        action.payload.coreId
                    ))
            };

            const getAccount = ({
                [AccountType.Bank]: bankAccouts,
                [AccountType.Crypto]: cryptoAccouts
            }: MerchantProfileFinanceWallet) => {
                switch (accountType) {
                    case AccountType.Bank: {
                        return (
                            bankAccouts.at(0) ??
                            cryptoAccouts.at(0) ??
                            null
                        );
                    }

                    case AccountType.Crypto: {
                        return (
                            cryptoAccouts.at(0) ??
                            bankAccouts.at(0) ??
                            null
                        );
                    }

                    default:
                        return null;
                }
            };

            return {
                ...state,
                [MerchantProfileTab.Finance]: {
                    ...state[MerchantProfileTab.Finance],
                    ...updateState,
                    account: decorateAccount(getAccount(updateState)),
                }
            };
        }

        case MerchantProfileActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
}

function decorateAccount(account?: MerchantPayoutAbstractAccount | null) {
    if (!account) {
        return null;
    }

    return {
        ...account,
        accountHolderName: account.accountHolderName || account.merchantName,
    };
}
