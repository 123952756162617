import type { LineProps } from "@nivo/line";
import type { Scalar } from "types";
import { ThemePaletteMode } from "consts/ui";
import { useThemeMode } from 'hooks';

export default function useLinearChartColors<TKey extends Scalar>(
    colorSchemeMap: Map<TKey, Record<ThemePaletteMode, string>>
): Pick<LineProps, 'colors'> {
    const mode = useThemeMode();

    return {
        colors: ({ id }) => `${colorSchemeMap.get(id)?.[mode]}`
    };
};
