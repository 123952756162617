import {
    GridToolbarColumnsButton as MuiGridToolbarColumnsButton,
    GridPreferencePanelsValue,
    useGridApiContext
} from "@mui/x-data-grid";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { memo, useState } from "react";

const GridToolbarColumnsButton = () => {
    const apiRef = useGridApiContext();
    const [, setOpenState] = useState(false);

    const onClick = () => {
        setOpenState((isOpen: boolean) => {
            if (isOpen) {
                apiRef.current.hidePreferences();
                return false;
            }

            apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
            return true;
        });
    };

    const onClickAway = ({ target }: globalThis.MouseEvent | globalThis.TouchEvent) => {
        if (!(target as HTMLDivElement).closest('.MuiDataGrid-panel')) {
            setOpenState(false);
        }
    };

    return (
        <ClickAwayListener
            onClickAway={onClickAway}
        >
            <MuiGridToolbarColumnsButton
                onClick={onClick}
            />
        </ClickAwayListener>
    );
};

export default memo(GridToolbarColumnsButton);
