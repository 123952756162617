import { memo } from "react";
import { Outlet } from "react-router-dom";
import ConsumerProvider from "./ConsumerProvider";
import ConsumersDialogs from "./ConsumersDialogs";

const Consumers = () => (
    <ConsumerProvider>
        <Outlet />
        <ConsumersDialogs />
    </ConsumerProvider>
);

export default memo(Consumers);
