import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from 'ui/atoms/Modal';
import { useProcessRowUpdate } from 'ui/molecules/TableGrids';
import { Filters } from 'consts/merchantPayouts';

type Props = ReturnType<typeof useProcessRowUpdate>;

const UpdateRollingReserveRetainedUntilDialog = ({
    isConfirmationPending,
    handleCancelAction,
    handleConfirmAction,
    isLoading,
    getProcessRowArguments
}: Props) => {
    const getRollingReserveRetainedUntil = (property: 'oldRow' | 'newRow') => {
        const processRowArguments = getProcessRowArguments();

        return processRowArguments?.[property][Filters.rollingReserveRetainedUntil];
    };

    return (
        <Modal
            isOpen={isConfirmationPending}
            onClose={handleCancelAction}
            titleSlot='Update Rolling Reserve Retained Until column?'
            actionsSlot={(
                <Stack
                    direction='row'
                    spacing={2}
                >
                    <Button
                        disabled={isLoading}
                        onClick={handleCancelAction}
                    >
                        Discard changes
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={handleConfirmAction}
                        color='error'
                    >
                        Save
                    </LoadingButton>
                </Stack>
            )}
            DialogProps={{
                sx: {
                    '& .MuiDialog-container .MuiPaper-root': {
                        maxWidth: '620px'
                    }
                }
            }}
            DialogContentProps={{
                sx: {
                    fontFamily: 'var(--primary-font)',
                    fontSize: '14px',
                    textAlign: 'center'
                }
            }}
        >
            <Box>
                The date you chose will impact the Rolling Reserve Paid,
                as the new payouts will take into account this date in order to add it to RR paid.
            </Box>
            <Box
                sx={{
                    mt: '10px',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '25px',
                    textAlign: 'center'
                }}
            >
                {getRollingReserveRetainedUntil('oldRow')} ➔ {getRollingReserveRetainedUntil('newRow')}
            </Box>
        </Modal>
    );
};

export default memo(UpdateRollingReserveRetainedUntilDialog);
