import { memo } from "react";
import MerchantPaymentMethodHistory from "../../MerchantPaymentMethodHistory";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const History = (merchantMethod: MerchantsMethodsPivot) => (
    <MerchantPaymentMethodHistory
        merchantMethod={merchantMethod}
    />
);

export default memo(History);
