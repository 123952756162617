import type { Props as AutocompleteProps } from "ui/atoms/Autocomplete";
import type { AutocompleteFilterProps } from "./types";
import type { Option } from "types";
import { compareIds } from "util/support";

export type SelectValue = Option | Option[] | null;

export default function useSelectFilter({
    id,
    isOptionEqualToValue = (option: Option, value: Option) => compareIds(option.id, value.id),
    onChange,
    ...props
}: Pick<
    AutocompleteFilterProps,
    | 'id'
    | 'isOptionEqualToValue'
    | 'onChange'
    | 'value'
    | 'options'
>) {
    const handleChange: AutocompleteProps['onChange'] = (_, payload: SelectValue) =>
        onChange({
            type: id,
            payload
        });


    const getValue = () => {
        const { value, options = [] } = props;

        const getValueFromOptions: (value: SelectValue) => SelectValue =
            (value: SelectValue) => {
                if (!value) {
                    return value;
                }

                if (Array.isArray(value)) {
                    const values = [];

                    for (const option of options) {
                        for (const v of value) {
                            if (compareIds(v.id, option.id)) {
                                values.push(option);
                            }
                        }
                    }

                    return values;
                }

                return options.find(option => isOptionEqualToValue(option, value)) ?? null;
            };

        return value
            ? getValueFromOptions(value)
            : value;
    };

    return {
        handleChange,
        getValue
    };
};
