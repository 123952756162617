import {
    FilterChangePayload,
    FilterSingleValue,
    FilterValueObject,
    ValueObjectStrategy
} from "../types";
import { TValueStrategy, ValueStrategy as ValueStrategyType } from "./types";

export default class ValueStrategy<
    TFilterValue,
    TFilterChangePayload extends FilterChangePayload = FilterChangePayload,
    TSingleValue extends FilterSingleValue = FilterSingleValue
> {
    public constructor(
        private filterValueStrategy: TValueStrategy<TFilterValue>,
        private adapterConstructor: new (
            value: TFilterValue,
            meta?: DOMStringMap
        ) => FilterValueObject<TFilterChangePayload, TSingleValue>
    ) { }

    public [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategyType) ||
            ValueStrategyType.Default;

        return new this.adapterConstructor(
            this.filterValueStrategy[initialValueStrategyName]!.getInitialValue(values)
        );
    }

    public [ValueObjectStrategy.Change](values: TFilterValue, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategyType) ||
            ValueStrategyType.Default;

        return new this.adapterConstructor(
            this.filterValueStrategy[changeValueStrategyName]!.handleChangeValue(values)
        );
    }

    public getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategyType) ||
            ValueStrategyType.Default;

        return this.filterValueStrategy[defaultValueStrategyName]!.getDefaultValue();
    }
}
