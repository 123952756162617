import { memo } from 'react';
import type { GridRowParams } from '@mui/x-data-grid-premium';
import Typography from '@mui/material/Typography';
import type { User } from 'features/users/types';

const DialogContent = ({ row }: GridRowParams<User>) => (
    <>
        <Typography
            variant="h5"
            gutterBottom
            sx={{
                fontSize: '24px'
            }}
        >
            Are you sure you want to delete user:
        </Typography>
        <Typography
            variant="h6"
            sx={{
                fontSize: '20px'
            }}
        >
            {[
                [
                    row.firstName,
                    row.lastName,
                ].join(' '),
                row.email
            ].join(' - ')}
        </Typography>

    </>
);

export default memo(DialogContent);
