import { splitWithModifier } from "util/filters";
import { isColumnVisibility, isPaginatable, isSortable, isSummableFilterVariant } from "./predicates";
import type {
    ColumnVisibilityFilterType,
    PaginatableFilterType,
    QueryMapperArgs,
    SortableFilterType,
    SummableFilterType
} from "./types";

export default function commonQueryMapper({
    filterName,
    filterValue,
    queryMapper,
    index = 0
}: QueryMapperArgs) {
    if (isColumnVisibility(filterName as ColumnVisibilityFilterType) ||
        isPaginatable(filterName as PaginatableFilterType)) {
        queryMapper.contains(filterName, filterValue);
        return;
    }

    if (isSortable(filterName as SortableFilterType)) {
        const [sortingColumn, sortingOrder] = splitWithModifier(filterValue);

        queryMapper
            .sortBy(sortingColumn, index)
            .sortOrder(sortingOrder.toUpperCase(), index);
        return;
    }

    if (isSummableFilterVariant(filterName as SummableFilterType)) {
        queryMapper.sum(filterValue);
        return;
    }
}
