import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";
import initialState, { sliceToken } from "./state";
import { getConsumersThunk } from "./thunks";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import type { Consumer, ConsumersState } from "./types";
import {
    consumersRequestFulfilled,
    consumersRequestLoading,
    consumersRequestRejected,
    updateConsumerEntityById
} from "./reducers";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        ...sliceMixin<ConsumersState>(initialState),
        updateEntityById: (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<Partial<Consumer>>) =>
            updateConsumerEntityById(state, payload),
    },
    extraReducers: builder => {
        builder
            .addCase(getConsumersThunk.pending, consumersRequestLoading)
            .addCase(getConsumersThunk.fulfilled, consumersRequestFulfilled)
            .addCase(getConsumersThunk.rejected, consumersRequestRejected)
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState,
        updateEntityById
    }
} = slice;
export default slice.reducer;
