import { ApiState } from "infrastructure/api";
import type { KYCSettingsState } from "./types";
import getKYCServices from "./getters/getKYCServices";
import { KYCProviders } from "consts/kyc";

export default function stateFactory(): KYCSettingsState {
    const kycServices = getKYCServices();

    return {
        kycSettingsLoadingState: ApiState.Idle,
        isKycSettingsChanged: false,
        kycProvider: KYCProviders.ShuftiPro,
        initialKYCServicesSnapshot: kycServices,
        kycServices
    };
};
