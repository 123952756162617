import { memo } from 'react';
import Service, { type ServiceProps } from '../Service';
import AdditionalOptions from './AdditionalOptions';

const IDVerification = (props: Omit<ServiceProps, 'serviceLabel'>) => (
    <Service
        {...props}
        serviceLabel='ID verification'
        additionalOptionsSlot={(
            <AdditionalOptions />
        )}
    />
);

export default memo(IDVerification);
