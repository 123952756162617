import { ApiRoutes } from "consts/enpoints/api";
import { FormSerializers, fetchApi } from "infrastructure/api";
import type { ID } from "types";
import type { UserPreferencesRequest } from "./types";

export const getConstants = () =>
    fetchApi(ApiRoutes.GetConstants);

export const getBitsoBanks = (query = '') =>
    fetchApi(`${ApiRoutes.GetBitsoBanks}${query}`);

export const getDomains = () =>
    fetchApi(ApiRoutes.GetApiDomains);

export const getCountries = (query = '') =>
    fetchApi(`${ApiRoutes.GetCountries}${query}`);

export const postFile = (body: FormData) =>
    fetchApi(ApiRoutes.PostFile, {
        serializer: FormSerializers.FormData,
        method: 'POST',
        body
    });

export const deleteFile = (fileKey: string) =>
    fetchApi(ApiRoutes.DeleteFile(fileKey), {
        method: 'DELETE'
    });

export const getUserPreferences = (userId: ID) =>
    fetchApi(ApiRoutes.GetUsersPreferences(userId));

export const createOrUpdateUserPreferences = ({ userId, preferences }: UserPreferencesRequest) =>
    fetchApi(ApiRoutes.PutUsersPreferences(userId), {
        method: 'PUT',
        body: {
            preferences
        }
    });
