import { memo } from "react";
import type { TextFieldProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import TextField from "ui/atoms/TextField";
import type { TextFieldFilterProps } from "./types";

const TextFieldFilter = ({ label, id, onChange, ...props }: TextFieldFilterProps) => {
    const defaultInputProps = {
        sx: { width: 211 }
    };

    const handleChange: TextFieldProps['onChange'] = ({ target }) => {
        const { value } = target;

        onChange({
            type: id,
            payload: value,
            meta: target.parentElement?.dataset
            /*{ name, id: value.trim() || undefined }*/
        });
    };

    const onClear = () => {
        onChange({
            type: id,
            payload: ''
            /*{ name: filterId, id: undefined }*/
        });
    };

    const value = props.value as string;

    return (
        <TextField
            {...defaultInputProps}
            {...props}
            value={value}
            id={id}
            label={label}
            onChange={handleChange}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <>
                        {Boolean(value) && (
                            <IconButton
                                size='small'
                                onClick={onClear}
                            >
                                <ClearIcon
                                    sx={{ marginRight: '0 !important' }}
                                    fontSize='small'
                                />
                            </IconButton>
                        )}
                    </>
                )
            }}
        />
    );
};

export default memo(TextFieldFilter);

/*export class TextFieldValueObject extends FilterValueObject<string> {
    // public toSerialized(): string[] {
    //     return [
    //         [this.value.trim(), this.meta?.modifier]
    //             .filter(Boolean)
    //             .join(':')
    //     ];
    // }
    public toArray(): string[] {
        return [this.value];
    }

    public serialize(value: string): string {
        return [value.trim(), this.meta?.modifier]
            .filter(Boolean)
            .join(':');
    }

    public isEmpty(value: string): boolean {
        return !value.trim().length;
    }
}


export const TextFieldValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new TextFieldValueObject(
            filterValueStrategy[FilterVariant.Text][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](value: string, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new TextFieldValueObject(
            filterValueStrategy[FilterVariant.Text][changeValueStrategyName]!.handleChangeValue(value),
            meta
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.Text][defaultValueStrategyName]!.getDefaultValue();
    }
};*/
