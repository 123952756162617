import Box, { type BoxProps } from "@mui/material/Box";
import { memo, type PropsWithChildren } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export type FormProps = BoxProps;

const Form = ({ children, ...props }: PropsWithChildren<FormProps>) => (
    <Box
        {...props}
        sx={{
            m: 3,
            pb: 3,
            ...props.sx
        }}
    >
        <Card>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    </Box>
);

export default memo(Form);
