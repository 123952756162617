import type { AsyncThunk } from "@reduxjs/toolkit";
import { ChangeEvent, useCallback } from "react";
import type { ThunkReturnType } from "types";
import useRequest from "./useRequest";
import { FORM_DATA_FILE_KEY } from "consts/forms";

type UseBulkImportArg<TResponse, TRequest = FormData> = {
    readonly thunk: AsyncThunk<ThunkReturnType<TResponse>, TRequest, {}>,
    readonly form?: HTMLFormElement;
    readonly paramName?: string;
};

export default function useBulkImport<TResponse>({ thunk, form, paramName = FORM_DATA_FILE_KEY }: UseBulkImportArg<TResponse>) {
    const bulkImportRequest = useRequest({ thunk });

    return useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        const { files } = target;

        if (!files) {
            return;
        }

        const formData = new FormData(form);

        for (const file of Array.from(files)) {
            formData.append(paramName, file);
        }

        return bulkImportRequest(formData);
    }, [form, paramName, bulkImportRequest]);
};
