import { Transaction, TransactionDetail } from "features/transactions/types";
import transactionHistory from 'config/transactionHistory.json';
import transactionDetails from 'config/transactionDetails.json';
import { Mapped } from "types";

export const transactionHistoryTransformer = (transactionHistoryData: Array<Transaction>) => {
    return transactionHistoryData.map(transaction =>
        Object.entries(transaction).reduce((acc, [key, value]) => {
            if (transactionHistory.includes(key)) {
                return {
                    ...acc,
                    [key]: value
                };
            }
            return acc;
        }, {}));
};

export const transactionDetailTransformer = (transactionDetail: TransactionDetail) => {
    return transactionDetails.reduce((acc, { field, label }) => {
        // if (field in transactionDetail) {
        //     return {
        //         ...acc,
        //         [field]: label
        //     };
        // }

        // return acc;
        return {
            ...acc,
            [field]: label
        };
    }, {});
};

export const labelParser = <T extends Mapped<unknown>>(label: string[], props: T) =>
    label
        .map(labelWord => {
            if (labelWord.includes('|')) {
                const labelWithoutStatus = labelWord.split('>');

                const field = labelWithoutStatus.at(-1)?.trim();
                const states = labelWithoutStatus.slice(0, -1).join('').split('|');

                const state = states.find(state => state.trim().endsWith(props[field!] as string));

                return state?.split(':')[0].trim();
            }

            return labelWord;
        })
        .filter(Boolean)
        .join(' ');

export const fieldParser = <T extends any>(field: string, props: T) => {
    const propertyAccessors = field.split('.');

    const value: string = propertyAccessors.reduce((object: any = {}, property) =>
        object[property], props);

    return value;
};
