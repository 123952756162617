import { useRef } from "react";
import type { Subscriber, SubscriberKey } from "./types";

export default function useContextPublisher() {
    const subscribersMapRef = useRef<Map<SubscriberKey, Subscriber>>(new Map());

    const addSubscriber = ([subscriberKey, subscriber]: [SubscriberKey | void, Subscriber]) => {
        if (subscriberKey) {
            subscribersMapRef.current.set(subscriberKey, subscriber);
        }
    };

    const removeSubscriber = (subscriberKey: SubscriberKey) =>
        subscribersMapRef.current.delete(subscriberKey);

    const notify = () =>
        subscribersMapRef.current.forEach(subscriber => subscriber());

    return {
        addSubscriber,
        removeSubscriber,
        notify
    };
};
