import { memo } from "react";
import { Service, useServiceEnable, useServiceAdditionalOptions } from "./Service";
import { TicketingSystemService } from "../TicketingSystemProvider/types";
import { SecondsInterval } from "./AdditionalOptions";

const EmailConfirmation = () => (
    <Service
        {...useServiceEnable(TicketingSystemService.EmailConfirmation)}
        label='User Email Confirmation'
        additionalOptionsSlot={(
            <SecondsInterval
                {...useServiceAdditionalOptions(TicketingSystemService.EmailConfirmation)}
            />
        )}
    />
);

export default memo(EmailConfirmation);