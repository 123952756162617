import { memo } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { ConsumerActionType, useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

const BulkKYC = () => {
    const { bulkKYC, requestProcessingType } = useConsumerContext();

    return (
        <LoadingButton
            variant="contained"
            color='secondary'
            loading={requestProcessingType.has(ConsumerActionType.BulkKYC)}
            onClick={bulkKYC}
        >
            Bulk KYC (.CSV)
        </LoadingButton>
    );
};

export default memo(BulkKYC);
