import { memo } from 'react';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { type MenuItemProps } from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import type { Option } from 'types';

type Props = {
    readonly checked: boolean;
    readonly value: Option;
    readonly onClick: MenuItemProps['onClick'];
};

const SelectMenuItem = ({ checked, value, onClick }: Props) => (
    <MenuItem
        key={value.id}
        value={value.name}
        onClick={onClick}
    >
        <Checkbox
            size='small'
            checked={checked}
        />
        <ListItemText
            primaryTypographyProps={{
                variant: 'subtitle2'
            }}
            primary={value.name}
        />
    </MenuItem>
);

export default memo(SelectMenuItem);
