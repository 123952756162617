import type { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { useCallback } from 'react';
import {
    type GridRowParams,
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_FIELD,
} from "@mui/x-data-grid-premium";
import TableActions from "../TableActions";

export default function useTableActions() {
    const getInitialState: () => GridInitialStatePremium = useCallback(() => ({
        pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD],
            right: [GRID_ACTIONS_COLUMN_TYPE]
        }
    }), []);

    const getColumnsOverrides = () => [
        {
            field: GRID_ACTIONS_COLUMN_TYPE,
            type: GRID_ACTIONS_COLUMN_TYPE,
            width: 50,
            maxWidth: 50,
            getActions: (rowParams: GridRowParams) => [
                <TableActions {...rowParams} />
            ]
        }
    ]

    return {
        getInitialState,
        getColumnsOverrides
    };
};
