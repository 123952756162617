import { memo } from "react";
import TransactionStatusCassette from "./TransactionStatusCassette";
import AvgTransactionSize from "./AvgTransactionSize";
import CassetteLayout from "ui/layouts/Cassette";

const StatisticDashboardCassettes = () => (
    <CassetteLayout>
        <TransactionStatusCassette
            statusLabel='Succeeded trx.'
            status='SUCCEEDED'
        />
        <TransactionStatusCassette
            statusLabel='Failed trx.'
            status='FAILED'
        />
        <TransactionStatusCassette
            statusLabel='Rejected trx.'
            status='REJECT'
        />
        <AvgTransactionSize />
    </CassetteLayout>
);

export default memo(StatisticDashboardCassettes);
