import { GridRowParams } from '@mui/x-data-grid-premium';
import { Filters } from 'consts/merchantPayouts';
import { memo } from 'react';

const DeleteIncrementExchangeRatesDialogBody = ({ row }: GridRowParams) => (
    <>
        "{[
            row[Filters.MOR],
            row[Filters.PSP],
            row[Filters.transactionCurrency],
            row[Filters.payoutCurrency],
            row[Filters.currencyExchangeRate],
            row[Filters.dateFrom],
            row[Filters.dateTo]
        ].join('-')}"
    </>
);

export default memo(DeleteIncrementExchangeRatesDialogBody);
