import { useCallback } from "react";
import { merchantTabValueMapping } from "consts/merchants";
import UnknownTabMappingException from "features/merchantPayouts/exceptions";
import { selectIsMerchantsLoading, selectMerchantsActiveTab } from "features/merchants/selectors";
import { changeTab } from "features/merchants/slice";
import { useAppDispatch, useTypedSelector } from "hooks";
import { useResourceContext } from "ui/widgets/Resource";
import { TabManager } from "util/tab";

export default function useMerchants() {
    const dispatch = useAppDispatch();

    const { resource } = useResourceContext();

    const isMerchantsLoading = useTypedSelector(selectIsMerchantsLoading());
    const activeTab = useTypedSelector(selectMerchantsActiveTab);

    const getTabValue = () =>
        TabManager.getTabValue(merchantTabValueMapping, activeTab);

    const onChangeTab = useCallback((value: string) => {
        if (!merchantTabValueMapping.has(value)) {
            throw new UnknownTabMappingException(
                'Tab value does not satisfy valueTabMapping',
                {}
            );
        }

        dispatch(changeTab(merchantTabValueMapping.get(value)!));
    }, [dispatch]);

    return {
        disabled: Boolean(isMerchantsLoading || resource),
        getTabValue,
        onChangeTab
    };
};
