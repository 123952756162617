import { memo } from 'react';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import Box, { BoxProps } from '@mui/material/Box';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

export type LabeledSwitchProps = SwitchProps & {
    readonly label?: FormControlLabelProps['label'];
    readonly FormControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
    readonly RootProps?: BoxProps;
    readonly FormGroupProps?: FormGroupProps;
    readonly SwitchProps?: SwitchProps;
};

const LabeledSwitch = ({
    label,
    RootProps = {},
    FormGroupProps = {},
    FormControlLabelProps = {},
    SwitchProps = {},
    ...restSwitchProps
}: LabeledSwitchProps) => (
    <Box
        {...RootProps}
    >
        <FormGroup
            {...FormGroupProps}
        >
            <FormControlLabel
                {...FormControlLabelProps}
                control={(
                    <Switch
                        {...SwitchProps}
                        {...restSwitchProps}
                    />
                )}
                label={label}
            />
        </FormGroup>
    </Box>
);

export default memo(LabeledSwitch);
