import { ThemePaletteMode } from 'consts/ui';
import type { SettingsValueProps } from './types';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: ThemePaletteMode.Light,
  themeDirection: 'ltr',
  themeContrast: 'bold',
  themeLayout: 'vertical',
  themeColorPresets: 'blue',
  themeStretch: false,
};
