import { FunctionComponent, memo } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import useToolbar, { ToolbarElements } from "./useToolbar";

type Props = {
    readonly [P in ToolbarElements]?: FunctionComponent;
};

const Toolbar = (props: Props) => {
    const { getToolbarElements, toolbarComponentsRepository } = useToolbar();

    const SearchComponent = props.search ?? toolbarComponentsRepository.get('search')!;

    return (
        <GridToolbarContainer
            sx={{
                m: 2,
                justifyContent: 'space-between'
            }}
        >
            <Box>
                {getToolbarElements({
                    omit: ['search']
                })
                    .map(toolbarElement => {
                        const elementType = toolbarElement as ToolbarElements;

                        if (toolbarComponentsRepository.has(elementType)) {
                            const Component = props[elementType] ?? toolbarComponentsRepository.get(elementType)!;

                            return (
                                <Component key={elementType} />
                            );
                        }

                        return null;
                    })}
            </Box>
            <Box>
                <SearchComponent />
            </Box>
        </GridToolbarContainer>
    );
};

export default memo(Toolbar);
