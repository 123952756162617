import useBulkImport from "hooks/useBulkImport";
import { ChangeEvent, useState } from "react";
import type { ResourceResponse } from "types";
import { useModal } from "ui/atoms/Modal";
import { useDetectComponentMounted } from "hooks";
import type { ImportCSVContextType, UseImportCSVArg, UseImportResultArg } from "./Props";

export default function useImportCSV({ thunk }: UseImportCSVArg) {
    const isComponentMounted = useDetectComponentMounted();
    const { open, onOpen, onClose } = useModal();

    const [result, setResult] = useState<UseImportResultArg['data']>([]);

    const bulkImport = useBulkImport({
        thunk
    });

    const handleClose = () => {
        onClose();
        setResult([]);
    };

    const onImportCSV: ImportCSVContextType = async (event: ChangeEvent<HTMLInputElement>) => {
        const [request] = bulkImport(event)!;

        const result = (await request) as ResourceResponse<UseImportResultArg['data']>;

        if (isComponentMounted.current && result) {
            setResult(result.data);
            onOpen();
        }
    };

    return {
        open,
        result,
        onClose: handleClose,
        onImportCSV
    };
};
