import CountrySelector, { type CountrySelectorProps } from "ui/widgets/CountrySelector";

type Props = Pick<
    CountrySelectorProps,
    | 'value'
    | 'onChange'
    | 'label'
>;

const ConsumerCountrySelector = ({ value, onChange, label }: Props) => (
    <CountrySelector
        label={`Country ISO code (Required for ${label}))`}
        value={value}
        onChange={onChange}
        FormControlProps={{
            sx: {
                width: '100%',
                mb: 1
            }
        }}
    />
);

export default ConsumerCountrySelector;
