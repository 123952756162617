import { RequestQueryMapper } from "util/request-query-mapper";

export default function useAggregates<TResponse = Promise<Response>>(
    aggregates: (requestQueryMapper: RequestQueryMapper) => string | RequestQueryMapper
) {
    return (
        fetchData: (searchQueryParams: string) => TResponse
    ) => (searchQueryParams: string) => (
        fetchData(
            aggregates(RequestQueryMapper.from(searchQueryParams))
            .toString()
        )
    )
};
