import { ApiState } from "infrastructure/api";
import { PaymentMethodsState } from "./types";

const state: PaymentMethodsState = {
    paymentMethodsLoadingState: ApiState.Idle,
    paymentMethodsSlice: null
};

export const sliceToken = 'paymentMethods';

export default state;
