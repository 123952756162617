import { Filters } from "./transactions";

export const multi = [
    Filters.merchantId,
    Filters.methodId,
    Filters.PSP,
    Filters.MOR,
    Filters.currency
];

export const text = [
    Filters.email,
    Filters.hashedEmail
];
