import type { ID, Option } from "types";

export class ManagementApiMapper {
    public static from(relation: string) {
        return new this(relation);
    }

    private constructor(
        private relation: string
    ) { }

    public get(key: string) {
        return `${this.relation}${key}`;
    }
}

export const toIds = <TArray>(collection?: TArray) =>
    [collection]
        .flat()
        .filter((item: any) => ![null, undefined].includes(item))
        .map(item =>
            typeof item === 'object'
                ? (item as Option).id
                : item
        ) as ID[];
