import type { TablePreferences } from 'features/general/types';
import useUserPreferencesApi, { type UseUserPreferencesArg } from 'features/general/useUserPreferences';
import type { ID } from 'types';

export const useUserPreferences = useUserPreferencesApi;

export const useUserPreferencesNoop = (_: UseUserPreferencesArg): ReturnType<typeof useUserPreferencesApi> => ({
    getUserPreferences: (_: ID) => [],
    createOrUpdateUserPreferences: async (_: Partial<TablePreferences>) => {},
});
