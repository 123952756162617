import { memo } from "react";
import PaymentMethodLimitsForm, { type PaymentMethodLimitsFormProps } from "ui/forms/Merchant/PaymentMethodLimits";

const MethodLimits = (props: PaymentMethodLimitsFormProps) => (
    <PaymentMethodLimitsForm
        {...props}
    />
);

export default memo(MethodLimits);
