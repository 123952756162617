import { TableGridMode } from "consts/table";
import { memo } from "react";
import { Resource, type ResourceProps } from "ui/widgets/Resource";
import { Filters, single, Status } from "consts/users";
import { FilterVariant } from "consts/filters";
import MerchantUserCreateEdit from "./MerchantUserCreateEdit";
import ToolbarFilters, {
    activeInactiveSelectorKey,
    useToolbarFiltersContext
} from "../shared/ToolbarFilters";

type Props = Pick<
    ResourceProps,
    | 'selectIsTableUninitialized'
    | 'selectIsTableLoading'
    | 'selectTableDataSlice'
    | 'useResource'
    | 'DetailProps'
    | 'adapters'
    | 'LayoutProps'
    | 'columns'
    | 'pinnedColumns'
    | 'Toolbar'
>;

const MerchantUsers = ({
    selectIsTableUninitialized,
    selectIsTableLoading,
    selectTableDataSlice,
    DetailProps,
    useResource,
    adapters,
    LayoutProps,
    columns,
    pinnedColumns,
    Toolbar
}: Props) => {
    const { filtersRef } = useToolbarFiltersContext();

    return (
        <Resource
            name='Merchant User'
            useResource={useResource}
            mode={TableGridMode.MerchantUser}
            selectIsTableUninitialized={selectIsTableUninitialized}
            selectIsTableLoading={selectIsTableLoading}
            selectTableDataSlice={selectTableDataSlice}
            Toolbar={Toolbar}
            DetailProps={DetailProps}
            Detail={MerchantUserCreateEdit}
            LayoutProps={LayoutProps}
            adapters={adapters}
            pinnedColumns={pinnedColumns}
            columns={columns}
            filtersRef={filtersRef}
            FiltersProps={{
                sx: {
                    display: 'none'
                }
            }}
            filterTypes={new Map<FilterVariant, string[]>([
                [FilterVariant.SingleOption, single],
            ])}
        />
    );
};

export default memo((props: Props) => (
    <ToolbarFilters
        toolbarFilters={new Map([
            [activeInactiveSelectorKey, {
                filterId: Filters.status,
                options: [
                    { id: Status.Active, name: Status.Active },
                    { id: Status.Inactive, name: Status.Inactive }
                ]
            }]
        ])}
    >
        <MerchantUsers
            {...props}
        />
    </ToolbarFilters>
));
