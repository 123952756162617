import { useMemo } from "react";
import { boolean, mixed, number, object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.Descriptor]: string()
                .max(255)
                .label('Descriptor'),
            [FormField.AccountStatus]: mixed()
                .required()
                .label('Account Status'),
            [FormField.WhitelistCheck]: boolean(),
            [FormField.ClientCallbackUrl]: string()
                .url()
                .nullable()
                .label('Client Callback Url'),
            [FormField.ClientNotificationUrl]: string()
                .url()
                .nullable()
                .label('Client Notification Url'),
            [FormField.AccountKYCOption]: mixed()
                .required()
                .label('Account KYC Option'),
            [FormField.DomainCheck]: boolean(),
            [FormField.DailyAmountLimit]: number()
                .label('Merchant Daily Amount Limit')
        })
        , []);
};
