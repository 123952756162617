import useLocalStorage from 'hooks/useLocalStorage';
import { defaultSettings } from './config';
import type { SettingsValueProps } from './types';

export default function useSettingsRepository() {
    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

    return [
        {
            ...settings,
            themeColorPresets: defaultSettings.themeColorPresets,
            themeContrast: defaultSettings.themeContrast,
            themeDirection: defaultSettings.themeDirection
        } as SettingsValueProps,
        setSettings
    ];
};
