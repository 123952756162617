import { PER_PAGE_SIZE } from "consts/transactions";
import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { getPaymentMethods } from "features/paymentMethods/api";
import { changePaymentMethodsLoadingState, changePaymentMethodsSlice } from "features/paymentMethods/slice";
import { ApiState } from "infrastructure/api";
import { selectIsNotMor } from "features/auth/selectors";

const useActions = () => ({
    getAllPaymentMethods: useFetchFeatureResource({
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE,
        resourceRequest: getPaymentMethods,
        changeLoadingState: changePaymentMethodsLoadingState,
        changeSlice: changePaymentMethodsSlice,
        condition: getState => selectIsNotMor(getState()) && ![
            ApiState.Succeeded,
            ApiState.Pending
        ].includes(getState().paymentMethods.paymentMethodsLoadingState)
    })
});

export default useActions;
