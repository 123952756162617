import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { splitWithModifier } from "util/filters";
import { QueryMapperArgs } from "./types";

export const exclusiveSingleColumnQueryMapper = ({
    filterName,
    filterValue,
    queryMapper,
    index = 0
}: QueryMapperArgs) => {
    queryMapper.inclusiveRange(filterName, filterValue, index);
};

export const exclusiveMultiColumnQueryMapper = ({
    filterName,
    filterValue,
    queryMapper,
    index = 0
}: QueryMapperArgs) => {
    const filterColumnName = splitWithModifier(
        filterName,
        MULTI_COLUMN_SEPARATOR
    )[index];

    if (!filterColumnName) {
        return;
    }

    return exclusiveSingleColumnQueryMapper({
        filterName: filterColumnName,
        filterValue,
        queryMapper,
        index
    });
};