import {
    esTransactionsGroupByStatusQueryToken,
    topMerchantSucceededTransactionsByUniqueEmailQueryToken,
    useStatisticDashboardContext,
    useStatisticDashboardUtils
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import type { TopMerchantSucceededTransactionsByUniqueEmailRecord } from "./types";

export default function useTopMerchants() {
    const statisticDashboard = useStatisticDashboardContext();

    const {
        payload: topMerchantSucceededTransactionsByUniqueEmailQueryResponse
    } = statisticDashboard[topMerchantSucceededTransactionsByUniqueEmailQueryToken];

    const isLoading = (
        statisticDashboard[topMerchantSucceededTransactionsByUniqueEmailQueryToken].isLoading ||
        statisticDashboard[esTransactionsGroupByStatusQueryToken].isLoading
    );

    const {
        getTransactionAmountPercentageByStatus
    } = useStatisticDashboardUtils();

    const getTopMerchantSucceededTransactionsByUniqueEmail = () =>
        Array.from(
            topMerchantSucceededTransactionsByUniqueEmailQueryResponse?.rows.reduce((topMerchantMap, row) => {
                const amountEuro = Number(row.at(0));
                const successfulTransactionsCount = Number(row.at(1));
                const merchantName = String(row.at(2));

                return topMerchantMap.set(merchantName, {
                    name: merchantName,
                    succeededTransactions: successfulTransactionsCount,
                    amount: amountEuro
                });
            }, new Map<string, TopMerchantSucceededTransactionsByUniqueEmailRecord>())
                .values() ?? []
        )
        .filter(({ name }) => isLoading || name);

    const getPercentageFromTotalAmount = (amount: number) => getTransactionAmountPercentageByStatus({
        status: 'SUCCEEDED',
        amount,
        rowParser: row => ({
            status: String(row.at(-1)),
            groupCount: Number(row.at(1))
        })
    });

    return {
        isLoading,
        getTopMerchantSucceededTransactionsByUniqueEmail,
        getPercentageFromTotalAmount
    };
};
