import { useMemo } from 'react';
import { object, string } from 'yup';
import { capitalizeFirstLetterFormatter } from 'util/formaters';
import { FormFields } from './types';

export const getInitialValues = () => ({
    [FormFields.SearchQuery]: '',
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormFields.SearchQuery]: string()
                .label(capitalizeFirstLetterFormatter(FormFields.SearchQuery))
                .required()
                .max(5000)
        })
        , []);
