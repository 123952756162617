import { memo } from "react";
import Box from "@mui/material/Box";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import type { ActionProps } from "./types";
import { DownloadableAction } from "ui/molecules/Action";

const Action = ({ value, downloadFile }: ActionProps) => {
    const getValue = () =>
        (value instanceof File)
            ? (value as File).name
            : String(value);

    return (
        <DownloadableAction
            file={getValue()}
            downloadFile={downloadFile}
            sx={{
                width: 'initial',
                height: 'initial',
                borderRadius: 1
            }}
        >
            <SaveAltIcon color='disabled' />
            <Box
                sx={{
                    textTransform: 'none',
                    ml: 1
                }}
            >
                {getValue()}
            </Box>
        </DownloadableAction>
    );
};

export default memo(Action);
