import { FC, memo } from "react";
import Box from "@mui/material/Box";
import MasterConnection, { UseMasterConnectionArg } from "ui/organizms/Merchants/MasterConnection";
import {
    getTicketingSystemZendeskTicketingSwitch,
    getTicketingSystemSwitch,
    updateTicketingSystemZendeskTicketingSwitch,
    updateTicketingSystemSwitch
} from "features/ticketing/api";
import MasterConnectionLabel from "./MasterConnectionLabel";
import { BooleanSwitchValue, MOR, Setup } from "consts/general";

type Props = {
    readonly processInitialDataResponse?: UseMasterConnectionArg<string>['processInitialDataResponse'];
};

const TicketingSystemMasterConnections: FC<Props> = ({ processInitialDataResponse }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, auto)',
            columnGap: 5
        }}
    >
        <MasterConnection
            getSetupsMasterConnectionSwitch={getTicketingSystemZendeskTicketingSwitch}
            updateSetupsMasterConnectionSwitch={updateTicketingSystemZendeskTicketingSwitch}
            renderLabel={() => [
                <MasterConnectionLabel
                    key={Setup.ZendeskTicketingSystem}
                    title="Enable or Disable Zendesk ticketing provider"
                >
                    Zendesk Connection
                </MasterConnectionLabel>
            ]}
            processInitialDataResponse={processInitialDataResponse}
            content={[
                {
                    dialogContent: [
                        'Are you sure you want to enable ticketing provider connection for all merchants?',
                        'This action will turn on Zendesk’s services.'
                    ],
                    actionContent: 'Enable Zendesk'
                },
                {
                    dialogContent: [
                        'Are you sure you want to disable ticketing provider connection for all merchants?',
                        'This action will shut down Zendesk’s services.',
                        'Enabled ticketing settings will be available on the manual route'
                    ],
                    actionContent: 'Disable Zendesk'
                }
            ]}
        />
        <MasterConnection
            getSetupsMasterConnectionSwitch={getTicketingSystemSwitch}
            updateSetupsMasterConnectionSwitch={updateTicketingSystemSwitch}
            renderLabel={() => [
                <MasterConnectionLabel
                    key={Setup.TicketingSystem}
                    title="Enable or Disable ticketing support"
                >
                    Ticketing Support
                </MasterConnectionLabel>
            ]}
            processInitialDataResponse={processInitialDataResponse}
            content={[
                {
                    dialogContent: [
                        'Are you sure you want to enable the support ticketing functionality for all merchants?',
                        'This action will turn on the entire support ticketing functionality.',
                        "If you would want the requests to go via Zendesk (ticketing system provide), please make sure you enable 'Zendesk Connection' as well."
                    ],
                    actionContent: 'Enable Ticketing Support'
                },
                {
                    dialogContent: [
                        'Are you sure you want to disable the support ticketing functionality for all merchants?',
                        'This action will shut down the entire support ticketing functionality.'
                    ],
                    actionContent: 'Disable Ticketing Support'
                }
            ]}
        />
    </Box>
);

TicketingSystemMasterConnections.defaultProps = {
    processInitialDataResponse: ({ data }) => data ?? ({
        MOR: MOR.Default,
        setupValue: BooleanSwitchValue.Off
    })
};

export default memo(TicketingSystemMasterConnections);
