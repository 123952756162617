import type {
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount
} from "features/merchants/types";
import type { MerchantProfileRepositoryState } from "./types";
import { ApiState } from "infrastructure/api";
import { MerchantProfileTab } from "../MerchantProfileProvider";
import { AccountType } from "consts/merchants";

export const stateFactory: () => MerchantProfileRepositoryState = () => ({
    [MerchantProfileTab.Finance]: {
        [AccountType.Bank]: Array.of<MerchantPayoutAccount>(),
        [AccountType.Crypto]: Array.of<MerchantPayoutCryptoAccount>(),
        account: null,
        apiState: ApiState.Idle
    }
});
