import { memo } from "react";
import Table from "ui/widgets/Table";
import {
    selectConsumersSlice,
    selectIsConsumersLoading,
    selectIsConsumersUninitialized
} from "features/consumers/selectors";
import ConsumersTableToolbar from "./ConsumersTableToolbar";
import FrontPanelWidget from "./FrontPanelWidget";
import useConsumersTable from "./useConsumersTable";
import { TableGridMode } from "consts/table";
import useRequestQueryOverrideDecorator from "./useConsumersTable/useRequestQueryOverrideDecorator";

const ConsumersTable = () => {
    const {
        isRowSelectable,
        getConsumers,
        getInitialState,
        getColumnsOverrides,
        downloadCsv,
        filterTypes,
        CellProps,
        FiltersProps,
        selectionModel,
        setSelectionModel
    } = useConsumersTable();

    return (
        <Table
            disableSelectionOnClick
            checkboxSelection
            mode={TableGridMode.Consumer}
            isRowSelectable={isRowSelectable}
            fetchTableData={getConsumers}
            filterTypes={filterTypes}
            selectIsTableUninitialized={selectIsConsumersUninitialized}
            selectIsTableLoading={selectIsConsumersLoading}
            selectTableDataSlice={selectConsumersSlice}
            downloadCsv={downloadCsv}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
            initialState={getInitialState()}
            columns={getColumnsOverrides()}
            Toolbar={ConsumersTableToolbar}
            CellProps={CellProps}
            requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
            pinnedColumnsSx={{
                right: {
                    width: 50
                }
            }}
            FiltersProps={{
                ...FiltersProps,
                FrontPanelWidget
            }}
        />
    );
};

export default memo(ConsumersTable);
