import type { MerchantSettings } from "features/merchants/types";
import type { useForm } from "hooks";
import type { BootstrapableForm, Option } from "types";

export const enum FormField {
    Descriptor = 'obs',
    AccountStatus = 'activeStatus',
    WhitelistCheck = 'isEchoSystem',
    ClientCallbackUrl = 'redirectUrl',
    ClientNotificationUrl = 'notificationUrl',
    AccountKYCOption = 'kycType',
    DomainCheck = 'isDomainDriven',
    DailyAmountLimit = 'maxTotalAmount24'
};

export type MerchantAccountAdvancedSettings = Partial<
    Pick<
        MerchantSettings,
        | 'obs'
        | 'activeStatus'
        | 'isEchoSystem'
        | 'redirectUrl'
        | 'notificationUrl'
        | 'kycType'
        | 'isDomainDriven'
        | 'maxTotalAmount24'
    >
>;

export type MerchantAccountAdvancedSettingsForm =
    & BootstrapableForm<MerchantAccountAdvancedSettings, {
        readonly [FormField.AccountStatus]: Option[];
        readonly [FormField.AccountKYCOption]: Option[];
    }>;

export type MerchantAccountAdvancedSettingsProps =
    & ReturnType<typeof useForm<MerchantAccountAdvancedSettings>>
    & MerchantAccountAdvancedSettingsForm;
