import { createSlice } from "@reduxjs/toolkit";
import {
  bitsoBanksRequestFulfilled,
  bitsoBanksRequestLoading,
  bitsoBanksRequestRejected,
  constantsRequestFulfilled,
  constantsRequestLoading,
  constantsRequestRejected,
  countriesRequestFulfilled,
  countriesRequestLoading,
  countriesRequestRejected,
  domainsRequestFulfilled,
  domainsRequestLoading,
  domainsRequestRejected,
  userPreferencesRequestFulfilled,
  userPreferencesRequestLoading,
  userPreferencesRequestRejected
} from "./reducers";
import initialState from "./state";
import {
  createOrUpdateUserPreferencesThunk,
  getBitsoBanksThunk,
  getConstantsThunk,
  getCountriesThunk,
  getDomainsThunk,
  getUserPreferencesThunk
} from "./thunks";
import { fromPaginateResourceResponse } from "util/api";

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    toggleSideMenu: (state) => {
      state.isSideMenuOpen = !state.isSideMenuOpen;
    },
    addNotification: (state, { payload }) => {
      state.notifications.push(payload);
    },
    removeNotification: (state) => {
      state.notifications.shift();
    },
    changeCountriesLoadingState: (state, { payload }) => {
      state.countriesLoadingState = payload;
    },
    changeCountriesSlice: (state, { payload }) => {
      state.countries = fromPaginateResourceResponse(payload);
    },
    changeBitsoBanksLoadingState: (state, { payload }) => {
      state.bitsoBanksLoadingState = payload;
    },
    changeBitsoBanksSlice: (state, { payload }) => {
      state.bitsoBanks = payload.data;
    }
  },
  extraReducers: builder =>
    builder
      // Constants
      .addCase(getConstantsThunk.pending, constantsRequestLoading)
      .addCase(getConstantsThunk.fulfilled, constantsRequestFulfilled)
      .addCase(getConstantsThunk.rejected, constantsRequestRejected)
      .addCase(getDomainsThunk.pending, domainsRequestLoading)
      .addCase(getDomainsThunk.fulfilled, domainsRequestFulfilled)
      .addCase(getDomainsThunk.rejected, domainsRequestRejected)
      // Countries
      .addCase(getCountriesThunk.pending, countriesRequestLoading)
      .addCase(getCountriesThunk.fulfilled, countriesRequestFulfilled)
      .addCase(getCountriesThunk.rejected, countriesRequestRejected)
      // User preferences
      .addCase(getUserPreferencesThunk.pending, userPreferencesRequestLoading)
      .addCase(getUserPreferencesThunk.fulfilled, userPreferencesRequestFulfilled)
      .addCase(getUserPreferencesThunk.rejected, userPreferencesRequestRejected)
      .addCase(createOrUpdateUserPreferencesThunk.fulfilled, userPreferencesRequestFulfilled)
      .addCase(createOrUpdateUserPreferencesThunk.rejected, userPreferencesRequestRejected)
      // Bitso banks
      .addCase(getBitsoBanksThunk.pending, bitsoBanksRequestLoading)
      .addCase(getBitsoBanksThunk.fulfilled, bitsoBanksRequestFulfilled)
      .addCase(getBitsoBanksThunk.rejected, bitsoBanksRequestRejected)
});

export const { name } = slice;
export const {
  toggleSideMenu,
  addNotification,
  removeNotification,
  changeCountriesLoadingState,
  changeCountriesSlice,
  changeBitsoBanksLoadingState,
  changeBitsoBanksSlice
} = slice.actions;
export default slice.reducer;
