import { memo, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import MergeFilesProvider from "./MergeFilesProvider";
import type { MergeFilesActionProps } from "./Props";
import useMergeFiles from "./useMergeFiles";
import { BasicDialog } from "ui/molecules/Dialog";

const MergeFilesDialog = ({ children, ...restProps }: PropsWithChildren<MergeFilesActionProps>) => {
    const {
        isLoading,
        open,
        onOpen,
        onClose,
        onMerge,
        isRowsMergable
    } = useMergeFiles(restProps);

    return (
        <MergeFilesProvider
            open={open}
            onOpen={onOpen}
        >
            <BasicDialog
                isOpen={open}
                onClose={onClose}
                isCanClose={!isLoading}
                DialogProps={{
                    sx: {
                        "& .MuiDialog-container .MuiPaper-root": {
                            maxWidth: "600px"
                        }
                    }
                }}
                titleSlot='Merge files'
                actionsSlot={(
                    <>
                        <Button
                            disabled={isLoading}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <LoadingButton
                            variant="outlined"
                            loading={isLoading}
                            disabled={!isRowsMergable()}
                            onClick={onMerge}
                        >
                            Proceed
                        </LoadingButton>
                    </>
                )}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        maxWidth: "auto"
                    }}
                >
                    <Typography>
                        <b>Important Note:</b>&nbsp;
                        {isRowsMergable()
                            ? 'Merging these files is a permanent action and cannot be undone. If you proceed, the only way to reverse the merge is by deleting the files. Are you sure you want to proceed?'
                            : 'You are missing required conditions in order to proceed with the merge. Please make sure the files you are merging fullfill all these conditions: Domain is different between files, files have same currency and the merchant is the same'}
                    </Typography>
                </Box>
            </BasicDialog>
            {children}
        </MergeFilesProvider>
    );
};

export default memo(MergeFilesDialog);
